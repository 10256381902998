import {createMuiTheme} from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ee600d",
    },
    secondary: {
      main: "#ee600d",
    },
    green: {
      main: "#00c180",
    },
    lightGrey: {
      main: "#b9b9b9",
    },
    darkGrey: {
      main: "#6c6c6c",
    },
    blue: {
      main: "#43b1fa",
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontSize: "1rem",
        color: "#484848",
      },
    },
  },
});

export default theme;
