import React, { useEffect } from "react";

const PaymentNativeLoader = ({ selectedPaymentOption }) => {

    const initiateNativePurchase = (pg = 'RZ-M') => {
        const last_order_details = localStorage.getItem('last_order_details');
        const cotPhone = localStorage.getItem('phone') !== undefined ? localStorage.getItem('phone') : null;
        if (last_order_details) {
            const last_order_details_obj = JSON.parse(last_order_details);
            if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
                const paymentData = {
                    referenceTransactionId: last_order_details_obj.order_id, // payment_reference_id encoded?.
                    productPrice: last_order_details_obj.amount,
                    skuList: 'FA',
                    transactionNote: `#${last_order_details_obj.order_id}`,
                    pgOrderId: last_order_details_obj.payment_reference_id,
                    pg,
                    cotPhone,
                    responseTarget: 'COT', // CAPG for CA gateway
                    faOrderId: last_order_details_obj.order_id,
                }
                console.log("Sending Payment Data", paymentData);
                window.AndroidBridge.call("initiatePurchase", JSON.stringify(paymentData))
            } else {
                const paymentData = {
                    referenceTransactionId: last_order_details_obj.order_id, // payment_reference_id encoded?.
                    productPrice: last_order_details_obj.amount,
                    skuList: 'FA',
                    transactionNote: `#${last_order_details_obj.order_id}`,
                    pgOrderId: last_order_details_obj.payment_reference_id,
                    pg,
                    cotPhone,
                    responseTarget: 'COT', // CAPG for CA gateway,
                    faOrderId: last_order_details_obj.order_id,
                }
                console.log("Sending Payment Data", paymentData);
                alert('AndroidBridge is not active');
            }

        } else {
            alert('No last order details found');
        }
    }

    useEffect(() => {
        if (selectedPaymentOption !== null) {
            initiateNativePurchase(selectedPaymentOption);
        }
    }, [selectedPaymentOption])

    return (
        <div>
            <div style={{ "height": "0" }}>
                Please wait...
            </div>
            {/* <button onClick={() => initiateNativePurchase('GPAY')}>testNativeGPay</button>
            <button onClick={() => initiateNativePurchase('RazorPay')}>testNativeRazorPay</button> */}
        </div>
    );
};

export default PaymentNativeLoader;