import React, { Component } from "react";
import axios from "../../../common/interceptor";
import moengage from "../../../common/moengage";
import sentryError from "../../../common/sentryError";
import gTag from "../../../common/google_analytics";

import { ReactComponent as GPIcon } from "../../../common/googleplayicon.svg";
import { ReactComponent as TYBackdrop } from "../../../common/tybackdrop.svg";
import { ReactComponent as FABackdrop } from "../../../common/backdrop.svg";
import Modal from "@material-ui/core/Modal";

import "./Consultation.css";
import HelmetComponent from "../../../common/HelmetComponent.component";
import ThankyouModal from "./ThankyouModal.component";
import Snackbar from "../../../common/SnackBarMessage";

import Button from "@material-ui/core/Button";
import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as FavIcon } from "../../../common/fa_fav.svg";
import { ReactComponent as SadIcon } from "../../../common/sad.svg";

import QueryString from "../../../common/util/QueryString";
import FA_localStorage from "../../../common/util/FA_localStorage";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import { MIN_BALANCE_MINS } from "../../../app.config";
import T2AEventTrack from "../../../common/T2AEventTrack";

const MINIMUM_MIN = 1;
const styles = (theme) => ({
  rateusModalButton: {
    backgroundColor: "#01c180 !important",
    margin: "30px 0px 15px 0px !important",
    color: "white !important",
  },
  rateusCloseIcon: {
    position: "relative",
    left: "45%",
    marginBottom: "10px",
  },
  rateusModalSkip: {
    fontSize: "14px",
  },
  rateusModal: {
    borderRadius: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fbead0",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    padding: "30px 20px",
    maxWidth: "450px",
    width: "90%",
    margin: "auto",
    textAlign: "center",
    outline: "none",
  },
  sidebarScreensArrowIcon: {
    marginTop: "24px",
    marginRight: "8px",
  },
});
class Thankyou extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    questions: [{ id: null, question: "", question_type: "" }],
    stars: [],
    isLoading: true,
    capital: false,
    modalOpen: false,
    astrologerImage: "",
    astrologerName: "",
    commentText: "",
    cost: "",
    time: "",
    offer: "",
    modelOpen: false,
    ackMessage: "Would you please give us the same rating in Google Play as well?",
    cashbackOffer: {},
    blastConfetti: false,
    isReviewSubmitted: true,
  };
  onStarReviewClick = (e, qid, value) => {
    this.setState((prevState) => ({
      stars: {
        ...prevState.stars,
        [qid]: value,
      },
    }));
  };

  onSubmit = () => {
    this.setState({ isLoading: true });
    const filterParams = QueryString();
    let that = this;
    let tempValues = Object.keys(that.state.stars).map(function (key) {
      return that.state.stars[key];
    });
    moengage("THANKYOU_PAGE_SUBMIT", { rating: tempValues.join(","), cost: this.state.cost });
    axios()
      .post("/review/consultation", {
        id: this.props.match.params.id,
        token: filterParams.token,
        answers: this.state.stars,
        comment: this.state.commentText,
      })
      .then((res) => {
        if (res.data.success) {
          this.setState({ isLoading: false });
          if (this.state.stars[this.state.questions[0].id] > 3) {
            //show google play rating always
            if (true || !FA_localStorage("GPRating")) {
              this.setState({ modelOpen: true });
            } else {
              window.setTimeout(function () {
                window.location.href = "/";
              }, 500);
            }
          } else {
            this.showError("Successfully submitted review.");
            window.setTimeout(function () {
              window.location.href = "/";
            }, 3000);
          }
        } else {
          this.showError(res.data.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        sentryError("FEEDBACK_SUBMIT", err);
        this.setState({ isLoading: false });
        this.showError("Something went wrong. please try again");
      });

    this.trackActions("fa_consult_ast_rating", "consult", {
      profileUrl: this.state.profileUrl,
      value: this.state.stars,
      call_rate: this.state.call_rate,
    });
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  capitalize = (questions) => {
    for (let i = 0; i < questions.length; i++) {
      questions[i].question = questions[i].question.charAt(0).toUpperCase() + questions[i].question.slice(1);
    }

    return questions;
  };

  onComment = (e) => {
    this.setState({ commentText: e.target.value });
  };

  trackActions = (type, label, data) => {
    moengage(type);
    let ast_rating = data.reviewsData && data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: "thank-you",
      p_label: label,
      p_content: data.profileUrl || "NA",
      p_value: data.value || 0,
      value: data.value || 0,
      currency: "INR",
      p_ast_exp: data.experience ? data.experience.split(" ")[0] : "NA",
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.call_rate ? data.call_rate.split("/")[0] : "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  componentDidMount = () => {
    /*this.setState({
      isLoading: false,
      caller_id: "+918000525121",
      time: "00:02:20",
      cost: "180",
      astrologerName: "Aacharya Vivek GCS",
    });
    return false;*/

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login";
    } else if (window.location.search) {
      const filterParams = QueryString();
      if (filterParams.token && filterParams.token !== "") {
        axios()
          .post("/review/questions", {
            id: this.props.match.params.id,
            token: filterParams.token,
          })
          .then((res) => {
            if (res.data.success) {
              moengage("THANKYOU_PAGE_VIEWED", { cost: Number(res.data.data.cost) });
              gTag("Call_made", {
                value: Number(res.data.data.cost),
                currency: "INR",
              });

              if (window.Moengage) window.Moengage.add_user_attribute("WALLET_BALANCE", Number(res.data.data.balance));
              let temp = [];
              for (let key in res.data.data.questions) {
                temp.push(res.data.data.questions[key]);
                this.onStarReviewClick(res, res.data.data.questions[key].id, 5);
              }
              this.setState({
                astrologerImage: res.data.data.profile_image,
                astrologerName: res.data.data.name,
                profileUrl: res.data.data.profileUrl,
                questions: temp,
                isLoading: false,
                capital: true,
                cost: res.data.data.cost,
                time: res.data.data.time,
                offer: res.data.data.offer,
                balance: res.data.data.balance,
                call_rate: res.data.data.call_rate,
                free_min: res.data.data.free_min || 0,
                user_discount: res.data.data.user_discount || 0,
                user_discount_per: res.data.data.user_discount_per || 0,
              });

              if (
                FA_localStorage("btkn") &&
                FA_localStorage("utkn") &&
                this.MinutesFromTime(res.data.data.time) > MINIMUM_MIN
              ) {
                axios()
                  .post("/profile/updates")
                  .then((res) => {
                    if (res.data.success) {
                      if (BrowserUtils.isNewUI() && res.data.hasOwnProperty('user_status')) {
                        BrowserUtils.setCachedKey('userStatus', res.data.user_status);
                      }
                      setTimeout(() => {
                        FA_localStorage(
                          "pendingCashbackOffer",
                          JSON.stringify({
                            cashbackOffer: {
                              offer: res.data.data.discount_per || 0,
                              showOfferPopup: true,
                            },
                          })
                        );
                      }, 100);
                    }
                    if (res.data.data.last_consultation_review && res.data.data.last_consultation_review.is_submitted) {
                      this.setState({ isReviewSubmitted: true });
                    } else {
                      this.setState({ isReviewSubmitted: false });
                    }
                  })
                  .catch((err) => {
                    sentryError("TY_CONSULTATION_OFFER", err);
                  });
              }

              /** fa tracking */
              const isPromo = parseInt(res.data.data.user_discount_per) === 50;
              this.trackActions("fa_consult_success", isPromo ? "promotional" : "main", res.data.data);

              let value = parseInt(res.data.data.cost - res.data.data.user_discount);
              this.trackActions("fa_wallet_debit", "NA", { value: value });

              this.trackActions("fa_cashback", "ncd", { value: parseInt(res.data.data.user_discount) });

              if (BrowserUtils.isNewUI()) {
                this.completedCallConsultation({
                  free_min: res.data.data.free_min || 0,
                  time: res.data.data.time || 0,
                  call_rate: res.data.data.call_rate,
                  cost: res.data.data.cost,
                  astrologerName: res.data.data.name,
                  consultId: this.props.match.params.id,
                })
              }
              /** fa tracking */

            } else {
              this.showError(res.data.message);
              this.setState({ isLoading: false });
            }
          })
          .catch((err) => {
            //console.log("arun", err);
            sentryError("FEEDBACK_QUESTIONS", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false });
          });
      }
    }
    localStorage.removeItem("progress_call");
    localStorage.removeItem("lastActiveConsultation");
    window.location.hash = "backtohome";
  };

  MinutesFromTime = (time) => {
    const timeSeg = time.split(":");
    if (timeSeg.length === 3) {
      var ref = new Date(2020, 1, 1, timeSeg[0], timeSeg[1], timeSeg[2]);
      return Math.ceil((ref.getHours() * 60 * 60 + ref.getMinutes() * 60 + ref.getSeconds()) / 60.0);
    } else {
      return 0;
    }
  };

  isAChargeableConsultation = () => {
    return this.MinutesFromTime(this.state.time) > MINIMUM_MIN;
  };

  onRateUsBtnClick = () => {
    FA_localStorage("GPRating", "initiated");
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      window.AndroidBridge.call("rateAppInPlayStore")
      return
    }
    window.open(
      "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3DFindastro_web%26utm_medium%3Dapplink",
      "_blank"
    )
  }

  completedCallConsultation = (tData) => {
    let astrologerId = 0;
    let lastCallData = {};

    try {
      let lastCallInfo = localStorage.getItem('lastCallData');
      if (lastCallInfo) {
        lastCallData = JSON.parse(lastCallInfo);
        if (lastCallData.astro_id) {
          astrologerId = lastCallData.astro_id
        }
      }
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
    }

    if (lastCallData?.consult_id && lastCallData.consult_id === tData.consultId && lastCallData.status === 'completed') {
      console.log("Already send : ", JSON.stringify(lastCallData))
      return;
    }


    let consultType = 'Paid'
    if (tData.free_min) {
      consultType = 'Free'
      if (tData?.cost && parseInt(tData.cost) > 0) {
        consultType = 'Free paid'
      }
    }
    const c_type = parseInt(tData.free_min) > 0 ? 'free' : 'paid'

    const duration = this.MinutesFromTime(tData.time);
    let walletBalance = parseInt(localStorage.getItem('walletBalance')) || 0;
    if (parseInt(tData.cost) > 0) {
      walletBalance = Math.max(walletBalance - parseInt(tData.cost), 0);
    }

    const data = {
      event: {
        name: 'Consultation',
        attributes: {
          'Screen Name': 'Chat Screen',
          'Consultation Name': 'Call',
          'Consultation Type': consultType,
          'Consultation Status': 'completed',
          'Consultation Duration': duration,
          'Consultation amount': `${tData.call_rate} pts/min `,
          'User ID': localStorage["userid"] ? parseInt(localStorage["userid"].split('-').at(-1)) || 0 : 0,
          'User Name': localStorage.getItem('name'),
          'Astrologer ID': astrologerId,
          'Astrologer Name': tData.astrologerName,
          'Product': 'COT',
          'Consultation ID': tData.consultId
        }
      },
      extra: {
        user: {
          'User ID': parseInt(this.state.currentUserId.split('-').at(-1)) || 0,
          'User Name': localStorage.getItem('name'),
          [`Last consulted ${c_type} call astrologer ID`]: tData.astrologerId,
          [`Last consulted ${c_type} call astrologer name`]: tData.astrologerName,
          'Last call attempt status': 'completed',
          'Last call attempt time stamp': Date.now(),
          'COT wallet balance': walletBalance,
          'Current wallet balance': walletBalance
        }
      }
    }

    if (duration >= MINIMUM_MIN) {
      data.extra.user['Last consulted astrologer ID'] = tData.astrologerId
      data.extra.user['Last consulted astrologer name'] = tData.astrologerName
      data.extra.user['Last consulted Id'] = tData.consultId
      data.extra.user['Last consulted type'] = `${consultType} call`;
    }

    T2AEventTrack(data.event, data.extra)
  }

  render() {
    const { classes } = this.props;
    if (this.state.capital) {
      this.capitalize(this.state.questions);
      this.setState({ capital: false });
    }
    return (
      <div id="thankyouParentDiv">
        <HelmetComponent
          title="Consultation - Feedback at Findastro"
          url="https://www.findastro.com/consultant-feedback"
          description="Consultation - Feedback at Findastro"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        {/*console.log(this.state.time, this.MinutesFromTime(this.state.time))*/}
        {this.isAChargeableConsultation() ? (
          <>
            <FABackdrop
              style={{
                width: "100%",
                position: "absolute",
                right: "-30px",
                top: "0px",
                opacity: "0.15",
              }}
            />
            <div className={"titleContainer"}>
              {BrowserUtils.isNewUI() ? '' : <FavIcon style={{ width: "56px" }} />}
              <div style={{ padding: "10px;" }}>
                <b>{FA_localStorage("name").split(" ")[0]}, let there be only happiness in your life!</b>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={"titleContainer"}>
              <SadIcon
                style={{
                  width: "64px",
                  marginBottom: 16,
                }}
              />
              <div style={{ padding: "10px", marginBottom: 16 }}>
                <b>
                  We are sorry!
                  <br />
                  It seems like your consultation was incomplete.
                  <br />
                  <br />
                  {this.state.cost.toString() === "0" ? "You don't need to pay anything!" : ""}
                </b>
              </div>
            </div>
          </>
        )}
        <div className={"callDetails"}>
          <div class="time">
            <span>Call duration:</span>
            <span>{this.MinutesFromTime(this.state.time)} mins</span>
            {this.state.offer > 0 && (
              <>
                <span>Free call duration:</span>
                <span>{this.state.free_min || 0} mins</span>
              </>
            )}
            <span>
              <b>Chargeable duration:</b>
            </span>
            <span>
              {this.state.offer > 0 ? (
                <b>
                  {this.MinutesFromTime(this.state.time) > this.state.free_min
                    ? this.MinutesFromTime(this.state.time) - this.state.free_min
                    : 0}{" "}
                  mins
                </b>
              ) : (
                <b>{this.isAChargeableConsultation() ? this.MinutesFromTime(this.state.time) : 0} mins</b>
              )}
            </span>
          </div>
          <div class="rate">
            <span>Call rate</span>
            <span>₹{this.state.call_rate || 0}/min</span>
            <span>Total cost</span>
            <span>₹{this.state.cost || 0}.00</span>
            {this.state.user_discount_per > 0 ? (
              <span>
                <b>{this.state.user_discount_per}% discount</b>
              </span>
            ) : (
              <></>
            )}
            {this.state.user_discount_per > 0 ? (
              <span>
                <b>₹{this.state.user_discount}.00</b>
              </span>
            ) : (
              <></>
            )}
          </div>
          <div class="final">
            <span>Payable</span>
            <span>₹{this.state.cost - this.state.user_discount || 0}.00</span>
          </div>
        </div>
        {this.state.free_min > 0 && this.state.offer.toString() === "0" && this.isAChargeableConsultation() && (
          <div className="no-free-call">
            The free call duration of{" "}
            {this.state.free_min + (this.state.free_min.toString() === "1" ? " min" : " mins")} is not applicable as the
            total call duration was less than {MIN_BALANCE_MINS} mins.
          </div>
        )}
        {!this.state.isReviewSubmitted && this.isAChargeableConsultation() ? (
          <div>
            <div class="ratingPanel">
              <div style={{ display: "flex", marginTop: "24px", marginBottom: 4 }}>
                <Avatar alt="Astrologer" id="thankyouImage" src={this.state.astrologerImage} />
                <div id="thankyouQuestionsDiv">
                  <span id="thankyouRate">
                    Rate consultation experience with astrologer <b>{this.state.astrologerName}</b>
                  </span>
                  <div>
                    <Rating
                      name={"rating" + this.state.questions[0].id} //hardcoded id
                      id={this.state.questions[0].id}
                      className="thankyouStarRating"
                      value={
                        this.state.stars[this.state.questions[0].id] ? this.state.stars[this.state.questions[0].id] : 0
                      }
                      onChange={(e, value) => this.onStarReviewClick(e, this.state.questions[0].id, value)}
                      precision={1}
                    />
                  </div>
                </div>
              </div>
              <div id="thankyouLeaveCommentDiv">
                <textarea
                  id="thankyouCommentBox"
                  variant="outlined"
                  type="text"
                  placeholder="Type your feedback here"
                  value={this.state.commentText}
                  onChange={this.onComment}
                />
              </div>
              <div
                onClick={() => (window.location.href = "/")}
                style={{ color: "#aaa", textAlign: "center", paddingTop: 8 }}>
                Later
              </div>
            </div>
            <div className="bottomBar">
              <Button id="thankyouSubmitButton" variant="contained" onClick={this.onSubmit} style={{ width: "100%" }}>
                Submit
              </Button>
            </div>
          </div>
        ) : (
          <div className="bottomBar">
            <Button
              style={{ width: "100%" }}
              id="thankyouSubmitButton"
              variant="contained"
              onClick={() => (window.location.href = "/")}>
              Okay
            </Button>
          </div>
        )}
        <ThankyouModal onModalClose={this.onModalClose} modalOpen={this.state.modalOpen} />
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
        <Modal open={this.state.modelOpen} onClose={() => (window.location.href = "/")}>
          <div className={classes.rateusModal}>
            <TYBackdrop style={{ height: "30vh", opacity: "0.8" }} />
            <div style={{ fontSize: "1.4rem", fontWeight: "700", color: "rgb(139, 10, 46)", margin: "24px 0px 24px 0" }}>
              Wow!
              <br />
              {(FA_localStorage("name") || "").split(" ")[0]}, thank you!
            </div>
            {BrowserUtils.GooglePlaySupport === true ? (
              <>
                <div style={{ padding: "0 24px 24px 24px", fontWeight: 700, color: "#5e5e5e" }}>
                  {this.state.ackMessage}
                </div>
                <Button
                  variant="contained"
                  className={classes.rateusModalButton}
                  onClick={this.onRateUsBtnClick}>
                  <GPIcon style={{ marginRight: "10px" }} />
                  <span>Rate us on Google Play</span>
                </Button>
                <div
                  className={classes.rateusModalSkip}
                  style={{ opacity: 0.6 }}
                  onClick={() => {
                    FA_localStorage("GPRating", "initiated");
                    window.location.href = "/";
                  }}>
                  I already rated in Google Play
                </div>{" "}
              </>
            ) : (
              <>
                <div style={{ padding: "0 24px 24px 24px", fontWeight: 700, color: "#5e5e5e" }}>
                  {"Would you please share your Findastro experience with your friends and family & support us."}
                </div>
                <Button
                  variant="contained"
                  className={classes.rateusModalButton}
                  onClick={() => {
                    FA_localStorage("GPRating", "initiated");
                    window.location.href = "/";
                  }}>
                  <span>Yes, sure!</span>
                </Button>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Thankyou));
