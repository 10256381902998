import React, {Component} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import {withRouter} from "react-router-dom";

import "./UserActions.css";
import userActionData from "../mock/UserActionData.mock";
import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SubmitArrowIcon from "@material-ui/icons/ChevronRight";
import QueryString from "../../../common/util/QueryString";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

class Register extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    phone: QueryString().phone ? QueryString().phone : "",
    code: "+91",
    isLoading: false,
    checkbox: true,
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onCheckBox = (event) => {
    this.setState({checkbox: event.target.checked});
  };

  onLogin = () => {
    window.location.href = "/login";
  };

  goBack = () => {
    if (document.referrer === "") {
      window.location.href = "/";
    } else {
      window.history.back();
    }
  };

  onlyNumbers = (e) => {
    var validChars = userActionData.validChars;
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  updateNumber = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  updateCode = (e, value) => {
    if (value && value.phone !== "") {
      this.setState({
        code: value.phone,
      });
    } else {
      this.setState({
        code: "",
      });
    }
  };

  submitOTP = () => {
    if (this.state.phone.length !== 10) {
      this.setState({
        snackBar: {
          open: true,
          message: "Please enter a valid phone number",
        },
      });
    } else if (this.state.code === "") {
      this.setState({
        snackBar: {
          open: true,
          message: "Please select a country code",
        },
      });
    } else if (!this.state.checkbox) {
      this.setState({
        snackBar: {
          open: true,
          message: "Please accept Terms of Service",
        },
      });
    } else {
      this.setState({
        isLoading: true,
      });
      axios()
        .post("/user/otp", {
          phone: this.state.phone,
          code: this.state.code,
          is_tc: this.state.checkbox,
        })
        .then((res) => {
          if (res.data.data.success) {
            this.props.history.push({
              pathname: "/otp",
              state: {
                data: res.data.data,
                isRegister: true,
              },
            });
          } else {
            this.showError("Error occured, Please try again");
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          sentryError("REGISTER", err);
          this.showError("Error occured, Please try again");
          this.setState({
            isLoading: false,
          });
        });
      this.trackActions("fa_otp_init", "NA");
    }
  };

  trackActions = (type, label) => {
    moengage(type);
    const payload = {
      p_screen: "signup",
      p_label: label,
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(this.state.phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  trackPageview = () => {
    /** event tracking */
    const type = "fa_signup";
    const payload = {
      p_screen: "signup",
      p_label: "NA",
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  componentDidMount = () => {
    moengage("REGISTER_PAGE_VIEWED");
    moengage("fa_signup");
    this.trackPageview();
  };

  render() {
    return (
      <div className="userActionParent">
        <HelmetComponent
          title="Signup at Findastro"
          url="https://www.findastro.com/register"
          description="Signup at Findastro- Signup and connect to your Astrologer at Findastro"
        />
        <Box id="registerTopArrow">
          <ArrowBackIcon className="userActionsArrow" onClick={this.goBack} />
          <span className="loginTroubleText">
            Already have an account?{" "}
            <span className="signupnowCTA" onClick={this.onLogin}>
              Login here
            </span>
          </span>
        </Box>
        <img alt="Phone Logo" id="registerPhoneLogo" src={userActionData.phoneImg} />
        <p id="registerText">Register</p>
        <p id="registerInfoText">Enter your mobile number, we will send you OTP to verify</p>
        <div id="registerFormDiv">
          <div id="registerFormInputAlign">
            <div id="registerLocSelect">
              <Autocomplete
                closeIcon=""
                defaultValue={userActionData.extensions[0]}
                onChange={this.updateCode}
                options={userActionData.extensions}
                getOptionLabel={(option) => option.label}
                disabled={true}
                renderOption={(option) => (
                  <React.Fragment>
                    <span className="loginExtensionOptions">
                      {option.label} {option.phone}
                    </span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </div>
            <TextField
              id="registerPhoneInput"
              type="tel"
              value={this.state.phone}
              variant="outlined"
              placeholder="0000000000"
              phone={this.state.phone}
              inputProps={{maxLength: 10}}
              onKeyDown={this.onlyNumbers}
              onChange={(e) => this.updateNumber(e)}
            />
          </div>
          <FormControlLabel
            control={<Checkbox checked={this.state.checkbox} onChange={this.onCheckBox} color="primary" />}
            label={
              <span>
                I accept the
                <a target="_blank" href="/terms-and-conditions?isRegister=true">
                  &nbsp;Terms & Conditions&nbsp;
                </a>
                and
                <a target="_blank" href="/privacy-policy?isRegister=true">
                  &nbsp; Privacy Policy.&nbsp;
                </a>
              </span>
            }
          />
          {!this.state.isLoading ? (
            <Button variant="contained" id="registerSubmitButton" onClick={this.submitOTP}>
              Continue
              <SubmitArrowIcon id="registerSubmitArrow"></SubmitArrowIcon>
            </Button>
          ) : (
            <div id="loading">
              <CircularProgress />
            </div>
          )}
        </div>
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}

export default withRouter(Register);
