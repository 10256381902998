import React, { useEffect, useState } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import { makeStyles } from "@material-ui/core/styles";

import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";

import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Switch from "@material-ui/core/Switch";
import clsx from "clsx";
import "./MyAccount.css";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import T2AEventTrack from "../../../common/T2AEventTrack";
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  body: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#fff",
    paddingBottom: "20px",
  },
  accountTopArea: {
    display: "flex",
    flexDirection: "row",
  },
  accountArrowIcon2: {
    color: "#5d5d5d !important",
    margin: "26px 15px 0 0px",
  },
  accountChildTopArea2: {
    marginTop: "36px",
    lineHeight: "6px",
    fontSize: "19px",
  },
  accountText: {
    color: "#ee600d",
    marginBottom: "37px",
  },
  flexParent: {
    marginBottom: "12px",
    padding: "16px 10px",
    background: "#fff7f7",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
  },
  flexChild1: {
    fontSize: 14,
  },
  flexChild2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "60px",
  },
  button: {
    color: "#ee600d",
    fontSize: "15px",
    textDecoration: "underline",
  },
  statusgreen: {
    color: "#00ff1f !important",
  },
  statusred: {
    color: "#ff0c00 !important",
  },
  statusyellow: {
    color: "#ffb100 !important",
  },
  statusblue: {
    color: "#008cff !important",
  },
  historyDateText: {
    color: "#8e8e8e",
    fontSize: "12px",
  },
  top: {
    paddingBottom: 5,
  },
  switch: {
    textAlign: "right",
    marginBottom: "15px",
    marginTop: "16px",
  },
});

const Invoices = (props) => {
  const classes = useStyles();

  const [invoice, setInvoice] = useState([]);
  const [invoiceSuccess, setSuccess] = useState([]);
  const [invoiceAll, setAll] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [toggleText, setToggleText] = useState("Show only successful transactions");
  const [count, setCount] = useState(0);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });
  const history = useHistory()
  const isWalletDialogOpen = window.location.hash.includes('back-button--wallet-recharge')

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  const onToggle = () => {
    if (count < 1) {
      setCount(count + 1);
    }
    setToggle(!toggle);
    if (toggle) {
      setInvoice(invoiceAll);
    } else {
      setInvoice(invoiceSuccess);
    }
  };

  useEffect(() => {
    setLoading(true);
    axios()
      .post("/wallet/transactions" + (toggle ? "?type=success" : ""))
      .then((res) => {
        if (res.data.success) {
          let arr = [];
          if (Object.keys(res.data.data).length > 0) {
            arr = Object.keys(res.data.data)
              .sort()
              .reverse()
              .map(function (key) {
                return res.data.data[key];
              });
          }
          if (toggle) {
            setSuccess(arr);
          } else {
            setAll(arr);
          }
          setInvoice(arr);
          setLoading(false);
        } else {
          showError(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        sentryError("INVOICES", err);
        showError("Error occured! Please try again later.");
        setLoading(false);
      });
    trackeventData();

    if (!isWalletDialogOpen) {
      window.location.hash = "backtohome";
    }
  }, [count]);

  const trackeventData = () => {
    const payload = {
      event: {
        name: 'Screen View Action',
        attributes: {
          'Screen Name': 'My transactions',
          'Source Screen Name': 'My Wallet',
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name,
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra)
  };

  const goBack = () => {
    if (isWalletDialogOpen) {
      history.goBack()
    } else if (BrowserUtils.isNewUI()) {
      if (history.length === 1) {
        history.push('/user-wallet')
      } else {
        history.goBack()
      }
    } else {
      props.history.goBack()
    }
  }

  return (
    <div className={clsx(classes.body, 'invoice-page')}>
      <HelmetComponent
        title="Invoice History at Findastro"
        url="https://www.findastro.com/invoices"
        description="Invoice History at Findastro-Connect to your Astrologer"
      />
      {isLoading ? (
        <div className="circle-loading">
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}
      <div className={classes.accountTopArea}>
        <ArrowBackIcon className={classes.accountArrowIcon2} onClick={goBack} />
        <div className={classes.accountChildTopArea2}>
          <p>My Transactions</p>
        </div>
      </div>
      <div className={classes.switch}>
        <span>{toggleText}</span>
        <Switch checked={toggle} onChange={onToggle} color="primary" />
      </div>
      {invoice.length === 0 && !isLoading ? (
        <>
          <div style={{ padding: '.5em' }}>
            <Alert severity="info">{toggle ? "No valid Invoices found " : "No transactions found"}</Alert>
          </div>
          <Snackbar
            vertical="top"
            horizontal="center"
            message={toggle ? "No valid Invoices found " : "No transactions found"}
            closeError={closeError}
            open={true}></Snackbar>
        </>
      ) : (
        ""
      )}
      {invoice.map((data, key) => (
        <div className={clsx(classes.flexParent, data.type, data.paymentStatus, "invoice")} key={key}>
          <div className={classes.flexChild1}>
            <div className={clsx("type", data.type)}>
              <span style={{ fontWeight: 600 }}>
                {data.type === "transaction"
                  ? "Recharge"
                  : data.type === "discount"
                    ? "Cashback"
                    : data.type === "newuser cashback"
                      ? "Welcome bonus"
                      : data.type === "refer earn regn"
                        ? "friend referral bonus"
                        : data.paymentStatus === "refunded"
                          ? "Refund"
                          : data.type}
              </span>
            </div>
            {data.orderId && data.orderId !== "" ? (
              data.type === "transaction" ? (
                <div className={classes.top}>Order ID: {data.orderId}</div>
              ) : data.type === "refer earn regn" ? (
                <div className={classes.top}>Ref ID: {data.orderId}</div>
              ) : (
                <div className={classes.top}>Order ID: {data.orderId}</div>
              )
            ) : (
              <div className={classes.top}> Consultation ID: {data.consultationId}</div>
            )}
            {data.booking_id && <div className={classes.top}> Booking ID: {data.booking_id}</div>}
            <div className={classes.historyDateText}>
              Date: {data.time} <br /> Status:&nbsp;
              <span className={clsx("status", data.paymentStatus === "refunded" ? "completed" : data.paymentStatus)}>
                {data.type === "discount" || data.paymentStatus === "refunded" ? "credited" : data.paymentStatus}
              </span>
            </div>
          </div>
          <div className={classes.flexChild2}>
            {data.consultationId &&
              data.consultationId !== "" &&
              data.type !== "discount" &&
              data.paymentStatus !== "refunded" ? (
              <div className={clsx(classes.amountred, "amount")}>
                &nbsp;<span className="rupee-symbol">₹</span>&nbsp;
                {data.amount > 0 ? "-" + data.amount : data.amount}
              </div>
            ) : (
              <div className={clsx(classes.amountgreen, "amount")}>
                <span className="rupee-symbol">₹</span>&nbsp;
                {data.amount}
              </div>
            )}

            {data.orderId && data.orderId !== "" && data.invoice && data.invoice !== "" ? (
              <a target="_blank" rel="noreferrer" href={data.invoice} className={classes.button}>
                Invoice
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      ))}

      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBar.message}
        closeError={closeError}
        open={snackBar.open}></Snackbar>
    </div>
  );
};

export default Invoices;
