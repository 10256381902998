import React, { useEffect, useState } from 'react'
import BrowserUtils from '../modules/user/astrologer/components/UAScripts';
import WalletPage from '../modules/account/components/WalletPage';
import UserWallet from '../modules/account/components/UserWallet';
import MyWalletComponent from '../modules/account/components/MyWallet.component';
import WalletNativePage from '../modules/account/components/WalletNativePage';
import DealPurchase from '../modules/feature/deal-purchase/components/DealPurchase';
import { getURLParams } from '../modules/user/astrologer/components/chat/ChatUtls';
import { GatewayConfig } from '../gateway.config';

export const setWalletEvent = {
  set: (name, data) => {
    console.log('setWalletEvent:onWallet_' + name, data)
    const event = new Event(`onWallet_${name}`)
    event.data = data
    window.dispatchEvent(event)
  },
}

export const WalletUtils = {
  setType: (type) => {
    console.trace('wc:walletType', type)
    localStorage.setItem('walletType', type)
    window.dispatchEvent(new Event('LS_walletType'))
  },
  getType: () => {
    return localStorage.getItem('walletType') || GatewayConfig.default
  },

  getDefaultType: () => {
    if (localStorage.getItem('enableNewPayment') === 'true') {
      return 'capg'
    }
    if (localStorage.getItem('enableNativePayment') === 'true') {
      return 'rz_native'
    }
    return GatewayConfig.default
  },
}

const WalletComponent = () => {

  const [isNewWalletEnabled, setIsNewWalletEnabled] = useState(false);
  const [isNativeWalletEnabled, setIsNativeWalletEnabled] = useState(false);
  const [isReadyWallet, setIsReadyWallet] = useState(false);
  const [walletType, setWalletType] = useState(GatewayConfig.default);

  useEffect(() => {
    handleWalletType()

    // console.log({
    //   'DealPurchase.isLastPurchaseIsDealPurchase()': DealPurchase.isLastPurchaseIsDealPurchase(),
    //   'getURLParams(\'status\')': getURLParams('status'),
    //   'GatewayConfig.dealsGateway': GatewayConfig.dealsGateway,
    // })
    if (DealPurchase.isLastPurchaseIsDealPurchase()
      && getURLParams('status') === 'failed'
      && GatewayConfig.dealsGateway !== 'default'
    ) {
      console.log('wc:payment-failed', GatewayConfig.dealsGateway)
      WalletUtils.setType(GatewayConfig.dealsGateway)
    }
    window.addEventListener("LS_walletType", () => {
      console.log('wc:storage', WalletUtils.getType())
      handleWalletType()
    });
  }, [])

  useEffect(() => {
    handleWalletType()
  }, [window, window.location.hash])

  const handleWalletType = () => {
    // console.log('wc:handleWalletType:walletType', walletType)
    if (WalletUtils.getType() === 'rz_native') {
      console.log('wc:handleWalletType:rz_native')
      setWalletType('rz_native')
      setIsReadyWallet(true)
    } else if (WalletUtils.getType() === 'capg') {
      console.log('wc:handleWalletType:capg')
      setWalletType('capg')
      setIsReadyWallet(true)
    }
  }


  // useEffect(() => {


  //   const handleWalletType = e => {
  //     console.log('setWalletEvent:onWallet_type', e)
  //     if (e.data) {
  //       if (e.data === 'rz_native') {
  //         setWalletType('rz_native')
  //       } else if (e.data === 'capg') {
  //         setWalletType('capg')
  //       }
  //       setIsReadyWallet(true)
  //     }
  //   }

  //   window.addEventListener('onWallet_type', handleWalletType)

  //   // return () => {
  //   //   // window.removeEventListener('onWallet_type', handleWalletType)
  //   // };

  // });
  // }, [window, window.location.hash])

  const haveNativeWalletFunctionsOnApp = () => {
    if (window.location.host === 'localhost:3000') {
      return true;
    }
    if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
      return parseInt(window.AndroidBridge.call("getAppVersionCode")) >= 188;
    } else {
      return false;
    }
  }

  useEffect(() => {
    const userProfileId = localStorage.getItem("userProfileId");

    if (window.location.host === 'www.findastro.com') {
      // const testProfileIds = [5116, 8146, 8147, 8148, 18188, 22870, 64889, 97236, 97590, 100478, 105215, 108179, 117294, 114388];
      // const isTestProfileID = testProfileIds.includes(parseInt(userProfileId));
      // setIsNewWalletEnabled(true);
      const isUserOdd = userProfileId !== null ? parseInt(userProfileId) % 2 !== 0 : false;
      // setIsNewWalletEnabled(!isUserOdd);
      // Enable Native Wallet for internal users
      const testProfileIds = [105215, 118007, 108179];
      const isTestProfileID = testProfileIds.includes(parseInt(userProfileId));
      setIsNativeWalletEnabled(isTestProfileID);
      // setIsNativeWalletEnabled(isUserOdd);
    } else {
      const isUserOdd = userProfileId !== null ? parseInt(userProfileId) % 2 !== 0 : false;
      // setIsNewWalletEnabled(!isUserOdd);
      setIsNativeWalletEnabled(isUserOdd);
    }

    if (localStorage.getItem('enableNewPayment') !== null) {
      setIsNewWalletEnabled(localStorage.getItem('enableNewPayment') === 'true' ? true : false);
    }

    if (localStorage.getItem('enableNativePayment') !== null) {
      console.log("enableNativePayment", localStorage.getItem('enableNativePayment'));
      setIsNativeWalletEnabled(localStorage.getItem('enableNativePayment') === 'true' ? true : false);
    }

    // Set rz_native payment for last order have deal purchase
    // let data = localStorage.getItem('last_order_details')
    // if (data !== null) {
    //   data = JSON.parse(data)
    //   if (data?.dealPurchase) {
    //     setIsNativeWalletEnabled(true);
    //   }
    // }

    setIsReadyWallet(true);

  }, [])

  useEffect(() => {
    if (isNewWalletEnabled && walletType === 'capg') {
      // setWalletType('capg')
      WalletUtils.setType('capg')
    }
  }, [isNewWalletEnabled])

  useEffect(() => {
    if (isNativeWalletEnabled && walletType === 'rz_native') {
      // setWalletType('rz_native')
      WalletUtils.setType('rz_native')
    }
  }, [isNativeWalletEnabled])

  // <UserWallet /> - Old RazorPay
  // <WalletPage /> - CAPG
  // <WalletNativePage /> - Native RazorPay
  return (
    <>
      {isReadyWallet ? (
        <>
          {BrowserUtils.isNewUI() ? (
            <>
              {isNativeWalletEnabled && haveNativeWalletFunctionsOnApp() ? (
                walletType === 'rz_native' ? <WalletNativePage /> : <WalletPage haveNativeWalletFunctionsOnApp={haveNativeWalletFunctionsOnApp} />
              ) : (
                walletType === 'rz_native' ? <WalletNativePage /> : <WalletPage haveNativeWalletFunctionsOnApp={haveNativeWalletFunctionsOnApp} />
              )}
            </>
          ) : (
            <MyWalletComponent />
          )}
        </>
      ) : (
        <></>
      )
      }
    </>
  )
}

export default WalletComponent