import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ConsolutationRequests, getURLParams } from "../../user/astrologer/components/chat/ChatUtls";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

export const toQueryString = (obj, name) => {
  return encodeURIComponent(name) + '=' + encodeURIComponent(JSON.stringify(obj));
}

export const sessionKey = 'cndata'

// @Deprecated use OpenAstrologerProfile
const AppDirect = () => {
  const history = useHistory()
  const astrologerID = getURLParams('astrologer')
  const task = getURLParams('task')

  useEffect(() => {
    if (BrowserUtils.isLoggedIn()) {
      handleRedirection()
    } else {
      const redirectURL = `/?verify-number-by-url=1&astrologerID=${astrologerID}&task=${task}`
      redirectToListing(redirectURL)
    }
    return () => { }
  }, [])

  const redirectToListing = (url = '/') => {
    history.push(url)
  }

  const handleRedirection = () => {
    try {
      (async () => {
        await ConsolutationRequests.pushNotificationData({
          chatID: '',
          task,
          astrologerID
        })
          .then(pushData => {
            let redirectURL = ''
            if (pushData?.success && pushData?.astrologer?.profileUrl) {
              const data = pushData
              delete data.success
              data.astrologer.id = parseInt(astrologerID)
              data.task = task
              sessionStorage.setItem(sessionKey, JSON.stringify(data))
              // const data = toQueryString(pushData, 'data')
              // redirectURL = `/astrologer-profile/${pushData.astrologer.profileUrl}?direct-loading=chat-notification&${data}`
              redirectURL = `/astrologer-profile/${pushData.astrologer.profileUrl}?direct-loading=chat-notification`
            }
            // console.log('AppDirect:redirectURL:', redirectURL)
            history.push(redirectURL)
          }, err => {
            redirectToListing()
            console.error('ConsolutationRequests.pushNotificationData => Reject error:', err)
          })
      })()

    } catch (err) {
      redirectToListing()
      console.log('ConsolutationRequests.pushNotificationData => Error catch:', err)
    }
  }

  return (null)
}

export default AppDirect