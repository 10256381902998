import React, { useEffect, useState } from "react";

import astrologerListData from "../mock/Astrologers.mock";
import FilterDrawerCategoriesComponent from "./FilterDrawerCategories.component";

import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import QueryString from "../../../../common/util/QueryString";

const FilterDrawerComponent = (props) => {
  const [filters, setFilters] = useState([]);

  const onClear = () => {
    setFilters({
      expertise: [],
      languages: [],
      category: [],
    });
  };

  const onSelect = () => {
    props.showLoading();
    let filterText = "?";
    var letters = /^[a-zA-Z]+$/;

    for (const key in filters) {
      for (var j = 0; j < filters[key].length; j++) {
        if (!filters[key][j].match(letters)) {
          filters[key].splice(j, 1);
        }
      }
    }

    if (props.filterData.availability) filterText = filterText + "availability=" + props.filterData.availability + "&";
    if (filters.expertise.length) filterText = filterText + "expertise=" + filters.expertise.join("|") + "&";
    if (filters.languages.length) filterText = filterText + "language=" + filters.languages.join("|") + "&";
    if (filters.category.length) filterText = filterText + "category=" + filters.category.join("|") + "&";
    if (props.filterData.sort) filterText = filterText + "sort=" + props.filterData.sort + "&";
    if (filterText === "?") {
      window.location.href = "/";
    } else {
      window.location.href = filterText.slice(0, -1);
    }
  };

  const onSelectFilters = (text) => {
    let selectionData = text.split("||");
    const tempFilters = { ...filters };

    if (selectionData[0] === "add") {
      tempFilters[selectionData["1"]].push(selectionData["2"]);
    } else if (selectionData[0] === "remove") {
      tempFilters[selectionData["1"]].splice(filters[selectionData[1]].indexOf(selectionData[2]), 1);
    }

    setFilters(tempFilters);
  };

  useEffect(() => {
    const formattedFilterData = QueryString();
    const tempData = {
      expertise: formattedFilterData.expertise ? formattedFilterData.expertise.split("|") : [],
      languages: formattedFilterData.language ? formattedFilterData.language.split("|") : [],
      category: formattedFilterData.category ? formattedFilterData.category.split("|") : [],
    };
    setFilters(tempData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      className="homeFilterBox"
      anchor="bottom"
      open={props.filterOpen}
      onClose={() => {
        props.onFilterDrawerClose();
      }}>
      <div id="homeFilterBoxDiv">
        {astrologerListData.filterCategories.map((category, i) =>
          category.text !== "category" ? (
            <div key={category.text}>
              <div className="homeFilterBoxCategoryText">{category.text.toUpperCase()}</div>
              <div className="filterOptionFlex">
                {category.options.map((option) => (
                  <FilterDrawerCategoriesComponent
                    isActive={
                      filters[category.text] &&
                        filters[category.text].indexOf(option.text.replace(/\s/g, "").toLowerCase()) > -1
                        ? true
                        : false
                    }
                    option={option}
                    optionType={category.text}
                    onSelectFilters={onSelectFilters}
                    key={option.key}
                  />
                ))}
              </div>
              {category.text === "languages" ? <></> : <Divider />}
            </div>
          ) : (
            <React.Fragment key={i}></React.Fragment>
          )
        )}
      </div>
      <div className="homeFilterFooter">
        <span className="homeFilterButtonsClear" onClick={onClear}>
          Clear Filter
        </span>
        <span className="homeFilterButtons" onClick={onSelect}>
          Apply
        </span>
      </div>
    </Drawer>
  );
};
export default FilterDrawerComponent;
