import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "20px",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 3),
    top: "30%",
    maxWidth: "450px",
    left: "5%",
    width: "90%",
    textAlign: "center",
    "&:hover, &:focus": {
      outline: "none !important",
    },
  },
  text: {
    paddingTop: "14%",
    fontSize: "20px",
    paddingBottom: "14%",
  },
  button: {
    borderRadius: "20px",
    backgroundColor: "#ee600d",
    marginBottom: "14%",
    padding: "8px",
    width: "80%",
    color: "white",
  },
  skip: {fontSize: "14px"},
}));

export default function ModalDrawer(props) {
  const classes = useStyles();

  const onSkip = () => {
    window.location.href = "/";
  };

  const onGoto = () => {
    window.location.href = "/";
  };

  return (
    <div>
      <Modal open={props.modalOpen} onClose={onSkip}>
        <div className={classes.paper}>
          <div className={classes.text}>Thank you for your valuable feedback.</div>
          <Button variant="contained" className={classes.button} onClick={onGoto}>
            <span>GO TO HOMEPAGE</span>
          </Button>
          <div onClick={onSkip} className={classes.skip}>
            Skip and continue browsing
          </div>
        </div>
      </Modal>
    </div>
  );
}
