import React, { useEffect, useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from "@material-ui/core";
import clsx from "clsx";
import { ChevronRight, Help } from "@material-ui/icons";
import RechargeLinkRedirect from "./RechargeLinkRedirect";

const RechargeAccordion = ({ item, expanded }) => {
  return (
    <Accordion className="accordion" expanded={expanded}>
      <AccordionSummary>
        Amount break up
      </AccordionSummary>
      <AccordionDetails>
        <table>
          <thead>
            <tr>
              <th align="left">Items</th>
              <th align="right">Calculation</th>
            </tr>
          </thead>
          <tbody>
            {item.amount &&
              <tr>
                <td>Recharge Amount</td>
                <td>₹{item.amount}</td>
              </tr>
            }
            {item.gst &&
              <tr>
                <td>GST {item.gstPercentage}%</td>
                <td>₹{item.gstAmount}</td>
              </tr>
            }
            {item.payableAmount &&
              <tr className="highlight-row payable">
                <td>You Pay:</td>
                <td>₹{item.payableAmount}</td>
              </tr>
            }
            {item.pointsActual &&
              <tr>
                <td>Recharge points</td>
                <td>{item.pointsActual}</td>
              </tr>
            }
            {(item.pointsExtra && item.pointsExtraPercentage) &&
              <tr>
                <td>{item.pointsExtraPercentageRound}% extra points</td>
                <td>{item.pointsExtra}</td>
              </tr>
            }
            {item.points &&
              <tr className="highlight-row">
                <td>You get:</td>
                <td>{item.points}</td>
              </tr>
            }
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  )
}

const RCButton = (p) => {
  return <Button {...p} className={"action-button"} variant="contained" color="primary">
    <div className="text">Recharge</div>
    <div className="icon"><ChevronRight /></div>
  </Button>
}

const RechargeButton = (props) => {
  return (
    props.rechargeRefData ?
      <RechargeLinkRedirect
        refData={props.rechargeRefData}
        onClick={props.rechargeClick}
      >
        <RCButton />
      </RechargeLinkRedirect>
      :
      <RCButton onClick={props.rechargeClick} />
  )
}

const isDealItem = (item) => {
  return item.plan_type === 'DEAL'
}

const RechargeOptionItem = (props) => {
  const [item, setItem] = useState('')
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    calculation()
  }, [props.amount])

  const twoDecimal = (number) => {
    return Math.round(number * 100) / 100
  }

  const calculation = () => {
    const i = {
      amount: props.amount,
      plan_type: props.plan_type,
      deal_type: props?.deal_type,
      minutes: props?.minutes,
      payableAmount: '',
      gst: props.gst,
      gstPercentage: 18,
      gstAmount: '',
      points: props.points,
      pointsActual: '',
      pointsExtra: '',
      pointsExtraPercentage: '',
      pointsExtraPercentageRound: ''
    }
    if (i.gst) {
      i.gstAmount = twoDecimal(i.amount * (i.gstPercentage / 100))
      i.payableAmount = twoDecimal(i.amount + i.gstAmount)
    }

    if (i.points) {
      i.pointsActual = i.amount
      if (i.points > i.amount) {
        i.pointsExtra = i.points - i.amount
        i.pointsExtraPercentage = twoDecimal((i.pointsExtra / i.points) * 100)
        i.pointsExtraPercentageRound = Math.round(i.pointsExtraPercentage)
      }
    }

    i.calculated = true
    setItem(i)
  }

  const onExpandToggle = (item) => {
    const value = !expanded
    setExpanded(value)
    if (value === true && props.onExpand) {
      props.onExpand(item)
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const OptionLabel = (e) => {
    const item = e.item

    if (props.type === 'wallet-item') {
      return (
        <div className="option-text">Option ₹{item.amount}:&nbsp;
          {(item.pointsExtra && item.pointsExtraPercentage)
            ? <span>{item.pointsExtraPercentageRound}% extra points</span>
            : (
              isDealItem(e.item)
                ? <span>Get 5 mins</span>
                :
                ''
            )
          }
        </div>
      )
    }

    return (null)
  }

  return (
    item ?
      <div
        className={clsx(
          'recharge-option__item',
          props.highlight ? 'highlight' : '',
          props.type ? props.type : '',
          props.rechargeButtonExpand ? 'expand-recharge-btn' : '',
          props.showOptionLabel ? 'show-option-label' : '',
          isDealItem(item) ? 'highlight deal-item' : '',
        )}
        key={item.amount}
      >
        {props.showOptionLabel ? <OptionLabel item={item} /> : null}

        <div className="wrap">
          <div className="info">

            {(props.highlight && !props.type) && <div className="highlight-text"><span>Most popular!</span></div>}

            {/* Deal Item */}
            {isDealItem(item) ?
              <>
                <div className="texts">
                  <div className="column">
                    {item.amount &&
                      <div className="amount">Recharge for&nbsp;
                        <span className="amount-price">
                          <span className="text-green">₹{item.amount} </span>
                        </span>
                      </div>
                    }
                  </div>
                  <div className="column">
                    {item.amount &&
                      <div className="points">
                        <strong className="pts-title">Get first chat<br />for {item.minutes} mins</strong>
                      </div>
                    }
                  </div>
                  <div className="column icon">
                    <div className="actions">
                      <RechargeButton {...props} />
                    </div>
                  </div>
                </div>
              </>
              : (
                /* Wallet Item */
                props.type === 'wallet-item' ?
                  <>
                    <div className="texts">
                      <div className="column">
                        {props.highlight && <div className="highlight-text"><span>Most popular!</span></div>}
                        {item.amount &&
                          <div className="amount">Pay only&nbsp;
                            <span className="amount-gst">
                              <span className="text-green">₹{item.amount} </span>
                              {item.gst && <span className="gst">+&nbsp;GST</span>}
                            </span>
                          </div>
                        }
                      </div>
                      <div className="column">
                        {item.points &&
                          <div className="points">
                            <strong className="pts-title">Get</strong>
                            <div className="pts"><span className="text-green">{item.points}&nbsp;pts</span></div>
                          </div>
                        }
                      </div>
                      <div className="column icon">
                        <IconButton onClick={e => onExpandToggle(item)}>
                          <Help />
                        </IconButton>

                        <div className="actions">
                          <RechargeButton {...props} />
                        </div>
                      </div>
                    </div>
                    <RechargeAccordion item={item} expanded={expanded} />
                  </>
                  :
                  ''
              )
            }

            {/* Default Item */}
            {
              !props.type &&
              <>
                {props.info && <div className="info-text">{props.info}</div>}
                <div className="price">
                  {props.actualAmount && <div className="actual-amount">₹{props.actualAmount}</div>}
                  {props.amount && <div className="amount">₹{props.amount}</div>}
                  {props.gst && <div className="gst">+ GST</div>}
                  {props.discount && <div className="discount">{props.discount}% OFF</div>}
                </div>
              </>
            }

          </div>
          {
            !props.type &&
            <div className="actions">
              <RechargeButton {...props} />
            </div>
          }
        </div>
      </div>
      :
      null
  );
}

export default RechargeOptionItem;