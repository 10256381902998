import React, { useEffect, useState } from "react"
import { Avatar, Drawer, IconButton } from "@material-ui/core"
import RechargeOptionItem from "../RechargeOptionItem"
import axios from "../../../../../common/interceptor"
import { useTimer } from "react-timer-hook"
import clsx from "clsx"
import { BotMessage } from "../chat/ChatUtls"
import { ReactComponent as GiftIcon } from "../../../../../common/giftbox.svg"
import BrowserUtils from "../UAScripts";
import T2AEventTrack from "../../../../../common/T2AEventTrack";
import AstrologerUtils from "../AstrologerUtils"
import AstrologerAvatar from "../AstrologerAvatar"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { Close } from "@material-ui/icons"

/**
 * getContent Function
 *
 * A function that retrieves content based on a specified variant and data
 *
 * @param {string} variant - The content variant ID to retrieve
 * @param {object} data - Additional data used for content retrieval
 * @returns {any} - The content corresponding to the variant and data
 */
const getContent = ({ variant, data }) => {
  const { astrologer, minutes } = data
  console.log({ variant, data })
  const contentVariants = [
    {
      variant: 'IP1',
      title: 'Continue consultation',
      content: `${astrologer.name} is waiting for you. Recharge in the next ${minutes} minutes to continue chatting.`,
      optionText: 'Recharge for'
    },
    {
      variant: 'IP2',
      title: 'Recharge Now to Keep Chatting!',
      content: `Your conversation with ${astrologer.name} is on hold. 
      To resume your chat, recharge within the next `,
      timerAfter: 'min',
      optionText: 'Choose your recharge option:',
    },
    {
      variant: 'IP3',
      title: 'Time\'s Running Out!',
      content: `Don't miss out on valuable insights from ${astrologer.name}. Recharge within the next ${minutes} minutes to continue your chat session.`,
      optionText: 'Recharge Options:'
    },
    {
      variant: 'IP4',
      title: 'Astrologer Chat Alert!',
      content: `${astrologer.name} is ready to assist you, but your chat time has expired.
      Recharge within the next ${minutes} minutes to continue your session.`,
      timerBefore: 'Time Remaining:',
      optionText: 'Recharge Options:',
    },
    {
      variant: 'IP5',
      title: 'Recharge alert!',
      content: `Your conversation with ${astrologer.name} is on hold. 
      We have reserved the astrologer for ${minutes} mins. Recharge now!`,
      timerAfter: 'min',
      optionText: 'Choose your recharge option:',
    },
  ]

  let content = contentVariants.filter(c => c.variant === variant)
  if (content.length) {
    return content[0]
  } else {
    return contentVariants[Math.floor(Math.random() * contentVariants.length)]
  }
}

/**
 * CountDownTimer Component
 *
 * A timer component that counts down to a specified expiration timestamp.
 *
 * @param {boolean} autoStart - Whether the timer should start automatically.
 * @param {number} expiryTimestamp - The timestamp when the countdown expires.
 * @param {function} onExpire - Callback function to be executed when the countdown expires.
 */
const CountDownTimer = ({ autoStart, expiryTimestamp, onExpire }) => {
  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp,
    autoStart,
    onExpire
  })
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    restart(expiryTimestamp)
    setVisible(true)
  }, [expiryTimestamp])

  return (
    <>
      {visible &&
        <span className={clsx('count-down-timer', (minutes === 0 && hours === 0) ? 'danger' : 'normal')}>
          {hours > 0 ? String(hours).padStart(2, "0") + ':' : ''}{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </span>
      }
    </>
  );
};

export const InstantPopupMessage = {
  open: {
    text: `Please be aware that the user's instant payment popup is now open, indicating they may be in the process of recharging. We kindly request your patience and ask that you wait for 3 minutes to allow them to complete their transaction.`,
    bot_type: 'instant-popup-open',
    visibility: 'astrologer',
  }
}

/**
 * InstantPaymentPopup Component
 *
 * A component that represents an instant payment popup for astrologer services
 *
 * @param {string} anchor - Position of the popup ('bottom' by default)
 * @param {boolean} open - Whether the popup is open
 * @param {object} astrologer - Astrologer object
 * @param {number} minutes - Minutes for the timer (default is 3)
 * @param {string} variant - Content variant ID
 * @param {string} chatID - Chat ID
 * @param {function} onFailedRechargeOptions - Callback function when no options are found
 * @param {function} onClose - Callback when the popup is closed
 * @param {boolean} showFAB - Show the FAB initially
 * @param {object} styleFAB - Custom style for the FAB
 * @param {boolean} showAstrologerPhoto - Whether to show the astrologer's photo
 */
const InstantPaymentPopup = ({
  anchor = 'bottom',
  open,
  astrologer,
  minutes = 3,
  variant = '',
  chatID,
  onFailedRechargeOptions = () => { },
  onClose,
  showFAB = false,
  styleFAB = {},
  showAstrologerPhoto = false,
  trackEvents = {
    default: {
      screenName: 'Instant recharge popup'
    },
    showFABIcon: {
      screenName: 'Chat screen instant popup FAB'
    },
    FABClick: {
      screenName: 'Chat screen'
    }
  }
}) => {
  const [openDrawer, setOpenDrawer] = useState(null)
  const [content, setContent] = useState({})
  const [rechargeOptions, setRechargeOptions] = useState([])
  const [expiryTimestamp, setExpiryTimestamp] = useState(false)
  const [showGiftIcon, setShowGiftIcon] = useState(false)
  const [eventPaymentData, setEventPaymentData] = useState({})
  const history = useHistory()
  const urlHash = 'back-button--instant-dialog'

  useEffect(() => {
    const c = getContent({ variant, data: { astrologer, minutes } })
    // console.log({ variant, data: { astrologer, minutes }, c })
    setContent(c)
  }, [])

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.endsWith(`#${urlHash}`)
        && !e.newURL.endsWith('#back-button--wallet-recharge')
        && openDrawer === true) {
        setOpenDrawer(false)
        setTimeout(() => {
          setShowGiftIcon(true)
        }, 500)
      }
    })
  })

  useEffect(() => {
    if (open === true) {
      getRechargeOptions()
    }
  }, [open])

  const browserBackTrigger = () => {
    if (!window.location.hash.includes(`#${urlHash}`) && open === true && BrowserUtils.isNewUI()) {
      history.goBack()
    }
  }

  // Initially show the FAB
  useEffect(() => {
    if (showFAB === true) {
      getRechargeOptions()
    }
  }, [showFAB])

  useEffect(async () => {
    if (openDrawer === true) {

      window.location.hash = urlHash

      // Re-start timer if expired for showFAB
      if (expiryTimestamp && showFAB) {
        const totalSeconds = Math.round((expiryTimestamp - new Date()) / 1000)
        if (totalSeconds <= 0) {
          runTimer()
        }
      }

      // Run timer initially
      if (expiryTimestamp === false) {
        runTimer()
      }

      await BotMessage.send(chatID, 'instant-payment-open')
      // updateOpen(openDrawer)
      if (astrologer) {
        trackEventData({
          name: 'Screen View Action',
          astrologerId: astrologer.id,
          astrologerName: astrologer.name,
          variant: (content?.variant) || '',
        })
      }
    } else if (openDrawer === false) {
      await BotMessage.send(chatID, 'instant-payment-manual-close')
      onClose(false)
    }
  }, [openDrawer])

  useEffect(() => {
    //open box
    if (showGiftIcon) {
      trackEventData({
        name: 'Screen View Action',
        screenName: trackEvents.showFABIcon.screenName,
        astrologerId: astrologer?.id || 0,
        astrologerName: astrologer?.name || '',
        buttonLocation: 'FAB',
        variant: content.variant
      })
    }
  }, [showGiftIcon])

  useEffect(() => {
    if (eventPaymentData?.END_TIME) {
      console.trace('send event data', eventPaymentData)
      T2AEventTrack(eventPaymentData.event, eventPaymentData.extra)
    }
  }, [eventPaymentData.END_TIME])

  const trackEventData = (data) => {
    const payload = {
      event: {
        name: data.name,
        attributes: {
          'Screen Name': data.screenName ? data.screenName : trackEvents.default.screenName,
          'Astrologer ID': data.astrologerId,
          'Astrologer Name': data.astrologerName,
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Variant Name': data.variant,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Last pay attempt status': data.status,
          'Last pay attempt time stamp': Date.now(),
          'COT wallet balance': localStorage.getItem("walletBalance") ? parseInt(localStorage.getItem("walletBalance")) : 0,
          'Current wallet balance': localStorage.getItem("walletBalance") ? parseInt(localStorage.getItem("walletBalance")) : 0
        }
      },
      END_TIME: Date.now()
    }
    if (data.buttonName) {
      payload.event.attributes['Button Name'] = data.buttonName;
      if (data.buttonName === 'Recharge') {
        payload.event.attributes['Target Screen Name'] = 'Payment gateway';
        payload.event.attributes['Button Location'] = 'Chat Bottom';
        payload.event.attributes['Price'] = data.amount || 0;
        payload.event.attributes['Source Screen Name'] = data.buttonLocation || 'Chat Bottom';
      }
    }
    if (data.amount) {
      payload.event.attributes['SKU'] = 'COT' + data.amount;
    }
    if (data.buttonLocation) {
      payload.event.attributes['Button Location'] = data.buttonLocation;
    }

    // console.log(payload);
    setEventPaymentData(payload)
  }

  const giftIconClick = () => {
    trackEventData({
      name: 'Button Action',
      screenName: trackEvents.FABClick.screenName,
      astrologerId: astrologer?.id || 0,
      astrologerName: astrologer?.name || '',
      buttonName: 'Instant popup FAB',
      buttonLocation: 'FAB',
      variant: content.variant
    })
  }

  const getConsultationID = async (chatID) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/list")
        .then((res) => {
          if (res.data.success) {
            const consultation_id = res.data?.messages?.data?.[0]?.latest_chat.consultation_id
            if (consultation_id) {
              resolve(consultation_id)
            } else {
              reject(res)
            }
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const getListOffers = async (chatID) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/wallet/recharge/list-offers", {
          astrologer_id: astrologer.id,
          chatID
        })
        .then((res) => {
          if (res && res.data.success && res.data) {
            resolve(res.data)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const runTimer = () => {
    console.log('runtimer')
    const time = new Date()
    const totalSeconds = time.getSeconds() + (minutes * 60)
    time.setSeconds(totalSeconds)
    setExpiryTimestamp(time)
  }

  const getRechargeOptions = async () => {
    // const consultation_id = await getConsultationID()
    const offers = await getListOffers(chatID)
    if (offers.success && offers.discount) {
      const options = []
      const minutes = [5, 15, 30]
      let noValidAmountCount = 0
      minutes.map(minute => {
        let discount = 0
        if (offers.discount?.[minute]) {
          discount = offers.discount?.[minute]
        }
        // console.log(offers, offers.discount, offers.discount?.[0], offers.discount?.[0]?.[minute], minute, discount)
        const price_equation = offers.price_equation.replace('MINUTE', minute).replace('DISCOUNT', discount)
        const after_discount_equation = offers.after_discount_equation.replace('MINUTE', minute).replace('DISCOUNT', discount)
        const after_discount_gst_equation = offers.after_discount_gst_equation.replace('MINUTE', minute).replace('DISCOUNT', discount)
        const actualAmount = Math.round(eval(price_equation))
        const amount = Math.round(eval(after_discount_equation))
        const payableAmount = Math.round(eval(after_discount_gst_equation))
        options.push({
          minute,
          amount,
          gst: true,
          discount: discount === 0 ? '' : discount,
          actualAmount,
          info: `${minute} minutes more`,
          payableAmount: actualAmount,
          // offers
        })
        if (amount <= 0) {
          noValidAmountCount++
        }
      })

      console.log('options:', options)
      if (options.length !== noValidAmountCount) {

        const limitOptions = options.filter(o => o.amount > 0)
        limitOptions.length = 2
        setRechargeOptions(limitOptions)

        if (showFAB) {
          setOpenDrawer(false)
          setShowGiftIcon(true)
        } else {
          setOpenDrawer(true)
          setShowGiftIcon(false)
        }
      } else {
        const failed = {
          reason: 'no-valid-amount',
          options
        }
        console.warn(failed)
        onFailedRechargeOptions(failed)
      }
    }
  }

  const onCloseDrawer = () => {
    setOpenDrawer(false)
    // browserBackTrigger()
    history.goBack()
    if (astrologer) {
      trackEventData({
        name: 'Button Action',
        astrologerId: astrologer.id,
        astrologerName: astrologer.name,
        variant: (content?.variant) || '',
        buttonName: 'Close'
      })
    }

    setTimeout(() => {
      setShowGiftIcon(true)
    }, 500)
  }

  // Timer expire
  const onTimerExpire = async () => {
    await BotMessage.send(chatID, 'instant-payment-timeout')
    //auto-close after 3 min
    if (astrologer) {
      trackEventData({
        name: 'Button Action',
        astrologerId: astrologer.id,
        astrologerName: astrologer.name,
        variant: (content?.variant) || '',
        buttonName: 'Auto Close'
      })
    }

    // 
    if (showFAB) {
      setShowGiftIcon(true)
    }
    setOpenDrawer(false)
  }

  const rechargeClick = async (e, rechargeRefData) => {
    await BotMessage.send(chatID, 'instant-payment-recharge-click')
    console.log('recharge click event', rechargeRefData)
    if (rechargeRefData) {
      trackEventData({
        name: 'Buy Now Action',
        buttonName: 'Recharge',
        astrologerId: rechargeRefData.astrologerID,
        astrologerName: rechargeRefData.astrologerName,
        amount: rechargeRefData.amount,
        variant: (content?.variant) || '',
        buttonLocation: rechargeRefData?.location || 'Chat Bottom'
      })
    }
    e.goToWallet()
  }

  return <>
    <div className={clsx('instant-popup-btn-wrap', showGiftIcon === true ? 'show' : '')} style={styleFAB}>
      <div className="btn-hold">
        <IconButton
          onClick={() => {
            setShowGiftIcon(false)
            setOpenDrawer(true)
            giftIconClick()
          }}
          className="btn-instant-toggler">
          <GiftIcon />
        </IconButton>
        <div className="text">Offer</div>
      </div>
    </div>
    <Drawer className="instant-popup" anchor={anchor} open={openDrawer} onClose={onCloseDrawer}>
      {(content.title && astrologer.id) &&
        <div className="card-v2">
          <div className="card-v2__header">
            {showAstrologerPhoto ?
              <div className="card-v2__header_pic">
                <AstrologerAvatar
                  astrologer={astrologer}
                  className="card-v2__header_pic"
                />
              </div>
              :
              null
            }
            <div className="card-v2__header_title">
              {content.title}
            </div>
            <div className="card-v2__header_close">
              <IconButton
                onClick={onCloseDrawer}
              >
                <Close />
              </IconButton>
            </div>
          </div>
          <div className="card-v2__content">
            <div className="card-v2__content_info">
              {content.content}
            </div>
            <div className="card-v2__content_timer">
              <div className="count-down-timer">
                {content.timerBefore && <div className="count-down-timer__before">{content.timerBefore}</div>}
                <div className="count-down-timer__timer">
                  <CountDownTimer autoStart={false} expiryTimestamp={expiryTimestamp} onExpire={onTimerExpire} />
                </div>
                {content.timerAfter && <div className="count-down-timer__after">{content.timerAfter}</div>}
              </div>
            </div>
            <div className="card-v2__options">
              <div className="card-v2__options_before">{content.optionText}</div>
              <div className="card-v2__options_option recharge-option">
                {rechargeOptions.map(recharge =>
                  <RechargeOptionItem
                    key={recharge.amount}
                    {...recharge}
                    rechargeRefData={{
                      type: 'chat',
                      instantPayment: true,
                      refID: (trackEvents.FABClick.screenName === 'Astrologer profile') ? 'chat/instant-payment-popup-astrologer' : 'chat/instant-payment-popup',
                      location: (trackEvents.FABClick.screenName === 'Astrologer profile') ? 'Instant popup astrologer' : 'Chat Bottom',
                      astrologerID: astrologer.id,
                      astrologerName: astrologer.name,
                      astrologerProfileURL: astrologer.profile_url || astrologer.profileUrl,
                      astrologerCharge: astrologer?.perMinutePrice || 0,
                      amount: recharge.payableAmount,
                      minute: recharge.minute,
                      recharge,
                      chatID
                    }}
                    rechargeClick={
                      (e) => {
                        rechargeClick(e, {
                          astrologerID: astrologer.id,
                          astrologerName: astrologer.name,
                          amount: recharge.payableAmount,
                          location: (trackEvents.FABClick.screenName === 'Astrologer profile') ? 'Instant popup astrologer' : 'Chat Bottom'
                        })
                      }
                    }
                  />
                )}
              </div>
            </div>
            <div className="card-v2__before_footer">
              * Unused points will be added to your wallet
            </div>
          </div>
        </div>
      }
    </Drawer>
  </>

}

export default InstantPaymentPopup