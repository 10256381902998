import React from "react";

import miscScreensData from "../mock/MiscScreensData.mock";

import Grid from "@material-ui/core/Grid";

export default function SignBox(props) {
  const classes = props.classes;

  const onSignClick = (val) => {
    if (props.pathname === "/daily-updates" || props.pathname === "/daily-updates/")
      window.location.href = "/daily-updates/" + val;
    else if (props.pathname === "/weekly-updates" || props.pathname === "/weekly-updates/")
      window.location.href = "/weekly-updates/" + val;
  };

  return (
    <Grid container>
      {miscScreensData.signData.map((sign) => (
        <Grid
          key={sign.id}
          item
          xs={4}
          className={classes.starSignGrid}
          onClick={() => {
            onSignClick(sign.sign);
          }}>
          <div className={classes.signImage}>{sign.letter}</div>
          <div className={classes.starSignText}>{sign.sign}</div>
          <div className={classes.starSignPeriod}>{sign.period}</div>
        </Grid>
      ))}
    </Grid>
  );
}
