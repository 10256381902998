
import React, { useEffect, useState } from "react"
import { CheckCircle } from "@material-ui/icons"
import { Button, TextField, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import userActionData from "../../../../user-actions/mock/UserActionData.mock"
import userProfileData from "../../../../user-profile/mock/UserProfileData.mock"
import axios_noheader from "../../../../../common/interceptor_noheader"
import BrowserUtils from "../UAScripts"
import axios from "../../../../../common/interceptor"
import domtoimage from "dom-to-image"
import CountDownTimer from "../CountDownTimer"


const useStyles = makeStyles({
  container: {
    padding: '1rem',
    color: '#2f3148',
    '& p': {
      color: '#000',
    }
  },
  checkCircle: {
    color: 'var(--clr-green)',
    marginBottom: '-7px',
    marginLeft: '5px',
  },
  title: {
    marginTop: 0,
    marginBottom: '5px',
    fontSize: '16px',
  },
  form: {
    display: 'flex',
    gap: '.5rem',
    maxWidth: '300px',
    margin: 'auto',
    marginTop: '1rem',
    '& .MuiInputBase-root': {
      // padding: '.5rem .75rem',
      border: 0,
      borderRadius: '.75rem',
      height: '3rem',
      backgroundColor: '#fff',
      '& .MuiInputBase-input': {
        fontSize: '.85rem',
      },
      '&:focus': {
        outline: '1px solid var(--primary)',
      }
    },
    '& .MuiButtonBase-root': {
      backgroundColor: 'var(--clr-green)',
      color: '#fff',
      height: '3rem',
      borderRadius: '.75rem',
      minWidth: '6rem',
    },
    '& .MuiButtonBase-root.otpLink': {
      background: 'transparent',
      padding: 0,
      height: 'auto',
      lineHeight: 'normal',
      marginTop: '10px',
      fontSize: '12px',
      color: 'rgb(24, 29, 188)',
      fontWeight: 'bold',
      border: 0,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      margin: 0,
      marginTop: 10
    }
  },
  otpInfoText: {
    color: 'red'
  },
  otpLink: {
  }
})

const defaultError = { error: false, message: '' }
const otpSecond = 20
let timer = null

const VerifyNumberComponent = ({ onVerify, events }) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [otp, setOTP] = useState('');
  const [otpSent, setOTPSent] = useState(false)
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false)
  // const [timer, setTimer] = useState(60)
  const classes = useStyles()
  const [mobile, setMobile] = useState('')
  const [error, setError] = useState({ mobile: defaultError, otp: defaultError })
  const [stage, setStage] = useState('before-otp-send')
  const [second, setSecond] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [screenshot, setScreenshot] = useState('')
  const [otpSentAPI, setOtpSentAPI] = useState(false)
  const [otpID, setOtpID] = useState('')
  const [resentCount, setResentCount] = useState(0)
  const [expiryTimestamp, setExpiryTimestamp] = useState(false)

  useEffect(() => {
    events.scrollToBottom()
  }, [])

  useEffect(() => {
    setTimeout(events.scrollToBottom, 150)
  }, [stage, error])

  const handleGetOtpClick = () => {
    onSendOTP_API('phone')
    setResentCount(0)
  };

  const handleVerifyOtp = () => {
    takeScreenshot()
  }

  const takeScreenshot = () => {
    setError({ ...error, otp: defaultError })
    setOtpSentAPI(false)
    if (!(otp && otp.length === 6)) {
      setError({ ...error, otp: { error: true, message: 'Please enter the OTP' } })
    } else {
      const eData = {
        name: 'Button Action',
        screenName: 'Chat or Talk Mobile verification',
        buttonName: 'Continue'
      }
      trackeventData(eData)
      const node = document.getElementById("wrapperOTP")
      domtoimage
        .toPng(node)
        .then((dataUrl) => {
          setScreenshot(dataUrl)
        })
        .catch(function (error) {
          // sentryError("OTP_SCREENSHOT", error);
          setScreenshot('')
        })
      onVerifyAPI()
    }
  }

  const onlyNumbers = (e) => {
    var validChars = userActionData.validChars
    if (!validChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const updateNumber = (e) => {
    setMobile(e.target.value)
  }

  const runTimer = (s) => {
    // clearTimeout(timer)
    // if (s >= 0 && s <= otpSecond) {
    //   setSecond(s)
    //   timer = setTimeout(() => { runTimer(s - 1) }, 1000)
    // }

    const time = new Date()
    const totalSeconds = time.getSeconds() + (otpSecond)
    setSecond(otpSecond)
    time.setSeconds(totalSeconds)
    setExpiryTimestamp(time)
  }

  const trackeventData = () => {

  }

  const onSendOTP_API = () => {
    setLoading(false)
    setOTP('')
    setError({ otp: defaultError, mobile: defaultError })
    if (mobile.length !== 10) {
      // setSnackBar({ message: 'Please enter a valid phone number', open: true })
      setError({ ...error, mobile: { error: true, message: 'Please enter a valid phone number' } })
    }
    // else if (this.state.code === "") {
    //   setSnackBar({ message: 'Please select a country code', open: true })
    // } 
    else {

      const eData = {
        name: 'Screen View Action',
        screenName: 'Chat or Talk OTP Screen',
        sourceScreenName: 'Verify Number'
      }
      trackeventData(eData)

      setError({ ...error, mobile: defaultError })
      setLoading(true)
      setError({ ...error, otp: defaultError })
      axios_noheader()
        .post("/user/get-otp", {
          phone: mobile,
          code: "+91",
          slide: null,
        })
        .then((res) => {
          setLoading(false)
          if (res.data.data.success) {
            setOtpID(res.data.data.id)
            setStage('otp-send')
            setOTPSent(true)

            if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
              window.AndroidBridge.call("onScreenLoaded");
            }

            runTimer(otpSecond)
            sessionStorage.setItem("sessionLoginData", JSON.stringify(res.data.data))
          } else {
            setError({ ...error, mobile: { error: true, message: res?.data?.data?.message || 'Error occurred, Please try again' } })
          }
        })
        .catch((err) => {
          // sentryError("LOGIN", err);
          setLoading(false)
          setError({ ...error, mobile: { error: true, message: 'Error occurred, Please try again' } })
        })
    }
  }

  const getAndroidProfileData = () => {
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      const profileData = window.AndroidBridge.call("getAppProfileData")
      let userProfile = {};
      try {
        userProfile = JSON.parse(profileData)
      } catch (e) {
        console.log(profileData);
      }
      return userProfile;
    }
    return false;
  }

  const onVerifyAPI = (apiType = 'login') => {
    sessionStorage.removeItem("sessionLoginData")
    setLoading(true)
    setError({ ...error, otp: defaultError })
    if (!(otp && otp.length === 6)) {
      setError({ ...error, otp: { error: true, message: 'Please enter the OTP' } })
    } else {
      // alert('Verified')
      // onClose()
      const postData = {
        phone: mobile,
        otp: otp,
        is_trai: true,
        is_whatsapp: true,
        data_dom: screenshot,
      }

      if (apiType === 'register') {
        //postData from android login
        const profileData = getAndroidProfileData();
        if (profileData) {
          let userprofileData = {
            name: profileData.name,
            relation: profileData?.relation || null,
            email: profileData.email,
            phone: mobile,
            gender: profileData.gender,
            placeOfBirth: profileData.place_name,
            dateOfBirth: profileData?.dob.replaceAll('-', '/'),
            timeOfBirth: '10:00 AM',
            horoscopeStyle: 'North Indian',
            profileUrl: null,
            country: profileData?.country || '',
            lat_deg: profileData.latitude_deg,
            lat_min: profileData.latitude_min,
            lat_dir: profileData.lat_dir,
            long_deg: profileData.longitude_deg,
            long_min: profileData.longitude_min,
            long_dir: profileData.long_dir,
            timeCorrectionValue: profileData?.timeCorrectionValue,
            timeCorrection: profileData?.timeCorrection,
            time_zone: profileData?.time_zone,
            app_user_id: profileData?.userId || 0
          }
          if (profileData?.chartStyle) {
            if (userProfileData.horoscopeStyle[profileData.chartStyle]) {
              userprofileData.horoscopeStyle = userProfileData.horoscopeStyle[profileData.chartStyle]
            }
          } else {
            userprofileData.horoscopeStyle = userProfileData.horoscopeStyle[0]
          }

          if (profileData?.tob) {
            userprofileData.timeOfBirth = new Date('1970-01-01T' + profileData.tob + 'Z')
              .toLocaleTimeString('en-US',
                { timeZone: 'UTC', hour12: true, hour: '2-digit', minute: '2-digit' }
              )
          }

          postData.userProfile = userprofileData;
        }
      }

      // TODO: check the api url & what is ID?
      axios()
        .post(`/user/otp/${apiType}/${otpID}`, postData)
        .then((res) => {
          setLoading(false)
          // console.log({
          //   path: `/user/otp/${apiType}/${otpID}`,
          //   res
          // })
          if (res.data.success) {

            if (res.data.userProfile.phone && BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
              window.AndroidBridge.call("pushUserPhone", res.data.userProfile.phone);
            }
            if (res.data.userProfile.id && window.smartlook) {
              window.smartlook('identify', res.data.userProfile.id)
            }

            // TODO: check this trackActions
            // if (this.props.location.state.doRegister) {
            //   this.trackActions("fa_wallet_credit", "NA", 75); //free 75 wallet credit
            // }

            localStorage.setItem("userProfileId", res.data.userProfile.id);
            localStorage.setItem("utkn", res.data.token);
            localStorage.setItem("btkn", res.data.bearer);
            localStorage.setItem("name", res.data.userProfile.name);
            localStorage.setItem("email", res.data.userProfile.email);
            localStorage.setItem("phone", res.data.userProfile.phone);
            localStorage.setItem("image", res.data.userProfile.profileUrl);
            localStorage.setItem("walletBalance", res.data.userProfile.balance ? res.data.userProfile.balance : "0");

            const eData = {
              name: 'Login Action',
              screenName: 'Chat or Talk Mobile verification',
              mobVerification: 'Success'
            }
            trackeventData(eData)

            // TODO: Moengage
            // if (res.data.isLoginElapse) {
            //   if (window.Moengage) {
            //     window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            //   }
            //   history.replace("/app-home")
            // } else if (this.props.location.state.doRegister) {
            //   history.replace("/language-select?register=1");
            // } else if (res.data.isRegister) {
            //   history.replace("/language-select?register=1");
            // } else if (this.props.location.state.historyData) {
            //   if (window.Moengage) {
            //     window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            //   }
            //   window.location.href = decodeURIComponent(this.props.location.state.historyData);
            // } else {
            //   if (window.Moengage) {
            //     window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            //   }
            //   this.props.history.replace("/");
            // }

            //remove local crj if logged in
            localStorage.removeItem("userCRJ");
            localStorage.removeItem("sunsignData");
            localStorage.removeItem("moonsignData");
            localStorage.removeItem("dailyPrediciton");

            if (apiType === 'register')
              localStorage.setItem("showLangPrefBnr", 1);
            else if (localStorage.getItem("showLangPrefBnr"))
              localStorage.removeItem("showLangPrefBnr");

            //remove local crj if logged in
            // this.trackActions("fa_otp_success", "NA");
            // this.trackActions("fa_registration_success", "NA");

            // Update profile using getAppProfileData
            updateProfileData({ ...res.data.userProfile, "apiType": apiType })
          }
          else if (res?.data?.isRegister) {
            onVerifyAPI('register')
          }
          else {
            setError({ ...error, otp: { error: true, message: res.data.message || 'Something went wrong' } })

            const eData = {
              name: 'Login Action',
              screenName: 'Chat or Talk Mobile verification',
              mobVerification: 'Failed'
            }
            trackeventData(eData)


            // if (!this.props.location.state.doRegister && res.data.isRegister) {
            //   let that = this;
            //   window.setTimeout(function () {
            //     window.location.href = "/register?phone=" + that.state.phone;
            //   }, 2000);
            // }
          }
        })
        .catch((err) => {
          setLoading(false)
          // sentryError("OTP_SUBMIT", err)
          setError({ ...error, otp: { error: true, message: 'Something went wrong' } })
          const eData = {
            name: 'Login Action',
            screenName: 'Chat or Talk Mobile verification',
            mobVerification: 'Failed'
          }
          trackeventData(eData)

        })
    }
  }

  const updateProfileData = (userProfile) => {
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      const profileData = window.AndroidBridge.call("getAppProfileData")
      const eData = {
        name: 'Test EventTA',
        screenName: 'OTP Login',
        targetName: 'Profile data send to COT',
        Devdata: 'NA',
        phoneNumber: userProfile?.phone || ''
      }
      if (profileData) {
        eData.Devdata = profileData;
      }
      trackeventData(eData);
      if (profileData) {
        BrowserUtils.devAlert('AndroidBridge.getAppProfileData: ' + profileData)
        sendProfileData(userProfile, JSON.parse(profileData))
        return true
      }
    }
    setLoading(false)

    setPhoneNumberVerified(true)
    onVerify(userProfile)
    // onClose()
    return false
  }

  const sendProfileData = (profile, details) => {
    setLoading(true)
    console.log('sendProfileData:profile, details', profile, details)

    /*
      profile data sample

      balance: "75",
      email: "test@test.com",
      name: "Test",
      phone: "9388820459",
      profileUrl: null
    */

    if (details?.name) {
      // Allow only characters & spaces
      details.name.replace(/([a-zA-Z ])/g, "")
        .split('')
        .map(k => {
          details.name = details.name.replaceAll(k, '')
          return k
        })
    } else {
      details.name = " "
    }

    // Date of birth
    if (details?.dob) {
      details.dob = details?.dob.replaceAll('-', '/')
    }

    // Email
    if (!details?.email && profile?.email) {
      details.email = profile.email
    }

    // Phone
    if (!details?.phone && profile?.phone) {
      details.phone = profile.phone
    }

    // Time of birth
    if (details?.tob) {
      details.tob = new Date('1970-01-01T' + details.tob + 'Z')
        .toLocaleTimeString('en-US',
          { timeZone: 'UTC', hour12: true, hour: '2-digit', minute: '2-digit' }
        )
    }

    // Latitude
    if (details?.latitude_deg) {
      details.latitude_deg = details.latitude_deg
      // if (details?.latitude_min) {
      //   details.latitude_deg = `${details.latitude_deg}.${details.latitude_min}`
      // }
    }

    // Longitude
    if (details?.longitude_deg) {
      details.longitude_deg = details.longitude_deg
      // if (details?.longitude_min) {
      //   details.longitude_deg = `${details.longitude_deg}.${details.longitude_min}`
      // }
    }

    // Profile url
    if (!details?.profileUrl && profile?.profileUrl) {
      details.profileUrl = profile?.profileUrl
    }

    // ChartStyle
    if (details?.chartStyle) {
      if (userProfileData.horoscopeStyle[details.chartStyle]) {
        details.chartStyle = userProfileData.horoscopeStyle[details.chartStyle]
      }
    } else {
      details.chartStyle = userProfileData.horoscopeStyle[0]
    }

    setError({ ...error, otp: defaultError })
    const postData = {
      name: details?.name, // Test
      relation: details?.relation || '', // BLANK
      gender: details?.gender, // Male
      email: details?.email, // test@test.com
      phone: details?.phone, // 9388820459
      horoscopeStyle: details?.chartStyle, // South Indian
      dateOfBirth: details?.dob, // 20/06/1992 (DD/MM/YYYY)
      placeOfBirth: details?.place_name, // Kochi, Kerala, India
      timeOfBirth: details?.tob, // 11:50 AM
      profileUrl: details?.profileUrl || null,
      lat: details?.latitude_deg + '.' + details?.latitude_min,
      long: details?.longitude_deg + '.' + details?.longitude_min,
      lat_deg: details?.latitude_deg,
      lat_min: details?.latitude_min,
      lat_dir: details?.lat_dir,
      long_deg: details?.longitude_deg,
      long_min: details?.longitude_min,
      long_dir: details?.long_dir,
      country: details?.country || 'India',
      app_user_id: details?.userId,
      app_language: details?.appLanguage,
      api_type: profile.apiType,
    }

    BrowserUtils.devAlert('updateProfile:postData: ' + JSON.stringify(postData))
    axios()
      .post("/profile/user/edit", postData)
      .then((res) => {
        BrowserUtils.devAlert('updateProfile:/profile/user/edit:result: ' + JSON.stringify(res))
        if (res.data.success) {
          // if (pageType === "userProfile") {

          // if (BrowserUtils.isAWebview) {
          //   if (typeof window.AndroidBridge !== "undefined") {
          //     //let myVar = window.AndroidBridge.call("getAndroidVersion");
          //     window.AndroidBridge.call(
          //       "pushUserProfileDetails",
          //       JSON.stringify({
          //         name: details.name,
          //         relation: details.relation,
          //         gender: details.gender,
          //         email: details.email,
          //         phone: details.phone,
          //         horoscopeStyle: details.horoscopeStyle,
          //         dateOfBirth: tempDate,
          //         placeOfBirth: query,
          //         timeOfBirth: details.timeOfBirth,
          //         profileUrl: data ? data : details.profileUrl ? details.profileUrl : "",
          //         lat: details.lat,
          //         long: details.long,
          //         country: details.country,
          //       })
          //     );
          //   }
          // } else {
          //   if (window.Moengage) {
          //     window.Moengage.add_unique_user_id(details.phone);
          //     window.Moengage.add_first_name(details.name);
          //     window.Moengage.add_user_name(details.name);
          //     window.Moengage.add_email(details.email);
          //     window.Moengage.add_mobile(Number(details.phone));
          //     window.Moengage.add_gender(details.gender);
          //     window.Moengage.add_user_attribute("MOBILE_NUMBER", Number(details.phone));
          //     window.Moengage.add_user_attribute("DATE_OF_BIRTH", tempDate);
          //     window.Moengage.add_user_attribute("PLACE_OF_BIRTH", query);
          //     window.Moengage.add_user_attribute("HOROSCOPE_STYLE", details.horoscopeStyle);
          //     window.Moengage.add_user_attribute("TIME_OF_BIRTH", details.timeOfBirth);
          //   }
          // }

          localStorage.setItem("name", details?.name);
          localStorage.setItem("email", details?.email);
          localStorage.setItem("phone", details?.phone);
          localStorage.setItem("image", details?.profileUrl);
          if (postData.app_user_id) {
            localStorage.setItem('userid', postData.app_user_id);
          }
          //remove local crj if logged in
          localStorage.removeItem("userCRJ");
          localStorage.removeItem("sunsignData");
          localStorage.removeItem("moonsignData");
          localStorage.removeItem("dailyPrediciton");
          //remove local crj if logged in
          // }

          // Callback to Free consultation
          setPhoneNumberVerified(true)
          onVerify(profile)

          // window.setTimeout(() => {
          //   moengage("USERPROFILE_CREATED");
          //   if (props.location.search !== "") {
          //     let redirectPath = QueryString().pathname;
          //     if (redirectPath === "/my-account") {
          //       props.history.replace("/my-account");
          //       //window.location.href = "/my-account";
          //     } else if (redirectPath === "/my-wallet") {
          //       props.history.replace("/my-wallet");
          //       //window.location.href = "/my-wallet";
          //     } else if (redirectPath === "/") {
          //       props.history.replace("/");
          //       //window.location.href = "/";
          //     } else if (redirectPath && redirectPath.indexOf("/astrologer") >= 0) {
          //       props.history.replace(redirectPath);
          //       //window.location.href = redirectPath;
          //     } else {
          //       window.location.href = "/";
          //     }
          //   } else {
          //     //props.history.replace("/");
          //     window.location.href = "/";
          //   }
          // }, 1000);
          // refreshHome();
        } else {
          console.log('edit error', res)
          setLoading(false)
          // setError({ ...error, otp: { error: true, message: res.data.message || 'Something went wrong' } })
        }
        setLoading(false)
        // setRechargeDirect()
        // onClose()
      })
      .catch((err) => {
        console.log('catch error', err)
        setLoading(false)
        // onClose()
        // setError({ ...error, otp: { error: true, message: 'Please try again' } })
        // sentryError("USER_PROFILE_SUBMIT_DETAILS", err);
      });
  };

  const resendOtp = (type) => {
    const eData = {
      name: 'Button Action',
      screenName: 'Chat or Talk Mobile verification',
      buttonName: 'Continue'
    }
    trackeventData(eData);
    if (type === 'whatsapp') {
      setResentCount(1)
    } else {
      setResentCount(2)
    }
    onSendOTP_API(type)
  }

  return (
    <div className={clsx(
      'chat-message meta verify-number-component',
      phoneNumberVerified ? 'hidden' : ''
    )}>

      {phoneNumberVerified ?
        <></>
        :
        <div className={classes.container}>
          <h2 className={classes.title}>Verify phone number to continue</h2>
          <p>Please verify your phone number to proceed. <br />
            It ensures security, and unlocks free consultations on Clickastro.
          </p>
          {!otpSent ? (
            <div className={clsx('phone-number-form', classes.form)}>
              <TextField
                error={error.mobile.error}
                helperText={error.mobile.message}
                value={mobile}
                type="tel"
                variant="outlined"
                placeholder="Enter phone number"
                // phone={this.state.phone}
                inputProps={{ maxLength: 10 }}
                onKeyDown={onlyNumbers}
                onChange={(e) => updateNumber(e)}
                id="verifyOTPPhoneNumberInput"
              />
              <div className="action">
                <Button onClick={handleGetOtpClick}>Get OTP</Button>
              </div>
            </div>
          ) : (
            <div className={clsx('otp-form', classes.form)}>
              <TextField
                value={otp}
                error={error.otp.error}
                helperText={error.otp.message}
                autoFocus
                id="loginOTPInput"
                type="tel"
                variant="outlined"
                placeholder="000000"
                inputProps={{ maxLength: 6 }}
                onKeyDown={onlyNumbers}
                onChange={(e) => setOTP(e.target.value)}
              />
              <div className="action">
                <Button onClick={handleVerifyOtp}>Verify</Button>
                <p>
                  {/* {second > 0 && resentCount < 2 && <span className={classes.otpInfoText}>Resend in {second}s</span>} */}
                  {second > 0 && resentCount < 2 && <span className={classes.otpInfoText}>Resend in <CountDownTimer
                    className="timer"
                    autoStart={false}
                    expiryTimestamp={expiryTimestamp}
                    onExpire={() => setSecond(0)}
                  /></span>}
                  {second === 0 && resentCount == 1 && <Button variant="text" className="otpLink" onClick={() => {
                    resendOtp('phone')
                  }}>Resend OTP via SMS</Button>}
                  {second === 0 && resentCount == 0 && <Button variant="text" className="otpLink" onClick={() => {
                    resendOtp('whatsapp')
                  }}>Resend OTP via Whatsapp</Button>}</p>

              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default VerifyNumberComponent;