import React from "react";

import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import {FacebookShareButton, FacebookIcon} from "react-share";
import {WhatsappShareButton, WhatsappIcon} from "react-share";
import {TwitterShareButton, TwitterIcon} from "react-share";
import {TelegramShareButton, TelegramIcon} from "react-share";
import {TumblrShareButton, TumblrIcon} from "react-share";
import FileCopyIcon from "@material-ui/icons/AssignmentReturned";
import {LinkedinShareButton, LinkedinIcon} from "react-share";
import {EmailShareButton, EmailIcon} from "react-share";

const ShareDrawerComponent = (props) => {
  const onCopy = () => {
    navigator.clipboard.writeText(props.profileUrl);
    props.showError("Copied to clipboard");
  };

  return (
    <Drawer
      id="shareBox"
      anchor="bottom"
      style={{transform: "translateZ(10px);"}}
      open={props.open}
      onClose={() => {
        props.onDrawerClose();
      }}>
      <div id="shareParentDiv">
        <div id="shareviaText">Share via</div>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <WhatsappShareButton url={props.profileUrl} title={props.title} separator=" ">
              <WhatsappIcon size={36} className="socialMediaButton" />
              <div className="socialMediaText">Whatsapp</div>
            </WhatsappShareButton>
          </Grid>
          <Grid item xs={3}>
            <FacebookShareButton url={props.profileUrl} quote={props.title} hashtag="#findastro">
              <FacebookIcon size={36} className="socialMediaButton" />
              <div className="socialMediaText">Facebook</div>
            </FacebookShareButton>
          </Grid>
          <Grid item xs={3}>
            <TwitterShareButton url={props.profileUrl} title={props.title} hashtag="#findastro">
              <TwitterIcon size={36} className="socialMediaButton" />
              <div className="socialMediaText">Twitter</div>
            </TwitterShareButton>
          </Grid>
          <Grid item xs={3}>
            <TelegramShareButton url={props.profileUrl} title={props.title}>
              <TelegramIcon size={36} className="socialMediaButton" />
              <div className="socialMediaText">Telegram</div>
            </TelegramShareButton>
          </Grid>
          <Grid item xs={3}>
            <TumblrShareButton url={props.profileUrl} title={props.title} caption="#findastro">
              <TumblrIcon size={36} className="socialMediaButton" />
              <div className="socialMediaText">Tumblr</div>
            </TumblrShareButton>
          </Grid>
          <Grid item xs={3}>
            <LinkedinShareButton url={props.profileUrl} title={props.title} summary="#findastro">
              <LinkedinIcon size={36} className="socialMediaButton" />
              <div className="socialMediaText">LinkedIn</div>
            </LinkedinShareButton>
          </Grid>
          <Grid item xs={3}>
            <EmailShareButton
              url={props.profileUrl}
              subject="Findastro"
              body={props.title + "#findastro"}
              separator=" ">
              <EmailIcon size={36} className="socialMediaButton" />
              <div className="socialMediaText">Email</div>
            </EmailShareButton>
          </Grid>
          <Grid item xs={3}>
            <FileCopyIcon size={36} className="shareButtonCopyURL" onClick={onCopy} />
            <div className="socialMediaText">Copy URL</div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};
export default ShareDrawerComponent;
