import React, {Component} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import QueryString from "../../../common/util/QueryString";

export default class AppLogin extends Component {
  componentDidMount = () => {
    const filterParams = QueryString();
    if (filterParams.token && filterParams.token !== "" && filterParams.phone && filterParams.phone !== "") {
      axios()
        .post("/user/app-login", {
          phone: filterParams.phone,
          email: filterParams.email,
          token: filterParams.token,
        })
        .then((res) => {
          if (res.data.success) {
            localStorage.setItem("utkn", res.data.token);
            localStorage.setItem("btkn", res.data.bearer);
            localStorage.setItem("name", res.data.userProfile.name);
            localStorage.setItem("email", res.data.userProfile.email);
            localStorage.setItem("phone", res.data.userProfile.phone);
            localStorage.setItem("image", res.data.userProfile.profileUrl);
            localStorage.setItem("old_user", true);
            localStorage.setItem("walletBalance", res.data.userProfile.balance ? res.data.userProfile.balance : "0");
            window.location.href = "/";
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          sentryError("APP_LOGIN", err);
        });
    } else {
      window.location.href = "/";
    }
  };

  render() {
    return (
      <>
        <span>Loading...</span>
      </>
    );
  }
}
