import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import axios from "../../../common/interceptor";
import axios_noheader from "../../../common/interceptor_noheader";
import sentryError from "../../../common/sentryError";

import HelmetComponent from "../../../common/HelmetComponent.component";
import ShareDrawerComponent from "../../user/astrologer/components/ShareDrawer.component";
import Snackbar from "../../../common/SnackBarMessage";
import makeStyles from "./makeStyles.js";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import { ReactComponent as GPIcon } from "../../../common/googleplayicon.svg";
import { ReactComponent as TYBackdrop } from "../../../common/tybackdrop.svg";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import FA_localStorage from "../../../common/util/FA_localStorage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

const styles = (theme) => ({
  ...makeStyles,
  rateusModalButton: {
    backgroundColor: "#01c180 !important",
    margin: "30px 0px 15px 0px !important",
    color: "white !important",
  },
  rateusCloseIcon: {
    position: "relative",
    left: "45%",
    marginBottom: "10px",
  },
  rateusModalSkip: {
    fontSize: "14px",
  },
  rateusModal: {
    borderRadius: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fbead0",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    padding: "30px 20px",
    maxWidth: "450px",
    width: "90%",
    margin: "auto",
    textAlign: "center",
    outline: "none",
  },
  sidebarScreensArrowIcon: {
    marginTop: "24px",
    marginRight: "8px",
  },
});
class RateUs extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    rating: localStorage.getItem("r_us") ? localStorage.getItem("r_us") : 0,
    commentText: "",
    isLoading: false,
    ackMessage: "",
    modelOpen: false,
    shareModalOpen: false,
    isReviewSubmitted: localStorage.getItem("r_us") ? true : false,
    disabledReSubmit: false,
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onRating = (e) => {
    if (!this.state.isReviewSubmitted || this.state.disabledReSubmit === false) {
      this.setState({ rating: e.target.value });
    }
  };

  onComment = (e) => {
    if (!this.state.isReviewSubmitted || this.state.disabledReSubmit === false) {
      this.setState({ commentText: e.target.value });
    }
  };

  onName = (e) => {
    if (!this.state.isReviewSubmitted || this.state.disabledReSubmit === false) {
      this.setState({ commentName: e.target.value });
    }
  };
  onPlace = (e) => {
    if (!this.state.isReviewSubmitted || this.state.disabledReSubmit === false) {
      this.setState({ commentPlace: e.target.value });
    }
  };

  onSubmit = () => {
    if (!this.state.rating) {
      this.showError("Please submit a rating for Findastro");
    } else {
      this.setState({ isLoading: true });
      if (
        !localStorage.getItem("btkn") ||
        localStorage.getItem("btkn") === "" ||
        localStorage.getItem("btkn") === "undefined" ||
        !localStorage.getItem("utkn") ||
        localStorage.getItem("utkn") === "" ||
        localStorage.getItem("utkn") === "undefined"
      ) {
        axios_noheader()
          .post("rate-us", {
            comments: [this.state.commentText, this.state.commentName, this.state.commentPlace, "NA", "NA"].join("||"),
            rating: this.state.rating,
          })
          .then((res) => {
            if (res.data.success) {
              this.setRating(this.state.rating);
            } else {
              this.showError(res.data.message);
            }
            this.setState({ isLoading: false });
            /*re confirm google play review*/
            FA_localStorage("GPRating", "remove");
            if (this.state.rating > 3) this.setState({ modelOpen: true });
            else this.showError("Thank you for sharing your story");
          })
          .catch((err) => {
            sentryError("r_us", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false });
          });
      } else {
        const phone = FA_localStorage("phone");
        const email = FA_localStorage("email");
        axios()
          .post("rate-us-user", {
            comments: [this.state.commentText, this.state.commentName, this.state.commentPlace, phone, email].join(
              "||"
            ),
            rating: this.state.rating,
          })
          .then((res) => {
            if (res.data.success) {
              this.setRating(this.state.rating);
              /*re confirm google play review*/
              FA_localStorage("GPRating", "remove");
              if (this.state.rating > 3) this.setState({ modelOpen: true });
              else this.showError("Thank you for sharing your story");
            } else {
              this.showError(res.data.message);
            }
            this.setState({ isLoading: false });
          })
          .catch((err) => {
            sentryError("r_us", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false });
          });
      }
    }
  };

  setRating = (rating) => {
    if (rating >= 4) {
      this.setState({
        ackMessage: "Would you please give us the same rating in Google Play as well?",
        isReviewSubmitted: true,
      });
      localStorage.setItem("r_us", rating);
    } else if (rating === 3) {
      this.setState({
        ackMessage: "Thanks for the rating. Please let us know if you have any suggestions.",
        isReviewSubmitted: true,
      });
      localStorage.setItem("r_us", rating);
    } else {
      this.setState({
        ackMessage: "Sorry, we could not meet your expectations. Please tell us how we can improve.",
        isReviewSubmitted: true,
      });
      localStorage.setItem("r_us", rating);
    }
    if (rating > 3) this.setState({ modelOpen: !FA_localStorage("GPRating") });
    else this.showError("Rating successfully submitted.");
  };

  componentDidMount = () => {
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + this.props.location.pathname;
    } else if (localStorage.getItem("r_us")) {
      this.setRating(localStorage.getItem("r_us"));
    }
    window.location.hash = "backtohome";
  };

  onRateUsBtnClick = () => {
    FA_localStorage("GPRating", "initiated");
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      window.AndroidBridge.call("rateAppInPlayStore")
      return
    }
    window.open(
      "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3DFindastro_web%26utm_medium%3Dapplink",
      "_blank"
    )
  }

  render() {
    const { classes } = this.props;
    return localStorage.getItem("name") ? (
      <div className={classes.sidebarScreensParentDiv}>
        <HelmetComponent
          title="Rate us at Findastro"
          url="https://www.findastro.com/rate-us"
          description="Rate us at Findastro-Get Connected to your Favorite Astrologer at Findastro"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        <div className={classes.sidebarScreensTopArea}>
          <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={this.props.history.goBack} />
          <div className={classes.sidebarScreensChildTopArea}>
            <p>Tell us your Findastro story</p>
          </div>
        </div>
        {/* <img className={classes.sidebarScreensLogo} src={logo} alt="Logo" /> */}
        {this.state.isReviewSubmitted && this.state.disabledReSubmit ? (
          <></>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div className={classes.sidebarScreensLeaveCommentDiv}>
              <textarea
                className={classes.sidebarScreensCommentBox}
                variant="outlined"
                type="text"
                placeholder="Your story here"
                value={this.state.commentText}
                onChange={this.onComment}
                maxWidth={1000}
              />
            </div>
            <div className={classes.sidebarScreensLeaveCommentDiv}>
              <input
                className={classes.sidebarScreensNameBox}
                variant="outlined"
                type="text"
                placeholder="Your name here"
                value={this.state.commentName}
                onChange={this.onName}
                maxWidth={50}
              />
            </div>
            <div className={classes.sidebarScreensLeaveCommentDiv}>
              <input
                className={classes.sidebarScreensNameBox}
                variant="outlined"
                type="text"
                placeholder="Your place"
                value={this.state.commentPlace}
                onChange={this.onPlace}
                maxWidth={50}
              />
            </div>
            <div style={{ color: "#5d5d5d", marginTop: 20 }}>Rating</div>
            <Rating className={classes.sidebarScreensRating} value={this.state.rating} onChange={this.onRating} />
            <Button className={classes.sidebarScreensSubmitButton} variant="contained" onClick={this.onSubmit}>
              <span className={classes.sidebarScreensSubmit}>Submit</span>
            </Button>
          </div>
        )}
        {/* <div className={classes.sidebarScreensCenter}>
          <div className={classes.sidebarScreensHeading}>Please rate your experience</div>
          <div className={classes.sidebarScreensParagraph}>
            Your valuable feedback will help us to improve our service quality. Please help us to serve you better.
          </div>
          <Rating className={classes.sidebarScreensRating} value={this.state.rating} onChange={this.onRating} />
          {this.state.isReviewSubmitted && this.state.rating >= 4 ? (
            <div className={classes.sidebarScreensAckMessage}>{this.state.ackMessage}</div>
          ) : (
            <></>
          )}
        </div> */}
        <Modal open={this.state.modelOpen} onClose={() => (window.location.href = "/")}>
          <div className={classes.rateusModal}>
            <TYBackdrop style={{ height: "30vh", opacity: "0.8" }} />
            <div style={{ fontSize: "1.4rem", fontWeight: "700", color: "rgb(139, 10, 46)", margin: "24px 0px 24px 0" }}>
              Wow!
              <br />
              {(FA_localStorage("name") || "").split(" ")[0]}, thank you!
            </div>
            {BrowserUtils.GooglePlaySupport === true ? (
              <>
                <div style={{ padding: "0 24px 24px 24px", fontWeight: 700, color: "#5e5e5e" }}>
                  {this.state.ackMessage}
                </div>
                <Button
                  variant="contained"
                  className={classes.rateusModalButton}
                  onClick={this.onRateUsBtnClick}>
                  <GPIcon style={{ marginRight: "10px" }} />
                  <span>Rate us on Google Play</span>
                </Button>
                <div
                  className={classes.rateusModalSkip}
                  style={{ opacity: 0.6 }}
                  onClick={() => {
                    FA_localStorage("GPRating", "initiated");
                    window.location.href = "/";
                  }}>
                  Later
                </div>{" "}
              </>
            ) : (
              <>
                <div style={{ padding: "0 24px 24px 24px", fontWeight: 700, color: "#5e5e5e" }}>
                  {"Would you please share your Findastro experience with your friends and family & support us?"}
                </div>
                <Button
                  variant="contained"
                  className={classes.rateusModalButton}
                  onClick={() => {
                    FA_localStorage("GPRating", "initiated");
                    window.location.href = "/";
                  }}>
                  <span>Yes, sure!</span>
                </Button>
              </>
            )}
          </div>
        </Modal>
        <ShareDrawerComponent
          open={this.state.shareModalOpen}
          onDrawerClose={() => this.setState({ shareModalOpen: false })}
          showError={this.showError}
          title="Findastro - "
          profileUrl={String(window.location.origin)}
        />

        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    ) : (
      <></>
    );
  }
}

export default withRouter(withStyles(styles)(RateUs));
