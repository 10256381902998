/* eslint-disable */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
export const drawChart = (t) => {
  var e = t;
  checking(e)
}
function checking(t) {
  if ("SO" == t.ChartStyle || "KE" == t.ChartStyle)
      var e = 1;
  else if ("NIC" == t.ChartStyle)
      var e = 2;
  else if ("NIA" == t.ChartStyle)
      var e = 3;
  else if ("EI" == t.ChartStyle)
      var e = 4;
  else if ("SL" == t.ChartStyle)
      var e = 5;
  var p = t.Target.width;
  var outerpercentage = .1,
      innerpercentage = "B_S0" == t.BorderStyle ? 40 * outerpercentage : 80 * outerpercentage,
      length_percentinner = Math.round(innerpercentage * p / 100),
      length_percentouter = Math.round(outerpercentage * p / 100),
      calc_length = p - 1.1 * length_percentinner - length_percentinner,
      calc_length_outer = p - 2 * length_percentouter - length_percentouter;
  var m = document.getElementById(t.Target.ID);
  var y = document.createElementNS("http://www.w3.org/2000/svg", "svg");

  y.setAttribute("id", "svg" + t.Target.ID),
      y.setAttribute("width", t.Target.width),
      y.setAttribute("height", t.Target.height),
      y.setAttribute("xmlns", "http://www.w3.org/2000/svg"),
      y.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink"),
      y.setAttribute("version", "1.1"),
      y.setAttribute("x", "0px"),
      y.setAttribute("y", "0px"),
      y.setAttribute("viewBox", "0 0 " + t.Target.width + " " + t.Target.height),
      y.setAttribute("style", "enable-background:new 0 0 " + t.Target.width + " " + t.Target.height + ";background-color:transparent;"),
      y.setAttribute("xml:space", "preserve");

  var i = document.createElementNS("http://www.w3.org/2000/svg", "path");
  i.setAttribute("stroke", "#ee600d"),
      i.setAttribute("stroke-width", 2),
      i.setAttribute("fill", "none");

  var x = length_percentinner
      , _ = length_percentinner
      , r = calc_length
      , n = length_percentouter
      , d = length_percentouter
      , o = calc_length_outer
      , a = {};
  a.x = x,
      a.y = _,
      a.length = r;
  var s = {};
  s.x = n,
      s.y = d,
      s.length = o;
  if (t.ChartType == "SD") {
      var u = createJSON_squ_sudarshana(s.x, s.y, s.length);
      var l = create_squ_sudarshana_chart_path(u);
  }
  else {
      if ("NIL" == t.BorderStyle)
          var u = createJSON(s.x, s.y, s.length);
      else
          var u = createJSON(a.x, a.y, a.length);
      if (1 == e)
          var l = create_South_chart_path(u);
      else if (2 == e)
          var l = create_North_chart_path(u);
      else if (3 == e)
          var l = create_North_antique_chart_path(u);
      else if (4 == e || 5 == e)
          var l = create_East_chart_path(u);
  }
  i.setAttribute("d", l),
      y.appendChild(i),
      m.appendChild(y);
  var c = t;
  if (t.ChartType == "SD") {
      insert_planets_sudarshana_squ(u, c);
  }
  else {
      allocate_planets(u, c, e),
          "B_S0" == t.BorderStyle ? setDesignFor_B_S0(a, s, c.Target.ID) : "B_S1" == t.BorderStyle ? setDesignFor_B_S1(a, s, c.Target.ID) : "B_S2" == t.BorderStyle && setDesignFor_B_S2(a, s, c.Target.ID)
  }
}
function setPlanet_Font_And_SizeForLanguage_UTF(e) {
  var langJson = {};
  if ("ENG" == e.Language)
      langJson.planetsArray = ["Moo", "Sun", "Mer", "Ven", "Mar", "Jup", "Sat", "Rah", "Ket", "Lag", "Gul", "Aru", "Thi"],
          langJson.Font = "Calibri",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("MAL" == e.Language)
      langJson.planetsArray = ["à´š", "à´°", "à´¬àµ", "à´¶àµ", "à´•àµ", "à´—àµ", "à´®", "à´¸", "à´¶à´¿", "à´²", "à´®à´¾", "", ""],
          langJson.Font = "Rachana",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("TAM" == e.Language)
      langJson.planetsArray = ["à®šà®¨à¯", "à®šà¯‚", "à®ªà¯", "à®šà¯", "à®šà¯†", "à®µà®¿", "à®šà®©à®¿", "à®°à®¾", "à®•à¯‡", "à®²", "à®®à®¾", "", ""],
          langJson.Font = "Akshar Unicode",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("KAN" == e.Language)
      langJson.planetsArray = ["à²šà²‚", "à²¸à³‚", "à²¬à³", "à²¶à³", "à²•à³", "à²—à³", "à²¶", "à²°à²¾", "à²•à³†à³•", "à²²", "à²®à²¾", "", ""],
          langJson.Font = "Kedage",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("TEL" == e.Language)
      langJson.planetsArray = ["à°šà°‚", "à°°", "à°¬à±", "à°¶à±", "à°•à±", "à°—à±", "à°¶", "à°°à°¾", "à°•à±‡", "à°²", "à°®à°¾", "", ""],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("MAR" == e.Language)
      langJson.planetsArray = ["à¤šà¤‚", "à¤°", "à¤¬à¥", "à¤¶à¥", "à¤®à¤‚", "à¤—à¥", "à¤¶", "à¤°à¤¾", "à¤•à¥‡", "à¤²", "à¤®à¤¾", "", ""],
          langJson.Font = "Mangal",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("BEN" == e.Language)
      langJson.planetsArray = ["à¦š", "à¦°", "à¦¬à§", "à¦¶à§", "à¦®", "à¦¬à§ƒ", "à¦¶", "à¦°à¦¾", "à¦•à§‡", "à¦²à¦‚", "à¦®à¦¾à¦‚", "", ""],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("HIN" == e.Language)
      langJson.planetsArray = ["à¤šà¤‚", "à¤°", "à¤¬à¥", "à¤¶à¥", "à¤®à¤‚", "à¤—à¥", "à¤¶", "à¤°à¤¾", "à¤•à¥‡", "à¤²", "à¤®à¤¾", "", ""],
          langJson.Font = "Akshar Unicode",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("ORI" == e.Language)
      langJson.planetsArray = ["à¬š", "à¬¸à­‚", "à¬¬à­", "à¬¶à­", "à¬•à­", "à¬—à­à¬°à­", "à¬¶", "à¬°à¬¾", "à¬•à­‡", "à¬²", "à¬®à¬£à­à¬¡à¬¿", "", ""],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("GUJ" == e.Language)
      langJson.planetsArray = ["àªšàª‚", "àª¸à«‚", "àª¬à«", "àª¶à«", "àª®àª‚", "àª—à«", "àª¶", "àª°àª¾", "àª•à«‡", "àª²", "àª®àª¾àª‚", "", ""],
          langJson.Font = "Shruti",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("SIN" == e.Language)
      langJson.planetsArray = ["à¶ ", "à·ƒà·–", "à¶¶à·”", "à·ƒà·’", "à¶šà·”", "à¶œà·”", "à·", "à¶»à·", "à¶šà·š", "à¶½à¶œà·Š", "à¶¸à·", "", ""],
          langJson.Font = "Abhaya Libre",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else
      langJson.planetsArray = ["Moo", "Sun", "Mer", "Ven", "Mar", "Jup", "Sat", "Rah", "Ket", "Lag", "Gul", "Aru", "Thi"],
          langJson.Font = "Calibri",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  return langJson;
}
function setUpagraha_Font_And_SizeForLanguage_UTF(e) {
  var langJson = {};
  if ("ENG" == e.Language)
      langJson.planetsArray = ["Kal", "Ard", "Mrt", "Yam", "Gul", "Par", "Ind", "Vya", "Upk", "Lag", "Dhu", "", ""],
          langJson.Font = "Calibri",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("MAL" == e.Language)
      langJson.planetsArray = ["à´•à´¾", "à´…", "à´®àµƒ", "à´¯", "à´—àµàµ¾", "à´ª", "à´‡", "à´µàµà´¯", "à´‰", "à´²", "à´§àµ‚", "", ""],
          langJson.Font = "Rachana",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("TAM" == e.Language)
      //        langJson.planetsArray = ["à®•à®¾"â€š"à®…à®°à¯"â€š"à®®à®¿à¯«"â€š"à®¯à®®"â€š"à®•à¯à®³à®¿"â€š"à®ªà®°à®¿"â€š"à®‡à®¨à¯"â€š"à®µà¯à®¯"â€š"à®‰"â€š"à®²"â€š"à®¤à¯‚","",""],
      langJson.planetsArray = ["à®•à®¾", "à®…à®°à¯", "à®®à®¿à®°à¯", "à®¯à®®", "à®•à¯à®³à®¿", "à®ªà®°à®¿", "à®‡à®¨à¯", "à®µà¯à®¯", "à®‰", "à®²", "à®¤à¯‚", "", ""],
          langJson.Font = "Akshar Unicode",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("KAN" == e.Language)
      langJson.planetsArray = ["à²•à²¾", "à²…à²°à³à²§à³", "à²®à³ƒà²¤à³", "à²¯à²®à³", "à²—à³à²²à³", "à²ªà²°à³", "à²‡à²‚à²¦à³", "à²µà³à²•à²¾", "à²‰à²ªà²•à³", "à²²", "à²§à³‚", "", ""],
          langJson.Font = "Kedage",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("TEL" == e.Language)
      langJson.planetsArray = ["à°•à°¾", "à°…", "à°®à±ƒ", "à°¯", "à°—à±à°³à±", "à°ª", "à°‡", "à°µà±à°¯", "à°‰", "à°²", "à°§à±‚", "", ""],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("MAR" == e.Language)
      langJson.planetsArray = ["à¤•à¤¾", "à¤…", "à¤®à¥ƒ", "à¤¯", "à¤—", "à¤ª", "à¤‡", "à¤µà¥à¤¯", "à¤‰", "à¤²", "à¤§à¥‚", "", ""],
          langJson.Font = "Mangal",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("BEN" == e.Language)
      langJson.planetsArray = ["à¦•à¦¾", "à¦…", "à¦®à§ƒ", "à¦¯à¦®", "à¦®à¦¾à¦‚", "à¦ª", "à¦‡", "à¦¬à§à¦¯à¦¾à¦¤à¦¿", "à¦‰", "à¦²à¦‚", "à¦§à§‚", "", ""],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("HIN" == e.Language)
      langJson.planetsArray = ["à¤•à¤¾", "à¤…", "à¤®à¥ƒ", "à¤¯", "à¤—", "à¤ª", "à¤‡", "à¤µà¥à¤¯", "à¤‰", "à¤²", "à¤§à¥‚", "", ""],
          langJson.Font = "Akshar Unicode",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("ORI" == e.Language)
      langJson.planetsArray = ["à¬•à¬¾", "à¬…à¬¦à­à¬§", "à¬®à­ƒ", "à¬¯à¬®", "à¬¬à­", "à¬˜à¬°", "à¬‡à¬¨à­à¬¦", "à¬¬à­à­Ÿ", "à¬‰à¬ª", "à¬²", "à¬§à­‚", "", ""],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("GUJ" == e.Language)
      langJson.planetsArray = ["àª•àª¾", "àª…", "àª®à«ƒ", "àª¯", "àª—", "àªª", "àª‡", "àªµà«àª¯", "àª‰", "àª²", "àª§à«‚", "", ""],
          langJson.Font = "Shruti",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("SIN" == e.Language)
      langJson.planetsArray = ["à¶šà·", "à¶…à¶»à·Š", "à¶¸à·˜", "à¶ºà¶¸", "à¶œà·”à¶½", "à¶´", "à¶‰à¶±à·Š", "à·€à·Šâ€à¶ºà·", "à¶‹à¶´", "à¶½à¶œà·Š", "à¶°à·–", "", ""],
          langJson.Font = "Abhaya Libre",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else
      langJson.planetsArray = ["Kal", "Ard", "Mrt", "Yam", "Gul", "Par", "Ind", "Vya", "Upk", "Lag", "Dhu", "", ""],
          langJson.Font = "Calibri",
          langJson.FontSize = 4 * e.Target.width / 100;
  langJson.SmallFontSize = 3 * e.Target.width / 100;
  return langJson;
}
function setJaiminiAruda_Font_And_SizeForLanguage_UTF(e) {
  var langJson = {};
  if ("ENG" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Calibri",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("MAL" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Rachana",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("TAM" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Akshar Unicode",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("KAN" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Kedage",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("TEL" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("MAR" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Mangal",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("BEN" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("HIN" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Akshar Unicode",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("ORI" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Arial Unicode MS",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("GUJ" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Shruti",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else if ("SIN" == e.Language)
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Abhaya Libre",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  else
      langJson.planetsArray = ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8", "P9", "Lag", "P10", "P11", "P12"],
          langJson.Font = "Calibri",
          langJson.FontSize = 4 * e.Target.width / 100,
          langJson.SmallFontSize = 3 * e.Target.width / 100;
  return langJson;
}
function allocate_planets(t, e, p) {
  var m = ["Moo", "Sun", "Mer", "Ven", "Mar", "Jup", "Sat", "Rah", "Ket", "Lag", "Gul", "Aru", "Thi"]


      , y = "Calibri"
      , x = "Calibri"
      , _ = 4 * e.Target.width / 100;

  var r, n, sf;
  if (e.ChartType == "AS") {
      //alert(e.AshtaCap[0]); 
      //var r=[];
      var lJSON = setPlanet_Font_And_SizeForLanguage_UTF(e);
      r = lJSON.planetsArray;
      x = lJSON.Font;
      n = lJSON.FontSize;
      sf = lJSON.SmallFontSize;
      for (var i = 0; i <= 11; i++) {
          r[i] = e.AshtaCap[i];
          //alert(r[i]);
      }
      //n = _;
  }
  else
      if (e.ChartType == "UG") {
          var lJSON = setUpagraha_Font_And_SizeForLanguage_UTF(e);
          r = lJSON.planetsArray;
          x = lJSON.Font;
          n = lJSON.FontSize;
          sf = lJSON.SmallFontSize;
      }
      else
          if (e.ChartType == "JA") {
              var lJSON = setJaiminiAruda_Font_And_SizeForLanguage_UTF(e);
              r = lJSON.planetsArray;
              x = lJSON.Font;
              n = lJSON.FontSize;
              sf = lJSON.SmallFontSize;
          }
          else {
              var lJSON = setPlanet_Font_And_SizeForLanguage_UTF(e);
              r = lJSON.planetsArray;
              x = lJSON.Font;
              n = lJSON.FontSize;
              sf = lJSON.SmallFontSize;
          }
  var d = []
      , o = e.PlanetPos.LAG
      , a = document.getElementById("svg" + e.Target.ID);
  for (var i = 0; i < 12; i++) {
      var boolMoreThan6planetsInARasi = new Boolean(false);
      if (1 == p) {
          var s = single_house_coordinates(i + 1, t);
          if (e.ChartType != "AS") {
              i + 1 == o && set_identification_for_lag(s, e.Target.ID);
          }
          var u = 0
              , l = [];
          for (var j = 0; j < 13; j++)
              e.PlanetPos[m[j].toUpperCase()] == i + 1 ? (l.push(m[j]),
                  ++u) : l.push("");
          var c = 0;
          if (u > 6) boolMoreThan6planetsInARasi = true;
          if (d.push(l),
              0 !== u)
              var h = find_planet_coordinates(u, s)

      }


      else if (2 == p || 3 == p) {

          var b = lag_no_to_house_no_north(o, i + 1)
              , v = single_house_coordinates_north(b, t)
              , u = 0
              , l = [];
          for (var j = 0; j < 13; j++)
              e.PlanetPos[m[j].toUpperCase()] == i + 1 ? (l.push(m[j]),
                  ++u) : l.push("");

          var c = 0;
          if (u > 6) boolMoreThan6planetsInARasi = true;
          if (d.push(l),
              0 !== u)

              var h = find_planet_coordinates_north_and_east(u, v.column_coordinates);
          if (e.ChartType != "AS") {
              var A = document.createElementNS("http://www.w3.org/2000/svg", "text");
              A.setAttribute("x", v.rasi_no_coordinates.x),
                  A.setAttribute("y", v.rasi_no_coordinates.y),
                  A.setAttribute("text-anchor", "middle"),
                  A.setAttribute("alignment-baseline", "middle"),
                  // A.setAttribute("font-size", 14),
                  //            A.setAttribute("font-size", (0.8*_)),
                  //            A.setAttribute("style", "stroke-antialiasing:true; font-family:" + y),
                  A.setAttribute("style", "stroke-antialiasing:true; font-family:" + y + "; font-size:" + (0.8 * _) + "px;"),
                  A.setAttribute("fill", "green"),
                  // A.innerHTML = i + 1,
                  //mangala - to see in pdf
                  A.appendChild(document.createTextNode(i + 1))
              a.appendChild(A)
          }
      } else if (4 == p /*|| 5 == p*/) {//mangala - SL bug issue
          var v = single_house_coordinates_east(i + 1, t)
              , u = 0
              , l = [];
          for (var j = 0; j < 13; j++)
              e.PlanetPos[m[j].toUpperCase()] == i + 1 ? (l.push(m[j]),
                  ++u) : l.push("");
          var c = 0;
          if (u > 6) boolMoreThan6planetsInARasi = true;
          if (d.push(l),
              0 !== u)
              var h = find_planet_coordinates_north_and_east(u, v.column_coordinates);
          if (e.ChartType != "AS") {
              var A = document.createElementNS("http://www.w3.org/2000/svg", "text");
              if (A.setAttribute("x", v.rasi_no_coordinates.x),
                  A.setAttribute("y", v.rasi_no_coordinates.y),
                  A.setAttribute("text-anchor", "middle"),
                  A.setAttribute("alignment-baseline", "middle"),
                  // A.setAttribute("font-size", 14),
                  //            A.setAttribute("font-size", (0.8*_)),
                  //            A.setAttribute("style", "stroke-antialiasing:true; font-family:" + y),
                  A.setAttribute("style", "stroke-antialiasing:true; font-family:" + y + "; font-size:" + (0.8 * _) + "px;"),
                  A.setAttribute("fill", "green"),
                  4 == p)
                  //  A.innerHTML = i + 1;

                  //else if (5 == p) {
                  //    var g = i + 1;
                  //    6 >= g ? g += 6 : g -= 6,
                  //    A.innerHTML = g
                  //}
                  //mangala - SL bug issue
                  A.appendChild(document.createTextNode(i + 1))
              a.appendChild(A)
          }
      }
      else if (5 == p)//mangala - SL bug issue
      {
          var b = lag_no_to_house_no_east(o, i + 1)
              , v = single_house_coordinates_east(b, t)
              , u = 0
              , l = [];

          for (var j = 0; j < 13; j++)
              e.PlanetPos[m[j].toUpperCase()] == i + 1 ? (l.push(m[j]),
                  ++u) : l.push("");
          var c = 0;
          if (u > 6) boolMoreThan6planetsInARasi = true;
          if (d.push(l),
              0 !== u)
              var h = find_planet_coordinates_north_and_east(u, v.column_coordinates);
          if (e.ChartType != "AS") {
              var A = document.createElementNS("http://www.w3.org/2000/svg", "text");
              if (A.setAttribute("x", v.rasi_no_coordinates.x),
                  A.setAttribute("y", v.rasi_no_coordinates.y),
                  A.setAttribute("text-anchor", "middle"),
                  A.setAttribute("alignment-baseline", "middle"),
                  // A.setAttribute("font-size", 14),
                  //            A.setAttribute("font-size", (0.8*_)),
                  //            A.setAttribute("style", "stroke-antialiasing:true; font-family:" + y),
                  A.setAttribute("style", "stroke-antialiasing:true; font-family:" + y + "; font-size:" + (0.8 * _) + "px;"),
                  A.setAttribute("fill", "green"),
                  5 == p)
                  // A.innerHTML = i + 1; mcb 10/12/2019
                  A.appendChild(document.createTextNode(i + 1))
              a.appendChild(A)
          }
      }
      if (0 !== u)
          for (var j = 0; j < 13; j++)
              if ("" !== d[i][j]) {
                  var w = document.createElementNS("http://www.w3.org/2000/svg", "text");
                  for (w.setAttribute("x", h[c].x),
                      w.setAttribute("y", h[c].y),
                      w.setAttribute("text-anchor", "middle"),
                      w.setAttribute("alignment-baseline", "middle"),
                      //                    w.setAttribute("font-size", 1.2 * n + "px"),
                      //                    w.setAttribute("style", "stroke-antialiasing:true; font-family:" + x),
                      w.setAttribute("style", "stroke-antialiasing:true; font-family:" + x + "; font-size:" + (boolMoreThan6planetsInARasi == true ? (1.2 * sf) : (1.2 * n)) + "px;"),
                      k = 0; k < 13; k++)
                      if (d[i][j] == m[k]) {
                          if (e.ChartType == "AS") {
                              w.setAttribute("class", "classesA" + (k + 1));
                          }
                          else
                              if (e.ChartType == "JA") {
                                  w.setAttribute("class", "classesJ" + (k + 1));
                              }
                              else {
                                  w.setAttribute("class", "classes" + (k + 1));
                              }

                          //mcb -- w.innerHTML = r[k],
                          // var txt = document.createTextNode("A"), 
                          //w.appendChild(txt),
                          w.appendChild(document.createTextNode(r[k]));

                          a.appendChild(w);
                          c++;
                      }
              }
  }
  if (e.ChartType == "AS") {
      setfunctions_for_planets_forAS();
  }
  else
      if (e.ChartType == "JA") {
          setfunctions_for_planets_forJA();
      }
      else {
          setfunctions_for_planets();
      }
  "SO" == e.ChartStyle && setCaptionAtCenter(e, t, n, _, x, y)
}
function setCaptionAtCenter(t, e, p, m, y, i) {
  var x, _, r, n, d, o, a, s, u, l, c, h, b, v, A, g, w, f, C = [], k = t.Caption.length,
      midtempx, midtempy, tmidtempx, tmidtempy, mid_to_top_2_3x, mid_to_top_2_3y, mid_to_top_1_3x, mid_to_top_1_3y,
      tempx, tempy, bmidtempx, bmidtempy, mid_to_bottom_1_3x, mid_to_bottom_1_3y;
  if (x = e.mid_top.x,
      _ = e.mid_top.y,
      r = e.mid_bottom.x,
      n = e.mid_bottom.y,
      midtempx = (x + r) / 2,
      midtempy = (_ + n) / 2,
      d = (x + r) / 2,
      o = (_ + n) / 2,
      tmidtempx = (midtempx + x) / 2,
      tmidtempy = (midtempy + _) / 2,
      mid_to_top_2_3x = midtempx + 2 * (x - midtempx) / 3,
      mid_to_top_2_3y = midtempy + 2 * (_ - midtempy) / 3,
      mid_to_top_1_3x = midtempx + (x - midtempx) / 3,
      mid_to_top_1_3y = midtempy + (_ - midtempy) / 3,
      a = mid_to_top_2_3x,
      s = mid_to_top_2_3y,
      tempx = (midtempx + tmidtempx) / 2,
      tempy = (midtempy + tmidtempy) / 2,
      tempx = (tempx + tmidtempx) / 2,
      tempy = (tempy + tmidtempy) / 2,
      u = tempx,
      l = tempy,
      c = (mid_to_top_1_3x + midtempx) / 2,
      h = (mid_to_top_1_3y + midtempy) / 2,
      bmidtempx = (midtempx + r) / 2,
      bmidtempy = (midtempy + n) / 2,
      tempx = (midtempx + bmidtempx) / 2,
      tempy = (midtempy + bmidtempy) / 2,
      b = (midtempx + tempx) / 2,
      v = (midtempy + tempy) / 2,
      tempx = (tempx + bmidtempx) / 2,
      tempy = (tempy + bmidtempy) / 2,
      A = (bmidtempx + tempx) / 2,
      g = (bmidtempy + tempy) / 2,
      mid_to_bottom_1_3x = r + (midtempx - r) / 3,
      mid_to_bottom_1_3y = n + (midtempy - n) / 3,
      w = mid_to_bottom_1_3x,
      f = mid_to_bottom_1_3y,
      1 == k) {
      var q = {};
      q.x = d,
          q.y = o,
          C.push(q)
  } else if (2 == k) {
      var q = {};
      q.x = c;
      q.y = h;
      C.push(q);
      var q = {};
      q.x = b;
      q.y = v;
      C.push(q)
  } else if (3 == k) {
      var q = {};
      q.x = u;
      q.y = l;
      C.push(q);
      var q = {};
      q.x = d;
      q.y = o;
      C.push(q);
      var q = {};
      q.x = A;
      q.y = g;
      C.push(q)
  } else if (4 == k) {
      var q = {};
      q.x = u;
      q.y = l;
      C.push(q);
      var q = {};
      q.x = c;
      q.y = h;
      C.push(q);
      var q = {};
      q.x = b;
      q.y = v;
      C.push(q);
      var q = {};
      q.x = tempx;
      q.y = tempy;
      C.push(q)
  } else if (5 == k) {
      var q = {};
      q.x = a;
      q.y = s;
      C.push(q);
      var q = {};
      q.x = u;
      q.y = l;
      C.push(q);
      var q = {};
      q.x = d;
      q.y = o;
      C.push(q);
      var q = {};
      q.x = A;
      q.y = g;
      C.push(q);
      var q = {};
      q.x = w;
      q.y = f;
      C.push(q)
  } else if (6 == k) {
      var q = {};
      q.x = a,
          q.y = s,
          C.push(q);
      var q = {};
      q.x = u,
          q.y = l,
          C.push(q);
      var q = {};
      q.x = c,
          q.y = h,
          C.push(q);
      var q = {};
      q.x = b,
          q.y = v,
          C.push(q);
      var q = {};
      q.x = A,
          q.y = g,
          C.push(q);
      var q = {};
      q.x = w,
          q.y = f,
          C.push(q)
  }
  var S, N, E, M;
  for (var i = 0; i < k; i++) {
      var B = i
          , L = document.getElementById("svg" + t.Target.ID)
          , T = document.createElementNS("http://www.w3.org/2000/svg", "text");
      if (T.setAttribute("x", C[B].x),
          T.setAttribute("y", C[B].y),
          "B" == t.Caption[B].Style)
          var z = "bold";
      else
          var z = "normal";
      T.setAttribute("text-anchor", "middle"),
          T.setAttribute("alignment-baseline", "middle"),
          //        T.setAttribute("style", "stroke-antialiasing:true;font-weight:" + z + "; font-family:" + y + ";"),
          //        T.setAttribute("font-size", 1.3 * p + "px"),
          T.setAttribute("style", "stroke-antialiasing:true;font-weight:" + z + "; font-family:" + y + "; font-size:" + (1.1 * p) + "px;"),
          T.setAttribute("fill", t.Caption[B].Color),
          //T.innerHTML = t.Caption[B].Caption,
          //mangala - to see in pdf
          T.appendChild(document.createTextNode(t.Caption[B].Caption)),
          L.appendChild(T)
  }

}
function set_identification_for_lag(t, e) {
  var p = document.getElementById("svg" + e);
  var midcell_topx = (t.c_p1.x + t.c_p2.x) / 2,
      midcell_topy = (t.c_p1.y + t.c_p2.y) / 2,
      midcell_topx = (midcell_topx + t.c_p1.x) / 2,
      midcell_topy = (midcell_topy + t.c_p1.y) / 2,
      midmidcell_topx = (midcell_topx + t.c_p1.x) / 2,
      midmidcell_topy = (midcell_topy + t.c_p1.y) / 2,
      midmidcell_top1x = (midcell_topx + midmidcell_topx) / 2,
      midmidcell_top1y = (midcell_topy + midmidcell_topy) / 2,
      midcell_bottomx = (t.c_p1.x + t.c_p4.x) / 2,
      midcell_bottomy = (t.c_p1.y + t.c_p4.y) / 2,
      midcell_bottomx = (midcell_bottomx + t.c_p1.x) / 2,
      midcell_bottomy = (midcell_bottomy + t.c_p1.y) / 2,
      midmidcell_bottomx = (midcell_bottomx + t.c_p1.x) / 2,
      midmidcell_bottomy = (midcell_bottomy + t.c_p1.y) / 2,
      midmidcell_bottom1x = (midcell_bottomx + midmidcell_bottomx) / 2,
      midmidcell_bottom1y = (midcell_bottomy + midmidcell_bottomy) / 2;
  var m = document.createElementNS("http://www.w3.org/2000/svg", "line");
  m.setAttribute("x1", midmidcell_topx),
      m.setAttribute("y1", midmidcell_topy),
      m.setAttribute("x2", midmidcell_bottomx),
      m.setAttribute("y2", midmidcell_bottomy),
      m.setAttribute("stroke", "#ee600d"),
      m.setAttribute("stroke-width", 2),
      p.appendChild(m);
  var m = document.createElementNS("http://www.w3.org/2000/svg", "line");
  m.setAttribute("x1", midmidcell_top1x),
      m.setAttribute("y1", midmidcell_top1y),
      m.setAttribute("x2", midmidcell_bottom1x),
      m.setAttribute("y2", midmidcell_bottom1y),
      m.setAttribute("stroke", "#ee600d"),
      m.setAttribute("stroke-width", 2),
      p.appendChild(m)
}
function setfunctions_for_planets() {
  var planet1 = document.getElementsByClassName("classes10");
  for (var t = 0; t < planet1.length; t++)
      planet1[t].setAttribute("fill", "#800080");
  var planet2 = document.getElementsByClassName("classes2");
  for (var t = 0; t < planet2.length; t++)
      planet2[t].setAttribute("fill", "#008080");
  var planet3 = document.getElementsByClassName("classes1");
  for (var t = 0; t < planet3.length; t++)
      planet3[t].setAttribute("fill", "#0000ff");
  var planet4 = document.getElementsByClassName("classes5");
  for (var t = 0; t < planet4.length; t++)
      planet4[t].setAttribute("fill", "#ff1200");
  var planet5 = document.getElementsByClassName("classes3");
  for (var t = 0; t < planet5.length; t++)
      planet5[t].setAttribute("fill", "#000080");
  var planet6 = document.getElementsByClassName("classes6");
  for (var t = 0; t < planet6.length; t++)
      planet6[t].setAttribute("fill", "#ff00ff");
  var planet7 = document.getElementsByClassName("classes4");
  for (var t = 0; t < planet7.length; t++)
      planet7[t].setAttribute("fill", "#800000");
  var planet8 = document.getElementsByClassName("classes7");
  for (var t = 0; t < planet8.length; t++)
      planet8[t].setAttribute("fill", "#800080");
  var planet9 = document.getElementsByClassName("classes8");
  for (var t = 0; t < planet9.length; t++)
      planet9[t].setAttribute("fill", "#008000");
  var planet10 = document.getElementsByClassName("classes9");
  for (var t = 0; t < planet10.length; t++)
      planet10[t].setAttribute("fill", "#008000");
  var planet11 = document.getElementsByClassName("classes11");
  for (var t = 0; t < planet11.length; t++)
      planet11[t].setAttribute("fill", "#000000")
}
function setfunctions_for_planets_forAS() {
  var planet1 = document.getElementsByClassName("classesA10");
  for (var t = 0; t < planet1.length; t++)
      planet1[t].setAttribute("fill", "#800000");
  var planet2 = document.getElementsByClassName("classesA2");
  for (var t = 0; t < planet2.length; t++)
      planet2[t].setAttribute("fill", "#800000");
  var planet3 = document.getElementsByClassName("classesA1");
  for (var t = 0; t < planet3.length; t++)
      planet3[t].setAttribute("fill", "#800000");
  var planet4 = document.getElementsByClassName("classesA5");
  for (var t = 0; t < planet4.length; t++)
      planet4[t].setAttribute("fill", "#800000");
  var planet5 = document.getElementsByClassName("classesA3");
  for (var t = 0; t < planet5.length; t++)
      planet5[t].setAttribute("fill", "#800000");
  var planet6 = document.getElementsByClassName("classesA6");
  for (var t = 0; t < planet6.length; t++)
      planet6[t].setAttribute("fill", "#800000");
  var planet7 = document.getElementsByClassName("classesA4");
  for (var t = 0; t < planet7.length; t++)
      planet7[t].setAttribute("fill", "#800000");
  var planet8 = document.getElementsByClassName("classesA7");
  for (var t = 0; t < planet8.length; t++)
      planet8[t].setAttribute("fill", "#800000");
  var planet9 = document.getElementsByClassName("classesA8");
  for (var t = 0; t < planet9.length; t++)
      planet9[t].setAttribute("fill", "#800000");
  var planet10 = document.getElementsByClassName("classesA9");
  for (var t = 0; t < planet10.length; t++)
      planet10[t].setAttribute("fill", "#800000");
  var planet11 = document.getElementsByClassName("classesA11");
  for (var t = 0; t < planet11.length; t++)
      planet11[t].setAttribute("fill", "#800000")
  var planet12 = document.getElementsByClassName("classesA12");
  for (var t = 0; t < planet12.length; t++)
      planet12[t].setAttribute("fill", "#800000")
}
function setfunctions_for_planets_forJA() {
  var planet1 = document.getElementsByClassName("classesJ10");
  for (var t = 0; t < planet1.length; t++)
      planet1[t].setAttribute("fill", "#800080");
  var planet2 = document.getElementsByClassName("classesJ2");
  for (var t = 0; t < planet2.length; t++)
      planet2[t].setAttribute("fill", "#008080");
  var planet3 = document.getElementsByClassName("classesJ1");
  for (var t = 0; t < planet3.length; t++)
      planet3[t].setAttribute("fill", "#0000ff");
  var planet4 = document.getElementsByClassName("classesJ5");
  for (var t = 0; t < planet4.length; t++)
      planet4[t].setAttribute("fill", "#ff1200");
  var planet5 = document.getElementsByClassName("classesJ3");
  for (var t = 0; t < planet5.length; t++)
      planet5[t].setAttribute("fill", "#000080");
  var planet6 = document.getElementsByClassName("classesJ6");
  for (var t = 0; t < planet6.length; t++)
      planet6[t].setAttribute("fill", "#ff00ff");
  var planet7 = document.getElementsByClassName("classesJ4");
  for (var t = 0; t < planet7.length; t++)
      planet7[t].setAttribute("fill", "#800000");
  var planet8 = document.getElementsByClassName("classesJ7");
  for (var t = 0; t < planet8.length; t++)
      planet8[t].setAttribute("fill", "#800080");
  var planet9 = document.getElementsByClassName("classesJ8");
  for (var t = 0; t < planet9.length; t++)
      planet9[t].setAttribute("fill", "#008000");
  var planet10 = document.getElementsByClassName("classesJ9");
  for (var t = 0; t < planet10.length; t++)
      planet10[t].setAttribute("fill", "#008000");
  var planet11 = document.getElementsByClassName("classesJ11");
  for (var t = 0; t < planet11.length; t++)
      planet11[t].setAttribute("fill", "#000000")
  var planet12 = document.getElementsByClassName("classesJ12");
  for (var t = 0; t < planet12.length; t++)
      planet12[t].setAttribute("fill", "#000000")
  var planet13 = document.getElementsByClassName("classesJ13");
  for (var t = 0; t < planet13.length; t++)
      planet13[t].setAttribute("fill", "#000000")
}

function single_house_coordinates(t, e) {
  var p, m, y, i, x = {};
  switch (t) {
      case 1:
          p = e.p1_p1p2,
              m = e.p1p2,
              y = e.mid_top,
              i = e.center_p1;
          break;
      case 2:
          p = e.p1p2,
              m = e.p1p2_p2,
              y = e.center_p2,
              i = e.mid_top;
          break;
      case 3:
          p = e.p1p2_p2,
              m = e.p2,
              y = e.p2_p2p3,
              i = e.center_p2;
          break;
      case 4:
          p = e.center_p2,
              m = e.p2_p2p3,
              y = e.p2p3,
              i = e.mid_right;
          break;
      case 5:
          p = e.mid_right,
              m = e.p2p3,
              y = e.p2p3_p3,
              i = e.center_p3;
          break;
      case 6:
          p = e.center_p3,
              m = e.p2p3_p3,
              y = e.p3,
              i = e.p3_p3p4;
          break;
      case 7:
          p = e.mid_bottom,
              m = e.center_p3,
              y = e.p3_p3p4,
              i = e.p3p4;
          break;
      case 8:
          p = e.center_p4,
              m = e.mid_bottom,
              y = e.p3p4,
              i = e.p3p4_p4;
          break;
      case 9:
          p = e.p4_p4p1,
              m = e.center_p4,
              y = e.p3p4_p4,
              i = e.p4;
          break;
      case 10:
          p = e.p4p1,
              m = e.mid_left,
              y = e.center_p4,
              i = e.p4_p4p1;
          break;
      case 11:
          p = e.p4p1_p1,
              m = e.center_p1,
              y = e.mid_left,
              i = e.p4p1;
          break;
      case 12:
          p = e.p1,
              m = e.p1_p1p2,
              y = e.center_p1,
              i = e.p4p1_p1
  }
  return null !== p ? (x.c_p1 = p,
      x.c_p2 = m,
      x.c_p3 = y,
      x.c_p4 = i,
      x) : void 0
}
function single_house_coordinates_north(t, e) {
  var p, m, y, i, x = {}, _ = {}, r = {}, n = null;
  const d = 18;
  switch (t) {
      case 1:
          p = e.center_chart,
              m = e.center_p1,
              y = e.p1p2,
              n = e.center_p2,
              _.x = e.center_chart.x,
              i = e.center_chart.y - d,
              _.y = i;
          break;
      case 2:
          p = e.center_p2,
              m = e.p1p2,
              y = e.p2,
              _.x = e.center_p2.x,
              i = e.center_p2.y - d,
              _.y = i;
          break;
      case 3:
          p = e.center_p2,
              m = e.p2,
              y = e.p2p3,
              _.y = e.center_p2.y,
              i = e.center_p2.x + d,
              _.x = i;
          break;
      case 4:
          p = e.center_chart,
              m = e.center_p2,
              y = e.p2p3,
              n = e.center_p3,
              _.y = e.center_chart.y,
              i = e.center_chart.x + d,
              _.x = i;
          break;
      case 5:
          p = e.center_p3,
              m = e.p2p3,
              y = e.p3,
              _.y = e.center_p3.y,
              i = e.center_p3.x + d,
              _.x = i;
          break;
      case 6:
          p = e.center_p3,
              m = e.p3,
              y = e.p3p4,
              _.x = e.center_p3.x,
              i = e.center_p3.y + d,
              _.y = i;
          break;
      case 7:
          p = e.center_chart,
              m = e.center_p3,
              y = e.p3p4,
              n = e.center_p4,
              _.x = e.center_chart.x,
              i = e.center_chart.y + d,
              _.y = i;
          break;
      case 8:
          p = e.center_p4,
              m = e.p3p4,
              y = e.p4,
              _.x = e.center_p4.x,
              i = e.center_p4.y + d,
              _.y = i;
          break;
      case 9:
          p = e.center_p4,
              m = e.p4,
              y = e.p4p1,
              _.y = e.center_p4.y,
              i = e.center_p4.x - d,
              _.x = i;
          break;
      case 10:
          p = e.center_chart,
              m = e.center_p4,
              y = e.p4p1,
              n = e.center_p1,
              _.y = e.center_chart.y,
              i = e.center_chart.x - d,
              _.x = i;
          break;
      case 11:
          p = e.center_p1,
              m = e.p4p1,
              y = e.p1,
              _.y = e.center_p1.y,
              i = e.center_p1.x - d,
              _.x = i;
          break;
      case 12:
          p = e.center_p1,
              m = e.p1,
              y = e.p1p2,
              _.x = e.center_p1.x,
              i = e.center_p1.y - d,
              _.y = i
  }
  return null !== p ? (r.c_p1 = p,
      r.c_p2 = m,
      r.c_p3 = y,
      null !== n && (r.c_p4 = n),
      x.rasi_no_coordinates = _,
      x.column_coordinates = r,
      x) : void 0
}
function single_house_coordinates_east(t, e) {
  var p, m, y, i = {}, x = {}, _ = {}, r = null;
  switch (t) {
      case 1:
          p = e.east_center_p2,
              m = e.east_center_p1,
              y = e.p1p2_1,
              r = e.p1p2_2;
          break;
      case 2:
          p = e.east_center_p1,
              m = e.p1,
              y = e.p1p2_1;
          break;
      case 3:
          p = e.east_center_p1,
              m = e.p1,
              y = e.p4p1_2;
          break;
      case 4:
          p = e.east_center_p1,
              m = e.east_center_p4,
              y = e.p4p1_1,
              r = e.p4p1_2;
          break;
      case 5:
          p = e.east_center_p4,
              m = e.p4,
              y = e.p4p1_1;
          break;
      case 6:
          p = e.east_center_p4,
              m = e.p4,
              y = e.p3p4_2;
          break;
      case 7:
          p = e.east_center_p4,
              m = e.east_center_p3,
              y = e.p3p4_1,
              r = e.p3p4_2;
          break;
      case 8:
          p = e.east_center_p3,
              m = e.p3,
              y = e.p3p4_1;
          break;
      case 9:
          p = e.east_center_p3,
              m = e.p3,
              y = e.p2p3_2;
          break;
      case 10:
          p = e.east_center_p3,
              m = e.east_center_p2,
              y = e.p2p3_1,
              r = e.p2p3_2;
          break;
      case 11:
          p = e.east_center_p2,
              m = e.p2,
              y = e.p2p3_1;
          break;
      case 12:
          p = e.east_center_p2,
              m = e.p2,
              y = e.p1p2_2
  }
  var tempx, tempy, temp1x, temp1y;
  return null !== p ? (_.c_p1 = p,
      _.c_p2 = m,
      _.c_p3 = y,
      null !== r ? (_.c_p4 = r,
          tempx = (p.x + y.x) / 2,
          tempy = (p.y + y.y) / 2,
          tempx = (p.x + tempx) / 2,
          tempy = (p.y + tempy) / 2,
          tempx = (p.x + tempx) / 2,
          tempy = (p.y + tempy) / 2,
          x.x = tempx,
          x.y = tempy) : (tempx = (m.x + y.x) / 2,
              tempy = (m.y + y.y) / 2,
              tempx = (p.x + tempx) / 2,
              tempy = (p.y + tempy) / 2,
              tempx = (p.x + tempx) / 2,
              tempy = (p.y + tempy) / 2,
              temp1x = (p.x + tempx) / 2,
              temp1y = (p.y + tempy) / 2,
              x.x = (tempx + temp1x) / 2,
              x.y = (tempy + temp1y) / 2),
      i.rasi_no_coordinates = x,
      i.column_coordinates = _,
      i) : void 0
}
function find_planet_coordinates(t, e) {
  var p, m, y, i, x, _, r, n, d, o, a, s, u, l, c, h, b, v, A, g, w, f, C, k, q, S, N, E, M, B, L, T, z, j, I, D, P, H, R, F, J, O, V = [];
  var tempx, tempy, temp1x, temp1y;
  if (p = (e.c_p1.x + e.c_p3.x) / 2,
      m = (e.c_p1.y + e.c_p3.y) / 2,
      t > 4 ? (tempx = (p + e.c_p1.x) / 2,
          tempy = (m + e.c_p1.y) / 2,
          temp1x = (tempx + e.c_p1.x) / 2,
          temp1y = (tempy + e.c_p1.y) / 2,
          y = (tempx + temp1x) / 2,
          i = (tempy + temp1y) / 2) : (y = (p + e.c_p1.x) / 2,
              i = (m + e.c_p1.y) / 2),
      t > 4 ? (tempx = (p + e.c_p2.x) / 2,
          tempy = (m + e.c_p2.y) / 2,
          temp1x = (tempx + e.c_p2.x) / 2,
          temp1y = (tempy + e.c_p2.y) / 2,
          x = (tempx + temp1x) / 2,
          _ = (tempy + temp1y) / 2) : (x = (p + e.c_p2.x) / 2,
              _ = (m + e.c_p2.y) / 2),
      t > 4 ? (tempx = (p + e.c_p3.x) / 2,
          tempy = (m + e.c_p3.y) / 2,
          temp1x = (tempx + e.c_p3.x) / 2,
          temp1y = (tempy + e.c_p3.y) / 2,
          r = (tempx + temp1x) / 2,
          n = (tempy + temp1y) / 2) : (r = (p + e.c_p3.x) / 2,
              n = (m + e.c_p3.y) / 2),
      t > 4 ? (tempx = (p + e.c_p4.x) / 2,
          tempy = (m + e.c_p4.y) / 2,
          temp1x = (tempx + e.c_p4.x) / 2,
          temp1y = (tempy + e.c_p4.y) / 2,
          d = (tempx + temp1x) / 2,
          o = (tempy + temp1y) / 2) : (d = (p + e.c_p4.x) / 2,
              o = (m + e.c_p4.y) / 2),
      a = (y + x) / 2,
      s = (i + _) / 2,
      u = (x + r) / 2,
      l = (_ + n) / 2,
      c = (r + d) / 2,
      h = (n + o) / 2,
      b = (d + y) / 2,
      v = (o + i) / 2,
      A = (y + a) / 2,
      g = (i + s) / 2,
      w = (a + x) / 2,
      f = (s + _) / 2,
      C = (x + u) / 2,
      k = (_ + l) / 2,
      q = (u + r) / 2,
      S = (l + n) / 2,
      N = (r + c) / 2,
      E = (n + h) / 2,
      M = (c + d) / 2,
      B = (h + o) / 2,
      L = (d + b) / 2,
      T = (o + v) / 2,
      z = (b + y) / 2,
      j = (v + i) / 2,
      I = (p + a) / 2,
      D = (m + s) / 2,
      P = (p + u) / 2,
      H = (m + l) / 2,
      R = (p + c) / 2,
      F = (m + h) / 2,
      J = (p + b) / 2,
      O = (m + v) / 2,
      1 == t) {
      var G = {};
      return G.x = p,
          G.y = m,
          V.push(G),
          V
  }
  if (2 == t) {
      var G = {};
      G.x = y,
          G.y = i,
          V.push(G);
      var G = {};
      return G.x = r,
          G.y = n,
          V.push(G),
          V
  }
  if (3 == t) {
      var G = {};
      G.x = a,
          G.y = s,
          V.push(G);
      var G = {};
      G.x = r,
          G.y = n,
          V.push(G);
      var G = {};
      return G.x = d,
          G.y = o,
          V.push(G),
          V
  }
  if (4 == t) {
      var G = {};
      G.x = y,
          G.y = i,
          V.push(G);
      var G = {};
      G.x = x,
          G.y = _,
          V.push(G);
      var G = {};
      G.x = r,
          G.y = n,
          V.push(G);
      var G = {};
      return G.x = d,
          G.y = o,
          V.push(G),
          V
  }
  if (5 == t) {
      var G = {};
      G.x = a,
          G.y = s,
          V.push(G);
      var G = {};
      G.x = b,
          G.y = v,
          V.push(G);
      var G = {};
      G.x = u,
          G.y = l,
          V.push(G);
      var G = {};
      G.x = d,
          G.y = o,
          V.push(G);
      var G = {};
      return G.x = r,
          G.y = n,
          V.push(G),
          V
  }
  if (6 == t) {
      var G = {};
      G.x = y,
          G.y = i,
          V.push(G);
      var G = {};
      G.x = x,
          G.y = _,
          V.push(G);
      var G = {};
      G.x = b,
          G.y = v,
          V.push(G);
      var G = {};
      G.x = u,
          G.y = l,
          V.push(G);
      var G = {};
      G.x = d,
          G.y = o,
          V.push(G);
      var G = {};
      return G.x = r,
          G.y = n,
          V.push(G),
          V
  }
  if (7 == t) {
      var G = {};
      G.x = a,
          G.y = s,
          V.push(G);
      var G = {};
      G.x = b,
          G.y = v,
          V.push(G);
      var G = {};
      G.x = p,
          G.y = m,
          V.push(G);
      var G = {};
      G.x = u,
          G.y = l,
          V.push(G);
      var G = {};
      G.x = d,
          G.y = o,
          V.push(G);
      var G = {};
      G.x = c,
          G.y = h,
          V.push(G);
      var G = {};
      return G.x = r,
          G.y = n,
          V.push(G),
          V
  }
  if (8 == t) {
      var G = {};
      G.x = y,
          G.y = i,
          V.push(G);
      var G = {};
      G.x = a,
          G.y = s,
          V.push(G);
      var G = {};
      G.x = x,
          G.y = _,
          V.push(G);
      var G = {};
      G.x = J,
          G.y = O,
          V.push(G);
      var G = {};
      G.x = P,
          G.y = H,
          V.push(G);
      var G = {};
      G.x = d,
          G.y = o,
          V.push(G);
      var G = {};
      G.x = c,
          G.y = h,
          V.push(G);
      var G = {};
      return G.x = r,
          G.y = n,
          V.push(G),
          V
  }
  if (9 == t) {
      var G = {};
      G.x = y,
          G.y = i,
          V.push(G);
      var G = {};
      G.x = a,
          G.y = s,
          V.push(G);
      var G = {};
      G.x = x,
          G.y = _,
          V.push(G);
      var G = {};
      G.x = b,
          G.y = v,
          V.push(G);
      var G = {};
      G.x = p,
          G.y = m,
          V.push(G);
      var G = {};
      G.x = u,
          G.y = l,
          V.push(G);
      var G = {};
      G.x = d,
          G.y = o,
          V.push(G);
      var G = {};
      G.x = c,
          G.y = h,
          V.push(G);
      var G = {};
      return G.x = r,
          G.y = n,
          V.push(G),
          V
  }
}
function find_planet_coordinates_north_and_east(t, e) {
  var p, m, y, i, x, _, r, n, d, o, a, s, u, l, c, h, b, v, A, g, w, f, C, k, q, S, N, E, M, B, L, T, z, j, I, D, P, H, R, F, J, O, V, G, K, U, Q, W, Z, X, Y, $, tt, et, pt, mt, yt, it, xt, _t, rt, nt, dt, ot, at, st, ut, lt, ct, ht, bt, vt, At, gt, wt, ft, Ct, kt, qt, St, Nt, Et, Mt, Bt, Lt, Tt, zt, jt, It, Dt, Pt, Ht, Rt, Ft, Jt = [];
  var temp1x, temp1y, c_northColx, c_northColy, temp2x, temp2y, temp3x, temp3y, temp4x, temp4y,
      temp5x, temp5y, temp6x, temp6y;
  if (3 == Object.keys(e).length ? (p = e.c_p1,
      m = e.c_p2,
      y = e.c_p3,
      x = c_northColx = (p.x + m.x + y.x) / 3,
      _ = c_northColy = (p.y + m.y + y.y) / 3,
      temp1x = (p.x + y.x) / 2,
      temp1y = (p.y + y.y) / 2,
      temp1x = (temp1x + p.x) / 2,
      temp1y = (temp1y + p.y) / 2,
      temp2x = (m.x + y.x) / 2,
      temp2y = (m.y + y.y) / 2,
      temp2x = (temp2x + m.x) / 2,
      temp2y = (temp2y + m.y) / 2,
      r = (c_northColx + temp1x) / 2,
      n = (c_northColy + temp1y) / 2,
      d = (c_northColx + temp2x) / 2,
      o = (c_northColy + temp2y) / 2,
      a = r,
      s = n,
      u = d,
      l = o,
      c = (c_northColx + y.x) / 2,
      h = (c_northColy + y.y) / 2,
      b = a,
      v = s,
      A = u,
      g = l,
      w = c,
      f = h,
      C = c_northColx,
      k = c_northColy,
      temp1x = (p.x + y.x) / 2,
      temp1y = (p.y + y.y) / 2,
      temp1x = (temp1x + p.x) / 2,
      temp1y = (temp1y + p.y) / 2,
      temp2x = (m.x + y.x) / 2,
      temp2y = (m.y + y.y) / 2,
      temp2x = (temp2x + m.x) / 2,
      temp2y = (temp2y + m.y) / 2,
      q = (temp1x + temp2x) / 2,
      S = (temp1y + temp2y) / 2,
      N = (q + temp1x) / 2,
      E = (S + temp1y) / 2,
      M = (q + temp2x) / 2,
      B = (S + temp2y) / 2,
      temp1x = (p.x + y.x) / 2,
      temp1y = (p.y + y.y) / 2,
      temp2x = (temp1x + y.x) / 2,
      temp2y = (temp1y + y.y) / 2,
      temp2x = (temp1x + temp2x) / 2,
      temp2y = (temp1y + temp2y) / 2,
      temp1x = (temp1x + temp2x) / 2,
      temp1y = (temp1y + temp2y) / 2,
      temp2x = (m.x + y.x) / 2,
      temp2y = (m.y + y.y) / 2,
      temp3x = (temp2x + y.x) / 2,
      temp3y = (temp2y + y.y) / 2,
      temp3x = (temp2x + temp3x) / 2,
      temp3y = (temp2y + temp3y) / 2,
      temp2x = (temp2x + temp3x) / 2,
      temp2y = (temp2y + temp3y) / 2,
      L = temp1x + (temp2x - temp1x) / 3,
      //T = temp1x + (temp2x - temp1x) / 3, mangala - 5 planet in corner rasis , one planet appears on line bug resolution
      T = temp1y + (temp2y - temp1y) / 3,
      z = temp1x + 2 * (temp2x - temp1x) / 3,
      j = temp1y + 2 * (temp2y - temp1y) / 3,
      temp1x = (p.x + m.x) / 2,
      temp1y = (p.y + m.y) / 2,
      temp2x = (m.x + y.x) / 2,
      temp2y = (m.y + y.y) / 2,
      temp3x = (y.x + p.x) / 2,
      temp3y = (y.y + p.y) / 2,
      I = (c_northColx + p.x) / 2,
      D = (c_northColy + p.y) / 2,
      P = (c_northColx + temp1x) / 2,
      H = (c_northColy + temp1y) / 2,
      R = (c_northColx + m.x) / 2,
      F = (c_northColy + m.y) / 2,
      J = (c_northColx + temp2x) / 2,
      O = (c_northColy + temp2y) / 2,
      V = (c_northColx + y.x) / 2,
      G = (c_northColy + y.y) / 2,
      K = (c_northColx + temp3x) / 2,
      U = (c_northColy + temp3y) / 2,
      Q = (c_northColx + p.x) / 2,
      W = (c_northColy + p.y) / 2,
      Z = (c_northColx + m.x) / 2,
      X = (c_northColy + m.y) / 2,
      Y = (c_northColx + y.x) / 2,
      $ = (c_northColy + y.y) / 2,
      temp1x = (m.x + y.x) / 2,
      temp1y = (m.y + y.y) / 2,
      temp2x = (m.x + temp1x) / 2,
      temp2y = (m.y + temp1y) / 2,
      temp3x = (temp1x + y.x) / 2,
      temp3y = (temp1y + y.y) / 2,
      temp2x = (temp2x + temp1x) / 2,
      temp2y = (temp2y + temp1y) / 2,
      temp3x = (temp1x + temp3x) / 2,
      temp3y = (temp1y + temp3y) / 2,
      tt = (c_northColx + temp2x) / 2,
      et = (c_northColy + temp2y) / 2,
      pt = (c_northColx + temp3x) / 2,
      mt = (c_northColy + temp3y) / 2,
      yt = (Q + Z) / 2,
      it = (W + X) / 2,
      xt = (Q + Y) / 2,
      _t = (W + $) / 2,
      rt = c_northColx,
      nt = c_northColy,
      temp1x = (p.x + m.x) / 2,
      temp1y = (p.y + m.y) / 2,
      temp2x = (m.x + y.x) / 2,
      temp2y = (m.y + y.y) / 2,
      temp3x = (y.x + p.x) / 2,
      temp3y = (y.y + p.y) / 2,
      temp4x = (temp3x + temp2x) / 2,
      temp4y = (temp3y + temp2y) / 2,
      dt = (temp4x + temp3x) / 2,
      ot = (temp4y + temp3y) / 2,
      at = (temp4x + temp2x) / 2,
      st = (temp4y + temp2y) / 2,
      ut = (temp4x + y.x) / 2,
      lt = (temp4y + y.y) / 2,
      ct = (temp3x + temp1x) / 2,
      ht = (temp3y + temp1y) / 2,
      bt = (temp2x + temp1x) / 2,
      vt = (temp2y + temp1y) / 2,
      temp5x = (temp3x + p.x) / 2,
      temp5y = (temp3y + p.y) / 2,
      temp6x = (temp2x + m.x) / 2,
      temp6y = (temp2y + m.y) / 2,
      At = (ct + temp5x) / 2,
      gt = (ht + temp5y) / 2,
      wt = (bt + temp6x) / 2,
      ft = (vt + temp6y) / 2,
      temp1x = (p.x + m.x) / 2,
      temp1y = (p.y + m.y) / 2,
      temp2x = (m.x + y.x) / 2,
      temp2y = (m.y + y.y) / 2,
      temp3x = (y.x + p.x) / 2,
      temp3y = (y.y + p.y) / 2,
      temp4x = (temp3x + temp2x) / 2,
      temp4y = (temp3y + temp2y) / 2,
      Ct = (temp4x + temp3x) / 2,
      kt = (temp4y + temp3y) / 2,
      qt = (temp4x + temp2x) / 2,
      St = (temp4y + temp2y) / 2,
      Nt = (temp4x + y.x) / 2,
      Et = (temp4y + y.y) / 2,
      Mt = (temp3x + temp1x) / 2,
      Bt = (temp3y + temp1y) / 2,
      Lt = (temp2x + temp1x) / 2,
      Tt = (temp2y + temp1y) / 2,
      temp5x = (temp3x + p.x) / 2,
      temp5y = (temp3y + p.y) / 2,
      temp6x = (temp2x + m.x) / 2,
      temp6y = (temp2y + m.y) / 2,
      zt = (Mt + temp5x) / 2,
      jt = (Bt + temp5y) / 2,
      It = (Lt + temp6x) / 2,
      Dt = (Tt + temp6y) / 2,
      Pt = (Mt + Lt) / 2,
      Ht = (Bt + Tt) / 2,
      Rt = (Ct + qt) / 2,
      Ft = (kt + St) / 2) : 4 == Object.keys(e).length && (p = e.c_p1,
          m = e.c_p2,
          y = e.c_p3,
          i = e.c_p4,
          x = c_northColx = (p.x + y.x) / 2,
          _ = c_northColy = (p.y + y.y) / 2,
          r = (m.x + c_northColx) / 2,
          n = (m.y + c_northColy) / 2,
          d = (i.x + c_northColx) / 2,
          o = (i.y + c_northColy) / 2,
          a = (c_northColx + m.x) / 2,
          s = (c_northColy + m.y) / 2,
          u = (c_northColx + y.x) / 2,
          l = (c_northColy + y.y) / 2,
          c = (c_northColx + i.x) / 2,
          h = (c_northColy + i.y) / 2,
          b = (c_northColx + p.x) / 2,
          v = (c_northColy + p.y) / 2,
          A = (c_northColx + m.x) / 2,
          g = (c_northColy + m.y) / 2,
          w = (c_northColx + y.x) / 2,
          f = (c_northColy + y.y) / 2,
          C = (c_northColx + i.x) / 2,
          k = (c_northColy + i.y) / 2,
          q = c_northColx,
          S = c_northColy,
          N = (c_northColx + p.x) / 2,
          E = (c_northColy + p.y) / 2,
          M = (c_northColx + m.x) / 2,
          B = (c_northColy + m.y) / 2,
          L = (c_northColx + y.x) / 2,
          T = (c_northColy + y.y) / 2,
          z = (c_northColx + i.x) / 2,
          j = (c_northColy + i.y) / 2,
          I = (c_northColx + p.x) / 2,
          D = (c_northColy + p.y) / 2,
          P = (c_northColx + m.x) / 2,
          H = (c_northColy + m.y) / 2,
          R = (c_northColx + y.x) / 2,
          F = (c_northColy + y.y) / 2,
          J = (c_northColx + i.x) / 2,
          O = (c_northColy + i.y) / 2,
          V = (I + J) / 2,
          G = (D + O) / 2,
          K = (P + R) / 2,
          U = (H + F) / 2,
          temp1x = (c_northColx + p.x) / 2,
          temp1y = (c_northColy + p.y) / 2,
          temp2x = (c_northColx + m.x) / 2,
          temp2y = (c_northColy + m.y) / 2,
          Q = (temp1x + temp2x) / 2,
          W = (temp1y + temp2y) / 2,
          Z = (c_northColx + y.x) / 2,
          X = (c_northColy + y.y) / 2,
          Y = (c_northColx + i.x) / 2,
          $ = (c_northColy + i.y) / 2,
          tt = c_northColx,
          et = c_northColy,
          pt = (Y + temp1x) / 2,
          mt = ($ + temp1y) / 2,
          yt = (Z + temp2x) / 2,
          it = (X + temp2y) / 2,
          xt = (Z + Y) / 2,
          _t = (X + $) / 2,
          rt = (c_northColx + p.x) / 2,
          nt = (c_northColy + p.y) / 2,
          dt = (c_northColx + m.x) / 2,
          ot = (c_northColy + m.y) / 2,
          at = (c_northColx + y.x) / 2,
          st = (c_northColy + y.y) / 2,
          ut = (c_northColx + i.x) / 2,
          lt = (c_northColy + i.y) / 2,
          ct = (rt + dt) / 2,
          ht = (nt + ot) / 2,
          bt = (ut + at) / 2,
          vt = (lt + st) / 2,
          temp1x = (rt + ut) / 2,
          temp1y = (nt + lt) / 2,
          temp2x = (dt + at) / 2,
          temp2y = (ot + st) / 2,
          At = (temp1x + c_northColx) / 2,
          gt = (temp1y + c_northColy) / 2,
          wt = (temp2x + c_northColx) / 2,
          ft = (temp2y + c_northColy) / 2,
          Ct = (c_northColx + p.x) / 2,
          kt = (c_northColy + p.y) / 2,
          qt = (c_northColx + m.x) / 2,
          St = (c_northColy + m.y) / 2,
          Nt = (c_northColx + y.x) / 2,
          Et = (c_northColy + y.y) / 2,
          Mt = (c_northColx + i.x) / 2,
          Bt = (c_northColy + i.y) / 2,
          Lt = c_northColx,
          Tt = c_northColy,
          zt = (Ct + qt) / 2,
          jt = (kt + St) / 2,
          It = (qt + Nt) / 2,
          Dt = (St + Et) / 2,
          Pt = (Nt + Mt) / 2,
          Ht = (Et + Bt) / 2,
          Rt = (Mt + Ct) / 2,
          Ft = (Bt + kt) / 2),
      1 == t) {
      var Ot = {};
      return Ot.x = x,
          Ot.y = _,
          Jt.push(Ot),
          Jt
  }
  if (2 == t) {
      var Ot = {};
      Ot.x = r,
          Ot.y = n,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = d,
          Ot.y = o,
          Jt.push(Ot),
          Jt
  }
  if (3 == t) {
      var Ot = {};
      Ot.x = a,
          Ot.y = s,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = u,
          Ot.y = l,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = c,
          Ot.y = h,
          Jt.push(Ot),
          Jt
  }
  if (4 == t) {
      var Ot = {};
      Ot.x = b,
          Ot.y = v,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = A,
          Ot.y = g,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = w,
          Ot.y = f,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = C,
          Ot.y = k,
          Jt.push(Ot),
          Jt
  }
  if (5 == t) {
      var Ot = {};
      Ot.x = q,
          Ot.y = S,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = N,
          Ot.y = E,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = M,
          Ot.y = B,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = L,
          Ot.y = T,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = z,
          Ot.y = j,
          Jt.push(Ot),
          Jt
  }
  if (6 == t) {
      var Ot = {};
      Ot.x = I,
          Ot.y = D,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = P,
          Ot.y = H,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = R,
          Ot.y = F,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = J,
          Ot.y = O,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = V,
          Ot.y = G,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = K,
          Ot.y = U,
          Jt.push(Ot),
          Jt
  }
  if (7 == t) {
      var Ot = {};
      Ot.x = Q,
          Ot.y = W,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = Z,
          Ot.y = X,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = Y,
          Ot.y = $,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = tt,
          Ot.y = et,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = pt,
          Ot.y = mt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = yt,
          Ot.y = it,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = xt,
          Ot.y = _t,
          Jt.push(Ot),
          Jt
  }
  if (8 == t) {
      var Ot = {};
      Ot.x = rt,
          Ot.y = nt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = dt,
          Ot.y = ot,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = at,
          Ot.y = st,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = ut,
          Ot.y = lt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = ct,
          Ot.y = ht,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = bt,
          Ot.y = vt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = At,
          Ot.y = gt,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = wt,
          Ot.y = ft,
          Jt.push(Ot),
          Jt
  }
  if (9 == t) {
      var Ot = {};
      Ot.x = Ct,
          Ot.y = kt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = qt,
          Ot.y = St,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = Nt,
          Ot.y = Et,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = Mt,
          Ot.y = Bt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = Lt,
          Ot.y = Tt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = zt,
          Ot.y = jt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = It,
          Ot.y = Dt,
          Jt.push(Ot);
      var Ot = {};
      Ot.x = Pt,
          Ot.y = Ht,
          Jt.push(Ot);
      var Ot = {};
      return Ot.x = Rt,
          Ot.y = Ft,
          Jt.push(Ot),
          Jt
  }
}
function lag_no_to_house_no_north(t, e) {
  var p = 0;
  return parseInt(e) <= parseInt(t) ? p = parseInt(t) - parseInt(e) + 1 : parseInt(e) > parseInt(t) && (p = 12 + (parseInt(t) - parseInt(e) + 1)),
      p
}
function lag_no_to_house_no_east(t, e) {//mangala - SL bug issue
  var p = 0;
  return parseInt(e) >= parseInt(t) ? p = parseInt(e) - parseInt(t) + 1 : parseInt(e) < parseInt(t) && (p = 12 + (parseInt(e) - parseInt(t) + 1)),
      p
}
function createJSON(t, e, p) {
  var m, y, i, x, _, r, n, d, o, a, s, u, l, c, h, b, v, A, g, w, f, C, k, q, S, N, E, M, B, L, T, z, j, I, D, P, H, R, F, J, O, V, G, K, U, Q, W, Z, X, Y, $, tt, et, pt, mt, yt, it, xt, _t, rt, nt, dt, ot, at, st, ut, lt, ct, ht, bt, vt, At, gt, wt, ft, Ct, kt, qt, St, Nt, Et, Mt, Bt, Lt, Tt, zt, jt, It, Dt, Pt, Ht, Rt, Ft, Jt, Ot, Vt, Gt, Kt, Ut, Qt, Wt, Zt, Xt, Yt, $t, te, ee = {}, pe = {};
  m = t,
      y = e,
      pe.x = m,
      pe.y = y;
  var me = {};
  i = t + p,
      x = e,
      me.x = i,
      me.y = x;
  var ye = {};
  _ = t + p,
      r = e + p,
      ye.x = _,
      ye.y = r;
  var ie = {};
  n = t,
      d = e + p,
      ie.x = n,
      ie.y = d;
  var xe = {};
  O = (m + _) / 2,
      V = (y + r) / 2,
      xe.x = O,
      xe.y = V;
  var _e = {};
  o = (m + i) / 2,
      a = (y + x) / 2,
      _e.x = o,
      _e.y = a;
  var re = {};
  s = (i + _) / 2,
      u = (x + r) / 2,
      re.x = s,
      re.y = u;
  var ne = {};
  l = (_ + n) / 2,
      c = (r + d) / 2,
      ne.x = l,
      ne.y = c;
  var de = {};
  h = (n + m) / 2,
      b = (d + y) / 2,
      de.x = h,
      de.y = b;
  var oe = {};
  v = (m + o) / 2,
      A = (y + a) / 2,
      oe.x = v,
      oe.y = A;
  var ae = {};
  g = (o + i) / 2,
      w = (a + x) / 2,
      ae.x = g,
      ae.y = w;
  var se = {};
  f = (i + s) / 2,
      C = (x + u) / 2,
      se.x = f,
      se.y = C;
  var ue = {};
  k = (s + _) / 2,
      q = (u + r) / 2,
      ue.x = k,
      ue.y = q;
  var le = {};
  S = (_ + l) / 2,
      N = (r + c) / 2,
      le.x = S,
      le.y = N;
  var ce = {};
  E = (l + n) / 2,
      M = (c + d) / 2,
      ce.x = E,
      ce.y = M;
  var he = {};
  B = (n + h) / 2,
      L = (d + b) / 2,
      he.x = B,
      he.y = L;
  var be = {};
  T = (h + m) / 2,
      z = (b + y) / 2,
      be.x = T,
      be.y = z;
  var ve = {};
  j = (T + f) / 2,
      I = (z + C) / 2,
      ve.x = j,
      ve.y = I;
  var Ae = {};
  D = (g + S) / 2,
      P = (w + N) / 2,
      Ae.x = D,
      Ae.y = P;
  var ge = {};
  H = (k + B) / 2,
      R = (q + L) / 2,
      ge.x = H,
      ge.y = R;
  var we = {};
  F = (E + v) / 2,
      J = (M + A) / 2,
      we.x = F,
      we.y = J;
  var fe = {};
  G = (m + O) / 2,
      K = (y + V) / 2,
      fe.x = G,
      fe.y = K;
  var Ce = {};
  U = (i + O) / 2,
      Q = (x + V) / 2,
      Ce.x = U,
      Ce.y = Q;
  var ke = {};
  W = (_ + O) / 2,
      Z = (r + V) / 2,
      ke.x = W,
      ke.y = Z;
  var qe = {};
  X = (n + O) / 2,
      Y = (d + V) / 2,
      qe.x = X,
      qe.y = Y;
  var Se = {};
  $ = (G + v) / 2,
      tt = (K + A) / 2,
      Se.x = $,
      Se.y = tt;
  var Ne = {};
  lt = (j + o) / 2,
      ct = (I + a) / 2,
      Ne.x = lt,
      Ne.y = ct;
  var Ee = {};
  et = (U + g) / 2,
      pt = (Q + w) / 2,
      Ee.x = et,
      Ee.y = pt;
  var Me = {};
  ht = (j + o) / 2,
      bt = (I + a) / 2,
      Me.x = ht,
      Me.y = bt;
  var Be = {};
  mt = (U + f) / 2,
      yt = (Q + C) / 2,
      Be.x = mt,
      Be.y = yt;
  var Le = {};
  vt = (D + s) / 2,
      At = (P + u) / 2,
      Le.x = vt,
      Le.y = At;
  var Te = {};
  it = (W + k) / 2,
      xt = (Z + q) / 2,
      Te.x = it,
      Te.y = xt;
  var ze = {};
  gt = (D + s) / 2,
      wt = (P + u) / 2,
      ze.x = gt,
      ze.y = wt;
  var je = {};
  _t = (W + S) / 2,
      rt = (Z + N) / 2,
      je.x = _t,
      je.y = rt;
  var Ie = {};
  ft = (H + l) / 2,
      Ct = (R + c) / 2,
      Ie.x = ft,
      Ie.y = Ct;
  var De = {};
  nt = (X + E) / 2,
      dt = (Y + M) / 2,
      De.x = nt,
      De.y = dt;
  var Pe = {};
  kt = (H + l) / 2,
      qt = (R + c) / 2,
      Pe.x = kt,
      Pe.y = qt;
  var He = {};
  ot = (X + B) / 2,
      at = (Y + L) / 2,
      He.x = ot,
      He.y = at;
  var Re = {};
  St = (F + h) / 2,
      Nt = (J + b) / 2,
      Re.x = St,
      Re.y = Nt;
  var Fe = {};
  st = (G + T) / 2,
      ut = (K + z) / 2,
      Fe.x = st,
      Fe.y = ut;
  var Je = {};
  Et = (F + h) / 2,
      Mt = (J + b) / 2,
      Je.x = Et,
      Je.y = Mt;
  var Oe = {};
  Bt = m + (i - m) / 3,
      Lt = y + (x - y) / 3,
      Oe.x = Bt,
      Oe.y = Lt;
  var Ve = {};
  Tt = m + 2 * (i - m) / 3,
      zt = y + 2 * (x - y) / 3,
      Ve.x = Tt,
      Ve.y = zt;
  var Ge = {};
  jt = i + (_ - i) / 3,
      It = x + (r - x) / 3,
      Ge.x = jt,
      Ge.y = It;
  var Ke = {};
  Dt = i + 2 * (_ - i) / 3,
      Pt = x + 2 * (r - x) / 3,
      Ke.x = Dt,
      Ke.y = Pt;
  var Ue = {};
  Ht = _ + (n - _) / 3,
      Rt = r + (d - r) / 3,
      Ue.x = Ht,
      Ue.y = Rt;
  var Qe = {};
  Ft = _ + 2 * (n - _) / 3,
      Jt = r + 2 * (d - r) / 3,
      Qe.x = Ft,
      Qe.y = Jt;
  var We = {};
  Ot = n + (m - n) / 3,
      Vt = d + (y - d) / 3,
      We.x = Ot,
      We.y = Vt;
  var Ze = {};
  Gt = n + 2 * (m - n) / 3,
      Kt = d + 2 * (y - d) / 3,
      Ze.x = Gt,
      Ze.y = Kt;
  var Xe = {};
  Ut = Gt + (jt - Gt) / 3,
      Qt = Kt + (It - Kt) / 3,
      Xe.x = Ut,
      Xe.y = Qt;
  var Ye = {};
  Wt = Gt + 2 * (jt - Gt) / 3,
      Zt = Kt + 2 * (It - Kt) / 3,
      Ye.x = Wt,
      Ye.y = Zt;
  var $e = {};
  Xt = Ot + 2 * (Dt - Ot) / 3,
      Yt = Vt + 2 * (Pt - Vt) / 3,
      $e.x = Xt,
      $e.y = Yt;
  var tp = {};
  return $t = Ot + (Dt - Ot) / 3,
      te = Vt + (Pt - Vt) / 3,
      tp.x = $t,
      tp.y = te,
      ee.p1 = pe,
      ee.p2 = me,
      ee.p3 = ye,
      ee.p4 = ie,
      ee.center_chart = xe,
      ee.p1p2 = _e,
      ee.p2p3 = re,
      ee.p3p4 = ne,
      ee.p4p1 = de,
      ee.p1_p1p2 = oe,
      ee.p1p2_p2 = ae,
      ee.p1p2_p2 = ae,
      ee.p2_p2p3 = se,
      ee.p2p3_p3 = ue,
      ee.p3_p3p4 = le,
      ee.p3p4_p4 = ce,
      ee.p4_p4p1 = he,
      ee.p4p1_p1 = be,
      ee.mid_top = ve,
      ee.mid_right = Ae,
      ee.mid_bottom = ge,
      ee.mid_left = we,
      ee.center_p1 = fe,
      ee.center_p2 = Ce,
      ee.center_p3 = ke,
      ee.center_p4 = qe,
      ee.startnode1 = Se,
      ee.startnode2 = Ee,
      ee.startnode3 = Be,
      ee.startnode4 = Te,
      ee.startnode5 = je,
      ee.startnode6 = De,
      ee.startnode7 = He,
      ee.startnode8 = Fe,
      ee.endnode1 = Ne,
      ee.endnode2 = Me,
      ee.endnode3 = Le,
      ee.endnode4 = ze,
      ee.endnode5 = Ie,
      ee.endnode6 = Pe,
      ee.endnode7 = Re,
      ee.endnode8 = Je,
      ee.p1p2_1 = Oe,
      ee.p1p2_2 = Ve,
      ee.p2p3_1 = Ge,
      ee.p2p3_2 = Ke,
      ee.p3p4_1 = Ue,
      ee.p3p4_2 = Qe,
      ee.p4p1_1 = We,
      ee.p4p1_2 = Ze,
      ee.east_center_p1 = Xe,
      ee.east_center_p2 = Ye,
      ee.east_center_p3 = $e,
      ee.east_center_p4 = tp,
      ee
}
function create_South_chart_path(t) {
  var e = " M "
      , p = " L "
      , m = " ";
  return e + t.p1.x + m + t.p1.y + p + t.p2.x + m + t.p2.y + p + t.p3.x + m + t.p3.y + p + t.p4.x + m + t.p4.y + p + t.p1.x + m + t.p1.y + e + t.p1_p1p2.x + m + t.p1_p1p2.y + p + t.p3p4_p4.x + m + t.p3p4_p4.y + e + t.p1p2_p2.x + m + t.p1p2_p2.y + p + t.p3_p3p4.x + m + t.p3_p3p4.y + e + t.p4p1_p1.x + m + t.p4p1_p1.y + p + t.p2_p2p3.x + m + t.p2_p2p3.y + e + t.p4_p4p1.x + m + t.p4_p4p1.y + p + t.p2p3_p3.x + m + t.p2p3_p3.y + e + t.p1p2.x + m + t.p1p2.y + p + t.mid_top.x + m + t.mid_top.y + e + t.p2p3.x + m + t.p2p3.y + p + t.mid_right.x + m + t.mid_right.y + e + t.p3p4.x + m + t.p3p4.y + p + t.mid_bottom.x + m + t.mid_bottom.y + e + t.p4p1.x + m + t.p4p1.y + p + t.mid_left.x + m + t.mid_left.y
}
function create_North_chart_path(t) {
  var e = " M "
      , p = " L "
      , m = " ";
  return e + t.p1.x + m + t.p1.y + p + t.p2.x + m + t.p2.y + p + t.p3.x + m + t.p3.y + p + t.p4.x + m + t.p4.y + p + t.p1.x + m + t.p1.y + p + t.p3.x + m + t.p3.y + e + t.p2.x + m + t.p2.y + p + t.p4.x + m + t.p4.y + e + t.p1p2.x + m + t.p1p2.y + p + t.p2p3.x + m + t.p2p3.y + p + t.p3p4.x + m + t.p3p4.y + p + t.p4p1.x + m + t.p4p1.y + p + t.p1p2.x + m + t.p1p2.y
}
function create_North_antique_chart_path(t) {
  var e = " M "
      , p = " L "
      , m = " "
      , y = " C "
      , i = ",";
  return e + t.p1.x + m + t.p1.y + p + t.p2.x + m + t.p2.y + p + t.p3.x + m + t.p3.y + p + t.p4.x + m + t.p4.y + p + t.p1.x + m + t.p1.y + p + t.p3.x + m + t.p3.y + e + t.p2.x + m + t.p2.y + p + t.p4.x + m + t.p4.y + e + t.center_p1.x + m + t.center_p1.y + y + t.startnode1.x + i + t.startnode1.y + m + t.endnode1.x + i + t.endnode1.y + m + t.p1p2.x + i + t.p1p2.y + e + t.center_p2.x + m + t.center_p2.y + y + t.startnode2.x + i + t.startnode2.y + m + t.endnode2.x + i + t.endnode2.y + m + t.p1p2.x + i + t.p1p2.y + e + t.center_p2.x + m + t.center_p2.y + y + t.startnode3.x + i + t.startnode3.y + m + t.endnode3.x + i + t.endnode3.y + m + t.p2p3.x + i + t.p2p3.y + e + t.center_p3.x + m + t.center_p3.y + y + t.startnode4.x + i + t.startnode4.y + m + t.endnode4.x + i + t.endnode4.y + m + t.p2p3.x + i + t.p2p3.y + e + t.center_p3.x + m + t.center_p3.y + y + t.startnode5.x + i + t.startnode5.y + m + t.endnode5.x + i + t.endnode5.y + m + t.p3p4.x + i + t.p3p4.y + e + t.center_p4.x + m + t.center_p4.y + y + t.startnode6.x + i + t.startnode6.y + m + t.endnode6.x + i + t.endnode6.y + m + t.p3p4.x + i + t.p3p4.y + e + t.center_p4.x + m + t.center_p4.y + y + t.startnode7.x + i + t.startnode7.y + m + t.endnode7.x + i + t.endnode7.y + m + t.p4p1.x + i + t.p4p1.y + e + t.center_p1.x + m + t.center_p1.y + y + t.startnode8.x + i + t.startnode8.y + m + t.endnode8.x + i + t.endnode8.y + m + t.p4p1.x + i + t.p4p1.y
}
function create_East_chart_path(t) {
  var e = " M "
      , p = " L "
      , m = " ";
  return e + t.p1.x + m + t.p1.y + p + t.p2.x + m + t.p2.y + p + t.p3.x + m + t.p3.y + p + t.p4.x + m + t.p4.y + p + t.p1.x + m + t.p1.y + e + t.p1p2_1.x + m + t.p1p2_1.y + p + t.p3p4_2.x + m + t.p3p4_2.y + e + t.p1p2_2.x + m + t.p1p2_2.y + p + t.p3p4_1.x + m + t.p3p4_1.y + e + t.p4p1_2.x + m + t.p4p1_2.y + p + t.p2p3_1.x + m + t.p2p3_1.y + e + t.p4p1_1.x + m + t.p4p1_1.y + p + t.p2p3_2.x + m + t.p2p3_2.y + e + t.p1.x + m + t.p1.y + p + t.east_center_p1.x + m + t.east_center_p1.y + e + t.p2.x + m + t.p2.y + p + t.east_center_p2.x + m + t.east_center_p2.y + e + t.p3.x + m + t.p3.y + p + t.east_center_p3.x + m + t.east_center_p3.y + e + t.p4.x + m + t.p4.y + p + t.east_center_p4.x + m + t.east_center_p4.y
}
function setDesignFor_B_S1(t, e, p) {
  var m, y, i, x, _, r, n, d, o, a, s, u, l, c, h, b, v, A, g, w, f, C, k, q, S, N, E, M, B, L, T, z, j, I, D, P, H, R, F, J, O, V, G, K, U, Q, W, Z, X, Y, $, tt;
  m = e.x,
      i = e.x + e.length,
      _ = e.x + e.length,
      n = e.x,
      y = e.y,
      x = e.y,
      r = e.y + e.length,
      d = e.y + e.length,
      o = t.x,
      s = t.x + t.length,
      l = t.x + t.length,
      h = t.x,
      a = t.y,
      u = t.y,
      c = t.y + t.length,
      b = t.y + t.length;
  var et = .5 * e.length / 100;
  var tempmidx = (o + m) / 2,
      tempmidy = (a + y) / 2,
      tempx = (o + tempmidx) / 2,
      tempy = (a + tempmidy) / 2,
      tempx = (o + tempx) / 2,
      tempy = (a + tempy) / 2,
      for_in_p1x = X = tempx,
      for_in_p1y = Y = tempy,
      tempmidx = (s + i) / 2,
      tempmidy = (u + x) / 2,
      tempx = (s + tempmidx) / 2,
      tempy = (u + tempmidy) / 2,
      tempx = (s + tempx) / 2,
      tempy = (u + tempy) / 2,
      $ = tempx,
      tt = tempy;
  var pt = document.getElementById("svg" + p)
      , mt = document.createElementNS("http://www.w3.org/2000/svg", "line");
  mt.setAttribute("x1", X),
      mt.setAttribute("x2", $),
      mt.setAttribute("y1", Y),
      mt.setAttribute("y2", tt),
      mt.setAttribute("stroke", "black"),
      mt.setAttribute("stroke-width", et),
      pt.appendChild(mt),
      tempmidx = (s + i) / 2,
      tempmidy = (u + x) / 2,
      tempx = (s + tempmidx) / 2,
      tempy = (u + tempmidy) / 2,
      tempx = (s + tempx) / 2,
      tempy = (u + tempy) / 2,
      s = X = tempx,
      u = Y = tempy,
      tempmidx = (l + _) / 2,
      tempmidy = (c + r) / 2,
      tempx = (l + tempmidx) / 2,
      tempy = (c + tempmidy) / 2,
      tempx = (l + tempx) / 2,
      tempy = (c + tempy) / 2,
      $ = tempx,
      tt = tempy;
  var mt = document.createElementNS("http://www.w3.org/2000/svg", "line");
  mt.setAttribute("x1", X),
      mt.setAttribute("x2", $),
      mt.setAttribute("y1", Y),
      mt.setAttribute("y2", tt),
      mt.setAttribute("stroke", "black"),
      mt.setAttribute("stroke-width", et),
      pt.appendChild(mt),
      tempmidx = (l + _) / 2,
      tempmidy = (c + r) / 2,
      tempx = (l + tempmidx) / 2,
      tempy = (c + tempmidy) / 2,
      tempx = (l + tempx) / 2,
      tempy = (c + tempy) / 2,
      l = X = tempx,
      c = Y = tempy,
      tempmidx = (h + n) / 2,
      tempmidy = (b + d) / 2,
      tempx = (h + tempmidx) / 2,
      tempy = (b + tempmidy) / 2,
      tempx = (h + tempx) / 2,
      tempy = (b + tempy) / 2,
      $ = tempx,
      tt = tempy;
  var mt = document.createElementNS("http://www.w3.org/2000/svg", "line");
  mt.setAttribute("x1", X),
      mt.setAttribute("x2", $),
      mt.setAttribute("y1", Y),
      mt.setAttribute("y2", tt),
      mt.setAttribute("stroke", "black"),
      mt.setAttribute("stroke-width", et),
      pt.appendChild(mt),
      tempmidx = (h + n) / 2,
      tempmidy = (b + d) / 2,
      tempx = (h + tempmidx) / 2,
      tempy = (b + tempmidy) / 2,
      tempx = (h + tempx) / 2,
      tempy = (b + tempy) / 2,
      h = X = tempx,
      b = Y = tempy,
      tempmidx = (o + m) / 2,
      tempmidy = (a + y) / 2,
      tempx = (o + tempmidx) / 2,
      tempy = (a + tempmidy) / 2,
      tempx = (o + tempx) / 2,
      tempy = (a + tempy) / 2,
      $ = tempx,
      tt = tempy,
      o = for_in_p1x,
      a = for_in_p1y;
  var mt = document.createElementNS("http://www.w3.org/2000/svg", "line");
  mt.setAttribute("x1", X),
      mt.setAttribute("x2", $),
      mt.setAttribute("y1", Y),
      mt.setAttribute("y2", tt),
      mt.setAttribute("stroke", "black"),
      mt.setAttribute("stroke-width", et),
      pt.appendChild(mt),
      j = (o + s) / 2,
      I = (a + u) / 2,
      D = (s + l) / 2,
      P = (u + c) / 2,
      H = (l + h) / 2,
      R = (c + b) / 2,
      F = (h + o) / 2,
      J = (b + a) / 2,
      O = (m + i) / 2,
      V = (y + x) / 2,
      G = (i + _) / 2,
      K = (x + r) / 2,
      U = (_ + n) / 2,
      Q = (r + d) / 2,
      W = (n + m) / 2,
      Z = (d + y) / 2,
      tempx = (m + o) / 2,
      v = (m + tempx) / 2,
      tempy = (y + a) / 2,
      A = (y + tempy) / 2,
      tempx = (i + s) / 2,
      g = (i + tempx) / 2,
      tempy = (x + u) / 2,
      w = (x + tempy) / 2,
      tempx = (_ + l) / 2,
      f = (_ + tempx) / 2,
      tempy = (r + c) / 2,
      C = (r + tempy) / 2,
      tempx = (n + h) / 2,
      k = (n + tempx) / 2,
      tempy = (d + b) / 2,
      q = (d + tempy) / 2,
      S = (m + i) / 2,
      E = (i + _) / 2,
      B = (_ + n) / 2,
      T = (n + m) / 2,
      N = (y + x) / 2,
      M = (x + r) / 2,
      L = (r + d) / 2,
      z = (d + y) / 2;
  var yt, it, xt, _t, rt, nt, dt, ot, at, st, ut, lt, ct, ht, bt, vt, At, gt, wt, ft, Ct, kt, qt, St, Nt, Et, Mt, Bt, Lt, Tt, zt, jt, It, Dt, Pt, Ht, Rt, Ft, Jt, Ot, Vt, Gt, Kt, Ut, Qt, Wt, Zt, Xt, Yt, $t, te, ee, pe, me, ye, ie, xe, _e, re, ne, de, oe, ae, se, ue, le, ce, he, be, ve, Ae, ge, we, fe, Ce, ke, qe, Se, Ne, Ee, Me, Be, Le, Te, ze, je, Ie, De, Pe, He, Re, Fe, Je, Oe, Ve, Ge, Ke, Ue, Qe, We, Ze, Xe, Ye, $e, tp, ep, pp, mp, yp, ip, xp, _p, rp, np, dp, op, ap, sp, up, lp, cp, hp, bp, vp, Ap, gp, wp, fp, Cp, kp, qp, Sp, Np, Ep, Mp, Bp, Lp, Tp, zp, jp, Ip, Dp, Pp, Hp, Rp, Fp, Jp, Op, Vp, Gp, Kp, Up, Qp, Wp, Zp, Xp, Yp, $p, tm, em, pm, mm, ym, im, xm, _m, rm, nm, dm, om, am, sm, um, lm, cm, hm;
  var temp1x = o + (j - o) / 3,
      tempmidx = (o + j) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      Me = tempx,
      temp1y = a + (I - a) / 3,
      tempmidy = (a + I) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      Be = tempy,
      temp3x = m + 2 * (O - m) / 3,
      temp3midx = (m + O) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = y + 2 * (V - y) / 3,
      temp3midy = (y + V) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (o + temp_q_topx) / 2,
      temp6y = (a + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      Le = tempx,
      Te = tempy,
      ze = o,
      je = a,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      at = temp7x,
      st = temp7y,
      temp8x = o + (j - o) / 3,
      temp8y = a + (I - a) / 3,
      temp9x = o + 2 * (j - o) / 3,
      temp9y = a + 2 * (I - a) / 3,
      temp10x = (at + temp8x) / 2,
      temp10y = (st + temp8y) / 2,
      temp11x = (at + temp9x) / 2,
      temp11y = (st + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      yt = temp15x,
      it = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      rt = temp15x,
      nt = temp15y,
      xt = (tempmidx + at) / 2,
      _t = (tempmidy + st) / 2,
      dt = (tempmidx + at) / 2,
      ot = (tempmidy + st) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      Ie = temp12x,
      De = temp12y,
      temp13x = (j + temp_q_topx) / 2,
      temp13y = (I + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      Pe = tempx,
      He = tempy,
      Re = j,
      Fe = I,
      temp1x = s + (j - s) / 3,
      tempmidx = (s + j) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      Qe = tempx,
      temp1y = u + (I - u) / 3,
      tempmidy = (u + I) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      We = tempy,
      temp3x = i + 2 * (O - i) / 3,
      temp3midx = (i + O) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = x + 2 * (V - x) / 3,
      temp3midy = (x + V) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (s + temp_q_topx) / 2,
      temp6y = (u + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      Ze = tempx,
      Xe = tempy,
      Ye = s,
      $e = u,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      wt = temp7x,
      ft = temp7y,
      temp8x = s + (j - s) / 3,
      temp8y = u + (I - u) / 3,
      temp9x = s + 2 * (j - s) / 3,
      temp9y = u + 2 * (I - u) / 3,
      temp10x = (wt + temp8x) / 2,
      temp10y = (ft + temp8y) / 2,
      temp11x = (wt + temp9x) / 2,
      temp11y = (ft + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      bt = temp15x,
      vt = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      ut = temp15x,
      lt = temp15y,
      At = (tempmidx + wt) / 2,
      gt = (tempmidy + ft) / 2,
      ct = (tempmidx + wt) / 2,
      ht = (tempmidy + ft) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      Je = temp12x,
      Oe = temp12y,
      temp13x = (j + temp_q_topx) / 2,
      temp13y = (I + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      Ve = tempx,
      Ge = tempy,
      Ke = j,
      Ue = I,
      temp1x = s + (D - s) / 3,
      tempmidx = (s + D) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      tp = tempx,
      temp1y = u + (P - u) / 3,
      tempmidy = (u + P) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      ep = tempy,
      temp3x = i + 2 * (G - i) / 3,
      temp3midx = (i + G) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = x + 2 * (K - x) / 3,
      temp3midy = (x + K) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (s + temp_q_topx) / 2,
      temp6y = (u + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      pp = tempx,
      mp = tempy,
      yp = s,
      ip = u,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      Lt = temp7x,
      Tt = temp7y,
      temp8x = s + (D - s) / 3,
      temp8y = u + (P - u) / 3,
      temp9x = s + 2 * (D - s) / 3,
      temp9y = u + 2 * (P - u) / 3,
      temp10x = (Lt + temp8x) / 2,
      temp10y = (Tt + temp8y) / 2,
      temp11x = (Lt + temp9x) / 2,
      temp11y = (Tt + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      Ct = temp15x,
      kt = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      Nt = temp15x,
      Et = temp15y,
      qt = (tempmidx + Lt) / 2,
      St = (tempmidy + Tt) / 2,
      Mt = (tempmidx + Lt) / 2,
      Bt = (tempmidy + Tt) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      xp = temp12x,
      _p = temp12y,
      temp13x = (D + temp_q_topx) / 2,
      temp13y = (P + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      rp = tempx,
      np = tempy,
      dp = D,
      op = P,
      temp1x = l + (D - l) / 3,
      tempmidx = (l + D) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      bp = tempx,
      temp1y = c + (P - c) / 3,
      tempmidy = (c + P) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      vp = tempy,
      temp3x = _ + 2 * (G - _) / 3,
      temp3midx = (_ + G) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = r + 2 * (K - r) / 3,
      temp3midy = (r + K) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (l + temp_q_topx) / 2,
      temp6y = (c + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      Ap = tempx,
      gp = tempy,
      wp = l,
      fp = c,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      Jt = temp7x,
      Ot = temp7y,
      temp8x = l + (D - l) / 3,
      temp8y = c + (P - c) / 3,
      temp9x = l + 2 * (D - l) / 3,
      temp9y = c + 2 * (P - c) / 3,
      temp10x = (Jt + temp8x) / 2,
      temp10y = (Ot + temp8y) / 2,
      temp11x = (Jt + temp9x) / 2,
      temp11y = (Ot + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      Pt = temp15x,
      Ht = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      zt = temp15x,
      jt = temp15y,
      Rt = (tempmidx + Jt) / 2,
      Ft = (tempmidy + Ot) / 2,
      It = (tempmidx + Jt) / 2,
      Dt = (tempmidy + Ot) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      ap = temp12x,
      sp = temp12y,
      temp13x = (D + temp_q_topx) / 2,
      temp13y = (P + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      up = tempx,
      lp = tempy,
      cp = D,
      hp = P,
      temp1x = l + (H - l) / 3,
      tempmidx = (l + H) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      Cp = tempx,
      temp1y = c + (R - c) / 3,
      tempmidy = (c + R) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      kp = tempy,
      temp3x = _ + 2 * (U - _) / 3,
      temp3midx = (_ + U) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = r + 2 * (Q - r) / 3,
      temp3midy = (r + Q) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (l + temp_q_topx) / 2,
      temp6y = (c + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      qp = tempx,
      Sp = tempy,
      Np = l,
      Ep = c,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      Yt = temp7x,
      $t = temp7y,
      temp8x = l + (H - l) / 3,
      temp8y = c + (R - c) / 3,
      temp9x = l + 2 * (H - l) / 3,
      temp9y = c + 2 * (R - c) / 3,
      temp10x = (Yt + temp8x) / 2,
      temp10y = ($t + temp8y) / 2,
      temp11x = (Yt + temp9x) / 2,
      temp11y = ($t + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      Vt = temp15x,
      Gt = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      Qt = temp15x,
      Wt = temp15y,
      Kt = (tempmidx + Yt) / 2,
      Ut = (tempmidy + $t) / 2,
      Zt = (tempmidx + Yt) / 2,
      Xt = (tempmidy + $t) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      Mp = temp12x,
      Bp = temp12y,
      temp13x = (H + temp_q_topx) / 2,
      temp13y = (R + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      Lp = tempx,
      Tp = tempy,
      zp = H,
      jp = R,
      temp1x = h + (H - h) / 3,
      tempmidx = (h + H) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      Jp = tempx,
      temp1y = b + (R - b) / 3,
      tempmidy = (b + R) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      Op = tempy,
      temp3x = n + 2 * (U - n) / 3,
      temp3midx = (n + U) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = d + 2 * (Q - d) / 3,
      temp3midy = (d + Q) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (h + temp_q_topx) / 2,
      temp6y = (b + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      Vp = tempx,
      Gp = tempy,
      Kp = h,
      Up = b,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      re = temp7x,
      ne = temp7y,
      temp8x = h + (H - h) / 3,
      temp8y = b + (R - b) / 3,
      temp9x = h + 2 * (H - h) / 3,
      temp9y = b + 2 * (R - b) / 3,
      temp10x = (re + temp8x) / 2,
      temp10y = (ne + temp8y) / 2,
      temp11x = (re + temp9x) / 2,
      temp11y = (ne + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      ye = temp15x,
      ie = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      te = temp15x,
      ee = temp15y,
      xe = (tempmidx + re) / 2,
      _e = (tempmidy + ne) / 2,
      pe = (tempmidx + re) / 2,
      me = (tempmidy + ne) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      Ip = temp12x,
      Dp = temp12y,
      temp13x = (H + temp_q_topx) / 2,
      temp13y = (R + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      Pp = tempx,
      Hp = tempy,
      Rp = H,
      Fp = R,
      temp1x = h + (F - h) / 3,
      tempmidx = (h + F) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      Qp = tempx,
      temp1y = b + (J - b) / 3,
      tempmidy = (b + J) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      Wp = tempy,
      temp3x = n + 2 * (W - n) / 3,
      temp3midx = (n + W) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = d + 2 * (Z - d) / 3,
      temp3midy = (d + Z) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (h + temp_q_topx) / 2,
      temp6y = (b + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      Zp = tempx,
      Xp = tempy,
      Yp = h,
      $p = b,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      be = temp7x,
      ve = temp7y,
      temp8x = h + (F - h) / 3,
      temp8y = b + (J - b) / 3,
      temp9x = h + 2 * (F - h) / 3,
      temp9y = b + 2 * (J - b) / 3,
      temp10x = (be + temp8x) / 2,
      temp10y = (ve + temp8y) / 2,
      temp11x = (be + temp9x) / 2,
      temp11y = (ve + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      de = temp15x,
      oe = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      ue = temp15x,
      le = temp15y,
      ae = (tempmidx + be) / 2,
      se = (tempmidy + ve) / 2,
      ce = (tempmidx + be) / 2,
      he = (tempmidy + ve) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      tm = temp12x,
      em = temp12y,
      temp13x = (F + temp_q_topx) / 2,
      temp13y = (J + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      pm = tempx,
      mm = tempy,
      ym = F,
      im = J,
      temp1x = o + (F - o) / 3,
      tempmidx = (o + F) / 2,
      temp2x = (temp1x + tempmidx) / 2,
      tempx = (temp2x + tempmidx) / 2,
      am = tempx,
      temp1y = a + (J - a) / 3,
      tempmidy = (a + J) / 2,
      temp2y = (temp1y + tempmidy) / 2,
      tempy = (temp2y + tempmidy) / 2,
      sm = tempy,
      temp3x = m + 2 * (W - m) / 3,
      temp3midx = (m + W) / 2,
      temp4x = (temp3x + temp3midx) / 2,
      temp5x = (temp3midx + temp4x) / 2,
      temp6x = (temp4x + temp5x) / 2,
      temp_q_topx = (temp4x + temp6x) / 2,
      temp3y = y + 2 * (Z - y) / 3,
      temp3midy = (y + Z) / 2,
      temp4y = (temp3y + temp3midy) / 2,
      temp5y = (temp3midy + temp4y) / 2,
      temp6y = (temp4y + temp5y) / 2,
      temp_q_topy = (temp4y + temp6y) / 2,
      temp6x = (o + temp_q_topx) / 2,
      temp6y = (a + temp_q_topy) / 2,
      tempx = (temp6x + temp_q_topx) / 2,
      tempy = (temp6y + temp_q_topy) / 2,
      um = tempx,
      lm = tempy,
      cm = o,
      hm = a,
      temp7x = (temp_q_topx + tempmidx) / 2,
      temp7y = (temp_q_topy + tempmidy) / 2,
      temp7x = (temp_q_topx + temp7x) / 2,
      temp7y = (temp_q_topy + temp7y) / 2,
      Ne = temp7x,
      Ee = temp7y,
      temp8x = o + (F - o) / 3,
      temp8y = a + (J - a) / 3,
      temp9x = o + 2 * (F - o) / 3,
      temp9y = a + 2 * (J - a) / 3,
      temp10x = (Ne + temp8x) / 2,
      temp10y = (Ee + temp8y) / 2,
      temp11x = (Ne + temp9x) / 2,
      temp11y = (Ee + temp9y) / 2,
      temp15x = (temp10x + temp8x) / 2,
      temp15y = (temp10y + temp8y) / 2,
      temp15x = (temp10x + temp15x) / 2,
      temp15y = (temp10y + temp15y) / 2,
      Ce = temp15x,
      ke = temp15y,
      temp15x = (temp11x + temp9x) / 2,
      temp15y = (temp11y + temp9y) / 2,
      temp15x = (temp11x + temp15x) / 2,
      temp15y = (temp11y + temp15y) / 2,
      Ae = temp15x,
      ge = temp15y,
      qe = (tempmidx + Ne) / 2,
      Se = (tempmidy + Ee) / 2,
      we = (tempmidx + Ne) / 2,
      fe = (tempmidy + Ee) / 2,
      temp12x = (tempmidx + temp9x) / 2,
      temp12y = (tempmidy + temp9y) / 2,
      temp12x = (tempmidx + temp12x) / 2,
      temp12y = (tempmidy + temp12y) / 2,
      xm = temp12x,
      _m = temp12y,
      temp13x = (F + temp_q_topx) / 2,
      temp13y = (J + temp_q_topy) / 2,
      tempx = (temp13x + temp_q_topx) / 2,
      tempy = (temp13y + temp_q_topy) / 2,
      rm = tempx,
      nm = tempy,
      dm = F,
      om = J;
  var bm = " M"
      , vm = " "
      , Am = " L"
      , gm = " C "
      , wm = ","
      , fm = " Q"
      , Cm = bm + am + vm + sm + gm + um + wm + lm + vm + cm + wm + hm + vm + v + wm + A + gm + ze + wm + je + vm + Le + wm + Te + vm + Me + wm + Be + Am + o + vm + a + "z" + bm + Ie + vm + De + gm + Pe + wm + He + vm + Re + wm + Fe + vm + O + wm + V + gm + Ke + wm + Ue + vm + Ve + wm + Ge + vm + Je + wm + Oe + "z" + bm + Qe + vm + We + gm + Ze + wm + Xe + vm + Ye + wm + $e + vm + i + wm + x + gm + yp + wm + ip + vm + pp + wm + mp + vm + tp + wm + ep + Am + s + vm + u + "z" + bm + xp + vm + _p + gm + rp + wm + np + vm + dp + wm + op + vm + G + wm + K + gm + cp + wm + hp + vm + up + wm + lp + vm + ap + wm + sp + "z" + bm + bp + vm + vp + gm + Ap + wm + gp + vm + wp + wm + fp + vm + _ + wm + r + gm + Np + wm + Ep + vm + qp + wm + Sp + vm + Cp + wm + kp + Am + l + vm + c + "z" + bm + Mp + vm + Bp + gm + Lp + wm + Tp + vm + zp + wm + jp + vm + U + wm + Q + gm + Rp + wm + Fp + vm + Pp + wm + Hp + vm + Ip + wm + Dp + "z" + bm + Jp + vm + Op + gm + Vp + wm + Gp + vm + Kp + wm + Up + vm + n + wm + d + gm + Yp + wm + $p + vm + Zp + wm + Xp + vm + Qp + wm + Wp + Am + h + vm + b + "z" + bm + tm + vm + em + gm + pm + wm + mm + vm + ym + wm + im + vm + W + wm + Z + gm + dm + wm + om + vm + rm + wm + nm + vm + xm + wm + _m + "z"
      , km = bm + yt + vm + it + fm + xt + wm + _t + vm + at + wm + st + bm + rt + vm + nt + fm + dt + wm + ot + vm + at + wm + st + bm + ut + vm + lt + fm + ct + wm + ht + vm + wt + wm + ft + bm + bt + vm + vt + fm + At + wm + gt + vm + wt + wm + ft + bm + Ct + vm + kt + fm + qt + wm + St + vm + Lt + wm + Tt + bm + Nt + vm + Et + fm + Mt + wm + Bt + vm + Lt + wm + Tt + bm + zt + vm + jt + fm + It + wm + Dt + vm + Jt + wm + Ot + bm + Pt + vm + Ht + fm + Rt + wm + Ft + vm + Jt + wm + Ot + bm + Vt + vm + Gt + fm + Kt + wm + Ut + vm + Yt + wm + $t + bm + Qt + vm + Wt + fm + Zt + wm + Xt + vm + Yt + wm + $t + bm + te + vm + ee + fm + pe + wm + me + vm + re + wm + ne + bm + ye + vm + ie + fm + xe + wm + _e + vm + re + wm + ne + bm + de + vm + oe + fm + ae + wm + se + vm + be + wm + ve + bm + ue + vm + le + fm + ce + wm + he + vm + be + wm + ve + bm + Ae + vm + ge + fm + we + wm + fe + vm + Ne + wm + Ee + bm + Ce + vm + ke + fm + qe + wm + Se + vm + Ne + wm + Ee
      , qm = .2 * e.length / 100
      , Sm = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Sm.setAttribute("stroke", "black"),
      Sm.setAttribute("stroke-width", qm),
      Sm.setAttribute("fill", "#ee600d"),
      Sm.setAttribute("stroke-linejoin", "round"),
      Sm.setAttribute("d", Cm),
      pt.appendChild(Sm);
  var qm = .2 * e.length / 100
      , Nm = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Nm.setAttribute("stroke", "black"),
      Nm.setAttribute("stroke-width", qm),
      Nm.setAttribute("fill", "none"),
      Nm.setAttribute("stroke-linejoin", "round"),
      Nm.setAttribute("d", km),
      pt.appendChild(Nm)
}
function setDesignFor_B_S2(t, e, p) {
  var m, y, i, x, _, r, n, d, o, a, s, u, l, c, h, b, v, A, g, w;
  m = e.x,
      y = e.y,
      i = e.x + e.length,
      x = e.y,
      _ = e.x + e.length,
      r = e.y + e.length,
      n = e.x,
      d = e.y + e.length,
      o = t.x,
      a = t.y,
      s = t.x + t.length,
      u = t.y,
      l = t.x + t.length,
      c = t.y + t.length,
      h = t.x,
      b = t.y + t.length;
  var f = .5 * e.length / 100
      , C = .2 * e.length / 100;
  tempmidx = (o + m) / 2,
      tempmidy = (a + y) / 2,
      tempx = (o + tempmidx) / 2,
      tempy = (a + tempmidy) / 2,
      tempx = (o + tempx) / 2,
      tempy = (a + tempy) / 2,
      for_in_p1x = v = tempx,
      for_in_p1y = A = tempy,
      tempmidx = (s + i) / 2,
      tempmidy = (u + x) / 2,
      tempx = (s + tempmidx) / 2,
      tempy = (u + tempmidy) / 2,
      tempx = (s + tempx) / 2,
      tempy = (u + tempy) / 2,
      g = tempx,
      w = tempy;
  var k = document.getElementById("svg" + p)
      , q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", v),
      q.setAttribute("x2", g),
      q.setAttribute("y1", A),
      q.setAttribute("y2", w),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", f),
      k.appendChild(q),
      tempmidx = (s + i) / 2,
      tempmidy = (u + x) / 2,
      tempx = (s + tempmidx) / 2,
      tempy = (u + tempmidy) / 2,
      tempx = (s + tempx) / 2,
      tempy = (u + tempy) / 2,
      s = v = tempx,
      u = A = tempy,
      tempmidx = (l + _) / 2,
      tempmidy = (c + r) / 2,
      tempx = (l + tempmidx) / 2,
      tempy = (c + tempmidy) / 2,
      tempx = (l + tempx) / 2,
      tempy = (c + tempy) / 2,
      g = tempx,
      w = tempy;
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", v),
      q.setAttribute("x2", g),
      q.setAttribute("y1", A),
      q.setAttribute("y2", w),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", f),
      k.appendChild(q),
      tempmidx = (l + _) / 2,
      tempmidy = (c + r) / 2,
      tempx = (l + tempmidx) / 2,
      tempy = (c + tempmidy) / 2,
      tempx = (l + tempx) / 2,
      tempy = (c + tempy) / 2,
      l = v = tempx,
      c = A = tempy,
      tempmidx = (h + n) / 2,
      tempmidy = (b + d) / 2,
      tempx = (h + tempmidx) / 2,
      tempy = (b + tempmidy) / 2,
      tempx = (h + tempx) / 2,
      tempy = (b + tempy) / 2,
      g = tempx,
      w = tempy;
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", v),
      q.setAttribute("x2", g),
      q.setAttribute("y1", A),
      q.setAttribute("y2", w),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", f),
      k.appendChild(q),
      tempmidx = (h + n) / 2,
      tempmidy = (b + d) / 2,
      tempx = (h + tempmidx) / 2,
      tempy = (b + tempmidy) / 2,
      tempx = (h + tempx) / 2,
      tempy = (b + tempy) / 2,
      h = v = tempx,
      b = A = tempy,
      tempmidx = (o + m) / 2,
      tempmidy = (a + y) / 2,
      tempx = (o + tempmidx) / 2,
      tempy = (a + tempmidy) / 2,
      tempx = (o + tempx) / 2,
      tempy = (a + tempy) / 2,
      g = tempx,
      w = tempy,
      o = for_in_p1x,
      a = for_in_p1y;
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", v),
      q.setAttribute("x2", g),
      q.setAttribute("y1", A),
      q.setAttribute("y2", w),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", f),
      k.appendChild(q),
      outermidx = (m + n) / 2,
      outermidy = (y + d) / 2,
      mid_ou_p1_ou_p4x = (m + outermidx) / 2,
      mid_ou_p1_ou_p4y = (y + outermidy) / 2,
      outermidx = (mid_ou_p1_ou_p4x + m) / 2,
      outermidy = (mid_ou_p1_ou_p4y + y) / 2,
      outer_1_3x = mid_ou_p1_ou_p4x + (m - mid_ou_p1_ou_p4x) / 3,
      outer_1_3y = mid_ou_p1_ou_p4y + (y - mid_ou_p1_ou_p4y) / 3,
      outer_2_3x = mid_ou_p1_ou_p4x + 2 * (m - mid_ou_p1_ou_p4x) / 3,
      outer_2_3y = mid_ou_p1_ou_p4y + 2 * (y - mid_ou_p1_ou_p4y) / 3,
      tempx = (outermidx + outer_1_3x) / 2,
      tempy = (outermidy + outer_1_3y) / 2,
      temp1x = (tempx + outer_1_3x) / 2,
      temp1y = (tempy + outer_1_3y) / 2,
      temp2x = (outermidx + tempx) / 2,
      temp2y = (outermidy + tempy) / 2,
      innermidx = (o + h) / 2,
      innermidy = (a + b) / 2,
      mid_in_p1_in_p4x = (o + innermidx) / 2,
      mid_in_p1_in_p4y = (a + innermidy) / 2,
      innermidx = (mid_in_p1_in_p4x + o) / 2,
      innermidy = (mid_in_p1_in_p4y + a) / 2,
      inner_1_3x = o + (mid_in_p1_in_p4x - o) / 3,
      inner_1_3y = a + (mid_in_p1_in_p4y - a) / 3,
      temp3x = (innermidx + inner_1_3x) / 2,
      temp3y = (innermidy + inner_1_3y) / 2;
  var S = (temp1x + temp3x) / 2
      , N = (temp1y + temp3y) / 2
      , E = (S + temp3x) / 2
      , M = (N + temp3y) / 2
      , B = (temp2x + temp3x) / 2
      , L = (temp2y + temp3y) / 2;
  tempx = (B + temp3x) / 2,
      tempy = (L + temp3y) / 2,
      temp1x = (B + tempx) / 2,
      temp1y = (L + tempy) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2;
  var T = temp1x
      , z = temp1y
      , j = E
      , I = M;
  tempx = (mid_in_p1_in_p4x + innermidx) / 2,
      tempy = (mid_in_p1_in_p4y + innermidy) / 2;
  var D = (tempx + j) / 2
      , P = (tempy + I) / 2
      , H = (mid_ou_p1_ou_p4x + outer_1_3x) / 2
      , R = (mid_ou_p1_ou_p4y + outer_1_3y) / 2
      , F = temp2x
      , J = temp2y;
  tempx = (outer_2_3x + o) / 2,
      tempy = (outer_2_3y + a) / 2,
      tempx = (outer_2_3x + tempx) / 2,
      tempy = (outer_2_3y + tempy) / 2,
      tempx = (outer_2_3x + tempx) / 2,
      tempy = (outer_2_3y + tempy) / 2;
  var O = tempx
      , V = tempy;
  tempx = (m + o) / 2,
      tempy = (y + a) / 2,
      temp1x = (tempx + o) / 2,
      temp1y = (tempy + a) / 2,
      tempx = (tempx + temp1x) / 2,
      tempy = (tempy + temp1y) / 2;
  var G = tempx
      , K = tempy;
  tempx = (outer_1_3x + o) / 2,
      tempy = (outer_1_3y + a) / 2,
      tempx = (G + tempx) / 2,
      tempy = (K + tempy) / 2;
  var U = tempx
      , Q = tempy
      , W = G
      , Z = K
      , X = m
      , Y = y
      , $ = W
      , tt = y;
  outermidx = (m + i) / 2,
      outermidy = (y + x) / 2,
      mid_ou_p1_ou_p2x = (m + outermidx) / 2,
      mid_ou_p1_ou_p2y = (y + outermidy) / 2,
      outermidx = (mid_ou_p1_ou_p2x + m) / 2,
      outermidy = (mid_ou_p1_ou_p2y + y) / 2,
      outer_1_3x = mid_ou_p1_ou_p2x + (m - mid_ou_p1_ou_p2x) / 3,
      outer_1_3y = mid_ou_p1_ou_p2y + (y - mid_ou_p1_ou_p2y) / 3,
      outer_2_3x = mid_ou_p1_ou_p2x + 2 * (m - mid_ou_p1_ou_p2x) / 3,
      outer_2_3y = mid_ou_p1_ou_p2y + 2 * (y - mid_ou_p1_ou_p2y) / 3,
      tempx = (outermidx + outer_1_3x) / 2,
      tempy = (outermidy + outer_1_3y) / 2,
      temp1x = (tempx + outer_1_3x) / 2,
      temp1y = (tempy + outer_1_3y) / 2,
      temp2x = (outermidx + tempx) / 2,
      temp2y = (outermidy + tempy) / 2,
      innermidx = (o + s) / 2,
      innermidy = (a + u) / 2,
      mid_in_p1_in_p2x = (o + innermidx) / 2,
      mid_in_p1_in_p2y = (a + innermidy) / 2,
      innermidx = (mid_in_p1_in_p2x + o) / 2,
      innermidy = (mid_in_p1_in_p2y + a) / 2,
      inner_1_3x = o + (mid_in_p1_in_p2x - o) / 3,
      inner_1_3y = a + (mid_in_p1_in_p2y - a) / 3,
      temp3x = (innermidx + inner_1_3x) / 2,
      temp3y = (innermidy + inner_1_3y) / 2;
  var et = (temp1x + temp3x) / 2
      , pt = (temp1y + temp3y) / 2
      , mt = (et + temp3x) / 2
      , yt = (pt + temp3y) / 2
      , it = (temp2x + temp3x) / 2
      , xt = (temp2y + temp3y) / 2;
  tempx = (it + temp3x) / 2,
      tempy = (xt + temp3y) / 2,
      temp1x = (it + tempx) / 2,
      temp1y = (xt + tempy) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2,
      temp1x = (tempx + temp1x) / 2,
      temp1y = (tempy + temp1y) / 2;
  var _t = temp1x
      , rt = temp1y
      , nt = mt
      , dt = yt;
  tempx = (mid_in_p1_in_p2x + innermidx) / 2,
      tempy = (mid_in_p1_in_p2y + innermidy) / 2;
  var ot = (tempx + nt) / 2
      , at = (tempy + dt) / 2
      , st = (mid_ou_p1_ou_p2x + outer_1_3x) / 2
      , ut = (mid_ou_p1_ou_p2y + outer_1_3y) / 2
      , lt = temp2x
      , ct = temp2y
      , ht = lt
      , bt = ct;
  tempx = (outer_2_3x + o) / 2,
      tempy = (outer_2_3y + a) / 2,
      tempx = (outer_2_3x + tempx) / 2,
      tempy = (outer_2_3y + tempy) / 2,
      tempx = (outer_2_3x + tempx) / 2,
      tempy = (outer_2_3y + tempy) / 2;
  var vt = tempx
      , At = tempy;
  tempx = (m + o) / 2,
      tempy = (y + a) / 2,
      temp1x = (tempx + o) / 2,
      temp1y = (tempy + a) / 2,
      tempx = (tempx + temp1x) / 2,
      tempy = (tempy + temp1y) / 2;
  tempx,
      tempy;
  tempx = (outer_1_3x + o) / 2,
      tempy = (outer_1_3y + a) / 2,
      tempx = (G + tempx) / 2,
      tempy = (K + tempy) / 2;
  var gt = tempx
      , wt = tempy
      , ft = m
      , Ct = Z;
  outer_midx = (m + n) / 2,
      outer_midy = (y + d) / 2,
      mid_ou_p1_ou_p4x = (m + outer_midx) / 2,
      mid_ou_p1_ou_p4y = (y + outer_midy) / 2,
      outermidx = (mid_ou_p1_ou_p4x + m) / 2,
      outermidy = (mid_ou_p1_ou_p4y + y) / 2,
      outer_2_3x = outer_midx + 2 * (m - outer_midx) / 3,
      outer_2_3y = outer_midy + 2 * (y - outer_midy) / 3,
      outer_tempx = outer_2_3x + 2 * (outermidx - outer_2_3x) / 3,
      outer_tempy = outer_2_3y + 2 * (outermidy - outer_2_3y) / 3,
      inner_midx = (o + h) / 2,
      inner_midy = (a + b) / 2,
      mid_in_p1_in_p4x = (o + inner_midx) / 2,
      mid_in_p1_in_p4y = (a + inner_midy) / 2,
      inner_2_3x = mid_in_p1_in_p4x + 2 * (o - mid_in_p1_in_p4x) / 3,
      inner_2_3y = mid_in_p1_in_p4y + 2 * (a - mid_in_p1_in_p4y) / 3;
  var kt = (outer_tempx + inner_2_3x) / 2
      , qt = (outer_tempy + inner_2_3y) / 2
      , St = (kt + inner_2_3x) / 2
      , Nt = (qt + inner_2_3y) / 2
      , Et = kt
      , Mt = qt
      , Bt = E
      , Lt = M
      , Tt = S
      , zt = N
      , jt = inner_2_3x
      , It = inner_2_3y
      , Dt = S
      , Pt = N;
  tempx = (o + W) / 2,
      tempy = (a + Z) / 2,
      tempx = (tempx + W) / 2,
      tempy = (tempy + Z) / 2;
  var Ht = tempx
      , Rt = tempy;
  tempx = (Et + W) / 2,
      tempy = (Mt + Z) / 2,
      tempx = (Et + tempx) / 2,
      tempy = (Mt + tempy) / 2,
      temp1x = (Et + tempx) / 2,
      temp1y = (Mt + tempy) / 2,
      tempx = (temp1x + tempx) / 2,
      tempy = (temp1y + tempy) / 2;
  var Ft = tempx
      , Jt = tempy;
  tempx = (o + jt) / 2,
      tempy = (a + It) / 2,
      tempx = (tempx + jt) / 2,
      tempy = (tempy + It) / 2;
  var Ot = tempx
      , Vt = tempy;
  innermidx = (mid_in_p1_in_p4x + o) / 2,
      innermidy = (mid_in_p1_in_p4y + a) / 2;
  var Gt = innermidx
      , Kt = innermidy;
  tempx = (Dt + mid_in_p1_in_p4x) / 2,
      tempy = (Pt + mid_in_p1_in_p4y) / 2,
      tempx = (Dt + tempx) / 2,
      tempy = (Pt + tempy) / 2,
      tempx = (Dt + tempx) / 2,
      tempy = (Pt + tempy) / 2,
      tempx = (Dt + tempx) / 2,
      tempy = (Pt + tempy) / 2,
      tempx = (Dt + tempx) / 2,
      tempy = (Pt + tempy) / 2;
  var Ut = tempx
      , Qt = tempy;
  tempx = (Dt + m) / 2,
      tempy = (Pt + y) / 2,
      tempx = (tempx + outer_tempx) / 2,
      tempy = (tempy + outer_tempy) / 2,
      tempx = (tempx + outer_tempx) / 2,
      tempy = (tempy + outer_tempy) / 2;
  var Wt = tempx
      , Zt = tempy
      , Xt = o
      , Yt = a;
  outer_midx = (m + i) / 2,
      outer_midy = (y + x) / 2,
      mid_ou_p1_ou_p2x = (m + outer_midx) / 2,
      mid_ou_p1_ou_p2y = (y + outer_midy) / 2,
      outermidx = (mid_ou_p1_ou_p2x + m) / 2,
      outermidy = (mid_ou_p1_ou_p2y + y) / 2,
      outer_2_3x = outer_midx + 2 * (m - outer_midx) / 3,
      outer_2_3y = outer_midy + 2 * (y - outer_midy) / 3,
      outer_tempx = outer_2_3x + 2 * (outermidx - outer_2_3x) / 3,
      outer_tempy = outer_2_3y + 2 * (outermidy - outer_2_3y) / 3,
      inner_midx = (o + s) / 2,
      inner_midy = (a + u) / 2,
      mid_in_p1_in_p2x = (o + inner_midx) / 2,
      mid_in_p1_in_p2y = (a + inner_midy) / 2,
      inner_2_3x = mid_in_p1_in_p2x + 2 * (o - mid_in_p1_in_p2x) / 3,
      inner_2_3y = mid_in_p1_in_p2y + 2 * (a - mid_in_p1_in_p2y) / 3;
  var $t = (outer_tempx + inner_2_3x) / 2
      , te = (outer_tempy + inner_2_3y) / 2
      , ee = ($t + inner_2_3x) / 2
      , pe = (te + inner_2_3y) / 2
      , me = $t
      , ye = te
      , ie = mt
      , xe = yt
      , _e = et
      , re = pt
      , ne = inner_2_3x
      , de = inner_2_3y
      , oe = et
      , ae = pt;
  tempx = (o + W) / 2,
      tempy = (a + Z) / 2,
      tempx = (tempx + W) / 2,
      tempy = (tempy + Z) / 2;
  var Ht = tempx
      , Rt = tempy;
  tempx = (me + W) / 2,
      tempy = (ye + Z) / 2,
      tempx = (me + tempx) / 2,
      tempy = (ye + tempy) / 2,
      temp1x = (me + tempx) / 2,
      temp1y = (ye + tempy) / 2,
      tempx = (temp1x + tempx) / 2,
      tempy = (temp1y + tempy) / 2;
  var se = tempx
      , ue = tempy;
  tempx = (o + ne) / 2,
      tempy = (a + de) / 2,
      tempx = (tempx + ne) / 2,
      tempy = (tempy + de) / 2;
  var le = tempx
      , ce = tempy;
  innermidx = (mid_in_p1_in_p2x + o) / 2,
      innermidy = (mid_in_p1_in_p2y + a) / 2;
  var he = innermidx
      , be = innermidy;
  tempx = (oe + mid_in_p1_in_p2x) / 2,
      tempy = (ae + mid_in_p1_in_p2y) / 2,
      tempx = (oe + tempx) / 2,
      tempy = (ae + tempy) / 2,
      tempx = (oe + tempx) / 2,
      tempy = (ae + tempy) / 2,
      tempx = (oe + tempx) / 2,
      tempy = (ae + tempy) / 2,
      tempx = (oe + tempx) / 2,
      tempy = (ae + tempy) / 2;
  var ve = tempx
      , Ae = tempy;
  tempx = (oe + m) / 2,
      tempy = (ae + y) / 2,
      tempx = (tempx + outer_tempx) / 2,
      tempy = (tempy + outer_tempy) / 2,
      tempx = (tempx + outer_tempx) / 2,
      tempy = (tempy + outer_tempy) / 2;
  var ge = tempx
      , we = tempy
      , fe = o
      , Ce = a
      , ke = W
      , qe = Z;
  tempx = (m + o) / 2,
      tempy = (y + a) / 2,
      tempx = (m + tempx) / 2,
      tempy = (y + tempy) / 2,
      common_tempx = tempx,
      outermidx = (m + n) / 2,
      outermidy = (y + d) / 2,
      mid_ou_p1_ou_p4x = (m + outermidx) / 2,
      mid_ou_p1_ou_p4y = (y + outermidy) / 2,
      outermidx = (mid_ou_p1_ou_p4x + m) / 2,
      outermidy = (mid_ou_p1_ou_p4y + y) / 2,
      tempmidx = (outermidx + m) / 2,
      tempmidy = (outermidy + y) / 2,
      temp1midx = (tempmidx + outermidx) / 2,
      temp1midy = (tempmidy + outermidy) / 2,
      k_temp1y = (tempmidy + temp1midy) / 2,
      k_temp1y = (tempmidy + k_temp1y) / 2,
      temp1midx = (tempmidx + m) / 2,
      temp1midy = (tempmidy + y) / 2,
      temp1midx = (tempmidx + temp1midx) / 2,
      temp1midy = (tempmidy + temp1midy) / 2,
      k_temp3y = temp1midy,
      k_temp2y = (k_temp1y + k_temp3y) / 2;
  var Se = common_tempx
      , Ne = k_temp1y
      , Ee = Se
      , Me = Ne
      , Be = common_tempx
      , Le = k_temp3y
      , Te = Le
      , ze = common_tempx
      , je = k_temp2y;
  tempx = W,
      tempy = tempmidy,
      temp1x = common_tempx,
      temp1y = k_temp1y;
  var Ie = (tempx + temp1x) / 2
      , De = (tempy + temp1y) / 2;
  tempx = (tempx + W) / 2,
      tempy = (tempy + Z) / 2;
  var Pe = tempx
      , He = tempy
      , Re = m
      , Fe = Me
      , Je = m
      , Oe = Le;
  tempx = (common_tempx + W) / 2;
  var Ve = tempx
      , Ge = Te
      , Ke = tempx
      , Ue = je;
  tempx = m,
      tempy = Z,
      tempx = (tempx + ze) / 2,
      tempy = (tempy + je) / 2;
  var Qe = tempx
      , We = tempy
      , Ze = tempmidx
      , Xe = k_temp1y
      , Ye = W
      , $e = Z;
  tempx = (m + o) / 2,
      tempy = (y + a) / 2,
      tempx = (m + tempx) / 2,
      tempy = (y + tempy) / 2,
      common_tempy = tempy,
      outermidx = (m + i) / 2,
      outermidy = (y + x) / 2,
      mid_ou_p1_ou_p2x = (m + outermidx) / 2,
      mid_ou_p1_ou_p2y = (y + outermidy) / 2,
      outermidx = (mid_ou_p1_ou_p2x + m) / 2,
      outermidy = (mid_ou_p1_ou_p2y + y) / 2,
      tempmidx = (outermidx + m) / 2,
      tempmidy = (outermidy + y) / 2,
      temp1midx = (tempmidx + outermidx) / 2,
      temp1midy = (tempmidy + outermidy) / 2,
      k_temp1x = (tempmidx + temp1midx) / 2,
      k_temp1x = (tempmidx + k_temp1x) / 2,
      temp1midx = (tempmidx + m) / 2,
      temp1midy = (tempmidy + y) / 2,
      temp1midx = (tempmidx + temp1midx) / 2,
      temp1midy = (tempmidy + temp1midy) / 2,
      k_temp3x = temp1midx,
      k_temp2x = (k_temp1x + k_temp3x) / 2;
  var tp = k_temp1x
      , ep = common_tempy
      , pp = tp
      , mp = ep
      , yp = k_temp3x
      , ip = common_tempy
      , xp = yp
      , _p = k_temp2x
      , rp = common_tempy;
  tempx = tempmidx,
      tempy = Z,
      temp1x = k_temp1x,
      temp1y = common_tempy;
  var np = (tempx + temp1x) / 2
      , dp = (tempy + temp1y) / 2;
  tempx = (tempx + W) / 2,
      tempy = (tempy + Z) / 2;
  var op = tempx
      , ap = tempy
      , sp = pp
      , up = y
      , lp = yp
      , cp = y;
  tempy = (common_tempy + Z) / 2;
  var hp = xp
      , bp = tempy
      , vp = _p
      , Ap = tempy;
  tempx = W,
      tempy = y,
      tempx = (tempx + _p) / 2,
      tempy = (tempy + rp) / 2;
  var gp = tempx
      , wp = tempy
      , fp = k_temp1x
      , Cp = tempmidy;
  outer_midx = (m + i) / 2,
      outer_midy = (y + x) / 2,
      mid_ou_p1_ou_p2x = (m + outer_midx) / 2,
      mid_ou_p1_ou_p2y = (y + outer_midy) / 2,
      outermidx = (mid_ou_p1_ou_p2x + m) / 2,
      outermidy = (mid_ou_p1_ou_p2y + y) / 2,
      tempmidx = (outermidx + mid_ou_p1_ou_p2x) / 2,
      tempmidy = (outermidy + mid_ou_p1_ou_p2y) / 2,
      inner_midx = (o + s) / 2,
      inner_midy = (a + u) / 2,
      mid_in_p1_in_p2x = (o + inner_midx) / 2,
      mid_in_p1_oinp2y = (a + inner_midy) / 2,
      innermidx = (mid_in_p1_in_p2x + o) / 2,
      innermidy = (mid_in_p1_in_p2y + a) / 2,
      temp1midx = (innermidx + mid_in_p1_in_p2x) / 2,
      temp1midy = (innermidy + mid_in_p1_in_p2y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var kp = temp1x
      , qp = temp1y
      , Sp = temp2x
      , Np = temp2y;
  mid_ou_p1_ou_p2x = (i + outer_midx) / 2,
      mid_ou_p1_ou_p2y = (x + outer_midy) / 2,
      outermidx = (mid_ou_p1_ou_p2x + i) / 2,
      outermidy = (mid_ou_p1_ou_p2y + x) / 2,
      tempmidx = (outermidx + mid_ou_p1_ou_p2x) / 2,
      tempmidy = (outermidy + mid_ou_p1_ou_p2y) / 2,
      inner_midx = (o + s) / 2,
      inner_midy = (a + u) / 2,
      mid_in_p1_in_p2x = (s + inner_midx) / 2,
      mid_in_p1_oinp2y = (u + inner_midy) / 2,
      innermidx = (mid_in_p1_in_p2x + s) / 2,
      innermidy = (mid_in_p1_in_p2y + u) / 2,
      temp1midx = (innermidx + mid_in_p1_in_p2x) / 2,
      temp1midy = (innermidy + mid_in_p1_in_p2y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var Ep = temp1x
      , Mp = temp1y
      , Bp = temp2x
      , Lp = temp2y;
  tempx = (m + o) / 2,
      tempy = (y + a) / 2,
      temp1x = (tempx + o) / 2,
      temp1y = (tempy + a) / 2,
      tempx = (tempx + temp1x) / 2,
      tempy = (tempy + temp1y) / 2;
  tempx,
      tempy;
  tempx = (i + s) / 2,
      tempy = (x + u) / 2,
      temp1x = (tempx + s) / 2,
      temp1y = (tempy + u) / 2,
      tempx = (tempx + temp1x) / 2,
      tempy = (tempy + temp1y) / 2;
  var Tp = tempx
      , zp = tempy;
  tempx = (_ + l) / 2,
      tempy = (r + c) / 2,
      temp1x = (tempx + l) / 2,
      temp1y = (tempy + c) / 2,
      tempx = (tempx + temp1x) / 2,
      tempy = (tempy + temp1y) / 2;
  var jp = tempx
      , Ip = tempy;
  tempx = (n + h) / 2,
      tempy = (d + b) / 2,
      temp1x = (tempx + h) / 2,
      temp1y = (tempy + b) / 2,
      tempx = (tempx + temp1x) / 2,
      tempy = (tempy + temp1y) / 2;
  var Dp = tempx
      , Pp = tempy
      , k = document.getElementById("svg" + p)
      , Hp = document.createElementNS("http://www.w3.org/2000/svg", "g");
  Hp.setAttribute("id", "group_of_border" + p),
      Hp.setAttribute("x", W),
      Hp.setAttribute("y", Z),
      k.appendChild(Hp);
  var Rp = " M"
      , Fp = " C"
      , Jp = " "
      , Op = ","
      , Vp = " Q"
      , Gp = Rp + S + Jp + N + Fp + B + Op + L + Jp + T + Op + z + Jp + E + Op + M + Fp + D + Op + P + Jp + H + Op + R + Jp + F + Op + J + Fp + O + Op + V + Jp + U + Op + Q + Jp + G + Op + K + Vp + $ + Op + tt + Jp + X + Op + Y + Vp + ft + Op + Ct + Jp + W + Op + Z + Fp + gt + Op + wt + Jp + vt + Op + At + Jp + ht + Op + bt + Fp + st + Op + ut + Jp + ot + Op + at + Jp + nt + Op + dt + Fp + _t + Op + rt + Jp + it + Op + xt + Jp + et + Op + pt + Rp + St + Jp + Nt + Fp + Bt + Op + Lt + Jp + Tt + Op + zt + Jp + kt + Op + qt + Fp + Ft + Op + Jt + Jp + Ot + Op + Vt + Jp + jt + Op + It + Fp + Gt + Op + Kt + Jp + Ut + Op + Qt + Jp + Dt + Op + Pt + Fp + Wt + Op + Zt + Jp + Xt + Op + Yt + Jp + Ht + Op + Rt + Rp + ee + Jp + pe + Fp + ie + Op + xe + Jp + _e + Op + re + Jp + $t + Op + te + Fp + se + Op + ue + Jp + le + Op + ce + Jp + ne + Op + de + Fp + he + Op + be + Jp + ve + Op + Ae + Jp + oe + Op + ae + Fp + ge + Op + we + Jp + fe + Op + Ce + Jp + Ht + Op + Rt
      , Kp = document.getElementById("group_of_border" + p)
      , Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "none"),
      Up.setAttribute("d", Gp),
      Kp.appendChild(Up);
  var Qp = Rp + ke + Jp + qe + Fp + Pe + Op + He + Jp + Ie + Op + De + Jp + Se + Op + Ne + Fp + Re + Op + Fe + Jp + Je + Op + Oe + Jp + Be + Op + Le + Fp + Ve + Op + Ge + Jp + Ke + Op + Ue + Jp + ze + Op + je + Fp + Qe + Op + We + Jp + Ze + Op + Xe + Jp + Ee + Op + Me + Fp + Ie + Op + De + Jp + Pe + Op + He + Jp + ke + Op + qe + Rp + Ye + Jp + $e + Fp + op + Op + ap + Jp + np + Op + dp + Jp + tp + Op + ep + Fp + sp + Op + up + Jp + lp + Op + cp + Jp + yp + Op + ip + Fp + hp + Op + bp + Jp + vp + Op + Ap + Jp + _p + Op + rp + Fp + gp + Op + wp + Jp + fp + Op + Cp + Jp + pp + Op + mp + Fp + np + Op + dp + Jp + op + Op + ap + Jp + Ye + Op + $e + "z"
      , Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "#ee600d"),
      Up.setAttribute("d", Qp),
      Kp.appendChild(Up);
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", Sp),
      q.setAttribute("x2", Bp),
      q.setAttribute("y1", Np),
      q.setAttribute("y2", Lp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", 2.5 * C),
      Kp.appendChild(q);
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", kp),
      q.setAttribute("x2", Ep),
      q.setAttribute("y1", qp),
      q.setAttribute("y2", Mp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", C),
      Kp.appendChild(q),
      p1_tempx = (m + o) / 2,
      p1_tempy = (y + a) / 2,
      p1_temp1x = (p1_tempx + o) / 2,
      p1_temp1y = (p1_tempy + a) / 2,
      p1_tempx = (p1_tempx + p1_temp1x) / 2,
      p1_tempy = (p1_tempy + p1_temp1y) / 2,
      p1_tempx = (p1_tempx + m) / 2,
      p1_tempy = (p1_tempy + y) / 2,
      temp = 1.5 * e.length / 100,
      temp1 = temp / 2.5;
  var Wp = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
  Wp.setAttribute("stroke", "#ee600d"),
      Wp.setAttribute("stroke-width", C),
      Wp.setAttribute("fill", "#ee600d"),
      Wp.setAttribute("cx", p1_tempx),
      Wp.setAttribute("cy", p1_tempy),
      Wp.setAttribute("rx", temp),
      Wp.setAttribute("ry", temp1),
      Wp.setAttribute("transform", "rotate(45," + p1_tempx + "," + p1_tempy + ")"),
      Kp.appendChild(Wp);
  var Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "none"),
      Up.setAttribute("transform", " translate(" + Math.abs(Tp - W) + "," + Math.abs(zp - Z) + ") rotate(90," + W + "," + Z + ")"),
      Up.setAttribute("stroke-linejoin", "round"),
      Up.setAttribute("d", Gp),
      k.appendChild(Up);
  var Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "#ee600d"),
      Up.setAttribute("transform", " translate(" + Math.abs(Tp - W) + "," + Math.abs(zp - Z) + ") rotate(90," + W + "," + Z + ")"),
      Up.setAttribute("stroke-linejoin", "round"),
      Up.setAttribute("d", Qp),
      k.appendChild(Up),
      p2_tempx = (i + s) / 2,
      p2_tempy = (x + u) / 2,
      p2_temp1x = (p2_tempx + s) / 2,
      p2_temp1y = (p2_tempy + u) / 2,
      p2_tempx = (p2_tempx + p2_temp1x) / 2,
      p2_tempy = (p2_tempy + p2_temp1y) / 2,
      p2_tempx = (p2_tempx + i) / 2,
      p2_tempy = (p2_tempy + x) / 2;
  var Wp = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
  Wp.setAttribute("stroke", "#ee600d"),
      Wp.setAttribute("stroke-width", C),
      Wp.setAttribute("fill", "#ee600d"),
      Wp.setAttribute("cx", p1_tempx),
      Wp.setAttribute("cy", p1_tempy),
      Wp.setAttribute("rx", temp),
      Wp.setAttribute("ry", temp1),
      Wp.setAttribute("transform", " translate(" + Math.abs(p2_tempx - p1_tempx) + "," + Math.abs(p2_tempy - p1_tempy) + ") rotate(135," + p1_tempx + "," + p1_tempy + ")"),
      k.appendChild(Wp),
      outer_midx = (i + _) / 2,
      outer_midy = (x + r) / 2,
      mid_ou_p2_ou_p3x = (i + outer_midx) / 2,
      mid_ou_p2_ou_p3y = (x + outer_midy) / 2,
      outermidx = (mid_ou_p2_ou_p3x + i) / 2,
      outermidy = (mid_ou_p2_ou_p3y + x) / 2,
      tempmidx = (outermidx + mid_ou_p2_ou_p3x) / 2,
      tempmidy = (outermidy + mid_ou_p2_ou_p3y) / 2,
      inner_midx = (s + l) / 2,
      inner_midy = (u + c) / 2,
      mid_in_p2_in_p3x = (s + inner_midx) / 2,
      mid_in_p2_in_p3y = (u + inner_midy) / 2,
      innermidx = (mid_in_p2_in_p3x + s) / 2,
      innermidy = (mid_in_p2_in_p3y + u) / 2,
      temp1midx = (innermidx + mid_in_p2_in_p3x) / 2,
      temp1midy = (innermidy + mid_in_p2_in_p3y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var kp = temp1x
      , qp = temp1y
      , Sp = temp2x
      , Np = temp2y;
  mid_ou_p2_ou_p3x = (_ + outer_midx) / 2,
      mid_ou_p2_ou_p3y = (r + outer_midy) / 2,
      outermidx = (mid_ou_p2_ou_p3x + _) / 2,
      outermidy = (mid_ou_p2_ou_p3y + r) / 2,
      tempmidx = (outermidx + mid_ou_p2_ou_p3x) / 2,
      tempmidy = (outermidy + mid_ou_p2_ou_p3y) / 2,
      inner_midx = (s + l) / 2,
      inner_midy = (u + c) / 2,
      mid_in_p2_in_p3x = (l + inner_midx) / 2,
      mid_in_p2_in_p3y = (c + inner_midy) / 2,
      innermidx = (mid_in_p2_in_p3x + l) / 2,
      innermidy = (mid_in_p2_in_p3y + c) / 2,
      temp1midx = (innermidx + mid_in_p2_in_p3x) / 2,
      temp1midy = (innermidy + mid_in_p2_in_p3y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var Ep = temp1x
      , Mp = temp1y
      , Bp = temp2x
      , Lp = temp2y
      , q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", kp),
      q.setAttribute("x2", Ep),
      q.setAttribute("y1", qp),
      q.setAttribute("y2", Mp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", C),
      k.appendChild(q);
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", Sp),
      q.setAttribute("x2", Bp),
      q.setAttribute("y1", Np),
      q.setAttribute("y2", Lp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", 2.5 * C),
      k.appendChild(q);
  var Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "none"),
      Up.setAttribute("transform", " translate(" + Math.abs(jp - W) + "," + Math.abs(Ip - Z) + ") rotate(180," + W + "," + Z + ")"),
      Up.setAttribute("stroke-linejoin", "round"),
      Up.setAttribute("d", Gp),
      k.appendChild(Up);
  var Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "#ee600d"),
      Up.setAttribute("transform", " translate(" + Math.abs(jp - W) + "," + Math.abs(Ip - Z) + ") rotate(180," + W + "," + Z + ")"),
      Up.setAttribute("stroke-linejoin", "round"),
      Up.setAttribute("d", Qp),
      k.appendChild(Up),
      p3_tempx = (_ + l) / 2,
      p3_tempy = (r + c) / 2,
      p3_temp1x = (p3_tempx + l) / 2,
      p3_temp1y = (p3_tempy + c) / 2,
      p3_tempx = (p3_tempx + p3_temp1x) / 2,
      p3_tempy = (p3_tempy + p3_temp1y) / 2,
      p3_tempx = (p3_tempx + _) / 2,
      p3_tempy = (p3_tempy + r) / 2;
  var Wp = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
  Wp.setAttribute("stroke", "#ee600d"),
      Wp.setAttribute("stroke-width", C),
      Wp.setAttribute("fill", "#ee600d"),
      Wp.setAttribute("cx", p1_tempx),
      Wp.setAttribute("cy", p1_tempy),
      Wp.setAttribute("rx", temp),
      Wp.setAttribute("ry", temp1),
      Wp.setAttribute("transform", " translate(" + Math.abs(p3_tempx - p1_tempx) + "," + Math.abs(p3_tempy - p1_tempy) + ") rotate(45," + p1_tempx + "," + p1_tempy + ")"),
      k.appendChild(Wp),
      outer_midx = (_ + n) / 2,
      outer_midy = (r + d) / 2,
      mid_ou_p3_ou_p4x = (_ + outer_midx) / 2,
      mid_ou_p3_ou_p4y = (r + outer_midy) / 2,
      outermidx = (mid_ou_p3_ou_p4x + _) / 2,
      outermidy = (mid_ou_p3_ou_p4y + r) / 2,
      tempmidx = (outermidx + mid_ou_p3_ou_p4x) / 2,
      tempmidy = (outermidy + mid_ou_p3_ou_p4y) / 2,
      inner_midx = (l + h) / 2,
      inner_midy = (c + b) / 2,
      mid_in_p3_in_p4x = (l + inner_midx) / 2,
      mid_in_p3_in_p4y = (c + inner_midy) / 2,
      innermidx = (mid_in_p3_in_p4x + l) / 2,
      innermidy = (mid_in_p3_in_p4y + c) / 2,
      temp1midx = (innermidx + mid_in_p3_in_p4x) / 2,
      temp1midy = (innermidy + mid_in_p3_in_p4y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var kp = temp1x
      , qp = temp1y
      , Sp = temp2x
      , Np = temp2y;
  mid_ou_p3_ou_p4x = (n + outer_midx) / 2,
      mid_ou_p3_ou_p4y = (d + outer_midy) / 2,
      outermidx = (mid_ou_p3_ou_p4x + n) / 2,
      outermidy = (mid_ou_p3_ou_p4y + d) / 2,
      tempmidx = (outermidx + mid_ou_p3_ou_p4x) / 2,
      tempmidy = (outermidy + mid_ou_p3_ou_p4y) / 2,
      inner_midx = (l + h) / 2,
      inner_midy = (c + b) / 2,
      mid_in_p3_in_p4x = (h + inner_midx) / 2,
      mid_in_p3_in_p4y = (b + inner_midy) / 2,
      innermidx = (mid_in_p3_in_p4x + h) / 2,
      innermidy = (mid_in_p3_in_p4y + b) / 2,
      temp1midx = (innermidx + mid_in_p3_in_p4x) / 2,
      temp1midy = (innermidy + mid_in_p3_in_p4y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var Ep = temp1x
      , Mp = temp1y
      , Bp = temp2x
      , Lp = temp2y
      , q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", kp),
      q.setAttribute("x2", Ep),
      q.setAttribute("y1", qp),
      q.setAttribute("y2", Mp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", C),
      k.appendChild(q);
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", Sp),
      q.setAttribute("x2", Bp),
      q.setAttribute("y1", Np),
      q.setAttribute("y2", Lp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", 2.5 * C),
      k.appendChild(q);
  var Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "none"),
      Up.setAttribute("transform", " translate(" + Math.abs(Dp - W) + "," + Math.abs(Pp - Z) + ") rotate(-90," + W + "," + Z + ")"),
      Up.setAttribute("stroke-linejoin", "round"),
      Up.setAttribute("d", Gp),
      k.appendChild(Up);
  var Up = document.createElementNS("http://www.w3.org/2000/svg", "path");
  Up.setAttribute("stroke", "#ee600d"),
      Up.setAttribute("stroke-width", C),
      Up.setAttribute("fill", "#ee600d"),
      Up.setAttribute("transform", " translate(" + Math.abs(Dp - W) + "," + Math.abs(Pp - Z) + ") rotate(-90," + W + "," + Z + ")"),
      Up.setAttribute("stroke-linejoin", "round"),
      Up.setAttribute("d", Qp),
      k.appendChild(Up),
      p4_tempx = (n + h) / 2,
      p4_tempy = (d + b) / 2,
      p4_temp1x = (p4_tempx + h) / 2,
      p4_temp1y = (p4_tempy + b) / 2,
      p4_tempx = (p4_tempx + p4_temp1x) / 2,
      p4_tempy = (p4_tempy + p4_temp1y) / 2,
      p4_tempx = (p4_tempx + n) / 2,
      p4_tempy = (p4_tempy + d) / 2;
  var Wp = document.createElementNS("http://www.w3.org/2000/svg", "ellipse");
  Wp.setAttribute("stroke", "#ee600d"),
      Wp.setAttribute("stroke-width", C),
      Wp.setAttribute("fill", "#ee600d"),
      Wp.setAttribute("cx", p1_tempx),
      Wp.setAttribute("cy", p1_tempy),
      Wp.setAttribute("rx", temp),
      Wp.setAttribute("ry", temp1),
      Wp.setAttribute("transform", " translate(" + Math.abs(p4_tempx - p1_tempx) + "," + Math.abs(p4_tempy - p1_tempy) + ") rotate(135," + p1_tempx + "," + p1_tempy + ")"),
      k.appendChild(Wp),
      outer_midx = (n + m) / 2,
      outer_midy = (d + y) / 2,
      mid_ou_p4_ou_p1x = (n + outer_midx) / 2,
      mid_ou_p4_ou_p1y = (d + outer_midy) / 2,
      outermidx = (mid_ou_p4_ou_p1x + n) / 2,
      outermidy = (mid_ou_p4_ou_p1y + d) / 2,
      tempmidx = (outermidx + mid_ou_p4_ou_p1x) / 2,
      tempmidy = (outermidy + mid_ou_p4_ou_p1y) / 2,
      inner_midx = (h + o) / 2,
      inner_midy = (b + a) / 2,
      mid_in_p4_in_p1x = (h + inner_midx) / 2,
      mid_in_p4_in_p1y = (b + inner_midy) / 2,
      innermidx = (mid_in_p4_in_p1x + h) / 2,
      innermidy = (mid_in_p4_in_p1y + b) / 2,
      temp1midx = (innermidx + mid_in_p4_in_p1x) / 2,
      temp1midy = (innermidy + mid_in_p4_in_p1y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var kp = temp1x
      , qp = temp1y
      , Sp = temp2x
      , Np = temp2y;
  mid_ou_p4_ou_p1x = (m + outer_midx) / 2,
      mid_ou_p4_ou_p1y = (y + outer_midy) / 2,
      outermidx = (mid_ou_p4_ou_p1x + m) / 2,
      outermidy = (mid_ou_p4_ou_p1y + y) / 2,
      tempmidx = (outermidx + mid_ou_p4_ou_p1x) / 2,
      tempmidy = (outermidy + mid_ou_p4_ou_p1y) / 2,
      inner_midx = (h + o) / 2,
      inner_midy = (b + a) / 2,
      mid_in_p4_in_p1x = (o + inner_midx) / 2,
      mid_in_p4_in_p1y = (a + inner_midy) / 2,
      innermidx = (mid_in_p4_in_p1x + o) / 2,
      innermidy = (mid_in_p4_in_p1y + a) / 2,
      temp1midx = (innermidx + mid_in_p4_in_p1x) / 2,
      temp1midy = (innermidy + mid_in_p4_in_p1y) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1midx = (innermidx + temp1midx) / 2,
      temp1midy = (innermidy + temp1midy) / 2,
      temp1x = (tempmidx + temp1midx) / 2,
      temp1y = (tempmidy + temp1midy) / 2,
      tempx = (tempmidx + temp1x) / 2,
      tempy = (tempmidy + temp1y) / 2,
      temp2x = (tempx + temp1x) / 2,
      temp2y = (tempy + temp1y) / 2;
  var Ep = temp1x
      , Mp = temp1y
      , Bp = temp2x
      , Lp = temp2y
      , q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", kp),
      q.setAttribute("x2", Ep),
      q.setAttribute("y1", qp),
      q.setAttribute("y2", Mp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", C),
      k.appendChild(q);
  var q = document.createElementNS("http://www.w3.org/2000/svg", "line");
  q.setAttribute("x1", Sp),
      q.setAttribute("x2", Bp),
      q.setAttribute("y1", Np),
      q.setAttribute("y2", Lp),
      q.setAttribute("stroke", "#ee600d"),
      q.setAttribute("stroke-width", 2.5 * C),
      k.appendChild(q)
}
function setDesignFor_B_S0(t, e, p) {
  var m, y, i, x, _, r, n, d, o, a, s, u, l, c, h, b, v, A, g, w;
  m = e.x,
      y = e.y,
      i = e.x + e.length,
      x = e.y,
      _ = e.x + e.length,
      r = e.y + e.length,
      n = e.x,
      d = e.y + e.length,
      o = t.x,
      a = t.y,
      s = t.x + t.length,
      u = t.y,
      l = t.x + t.length,
      c = t.y + t.length,
      h = t.x,
      b = t.y + t.length,
      stroke_size = 1.5 * e.length / 100,
      tempmidx = (o + m) / 2,
      tempmidy = (a + y) / 2,
      tempx = (o + tempmidx) / 2,
      tempy = (a + tempmidy) / 2,
      tempx = (o + tempx) / 2,
      tempy = (a + tempy) / 2,
      v = tempx,
      A = tempy,
      tempmidx = (s + i) / 2,
      tempmidy = (u + x) / 2,
      tempx = (s + tempmidx) / 2,
      tempy = (u + tempmidy) / 2,
      tempx = (s + tempx) / 2,
      tempy = (u + tempy) / 2,
      g = tempx,
      w = tempy;
  var f = document.getElementById("svg" + p)
      , C = document.createElementNS("http://www.w3.org/2000/svg", "line");
  C.setAttribute("x1", v),
      C.setAttribute("x2", g),
      C.setAttribute("y1", A),
      C.setAttribute("y2", w),
      C.setAttribute("stroke", "#ee600d"),
      C.setAttribute("stroke-width", stroke_size),
      C.setAttribute("stroke-linecap", "round"),
      C.setAttribute("stroke-dasharray", "0, " + stroke_size),
      f.appendChild(C),
      tempmidx = (s + i) / 2,
      tempmidy = (u + x) / 2,
      tempx = (s + tempmidx) / 2,
      tempy = (u + tempmidy) / 2,
      tempx = (s + tempx) / 2,
      tempy = (u + tempy) / 2,
      v = tempx,
      A = tempy,
      tempmidx = (l + _) / 2,
      tempmidy = (c + r) / 2,
      tempx = (l + tempmidx) / 2,
      tempy = (c + tempmidy) / 2,
      tempx = (l + tempx) / 2,
      tempy = (c + tempy) / 2,
      g = tempx,
      w = tempy;
  var C = document.createElementNS("http://www.w3.org/2000/svg", "line");
  C.setAttribute("x1", v),
      C.setAttribute("x2", g),
      C.setAttribute("y1", A),
      C.setAttribute("y2", w),
      C.setAttribute("stroke", "#ee600d"),
      C.setAttribute("stroke-width", stroke_size),
      C.setAttribute("stroke-linecap", "round"),
      C.setAttribute("stroke-dasharray", "0, " + stroke_size),
      f.appendChild(C),
      tempmidx = (l + _) / 2,
      tempmidy = (c + r) / 2,
      tempx = (l + tempmidx) / 2,
      tempy = (c + tempmidy) / 2,
      tempx = (l + tempx) / 2,
      tempy = (c + tempy) / 2,
      v = tempx,
      A = tempy,
      tempmidx = (h + n) / 2,
      tempmidy = (b + d) / 2,
      tempx = (h + tempmidx) / 2,
      tempy = (b + tempmidy) / 2,
      tempx = (h + tempx) / 2,
      tempy = (b + tempy) / 2,
      g = tempx,
      w = tempy;
  var C = document.createElementNS("http://www.w3.org/2000/svg", "line");
  C.setAttribute("x1", v),
      C.setAttribute("x2", g),
      C.setAttribute("y1", A),
      C.setAttribute("y2", w),
      C.setAttribute("stroke", "#ee600d"),
      C.setAttribute("stroke-width", stroke_size),
      C.setAttribute("stroke-linecap", "round"),
      C.setAttribute("stroke-dasharray", "0, " + stroke_size),
      f.appendChild(C),
      tempmidx = (h + n) / 2,
      tempmidy = (b + d) / 2,
      tempx = (h + tempmidx) / 2,
      tempy = (b + tempmidy) / 2,
      tempx = (h + tempx) / 2,
      tempy = (b + tempy) / 2,
      v = tempx,
      A = tempy,
      tempmidx = (o + m) / 2,
      tempmidy = (a + y) / 2,
      tempx = (o + tempmidx) / 2,
      tempy = (a + tempmidy) / 2,
      tempx = (o + tempx) / 2,
      tempy = (a + tempy) / 2,
      g = tempx,
      w = tempy;
  var C = document.createElementNS("http://www.w3.org/2000/svg", "line");
  C.setAttribute("x1", v),
      C.setAttribute("x2", g),
      C.setAttribute("y1", A),
      C.setAttribute("y2", w),
      C.setAttribute("stroke", "#ee600d"),
      C.setAttribute("stroke-width", stroke_size),
      C.setAttribute("stroke-linecap", "round"),
      C.setAttribute("stroke-dasharray", "0, " + stroke_size),
      f.appendChild(C)
}
function createJSON_squ_sudarshana(pointx, pointy, length) {
  var outer_p1x, outer_p1y, outer_p2x, outer_p2y, outer_p3x, outer_p3y, outer_p4x, outer_p4y, outer_top1x, outer_top1y, outer_top2x, outer_top2y, outer_right1x, outer_right1y, outer_right2x, outer_right2y, outer_bottom1x, outer_bottom1y, outer_bottom2x, outer_bottom2y, outer_left1x, outer_left1y, outer_left2x, outer_left2y;
  var middle_p1x, middle_p1y, middle_p2x, middle_p2y, middle_p3x, middle_p3y, middle_p4x, middle_p4y, middle_top1x, middle_top1y, middle_top2x, middle_top2y, middle_right1x, middle_right1y, middle_right2x, middle_right2y, middle_bottom1x, middle_bottom1y, middle_bottom2x, middle_bottom2y, middle_left1x, middle_left1y, middle_left2x, middle_left2y;
  var inner_p1x, inner_p1y, inner_p2x, inner_p2y, inner_p3x, inner_p3y, inner_p4x, inner_p4y, inner_top1x, inner_top1y, inner_top2x, inner_top2y, inner_right1x, inner_right1y, inner_right2x, inner_right2y, inner_bottom1x, inner_bottom1y, inner_bottom2x, inner_bottom2y, inner_left1x, inner_left1y, inner_left2x, inner_left2y;
  var innermost_p1x, innermost_p1y, innermost_p2x, innermost_p2y, innermost_p3x, innermost_p3y, innermost_p4x, innermost_p4y, innermost_top1x, innermost_top1y, innermost_top2x, innermost_top2y, innermost_right1x, innermost_right1y, innermost_right2x, innermost_right2y, innermost_bottom1x, innermost_bottom1y, innermost_bottom2x, innermost_bottom2y, innermost_left1x, innermost_left1y, innermost_left2x, innermost_left2y;
  height = (length * 85) / 100;
  width = (length * 99) / 100;
  pointy = pointy + ((width - height) / 2);
  var coordinates = {};
  //outer p1, p2, p3, p4
  //p1 point (left top)........................1
  var outer_p1 = {};
  //calculations...
  outer_p1x = pointx;
  outer_p1y = pointy;
  outer_p1["x"] = outer_p1x;
  outer_p1["y"] = outer_p1y;
  //p2 point (right top)........................2
  var outer_p2 = {};
  //calculations...
  outer_p2x = pointx + width;
  outer_p2y = pointy;
  outer_p2["x"] = outer_p2x;
  outer_p2["y"] = outer_p2y;
  //p3 point (right bottom)......................3
  var outer_p3 = {};
  //calculations...
  outer_p3x = pointx + width;
  outer_p3y = pointy + height;
  outer_p3["x"] = outer_p3x;
  outer_p3["y"] = outer_p3y;
  //p4 point (left bottom).........................4
  var outer_p4 = {};
  //calculations...
  outer_p4x = pointx;
  outer_p4y = pointy + height;
  outer_p4["x"] = outer_p4x;
  outer_p4["y"] = outer_p4y;
  //mid of midouterp1 and p2 point (top) and 1/3rd part of that length.........................5
  var outer_top1 = {};
  //calculations...
  midx = (outer_p1x + outer_p2x) / 2;
  midy = (outer_p1y + outer_p2y) / 2;
  tempx = outer_p1x + ((outer_p2x - outer_p1x) / 3);
  tempy = outer_p1y + ((outer_p2y - outer_p1y) / 3);
  outer_top1x = (midx + tempx) / 2;
  outer_top1y = (midy + tempy) / 2;
  outer_top1["x"] = outer_top1x;
  outer_top1["y"] = outer_top1y;
  //mid of midouterp1 and p2 point (top) and 2/3rd part of that length.........................5
  var outer_top2 = {};
  //calculations...
  tempx = outer_p1x + (((outer_p2x - outer_p1x) * 2) / 3);
  tempy = outer_p1y + (((outer_p2y - outer_p1y) * 2) / 3);
  outer_top2x = (midx + tempx) / 2;
  outer_top2y = (midy + tempy) / 2;
  outer_top2["x"] = outer_top2x;
  outer_top2["y"] = outer_top2y;
  //mid of midouterp2 and p3 point (right) and 1/3rd part of that length.........................5
  var outer_right1 = {};
  //calculations...
  midx = (outer_p2x + outer_p3x) / 2;
  midy = (outer_p2y + outer_p3y) / 2;
  tempx = outer_p2x + ((outer_p3x - outer_p2x) / 3);
  tempy = outer_p2y + ((outer_p3y - outer_p2y) / 3);
  outer_right1x = (midx + tempx) / 2;
  outer_right1y = (midy + tempy) / 2;
  outer_right1["x"] = outer_right1x;
  outer_right1["y"] = outer_right1y;
  //mid of midouterp1 and p2 point (right) and 2/3rd part of that length.........................5
  var outer_right2 = {};
  //calculations...
  tempx = outer_p2x + (((outer_p3x - outer_p2x) * 2) / 3);
  tempy = outer_p2y + (((outer_p3y - outer_p2y) * 2) / 3);
  outer_right2x = (midx + tempx) / 2;
  outer_right2y = (midy + tempy) / 2;
  outer_right2["x"] = outer_right2x;
  outer_right2["y"] = outer_right2y;
  //mid of midouterp3 and p4 point (bottom) and 1/3rd part of that length.........................5
  var outer_bottom1 = {};
  //calculations...
  midx = (outer_p3x + outer_p4x) / 2;
  midy = (outer_p3y + outer_p4y) / 2;
  tempx = outer_p3x + ((outer_p4x - outer_p3x) / 3);
  tempy = outer_p3y + ((outer_p4y - outer_p3y) / 3);
  outer_bottom1x = (midx + tempx) / 2;
  outer_bottom1y = (midy + tempy) / 2;
  outer_bottom1["x"] = outer_bottom1x;
  outer_bottom1["y"] = outer_bottom1y;
  //mid of midouterp1 and p2 point (bottom) and 2/3rd part of that length.........................5
  var outer_bottom2 = {};
  //calculations...
  tempx = outer_p3x + (((outer_p4x - outer_p3x) * 2) / 3);
  tempy = outer_p3y + (((outer_p4y - outer_p3y) * 2) / 3);
  outer_bottom2x = (midx + tempx) / 2;
  outer_bottom2y = (midy + tempy) / 2;
  outer_bottom2["x"] = outer_bottom2x;
  outer_bottom2["y"] = outer_bottom2y;
  //mid of midouterp4 and p1 point (left) and 1/3rd part of that length.........................5
  var outer_left1 = {};
  //calculations...
  midx = (outer_p4x + outer_p1x) / 2;
  midy = (outer_p4y + outer_p1y) / 2;
  tempx = outer_p4x + ((outer_p1x - outer_p4x) / 3);
  tempy = outer_p4y + ((outer_p1y - outer_p4y) / 3);
  outer_left1x = (midx + tempx) / 2;
  outer_left1y = (midy + tempy) / 2;
  outer_left1["x"] = outer_left1x;
  outer_left1["y"] = outer_left1y;
  //mid of midouterp4 and p1 point (left) and 2/3rd part of that length.........................5
  var outer_left2 = {};
  //calculations...
  tempx = outer_p4x + (((outer_p1x - outer_p4x) * 2) / 3);
  tempy = outer_p4y + (((outer_p1y - outer_p4y) * 2) / 3);
  outer_left2x = (midx + tempx) / 2;
  outer_left2y = (midy + tempy) / 2;
  outer_left2["x"] = outer_left2x;
  outer_left2["y"] = outer_left2y;
  // innermostp1,p2,p3 and p4 corners of inner most square
  var innermost_p1 = {};
  //calculations...
  midx = (outer_left2x + outer_right1x) / 2;
  midy = (outer_left2y + outer_right1y) / 2;
  tempx = outer_left2x + ((outer_right1x - outer_left2x) / 3);
  tempy = outer_left2y + ((outer_right1y - outer_left2y) / 3);
  innermost_p1x = (midx + tempx) / 2;
  innermost_p1y = (midy + tempy) / 2;
  innermost_p1["x"] = innermost_p1x;
  innermost_p1["y"] = innermost_p1y;
  var innermost_p2 = {};
  //calculations...
  tempx = outer_left2x + (((outer_right1x - outer_left2x) * 2) / 3);
  tempy = outer_left2y + (((outer_right1y - outer_left2y) * 2) / 3);
  innermost_p2x = (midx + tempx) / 2;
  innermost_p2y = (midy + tempy) / 2;
  innermost_p2["x"] = innermost_p2x;
  innermost_p2["y"] = innermost_p2y;
  var innermost_p4 = {};
  //calculations...
  midx = (outer_left1x + outer_right2x) / 2;
  midy = (outer_left1y + outer_right2y) / 2;
  tempx = outer_left1x + ((outer_right2x - outer_left1x) / 3);
  tempy = outer_left1y + ((outer_right2y - outer_left1y) / 3);
  innermost_p4x = (midx + tempx) / 2;
  innermost_p4y = (midy + tempy) / 2;
  innermost_p4["x"] = innermost_p4x;
  innermost_p4["y"] = innermost_p4y;
  var innermost_p3 = {};
  //calculations...
  tempx = outer_left1x + (((outer_right2x - outer_left1x) * 2) / 3);
  tempy = outer_left1y + (((outer_right2y - outer_left1y) * 2) / 3);
  innermost_p3x = (midx + tempx) / 2;
  innermost_p3y = (midy + tempy) / 2;
  innermost_p3["x"] = innermost_p3x;
  innermost_p3["y"] = innermost_p3y;
  // inner p1, p2, p3 and p4
  var inner_p1 = {};
  //calculations...
  inner_p1x = (outer_p1x + innermost_p1x) / 2;
  inner_p1y = (outer_p1y + innermost_p1y) / 2;
  inner_p1["x"] = inner_p1x;
  inner_p1["y"] = inner_p1y;
  var inner_p2 = {};
  //calculations...
  inner_p2x = (outer_p2x + innermost_p2x) / 2;
  inner_p2y = (outer_p2y + innermost_p2y) / 2;
  inner_p2["x"] = inner_p2x;
  inner_p2["y"] = inner_p2y;
  var inner_p3 = {};
  //calculations...
  inner_p3x = (outer_p3x + innermost_p3x) / 2;
  inner_p3y = (outer_p3y + innermost_p3y) / 2;
  inner_p3["x"] = inner_p3x;
  inner_p3["y"] = inner_p3y;
  var inner_p4 = {};
  //calculations...
  inner_p4x = (outer_p4x + innermost_p4x) / 2;
  inner_p4y = (outer_p4y + innermost_p4y) / 2;
  inner_p4["x"] = inner_p4x;
  inner_p4["y"] = inner_p4y;
  // middle p1, p2, p3 and p4
  var middle_p1 = {};
  //calculations...
  middle_p1x = (outer_p1x + inner_p1x) / 2;
  middle_p1y = (outer_p1y + inner_p1y) / 2;
  middle_p1["x"] = middle_p1x;
  middle_p1["y"] = middle_p1y;
  var middle_p2 = {};
  //calculations...
  middle_p2x = (outer_p2x + inner_p2x) / 2;
  middle_p2y = (outer_p2y + inner_p2y) / 2;
  middle_p2["x"] = middle_p2x;
  middle_p2["y"] = middle_p2y;
  var middle_p3 = {};
  //calculations...
  middle_p3x = (outer_p3x + inner_p3x) / 2;
  middle_p3y = (outer_p3y + inner_p3y) / 2;
  middle_p3["x"] = middle_p3x;
  middle_p3["y"] = middle_p3y;
  var middle_p4 = {};
  //calculations...
  middle_p4x = (outer_p4x + inner_p4x) / 2;
  middle_p4y = (outer_p4y + inner_p4y) / 2;
  middle_p4["x"] = middle_p4x;
  middle_p4["y"] = middle_p4y;
  // middle and inner coordinates of top, right, bottom and left
  var inner_top1 = {};
  //calculations...
  inner_top1x = (outer_top1x + innermost_p1x) / 2;
  inner_top1y = (outer_top1y + innermost_p1y) / 2;
  inner_top1["x"] = inner_top1x;
  inner_top1["y"] = inner_top1y;
  var inner_top2 = {};
  //calculations...
  inner_top2x = (outer_top2x + innermost_p2x) / 2;
  inner_top2y = (outer_top2y + innermost_p2y) / 2;
  inner_top2["x"] = inner_top2x;
  inner_top2["y"] = inner_top2y;
  var inner_right1 = {};
  //calculations...
  inner_right1x = (outer_right1x + innermost_p2x) / 2;
  inner_right1y = (outer_right1y + innermost_p2y) / 2;
  inner_right1["x"] = inner_right1x;
  inner_right1["y"] = inner_right1y;
  var inner_right2 = {};
  //calculations...
  inner_right2x = (outer_right2x + innermost_p3x) / 2;
  inner_right2y = (outer_right2y + innermost_p3y) / 2;
  inner_right2["x"] = inner_right2x;
  inner_right2["y"] = inner_right2y;
  var inner_bottom1 = {};
  //calculations...
  inner_bottom1x = (outer_bottom1x + innermost_p3x) / 2;
  inner_bottom1y = (outer_bottom1y + innermost_p3y) / 2;
  inner_bottom1["x"] = inner_bottom1x;
  inner_bottom1["y"] = inner_bottom1y;
  var inner_bottom2 = {};
  //calculations...
  inner_bottom2x = (outer_bottom2x + innermost_p4x) / 2;
  inner_bottom2y = (outer_bottom2y + innermost_p4y) / 2;
  inner_bottom2["x"] = inner_bottom2x;
  inner_bottom2["y"] = inner_bottom2y;
  var inner_left1 = {};
  //calculations...
  inner_left1x = (outer_left1x + innermost_p4x) / 2;
  inner_left1y = (outer_left1y + innermost_p4y) / 2;
  inner_left1["x"] = inner_left1x;
  inner_left1["y"] = inner_left1y;
  var inner_left2 = {};
  //calculations...
  inner_left2x = (outer_left2x + innermost_p1x) / 2;
  inner_left2y = (outer_left2y + innermost_p1y) / 2;
  inner_left2["x"] = inner_left2x;
  inner_left2["y"] = inner_left2y;
  var middle_top1 = {};
  //calculations...
  middle_top1x = (outer_top1x + inner_top1x) / 2;
  middle_top1y = (outer_top1y + inner_top1y) / 2;
  middle_top1["x"] = middle_top1x;
  middle_top1["y"] = middle_top1y;
  var middle_top2 = {};
  //calculations...
  middle_top2x = (outer_top2x + inner_top2x) / 2;
  middle_top2y = (outer_top2y + inner_top2y) / 2;
  middle_top2["x"] = middle_top2x;
  middle_top2["y"] = middle_top2y;
  var middle_right1 = {};
  //calculations...
  middle_right1x = (outer_right1x + inner_right1x) / 2;
  middle_right1y = (outer_right1y + inner_right1y) / 2;
  middle_right1["x"] = middle_right1x;
  middle_right1["y"] = middle_right1y;
  var middle_right2 = {};
  //calculations...
  middle_right2x = (outer_right2x + inner_right2x) / 2;
  middle_right2y = (outer_right2y + inner_right2y) / 2;
  middle_right2["x"] = middle_right2x;
  middle_right2["y"] = middle_right2y;
  var middle_bottom1 = {};
  //calculations...
  middle_bottom1x = (outer_bottom1x + inner_bottom1x) / 2;
  middle_bottom1y = (outer_bottom1y + inner_bottom1y) / 2;
  middle_bottom1["x"] = middle_bottom1x;
  middle_bottom1["y"] = middle_bottom1y;
  var middle_bottom2 = {};
  //calculations...
  middle_bottom2x = (outer_bottom2x + inner_bottom2x) / 2;
  middle_bottom2y = (outer_bottom2y + inner_bottom2y) / 2;
  middle_bottom2["x"] = middle_bottom2x;
  middle_bottom2["y"] = middle_bottom2y;
  var middle_left1 = {};
  //calculations...
  middle_left1x = (outer_left1x + inner_left1x) / 2;
  middle_left1y = (outer_left1y + inner_left1y) / 2;
  middle_left1["x"] = middle_left1x;
  middle_left1["y"] = middle_left1y;
  var middle_left2 = {};
  //calculations...
  middle_left2x = (outer_left2x + inner_left2x) / 2;
  middle_left2y = (outer_left2y + inner_left2y) / 2;
  middle_left2["x"] = middle_left2x;
  middle_left2["y"] = middle_left2y;
  // add all coordinates to whole json +"coordinates"
  coordinates.outer_p1 = outer_p1;
  coordinates.outer_p2 = outer_p2;
  coordinates.outer_p3 = outer_p3;
  coordinates.outer_p4 = outer_p4;
  coordinates.outer_top1 = outer_top1;
  coordinates.outer_top2 = outer_top2;
  coordinates.outer_right1 = outer_right1;
  coordinates.outer_right2 = outer_right2;
  coordinates.outer_bottom1 = outer_bottom1;
  coordinates.outer_bottom2 = outer_bottom2;
  coordinates.outer_left1 = outer_left1;
  coordinates.outer_left2 = outer_left2;
  coordinates.innermost_p1 = innermost_p1;
  coordinates.innermost_p2 = innermost_p2;
  coordinates.innermost_p3 = innermost_p3;
  coordinates.innermost_p4 = innermost_p4;
  coordinates.inner_p1 = inner_p1;
  coordinates.inner_p2 = inner_p2;
  coordinates.inner_p3 = inner_p3;
  coordinates.inner_p4 = inner_p4;
  coordinates.middle_p1 = middle_p1;
  coordinates.middle_p2 = middle_p2;
  coordinates.middle_p3 = middle_p3;
  coordinates.middle_p4 = middle_p4;
  coordinates.inner_top1 = inner_top1;
  coordinates.inner_top2 = inner_top2;
  coordinates.inner_right1 = inner_right1;
  coordinates.inner_right2 = inner_right2;
  coordinates.inner_bottom1 = inner_bottom1;
  coordinates.inner_bottom2 = inner_bottom2;
  coordinates.inner_left1 = inner_left1;
  coordinates.inner_left2 = inner_left2;
  coordinates.middle_top1 = middle_top1;
  coordinates.middle_top2 = middle_top2;
  coordinates.middle_right1 = middle_right1;
  coordinates.middle_right2 = middle_right2;
  coordinates.middle_bottom1 = middle_bottom1;
  coordinates.middle_bottom2 = middle_bottom2;
  coordinates.middle_left1 = middle_left1;
  coordinates.middle_left2 = middle_left2;
  return coordinates;
}
function create_squ_sudarshana_chart_path(JN) {
  var MOVE = " M ";
  var LINE = " L ";
  var SPACE = " ";
  return MOVE + JN.outer_p1.x + SPACE + JN.outer_p1.y + LINE + JN.outer_p2.x + SPACE + JN.outer_p2.y + LINE + JN.outer_p3.x + SPACE + JN.outer_p3.y + LINE + JN.outer_p4.x + SPACE + JN.outer_p4.y + LINE + JN.outer_p1.x + SPACE + JN.outer_p1.y + MOVE + JN.outer_top1.x + SPACE + JN.outer_top1.y + LINE + JN.outer_bottom2.x + SPACE + JN.outer_bottom2.y + MOVE + JN.outer_top2.x + SPACE + JN.outer_top2.y + LINE + JN.outer_bottom1.x + SPACE + JN.outer_bottom1.y + MOVE + JN.outer_left2.x + SPACE + JN.outer_left2.y + LINE + JN.outer_right1.x + SPACE + JN.outer_right1.y + MOVE + JN.outer_left1.x + SPACE + JN.outer_left1.y + LINE + JN.outer_right2.x + SPACE + JN.outer_right2.y + MOVE + JN.innermost_p1.x + SPACE + JN.innermost_p1.y + LINE + JN.outer_p1.x + SPACE + JN.outer_p1.y + MOVE + JN.innermost_p2.x + SPACE + JN.innermost_p2.y + LINE + JN.outer_p2.x + SPACE + JN.outer_p2.y + MOVE + JN.innermost_p3.x + SPACE + JN.innermost_p3.y + LINE + JN.outer_p3.x + SPACE + JN.outer_p3.y + MOVE + JN.innermost_p4.x + SPACE + JN.innermost_p4.y + LINE + JN.outer_p4.x + SPACE + JN.outer_p4.y + MOVE + JN.inner_p1.x + SPACE + JN.inner_p1.y + LINE + JN.inner_p2.x + SPACE + JN.inner_p2.y + LINE + JN.inner_p3.x + SPACE + JN.inner_p3.y + LINE + JN.inner_p4.x + SPACE + JN.inner_p4.y + LINE + JN.inner_p1.x + SPACE + JN.inner_p1.y + MOVE + JN.middle_p1.x + SPACE + JN.middle_p1.y + LINE + JN.middle_p2.x + SPACE + JN.middle_p2.y + LINE + JN.middle_p3.x + SPACE + JN.middle_p3.y + LINE + JN.middle_p4.x + SPACE + JN.middle_p4.y + LINE + JN.middle_p1.x + SPACE + JN.middle_p1.y
}
function insert_planets_sudarshana_squ(json_coordinates, obj_recieved) {
  var lag_no, sun_no, moo_no;
  lag_no = obj_recieved.PlanetPos.LAG;
  sun_no = obj_recieved.PlanetPos.SUN;
  moo_no = obj_recieved.PlanetPos.MOO;

  var m = ["Moo", "Sun", "Mer", "Ven", "Mar", "Jup", "Sat", "Rah", "Ket", "Lag", "Gul", "Aru", "Thi"];

  // console.log("lag_no",lag_no);
  // console.log("moo_no",moo_no);
  // console.log("sun_no",sun_no);
  var PlanetsInRasi = [];
  for (var i = 0; i <= 12; i++) {
      PlanetsInRasi[i] = [];
  }
  for (var i = 1; i <= 12; i++) {
      var count = 0;
      for (var j = 0; j < 10; j++) //change to j < 11 to see gullikan in sudarshana chakra
      {
          if (obj_recieved.PlanetPos[m[j].toUpperCase()] == i) {
              PlanetsInRasi[i][count] = j;
              count = count + 1;
          }
      }
  }
  // for (i=0; i <= 12;i++)
  // {
  //     console.log("PlanetCountInRasi "+i+ PlanetsInRasi[i].length)
  //     for(j=0; j < PlanetsInRasi[i].length; j++)
  //     {console.log("PlanetsInRasi "+i+" "+ j,PlanetsInRasi[i][j]);}
  // }
  /*if (shape == 0) {
      rad_diff = ((radius.outer_radius - radius.middle_radius) * 90) / 100;
  }
  else if (shape == 1) {
      rad_diff = ((radius.outer_radius - radius.middle_radius) * 60) / 100;
  }*/
  setPlanetPosition_Sudharshana_wrt_Sun_squ(sun_no, json_coordinates, obj_recieved, PlanetsInRasi);
  setPlanetPosition_Sudharshana_wrt_Moo_squ(moo_no, json_coordinates, obj_recieved, PlanetsInRasi);
  setPlanetPosition_Sudharshana_wrt_Lag_squ(lag_no, json_coordinates, obj_recieved, PlanetsInRasi);
  draw_swasthi_at_center_Sudharshana_squ(json_coordinates, obj_recieved);
  setfunctions_for_planets()
  //    enterNumber_in_inner_Circle(radius.originx, radius.originy, rad_diff, radius.inner_most_radius);
  /*enterNumber_in_Circle_Lag_Moo_Sun(radius.originx, radius.originy, rad_diff, radius.inner_most_radius, lag_no, shape);
  enterNumber_in_Circle_Lag_Moo_Sun(radius.originx, radius.originy, rad_diff, radius.inner_radius, moo_no, shape);
  enterNumber_in_Circle_Lag_Moo_Sun(radius.originx, radius.originy, rad_diff, radius.middle_radius, sun_no, shape);
  enterTextInCenter(radius.originx, radius.originy, shape);*/
}
function setPlanetPosition_Sudharshana_wrt_Sun_squ(sun_no, json_coordinates, obj_recieved, PlanetsInRasi) {
  var svgarea = document.getElementById("svg" + obj_recieved.Target.ID);
  var lJSON = setPlanet_Font_And_SizeForLanguage_UTF(obj_recieved);

  for (var i = 0; i < 12; i++) {
      var house_no = no_to_house_no(sun_no, i + 1);
      var rasi_no_cum_cell_coordinates = single_house_coordinates_Sun_squ(house_no, json_coordinates);
      if (PlanetsInRasi[i + 1].length !== 0) {
          var planet_positions_of_a_cell = getPlanetPosition_Sudharshana_wrt_Sun_squ(PlanetsInRasi[i + 1].length, rasi_no_cum_cell_coordinates.column_coordinates);
      }
      var color = rasi_no_cum_cell_coordinates.column_coordinates;
      var polyline = document.createElementNS("http://www.w3.org/2000/svg", 'polyline');
      if (Object.keys(color).length == 3) {
          polyline.setAttribute("points", "" + color.c_p1.x + "," + color.c_p1.y + " " + color.c_p2.x + "," + color.c_p2.y + " " + color.c_p3.x + "," + color.c_p3.y + "  " + color.c_p1.x + "," + color.c_p1.y);
      }
      else if (Object.keys(color).length == 4) {
          polyline.setAttribute("points", "" + color.c_p1.x + "," + color.c_p1.y + " " + color.c_p2.x + "," + color.c_p2.y + " " + color.c_p3.x + "," + color.c_p3.y + " " + color.c_p4.x + "," + color.c_p4.y + " " + color.c_p1.x + "," + color.c_p1.y);
      }
      polyline.setAttribute("style", "fill: rgb(255,255,220); fill-opacity=0.1; stroke:black; stroke-width: 1");
      svgarea.appendChild(polyline);
      var text_rasino = document.createElementNS("http://www.w3.org/2000/svg", 'text');
      text_rasino.setAttribute("x", rasi_no_cum_cell_coordinates.rasi_no_coordinates.x);
      text_rasino.setAttribute("y", rasi_no_cum_cell_coordinates.rasi_no_coordinates.y);
      text_rasino.setAttribute("text-anchor", "middle");
      text_rasino.setAttribute("alignment-baseline", "middle");
      //        text_rasino.setAttribute("font-size", 0.625 * lJSON.FontSize + "px");
      // text_rasino.setAttribute("font-size", 14);
      //        text_rasino.setAttribute("style", "stroke-antialiasing:true; font-family:Calibri");
      text_rasino.setAttribute("style", "stroke-antialiasing:true; font-family:Calibri; font-size:" + (0.4 * 4 * obj_recieved.Target.width / 100) + "px;");
      text_rasino.setAttribute("fill", "green");
      text_rasino.appendChild(document.createTextNode(i + 1));
      svgarea.appendChild(text_rasino);
      for (var j = 0; j < (PlanetsInRasi[i + 1].length); j++) {
          //            if (Object.keys(planet_positions_of_a_cell).length !== 0) {
          var text1 = document.createElementNS("http://www.w3.org/2000/svg", 'text');
          //            text1.setAttribute("id", obj_recieved.chart[i].planet[j].planet_name);
          text1.setAttribute("class", "classes" + (PlanetsInRasi[i + 1][j] + 1) + "");
          text1.setAttribute("x", planet_positions_of_a_cell[j].x);
          text1.setAttribute("y", planet_positions_of_a_cell[j].y);
          text1.setAttribute("text-anchor", "middle");
          text1.setAttribute("alignment-baseline", "middle");
          //            text1.setAttribute("style", "stroke-antialiasing:true; font-family:"+lJSON.Font+"");
          //            text1.setAttribute("font-size",0.625 * lJSON.FontSize + "px");
          text1.setAttribute("style", "stroke-antialiasing:true; font-family:" + lJSON.Font + "; font-size :" + ((PlanetsInRasi[i + 1].length > 6) ? (0.625 * lJSON.SmallFontSize) : (0.625 * lJSON.FontSize)) + "px;");
          // text1.setAttribute("fill", "blue");
          // text1.style.cursor = "pointer";
          //            text1.innerHTML = (obj_recieved.chart[i].planet[j].planet_name).substr(0, 2);
          text1.appendChild(document.createTextNode(lJSON.planetsArray[PlanetsInRasi[i + 1][j]]));
          // text1.innerHTML = obj_recieved.chart[i].planet[j].planet_name;
          svgarea.appendChild(text1);
          //            <line x1="0" y1="0" x2="200" y2="200" style="stroke:rgb(255,0,0);stroke-width:2" />
          //            var line = document.createElementNS("http://www.w3.org/2000/svg", 'line');
          ////            line.setAttribute("id", obj_recieved.chart[i].planet[j].planet_name);
          //            line.setAttribute("x1", radius.originx);
          //            line.setAttribute("y1", radius.originy);
          //            line.setAttribute("x2", planet_positions_of_a_cell[j].x);
          //            line.setAttribute("y2", planet_positions_of_a_cell[j].y);
          //            line.setAttribute("style", "stroke:rgb(50,50,100);stroke-width:1");
          //            svgarea.appendChild(line);
          //            }
      }
  }
  var color = json_coordinates;
  var polyline = document.createElementNS("http://www.w3.org/2000/svg", 'polyline');
  polyline.setAttribute("points", "" + color.innermost_p1.x + "," + color.innermost_p1.y + " " + color.innermost_p2.x + "," + color.innermost_p2.y + " " + color.innermost_p3.x + "," + color.innermost_p3.y + " " + color.innermost_p4.x + "," + color.innermost_p4.y + " " + color.innermost_p1.x + "," + color.innermost_p1.y);
  polyline.setAttribute("style", "fill: rgb(255,255,220); fill-opacity=0.1; stroke:black; stroke-width: 1");
  svgarea.appendChild(polyline);
}
function setPlanetPosition_Sudharshana_wrt_Moo_squ(moo_no, json_coordinates, obj_recieved, PlanetsInRasi) {
  var svgarea = document.getElementById("svg" + obj_recieved.Target.ID);
  var lJSON = setPlanet_Font_And_SizeForLanguage_UTF(obj_recieved);
  for (var i = 0; i < 12; i++) {
      var house_no = no_to_house_no(moo_no, i + 1);
      var rasi_no_cum_cell_coordinates = single_house_coordinates_Moo_squ(house_no, json_coordinates);
      //        console.log("Here1",house_no);
      //        console.log("Here2",(i+1));
      //        console.log("Here3",rasi_no_cum_cell_coordinates);
      if (PlanetsInRasi[i + 1].length !== 0) {
          var planet_positions_of_a_cell = getPlanetPosition_Sudharshana_wrt_Moo_Lag_squ(PlanetsInRasi[i + 1].length, rasi_no_cum_cell_coordinates.column_coordinates, house_no);
          //            console.log("Here4",planet_positions_of_a_cell);
      }
      var color = rasi_no_cum_cell_coordinates.column_coordinates;
      var polyline = document.createElementNS("http://www.w3.org/2000/svg", 'polyline');
      if (Object.keys(color).length == 3) {
          polyline.setAttribute("points", "" + color.c_p1.x + "," + color.c_p1.y + " " + color.c_p2.x + "," + color.c_p2.y + " " + color.c_p3.x + "," + color.c_p3.y + "  " + color.c_p1.x + "," + color.c_p1.y);
      }
      else if (Object.keys(color).length == 4) {
          polyline.setAttribute("points", "" + color.c_p1.x + "," + color.c_p1.y + " " + color.c_p2.x + "," + color.c_p2.y + " " + color.c_p3.x + "," + color.c_p3.y + " " + color.c_p4.x + "," + color.c_p4.y + " " + color.c_p1.x + "," + color.c_p1.y);
      }
      polyline.setAttribute("style", "fill: rgb(255,255,190); fill-opacity=0.1; stroke:black; stroke-width: 1");
      svgarea.appendChild(polyline);
      var text_rasino = document.createElementNS("http://www.w3.org/2000/svg", 'text');
      text_rasino.setAttribute("x", rasi_no_cum_cell_coordinates.rasi_no_coordinates.x);
      text_rasino.setAttribute("y", rasi_no_cum_cell_coordinates.rasi_no_coordinates.y);
      text_rasino.setAttribute("text-anchor", "middle");
      text_rasino.setAttribute("alignment-baseline", "middle");
      //        text_rasino.setAttribute("font-size", 0.625 * lJSON.FontSize + "px");
      //        text_rasino.setAttribute("style", "stroke-antialiasing:true; font-family:Calibri");
      text_rasino.setAttribute("style", "stroke-antialiasing:true; font-family:Calibri; font-size:" + (0.4 * 4 * obj_recieved.Target.width / 100) + "px;");
      text_rasino.setAttribute("fill", "green");
      text_rasino.appendChild(document.createTextNode(i + 1));
      svgarea.appendChild(text_rasino);
      /*find_planet_coordinates_north_and_east_combo(planet_count, cell_coordinates, house_no)*/
      for (var j = 0; j < (PlanetsInRasi[i + 1].length); j++) {
          //            if (Object.keys(planet_positions_of_a_cell).length !== 0) {
          var text1 = document.createElementNS("http://www.w3.org/2000/svg", 'text');
          //            text1.setAttribute("id", obj_recieved.chart[i].planet[j].planet_name);
          text1.setAttribute("class", "classes" + (PlanetsInRasi[i + 1][j] + 1) + "");
          text1.setAttribute("x", planet_positions_of_a_cell[j].x);
          text1.setAttribute("y", planet_positions_of_a_cell[j].y);
          text1.setAttribute("text-anchor", "middle");
          text1.setAttribute("alignment-baseline", "middle");
          //            text1.setAttribute("style", "stroke-antialiasing:true; font-family:"+lJSON.Font+"");
          //            text1.setAttribute("font-size",0.625 * lJSON.FontSize + "px");
          text1.setAttribute("style", "stroke-antialiasing:true; font-family:" + lJSON.Font + ";font-size:" + ((PlanetsInRasi[i + 1].length > 6) ? (0.625 * lJSON.SmallFontSize) : (0.625 * lJSON.FontSize)) + "px;");
          // text1.setAttribute("fill", "blue");
          // text1.style.cursor = "pointer";
          //            text1.innerHTML = (obj_recieved.chart[i].planet[j].planet_name).substr(0, 2);
          text1.appendChild(document.createTextNode(lJSON.planetsArray[PlanetsInRasi[i + 1][j]]));
          // text1.innerHTML = obj_recieved.chart[i].planet[j].planet_name;
          svgarea.appendChild(text1);
          //            <line x1="0" y1="0" x2="200" y2="200" style="stroke:rgb(255,0,0);stroke-width:2" />
          //            var line = document.createElementNS("http://www.w3.org/2000/svg", 'line');
          ////            line.setAttribute("id", obj_recieved.chart[i].planet[j].planet_name);
          //            line.setAttribute("x1", radius.originx);
          //            line.setAttribute("y1", radius.originy);
          //            line.setAttribute("x2", planet_positions_of_a_cell[j].x);
          //            line.setAttribute("y2", planet_positions_of_a_cell[j].y);
          //            line.setAttribute("style", "stroke:rgb(50,50,100);stroke-width:1");
          //            svgarea.appendChild(line);
          //            }
      }
  }
}
function setPlanetPosition_Sudharshana_wrt_Lag_squ(lag_no, json_coordinates, obj_recieved, PlanetsInRasi) {
  var svgarea = document.getElementById("svg" + obj_recieved.Target.ID);
  var lJSON = setPlanet_Font_And_SizeForLanguage_UTF(obj_recieved);
  for (var i = 0; i < 12; i++) {
      var house_no = no_to_house_no(lag_no, i + 1);
      var rasi_no_cum_cell_coordinates = single_house_coordinates_Lag_squ(house_no, json_coordinates);
      if (PlanetsInRasi[i + 1].length !== 0) {
          var planet_positions_of_a_cell = getPlanetPosition_Sudharshana_wrt_Moo_Lag_squ(PlanetsInRasi[i + 1].length, rasi_no_cum_cell_coordinates.column_coordinates, house_no);
      }
      var color = rasi_no_cum_cell_coordinates.column_coordinates;
      var polyline = document.createElementNS("http://www.w3.org/2000/svg", 'polyline');
      if (Object.keys(color).length == 3) {
          polyline.setAttribute("points", "" + color.c_p1.x + "," + color.c_p1.y + " " + color.c_p2.x + "," + color.c_p2.y + " " + color.c_p3.x + "," + color.c_p3.y + "  " + color.c_p1.x + "," + color.c_p1.y);
      }
      else if (Object.keys(color).length == 4) {
          polyline.setAttribute("points", "" + color.c_p1.x + "," + color.c_p1.y + " " + color.c_p2.x + "," + color.c_p2.y + " " + color.c_p3.x + "," + color.c_p3.y + " " + color.c_p4.x + "," + color.c_p4.y + " " + color.c_p1.x + "," + color.c_p1.y);
      }
      polyline.setAttribute("style", "fill: rgb(255,255,150); fill-opacity=0.1; stroke:black; stroke-width: 1");
      svgarea.appendChild(polyline);
      var text_rasino = document.createElementNS("http://www.w3.org/2000/svg", 'text');
      text_rasino.setAttribute("x", rasi_no_cum_cell_coordinates.rasi_no_coordinates.x);
      text_rasino.setAttribute("y", rasi_no_cum_cell_coordinates.rasi_no_coordinates.y);
      text_rasino.setAttribute("text-anchor", "middle");
      text_rasino.setAttribute("alignment-baseline", "middle");
      //        text_rasino.setAttribute("font-size", 0.625 * lJSON.FontSize + "px");
      //        text_rasino.setAttribute("style", "stroke-antialiasing:true; font-family:Calibri");
      text_rasino.setAttribute("style", "stroke-antialiasing:true; font-family:Calibri; font-size:" + (0.4 * 4 * obj_recieved.Target.width / 100) + "px;");
      text_rasino.setAttribute("fill", "green");
      text_rasino.appendChild(document.createTextNode(i + 1));
      svgarea.appendChild(text_rasino);
      /*find_planet_coordinates_north_and_east_combo(planet_count, cell_coordinates, house_no)*/
      for (var j = 0; j < (PlanetsInRasi[i + 1].length); j++) {
          //            if (Object.keys(planet_positions_of_a_cell).length !== 0) {
          var text1 = document.createElementNS("http://www.w3.org/2000/svg", 'text');
          //            text1.setAttribute("id", obj_recieved.chart[i].planet[j].planet_name);
          text1.setAttribute("class", "classes" + (PlanetsInRasi[i + 1][j] + 1) + "");
          text1.setAttribute("x", planet_positions_of_a_cell[j].x);
          text1.setAttribute("y", planet_positions_of_a_cell[j].y);
          text1.setAttribute("text-anchor", "middle");
          text1.setAttribute("alignment-baseline", "middle");
          //            text1.setAttribute("style", "stroke-antialiasing:true; font-family:"+lJSON.Font+"");
          //            text1.setAttribute("font-size", 0.625 * lJSON.FontSize + "px");
          text1.setAttribute("style", "stroke-antialiasing:true; font-family:" + lJSON.Font + "; font-size:" + ((PlanetsInRasi[i + 1].length > 6) ? (0.625 * lJSON.SmallFontSize) : (0.625 * lJSON.FontSize)) + "px;");
          // text1.setAttribute("fill", "blue");
          // text1.style.cursor = "pointer";
          //            text1.innerHTML = (obj_recieved.chart[i].planet[j].planet_name).substr(0, 2);
          text1.appendChild(document.createTextNode(lJSON.planetsArray[PlanetsInRasi[i + 1][j]]));
          // text1.innerHTML = obj_recieved.chart[i].planet[j].planet_name;
          svgarea.appendChild(text1);
          //            <line x1="0" y1="0" x2="200" y2="200" style="stroke:rgb(255,0,0);stroke-width:2" />
          //            var line = document.createElementNS("http://www.w3.org/2000/svg", 'line');
          ////            line.setAttribute("id", obj_recieved.chart[i].planet[j].planet_name);
          //            line.setAttribute("x1", radius.originx);
          //            line.setAttribute("y1", radius.originy);
          //            line.setAttribute("x2", planet_positions_of_a_cell[j].x);
          //            line.setAttribute("y2", planet_positions_of_a_cell[j].y);
          //            line.setAttribute("style", "stroke:rgb(50,50,100);stroke-width:1");
          //            svgarea.appendChild(line);
          //            }
      }
  }
}
function no_to_house_no(no, house_no) {
  var newhouse_no = 0;
  if (parseInt(house_no) <= parseInt(no)) {
      newhouse_no = (parseInt(no) - parseInt(house_no)) + 1;
  }
  else if (parseInt(house_no) > parseInt(no)) {
      newhouse_no = 12 + ((parseInt(no) - parseInt(house_no)) + 1);
  }
  //    console.log(newhouse_no);
  return newhouse_no;
}
function single_house_coordinates_Sun_squ(house_no, JN_chart) {
  var totaljson = {};
  var rasi_no_coordinates = {};
  var column_coordinates = {};
  var c_p1, c_p2, c_p3;
  var c_p4 = null;
  //            if (house_no == 1) {
  //                c_p1 = JN_chart.p1_p1p2;
  //                c_p2 = JN_chart.p1p2;
  //                c_p3 = JN_chart.mid_top;
  //                c_p4 = JN_chart.center_p1;
  //            }
  //            else if (house_no == 2) {
  //                c_p1 = JN_chart.p1_p1p2;
  //                c_p2 = JN_chart.p1p2;
  //                c_p3 = JN_chart.mid_top;
  //                c_p4 = JN_chart.center_p1;
  //            }
  switch (house_no) {
      case 1:
          c_p1 = JN_chart.innermost_p2;
          c_p2 = JN_chart.innermost_p1;
          c_p3 = JN_chart.inner_top1;
          c_p4 = JN_chart.inner_top2;
          break;
      case 2:
          c_p1 = JN_chart.innermost_p2;
          c_p2 = JN_chart.inner_p2;
          c_p3 = JN_chart.inner_top2;
          //        c_p4 = JN_chart.mid_top;
          break;
      case 3:
          c_p1 = JN_chart.innermost_p2;
          c_p2 = JN_chart.inner_p2;
          c_p3 = JN_chart.inner_right1;
          //        c_p4 = JN_chart.center_p2;
          break;
      case 4:
          c_p1 = JN_chart.innermost_p2;
          c_p2 = JN_chart.inner_right1;
          c_p3 = JN_chart.inner_right2;
          c_p4 = JN_chart.innermost_p3;
          break;
      case 5:
          c_p1 = JN_chart.innermost_p3;
          c_p2 = JN_chart.inner_p3;
          c_p3 = JN_chart.inner_right2;
          //        c_p4 = JN_chart.center_p3;
          break;
      case 6:
          c_p1 = JN_chart.innermost_p3;
          c_p2 = JN_chart.inner_p3;
          c_p3 = JN_chart.inner_bottom1;
          //        c_p4 = JN_chart.p3_p3p4;
          break;
      case 7:
          c_p1 = JN_chart.innermost_p4;
          c_p2 = JN_chart.innermost_p3;
          c_p3 = JN_chart.inner_bottom1;
          c_p4 = JN_chart.inner_bottom2;
          break;
      case 8:
          c_p1 = JN_chart.innermost_p4;
          c_p2 = JN_chart.inner_p4;
          c_p3 = JN_chart.inner_bottom2;
          //        c_p4 = JN_chart.p3p4_p4;
          break;
      case 9:
          c_p1 = JN_chart.innermost_p4;
          c_p2 = JN_chart.inner_p4;
          c_p3 = JN_chart.inner_left1;
          //        c_p4 = JN_chart.p4;
          break;
      case 10:
          c_p1 = JN_chart.innermost_p1;
          c_p2 = JN_chart.innermost_p4;
          c_p3 = JN_chart.inner_left1;
          c_p4 = JN_chart.inner_left2;
          break;
      case 11:
          c_p1 = JN_chart.innermost_p1;
          c_p2 = JN_chart.inner_p1;
          c_p3 = JN_chart.inner_left2;
          //        c_p4 = JN_chart.p4p1;
          break;
      case 12:
          c_p1 = JN_chart.innermost_p1;
          c_p2 = JN_chart.inner_p1;
          c_p3 = JN_chart.inner_top1;
      //        c_p4 = JN_chart.p4p1_p1;
  }
  if (c_p1 !== null) {
      column_coordinates.c_p1 = c_p1;
      column_coordinates.c_p2 = c_p2;
      column_coordinates.c_p3 = c_p3;
      if (c_p4 !== null) {
          column_coordinates.c_p4 = c_p4;
          tempx = (c_p1.x + c_p3.x) / 2;
          tempy = (c_p1.y + c_p3.y) / 2;
          tempx = (c_p1.x + tempx) / 2;
          tempy = (c_p1.y + tempy) / 2;
          tempx = (c_p1.x + tempx) / 2;
          tempy = (c_p1.y + tempy) / 2;
          //            tempx=(c_p1.x+tempx)/2;
          //            tempy=(c_p1.y+tempy)/2;
          rasi_no_coordinates["x"] = tempx;
          rasi_no_coordinates["y"] = tempy;
      }
      else {
          tempx = (c_p2.x + c_p3.x) / 2;
          tempy = (c_p2.y + c_p3.y) / 2;
          tempx = (c_p1.x + tempx) / 2;
          tempy = (c_p1.y + tempy) / 2;
          tempx = (c_p1.x + tempx) / 2;
          tempy = (c_p1.y + tempy) / 2;
          temp1x = (c_p1.x + tempx) / 2;
          temp1y = (c_p1.y + tempy) / 2;
          rasi_no_coordinates["x"] = (tempx + temp1x) / 2;
          rasi_no_coordinates["y"] = (tempy + temp1y) / 2;
      }
      totaljson.rasi_no_coordinates = rasi_no_coordinates;
      totaljson.column_coordinates = column_coordinates;
      return totaljson;
  }
}
function getPlanetPosition_Sudharshana_wrt_Sun_squ(planet_count, cell_coordinates) {
  var vertex1, vertex2, vertex3, vertex4;
  var planet1_point1x, planet1_point1y;
  var planet2_point1x, planet2_point1y, planet2_point2x, planet2_point2y;
  var planet3_point1x, planet3_point1y, planet3_point2x, planet3_point2y, planet3_point3x, planet3_point3y;
  var planet4_point1x, planet4_point1y, planet4_point2x, planet4_point2y, planet4_point3x, planet4_point3y, planet4_point4x, planet4_point4y;
  var planet5_point1x, planet5_point1y, planet5_point2x, planet5_point2y, planet5_point3x, planet5_point3y, planet5_point4x, planet5_point4y, planet5_point5x, planet5_point5y;
  var planet6_point1x, planet6_point1y, planet6_point2x, planet6_point2y, planet6_point3x, planet6_point3y, planet6_point4x, planet6_point4y, planet6_point5x, planet6_point5y, planet6_point6x, planet6_point6y;
  var planet7_point1x, planet7_point1y, planet7_point2x, planet7_point2y, planet7_point3x, planet7_point3y, planet7_point4x, planet7_point4y, planet7_point5x, planet7_point5y, planet7_point6x, planet7_point6y, planet7_point7x, planet7_point7y;
  var planet8_point1x, planet8_point1y, planet8_point2x, planet8_point2y, planet8_point3x, planet8_point3y, planet8_point4x, planet8_point4y, planet8_point5x, planet8_point5y, planet8_point6x, planet8_point6y, planet8_point7x, planet8_point7y, planet8_point8x, planet8_point8y;
  var planet9_point1x, planet9_point1y, planet9_point2x, planet9_point2y, planet9_point3x, planet9_point3y, planet9_point4x, planet9_point4y, planet9_point5x, planet9_point5y, planet9_point6x, planet9_point6y, planet9_point7x, planet9_point7y, planet9_point8x, planet9_point8y, planet9_point9x, planet9_point9y;
  var planet_positions = [];
  if (Object.keys(cell_coordinates).length == 3) {
      vertex1 = cell_coordinates.c_p1;
      vertex2 = cell_coordinates.c_p2;
      vertex3 = cell_coordinates.c_p3;
      //        calculate  planet1_point1x, planet1_point1y;
      planet1_point1x = c_northColx = (vertex1.x + vertex2.x + vertex3.x) / 3;
      planet1_point1y = c_northColy = (vertex1.y + vertex2.y + vertex3.y) / 3;
      // for 2 planets positioning......
      // calculate planet2_point1x, planet2_point1y, planet2_point2x, planet2_point2y;
      //get 3 mid points of all vertices and 1st planest position is the mid of vertex 1 and 2 mid point and vertex 2 and 3,likewise calculate another mid point of mids  
      //        temp1x = (vertex1.x + vertex2.x) / 2;
      //        temp1y = (vertex1.y + vertex2.y) / 2;
      //        temp2x = (temp1x + vertex1.x) / 2;
      //        temp2y = (temp1y + vertex1.y) / 2;
      //        temp3x = (temp1x + vertex2.x) / 2;
      //        temp3y = (temp1y + vertex2.y) / 2;
      //        east_center_p3x = p4p1_1x + (((p2p3_2x-p4p1_1x)* 2)/ 3);
      //        planet2_point1x = vertex3.x + (((temp2x-vertex3.x)* 2)/ 3);
      //        planet2_point1y = vertex3.y + (((temp2y-vertex3.y)* 2)/ 3);
      //        planet2_point2x = vertex3.x + (((temp3x-vertex3.x)* 2)/ 3);
      //        planet2_point2y = vertex3.y + (((temp3y-vertex3.y)* 2)/ 3);
      //        temp2x = (vertex2.x + vertex3.x) / 2;
      //        temp2y = (vertex2.y + vertex3.y) / 2;
      //        planet2_point1x = (temp1x + temp2x) / 2;
      //        planet2_point1y = (temp1y + temp2y) / 2;
      //        temp1x = (vertex1.x + vertex3.x) / 2;
      //        temp1y = (vertex1.y + vertex3.y) / 2;
      //        planet2_point2x = (temp1x + temp2x) / 2;
      //        planet2_point2y = (temp1y + temp2y) / 2;
      temp1x = (vertex1.x + vertex3.x) / 2;
      temp1y = (vertex1.y + vertex3.y) / 2;
      temp1x = (temp1x + vertex1.x) / 2;
      temp1y = (temp1y + vertex1.y) / 2;
      temp2x = (vertex2.x + vertex3.x) / 2;
      temp2y = (vertex2.y + vertex3.y) / 2;
      temp2x = (temp2x + vertex2.x) / 2;
      temp2y = (temp2y + vertex2.y) / 2;
      planet2_point1x = (c_northColx + temp1x) / 2;
      planet2_point1y = (c_northColy + temp1y) / 2;
      planet2_point2x = (c_northColx + temp2x) / 2;
      planet2_point2y = (c_northColy + temp2y) / 2;
      // for 3 planets positioning......
      //        var planet3_point1x, planet3_point1y, planet3_point2x, planet3_point2y, planet3_point3x, planet3_point3y;
      // calculate 3 mids between 3 vertices and with center with normal adjustment mid..mid..towards verticess
      //        temp1x = (vertex1.x + c_northColx) / 2;
      //        temp1y = (vertex1.y + c_northColy) / 2;
      //        temp2x = (temp1x + c_northColx) / 2;
      //        temp2y = (temp1y + c_northColy) / 2;
      //        planet3_point1x = (temp1x + temp2x) / 2;
      //        planet3_point1y = (temp1y + temp2y) / 2;
      //        temp1x = (vertex2.x + c_northColx) / 2;
      //        temp1y = (vertex2.y + c_northColy) / 2;
      //        temp2x = (temp1x + c_northColx) / 2;
      //        temp2y = (temp1y + c_northColy) / 2;
      //        planet3_point2x = (temp1x + temp2x) / 2
      //        planet3_point2y = (temp1y + temp2y) / 2;
      //        temp1x = (vertex3.x + c_northColx) / 2;
      //        temp1y = (vertex3.y + c_northColy) / 2;
      //        temp2x = (temp1x + c_northColx) / 2;
      //        temp2y = (temp1y + c_northColy) / 2;
      //        planet3_point3x = (temp1x + temp2x) / 2;
      //        planet3_point3y = (temp1y + temp2y) / 2;
      //        temp1x = (vertex1.x + vertex2.x) / 2;
      //        temp1y = (vertex1.y + vertex2.y) / 2;
      //        temp2x = (temp1x + vertex1.x) / 2;
      //        temp2y = (temp1y + vertex1.y) / 2;
      //        temp3x = (temp1x + vertex2.x) / 2;
      //        temp3y = (temp1y + vertex2.y) / 2;
      ////        east_center_p3x = p4p1_1x + (((p2p3_2x-p4p1_1x)* 2)/ 3);
      //        planet3_point1x = vertex3.x + (((temp2x-vertex3.x)* 2)/ 3);
      //        planet3_point1y = vertex3.y + (((temp2y-vertex3.y)* 2)/ 3);
      //        planet3_point2x = vertex3.x + (((temp3x-vertex3.x)* 2)/ 3);
      //        planet3_point2y = vertex3.y + (((temp3y-vertex3.y)* 2)/ 3);
      //        planet3_point3x = temp1x + (((vertex3.x-temp1x)* 2)/ 3);
      //        planet3_point3y = temp1y + (((vertex3.y-temp1y)* 2)/ 3);
      planet3_point1x = planet2_point1x;
      planet3_point1y = planet2_point1y;
      planet3_point2x = planet2_point2x;
      planet3_point2y = planet2_point2y;
      planet3_point3x = (c_northColx + vertex3.x) / 2;
      planet3_point3y = (c_northColy + vertex3.y) / 2;
      // for 4 planets positioning......
      // planets4_point1, planets4_point2, planets4_point3, planets4_point4: TPoint;
      //        planet4_point1x = (c_northColx + vertex1.x) / 2;
      //        planet4_point1y = (c_northColy + vertex1.y) / 2;
      //        planet4_point2x = (c_northColx + vertex2.x) / 2;
      //        planet4_point2y = (c_northColy + vertex2.y) / 2;
      //        planet4_point3x = (c_northColx + vertex3.x) / 2;
      //        planet4_point3y = (c_northColy + vertex3.y) / 2;
      //        temp1x = (vertex2.x + vertex3.x) / 2;
      //        temp1y = (vertex2.y + vertex3.y) / 2;
      //        planet4_point4x = (c_northColx + temp1x) / 2;
      //        planet4_point4y = (c_northColy + temp1y) / 2;
      planet4_point1x = planet3_point1x;
      planet4_point1y = planet3_point1y;
      planet4_point2x = planet3_point2x;
      planet4_point2y = planet3_point2y;
      planet4_point3x = planet3_point3x;
      planet4_point3y = planet3_point3y;
      planet4_point4x = c_northColx;
      planet4_point4y = c_northColy;
      // for 5 planets positioning......
      // planets5_point1, planets5_point2, planets5_point3, planets5_point4,planets5_point5: TPoint;
      //        planet5_point1x = (c_northColx + vertex2.x) / 2;
      //        planet5_point1y = (c_northColy + vertex2.y) / 2;
      //        planet5_point2x = (c_northColx + vertex3.x) / 2;
      //        planet5_point2y = (c_northColy + vertex3.y) / 2;
      //        planet5_point3x = (planet5_point1x + planet5_point2x) / 2;
      //        planet5_point3y = (planet5_point1y + planet5_point2y) / 2;
      //        temp1x = (vertex1.x + vertex2.x) / 2;
      //        temp1y = (vertex1.y + vertex2.y) / 2;
      //        temp2x = (vertex1.x + vertex3.x) / 2;
      //        temp2y = (vertex1.y + vertex3.y) / 2;
      //        temp3x = (temp1x + temp2x) / 2;
      //        temp3y = (temp1y + temp2y) / 2;
      //        planet5_point4x = (temp3x + temp1x) / 2;
      //        planet5_point4y = (temp3y + temp1y) / 2;
      //        planet5_point5x = (temp3x + temp2x) / 2;
      //        planet5_point5y = (temp3y + temp2y) / 2;
      temp1x = (vertex1.x + vertex3.x) / 2;
      temp1y = (vertex1.y + vertex3.y) / 2;
      temp1x = (temp1x + vertex1.x) / 2;
      temp1y = (temp1y + vertex1.y) / 2;
      temp2x = (vertex2.x + vertex3.x) / 2;
      temp2y = (vertex2.y + vertex3.y) / 2;
      temp2x = (temp2x + vertex2.x) / 2;
      temp2y = (temp2y + vertex2.y) / 2;
      planet5_point1x = (temp1x + temp2x) / 2;
      planet5_point1y = (temp1y + temp2y) / 2;
      planet5_point2x = (planet5_point1x + temp1x) / 2;
      planet5_point2y = (planet5_point1y + temp1y) / 2;
      planet5_point3x = (planet5_point1x + temp2x) / 2;
      planet5_point3y = (planet5_point1y + temp2y) / 2;
      temp1x = (vertex1.x + vertex3.x) / 2;
      temp1y = (vertex1.y + vertex3.y) / 2;
      temp2x = (temp1x + vertex3.x) / 2;
      temp2y = (temp1y + vertex3.y) / 2;
      temp2x = (temp1x + temp2x) / 2;
      temp2y = (temp1y + temp2y) / 2;
      temp1x = (temp1x + temp2x) / 2;
      temp1y = (temp1y + temp2y) / 2;
      temp2x = (vertex2.x + vertex3.x) / 2;
      temp2y = (vertex2.y + vertex3.y) / 2;
      temp3x = (temp2x + vertex3.x) / 2;
      temp3y = (temp2y + vertex3.y) / 2;
      temp3x = (temp2x + temp3x) / 2;
      temp3y = (temp2y + temp3y) / 2;
      temp2x = (temp2x + temp3x) / 2;
      temp2y = (temp2y + temp3y) / 2;
      planet5_point4x = temp1x + ((temp2x - temp1x) / 3);
      planet5_point4y = temp1x + ((temp2x - temp1x) / 3);
      planet5_point5x = temp1x + (((temp2x - temp1x) * 2) / 3);
      planet5_point5y = temp1y + (((temp2y - temp1y) * 2) / 3);
      // for 6 planets positioning......
      // planets6_point1, planets6_point2, planets6_point3, planets6_point4, planets6_point5, planets6_point6: TPoint;
      // in this temps are mid points of vertices
      temp1x = (vertex1.x + vertex2.x) / 2;
      temp1y = (vertex1.y + vertex2.y) / 2;
      temp2x = (vertex2.x + vertex3.x) / 2;
      temp2y = (vertex2.y + vertex3.y) / 2;
      temp3x = (vertex3.x + vertex1.x) / 2;
      temp3y = (vertex3.y + vertex1.y) / 2;
      planet6_point1x = (c_northColx + vertex1.x) / 2;
      planet6_point1y = (c_northColy + vertex1.y) / 2;
      planet6_point2x = (c_northColx + temp1x) / 2;
      planet6_point2y = (c_northColy + temp1y) / 2;
      planet6_point3x = (c_northColx + vertex2.x) / 2;
      planet6_point3y = (c_northColy + vertex2.y) / 2;
      planet6_point4x = (c_northColx + temp2x) / 2;
      planet6_point4y = (c_northColy + temp2y) / 2;
      planet6_point5x = (c_northColx + vertex3.x) / 2;
      planet6_point5y = (c_northColy + vertex3.y) / 2;
      planet6_point6x = (c_northColx + temp3x) / 2;
      planet6_point6y = (c_northColy + temp3y) / 2;
      // for 7 planets positioning......
      // planets7_point1, planets7_point2, planets7_point3, planets7_point4, planets7_point5, planets7_point6, planets7_point7: TPoint;
      planet7_point1x = (c_northColx + vertex1.x) / 2;
      planet7_point1y = (c_northColy + vertex1.y) / 2;
      planet7_point2x = (c_northColx + vertex2.x) / 2;
      planet7_point2y = (c_northColy + vertex2.y) / 2;
      planet7_point3x = (c_northColx + vertex3.x) / 2;
      planet7_point3y = (c_northColy + vertex3.y) / 2;
      temp1x = (vertex2.x + vertex3.x) / 2;
      temp1y = (vertex2.y + vertex3.y) / 2;
      temp2x = (vertex2.x + temp1x) / 2;
      temp2y = (vertex2.y + temp1y) / 2;
      temp3x = (temp1x + vertex3.x) / 2;
      temp3y = (temp1y + vertex3.y) / 2;
      temp2x = (temp2x + temp1x) / 2;
      temp2y = (temp2y + temp1y) / 2;
      temp3x = (temp1x + temp3x) / 2;
      temp3y = (temp1y + temp3y) / 2;
      planet7_point4x = (c_northColx + temp2x) / 2;
      planet7_point4y = (c_northColy + temp2y) / 2;
      planet7_point5x = (c_northColx + temp3x) / 2;
      planet7_point5y = (c_northColy + temp3y) / 2;
      planet7_point6x = (planet7_point1x + planet7_point2x) / 2;
      planet7_point6y = (planet7_point1y + planet7_point2y) / 2;
      planet7_point7x = (planet7_point1x + planet7_point3x) / 2;
      planet7_point7y = (planet7_point1y + planet7_point3y) / 2;
      // for 8 planets positioning......
      planet8_point1x = c_northColx;
      planet8_point1y = c_northColy;
      temp1x = (vertex1.x + vertex2.x) / 2;
      temp1y = (vertex1.y + vertex2.y) / 2;
      temp2x = (vertex2.x + vertex3.x) / 2;
      temp2y = (vertex2.y + vertex3.y) / 2;
      temp3x = (vertex3.x + vertex1.x) / 2;
      temp3y = (vertex3.y + vertex1.y) / 2;
      temp4x = (temp3x + temp2x) / 2;
      temp4y = (temp3y + temp2y) / 2;
      planet8_point2x = (temp4x + temp3x) / 2;
      planet8_point2y = (temp4y + temp3y) / 2;
      planet8_point3x = (temp4x + temp2x) / 2;
      planet8_point3y = (temp4y + temp2y) / 2;
      planet8_point4x = (temp4x + vertex3.x) / 2;
      planet8_point4y = (temp4y + vertex3.y) / 2;
      planet8_point5x = (temp3x + temp1x) / 2;
      planet8_point5y = (temp3y + temp1y) / 2;
      planet8_point6x = (temp2x + temp1x) / 2;
      planet8_point6y = (temp2y + temp1y) / 2;
      temp5x = (temp3x + vertex1.x) / 2;
      temp5y = (temp3y + vertex1.y) / 2;
      temp6x = (temp2x + vertex2.x) / 2;
      temp6y = (temp2y + vertex2.y) / 2;
      planet8_point7x = (planet8_point5x + temp5x) / 2;
      planet8_point7y = (planet8_point5y + temp5y) / 2;
      planet8_point8x = (planet8_point6x + temp6x) / 2;
      planet8_point8y = (planet8_point6y + temp6y) / 2;
      // for 9 planets positioning......
      temp1x = (vertex1.x + vertex2.x) / 2;
      temp1y = (vertex1.y + vertex2.y) / 2;
      temp2x = (vertex2.x + vertex3.x) / 2;
      temp2y = (vertex2.y + vertex3.y) / 2;
      temp3x = (vertex3.x + vertex1.x) / 2;
      temp3y = (vertex3.y + vertex1.y) / 2;
      temp4x = (temp3x + temp2x) / 2;
      temp4y = (temp3y + temp2y) / 2;
      planet9_point1x = (temp4x + temp3x) / 2;
      planet9_point1y = (temp4y + temp3y) / 2;
      planet9_point2x = (temp4x + temp2x) / 2;
      planet9_point2y = (temp4y + temp2y) / 2;
      planet9_point3x = (temp4x + vertex3.x) / 2;
      planet9_point3y = (temp4y + vertex3.y) / 2;
      planet9_point4x = (temp3x + temp1x) / 2;
      planet9_point4y = (temp3y + temp1y) / 2;
      planet9_point5x = (temp2x + temp1x) / 2;
      planet9_point5y = (temp2y + temp1y) / 2;
      temp5x = (temp3x + vertex1.x) / 2;
      temp5y = (temp3y + vertex1.y) / 2;
      temp6x = (temp2x + vertex2.x) / 2;
      temp6y = (temp2y + vertex2.y) / 2;
      planet9_point6x = (planet9_point4x + temp5x) / 2;
      planet9_point6y = (planet9_point4y + temp5y) / 2;
      planet9_point7x = (planet9_point5x + temp6x) / 2;
      planet9_point7y = (planet9_point5y + temp6y) / 2;
      planet9_point8x = (planet9_point4x + planet9_point5x) / 2;
      planet9_point8y = (planet9_point4y + planet9_point5y) / 2;
      planet9_point9x = (planet9_point1x + planet9_point2x) / 2;
      planet9_point9y = (planet9_point1y + planet9_point2y) / 2;
  }
  else if (Object.keys(cell_coordinates).length == 4) {
      vertex1 = cell_coordinates.c_p1;
      vertex2 = cell_coordinates.c_p2;
      vertex3 = cell_coordinates.c_p3;
      vertex4 = cell_coordinates.c_p4;
      //        calculate  planet1_point1x, planet1_point1y;
      planet1_point1x = c_northColx = (vertex1.x + vertex3.x) / 2;
      planet1_point1y = c_northColy = (vertex1.y + vertex3.y) / 2;
      // for 2 planets positioning......
      // planets2_point1, planets2_point2: TPoint;
      //get 3 mid points of all vertices and 1st planest position is the mid of vertex 1 and 2 mid point and vertex 2 and 3,likewise calculate another mid point of mids
      planet2_point1x = (vertex2.x + c_northColx) / 2;
      planet2_point1y = (vertex2.y + c_northColy) / 2;
      planet2_point2x = (vertex4.x + c_northColx) / 2;
      planet2_point2y = (vertex4.y + c_northColy) / 2;
      // for 3 planets positioning......
      // planets3_point1, planets3_point2, planets3_point3: TPoint;
      // calculate 3 mids between 3 vertices and with center with normal adjustment mid..mid..towards verticess
      planet3_point1x = (c_northColx + vertex2.x) / 2;
      planet3_point1y = (c_northColy + vertex2.y) / 2;
      planet3_point2x = (c_northColx + vertex3.x) / 2;
      planet3_point2y = (c_northColy + vertex3.y) / 2;
      planet3_point3x = (c_northColx + vertex4.x) / 2;
      planet3_point3y = (c_northColy + vertex4.y) / 2;
      // for 4 planets positioning......
      // planets4_point1, planets4_point2, planets4_point3, planets4_point4: TPoint;
      planet4_point1x = (c_northColx + vertex1.x) / 2;
      planet4_point1y = (c_northColy + vertex1.y) / 2;
      planet4_point2x = (c_northColx + vertex2.x) / 2;
      planet4_point2y = (c_northColy + vertex2.y) / 2;
      planet4_point3x = (c_northColx + vertex3.x) / 2;
      planet4_point3y = (c_northColy + vertex3.y) / 2;
      planet4_point4x = (c_northColx + vertex4.x) / 2;
      planet4_point4y = (c_northColy + vertex4.y) / 2;
      // for 5 planets positioning......
      // planets5_point1, planets5_point2, planets5_point3, planets5_point4,planets5_point5: TPoint;
      planet5_point1x = c_northColx;
      planet5_point1y = c_northColy;
      planet5_point2x = (c_northColx + vertex1.x) / 2;
      planet5_point2y = (c_northColy + vertex1.y) / 2;
      planet5_point3x = (c_northColx + vertex2.x) / 2;
      planet5_point3y = (c_northColy + vertex2.y) / 2;
      planet5_point4x = (c_northColx + vertex3.x) / 2;
      planet5_point4y = (c_northColy + vertex3.y) / 2;
      planet5_point5x = (c_northColx + vertex4.x) / 2;
      planet5_point5y = (c_northColy + vertex4.y) / 2;
      // for 6 planets positioning......
      planet6_point1x = (c_northColx + vertex1.x) / 2;
      planet6_point1y = (c_northColy + vertex1.y) / 2;
      planet6_point2x = (c_northColx + vertex2.x) / 2;
      planet6_point2y = (c_northColy + vertex2.y) / 2;
      planet6_point3x = (c_northColx + vertex3.x) / 2;
      planet6_point3y = (c_northColy + vertex3.y) / 2;
      planet6_point4x = (c_northColx + vertex4.x) / 2;
      planet6_point4y = (c_northColy + vertex4.y) / 2;
      planet6_point5x = (planet6_point1x + planet6_point4x) / 2;
      planet6_point5y = (planet6_point1y + planet6_point4y) / 2;
      planet6_point6x = (planet6_point2x + planet6_point3x) / 2;
      planet6_point6y = (planet6_point2y + planet6_point3y) / 2;
      // for  7 planets positioning......
      temp1x = (c_northColx + vertex1.x) / 2;
      temp1y = (c_northColy + vertex1.y) / 2;
      temp2x = (c_northColx + vertex2.x) / 2;
      temp2y = (c_northColy + vertex2.y) / 2;
      planet7_point1x = (temp1x + temp2x) / 2;
      planet7_point1y = (temp1y + temp2y) / 2;
      planet7_point2x = (c_northColx + vertex3.x) / 2;
      planet7_point2y = (c_northColy + vertex3.y) / 2;
      planet7_point3x = (c_northColx + vertex4.x) / 2;
      planet7_point3y = (c_northColy + vertex4.y) / 2;
      planet7_point4x = c_northColx;
      planet7_point4y = c_northColy;
      planet7_point5x = (planet7_point3x + temp1x) / 2;
      planet7_point5y = (planet7_point3y + temp1y) / 2;
      planet7_point6x = (planet7_point2x + temp2x) / 2;
      planet7_point6y = (planet7_point2y + temp2y) / 2;
      planet7_point7x = (planet7_point2x + planet7_point3x) / 2;
      planet7_point7y = (planet7_point2y + planet7_point3y) / 2;
      // for 8 planets positioning......
      planet8_point1x = (c_northColx + vertex1.x) / 2;
      planet8_point1y = (c_northColy + vertex1.y) / 2;
      planet8_point2x = (c_northColx + vertex2.x) / 2;
      planet8_point2y = (c_northColy + vertex2.y) / 2;
      planet8_point3x = (c_northColx + vertex3.x) / 2;
      planet8_point3y = (c_northColy + vertex3.y) / 2;
      planet8_point4x = (c_northColx + vertex4.x) / 2;
      planet8_point4y = (c_northColy + vertex4.y) / 2;
      planet8_point5x = (planet8_point1x + planet8_point2x) / 2;
      planet8_point5y = (planet8_point1y + planet8_point2y) / 2;
      planet8_point6x = (planet8_point4x + planet8_point3x) / 2;
      planet8_point6y = (planet8_point4y + planet8_point3y) / 2;
      temp1x = (planet8_point1x + planet8_point4x) / 2;
      temp1y = (planet8_point1y + planet8_point4y) / 2;
      temp2x = (planet8_point2x + planet8_point3x) / 2;
      temp2y = (planet8_point2y + planet8_point3y) / 2;
      planet8_point7x = (temp1x + c_northColx) / 2;
      planet8_point7y = (temp1y + c_northColy) / 2;
      planet8_point8x = (temp2x + c_northColx) / 2;
      planet8_point8y = (temp2y + c_northColy) / 2;
      // for 9 planets positioning......
      planet9_point1x = (c_northColx + vertex1.x) / 2;
      planet9_point1y = (c_northColy + vertex1.y) / 2;
      planet9_point2x = (c_northColx + vertex2.x) / 2;
      planet9_point2y = (c_northColy + vertex2.y) / 2;
      planet9_point3x = (c_northColx + vertex3.x) / 2;
      planet9_point3y = (c_northColy + vertex3.y) / 2;
      planet9_point4x = (c_northColx + vertex4.x) / 2;
      planet9_point4y = (c_northColy + vertex4.y) / 2;
      planet9_point5x = c_northColx;
      planet9_point5y = c_northColy;
      planet9_point6x = (planet9_point1x + planet9_point2x) / 2;
      planet9_point6y = (planet9_point1y + planet9_point2y) / 2;
      planet9_point7x = (planet9_point2x + planet9_point3x) / 2;
      planet9_point7y = (planet9_point2y + planet9_point3y) / 2;
      planet9_point8x = (planet9_point3x + planet9_point4x) / 2;
      planet9_point8y = (planet9_point3y + planet9_point4y) / 2;
      planet9_point9x = (planet9_point4x + planet9_point1x) / 2;
      planet9_point9y = (planet9_point4y + planet9_point1y) / 2;
  }
  if (planet_count == 1) {
      var temp = {};
      temp["x"] = planet1_point1x;
      temp["y"] = planet1_point1y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 2) {
      var temp = {};
      temp["x"] = planet2_point1x;
      temp["y"] = planet2_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet2_point2x;
      temp["y"] = planet2_point2y;
      planet_positions.push(temp)
      return planet_positions;
  }
  else if (planet_count == 3) {
      var temp = {};
      temp["x"] = planet3_point1x;
      temp["y"] = planet3_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet3_point2x;
      temp["y"] = planet3_point2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet3_point3x;
      temp["y"] = planet3_point3y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 4) {
      var temp = {};
      temp["x"] = planet4_point1x;
      temp["y"] = planet4_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet4_point2x;
      temp["y"] = planet4_point2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet4_point3x;
      temp["y"] = planet4_point3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet4_point4x;
      temp["y"] = planet4_point4y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 5) {
      var temp = {};
      temp["x"] = planet5_point1x;
      temp["y"] = planet5_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_point2x;
      temp["y"] = planet5_point2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_point3x;
      temp["y"] = planet5_point3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_point4x;
      temp["y"] = planet5_point4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_point5x;
      temp["y"] = planet5_point5y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 6) {
      var temp = {};
      temp["x"] = planet6_point1x;
      temp["y"] = planet6_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_point2x;
      temp["y"] = planet6_point2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_point3x;
      temp["y"] = planet6_point3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_point4x;
      temp["y"] = planet6_point4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_point5x;
      temp["y"] = planet6_point5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_point6x;
      temp["y"] = planet6_point6y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 7) {
      var temp = {};
      temp["x"] = planet7_point1x;
      temp["y"] = planet7_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_point2x;
      temp["y"] = planet7_point2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_point3x;
      temp["y"] = planet7_point3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_point4x;
      temp["y"] = planet7_point4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_point5x;
      temp["y"] = planet7_point5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_point6x;
      temp["y"] = planet7_point6y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_point7x;
      temp["y"] = planet7_point7y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 8) {
      var temp = {};
      temp["x"] = planet8_point1x;
      temp["y"] = planet8_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_point2x;
      temp["y"] = planet8_point2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_point3x;
      temp["y"] = planet8_point3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_point4x;
      temp["y"] = planet8_point4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_point5x;
      temp["y"] = planet8_point5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_point6x;
      temp["y"] = planet8_point6y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_point7x;
      temp["y"] = planet8_point7y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_point8x;
      temp["y"] = planet8_point8y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 9) {
      var temp = {};
      temp["x"] = planet9_point1x;
      temp["y"] = planet9_point1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point2x;
      temp["y"] = planet9_point2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point3x;
      temp["y"] = planet9_point3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point4x;
      temp["y"] = planet9_point4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point5x;
      temp["y"] = planet9_point5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point6x;
      temp["y"] = planet9_point6y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point7x;
      temp["y"] = planet9_point7y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point8x;
      temp["y"] = planet9_point8y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_point9x;
      temp["y"] = planet9_point9y;
      planet_positions.push(temp);
      return planet_positions;
  }
}
function single_house_coordinates_Moo_squ(house_no, JN_chart) {
  var totaljson = {};
  var rasi_no_coordinates = {};
  var column_coordinates = {};
  var c_p1, c_p2, c_p3;
  var c_p4 = null;
  //            if (house_no == 1) {
  //                c_p1 = JN_chart.p1_p1p2;
  //                c_p2 = JN_chart.p1p2;
  //                c_p3 = JN_chart.mid_top;
  //                c_p4 = JN_chart.center_p1;
  //            }
  //            else if (house_no == 2) {
  //                c_p1 = JN_chart.p1_p1p2;
  //                c_p2 = JN_chart.p1p2;
  //                c_p3 = JN_chart.mid_top;
  //                c_p4 = JN_chart.center_p1;
  //            }
  switch (house_no) {
      case 1:
          c_p1 = JN_chart.inner_top2;
          c_p2 = JN_chart.inner_top1;
          c_p3 = JN_chart.middle_top1;
          c_p4 = JN_chart.middle_top2;
          break;
      case 2:
          c_p1 = JN_chart.middle_p2;
          c_p2 = JN_chart.inner_p2;
          c_p3 = JN_chart.inner_top2;
          c_p4 = JN_chart.middle_top2;
          break;
      case 3:
          c_p1 = JN_chart.middle_p2;
          c_p2 = JN_chart.inner_p2;
          c_p3 = JN_chart.inner_right1;
          c_p4 = JN_chart.middle_right1;
          break;
      case 4:
          c_p1 = JN_chart.inner_right2;
          c_p2 = JN_chart.inner_right1;
          c_p3 = JN_chart.middle_right1;
          c_p4 = JN_chart.middle_right2;
          break;
      case 5:
          c_p1 = JN_chart.middle_p3;
          c_p2 = JN_chart.inner_p3;
          c_p3 = JN_chart.inner_right2;
          c_p4 = JN_chart.middle_right2;
          break;
      case 6:
          c_p1 = JN_chart.middle_p3;
          c_p2 = JN_chart.inner_p3;
          c_p3 = JN_chart.inner_bottom1;
          c_p4 = JN_chart.middle_bottom1;
          break;
      case 7:
          c_p1 = JN_chart.inner_bottom2;
          c_p2 = JN_chart.inner_bottom1;
          c_p3 = JN_chart.middle_bottom1;
          c_p4 = JN_chart.middle_bottom2;
          break;
      case 8:
          c_p1 = JN_chart.middle_p4;
          c_p2 = JN_chart.inner_p4;
          c_p3 = JN_chart.inner_bottom2;
          c_p4 = JN_chart.middle_bottom2;
          break;
      case 9:
          c_p1 = JN_chart.middle_p4;
          c_p2 = JN_chart.inner_p4;
          c_p3 = JN_chart.inner_left1;
          c_p4 = JN_chart.middle_left1;
          break;
      case 10:
          c_p1 = JN_chart.inner_left2;
          c_p2 = JN_chart.inner_left1;
          c_p3 = JN_chart.middle_left1;
          c_p4 = JN_chart.middle_left2;
          break;
      case 11:
          c_p1 = JN_chart.middle_p1;
          c_p2 = JN_chart.inner_p1;
          c_p3 = JN_chart.inner_left2;
          c_p4 = JN_chart.middle_left2;
          break;
      case 12:
          c_p1 = JN_chart.middle_p1;
          c_p2 = JN_chart.inner_p1;
          c_p3 = JN_chart.inner_top1;
          c_p4 = JN_chart.middle_top1;
  }
  if (c_p1 !== null) {
      column_coordinates.c_p1 = c_p1;
      column_coordinates.c_p2 = c_p2;
      column_coordinates.c_p3 = c_p3;
      column_coordinates.c_p4 = c_p4;
      if ((house_no % 3) == 1) {
          tempx = (c_p3.x + c_p4.x) / 2;
          tempy = (c_p3.y + c_p4.y) / 2;
      }
      else {
          tempx = (c_p2.x + c_p3.x) / 2;
          tempy = (c_p2.y + c_p3.y) / 2;
      }
      tempx = (c_p1.x + tempx) / 2;
      tempy = (c_p1.y + tempy) / 2;
      tempx = (c_p1.x + tempx) / 2;
      tempy = (c_p1.y + tempy) / 2;
      temp1x = (c_p1.x + tempx) / 2;
      temp1y = (c_p1.y + tempy) / 2;
      rasi_no_coordinates["x"] = (tempx + temp1x) / 2;
      rasi_no_coordinates["y"] = (tempy + temp1y) / 2;
      totaljson.rasi_no_coordinates = rasi_no_coordinates;
      totaljson.column_coordinates = column_coordinates;
      return totaljson;
  }
}
function single_house_coordinates_Lag_squ(house_no, JN_chart) {
  var totaljson = {};
  var rasi_no_coordinates = {};
  var column_coordinates = {};
  var c_p1, c_p2, c_p3;
  var c_p4 = null;
  //            if (house_no == 1) {
  //                c_p1 = JN_chart.p1_p1p2;
  //                c_p2 = JN_chart.p1p2;
  //                c_p3 = JN_chart.mid_top;
  //                c_p4 = JN_chart.center_p1;
  //            }
  //            else if (house_no == 2) {
  //                c_p1 = JN_chart.p1_p1p2;
  //                c_p2 = JN_chart.p1p2;
  //                c_p3 = JN_chart.mid_top;
  //                c_p4 = JN_chart.center_p1;
  //            }
  switch (house_no) {
      case 1:
          c_p1 = JN_chart.middle_top2;
          c_p2 = JN_chart.middle_top1;
          c_p3 = JN_chart.outer_top1;
          c_p4 = JN_chart.outer_top2;
          break;
      case 2:
          c_p1 = JN_chart.outer_p2;
          c_p2 = JN_chart.middle_p2;
          c_p3 = JN_chart.middle_top2;
          c_p4 = JN_chart.outer_top2;
          break;
      case 3:
          c_p1 = JN_chart.outer_p2;
          c_p2 = JN_chart.middle_p2;
          c_p3 = JN_chart.middle_right1;
          c_p4 = JN_chart.outer_right1;
          break;
      case 4:
          c_p1 = JN_chart.middle_right2;
          c_p2 = JN_chart.middle_right1;
          c_p3 = JN_chart.outer_right1;
          c_p4 = JN_chart.outer_right2;
          break;
      case 5:
          c_p1 = JN_chart.outer_p3;
          c_p2 = JN_chart.middle_p3;
          c_p3 = JN_chart.middle_right2;
          c_p4 = JN_chart.outer_right2;
          break;
      case 6:
          c_p1 = JN_chart.outer_p3;
          c_p2 = JN_chart.middle_p3;
          c_p3 = JN_chart.middle_bottom1;
          c_p4 = JN_chart.outer_bottom1;
          break;
      case 7:
          c_p1 = JN_chart.middle_bottom2;
          c_p2 = JN_chart.middle_bottom1;
          c_p3 = JN_chart.outer_bottom1;
          c_p4 = JN_chart.outer_bottom2;
          break;
      case 8:
          c_p1 = JN_chart.outer_p4;
          c_p2 = JN_chart.middle_p4;
          c_p3 = JN_chart.middle_bottom2;
          c_p4 = JN_chart.outer_bottom2;
          break;
      case 9:
          c_p1 = JN_chart.outer_p4;
          c_p2 = JN_chart.middle_p4;
          c_p3 = JN_chart.middle_left1;
          c_p4 = JN_chart.outer_left1;
          break;
      case 10:
          c_p1 = JN_chart.middle_left2;
          c_p2 = JN_chart.middle_left1;
          c_p3 = JN_chart.outer_left1;
          c_p4 = JN_chart.outer_left2;
          break;
      case 11:
          c_p1 = JN_chart.outer_p1;
          c_p2 = JN_chart.middle_p1;
          c_p3 = JN_chart.middle_left2;
          c_p4 = JN_chart.outer_left2;
          break;
      case 12:
          c_p1 = JN_chart.outer_p1;
          c_p2 = JN_chart.middle_p1;
          c_p3 = JN_chart.middle_top1;
          c_p4 = JN_chart.outer_top1;
  }
  if (c_p1 !== null) {
      column_coordinates.c_p1 = c_p1;
      column_coordinates.c_p2 = c_p2;
      column_coordinates.c_p3 = c_p3;
      column_coordinates.c_p4 = c_p4;
      if ((house_no % 3) == 1) {
          tempx = (c_p3.x + c_p4.x) / 2;
          tempy = (c_p3.y + c_p4.y) / 2;
      }
      else {
          tempx = (c_p2.x + c_p3.x) / 2;
          tempy = (c_p2.y + c_p3.y) / 2;
      }
      tempx = (c_p1.x + tempx) / 2;
      tempy = (c_p1.y + tempy) / 2;
      tempx = (c_p1.x + tempx) / 2;
      tempy = (c_p1.y + tempy) / 2;
      temp1x = (c_p1.x + tempx) / 2;
      temp1y = (c_p1.y + tempy) / 2;
      rasi_no_coordinates["x"] = (tempx + temp1x) / 2;
      rasi_no_coordinates["y"] = (tempy + temp1y) / 2;
      totaljson.rasi_no_coordinates = rasi_no_coordinates;
      totaljson.column_coordinates = column_coordinates;
      return totaljson;
  }
}
function getPlanetPosition_Sudharshana_wrt_Moo_Lag_squ(planet_count, cell_coordinates, house_no) {
  var planet_positions = [];
  var centrx, centry;
  var planet2_1x, planet2_1y, planet2_2x, planet2_2y;
  var planet3_1x, planet3_1y, planet3_2x, planet3_2y, planet3_3x, planet3_3y;
  var planet4_1x, planet4_1y, planet4_2x, planet4_2y, planet4_3x, planet4_3y, planet4_4x, planet4_4y;
  var planet5_1x, planet5_1y, planet5_2x, planet5_2y, planet5_3x, planet5_3y, planet5_4x, planet5_4y, planet5_5x, planet5_5y;
  var planet6_1x, planet6_1y, planet6_2x, planet6_2y, planet6_3x, planet6_3y, planet6_4x, planet6_4y, planet6_5x, planet6_5y, planet6_6x, planet6_6y;
  var planet7_1x, planet7_1y, planet7_2x, planet7_2y, planet7_3x, planet7_3y, planet7_4x, planet7_4y, planet7_5x, planet7_5y, planet7_6x, planet7_6y, planet7_7x, planet7_7y;
  var planet8_1x, planet8_1y, planet8_2x, planet8_2y, planet8_3x, planet8_3y, planet8_4x, planet8_4y, planet8_5x, planet8_5y, planet8_6x, planet8_6y, planet8_7x, planet8_7y, planet8_8x, planet8_8y;
  var planet9_1x, planet9_1y, planet9_2x, planet9_2y, planet9_3x, planet9_3y, planet9_4x, planet9_4y, planet9_5x, planet9_5y, planet9_6x, planet9_6y, planet9_7x, planet9_7y, planet9_8x, planet9_8y, planet9_9x, planet9_9y;
  if ((house_no % 3) == 1) {
      tempx = (cell_coordinates.c_p1.x + cell_coordinates.c_p3.x) / 2;
      tempy = (cell_coordinates.c_p1.y + cell_coordinates.c_p3.y) / 2;
      centrx = tempx;
      centry = tempy;
      planet2_1x = (centrx + cell_coordinates.c_p1.x) / 2;
      planet2_1y = (centry + cell_coordinates.c_p1.y) / 2;
      planet2_2x = (centrx + cell_coordinates.c_p3.x) / 2;
      planet2_2y = (centry + cell_coordinates.c_p3.y) / 2;
      tempx = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      tempy = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      planet3_1x = (tempx + centrx) / 2;
      planet3_1y = (tempy + centry) / 2;
      planet3_2x = (cell_coordinates.c_p3.x + centrx) / 2;
      planet3_2y = (cell_coordinates.c_p3.y + centry) / 2;
      planet3_3x = (cell_coordinates.c_p4.x + centrx) / 2;
      planet3_3y = (cell_coordinates.c_p4.y + centry) / 2;
      tempx = (centrx + cell_coordinates.c_p1.x) / 2;
      tempy = (centry + cell_coordinates.c_p1.y) / 2;
      planet4_1x = (tempx + cell_coordinates.c_p1.x) / 2;
      planet4_1y = (tempy + cell_coordinates.c_p1.y) / 2;
      tempx = (centrx + cell_coordinates.c_p2.x) / 2;
      tempy = (centry + cell_coordinates.c_p2.y) / 2;
      planet4_2x = (tempx + cell_coordinates.c_p2.x) / 2;
      planet4_2y = (tempy + cell_coordinates.c_p2.y) / 2;
      tempx = (centrx + cell_coordinates.c_p3.x) / 2;
      tempy = (centry + cell_coordinates.c_p3.y) / 2;
      planet4_3x = (tempx + cell_coordinates.c_p3.x) / 2;
      planet4_3y = (tempy + cell_coordinates.c_p3.y) / 2;
      tempx = (centrx + cell_coordinates.c_p4.x) / 2;
      tempy = (centry + cell_coordinates.c_p4.y) / 2;
      planet4_4x = (tempx + cell_coordinates.c_p4.x) / 2;
      planet4_4y = (tempy + cell_coordinates.c_p4.y) / 2;
      tempx = (centrx + cell_coordinates.c_p1.x) / 2;
      tempy = (centry + cell_coordinates.c_p1.y) / 2;
      planet5_1x = (tempx + cell_coordinates.c_p1.x) / 2;
      planet5_1y = (tempy + cell_coordinates.c_p1.y) / 2;
      tempx = (centrx + cell_coordinates.c_p2.x) / 2;
      tempy = (centry + cell_coordinates.c_p2.y) / 2;
      planet5_2x = (tempx + cell_coordinates.c_p2.x) / 2;
      planet5_2y = (tempy + cell_coordinates.c_p2.y) / 2;
      tempx = (centrx + cell_coordinates.c_p3.x) / 2;
      tempy = (centry + cell_coordinates.c_p3.y) / 2;
      planet5_3x = (tempx + cell_coordinates.c_p3.x) / 2;
      planet5_3y = (tempy + cell_coordinates.c_p3.y) / 2;
      tempx = (centrx + cell_coordinates.c_p4.x) / 2;
      tempy = (centry + cell_coordinates.c_p4.y) / 2;
      planet5_4x = (tempx + cell_coordinates.c_p4.x) / 2;
      planet5_4y = (tempy + cell_coordinates.c_p4.y) / 2;
      planet5_5x = centrx;
      planet5_5y = centry;
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p3.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p3.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p1.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p1.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p2.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p3.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p3.y + temp2y) / 2;
      planet6_1x = (templ1x + tempr1x) / 2;
      planet6_1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + planet6_1x) / 2;
      tempy = (templ1y + planet6_1y) / 2;
      planet6_2x = (templ1x + tempx) / 2;
      planet6_2y = (templ1y + tempy) / 2;
      tempx = (tempr1x + planet6_1x) / 2;
      tempy = (tempr1y + planet6_1y) / 2;
      planet6_3x = (tempr1x + tempx) / 2;
      planet6_3y = (tempr1y + tempy) / 2;
      planet6_4x = (templ2x + tempr2x) / 2;
      planet6_4y = (templ2y + tempr2y) / 2;
      tempx = (templ2x + planet6_4x) / 2;
      tempy = (templ2y + planet6_4y) / 2;
      planet6_5x = (templ2x + tempx) / 2;
      planet6_5y = (templ2y + tempy) / 2;
      tempx = (tempr2x + planet6_4x) / 2;
      tempy = (tempr2y + planet6_4y) / 2;
      planet6_6x = (tempr2x + tempx) / 2;
      planet6_6y = (tempr2y + tempy) / 2;
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p3.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p3.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p1.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p1.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p2.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p3.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p3.y + temp2y) / 2;
      planet7_1x = (templ1x + tempr1x) / 2;
      planet7_1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + planet7_1x) / 2;
      tempy = (templ1y + planet7_1y) / 2;
      planet7_2x = (templ1x + tempx) / 2;
      planet7_2y = (templ1y + tempy) / 2;
      tempx = (tempr1x + planet7_1x) / 2;
      tempy = (tempr1y + planet7_1y) / 2;
      planet7_3x = (tempr1x + tempx) / 2;
      planet7_3y = (tempr1y + tempy) / 2;
      planet7_4x = (templ2x + tempr2x) / 2;
      planet7_4y = (templ2y + tempr2y) / 2;
      tempx = (templ2x + planet7_4x) / 2;
      tempy = (templ2y + planet7_4y) / 2;
      planet7_5x = (templ2x + tempx) / 2;
      planet7_5y = (templ2y + tempy) / 2;
      tempx = (tempr2x + planet7_4x) / 2;
      tempy = (tempr2y + planet7_4y) / 2;
      planet7_6x = (tempr2x + tempx) / 2;
      planet7_6y = (tempr2y + tempy) / 2;
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p3.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p3.y + cell_coordinates.c_p2.y) / 2;
      temp3x = (temp1x + temp2x) / 2;
      temp3y = (temp1y + temp2y) / 2;
      temp4x = (temp1x + temp3x) / 2;
      temp4y = (temp1y + temp3y) / 2;
      temp5x = (temp4x + temp3x) / 2;
      temp5y = (temp4y + temp3y) / 2;
      planet7_7x = (temp4x + temp5x) / 2;
      planet7_7y = (temp4y + temp5y) / 2;
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p3.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p3.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p1.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p1.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p2.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p3.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p3.y + temp2y) / 2;
      planet8_1x = (templ1x + tempr1x) / 2;
      planet8_1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + planet8_1x) / 2;
      tempy = (templ1y + planet8_1y) / 2;
      planet8_2x = (templ1x + tempx) / 2;
      planet8_2y = (templ1y + tempy) / 2;
      tempx = (tempr1x + planet8_1x) / 2;
      tempy = (tempr1y + planet8_1y) / 2;
      planet8_3x = (tempr1x + tempx) / 2;
      planet8_3y = (tempr1y + tempy) / 2;
      planet8_4x = (templ2x + tempr2x) / 2;
      planet8_4y = (templ2y + tempr2y) / 2;
      tempx = (templ2x + planet8_4x) / 2;
      tempy = (templ2y + planet8_4y) / 2;
      planet8_5x = (templ2x + tempx) / 2;
      planet8_5y = (templ2y + tempy) / 2;
      tempx = (tempr2x + planet8_4x) / 2;
      tempy = (tempr2y + planet8_4y) / 2;
      planet8_6x = (tempr2x + tempx) / 2;
      planet8_6y = (tempr2y + tempy) / 2;
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p3.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p3.y + cell_coordinates.c_p2.y) / 2;
      planet8_7x = temp1x + ((temp2x - temp1x) / 3);
      planet8_7y = temp1y + ((temp2y - temp1y) / 3);
      planet8_8x = temp1x + (((temp2x - temp1x) * 2) / 3);
      planet8_8y = temp1y + (((temp2y - temp1y) * 2) / 3);
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p3.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p3.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p1.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p1.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p2.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p3.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p3.y + temp2y) / 2;
      planet9_1x = (templ1x + tempr1x) / 2;
      planet9_1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + planet9_1x) / 2;
      tempy = (templ1y + planet9_1y) / 2;
      planet9_2x = (templ1x + tempx) / 2;
      planet9_2y = (templ1y + tempy) / 2;
      tempx = (tempr1x + planet9_1x) / 2;
      tempy = (tempr1y + planet9_1y) / 2;
      planet9_3x = (tempr1x + tempx) / 2;
      planet9_3y = (tempr1y + tempy) / 2;
      planet9_4x = (templ2x + tempr2x) / 2;
      planet9_4y = (templ2y + tempr2y) / 2;
      tempx = (templ2x + planet9_4x) / 2;
      tempy = (templ2y + planet9_4y) / 2;
      planet9_5x = (templ2x + tempx) / 2;
      planet9_5y = (templ2y + tempy) / 2;
      tempx = (tempr2x + planet9_4x) / 2;
      tempy = (tempr2y + planet9_4y) / 2;
      planet9_6x = (tempr2x + tempx) / 2;
      planet9_6y = (tempr2y + tempy) / 2;
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p3.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p3.y + cell_coordinates.c_p2.y) / 2;
      planet9_7x = (temp1x + temp2x) / 2;
      planet9_7y = (temp1y + temp2y) / 2;
      tempx = (temp1x + planet9_7x) / 2;
      tempy = (temp1y + planet9_7y) / 2;
      planet9_8x = (temp1x + tempx) / 2;
      planet9_8y = (temp1y + tempy) / 2;
      tempx = (temp2x + planet9_7x) / 2;
      tempy = (temp2y + planet9_7y) / 2;
      planet9_9x = (temp2x + tempx) / 2;
      planet9_9y = (temp2y + tempy) / 2;
  }
  else {
      temp1x = (cell_coordinates.c_p1.x + cell_coordinates.c_p3.x) / 2;
      temp1y = (cell_coordinates.c_p1.y + cell_coordinates.c_p3.y) / 2;
      temp2x = (cell_coordinates.c_p2.x + cell_coordinates.c_p4.x) / 2;
      temp2y = (cell_coordinates.c_p2.y + cell_coordinates.c_p4.y) / 2;
      centrx = (temp1x + temp2x) / 2;
      centry = (temp1y + temp2y) / 2;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      planet2_1x = (centrx + temp1x) / 2;
      planet2_1y = (centry + temp1y) / 2;
      planet2_2x = (centrx + temp2x) / 2;
      planet2_2y = (centry + temp2y) / 2;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      tempmidx = (temp1x + temp2x) / 2;
      tempmidy = (temp1y + temp2y) / 2;
      planet3_1x = tempmidx;
      planet3_1y = tempmidy;
      tempx = (temp1x + tempmidx) / 2;
      tempy = (temp1y + tempmidy) / 2;
      planet3_2x = (temp1x + tempx) / 2;
      planet3_2y = (temp1y + tempy) / 2;
      tempx = (temp2x + tempmidx) / 2;
      tempy = (temp2y + tempmidy) / 2;
      planet3_3x = (temp2x + tempx) / 2;
      planet3_3y = (temp2y + tempy) / 2;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p3.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p3.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p1.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p1.y + temp2y) / 2;
      planet4_1x = (templ1x + tempr1x) / 2;
      planet4_1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + planet4_1x) / 2;
      tempy = (templ1y + planet4_1y) / 2;
      planet4_2x = (templ1x + tempx) / 2;
      planet4_2y = (templ1y + tempy) / 2;
      tempx = (tempr1x + planet4_1x) / 2;
      tempy = (tempr1y + planet4_1y) / 2;
      planet4_3x = (tempr1x + tempx) / 2;
      planet4_3y = (tempr1y + tempy) / 2;
      tempx = (cell_coordinates.c_p2.x + cell_coordinates.c_p4.x) / 2;
      tempy = (cell_coordinates.c_p2.y + cell_coordinates.c_p4.y) / 2;
      tempx = (tempx + cell_coordinates.c_p3.x) / 2;
      tempy = (tempy + cell_coordinates.c_p3.y) / 2;
      planet4_4x = (cell_coordinates.c_p3.x + tempx) / 2;
      planet4_4y = (cell_coordinates.c_p3.y + tempy) / 2;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p1.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p1.y + temp2y) / 2;
      planet5_1x = (templ1x + tempr1x) / 2;
      planet5_1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + planet5_1x) / 2;
      tempy = (templ1y + planet5_1y) / 2;
      planet5_2x = (templ1x + tempx) / 2;
      planet5_2y = (templ1y + tempy) / 2;
      tempx = (tempr1x + planet5_1x) / 2;
      tempy = (tempr1y + planet5_1y) / 2;
      planet5_3x = (tempr1x + tempx) / 2;
      planet5_3y = (tempr1y + tempy) / 2;
      tempx = (cell_coordinates.c_p2.x + cell_coordinates.c_p4.x) / 2;
      tempy = (cell_coordinates.c_p2.y + cell_coordinates.c_p4.y) / 2;
      tempx = (tempx + cell_coordinates.c_p3.x) / 2;
      tempy = (tempy + cell_coordinates.c_p3.y) / 2;
      planet5_4x = (cell_coordinates.c_p3.x + tempx) / 2;
      planet5_4y = (cell_coordinates.c_p3.y + tempy) / 2;
      tempx = (cell_coordinates.c_p2.x + cell_coordinates.c_p4.x) / 2;
      tempy = (cell_coordinates.c_p2.y + cell_coordinates.c_p4.y) / 2;
      tempx = (tempx + cell_coordinates.c_p2.x) / 2;
      tempy = (tempy + cell_coordinates.c_p2.y) / 2;
      planet5_5x = (cell_coordinates.c_p2.x + tempx) / 2;
      planet5_5y = (cell_coordinates.c_p2.y + tempy) / 2;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p1.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p1.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p3.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p3.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p2.y + temp2y) / 2;
      tempmid1x = (templ1x + tempr1x) / 2;
      tempmid1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + tempmid1x) / 2;
      tempy = (templ1y + tempmid1y) / 2;
      planet6_1x = (templ1x + tempx) / 2;
      planet6_1y = (templ1y + tempy) / 2;
      planet6_2x = (tempmid1x + tempx) / 2;
      planet6_2y = (tempmid1y + tempy) / 2;
      tempx = (tempr1x + tempmid1x) / 2;
      tempy = (tempr1y + tempmid1y) / 2;
      planet6_3x = (tempr1x + tempx) / 2;
      planet6_3y = (tempr1y + tempy) / 2;
      planet6_4x = (tempmid1x + tempx) / 2;
      planet6_4y = (tempmid1y + tempy) / 2;
      tempmid2x = (templ2x + tempr2x) / 2;
      tempmid2y = (templ2y + tempr2y) / 2;
      planet6_5x = tempmid2x;
      planet6_5y = tempmid2y;
      tempx = (templ2x + tempmid2x) / 2;
      tempy = (templ2y + tempmid2y) / 2;
      planet6_6x = (templ2x + tempx) / 2;
      planet6_6y = (templ2y + tempy) / 2;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p1.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p1.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p3.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p3.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p2.y + temp2y) / 2;
      tempmid1x = (templ1x + tempr1x) / 2;
      tempmid1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + tempmid1x) / 2;
      tempy = (templ1y + tempmid1y) / 2;
      planet7_1x = (templ1x + tempx) / 2;
      planet7_1y = (templ1y + tempy) / 2;
      planet7_2x = (tempmid1x + tempx) / 2;
      planet7_2y = (tempmid1y + tempy) / 2;
      tempx = (tempr1x + tempmid1x) / 2;
      tempy = (tempr1y + tempmid1y) / 2;
      planet7_3x = (tempr1x + tempx) / 2;
      planet7_3y = (tempr1y + tempy) / 2;
      planet7_4x = (tempmid1x + tempx) / 2;
      planet7_4y = (tempmid1y + tempy) / 2;
      tempmid2x = (templ2x + tempr2x) / 2;
      tempmid2y = (templ2y + tempr2y) / 2;
      tempx = (templ2x + tempmid2x) / 2;
      tempy = (templ2y + tempmid2y) / 2;
      planet7_5x = (templ2x + tempx) / 2;
      planet7_5y = (templ2y + tempy) / 2;
      tempx = (tempr2x + tempmid2x) / 2;
      tempy = (tempr2y + tempmid2y) / 2;
      planet7_6x = (tempr2x + tempx) / 2;
      planet7_6y = (tempr2y + tempy) / 2;
      planet7_7x = centrx;
      planet7_7y = centry;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p1.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p1.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p3.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p3.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p2.y + temp2y) / 2;
      tempmid1x = (templ1x + tempr1x) / 2;
      tempmid1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + tempmid1x) / 2;
      tempy = (templ1y + tempmid1y) / 2;
      planet8_1x = (templ1x + tempx) / 2;
      planet8_1y = (templ1y + tempy) / 2;
      planet8_2x = (tempmid1x + tempx) / 2;
      planet8_2y = (tempmid1y + tempy) / 2;
      tempx = (tempr1x + tempmid1x) / 2;
      tempy = (tempr1y + tempmid1y) / 2;
      planet8_3x = (tempr1x + tempx) / 2;
      planet8_3y = (tempr1y + tempy) / 2;
      planet8_4x = (tempmid1x + tempx) / 2;
      planet8_4y = (tempmid1y + tempy) / 2;
      tempmid2x = (templ2x + tempr2x) / 2;
      tempmid2y = (templ2y + tempr2y) / 2;
      tempx = (templ2x + tempmid2x) / 2;
      tempy = (templ2y + tempmid2y) / 2;
      planet8_5x = (templ2x + tempx) / 2;
      planet8_5y = (templ2y + tempy) / 2;
      tempx = (tempr2x + tempmid2x) / 2;
      tempy = (tempr2y + tempmid2y) / 2;
      planet8_6x = (tempr2x + tempx) / 2;
      planet8_6y = (tempr2y + tempy) / 2;
      planet8_7x = centrx;
      planet8_7y = centry;
      planet8_8x = tempmid2x;
      planet8_8y = tempmid2y;
      temp1x = (cell_coordinates.c_p3.x + cell_coordinates.c_p4.x) / 2;
      temp1y = (cell_coordinates.c_p3.y + cell_coordinates.c_p4.y) / 2;
      temp2x = (cell_coordinates.c_p1.x + cell_coordinates.c_p2.x) / 2;
      temp2y = (cell_coordinates.c_p1.y + cell_coordinates.c_p2.y) / 2;
      templ1x = (cell_coordinates.c_p4.x + temp1x) / 2;
      templ1y = (cell_coordinates.c_p4.y + temp1y) / 2;
      tempr1x = (cell_coordinates.c_p1.x + temp2x) / 2;
      tempr1y = (cell_coordinates.c_p1.y + temp2y) / 2;
      templ2x = (cell_coordinates.c_p3.x + temp1x) / 2;
      templ2y = (cell_coordinates.c_p3.y + temp1y) / 2;
      tempr2x = (cell_coordinates.c_p2.x + temp2x) / 2;
      tempr2y = (cell_coordinates.c_p2.y + temp2y) / 2;
      tempmid1x = (templ1x + tempr1x) / 2;
      tempmid1y = (templ1y + tempr1y) / 2;
      tempx = (templ1x + tempmid1x) / 2;
      tempy = (templ1y + tempmid1y) / 2;
      planet9_1x = (templ1x + tempx) / 2;
      planet9_1y = (templ1y + tempy) / 2;
      planet9_2x = (tempmid1x + tempx) / 2;
      planet9_2y = (tempmid1y + tempy) / 2;
      tempx = (tempr1x + tempmid1x) / 2;
      tempy = (tempr1y + tempmid1y) / 2;
      planet9_3x = (tempr1x + tempx) / 2;
      planet9_3y = (tempr1y + tempy) / 2;
      planet9_4x = (tempmid1x + tempx) / 2;
      planet9_4y = (tempmid1y + tempy) / 2;
      planet9_5x = (temp1x + temp2x) / 2;
      planet9_5y = (temp1y + temp2y) / 2;
      tempx = (temp1x + planet9_5x) / 2;
      tempy = (temp1y + planet9_5y) / 2;
      planet9_6x = (temp1x + tempx) / 2;
      planet9_6y = (temp1y + tempy) / 2;
      tempx = (temp2x + planet9_5x) / 2;
      tempy = (temp2y + planet9_5y) / 2;
      planet9_7x = (temp2x + tempx) / 2;
      planet9_7y = (temp2y + tempy) / 2;
      tempmidx = (templ2x + tempr2x) / 2;
      tempmidy = (templ2y + tempr2y) / 2;
      planet9_8x = tempmidx;
      planet9_8y = tempmidy;
      tempx = (templ2x + tempmidx) / 2;
      tempy = (templ2y + tempmidy) / 2;
      planet9_9x = (templ2x + tempx) / 2;
      planet9_9y = (templ2y + tempy) / 2;
  }
  if (planet_count == 1) {
      var temp = {};
      temp["x"] = centrx;
      temp["y"] = centry;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 2) {
      var temp = {};
      temp["x"] = planet2_1x;
      temp["y"] = planet2_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet2_2x;
      temp["y"] = planet2_2y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 3) {
      var temp = {};
      temp["x"] = planet3_1x;
      temp["y"] = planet3_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet3_2x;
      temp["y"] = planet3_2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet3_3x;
      temp["y"] = planet3_3y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 4) {
      var temp = {};
      temp["x"] = planet4_1x;
      temp["y"] = planet4_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet4_2x;
      temp["y"] = planet4_2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet4_3x;
      temp["y"] = planet4_3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet4_4x;
      temp["y"] = planet4_4y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 5) {
      var temp = {};
      temp["x"] = planet5_1x;
      temp["y"] = planet5_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_2x;
      temp["y"] = planet5_2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_3x;
      temp["y"] = planet5_3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_4x;
      temp["y"] = planet5_4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet5_5x;
      temp["y"] = planet5_5y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 6) {
      var temp = {};
      temp["x"] = planet6_1x;
      temp["y"] = planet6_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_2x;
      temp["y"] = planet6_2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_3x;
      temp["y"] = planet6_3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_4x;
      temp["y"] = planet6_4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_5x;
      temp["y"] = planet6_5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet6_6x;
      temp["y"] = planet6_6y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 7) {
      var temp = {};
      temp["x"] = planet7_1x;
      temp["y"] = planet7_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_2x;
      temp["y"] = planet7_2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_3x;
      temp["y"] = planet7_3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_4x;
      temp["y"] = planet7_4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_5x;
      temp["y"] = planet7_5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_6x;
      temp["y"] = planet7_6y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet7_7x;
      temp["y"] = planet7_7y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 8) {
      var temp = {};
      temp["x"] = planet8_1x;
      temp["y"] = planet8_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_2x;
      temp["y"] = planet8_2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_3x;
      temp["y"] = planet8_3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_4x;
      temp["y"] = planet8_4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_5x;
      temp["y"] = planet8_5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_6x;
      temp["y"] = planet8_6y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_7x;
      temp["y"] = planet8_7y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet8_8x;
      temp["y"] = planet8_8y;
      planet_positions.push(temp);
      return planet_positions;
  }
  else if (planet_count == 9) {
      var temp = {};
      temp["x"] = planet9_1x;
      temp["y"] = planet9_1y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_2x;
      temp["y"] = planet9_2y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_3x;
      temp["y"] = planet9_3y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_4x;
      temp["y"] = planet9_4y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_5x;
      temp["y"] = planet9_5y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_6x;
      temp["y"] = planet9_6y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_7x;
      temp["y"] = planet9_7y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_8x;
      temp["y"] = planet9_8y;
      planet_positions.push(temp);
      var temp = {};
      temp["x"] = planet9_9x;
      temp["y"] = planet9_9y;
      planet_positions.push(temp);
      return planet_positions;
  }
}
function draw_swasthi_at_center_Sudharshana_squ(JNch, obj_recieved) {
  var lJSON = setPlanet_Font_And_SizeForLanguage_UTF(obj_recieved);
  var MOVE = " M";
  var LINE = " L";
  var SPACE = " ";
  var s1x, s1y, s2x, s2y, s3x, s3y, s4x, s4y, s1s2x, s1s2y, s2s3x, s2s3y, s3s4x, s3s4y, s4s1x, s4s1y;
  mid_swasthix = (JNch.innermost_p1.x + JNch.innermost_p3.x) / 2;
  mid_swasthiy = (JNch.innermost_p1.y + JNch.innermost_p3.y) / 2;

  temp1x = (mid_swasthix + JNch.innermost_p1.x) / 2;
  temp1y = (mid_swasthiy + JNch.innermost_p1.y) / 2;
  temp2x = (mid_swasthix + temp1x) / 2;
  temp2y = (mid_swasthiy + temp1y) / 2;
  s1x = (temp1x + temp2x) / 2;
  s1y = (temp1y + temp2y) / 2;

  temp1x = (mid_swasthix + JNch.innermost_p2.x) / 2;
  temp1y = (mid_swasthiy + JNch.innermost_p2.y) / 2;
  temp2x = (mid_swasthix + temp1x) / 2;
  temp2y = (mid_swasthiy + temp1y) / 2;
  s2x = (temp1x + temp2x) / 2;
  s2y = (temp1y + temp2y) / 2;

  temp1x = (mid_swasthix + JNch.innermost_p3.x) / 2;
  temp1y = (mid_swasthiy + JNch.innermost_p3.y) / 2;
  temp2x = (mid_swasthix + temp1x) / 2;
  temp2y = (mid_swasthiy + temp1y) / 2;
  s3x = (temp1x + temp2x) / 2;
  s3y = (temp1y + temp2y) / 2;

  temp1x = (mid_swasthix + JNch.innermost_p4.x) / 2;
  temp1y = (mid_swasthiy + JNch.innermost_p4.y) / 2;
  temp2x = (mid_swasthix + temp1x) / 2;
  temp2y = (mid_swasthiy + temp1y) / 2;
  s4x = (temp1x + temp2x) / 2;
  s4y = (temp1y + temp2y) / 2;

  s1s2x = (s1x + s2x) / 2;
  s1s2y = (s1y + s2y) / 2;

  s2s3x = (s2x + s3x) / 2;
  s2s3y = (s2y + s3y) / 2;

  s3s4x = (s3x + s4x) / 2;
  s3s4y = (s3y + s4y) / 2;

  s4s1x = (s4x + s1x) / 2;
  s4s1y = (s4y + s1y) / 2;

  var pathstring = MOVE + s1x + SPACE + s1y + LINE + s4s1x + SPACE + s4s1y + LINE + s2s3x + SPACE + s2s3y + LINE + s3x + SPACE + s3y + MOVE + s2x + SPACE + s2y + LINE + s1s2x + SPACE + s1s2y + LINE + s3s4x + SPACE + s3s4y + LINE + s4x + SPACE + s4y;

  var svgarea = document.getElementById("svg" + obj_recieved.Target.ID);
  var path = document.createElementNS("http://www.w3.org/2000/svg", 'path');
  path.setAttribute("stroke", "rgb(128,0,0)");
  path.setAttribute("stroke-width", ((lJSON.FontSize * 25) / 100) + "px");
  path.setAttribute("fill", "none");
  path.setAttribute("stroke-linecap", "square");
  path.setAttribute("d", pathstring);
  svgarea.appendChild(path);
}