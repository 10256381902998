import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import moengage from "../../../../common/moengage";
import defaultImage from "../../../../common/default_profile_image.svg";
import FA_localStorage from "../../../../common/util/FA_localStorage";
import axios from "../../../../common/interceptor";
import sentryError from "../../../../common/sentryError";
import Modal from "@material-ui/core/Modal";
import Snackbar from "../../../../common/SnackBarMessage";
import CloseIcon from "@material-ui/icons/Close";
import SadIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import AstrologerUtils, { isChatEnabled, trackFAEvent } from "./AstrologerUtils";
import { Avatar, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import md5 from "md5";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BOOKING_CHARGE, MIN_BALANCE_MINS, SUPPORT_NUMBER } from "../../../../app.config";
import BrowserUtils from "./UAScripts";
import InfoIcon from "./info.svg";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, parse, setDate } from "date-fns";
import Warning from "@material-ui/icons/WarningRounded";
import SlotItem from "./SlotItem.component";
import { initChat, notifyChat } from "./chat/ChatUtls";
import Conversations from "./chat/Conversations";
import { ChatWindowStateSetterContext } from "./chat/ChatWindowContext";
import CallIcon from '@material-ui/icons/Call';
import ChatBubble from '@material-ui/icons/ChatBubble';
import UserProfileForPreCallChat from "../../../user-profile/components/UserProfileForPreCallChat";
import clsx from "clsx";
import T2AEventTrack from "../../../../common/T2AEventTrack";
import { ContentFreeChatInfo, ContentPaidChatInfo } from "./StaticContent";

const countDownMaxSeconds = 3
let timer = null

const AstrologerCallDialog = (props) => {
  const [snackBarState, setSnackBarState] = useState({});
  const [warModalState, SetWarModalState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [confirmAction, setConfirmAction] = useState(null);
  const [dialogType, setDialogType] = useState(props.dialogType);
  const [isOpen, setIsOpen] = useState(props.isStateOpen);
  const [newUI, setNewUI] = useState(false);
  const [countDownSecond, setCountDownSecond] = useState(countDownMaxSeconds);
  const [userProfileUpdated, setUserProfileUpdated] = useState(props.dialogType === 'call' || props.dialogType === 'chat' ? false : true);

  const setChatWindowData = useContext(ChatWindowStateSetterContext);
  const [consultationId, setConsultationId] = useState(null);
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, [])

  useEffect(() => {
    // console.log('AstrologerCallDialog:props.isStateOpen:', props.isStateOpen)
    setIsOpen(props.isStateOpen)
  }, [props.isStateOpen])

  useEffect(() => {
    if (props.skipBirthDetailsForm === true) {
      setUserProfileUpdated(true)
    }
  }, [props.skipBirthDetailsForm])

  useEffect(() => {
    // console.log('AstrologerCallDialog:useEffect:isOpen', isOpen)
    if (isOpen === true) {
      if (userProfileUpdated) {
        continueConsultation()
      } else {
        window.location.hash = "confirm-birth-details";
      }
    } else {
      clearTimeout(timer)
    }

  }, [isOpen])

  window.addEventListener("hashchange", (e) => {

    if (e.oldURL.endsWith('#confirm-birth-details') && isOpen === true) {
      props.handleClose();
      setIsOpen(false);
    }

  });

  // useEffect(() => {
  //   if (window.location.hash === '' && isOpen === true) {
  //     moengage(props.dialogType.toUpperCase() + "_CANCEL_CLICKED");
  //     props.handleClose();
  //     setIsOpen(false);
  //   }
  // }, [window.location.hash])

  const continueConsultation = () => {

    let reqType = props.dialogType;
    if (props.dialogType === 'suggest-call') {
      reqType = 'call'
    } else if (props.dialogType === 'suggest-chat') {
      reqType = 'chat'
    }

    const eData = {
      name: 'Screen View Action',
      screenName: 'Requesting ' + reqType,
      sourceScreenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile'
    }
    if (props.clickFrom == 'chat') {
      eData.sourceScreenName = 'Chat Screen';
    }
    trackeventData(eData);

    countDown(countDownMaxSeconds)
    timer = setTimeout(() => {
      // console.log('AstrologerCallDialog:useEffect:timeout:isOpen', isOpen, props.dialogType)
      if (isOpen === true) {
        if (props.dialogType === 'call') {
          onCall()
        }

        if (props.dialogType === 'chat') {
          onChat()
        }
      }
    }, countDownMaxSeconds * 1000)
  }

  const countDown = (s) => {
    if (s > 0) {
      setCountDownSecond(--s)
      setTimeout(() => countDown(s), 1000)
    }
  }

  const showConfirmDialog = (message, action) => {
    setConfirmMessage(message);
    setConfirmAction(action);
  };

  const confirmShortCall = (e) => {
    const shortCall = true;
    setConfirmMessage(null);
    onCall(e, shortCall);
  };

  const proceedToBooking = (e) => {
    setConfirmMessage(null);
    setDialogType("booking");
  };

  const astrologerFee = props.astrologer.price ? parseInt(props.astrologer.price.split("/")[0], 10) : 0;
  const walletBalance = parseInt(FA_localStorage("walletBalance")) || 0;
  const MINDELAY = 2;

  const upperCaseWords = (str) => {
    if (str) {
      str = str.toLowerCase();
      return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
        return s.toUpperCase();
      });
    }
  };
  const similarAstrologer = () => {
    moengage("PROFILE_FIND_SIMILAR_CLICKED");
    window.location.href = "/similar-astrologer/" + props.astrologer.profileUrl;
  };
  const showError = (message) => {
    setSnackBarState({
      open: true,
      message: message,
    });
    setTimeout(props.handleClose, 1000)
    setIsOpen(false)
  };

  const closeError = () => {
    setSnackBarState({ open: false });
  };

  const isFreeMinutes = () => {
    return props.astrologer.free_minutes && parseInt(props.astrologer.free_minutes) > 0;
  };

  const addConsulationId = (chatID, newConsultationId) => {
    Object.entries(localStorage).forEach(([key, value]) => {
      if (key.length > 9 && key.substr(0, 9) === 'cnslt_id_') {
        localStorage.removeItem(key)
      }
    })
    if (newConsultationId) {
      localStorage.setItem('cnslt_id_' + chatID, newConsultationId);
    }
  }

  const onChat = async (e) => {
    // TODO:call chat api
    if (props.startNewConsultation && typeof props.startNewConsultation === 'function') {
      const newAstrologer = props.astrologer;
      newAstrologer.fullName = newAstrologer.salutation && newAstrologer.name.indexOf(newAstrologer.salutation) < 0
        ? [newAstrologer.salutation, newAstrologer.name].join(" ")
        : newAstrologer.name
      props.startNewConsultation(newAstrologer)
    }
    const chatInfo = await initChat(props.astrologer.id);
    if (chatInfo && chatInfo.success === true) {
      setChatWindowData({
        astrologer: props.astrologer.id,
        time: Date.now(),
        chatID: chatInfo.chatID,
        status: chatInfo.status,
        freeMinutes: isFreeMinutes() ? props.astrologer.free_minutes : 0
      })
      //console.log(JSON.stringify({ 'astrologer': props.astrologer.id, 'chatID': chatInfo.chatID }))
      if (mounted) {
        setConsultationId(chatInfo.receiver?.consultationId)
      }
      let cnsltatn_id = chatInfo.receiver?.consultationId || 0;
      if (cnsltatn_id) {
        notifyChat(cnsltatn_id)
      }
      addConsulationId(chatInfo.chatID, cnsltatn_id)

      const eData = {
        name: 'Consultation',
        screenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile',
        consultStatus: 'requested',
        consultDuration: 0,
        consultAmount: 0,
        consultationId: chatInfo.receiver?.consultationId || 0
      }
      if (props.clickFrom == 'chat') {
        eData.sourceScreenName = 'Chat Screen';
      }
      trackeventData(eData);

    } else {
      showError(chatInfo.message);
      const eData = {
        name: 'Consultation',
        screenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile',
        consultStatus: 'failed',
        consultDuration: 0,
        consultAmount: 0,
        consultationId: chatInfo?.receiver?.consultationId || 0
      }
      if (props.clickFrom == 'chat') {
        eData.sourceScreenName = 'Chat Screen';
      }
      trackeventData(eData);
    }
  };

  const onCall = (e, shortCall) => {
    moengage("PROFILE_OKAY_CLICKED");
    handleClose();
    setIsLoading(true);

    if (
      window.AndroidBridge &&
      window.AndroidBridge.call("getAppVersion").toString() !== "" &&
      window.compareVersion("3.0.0", window.AndroidBridge.call("getAppVersion")) > 0
    ) {
      window.location.href = window.location.origin + "?access=no";
      return false;
    }

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + window.location.pathname;
    } else {

      let isInPromoAst = false;
      axios()
        .post("/consultations/call", {
          id: props.astrologer.id,
          mode: "audio",
          proceed_short_call: shortCall,
        })
        .then((res) => {
          if (res.data.success) {
            const secondsSinceEpoch = Math.floor(Date.now() / 1000);

            const eData = {
              name: 'Consultation',
              screenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile',
              consultStatus: 'requested',
              consultDuration: 0,
              consultAmount: 0,
              consultationId: res.data?.data?.id || 0
            }
            if (props.clickFrom == 'chat') {
              eData.sourceScreenName = 'Chat Screen';
            }
            trackeventData(eData);

            FA_localStorage(
              "lastActiveConsultation",
              JSON.stringify({
                id: res.data.data.id,
                key: md5(res.data.data.id.toString()),
                token: res.data.data.token,
                status_token: res.data.data.status_token,
                astrologer: props.astrologer.name,
                astrologerUrl: props.astrologer.profileUrl,
              })
            );

            localStorage.setItem(
              "progress_call",
              "1|" +
              (parseInt(secondsSinceEpoch) + parseInt(res.data.data.time)) +
              "|" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              props.astrologer.profileUrl +
              "|" +
              props.astrologer.name
            );
            localStorage.setItem("old_user", true);
            /** tracking start */
            trackActions("fa_consult_init", isFreeMinutes() ? "free" : "main");
            trackFAEvent({
              event_type: "fa_consult_init",
              event_action: props.extras ? props.extras.source : null,
              event_label: props.astrologer.profileUrl,
              event_value: res.data.data.id,
              meta: {
                ast_id: props.astrologer.id,
                ast_type: isInPromoAst ? "promotional" : "main",
                ast_url: props.astrologer.profileUrl,
                call_details: res.data.data,
              },
            });
            /** tracking end */
            window.location.href =
              "/astrologer-connect/" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              props.astrologer.profileUrl;
          } else if (res.data.isProfileUpdated) {
            showError("Please update your profile");
            window.setTimeout(function () {
              window.location.href = "/userprofile?pathname=/astrologer/" + props.astrologer.profileUrl;
            }, 3000);
          } else if (res.data.isWalletRecharge) {
            showError(res.data.message);
            window.setTimeout(function () {
              window.location.href = "/my-wallet?pathname=/astrologer/" + props.astrologer.profileUrl;
            }, 5000);
          } else if (res.data.isSimilar) {
            //SetWarModalState({...warModalState, warningModalOpen: true, warningModalMessage: res.data.message});
            props.handleClose();
            showError(res.data.message);
          } else {
            if (res.data.confirm) showConfirmDialog(res.data.message, res.data.action);
            else showError(res.data.message);
          }
          /** tracking un successful calls */
          if (res.data.success !== true) {
            trackActions("fa_consult_init_err", isInPromoAst ? "promotional" : "main");
            trackFAEvent({
              event_type: "fa_consult_init_err",
              event_action: props.extras ? props.extras.source : null,
              event_label: props.astrologer.profileUrl,
              event_value: props.astrologer.id,
              meta: res.data,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          sentryError("ASTROLOGER_PROFILE_CALL", err);
          showError("Error occurred, Please try again");

          const eData = {
            name: 'Consultation',
            screenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile',
            consultStatus: 'failed',
            consultDuration: 0,
            consultAmount: 0,
            consultationId: 0
          }
          if (props.clickFrom == 'chat') {
            eData.sourceScreenName = 'Chat Screen';
          }
          trackeventData(eData);

        });

      // axios()
      //   .get("/astrologer/in-promo/" + props.astrologer.id)
      //   .then((res) => {
      //     console.log(props.cashBackDiscount)
      //     if (
      //       (res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("promoPrice")) ||
      //       (!res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("nextCallPrice"))
      //     ) {
      //       showError("Offer outdated, please try again");
      //       setTimeout((e) => {
      //         window.location.href = "/";
      //       }, 3000);
      //       setIsLoading(false);
      //     } else {


      //     }
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //     console.log(err);
      //     showError("Error occurred, Please try again");
      //   });
    }
  };

  const trackeventData = (data) => {
    const astrologerData = props?.astrologer;
    let c_type = (isFreeMinutes()) ? 'Free' : 'Paid';
    let consultName = props?.dialogType || 'chat';
    if (consultName == 'suggest-call') {
      consultName = 'call';
    } else if (consultName == 'suggest-chat') {
      consultName = 'chat';
    }
    const payload = {
      event: {
        name: data.name,
        attributes: {
          'Screen Name': data.screenName,
          'Astrologer ID': astrologerData?.id || 0,
          'Astrologer Name': astrologerData?.name || '',
          'Consultation Name': (consultName == 'call') ? 'Call' : 'Chat',
          'Consultation Type': c_type,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Product': 'COT'
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }

    if (data.consultStatus) {
      c_type = c_type.toLocaleLowerCase();
      payload.event.attributes['Consultation Status'] = data.consultStatus;
      payload.event.attributes['Consultation Duration'] = data.consultDuration;
      payload.event.attributes['Consultation Amount'] = data.consultAmount;
      payload.event.attributes['Consultation ID'] = data.consultationId || 0;
      payload.extra.user['Current wallet balance'] = parseInt(localStorage.getItem('walletBalance'));
      payload.extra.user[`Last consulted ${c_type} ${consultName} astrologer ID`] = astrologerData.id;
      payload.extra.user[`Last consulted ${c_type} ${consultName} astrologer name`] = astrologerData.name;
      payload.extra.user[`Last ${consultName} attempt status`] = data.consultStatus;
      payload.extra.user[`Last ${consultName} attempt time stamp`] = Date.now();
    }
    if (data.sourceScreenName) {
      payload.event.attributes['Source Screen Name'] = data.sourceScreenName;
    }
    if (data.buttonName) {
      payload.event.attributes['Button Name'] = data.buttonName;
    }

    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  };

  const profileEditAction = () => {
    const payload = {
      event: {
        name: 'Profile Edit Action',
        attributes: {
          'Screen Name': 'Birth details confirmation',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Product': 'COT'
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  const trackActions = (type, label) => {
    moengage(type);
    const data = props.astrologer;
    let ast_rating = data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: props.isSearch ? "search" : "home",
      p_label: label,
      p_content: data.profileUrl,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience.split(" ")[0],
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.price.split("/")[0],
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };
  const imageLoaded = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("loaded");
  };
  const handleClose = () => {
    console.log("closing..");
  };
  const findCommonLanguages = (asl, usl) => {
    let cl = asl.filter(function (n) {
      return usl.indexOf(n) !== -1;
    });
    return cl.length > 0 ? cl : asl;
  };

  const [slotsOpen, setSlotOpen] = useState(null);
  const [timeInputOpen, setTIOpen] = useState(false);
  const [timeInput, setTimeInputValue] = useState("09:00 AM");
  const onTimeEdit = (e, value) => {
    if (value && value.length > 0) {
      const t = parse("01/Jan/2001 " + value, "dd/MMM/yyyy hh:mm a", new Date());
      if (8 < t.getHours() && t.getHours() < 21) {
        setTimeInputValue(value && value.length > 0 ? value : null);
      } else {
        showError("Please choose a time between 9 AM and 9 PM");
        setTimeInputValue(null);
      }
    }
  };

  const [duration, setDuration] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [dateInput, setDateInput] = useState(null);

  useEffect(() => {
    bookingData && props.runUserUpdates && props.runUserUpdates();
  }, [bookingData]);

  const onBook = (e) => {
    handleClose();
    setIsLoading(true);

    if (
      window.AndroidBridge &&
      window.AndroidBridge.call("getAppVersion").toString() !== "" &&
      window.compareVersion("3.0.0", window.AndroidBridge.call("getAppVersion")) > 0
    ) {
      window.location.href = window.location.origin + "?access=no";
      return false;
    }

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + window.location.pathname;
    } else {
      axios()
        .get("/astrologer/in-promo/" + props.astrologer.id)
        .then((res) => {
          if (
            (res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("promoPrice")) ||
            (!res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("nextCallPrice"))
          ) {
            showError("Offer outdated, please try again");
            setTimeout((e) => {
              window.location.href = "/";
            }, 3000);
            setIsLoading(false);
          } else {
            let isInPromoAst = res.data.is_in_promo_list;
            const time = parse(format(dateInput, "dd/MM/yyyy") + " " + timeInput, "dd/MM/yyyy hh:mm a", new Date());
            axios()
              .post("/consultations/book", {
                id: props.astrologer.id,
                mode: "audio",
                time: time.toGMTString(),
                duration: duration,
              })
              .then((res) => {
                if (res.data.success) {
                  setBookingData(res.data.data);
                  /** tracking start */
                  trackActions("fa_booking_init", isInPromoAst ? "promotional" : "main");
                  trackFAEvent({
                    event_type: "fa_booking_init",
                    event_action: props.extras ? props.extras.source : null,
                    event_label: props.astrologer.profileUrl,
                    event_value: res.data.data.id,
                    meta: {
                      ast_id: props.astrologer.id,
                      ast_type: isInPromoAst ? "promotional" : "main",
                      ast_url: props.astrologer.profileUrl,
                      call_details: res.data.data,
                    },
                  });
                  /** tracking end */
                } else if (res.data.isProfileUpdated) {
                  showError("Please update your profile");
                  window.setTimeout(function () {
                    window.location.href = "/userprofile?pathname=/astrologer/" + props.astrologer.profileUrl;
                  }, 3000);
                } else if (res.data.isWalletRecharge) {
                  showError(res.data.message);
                  window.setTimeout(function () {
                    window.location.href = "/my-wallet?pathname=/astrologer/" + props.astrologer.profileUrl;
                  }, 5000);
                } else if (res.data.isSimilar) {
                  //SetWarModalState({...warModalState, warningModalOpen: true, warningModalMessage: res.data.message});
                  props.handleClose();
                  showError(res.data.message);
                } else {
                  showError(res.data.message);
                }
                /** tracking un successful calls */
                if (res.data.success !== true) {
                  trackActions("fa_booking_init_err", isInPromoAst ? "promotional" : "main");
                  trackFAEvent({
                    event_type: "fa_booking_init_err",
                    event_action: props.extras ? props.extras.source : null,
                    event_label: props.astrologer.profileUrl,
                    meta: res.data,
                  });
                }
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err);
                sentryError("ASTROLOGER_PROFILE_CALL", err);
                showError("Error occured, Please try again");
              });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          showError("Error occured, Please try again");
        });
    }
  };

  const confirmBooking = () => {
    if (dateInput) {
      const t = parse(format(dateInput, "dd/MM/yyyy") + " " + timeInput, "dd/MM/yyyy hh:mm a", new Date());
      const minTime = new Date(Date.now() + MINDELAY * 60 * 60 * 1000);
      if (t.getTime() >= minTime.getTime() && duration > 0) {
        setShowConfirm(true);
      } else {
        showError(
          duration > 0
            ? `Consultation booking time should be after ${format(minTime, "hh:mm a")}`
            : `Choose a consultation duration`
        );
      }
    } else {
      showError(
        duration > 0
          ? `Choose a preferred consultation date & time`
          : `Choose a preferred consultation duration, date & time`
      );
    }
  };

  const finishBooking = () => {
    cancelBooking();
    props.handleClose();
  };

  const cancelBooking = () => {
    setShowConfirm(false);
  };

  const getRequiredBalance = () => {
    const chargeableDuration = duration - props.astrologer.free_minutes;
    return chargeableDuration * astrologerFee + BOOKING_CHARGE;
  };

  const formatedBookingTime = () => {
    if (dateInput && timeInput) {
      const t = parse(format(dateInput, "dd/MM/yyyy") + " " + timeInput, "dd/MM/yyyy hh:mm a", new Date());
      if (t.toDateString() === new Date().toDateString()) {
        return format(t, "dd MMMM yyyy ") + "(Today)" + format(t, ", hh:mm a");
      } else {
        return format(t, "dd MMMM yyyy, hh:mm a");
      }
    } else {
      return null;
    }
  };

  const BookingSlots = (props) => {
    const defDate = parse(format(new Date(), "yyyy-MM-dd"), "yyyy-MM-dd", new Date());
    const [dateInputOpen, setDIOpen] = useState(false);
    const [dateInput, setDateInputValue] = useState(props.dateInput ? props.dateInput : defDate);
    const [timeInput, setTimeInputValue] = useState(props.timeInput);
    const [error, setError] = useState(null);
    const showLocalError = function (err) {
      setError(err);
    };

    const onDateEdit = (e, value) => {
      if (value && value.length > 0) {
        const t = parse(value + " 12:00 AM", "MMM dd, yyyy hh:mm a", new Date());
        if (t.toDateString() !== dateInput.toDateString()) {
          setTimeInputValue(null);
        }
        setDateInputValue(t);
        if (t.getTime() < Date.now() + 2 * 60 * 60 * 1000) {
          setTimeInputValue(null);
        }
      }
    };

    const [showProgress, showLoading] = useState(false);
    const [slots, setSlots] = useState([]);

    const bookFrom = (i) => {
      const s = [...slots];
      //console.log("{", s, i, s[i]);
      s.map((d) => {
        if (d.marker === "selected") d.marker = "active";
        return true;
      });
      if (s[i] && s[i].marker === "active") {
        const dfactor = duration / 15 - 1;
        if (dfactor > 0) {
          let loop = dfactor;
          let goForward = true;
          let f = 1,
            b = 1;
          while (loop > 0) {
            if (goForward && s[i + f] && s[i + f].status === "open") {
              s[i + f].marker = "selected";
              f++;
            } else if (s[i - b] && s[i - b].status === "open") {
              s[i - b].marker = "selected";
              b++;
              goForward = false;
            }
            loop--;
          }
        }
        s[i].marker = "selected";
        setSlots(s);
      }
    };

    const getCaptionOfSlot = (slot) => {
      const slots = slot.split("-");
      return (
        format(parse("2001-01-01 " + slots[0], "yyyy-MM-dd HH:mm", new Date()), "hh:mm a") +
        " to " +
        format(parse("2001-01-01 " + slots[1], "yyyy-MM-dd HH:mm", new Date()), "hh:mm a")
      );
    };

    const timeElapsed = (slot) => {
      const time = parse(format(dateInput, "yyyy-MM-dd") + " " + slot.split("-")[0], "yyyy-MM-dd HH:mm", new Date());
      const ref = new Date(Date.now() + MINDELAY * 60 * 60 * 1000);
      return ref.getTime() > time.getTime();
    };

    const fetchAstrologerSlots = () => {
      setError(null);
      if (dateInput && slotsOpen) {
        showLoading(true);
        axios()
          .post("/consultations/astrologer-time-slot", {
            astrologer_id: props.astrologer.id,
          })
          .then((res) => {
            if (res.data.success) {
              try {
                const sID = format(dateInput, "yyyy-MM-dd");

                const bookings = res.data.slots[sID];
                const slots = [];
                bookings.map((booking, i) => {
                  const slot = Object.keys(booking)[0];
                  const status = booking[slot];
                  const sameDay = dateInput.toDateString() === new Date().toDateString();
                  const elapsed = sameDay && timeElapsed(slot);

                  if (elapsed === false) {
                    slots.push({
                      index: i,
                      caption: getCaptionOfSlot(slot),
                      status: status,
                      marker: "inactive",
                    });
                  }
                  return true;
                });
                if (slots.length > 0) {
                  filterTimeSlots(slots);
                  setSlots(slots);
                  setChange(Date.now());
                } else {
                  setDateInputValue(new Date(dateInput.getTime() + 24 * 60 * 60 * 1000));
                  setTimeInputValue(null);
                }
              } catch (err) {
                console.error(err);
              }
            }
            showLoading(false);
          })
          .catch((err) => {
            showLocalError("Unable to fetch astrologer bookings");
          });
      }
    };

    const [changed, setChange] = useState(null);

    const markSelection = () => {
      if (timeInput) {
        [...slots].map((slot, i) => {
          if (slot.caption.indexOf(timeInput) === 0) {
            bookFrom(i);
          }
          return true;
        });
      }
    };

    useEffect(() => {
      if (changed) {
        markSelection();
      }
    }, [changed]);

    function filterTimeSlots(slots) {
      const dfactor = duration / 15 - 1;

      slots.map((slot, i) => {
        let loop = dfactor;
        let lcount = 0;
        let f = 1,
          b = 1;
        let goForward = true;
        while (loop > 0) {
          if (goForward && slots[i + f] && slots[i + f].status === "open") {
            lcount++;
            f++;
          } else if (slots[i - b] && slots[i - b].status === "open") {
            lcount++;
            b++;
            goForward = false;
          }
          loop--;
        }
        if (lcount === dfactor || dfactor === 0) {
          slot.marker = slot.status === "open" ? "active" : "inactive";
        }
        return true;
      });
    }

    useEffect(() => {
      if (dateInput) fetchAstrologerSlots();
      else {
        setDateInputValue(new Date());
        setTimeInputValue(null);
      }
    }, [dateInput]);

    const chooseSelectedSlot = () => {
      setError(null);
      let timeslot = null;
      slots.map((slot) => {
        if (timeslot === null && slot.marker === "selected") {
          timeslot = slot.caption.split(" to ")[0];
        }
      });
      if (timeslot) {
        props.setDateInput(dateInput);
        setTimeInputValue(timeslot);
        props.setTimeInput(timeslot);
        setSlotOpen(false);
      } else {
        showLocalError("Please choose a preferred time slot");
      }
    };

    const cancelSlots = () => {
      setSlotOpen(false);
    };

    return (
      <>
        <div className="ctrl-group">
          <span>
            <b>Select consultation date:</b>
          </span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="dateInput"
              format="MMM dd, yyyy"
              className="dlgformInputs"
              placeholder="dd/MMM/yyyy"
              inputVariant="outlined"
              minDate={new Date()}
              maxDate={new Date(Date.now() + 6 * 24 * 60 * 60 * 1000)}
              keyboardIcon={null}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
              value={dateInput ? dateInput : new Date()}
              onChange={onDateEdit}
              open={dateInputOpen}
              onClick={() => {
                setDIOpen(true);
              }}
              onClose={() => {
                setDIOpen(false);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="dialog-content">
          {error && <div style={{ color: "red" }}>{error}</div>}
          <div className="book-slot-wrap">
            {slots.length > 0 ? (
              slots.map((slot, index) => (
                <SlotItem
                  index={index}
                  caption={slot.caption}
                  status={slot.status}
                  active={slot.marker}
                  dateInput={dateInput}
                  bookFrom={bookFrom}
                />
              ))
            ) : (
              <div>No timeslot available</div>
            )}
            <CircularProgress
              style={{
                display: showProgress ? "block" : "none",
                position: "absolute",
                left: "calc(50% - 20px)",
                top: "calc(50% - 20px)",
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <button onClick={cancelSlots}>Cancel</button>
          <button onClick={chooseSelectedSlot}>OK</button>
        </div>
      </>
    );
  };

  return (
    <div className={[
      newUI ? 'new-style' : ''
    ].join(' ').trim()}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className={"astrologerDialog CCDialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="dialog">

          {userProfileUpdated ?
            <>
              {props.dialogType === 'call' && props.astrologer.price &&
                <>
                  <div className={"header"}>Calling... <CallIcon /></div>
                  <div className={"dialogBody"}>
                    <Avatar
                      className={"avatarImage"}
                      alt={props.astrologer.name}
                      src={
                        props.astrologer.imageUrl ? AstrologerUtils.createThumbImageURI(props.astrologer.id) : defaultImage
                      }
                      onLoad={imageLoaded}
                    />
                    <div className="astrologerCnInfo threeDotAnimation">Please wait for a while</div>
                    <div className="astrologerName">{upperCaseWords(props.astrologer.name)}</div>
                    {isFreeMinutes() ? <ContentFreeChatInfo /> : <ContentPaidChatInfo />}
                    {/* <div className={clsx('astrologerCnInfo', countDownSecond === 0 ? 'threeDotAnimation' : '')}>
                      {countDownSecond === 0 ?
                        'connecting' : `will connect with you in ${countDownSecond}sec`
                      }
                    </div> */}
                    {isFreeMinutes() && <div className="free_cc">
                      <strong className="free-tag">Free call for 5 mins</strong>
                    </div>}
                  </div>
                </>
              }

              {props.dialogType === 'chat' && props.astrologer.price &&
                <>
                  <div className={"header"}>Requesting Chat... <ChatBubble /></div>
                  <div className={"dialogBody"}>
                    <Avatar
                      className={"avatarImage"}
                      alt={props.astrologer.name}
                      src={
                        props.astrologer.imageUrl ? AstrologerUtils.createThumbImageURI(props.astrologer.id) : defaultImage
                      }
                      onLoad={imageLoaded}
                    />
                    <div className="astrologerCnInfo threeDotAnimation">Please wait for a while</div>
                    <div className="astrologerName">{upperCaseWords(props.astrologer.name)}</div>
                    {isFreeMinutes() ? <ContentFreeChatInfo /> : <ContentPaidChatInfo />}
                    {/* <div className={clsx('astrologerCnInfo', countDownSecond === 0 ? 'threeDotAnimation' : '')}>
                      {countDownSecond === 0 ?
                        'connecting' : `will connect with you in ${countDownSecond}sec`
                      }
                    </div> */}
                    {isFreeMinutes() &&
                      <div className="free_cc">
                        <strong className="free-tag">Free chat for 5 mins</strong>
                      </div>
                    }
                  </div>
                </>
              }
            </>
            :
            <>
              <div className="UPFormPreCC">
                <div className={"header"}>Birth Details</div>
                <UserProfileForPreCallChat
                  location={{
                    pathname: '/userprofile'
                  }}
                  handleClose={() => {
                    moengage(props.dialogType.toUpperCase() + "_PRE_PROFILE_UPDATE_CANCEL_CLICKED");
                    props.handleClose();
                    setIsOpen(false);
                  }}
                  continue={() => {
                    if (props?.continueBirthForm) {
                      props.continueBirthForm()
                    } else {
                      setUserProfileUpdated(true)
                      continueConsultation()
                      profileEditAction()
                    }
                  }}
                />
              </div>
            </>
          }



          {props.dialogType === 'suggest-call' && props.astrologer.price &&
            <>
              <div className={"header"}>Request Call <CallIcon /></div>
              <div className={"dialogBody"}>
                <Avatar
                  className={"avatarImage"}
                  alt={props.astrologer.name}
                  src={
                    props.astrologer.imageUrl ? AstrologerUtils.createThumbImageURI(props.astrologer.id) : defaultImage
                  }
                  onLoad={imageLoaded}
                />
                <div className="astrologerName">{upperCaseWords(props.astrologer.name)}</div>
                <div className="actionInfo">The astrologer is not currently available to respond to chat requests.
                  Would you like to try making a phone call instead?</div>
                {
                  isFreeMinutes() ?
                    <div className="free_cc"><strong className="free-tag">Free call for 5 mins</strong></div>
                    :
                    <></>
                }
              </div>
            </>
          }

          {/* {props.cashBackDiscount > 0 ? (
            <span className="offerBanner">
              You will get <b>{props.cashBackDiscount}% CASHBACK</b> after the consultation
            </span>
          ) : (
            ""
          )} */}
        </div>

        <DialogActions className="footer" style={{ justifyContent: "center", padding: "16px 0" }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>

              {(userProfileUpdated && (props.dialogType === 'chat' || props.dialogType === 'call')) &&
                <>
                  <Button
                    className={"dialogButton secondary"}
                    onClick={() => {
                      moengage(props.dialogType.toUpperCase() + "_CANCEL_CLICKED");
                      props.handleClose();
                      setIsOpen(false);
                      const eData = {
                        name: 'Button Action',
                        screenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile',
                        consultDuration: 0,
                        consultAmount: 0,
                        buttonName: 'Cancel Request'
                      }
                      if (props.clickFrom == 'chat') {
                        eData.sourceScreenName = 'Chat Screen';
                      }
                      trackeventData(eData);
                    }}>
                    Cancel Request
                  </Button>

                  {props.dialogType === 'chat' && props.astrologer.chargesChat !== null &&
                    <Button
                      // onClick={onChat}

                      onClick={() => {
                        props.handleClose();
                        setIsOpen(false);
                        const eData = {
                          name: 'Button Action',
                          screenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile',
                          consultDuration: 0,
                          consultAmount: 0,
                          buttonName: 'Ok'
                        }
                        if (props.dialogType == 'chat') {
                          eData.sourceScreenName = 'Chat Screen';
                        }
                        trackeventData(eData);
                        onChat()
                      }}

                      variant="contained"
                      className={"dialogButton primary"}>
                      OK
                    </Button>
                  }
                  {props.dialogType === 'call' &&
                    <Button
                      onClick={() => {
                        props.handleClose();
                        setIsOpen(false);
                        const eData = {
                          name: 'Button Action',
                          screenName: props.clickFrom == '/app-home' ? 'Chat or Talk' : 'Astrologer profile',
                          consultDuration: 0,
                          consultAmount: 0,
                          buttonName: 'Ok'
                        }
                        if (props.dialogType == 'chat') {
                          eData.sourceScreenName = 'Chat Screen';
                        }
                        trackeventData(eData);
                        onCall()
                      }}
                      // onClick={onCall}
                      variant="contained"
                      className={"dialogButton primary"}>
                      OK
                    </Button>
                  }
                </>
              }

              {props.dialogType === 'suggest-call' &&
                <>
                  <Button
                    className={"dialogButton secondary"}
                    onClick={() => {
                      moengage("REQUEST_CALL_CANCEL_CLICKED");
                      props.handleClose();
                      setIsOpen(false);

                      const eData = {
                        name: 'Button Action',
                        screenName: 'Requesting call',
                        buttonName: 'Cancel Request',
                        consultationName: 'Call',
                      }
                      if (props.clickFrom === '/app-home') {
                        eData.sourceScreenName = 'Chat or Talk';
                      } else if (props.clickFrom === 'chat') {
                        eData.sourceScreenName = 'Chat Screen';
                      } else {
                        eData.sourceScreenName = 'Astrologer profile';
                      }
                      trackeventData(eData);


                    }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      props.callNowAction(onCall)
                      const eData = {
                        name: 'Button Action',
                        screenName: 'Requesting call',
                        buttonName: 'Ok',
                        consultationName: 'Call',
                      }
                      if (props.clickFrom === '/app-home') {
                        eData.sourceScreenName = 'Chat or Talk';
                      } else if (props.clickFrom === 'chat') {
                        eData.sourceScreenName = 'Chat Screen';
                      } else {
                        eData.sourceScreenName = 'Astrologer profile';
                      }
                      trackeventData(eData);
                    }}
                    variant="contained"
                    className={"dialogButton primary"}
                  >
                    Call Now
                  </Button>
                </>
              }


            </>
          )}
        </DialogActions>

      </Dialog>
      {
        <Dialog className="confirm-booking" open={slotsOpen}>
          <div className="dialog">
            <div className="title">Choose consultation date &amp; time</div>
            <BookingSlots
              astrologer={props.astrologer}
              setDateInput={setDateInput}
              dateInput={dateInput}
              setTimeInput={setTimeInputValue}
              timeInput={timeInput}
              duration={duration}
              showError={showError}
            />
          </div>
        </Dialog>
      }
      {showConfirm && (
        <Dialog className="confirm-booking" open={showConfirm}>
          <div className="dialog">
            <div className="title">{bookingData ? "Booking initiated" : "Proceed with booking?"}</div>
            <div>
              {bookingData ? (
                <b style={{ color: "#0a0", paddingTop: 14, display: "block" }}>
                  Your Booking is initiated. Please wait for the confirmation call.
                </b>
              ) : (
                <b style={{ color: "#000", paddingTop: 14, display: "block" }}>
                  Proceed with booking of consultation with Astrologer {upperCaseWords(props.astrologer.name)}?
                </b>
              )}
            </div>
            <div className="details">
              <div>
                <b>Booking details:</b>
              </div>
              <div>Time: {formatedBookingTime()} </div>
              <div>Duration: {duration} minutes</div>
              {bookingData && <div>Booking ID: {bookingData.id}</div>}
            </div>
            <div className="content">
              Our support executive will call you within an hour to confirm your booking after checking with the
              astrologer. Please make sure you pick the call from <b>{SUPPORT_NUMBER}</b>.
              {!bookingData && (
                <span>
                  {" "}
                  The booking charge of <b>{BOOKING_CHARGE}pts</b> will be deducted immediately.
                </span>
              )}
            </div>
            {bookingData ? (
              <div className="buttons">
                <button style={{ width: "100%", backgroundColor: "#ee600d", color: "#fff" }} onClick={finishBooking}>
                  Close
                </button>
              </div>
            ) : (
              <div className="buttons">
                {isLoading ? (
                  <button style={{ width: "100%" }}>
                    <CircularProgress style={{ width: 30, height: 30 }} />
                  </button>
                ) : (
                  <>
                    <button onClick={cancelBooking}>Cancel</button>
                    <button onClick={onBook}>Proceed with booking</button>
                  </>
                )}
              </div>
            )}
          </div>
        </Dialog>
      )}
      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBarState.message}
        closeError={closeError}
        open={snackBarState.open}></Snackbar>
      {confirmMessage && (
        <Modal open={confirmMessage ? true : false}>
          <div className="warningModal">
            <div style={{ textAlign: "left", padding: "0 8px" }}>{confirmMessage}</div>
            <div style={{ padding: "8px 0", marginBottom: -16 }}>
              <button
                style={{ marginRight: 8 }}
                className="dialogButton secondary"
                onClick={(e) => setConfirmMessage(null)}>
                Cancel
              </button>
              {confirmAction === "booking" ? (
                <button className="dialogButton primary" onClick={proceedToBooking}>
                  Book now
                </button>
              ) : (
                <button className="dialogButton primary" onClick={confirmShortCall}>
                  Yes, Continue
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
      <Modal open={warModalState.warningModalOpen}>
        <div className="warningModal">
          <CloseIcon
            className="profileCloseIcon"
            onClick={() => {
              SetWarModalState({ warningModalOpen: false });
            }}
          />
          <SadIcon className="astrologerProfileSadIcon" />
          <div>{warModalState.warningModalMessage}</div>
          <Button variant="contained" className="warningModalButton" onClick={similarAstrologer}>
            <span>Find Similar Astrologer</span>
          </Button>
          <div
            className="warningModalSkip"
            onClick={() => {
              moengage("PROFILE_PAGE_SKIP_CLICKED");
              SetWarModalState({ warningModalOpen: false });
            }}>
            Skip and continue browsing
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AstrologerCallDialog;
