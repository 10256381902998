import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moengage from "../../../../common/moengage";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { format } from "date-fns";

import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import Phone from "@material-ui/icons/Phone";
import ChatIcon from "@material-ui/icons/Chat";
import PhoneDisabled from "@material-ui/icons/PhoneDisabled";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Favorite from "@material-ui/icons/Favorite";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import TimerIcon from "@material-ui/icons/Schedule";
import theme from "../../../../theme/App.theme";
import { ReactComponent as OfferStamp } from "./offer.svg";
import AstrologerUtils, { isChatEnabled } from "./AstrologerUtils";
import defaultImage from "../../../../common/default_profile_image.svg";
import FA_localStorage from "../../../../common/util/FA_localStorage";
import axios from "../../../../common/interceptor";
import sentryError from "../../../../common/sentryError";
import BrowserUtils from "./UAScripts";
import Star from "@material-ui/icons/Star";
import { MIN_BALANCE_MINS } from "../../../../app.config";

const useStyles = makeStyles({
  astrologerCardCTA: {
    zIndex: "11",
    background: theme.palette.green.main,
    color: "#fff",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "5px 15px",
    borderRadius: "20px",
    whiteSpace: "nowrap",
    minWidth: "80px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
      "& svg": {
        fontSize: "16px",
      },
    },
    "&:hover": {
      background: theme.palette.green.main,
    },
  },
  audio: {
    height: 36,
    width: "100%",
  },
  iconFavorite: {
    fill: "#f44336",
    top: "48px",
    right: "12px",
    position: "absolute",
  },
  iconFavoriteDisabled: {
    fill: "#f2d3a3",
    top: "48px",
    right: "12px",
    position: "absolute",
  },
  callCTABlock: {
    width: "calc(100% - 90px)",
    position: "absolute",
    right: "0",
    top: "0",
    height: "calc(100% - 32px)",
    textAlign: "right",
    padding: "8px 8px 8px 0",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    borderRadius: "0 12px 12px 0",
  },
  callCTABlockFavourite: {
    background: "linear-gradient(40deg, transparent 78%, #cacaca 79%,#cccccc 79%,#ec0060 80%, #ec0060)",
  },
  statusCaption: {
    display: "inline-block",
    paddingTop: "0px",
    paddingRight: "4px",
    verticalAlign: "middle",
    maxWidth: "calc(100% - 116px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  statusCaptionHome: {
    display: "inline-block",
    paddingTop: "0px",
    paddingRight: "4px",
    verticalAlign: "middle",
    maxWidth: "calc(100% - 66px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardColLeft: {
    marginRight: "10px",
  },
  astrologerCardDisCTA: {
    zIndex: "11",
    background: "#f2f2f2",
    fontSize: "11px",
    color: "#bbbbbb",
    padding: "5px 15px",
    borderRadius: "20px",
    marginRight: "5px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
      "& svg": {
        fontSize: "14px",
      },
    },
  },
  astrologerCardJoinCTA: {
    zIndex: "11",
    background: "#fff0",
    fontSize: "0.8rem",
    padding: "5px 15px",
    color: "#ffffff",
    fontWeight: "bold",
    borderRadius: "20px",
    marginLeft: "5px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
      "& svg": {
        fontSize: "18px",
      },
    },
    "&:hover": {
      background: "#f07c38",
    },
  },
  onSimilarClass: {
    zIndex: "10",
  },
  name: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    display: "block",
    paddingRight: 64,
    width: "100%",
    alignItems: "center",
    color: "#ee600d",
    whiteSpace: "nowrap",
    paddingBottom: "4px",
    paddingTop: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
  },
  astrologerPlace: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "30%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.8rem",
  },
  message: {
    color: "#3c3c3c",
    fontSize: "0.7rem",
    fontWeight: "bold",
    width: "74px",
    textAlign: "center",
    display: "block",
    position: "absolute",
    top: "64px",
    left: "10px",
    zIndex: "4",
    background: "#ffff00",
    padding: "2px 0",
    borderRadius: "0 0 16px 16px",
    borderTop: "2px solid #fff",
  },
  marginB5: {
    marginBottom: "5px",
  },
  noReviews: {
    textAlign: "center",
    fontSize: "0.7rem",
  },
  verified: {
    width: "14px",
    transform: "translate(-2px,-1px)",
    verticalAlign: "middle",
    color: "#ee600d",
  },
  expertise: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    fontSize: "0.9rem",
    color: "#212121",
    fontWeight: "500",
    marginTop: "-6px",
    paddingRight: "64px",
  },
  langExpertise: {
    color: "#212121",
    display: "block",
    overflow: "hidden",
    maxWidth: "95%",
    paddingTop: "0px",
    whiteSpace: "nowrap",
    marginBottom: "8px",
    textOverflow: "ellipsis",
    opacity: "0.8",
    marginTop: "-4px",
  },
  langExpertiseMain: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    paddingTop: "0px",
    maxWidth: "95%",
    fontSize: "0.9rem",
    marginTop: 4,
    color: "#212121",
  },
  notifyMe: {
    background: "#9c9c9c",
    color: "#fff",
    "&:hover": {
      background: "#9c9c9c",
    },
    "&:hover svg": {
      animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97) both`,
    },
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0)" },
    "15%": { transform: "rotate(10deg)" },
    "30%": { transform: "rotate(-10deg)" },
    "45%": { transform: "rotate(5deg)" },
    "60%": { transform: "rotate(-5deg)" },
    "75%": { transform: "rotate(3deg)" },
    "85%": { transform: "rotate(-3deg)" },
    "92%": { transform: "rotate(1deg)" },
    "100%": { transform: "rotate(0)" },
  },
});

const AstrologerDetailsComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const family = props.family;
  const offerDiscount = props.offerDiscount;
  const cardType = props.type;
  const isLoggedIn =
    (!localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined") === false;

  /**Test presets */

  // family.reviewsData.count = 5;
  // family.reviewsData.rating = (((Math.random() * 100000) % 4) + 1).toFixed(1);
  // if (family.id == 1) {
  //   family.free_minutes = 5;
  // } else {
  //   family.away_until_mins = Math.round((Math.random() * 10000) % 240);
  // }
  //family.is_favourite = Math.random() * 10 > 5;
  // family.verified = Math.random() * 10 > 2;

  /**Test presets */

  const strikeStyle = { color: "#5d5d5d", fontWeight: "400", fontSize: "0.8rem" };
  const offerStampStyle = { fill: "#795548", width: 36, height: 36 };

  const astrologerProfile = () => {
    window.stopAllAudio();
    if (props.isSearch) {
      onCTAClick();
    }
    if (props.openProfile) {
      props.openProfile(family);
    } else {
      history.push({
        pathname: "/astrologer/" + family.profileUrl,
        state: {
          offerDiscount: offerDiscount,
          tracker: {
            screen: props.isSearch ? "search" : "home",
            rank: family.rank || "NA",
          },
        },
      });
    }
  };
  const stopPropagation = (e) => {
    e && e.stopPropagation();
    window.stopAllAudio();
  };
  const onCTAClick = (e) => {
    e && stopPropagation(e);
    if (window.location.pathname === "/") moengage("LIST_PAGE_CALL_CTA");
    else if (window.location.pathname.startsWith("/search")) {
      moengage("SEARCH_PAGE_CALL_CTA");
    } else if (window.location.pathname.startsWith("/similar-astrologer")) {
      moengage("SIMILAR_ASTROLOGER_CALL_CTA");
    }
    history.push({
      pathname: "/astrologer/" + family.profileUrl,
      state: {
        tracker: {
          screen: props.isSearch ? "search" : "home",
          rank: family.rank || "NA",
        },
      },
      isCTAClick: false,
    });
  };
  const onCTAClickDialog = (e, extra) => {
    stopPropagation(e);
    if (isLoggedIn) {
      if (BrowserUtils.hasUserProfile()) {
        props.handleCall(family, cardType, extra || { source: "astrologer_card" });
        if (extra && extra.booking) {
          trackActions("fa_booking_intent", cardType === "promotional" ? "promotional" : "main");
        } else {
          trackActions("fa_consult_intent", cardType === "promotional" ? "promotional" : "main");
        }
      } else {
        window.location.href = "/userprofile?pathname=" + window.location.pathname;
      }
    } else {
      window.location.href = "/login?pathname=" + window.location.pathname;
    }
  };

  const doNothing = (e) => {
    stopPropagation(e);
  };

  const clickNotifyMe = (e) => {
    stopPropagation(e);
    props.handleNotify(family.id, "add");
    trackActions("fa_button_notify", "add");
  };

  const clickRemoveNotifyMe = (e) => {
    stopPropagation(e);
    props.handleNotify(family.id, "remove");
    trackActions("fa_button_notify", "remove");
  };

  const getAwayCaption = (status, awayuntiltime) => {
    if (status === "away") {
      return formatAwayCaption(status, awayuntiltime);
    } else {
      return statusCaption(status);
    }
  };

  const formatAwayCaption = (status, awayuntiltime) => {
    /* away for minutes adjustment */
    //console.log("arun", awayuntiltime);
    let now = new Date();
    now.setSeconds(0);
    awayuntiltime = awayuntiltime * 1000;

    if (new Date(awayuntiltime).getTime() <= now.getTime() || awayuntiltime === null) {
      return upperCaseWords(status);
    } else {
      const away = new Date(awayuntiltime);

      if (away.getDate() === now.getDate()) {
        return "is away till " + format(away, "hh:mm a");
      } else {
        return "is away till " + format(away, "MMM dd, hh:mm a");
      }
    }
  };

  const statusCaption = (status) => {
    if (status === "busy") return "is online";
    else return "is " + status.toLowerCase();
  };

  const upperCaseWords = (str) => {
    str = str.toLowerCase();
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
      return s.toUpperCase();
    });
  };

  const [isFavActive, setIsFavActive] = useState(family.is_favourite);

  const onFavorite = (e) => {
    stopPropagation(e);
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + window.location.pathname;
    } else {
      var sUrl = "";
      props.showLoading(true);
      isFavActive ? (sUrl = "/favourite/remove") : (sUrl = "/favourite/add");

      if (!isFavActive) {
        moengage("FAVORITE_CLICKED");
      }
      const astName =
        family.salutation && family.name.indexOf(family.salutation) < 0
          ? upperCaseWords([family.salutation, family.name].join(" "))
          : upperCaseWords(family.name);
      axios()
        .post(sUrl, { astrologerId: family.id })
        .then((res) => {
          if (res.data.success) {
            props.showMessage(
              isFavActive ? "Removed " + astName + " from Favorites" : "Added  " + astName + " to Favorites"
            );
            //props.handleFavourite(family.id, !isFavActive);
            setIsFavActive(!isFavActive);
          } else {
            props.showMessage(res.data.message);
          }
          props.showLoading(false);
        })
        .catch((err) => {
          sentryError("ASTROLOGER_PROFILE_ONFAVOURITE", err);
          props.showMessage("Error occured, Please try again");
          props.showLoading(false);
        });
    }
    trackActions("fa_button_favourite", isFavActive ? "remove" : "add");
  };

  const trackActions = (type, label) => {
    moengage(type);
    const data = family;
    let ast_rating = data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: props.isSearch ? "search" : "home",
      p_label: label,
      p_content: data.profileUrl,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience.split(" ")[0],
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.price.split("/")[0],
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  const trackPlay = () => {
    trackActions("fa_ast_msg_play", "NA");
  };

  const isOfferMessageShow = () => {
    /**
     * Show the free minutes offer to all users, if it's available
     */
    return family.free_minutes && family.free_minutes > 0;
  };

  const getActiveLangList = () => {
    const langs = [];
    if (parseInt(localStorage.phone) > 0 && props.currentFilter) {
      const langList = props.currentFilter[0].values;
      langList.map((lang) => {
        if (lang.selected) {
          langs.push(lang.text);
        }
      });
    }
    return langs;
  };
  const userLangStyle = { color: "#ee600d", fontWeight: 400 };
  const formatUserLang = (lang) => {
    const uLangs = getActiveLangList();
    return uLangs.indexOf(lang) >= 0 ? <b style={userLangStyle}>{lang}</b> : lang;
  };
  const stepMargin = { margin: "16px 9px 0 0" };
  const expStyleLocal = { color: "#0c0c0c", float: "left", paddingLeft: 4 };

  const formatSecondaryLanguages = (languages) => {
    const user = [],
      other = [];
    const uLangs = getActiveLangList();
    languages.map((language) => {
      if (uLangs.indexOf(language) >= 0) return user.push({ user: language });
      else return other.push({ other: language });
    });
    const list = user.length > 0 ? [user, other] : [other];
    return list.map((lang, l) =>
      lang.map((u, i) => (
        <span style={{ color: u.user ? "#ee600d" : "#212121" }}>
          {i > 0 || l > 0 ? ", " : ""}
          {u.user || u.other}
        </span>
      ))
    );
  };

  const spacerStyle = { marginTop: -8, paddingBottom: 4 };

  return (
    <div className={[family.visible || props.isSearch ? "visible" : "hidden", family.status].join(" ")}>
      {cardType === "promotional" && offerDiscount > 0.0 ? <></> : <></>}
      <div
        className={clsx(
          "astrologerDetailsWrap",
          family.status,
          family.masked,
          !BrowserUtils.isiOSDevice && family.media && family.media.audio ? "has-audio" : ""
        )}>
        {(family.reviewsData.count || 0) <= 3 ? <div className="newAstrologer"> New</div> : <></>}
        <Grid container onClick={astrologerProfile}>
          <Grid item className={classes.cardColLeft}>
            <Avatar
              alt={family.name}
              imgProps={{ loading: "lazy" }}
              src={family.imageUrl ? AstrologerUtils.createThumbImageURI(family.id) : defaultImage}
            />
            <div className="flexWrapDiv">
              {/* <div className="astrologerReview">
                <Rating value={family.reviewsData.rating} readOnly size="small" />
              </div> */}
              <div className="astrologerRating">
                {family.reviewsData.rating > 0 ? (
                  <span>
                    {family.reviewsData.rating.toFixed(1)}
                    <b>
                      <Star />
                    </b>
                  </span>
                ) : (
                  "NA"
                )}
                {/* {family.reviewsData.rating ? <small>({family.reviewsData.count})</small> : <></>} */}
              </div>
            </div>
            {/* <div className={classes.noReviews}>Not enough Reviews</div> */}
          </Grid>
          <Grid item xs={9}>
            <div className="astrologerDetails">
              {isOfferMessageShow() ? (
                <span className={clsx(classes.message, "freemin")}>{family.free_minutes} min FREE</span>
              ) : (
                <> </>
              )}
              {/* <div>{family.away_until_mins}</div> */}
              <span className={clsx(classes.name, "n-title")}>
                {/* {family.verified ? <VerifiedUser className={classes.verified} /> : <></>} */}
                {family.salutation && family.name.indexOf(family.salutation) < 0
                  ? upperCaseWords([family.salutation, family.name].join(" "))
                  : upperCaseWords(family.name)}
              </span>
              <div style={spacerStyle}>
                <div
                  className={clsx("astrologerProfileImage astrologerProfileImageRed", {
                    astrologerProfileImageGreen: family.status === "online" || family.status === "busy",
                  })}></div>{" "}
                &nbsp;
                <span
                  className={clsx(
                    props.isSearch ? classes.statusCaption : classes.statusCaptionHome,
                    "status-caption"
                  )}>
                  {family.status === "online" ? "is online" : getAwayCaption(family.status, family.away_until)} now
                </span>
              </div>
              {/* <span className={classes.astrologerPlace}>- Kerala</span> */}
              {offerDiscount > 0 ? (
                <div
                  className={"offerStamp"}
                  onClick={(e) => {
                    props.handleOfferIcon(e, offerDiscount);
                    window.stopAllAudio();
                  }}>
                  <OfferStamp style={offerStampStyle} />
                  <span>
                    {offerDiscount}
                    <small>%</small>
                  </span>
                </div>
              ) : (
                <></>
              )}
              <span className={classes.langExpertiseMain}>
                {family.languages && family.languages[0] && (
                  <>
                    {window.innerWidth <= 324 ? "Language" : "Preferred language"}:{" "}
                    {formatUserLang(family.languages[0])}
                  </>
                )}
              </span>
              <span className={classes.langExpertise}>
                {family.languages && family.languages.length > 1 && <span>Also speaks&nbsp;</span>}
                {family.languages && family.languages.length > 1 ? (
                  formatSecondaryLanguages(family.languages.slice(1))
                ) : (
                  <>&nbsp;</>
                )}
              </span>
              <div
                className={clsx(
                  isFavActive === "favourite" ? classes.callCTABlockFavourite : null,
                  classes.callCTABlock
                )}>
                {!props.isSearch && isFavActive === true ? (
                  <Favorite className={clsx(classes.iconFavorite, "fav-icon")} onClick={onFavorite} />
                ) : !props.isSearch ? (
                  <Favorite className={clsx(classes.iconFavoriteDisabled, "fav-icon-dis")} onClick={onFavorite} />
                ) : (
                  ""
                )}
                <div style={stepMargin}>
                  <span style={expStyleLocal}>
                    <b>Exp:</b> {family.experience}
                  </span>
                  {family.price ? (
                    <span className="rupee-symbol price-group">
                      ₹{family.perMinutePrice * MIN_BALANCE_MINS} for {MIN_BALANCE_MINS} min
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div className="ast-card-ctabar">
          {!props.isSearch && (
            <div className="item">
              <Button
                className={clsx(classes.astrologerCardJoinCTA, family.status, "book")}
                onClick={(e) => onCTAClickDialog(e, { source: "astrologer_card", booking: true })}
                startIcon={props.isSearch ? null : <TimerIcon />}
                disabled={family.bookingSlots ? false : true}>
                Book consultation
              </Button>
            </div>
          )}
          {(family.status === "offline" || family.status === "away") && props.isNotifiedEnable ? (
            <div className="item">
              <Button
                className={clsx(classes.astrologerCardJoinCTA, family.status)}
                onClick={props.isSearch ? onCTAClick : clickRemoveNotifyMe}
                startIcon={props.isSearch ? null : <NotificationsOffIcon />}>
                {props.isSearch ? "View profile" : "Cancel notification"}
              </Button>
            </div>
          ) : (family.status === "offline" || family.status === "away") && !props.isNotifiedEnable ? (
            <div className="item">
              <Button
                className={clsx(classes.astrologerCardJoinCTA, family.status, classes.notifyMe)}
                onClick={props.isSearch ? onCTAClick : clickNotifyMe}
                startIcon={props.isSearch ? null : <NotificationsActiveIcon />}>
                {props.isSearch ? "View profile" : "Notify when online"}
              </Button>
            </div>
          ) : family.status === "away" || family.status === "busy" ? (
            <div className="item">
              <Button
                className={clsx(classes.astrologerCardJoinCTA, family.status)}
                onClick={props.isSearch ? onCTAClick : doNothing}
                startIcon={props.isSearch ? null : <PhoneDisabled />}>
                {props.isSearch
                  ? "View profile"
                  : family.status === "busy"
                    ? "On consultation"
                    : upperCaseWords(family.status)}
              </Button>
            </div>
          ) : (
            <div className="item">
              <>
                {isChatEnabled() && family.chargesChat != null ? (
                  <>
                    <Button
                      className={clsx(
                        family.chargesAudio ? classes.astrologerCardCTA : classes.astrologerCardDisCTA,
                        family.status
                      )}
                      startIcon={
                        props.isSearch ? null : (
                          <>
                            <ChatIcon />
                            <Phone />
                          </>
                        )
                      }
                      onClick={props.isSearch ? onCTAClick : onCTAClickDialog}>
                      {props.isSearch ? "View profile" : "Consult now"}
                    </Button>
                  </>
                ) : (
                  <Button
                    className={clsx(
                      family.chargesAudio ? classes.astrologerCardCTA : classes.astrologerCardDisCTA,
                      family.status
                    )}
                    startIcon={props.isSearch ? null : <Phone />}
                    onClick={props.isSearch ? onCTAClick : onCTAClickDialog}>
                    {props.isSearch ? "View profile" : "Consult now"}
                  </Button>
                )}
              </>
            </div>
          )}
        </div>
      </div>
      {!BrowserUtils.isiOSDevice && family.media && family.media.audio && (
        <div className="ast-audio">
          <audio
            onPlay={trackPlay}
            controls
            className={classes.audio}
            controlslist="nodownload"
            preload="none"
            id={"__faAudio" + family.id}>
            <source src={family.media.audio.url} type="audio/mpeg"></source>
          </audio>
        </div>
      )}
    </div>
  );
};
export default AstrologerDetailsComponent;
