const introData = [
  {
    heading: "Welcome! <br/><br/>",
    text: "Welcome to Findastro powered by India's leading astrology service provider.",
  },
  {
    heading: "Get <u>50% CASHBACK</u> on first consultation",
    text: "Do your first consultation for any amount and get 50% cashback.",
  },
  {
    heading: "Get advice from highly experienced astrologers",
    text:
      "We have a hand picked list of astrologers from all over India, experienced in different astrology practices like Vedic Numerology, Vastu, KP, Tarot, etc.",
  },
  {
    heading: "65 different havans & pujas",
    text: "Get havan / puja done at your home or via video&nbsp;call by the most experienced priests.",
  },
  {
    heading: "Astrologer busy? <u>Book consultation</u> for later",
    text:
      "If your astrologer is unavailable at the moment or you wish to consult at a time of your convenience, you may book now and consult later.",
  },
  {
    heading: "Trusted by 600,000 + users",
    text: "Welcome to Findastro, trusted by users from all over the world.",
  },
];
export default introData;
