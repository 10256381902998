import React, {Component} from "react";
import axios from "../../../common/interceptor";
import moengage from "../../../common/moengage";
import sentryError from "../../../common/sentryError";

import "./UserProfile.css";
import FamilyUserProfileBoxComponent from "./FamilyUserProfileBox.component";
import HelmetComponent from "../../../common/HelmetComponent.component";
import SideDrawerComponent from "../../user/astrologer/components/SideDrawer.component";
import Snackbar from "../../../common/SnackBarMessage";

import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@material-ui/core/Avatar";

export default class FamilyUserProfiles extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    userDetails: {},
    familyDetails: [],
    isLoading: true,
    drawerOpen: false,
  };

  componentDidMount = () => {
    moengage("FAMILY_PAGE_VIEWED");
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + this.props.location.pathname;
    } else {
      axios()
        .post("/profile/all")
        .then((res) => {
          if (res.data.success) {
            if (!res.data.user.email) {
              window.location.href = "/userprofile";
            } else {
              this.setState({
                userDetails: res.data.user,
                familyDetails: res.data.family,
                isLoading: false,
              });
            }
          } else {
            this.showError(res.data.message);
            this.setState({isLoading: false});
          }
        })
        .catch((err) => {
          sentryError("FAMILY_PROFILES", err);
          this.showError("Error occured, Please try again");
          this.setState({isLoading: false});
        });
    }
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onAddUser = () => {
    moengage("FAMILY_PAGE_ADD_CHILD_CLICKED");
    window.location.href = "/childprofile/add";
  };

  render() {
    return (
      <div id="familyParentDiv">
        <HelmetComponent
          title="Users Profile at Findastro"
          url="https://www.findastro.com/familyuserprofiles"
          description="Users Profile at Findastro-Look the user Profile at Findastro"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        <div className="familyTopArea">
          <Avatar
            variant="rounded"
            className="familyArrowIcon"
            onClick={() => {
              this.setState({drawerOpen: true});
            }}>
            <MenuIcon />
          </Avatar>
          <div className="familyChildTopArea">
            <p>My</p>
            <h3 id="familyText">Family</h3>
          </div>
        </div>
        {typeof this.state.userDetails.name !== "undefined" ? (
          <FamilyUserProfileBoxComponent family={this.state.userDetails} key="primaryUser" isPrimary={true} />
        ) : (
          <></>
        )}
        {this.state.familyDetails.length > 0 ? (
          <>
            {this.state.familyDetails.map((family) => (
              <FamilyUserProfileBoxComponent family={family} key={family.id} isPrimary={false} />
            ))}
          </>
        ) : (
          <Button id="familyProfileSaveButton" variant="contained" onClick={() => (window.location.href = "/")}>
            SHOW ASTROLOGERS
          </Button>
        )}
        <div id="addUser">
          <PersonAddIcon onClick={this.onAddUser} />
        </div>
        <SideDrawerComponent
          drawerOpen={this.state.drawerOpen}
          onDrawerClose={() => {
            this.setState({drawerOpen: false});
          }}
        />
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}
