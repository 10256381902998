import React from "react";
import ReactDOM from "react-dom";
import store from "./store/store";
import {Provider} from "react-redux";
import {HelmetProvider} from "react-helmet-async";

import "./index.css";
import ErrorBoundary from "./common/ErrorBoundary";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "./theme/App.theme";

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <ThemeProvider theme={Theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
