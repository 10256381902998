import React from "react";
import clsx from "clsx";

const FilterDrawerCategoriesComponent = (props) => {
  const showActive = (e) => {
    let filterString = props.optionType + "||" + props.option.text.replace(/\s/g, "").toLowerCase();
    if (props.isActive) {
      filterString = "remove||" + filterString;
    } else {
      filterString = "add||" + filterString;
    }

    props.onSelectFilters(filterString);
  };

  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  return (
    <div
      className={clsx("filterOptionText", {
        filterOptionTextActive: props.isActive,
      })}
      onClick={showActive}>
      {capitalizeFirstLetter(props.option.text)}
    </div>
  );
};

export default FilterDrawerCategoriesComponent;
