import React, {useEffect, useState} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import SearchIcon from "@material-ui/icons/Search";
import {Dialog, Grid, InputBase, makeStyles} from "@material-ui/core";
import SinglePujaDetails from "./SinglePujaItem.component";
import QueryString from "../../../common/util/QueryString";
import ArrowIcon from "@material-ui/icons/ArrowBack";
import {ASTROLOGER_RELATIONS_NUMBER, PUJA_SUPPORT_NUMBER, SUPPORT_NUMBER} from "../../../app.config";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import {ReactComponent as PaymentSuccess} from "../../../common/pay-success.svg";
import {isUserLoggedIn} from "../../user/astrologer/components/AstrologerUtils";

const PujaPayment = (props) => {
  const txid = window.location.pathname.split("/").pop();
  const [paymentStatus, setPaymentStatus] = useState(null);
  useEffect(() => {
    if (sessionStorage["visited-" + txid]) window.location.href = "/";
    else {
      axios()
        .post("/puja/transaction/" + txid, {
          token: QueryString().t,
        })
        .then((res) => {
          setPaymentStatus(res.data);
          if (res.data.success) {
            trackClick("fa_remedy_book_success", "NA", res.data.data.puja.puja_id, res.data.data);
            if (isUserLoggedIn()) {
              trackClick("fa_remedy_book_success_reg", "NA", res.data.data.puja.puja_id, res.data.data);
            } else {
              trackClick("fa_remedy_book_success_unreg", "NA", res.data.data.puja.puja_id, res.data.data);
            }
            sessionStorage["visited-" + txid] = true;
          }
        });
    }
  }, []);

  const trackClick = (type, content, label, data) => {
    try {
      moengage(type);
      const payload = {
        p_screen: "remedy",
        p_label: label,
        p_content: content,
        p_value: data.price.puja_price - data.price.fa_discount,
        value: data.price.puja_price - data.price.fa_discount,
        currency: "INR",
        p_ast_exp: "NA",
        p_ast_rating: "NA",
        p_ast_sort: "NA",
        p_ast_rank: "NA",
        p_ast_fees: "NA",
        p_usr_gender: BrowserUtils.CurrentUser().gender,
        p_usr_age: BrowserUtils.CurrentUser().age.toString(),
        p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
      };
      window.FAEventLog(type, payload);
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = (e) => {
    window.location.href = "/";
  };
  const goHome = (e) => {
    const ref = QueryString().ref;
    if (ref) {
      window.location.href = ref;
    } else {
      window.location.href = "/";
    }
  };

  const classes = makeStyles({
    orderDetails: {
      textAlign: "center",
      marginBottom: 24,
      "& > div": {
        marginBottom: 4,
      },
    },
  })();

  const buttonStyle = {
    textAlign: "center",
    position: "absolute",
    width: "100%",
    left: "50%",
    bottom: "0",
    transform: "translateX(-50%)",
  };
  const pricingStyle = {marginLeft: 10, color: "#00c180"};
  const orderStyle = {marginTop: 24, textAlign: "center", fontSize: "1rem"};
  const detailsStyle = {marginTop: 48, marginBottom: 60, textAlign: "left"};
  const pageStyle = {
    backgroundColor: "#fbead0",
    overflowY: "auto",
    height: "calc(100% - 64px)",
    marginTop: 72,
    borderRadius: 0,
    padding: "0 20px",
  };
  const numberFormat = (val) => {
    return (val || 0).toFixed(2);
  };
  return (
    <div className="puja-dialog dialog">
      <div className="title" style={{backgroundColor: "#ee600d"}}>
        <ArrowIcon onClick={goBack} />
        &nbsp;&nbsp;
        {paymentStatus && paymentStatus.success === true
          ? paymentStatus.message
          : paymentStatus && paymentStatus.success === false
          ? "Payment Failed"
          : "Please wait.."}
      </div>
      <div className="content puja" style={pageStyle}>
        <div>
          {paymentStatus && paymentStatus.success === false && (
            <>
              {paymentStatus.message}
              <br />
              <br />
              For any assistance, you can contact our customer support team on this number: <b>{PUJA_SUPPORT_NUMBER}</b>
            </>
          )}
        </div>
        {paymentStatus && paymentStatus.success === true && (
          <div>
            <div style={{marginBottom: 16, marginTop: 24, textAlign: "center"}}>
              <PaymentSuccess width="40%" />
              <div
                style={{
                  color: "#3fae89",
                  padding: "16px",
                  fontWeight: "700",
                  fontSize: "1rem",
                }}>
                Payment Success!
              </div>
            </div>
            {/* <div>
              Puja: <b>{paymentStatus.data.puja.name}</b>
            </div>
            <div>Duration: {paymentStatus.data.duration} mins.</div>
            <div>Priest: {paymentStatus.data.priest}</div>
            <div>Date: {paymentStatus.data.date}</div>
            <div>Time: {paymentStatus.data.time}</div>
            <div>Video: {paymentStatus.data.meet_link}</div>
            {paymentStatus.data.prasad && <div>Prasad: {paymentStatus.data.prasad_opted ? "Opted" : "Not opted"}</div>} */}
            <div style={orderStyle}>
              <b>Order details:</b>
            </div>
            <div className={classes.orderDetails}>
              <div>Order Number: {paymentStatus.data.order_number || "Not available"}</div>
              {/* <div>Puja price: ₹{numberFormat(paymentStatus.data.price.puja_price)}</div>
              <div>Findastro discount: ₹{numberFormat(paymentStatus.data.price.fa_discount)}</div>
              {paymentStatus.data.price.coupon_discount > 0 && (
                <div>
                  Coupon discount: ₹{numberFormat(paymentStatus.data.price.coupon_discount)} (
                  {paymentStatus.data.price.coupon_discount_percentage * 100}%)
                </div>
              )}
              <div>Prasad delivery: ₹{numberFormat(paymentStatus.data.price.prasad_price)}</div>
              <div>GST: ₹{numberFormat(paymentStatus.data.price.gst)}</div> */}
              <div>
                Total amount paid:
                <span style={pricingStyle}>
                  <b>₹{numberFormat(paymentStatus.data.price.total_paid)}</b>
                </span>
              </div>
              <div style={detailsStyle}>
                Our support executive will call you to confirm the date of the puja/havan within an hour. To know the
                status of your booking instantly, you may also contact us on <b>{PUJA_SUPPORT_NUMBER}</b>.
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={buttonStyle} className="action">
        <button onClick={goHome} style={{width: "100%", height: 56}}>
          OK
        </button>
      </div>
    </div>
  );
};

export default PujaPayment;
