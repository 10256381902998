import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
  marquee: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: '200px',
    padding: '2px 4px',
    containerType: 'inline-size',
    '& > *': {
      paddingRight: 'calc(100cqw + 20px)',
      // paddingRight: '20px',
      animation: `$marquee 15s 2s linear infinite both`,
    }
  },
  '@keyframes marquee': {
    '0%': {
      transform: 'translateX(0px)',
    },
    '70%': {
      transform: 'translateX(min(100cqw - 100%, 0px))',
    },
    '70.01%': {
      transform: 'translateX(0px)',
    },
    '100%': {
      transform: 'translateX(0px)',
    },
  }
})

const Marquee = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.marquee}><span>{children}</span></div>
    </>
  )
}

export default Marquee