import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import moengage from "../../../../common/moengage";
import FA_localStorage from "../../../../common/util/FA_localStorage";
import axios from "../../../../common/interceptor";
import BlastConfetti from "../../../misc-screens/components/Confetti.component";
import BrowserUtils from "./UAScripts";

const CouponRedeemDialog = (props) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const labelStyle = {
    display: "block",
    textAlign: "center",
    fontSize: "2.4rem",
    color: "rgb(241, 124, 56)",
    fontWeight: "400",
    lineHeight: "2.4rem",
    margin: "8px 0",
  };

  const onRedeem = () => {
    if (!FA_localStorage("phone") || FA_localStorage("phone") === "") {
      props.showError("Please login to redeem a coupon!");
      setTimeout(() => {
        window.location.href = "/login?pathname=" + window.location.pathname;
      }, 2000);
      //if not logged in, login first
    } else if (!couponSuccess) {
      props.showLoading(true);
      axios()
        .post("/user/redeem-coupon", {coupon: couponValue})
        .then((res) => {
          if (res.data.success === true) {
            //auto adjust wallet balance
            res.data.discount_val = parseInt(res.data.discount_val);
            if (res.data.discount_type === "points") {
              /** event tracking */
              const points = res.data.discount_val || 0;
              const credit_payload = {
                p_screen: "wallet",
                p_label: "",
                p_content: "NA",
                p_value: Number(points),
                value: Number(points),
                currency: "INR",
                p_ast_exp: "",
                p_ast_rating: "",
                p_ast_sort: "NA",
                p_ast_rank: "",
                p_ast_fees: "",
                p_usr_gender: BrowserUtils.CurrentUser().gender,
                p_usr_age: BrowserUtils.CurrentUser().age.toString(),
                p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
              };
              window.FAEventLog("fa_wallet_credit", credit_payload);
              moengage("fa_wallet_credit");
              /** event tracking */

              let balance = parseInt(FA_localStorage("walletBalance")) || 0;
              balance += res.data.discount_val || 0;
              FA_localStorage("walletBalance", balance);
            }

            /** event tracking */
            const succ_payload = {
              p_screen: "home",
              p_label: res.data.discount_type,
              p_content: "NA",
              p_value: res.data.discount_val || 0,
              value: res.data.discount_val || 0,
              currency: "INR",
              p_ast_exp: "",
              p_ast_rating: "",
              p_ast_sort: "NA",
              p_ast_rank: "",
              p_ast_fees: "",
              p_usr_gender: BrowserUtils.CurrentUser().gender,
              p_usr_age: BrowserUtils.CurrentUser().age.toString(),
              p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
            };
            window.FAEventLog("fa_screen_coupon_success", succ_payload);
            moengage("fa_screen_coupon_success");

            setTitle("Coupon applied successfully!");
            setMessage({
              text: (
                <div>
                  {res.data.discount_type === "points" ? (
                    <div style={{marginTop: "10px", fontSize: "0.9rem", textAlign: "center"}}>
                      You are rewarded with points worth <b style={labelStyle}>₹{res.data.discount_val}</b>
                      Happy consulting!
                    </div>
                  ) : (
                    <div style={{marginTop: "10px", fontSize: "0.9rem", textAlign: "center"}}>
                      On your next call, you will get <b style={labelStyle}>{res.data.discount_val}% CASHBACK</b>
                      Happy consulting!
                    </div>
                  )}
                </div>
              ),
              type: "success",
            });
            setSuccess(true);
            setShowConfetti(true);
          } else {
            if (res.data.validation === "SAME_VALUE") {
              setMessage({
                text: (
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "0.9rem",
                      textAlign: "left",
                    }}>
                    <b>
                      Wow, lucky you! You have already got {res.data.value}% CASHBACK applicable on your next call.{" "}
                      <br />
                      <br />
                      Save this coupon for another consultation
                    </b>
                  </div>
                ),
              });
            } else if (res.data.validation === "LOW_VALUE") {
              setMessage({
                text: (
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "0.9rem",
                      textAlign: "left",
                    }}>
                    <b>
                      A higher CASHBACK of {res.data.current_offer}% is already applicable on your next call. <br />
                      <br />
                      Save this coupon worth {res.data.value}% CASHBACK for a later use.
                    </b>
                  </div>
                ),
              });
            } else {
              setMessage({
                text: <div>Invalid coupon code</div>,
                type: "error",
              });
            }
          }
          props.showLoading(false);
        })
        .catch((err) => {
          setMessage({
            text: <div>Unable to redeem coupon. Try later!</div>,
            type: "error",
          });
          props.showLoading(false);
        });
      /** event tracking */
      const credit_payload = {
        p_screen: "home",
        p_label: "",
        p_content: "NA",
        p_value: 0,
        value: 0,
        currency: "INR",
        p_ast_exp: "",
        p_ast_rating: "",
        p_ast_sort: "NA",
        p_ast_rank: "",
        p_ast_fees: "",
        p_usr_gender: BrowserUtils.CurrentUser().gender,
        p_usr_age: BrowserUtils.CurrentUser().age.toString(),
        p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
      };
      window.FAEventLog("fa_screen_coupon_init", credit_payload);
      moengage("fa_screen_coupon_init");
    } else {
      handleClose();
    }
  };
  const handleClose = () => {
    setSuccess(false);
    setValue("");
    setMessage({});
    setShowConfetti(false);
    props.handleClose();
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [couponValue, setValue] = useState("");
  const [redeemMessage, setMessage] = useState({});
  const [couponSuccess, setSuccess] = useState(false);
  const [dialogTitle, setTitle] = useState("Redeem coupon");

  return (
    <Dialog
      open={props.isStateOpen}
      className={"astrologerDialog"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className="dialog">
        <div className={"dialogHead"} style={{padding: "16px 32px"}}>
          {dialogTitle}
        </div>
        <div className={"dialogBody"} style={{padding: "16px 32px 8px 32px", textAlign: "left"}}>
          <div style={{display: couponSuccess ? "none" : "block", minWidth: "70vw", padding: "0 0 8px 0"}}>
            Enter the coupon code below
          </div>
          <input
            type="text"
            style={{
              display: couponSuccess ? "none" : "block",
              padding: "4px 8px",
              border: "1px dashed #ee600d",
              width: "100%",
              fontSize: "1.3rem",
              textTransform: "uppercase",
              backgroundColor: "#f1f1f1",
              color: "#323232",
            }}
            value={couponValue}
            onChange={handleChange}
          />
          <div className={redeemMessage.type}>{redeemMessage.text}</div>
        </div>
      </div>
      <DialogActions style={{justifyContent: "center", padding: "16px 0"}}>
        <Button
          className={"dialogButton secondary"}
          style={{display: couponSuccess ? "none" : "block"}}
          onClick={() => {
            moengage("REDEEM_CANCEL_CLICKED");
            handleClose();
          }}>
          Cancel
        </Button>
        <Button onClick={onRedeem} variant="contained" className={"dialogButton primary"}>
          {couponSuccess ? "Okay" : "Redeem coupon"}
        </Button>
      </DialogActions>
      <BlastConfetti showConfetti={showConfetti} />
    </Dialog>
  );
};
export default CouponRedeemDialog;
