import React, {Component} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import InfiniteScroll from "react-infinite-scroll-component";

import "./Search.css";
import Snackbar from "../../../common/SnackBarMessage";
import HelmetComponent from "../../../common/HelmetComponent.component";
import AstrologerDetailsComponent from "../../user/astrologer/components/Astrologer-Details.component";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

export default class SimilarSearch extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    isLoading: true,
    isOthersLoading: true,
    astrologersData: [],
    searchText: "",
    isFetching: false,
    hasMore: true,
    currentPage: 1,
    maxPages: 1,
    recommendedData: [],
    offerClaim: true,
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  requestAndFetchData = (page = 1) => {
    axios()
      .post("/astrologers/similar/" + this.props.match.params.url + "?page=" + page)
      .then((res) => {
        if (res.data.success) {
          this.setState({isLoading: false});
          if (res.data.data.length === 0) {
            axios()
              .post("/astrologers/all/d")
              .then((res) => {
                if (res.data.success) {
                  this.setState({recommendedData: res.data.data, isOthersLoading: false});
                } else {
                  this.showError(res.data.message);
                }
              })
              .catch((err) => {
                sentryError("SIMILAR_SEARCH_RECOMMENDED", err);
                this.showError("Something went wrong. please try again");
              });
          } else {
            if (localStorage.getItem("btkn") && localStorage.getItem("utkn") && page === 1) {
              this.setState({offerClaim: false});
              axios()
                .post("/offer-claim")
                .then((res) => {
                  if (res.data.success) {
                    this.setState({offerClaim: res.data.isNUser});
                  }
                })
                .catch((err) => {
                  sentryError("SIMILAR_SEARCH_OFFERCLAIM", err);
                });
            }
            this.setState({
              astrologersData: this.state.astrologersData.concat(res.data.data),
              isLoading: false,
              isFetching: false,
              currentPage: this.state.currentPage + 1,
              maxPages: 1,
            });
          }
        } else {
          this.showError(res.data.message);
          this.setState({isLoading: false});
        }
      })
      .catch((err) => {
        sentryError("SIMILAR_SEARCH_PAGELOAD", err);
        this.showError("Something went wrong. please try again");
        this.setState({isLoading: false});
      });
  };

  fetchMoreData = () => {
    if (this.state.currentPage > this.state.maxPages) {
      this.setState({hasMore: false});
      return;
    } else {
      this.setState({isFetching: true});
      this.requestAndFetchData(this.state.currentPage);

      if (this.state.currentPage >= this.state.maxPages) {
        this.setState({hasMore: false});
      }
    }
  };

  componentDidMount = () => {
    moengage("SIMILAR_PAGE_VIEWED");
    this.setState({searchText: this.props.location.pathname.split("/")[2]});
    this.requestAndFetchData();
  };

  render() {
    const astrologerLlist = this.state.astrologersData.map((family) => (
      <AstrologerDetailsComponent family={family} key={family.id} isSearch={true} offerClaim={this.state.offerClaim} />
    ));

    return (
      <div className="searchParentDiv">
        <HelmetComponent
          title="Similar Astrologers-Findastro"
          url="https://www.findastro.com/similar-astrologer"
          description="Similar Astrologers-Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        <div className="similarTopArea">
          <ArrowBackIcon className="searchArrowIcon" onClick={this.props.history.goBack} />
          <div className="similarChildTopArea">
            <p className="similarSearchText">Similar Astrologers</p>
          </div>
        </div>
        {this.state.isLoading ? (
          <div className="circle-loading extra-circle">
            <CircularProgress />
          </div>
        ) : this.state.astrologersData.length > 0 ? (
          <InfiniteScroll
            dataLength={this.state.astrologersData.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={
              this.state.isFetching && (
                <div className="circle-loading extra-circle2">
                  <CircularProgress />
                </div>
              )
            }>
            {astrologerLlist}
          </InfiniteScroll>
        ) : (
          <div>
            <Paper className="searchPageNotFoundPaper" elevation={3}>
              <div>No astrologers found.</div>
            </Paper>
            {this.state.isOthersLoading ? (
              <></>
            ) : (
              <>
                <div className="searchPageyoumightalsolikeText">Other Astrologers you might like</div>
                {this.state.recommendedData.map((astrologer) => (
                  <AstrologerDetailsComponent family={astrologer} key={astrologer.id} isSearch={false} />
                ))}
              </>
            )}
          </div>
        )}
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}
