import React, { Component } from "react";

import "./Consultation.css";
import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";
import moengage from "../../../common/moengage";

import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Phone from "@material-ui/icons/Phone";
import Avatar from "@material-ui/core/Avatar";
import AstrologerUtils from "../../user/astrologer/components/AstrologerUtils";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

export default class AstrologerConnectFail extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    showCallBackCTA: false,
    showSimilarAstCTA: false,
    astrologerURL: "",
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onCallBack = (e) => {
    e.stopPropagation();
    window.location.href = "/astrologer/" + this.props.location.state.astrologerURL;
  };

  onSimilarSearch = (e) => {
    e.stopPropagation();
    window.location.href = "/similar-astrologer/" + this.props.location.state.astrologerURL;
  };

  componentDidMount = () => {
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login";
    }
    moengage("CONSULTATION_FAILED");
    localStorage.removeItem("progress_call");
    //console.log("arun", this.props);
  };

  render() {
    if (!this.props.location.state || typeof this.props.location.state === "undefined") {
      window.location.href = "/";
    }
    return (
      <div className={[
        "astrologerConnectWrap",
        BrowserUtils.isNewUI() ? 'newUI' : ''
      ].join(' ').trim()}>
        <HelmetComponent
          title="Astrologer Connection Fail at Findastro"
          url="https://www.findastro.com/connect-fail"
          description="Astrologer Connection Fail at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        <CloseIcon className="waitingCloseIcon" onClick={() => (window.location.href = "/")} />
        <div style={{ marginTop: "24px", textAlign: "center", fontSize: "1.8rem" }}>Oops!</div>
        <Avatar
          src={AstrologerUtils.createThumbImageURI(this.props.location.state.astrologerId)}
          style={{ width: 56, height: 56, margin: "10px auto" }}
        />
        {this.props.location.state.message ? (
          <div className="astrologerConnectFailMessage">
            Astrologer <b>{this.props.location.state.astrologerName}</b> appears to be busy.
          </div>
        ) : (
          <></>
        )}
        <div style={{ textAlign: "center" }}>
          {this.props.location.state.showCallBackCTA ? (
            <>
              <Button
                variant="contained"
                startIcon={<Phone />}
                className="astrologerConnectBottoms"
                onClick={this.onCallBack}>
                <span>Call again</span>
              </Button>
              <div className="astrologerConnectOR">OR</div>
            </>
          ) : (
            <></>
          )}
          {/* {this.props.location.state.showSimilarAstCTA ? (
          <Link onClick={this.onSimilarSearch}>
            <div className="astrologerConnectCTA">Show similar astrologers</div>
          </Link>
        ) : (
          <></>
        )} */}
          <Button
            variant="contained"
            className="astrologerConnectBottoms"
            style={{ backgroundColor: "#ee600d" }}
            onClick={() => (window.location.href = "/")}>
            <span>Consult another astrologer</span>
          </Button>
        </div>
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}
