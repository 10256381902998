import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DiscourageUser from "../../../../common/discourage user2.jpg";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "../../../../common/interceptor";

const AppInstallBannerDialog = (props) => {
  const handleClose = () => {
    console.log("closing..");
  };

  const handleDownload = () => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get("utm_source") || "Findastro_web";
    const medium = params.get("utm_medium") || "installdialog";
    const campaign = params.get("utm_campaign") || "";
    const term = params.get("utm_term") || "";
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3D" +
      source +
      "%26utm_medium%3D" +
      medium +
      "%utm_campaign%3D" +
      campaign +
      "%utm_term%3D" +
      term;
  };

  /*const handleDownload = () => {
    axios()
      .post("/gpinstall")
      .then((res) => {
        if (res.data.success) window.location.href = res.data.url;
        else redirectFallback();
      })
      .catch((err) => {
        redirectFallback();
      });
  };

  const redirectFallback = () => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get("utm_source") || "Findastro_web";
    const medium = params.get("utm_medium") || "installdialog";
    const campaign = params.get("utm_campaign") || "";
    const term = params.get("utm_term") || "";
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3D" +
      source +
      "%26utm_medium%3D" +
      medium +
      "%utm_campaign%3D" +
      campaign +
      "%utm_term%3D" +
      term;
  };*/

  return (
    <div>
      <Dialog
        open={props.isStateOpen}
        onClose={handleClose}
        className={"astrologerDialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="dialog" style={{width: "100%", position: "relative"}}>
          <img
            alt="banner"
            src={props.image || DiscourageUser}
            style={{width: "100%", display: "block"}}
            onClick={handleDownload}
          />
          {props.handleClose ? (
            <CancelIcon
              onClick={props.handleClose}
              style={{position: "absolute", zIndex: "100", fill: "#d00101", right: "8px", top: "4px"}}
            />
          ) : (
            <></>
          )}
        </div>
      </Dialog>
    </div>
  );
};
export default AppInstallBannerDialog;
