export var ChatTypes;
(function (ChatTypes) {
  ChatTypes["SUC"] = "@suc";
  ChatTypes["MUC"] = "@muc";
})(ChatTypes || (ChatTypes = {}));
export var ConnectionStatus;
(function (ConnectionStatus) {
  ConnectionStatus["CONNECTED"] = "connected";
  ConnectionStatus["DISCONNECTED"] = "disconnected";
})(ConnectionStatus || (ConnectionStatus = {}));
