import {makeStyles} from "@material-ui/core";
import {useEffect, useState} from "react";
import {ReactComponent as PlayBttn} from "../../../common/play.svg";
import QueryString from "../../../common/util/QueryString";
import YoutubeEmbed from "./YoutubePlayer.component";
import CancelIcon from "@material-ui/icons/Cancel";
import moengage from "../../../common/moengage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import T2AEventTrack from "../../../common/T2AEventTrack";

const useStyle = makeStyles({
  galleryImageHolder: {
    height: 160,
    width: "100%",
    display: "block",
    overflow: "hidden",
    "overflow-x": "auto",
    whiteSpace: "nowrap",
    marginTop: 8,
  },
  galleryItem: {
    width: "100%",
    height: "160px",
    display: "inline-block",
    overflow: "hidden",
    marginRight: "0",
    borderRadius: "14px",
    position: "relative",
    "& img": {
      width: "100%",
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%) scale(1.77)",
    },
  },
  playButton: {
    width: "56px",
    height: "auto",
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: "10",
    transform: "translate(-50%,-50%)",
  },
  textHolder: {
    marginBottom: 24,
  },
  videoHolder: {
    marginBottom: 24,
  },
  audioPlayer: {
    height: 48,
    "& #__AUPlayer": {
      display: "block",
      width: "100%",
    },
  },
  instaStory: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    transform: "translate3d(0,0,10px)",
    zIndex: 1000,
    backgroundColor: "rgba(0,0,0,0.98)",
  },
  closeIcon: {
    fill: "#ff9800",
    left: "50%",
    width: "48px",
    mixBlendMode: "screen",
    bottom: "24px",
    height: "48px",
    opacity: "1",
    zIndex: "1002",
    position: "fixed",
    transform: "translateX(-50%)",
  },
});
const MessageGallery = (props) => {
  const classes = useStyle();
  const [currentVideo, setCurrentVideoID] = useState(null);
  const presetHash = window.location.hash;
  const showVideo = () => {
    setCurrentVideoID(extractVideoID(props.galleryData.video.url));
    if(BrowserUtils.isNewUI()) {
      trackeventData('Play video profile', props.astrologer)
    } else {
      trackActions("fa_gallery_view", "message.video", props.astrologer);
    }
  };

  const handleHashChange = () => {
    if (window.location.hash !== "#storyopen") {
      setCurrentVideoID(null);
    }
  };

  window.addEventListener("hashchange", handleHashChange, true);

  useEffect(() => {
    window.location.hash = currentVideo ? "storyopen" : presetHash;
  }, [currentVideo]);

  const trackActions = (type, label, data) => {
    moengage(type);
    let ast_rating = data.reviewsData && data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: "profile",
      p_label: label,
      p_content: data.profileUrl || "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience ? data.experience.split(" ")[0] : "NA",
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.price ? data.price.split("/")[0] : "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  const trackeventData = (label,data) => {
    const payload = {
      event: {
        name: 'Button Action',
        attributes: {
          'Screen Name': 'Astrologer profile',
          'Button Name': label,
          'Product': 'COT',
          'Astrologer ID' : data.id,
          'Astrologer Name' : data.name,
        }
      },
      extra: {
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra)
  };

  const extractVideoID = (url) => {
    const URLParams = QueryString(url);
    return URLParams.v;
  };
  return (
    <div className={classes.messageHolder}>
      {props.galleryData && props.galleryData.text && (
        <div className={classes.textHolder}>
          <div className="profile-astro-message">{props.galleryData.text}</div>
          <div className="profile-author">{props.author}</div>
        </div>
      )}
      {props.galleryData && props.galleryData.video && (
        <div className={classes.videoHolder}>
          <div className={classes.galleryItem} onClick={showVideo}>
            <img src={props.galleryData.video.thumb} alt="thumbnail" />
            {<PlayBttn className={classes.playButton} />}
          </div>
        </div>
      )}
      {props.galleryData && props.galleryData.audio && (
        <div className={classes.audioHolder}>
          <div className={classes.audioPlayer}>
            <audio
              controls
              id={"__AUPlayer"}
              controlsList="nodownload"
              onPlay={(e) => {
                e.stopPropagation();
                if(BrowserUtils.isNewUI()) {
                  trackeventData('Play audio profile', props.astrologer)
                } else {
                  trackActions("fa_gallery_view", "message.audio", props.astrologer);
                }
              }}>
              <source src={props.galleryData.audio.url} type="audio/mpeg" />
            </audio>
          </div>
        </div>
      )}
      {currentVideo && (
        <div className={classes.instaStory} id={"fa-ast-story"}>
          <YoutubeEmbed embedId={currentVideo} />
          <span
            onClick={() => {
              setCurrentVideoID(null);
            }}>
            <CancelIcon className={classes.closeIcon} />
          </span>
        </div>
      )}
    </div>
  );
};

export default MessageGallery;
