import React, {useEffect, useRef, useState} from "react";
import axios from "../../../common/interceptor";
import SinglePujaDetails from "./SinglePujaItem.component";
import Snackbar from "../../../common/SnackBarMessage";
import moengage from "../../../common/moengage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

const PujaDetails = (props) => {
  const pujaId = props.match.params.id;

  const [selectedPuja, setSelectedPuja] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });

  const registerRZPay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    axios()
      .post("/puja/details", {puja_id: pujaId})
      .then((res) => {
        setSelectedPuja(res.data);
      })
      .catch((err) => {
        showError("Unable to get Puja or Havan details");
      });
    registerRZPay();
  }, []);

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };
  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };
  const closeSinglePuja = () => {
    props.history.goBack();
  };

  return (
    <div>
      {selectedPuja && <SinglePujaDetails data={selectedPuja} closePuja={closeSinglePuja} showError={showError} />}
      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBar.message}
        closeError={closeError}
        open={snackBar.open}></Snackbar>
    </div>
  );
};

export default React.memo(PujaDetails, function (prev, next) {
  return prev.data.puja.puja_id === next.data.puja.puja_id;
});
