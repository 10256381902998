import FlareIcon from "@material-ui/icons/Flare";
import GradeIcon from "@material-ui/icons/Grade";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Assistant from "@material-ui/icons/Assistant";

export const mockData = {
  sortCategories: [
    { text: "Clickastro recommendation", url: "rank", icon: <Assistant /> },
    { text: "Fees (Low-High)", url: "plth", icon: <TrendingUpIcon /> },
    { text: "Fees (High-Low)", url: "phtl", icon: <TrendingDownIcon /> },
    { text: "Experience", url: "exp", icon: <FlareIcon /> },
    { text: "Popularity", url: "reviews", icon: <GradeIcon /> },
  ],
  filterCategories: [
    {
      text: "category",
      options: [
        { key: "c1", text: "Marriage" },
        { key: "c2", text: "Career" },
        { key: "c3", text: "Business" },
        { key: "c4", text: "Love" },
        { key: "c5", text: "Education" },
        { key: "c6", text: "Health" },
        { key: "c8", text: "Wealth" },
        { key: "c7", text: "General" },
      ],
    },
    {
      text: "expertise",
      options: [
        { key: "e1", text: "Vedic" },
        { key: "e2", text: "Vastu" },
        { key: "e3", text: "KP" },
        { key: "e4", text: "Numerology" },
        { key: "e5", text: "Gemology" },
        { key: "e6", text: "Prashna" },
        { key: "e7", text: "Tarot" },
        { key: "e8", text: "Lal Kitab" },
        { key: "e9", text: "Nadi" },
        { key: "e10", text: "Fengshui" },
        { key: "e11", text: "Western" },
      ],
    },
    {
      text: "languages",
      options: [
        { key: "l1", text: "English" },
        { key: "l2", text: "Hindi" },
        { key: "l3", text: "Bengali" },
        { key: "l4", text: "Marathi" },
        { key: "l5", text: "Telugu" },
        { key: "l6", text: "Tamil" },
        { key: "l7", text: "Gujarati" },
        { key: "l8", text: "Kannada" },
        { key: "l9", text: "Odia" },
        { key: "l10", text: "Malayalam" },
        { key: "l11", text: "Punjabi" },
        { key: "l12", text: "Assamese" },
        { key: "l13", text: "Konkani" },
        { key: "l14", text: "Tulu" },
        { key: "l15", text: "Urdu" },
      ],
    },
  ],
};

export default mockData;
