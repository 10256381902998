export default function QueryString(customUrl = null) {
  let vars = {};
  let hash = "";
  if (!customUrl) {
    customUrl = document.URL.split("?")[1];
  } else {
    customUrl = customUrl.split("?")[1];
  }

  if (customUrl !== undefined) {
    if (customUrl.indexOf("#") > -1) {
      customUrl = customUrl.substring(0, customUrl.indexOf("#"));
    }

    customUrl = customUrl.split("&");
    for (let i = 0; i < customUrl.length; i++) {
      hash = customUrl[i].split("=");
      vars[hash[0]] = decodeURIComponent(hash[1]);
    }
  }

  return vars;
}
