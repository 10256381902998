import React from 'react'
import AstrologerUtils from './AstrologerUtils';
import { Avatar } from '@material-ui/core';

const AstrologerReservedMessage = ({ astrologer, style = {} }) => {
  const wrapStyle = {
    display: 'flex',
    alignItems: 'center',
    background: 'var(--clr-light-yellow)',
    borderRadius: '0.5rem',
    padding: '0.75rem',
    gap: '.75rem'
  }

  const contentStyle = {
    fontSize: 'var(--fz-medium)',
    margin: '0',
  }

  const minuteStyle = {
    color: 'var(--clr-red)'
  }

  return (
    <div className='astrologer-reserved-message' style={style}>
      <div className="astrologer-reserved-message-wrap" style={wrapStyle}>
        <Avatar
          className="reviews-panel__astrologer-pic"
          alt={astrologer.name}
          src={AstrologerUtils.createThumbImageURI(astrologer.id)}
        />
        <div style={contentStyle}>
          {astrologer.name} is happy to wait for <span style={minuteStyle}>3 minutes</span> as you recharge.
        </div>
      </div>
    </div>
  );
}

export default AstrologerReservedMessage