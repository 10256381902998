import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, makeStyles, CircularProgress, Checkbox, FormControlLabel, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
import { DeleteOutline, Close, Cancel, PowerSettingsNew } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import HelmetComponent from "../../../common/HelmetComponent.component.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styles from "./makeStyles.js";
import { SUPPORT_NUMBER } from "../../../app.config.js";
import axios from "../../../common/interceptor";
import Snackbar from "../../../common/SnackBarMessage";
import BrowserUtils from "../../user/astrologer/components/UAScripts.js";

const useStyles = makeStyles({
  ...styles,
  textError: { color: "var(--clr-red)", textAlign: "center" },
  deleteActionBtns: { justifyContent: "center", flexDirection: isMobile ? "column" : "row" },
  p: { marginBlockEnd: "14px" },
  li: { listStyle: "disc" },
});

const DeleteMyAccount = (props) => {
  const classes = useStyles();
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errorAccept, setErrorAccept] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };
  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  const promptDelete = () => {
    setIsDeleteConfirmVisible(true);
  }

  const handleConfirmDelete = (e) => {
    setConfirmDelete(e.target.checked);
    setErrorAccept(false);
  }

  const handleDeleteOk = () => {
    if (!confirmDelete) {
      return setErrorAccept(true);
    }

    setIsDeleteConfirmVisible(false);
    setLoading(true);
    setErrorAccept(false);

    axios()
      .post("/user/delete-user", {})
      .then((res) => {
        if (res.data.success) {
          showError("Account deleted successfully")
          setLoading(false);
          BrowserUtils.deleteAccount()
        } else {
          showError("An error occured. Please try again later.");
          setLoading(false);
        }
      })
      .catch(err => {
        showError("An error occured. Please try again later.");
        setLoading(false);
      });
  }

  const handleDeleteClose = () => {
    setErrorAccept(false);
    setConfirmDelete(false);
    setIsDeleteConfirmVisible(false);
  }

  const handlelogout = () => {
    window.location.href = "/app/logout";
  };

  return (
    <div className={classes.sidebarScreensParentDiv}>
      <HelmetComponent
        title="Delete My Account - Findastro"
        url="https://www.findastro.com/delete-my-account"
        description="Findastro is India Leading Astrologer site. You can connect to qualified Astrolger in your city."
      />
      <div className={classes.sidebarScreensTopArea}>
        <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={props.history.goBack} />
        <div className={classes.sidebarScreensChildTopArea}>
          Delete My Account
        </div>
      </div>
      <div>
        <h3>Please read and confirm the following before you continue.</h3>
        <p className={classes.p}>All the wallet points added via payments so far, along with any offers, will be removed.</p>
        <p className={classes.p}>All your consultation and payment history will be deleted.</p>
        <p className={classes.p}>Your accounts, wallet balance, consultation history, and all other activities in the Chat/Talk section or Findastro, if applicable, will be deleted.</p>
        <p className={classes.p}>All the data associated with your account will be deleted and cannot be recovered once you delete your account. It will take up to 30 days to fully remove your data from our system.</p>
        <p className={classes.p}>All user-saved profiles and login information (including mobile number and email ID) associated with your account will be permanently deleted.</p>
        {isLoading ? <CircularProgress size={25} /> :
          <Button variant="text" type="button" size="large"
            startIcon={<DeleteOutline />}
            style={{ color: "var(--clr-secondary)", fontSize: "inherit" }}
            onClick={promptDelete}>
            Delete my account
          </Button>}
        <div>
          Note: Once deleted, your account and data cannot be recovered
        </div>
        <p>
          <br />If you wish to stop receiving notifications, you can choose to log out of the app.<br />
          <Button variant="text" type="button" size="large"
            startIcon={<PowerSettingsNew />}
            style={{ color: "var(--clr-green)", fontSize: "inherit" }}
            onClick={handlelogout}>
            Logout
          </Button>
        </p>
        <p>
          For any queries or assistance, contact <a href={"tel:" + SUPPORT_NUMBER}>{SUPPORT_NUMBER}</a>
        </p>
      </div>
      <Dialog open={isDeleteConfirmVisible} onClose={handleDeleteClose}>
        <DialogTitle>
          <div style={{ textAlign: "right", height: 10 }}>
            <Close onClick={handleDeleteClose} />
          </div>
          <div>
            Important: Account Deletion Warning
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <div>
              <div>Deleting your account will remove all data registered with us, including:</div>
              <ul>
                <li className={classes.li}>User profile and login information</li>
                <li className={classes.li}>Wallet balance</li>
                <li className={classes.li}>Consultation history</li>
                <li className={classes.li}>Account, wallet balance, and consultation history in Chat/Talk section (if applicable)</li>
              </ul>
              <p>This action cannot be undone. Click "Confirm Deletion" to proceed or "Cancel" to review. We're here to assist with any decision.</p>

              <div>
                <FormControlLabel
                  aria-label="Acknowledge"
                  control={<Checkbox checked={confirmDelete} onClick={handleConfirmDelete} color="primary" />}
                  label={"I have completely read and understood the statements."}
                />
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <div>
          {errorAccept && <div className={classes.textError}>Please select the checkbox.</div>}
          <DialogActions className={classes.deleteActionBtns}>
            <Button
              variant="outlined"
              onClick={handleDeleteOk}
              startIcon={<DeleteOutline />}
              color="inherit"
              style={{ color: "var(--clr-secondary)", marginBottom: "16px" }}>
              Confirm Deletion
            </Button>
            <Button
              variant="outlined"
              onClick={handleDeleteClose}
              startIcon={<Cancel />}
              color="inherit"
              style={{ color: "var(--clr-green)", marginBottom: "16px" }}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBar.message}
        closeError={closeError}
        open={snackBar.open}></Snackbar>
    </div>
  );
};

export default withRouter(DeleteMyAccount);
