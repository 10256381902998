import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/CheckCircle";
import Button from "@material-ui/core/Button";
import { SUPPORT_NUMBER, T2A_SUPPORT_NUMBER } from "../../../app.config";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import { useRechargeRedirect } from "../../user/astrologer/components/RechargeLinkRedirect";
import { CallRounded, WhatsApp } from "@material-ui/icons";
import { BotMessage } from "../../user/astrologer/components/chat/ChatUtls";
import clsx from "clsx";
import DealPurchase from "../../feature/deal-purchase/components/DealPurchase";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      borderRadius: 12,
    },
  },
  paymentWrap: {
    padding: "20px",
  },
  paperCenter: {
    textAlign: "center",
  },
  closeModal: {
    fontSize: "20px",
    stroke: "black",
    display: "flex",
    strokeWidth: "1px",
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  errorPara: {
    color: "#5d5d5d",
    margin: "20px 0px",
    lineHeight: "normal",
    textAlign: "left",
    fontSize: "0.9rem",
  },
  errorText: {
    fontWeight: "bold",
    marginTop: "-5px",
    textTransform: 'capitalize',
  },
  errorIcon: {
    marginTop: "10px",
    color: "red",
    fontSize: "55px",
  },
  errorButton: {
    backgroundColor: "#f56307",
    color: "white",
    fontWeight: "bold",
    width: "160px",
    boxShadow: "none",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f56307 !important",
    },
  },
  warningIcon: {
    marginTop: "20px",
    color: "white",
    stroke: "#f5b707",
    fontSize: "55px",
  },
  warningButton: {
    backgroundColor: "#f5b707",
    color: "white",
    fontWeight: "bold",
    width: "90%",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f5b707 !important",
    },
  },
  successIcon: {
    marginTop: "20px",
    color: "rgb(109 202 78)",
    fontSize: "55px",
  },
  successNewIcon: {
    color: "var(--clr-green)",
  },
  successButton: {
    backgroundColor: "#00c180",
    color: "white",
    fontWeight: "400",
    fontSize: "0.9rem",
    boxShadow: "none",
    width: "90%",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#00c180 !important",
    },
  },
  errorLabel: {
    padding: "40px 40px 4px 40px",
    fontSize: "0.75rem",
    color: "#f00",
  },
}));

export default function WalletModal(props) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(props.modalOpen);
  const rechargeRedirect = useRechargeRedirect()
  const buttonNames = {
    default: 'CLOSE',
    chat: 'CONTINUE CHAT',
    call: 'CONTINUE CALL',
  }
  const chatID = rechargeRedirect.getItem('chatID')
  const params = new URLSearchParams(window.location.search)
  const paymentStatus = params.get('status')

  const onButton = async () => {
    if (rechargeRedirect.isValid()
      && (rechargeRedirect.getItem('astrologerID') || DealPurchase.isPaymentRequest() || isLastOrderIsDealPurchase())
      && props.status === "success"
    ) {
      if (chatID) {
        await BotMessage.send(chatID, 'instant-payment-initializing-chat')
      }
      const type = rechargeRedirect.getItem('type') || 'chat'
      if (rechargeRedirect.getItem('astrologerID')) {
        window.location.href = `/?recharge_redirect=1#${type}request`
      } else {
        window.location.href = `/`
      }
      return
    }
    setOpen(false);
    window.history.replaceState(null, "", "/");
    window.location.href = window.AndroidBridge == undefined ? "https://mobile.clickastro.com/cot-payment-home" : (props.targetURI || "/my-wallet?retry=1");
  };

  useEffect(async () => {
    // console.log({ chatID, props, isOpen })
    if (chatID && isOpen === true) {
      if (props.status === "success") {
        await BotMessage.send(chatID, 'instant-payment-success')
      } else {
        await BotMessage.send(chatID, 'instant-payment-failed')
      }
    }
  }, [props.status, isOpen])

  useEffect(() => {
    if (props.modalOpen) {
      // window.location.hash = "#back-button--wallet-recharge/"; // changed from #backtowallet for a temp fix.
      if (paymentStatus === null) {
        window.location.hash = "#backtowallet";
      } else {
        window.location.hash = "#back-button--wallet-recharge/";
      }
      setOpen(true);
    }
  }, [props.modalOpen]);

  const getButtonName = () => {
    let key = 'default'
    if (props.status === "success" && rechargeRedirect.getItem('astrologerID')) {
      key = buttonNames[rechargeRedirect.getItem('type')] ? rechargeRedirect.getItem('type') : 'default'
    }
    return buttonNames[key]
  }

  const ContactText = () => {
    const phone = T2A_SUPPORT_NUMBER.toString().replace(' ', '')
    const callHREF = `tel:${phone}`
    const whatsAppMessage = encodeURIComponent('Hello, I encountered a payment issue. Can you assist me with resolving it?')
    const whatsAppHREF = `https://wa.me/${phone}?text=${whatsAppMessage}`

    return <>
      Please contact our Customer Support{' '}
      <a className="a-link a-link-icon" href={callHREF}>
        <CallRounded /> {T2A_SUPPORT_NUMBER}
      </a>{' '}
      or{' '}
      <a className="a-link a-link-icon a-link-whatsapp" href={whatsAppHREF}>
        <WhatsApp /> Chat
      </a>{' '}
      between <span style={{ whiteSpace: 'nowrap' }}>7 AM to 8 PM</span> for further assistance.
    </>
  }

  const DealPurchaseSuccessContent = () => {
    let recharge = { amount: 1, minutes: 5 }
    if (rechargeRedirect.getItem('recharge')) {
      recharge = { ...recharge, ...rechargeRedirect.getItem('recharge') }
    }
    if (isLastOrderIsDealPurchase()) {
      recharge = isLastOrderIsDealPurchase()
    }

    let consult = 'chat'
    if (rechargeRedirect.getItem('type')) {
      consult = rechargeRedirect.getItem('type')
    }
    return (
      <>
        <CheckIcon className={clsx(classes.successIcon, classes.successNewIcon)} />
        <div style={{ fontSize: "1.2rem", color: 'var(--clr-green)' }}>Success!</div>
        <div className={classes.errorPara} style={{ textAlign: "center", marginBottom: 0, lineHeight: '1.5rem' }}>
          Your recharge for ₹{recharge.amount} is successful<br />
          Enjoy your {recharge.minutes} minute {consult}
        </div>
        <div className={classes.errorLabel}>Please do not press back button,
          click {getButtonName()} below</div>

        <Button variant="contained" className={classes.successButton} onClick={onButton}>
          {getButtonName()}
        </Button>
      </>
    )
  }

  const DealPurchaseFailedContent = () => {
    const validateDealPurchase = () => {
      return DealPurchase.isPaymentRequest()
    }

    return (
      <></>
    )

    return (
      validateDealPurchase ?
        <>
          <mark>Deal purchase {props?.status ? props.status.replace('_', ' ') : 'failed'}</mark>
        </>
        :
        <></>
    )
  }

  const isLastOrderIsDealPurchase = () => {
    let data = localStorage.getItem('last_order_details')
    if (data !== null) {
      data = JSON.parse(data)
      if (data?.item?.deal_type === 'FREE_CONSULT_MINUTES') {
        return data?.item
      }
    }
    return false
  }

  const InnerComponent = () => {
    // console.log({ props })

    return (
      <>
        <div
          className={clsx(
            classes.paymentWrap,
            props?.className ? props.className : ''
          )}
        >
          {/* <CloseIcon className={classes.closeModal} onClick={onButton} /> */}
          <div className={classes.paperCenter}>
            {props.status === "success" ? (
              <>
                {
                  DealPurchase.isPaymentRequest() || isLastOrderIsDealPurchase() ?
                    <DealPurchaseSuccessContent />
                    :
                    <>
                      <CheckIcon className={classes.successIcon} />
                      <div className={classes.errorPara} style={{ textAlign: "center" }}>
                        <div style={{ color: "#ee600d" }}>
                          <big style={{ fontSize: "2.4rem" }}>{props.rechargeValue}</big>
                          <small style={{ fontSize: "1.2rem" }}> points</small>
                        </div>
                        Credited to your wallet successfully.
                      </div>
                      <div className={classes.errorLabel}>Please do not press back button,
                        click {getButtonName()} below</div>

                      <Button variant="contained" className={classes.successButton} onClick={onButton}>
                        {getButtonName()}
                      </Button>
                    </>
                }

              </>
            ) : props.status === "failed" && props.orderId.length > 0 ? (
              <>
                <CloseIcon className={clsx(classes.errorIcon, 'error-icon')} />
                <div className={clsx(classes.errorText, 'error-text')}>Transaction Failed</div>
                <DealPurchaseFailedContent />
                <div className={clsx(classes.errorPara, 'error-para')}>
                  <div>
                    We are sorry! The last payment was unsuccessful(<b>#Order ID</b>:{props.orderId}). If your account
                    has been debited, the amount will be credited to your wallet once we receive a confirmation from
                    your bank.
                  </div>
                  <br />
                  <br />
                  <div style={{ fontSize: "0.8rem", color: "#666666" }}>
                    Note: Bank confirmation usually takes upto 24 hours.
                    <br />
                    <br />
                    <ContactText />
                  </div>
                </div>

                {props?.actionButton ?
                  props.actionButton :
                  <Button variant="contained" className={classes.errorButton} onClick={onButton}>
                    Okay
                  </Button>
                }
              </>
            ) : (
              <>
                <CloseIcon className={clsx(classes.errorIcon, 'error-icon')} />
                <div className={clsx(classes.errorText, 'error-text')}>Transaction {props?.status ? props.status.replace('_', ' ') : 'failed'}</div>
                <DealPurchaseFailedContent />
                <div className={clsx(classes.errorPara, 'error-para')}>
                  <div>
                    We are sorry! The last payment was unsuccessful. If your account has been debited, the amount will
                    be credited to your wallet once we receive a confirmation from your bank.
                  </div>
                  <br />
                  <br />
                  <div style={{ fontSize: "0.8rem", color: "#666666" }}>
                    Note: Bank confirmation usually takes upto 24 hours.
                    <br />
                    <br />
                    <ContactText />
                  </div>
                </div>
                {props?.actionButton ?
                  props.actionButton :
                  <Button variant="contained" className={classes.errorButton} onClick={onButton}>
                    Okay
                  </Button>
                }
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      {
        props.contentOnly ?
          <InnerComponent />
          :
          <Dialog
            open={isOpen}
            className={clsx(
              classes.dialogContainer,
            )}
          >
            <InnerComponent />
          </Dialog>
      }

    </div>
  );
}
