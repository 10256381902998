import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import "./astrovision-font.css";
import makeStyles from "./makeStyles.js";
import HelmetComponent from "../../../common/HelmetComponent.component";
import miscScreensData from "../mock/MiscScreensData.mock";
import SignBox from "./SignBox.component";
import Faq from "./Faq.component";
import Snackbar from "../../../common/SnackBarMessage";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const styles = (theme) => ({
  ...makeStyles,
  faqAnswer: {
    padding: "15px",
    textAlign: "justify",
  },
});
class DailyUpdates extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.sidebarScreensParentDiv}>
        <HelmetComponent
          title="Daily updates at Findastro"
          url="https://www.findastro.com/daily-updates"
          description="Daily updates at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        <div className={classes.sidebarScreensTopArea}>
          <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={this.props.history.goBack} />
          <div className={classes.sidebarScreensChildTopArea}>
            <p>Daily</p>
            <h3 className={classes.sidebarScreensText}>Updates</h3>
          </div>
        </div>
        <div className={classes.dailyHoroscopeSelectText}>Select your star sign</div>
        <SignBox pathname={this.props.location.pathname} classes={classes} />
        <div className={classes.dailyHoroscopeText}>{miscScreensData.dailyHoroscope}</div>
        {miscScreensData.faqData.map((data, index) => (
          <Faq data={data} key={index} classes={classes} />
        ))}
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DailyUpdates));
