import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import RechargeLinkRedirect from '../RechargeLinkRedirect'

const useStyles = makeStyles({
  container: {
    backgroundColor: 'transparent !important',
  },
  title: {
    marginTop: 0,
    marginBottom: '5px',
    fontSize: '14px',
    color: '#000',
    fontWeight: 'normal',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    width: 210,
    margin: 'auto',
    marginTop: '1rem',
    gap: '.5rem',
    '& .MuiButtonBase-root': {
      backgroundColor: 'var(--clr-green)',
      color: '#fff',
      borderRadius: '.75rem',
      padding: '.5rem .25rem',
      '&.no': {
        backgroundColor: 'var(--clr-red3)',
      }
    }
  },
  minimum: {
    color: 'var(--clr-red3)'
  },
  required: {
    color: 'var(--clr-green)'
  },
})

const InsufficientBalanceComponent = ({ astrologer, data, events }) => {
  const classes = useStyles()

  return (
    <div className={clsx('chat-message meta insufficient-component', classes.container)}>
      <p>
        To chat with <strong>{astrologer.fullName}</strong>, you need a minimum of <strong className={classes.minimum}>{data.minimum}</strong> points in your wallet.
        Please top up with at least <strong className={classes.required}>Rs.{data.required}</strong> to proceed.</p>
      <div className={classes.buttons}>
        <RechargeLinkRedirect
          refData={{
            refID: 'free-chat/recharge-and-continue',
            astrologerID: astrologer.id,
            astrologerName: astrologer.name,
            astrologerProfileURL: astrologer.profile_url || astrologer.profileUrl,
            type: 'chat',
            maxAmount: 1000,
            highlight: 499,
          }}
          onClick={e => {
            e.goToWallet()
          }}
        >
          <Button>Recharge &amp; continue</Button>
        </RechargeLinkRedirect>
        <Button onClick={events.onBack} className='no'>Search for another astrologer</Button>
      </div>
    </div>
  )
}

export default InsufficientBalanceComponent