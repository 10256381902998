import React, { Component } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";

import "./Consultation.css";
import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";
import * as Sentry from "@sentry/react";

import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import AstrologerUtils from "../../user/astrologer/components/AstrologerUtils";

import QueryString from "../../../common/util/QueryString";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import { useRechargeRedirect as getRechargeRedirect } from "../../user/astrologer/components/RechargeLinkRedirect";
import T2AEventTrack from "../../../common/T2AEventTrack";

export default class AstrologerConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackBar: {
        open: false,
        message: "Error occurred, Please try again",
      },
      isOnce: false,
      minutes: 0,
      seconds: 0,
      isLoading: true,
      caller_id: "",
      filterParams: QueryString(),
      astrologerName: null,
      currentUserId: (localStorage.getItem('userid')) ? localStorage.getItem('userid') : 0,
      callStatus: '',
      consultId: '',
      freeMinute: 0,
      clicked: false
    };
    this.rechargeRedirect = getRechargeRedirect()
  }

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  invalidRedirect = () => {
    this.props.history.push({
      pathname: this.state.filterParams.prl ? this.state.filterParams.prl : "/",
      status: "fail",
    });
  };

  normalRequest = () => {
    console.log('normalRequest')
    let that = this;
    const eData = {
      price: '0/min',
      status: 'requested',
      cost: 0,
      free_minute: 0
    }
    let callTimer = window.setInterval(
      (function checkStatus() {
        axios()
          .post("/consultations/call/status", {
            id: that.props.match.params.id,
            token: that.state.filterParams.token,
          })
          .then((res) => {
            that.setState({
              isLoading: false,
              caller_id: res.data.caller_id,
              consultId: that.props.match.params.id || 0
            });
            if (res.data.success) {
              eData.consultId = that.props.match.params.id || 0;
              if (res.data.completed) {
                clearInterval(callTimer);
                setTimeout(function () {
                  window.location.href =
                    "/consultant-feedback/" + that.props.match.params.id + "?token=" + that.state.filterParams.token;
                }, 1000);
                eData.status = 'completed'
                eData.time = res.data.time
              } else if (res.data.initiated) {
                eData.status = 'initiated'
                eData.time = res.data.time
                eData.price = res.data.price
              } else if (res.data.status) {
                eData.status = res.data.status
              }
            } else {
              that.invalidRedirect();
              eData.status = res?.data?.status || 'failed'
            }

            if (res.data.price) {
              eData.price = res.data.price
            }
            if (res.data.cost) {
              eData.cost = res.data.cost
            }
            if (res.data.free_consult_min) {
              eData.free_minute = res.data.free_consult_min
            }

            if (BrowserUtils.isNewUI() && this.status.callStatus !== eData.status) {
              this.setState({
                freeMinute: eData?.free_minute || 0,
                callStatus: eData.status
              });
              this.trackeventData(eData);
            }

          })
          .catch((err) => {
            sentryError("ASTROLOGER_CONNECT", err);
            that.setState({ isLoading: false });
            that.showError("Error occured, Please try again");
          });
      })(),
      10000
    );
  };

  freeChatMinutes = (profile) => {
    const sKey = 'fcm_' + this.state.caller_id
    let free_call_minutes = parseInt(localStorage.getItem(sKey) || '0')
    if (profile && profile?.free_call_minutes) {
      free_call_minutes = profile.free_call_minutes
    }
    return free_call_minutes
  }

  updateCallData = (data) => {
    if (data?.consult_id) {
      localStorage.setItem('lastCallData', JSON.stringify(data))
    } else {
      localStorage.removeItem('lastCallData')
    }
  }

  trackeventData = (tData) => {
    const astrologerProfile = {
      id: this.state.astrologerId,
      name: this.state.astrologerName,
    }
    //console.log(tData)
    const consultId = tData.consultId || this.state.consultId;
    let lastCallData = {};
    try {
      let lastCallInfo = localStorage.getItem('lastCallData');
      if (lastCallInfo) {
        lastCallData = JSON.parse(lastCallInfo);
      }
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
    }
    if (lastCallData?.consult_id && lastCallData.consult_id === consultId) {
      if (tData.status === lastCallData.status || tData.status === 'initiated' || (tData.status === 'cancelled' && lastCallData.status !== 'initiated')) {
        console.log("Already send : ", JSON.stringify(lastCallData))
        return;
      }
    }

    this.updateCallData({
      consult_id: consultId,
      astro_id: this.state.astrologerId,
      status: tData.status
    })

    let freeSeconds = (this.freeChatMinutes(astrologerProfile) * 60)
    let consultType = 'Paid'
    if (tData?.free_minute) {
      freeSeconds = parseInt(tData.free_minute) * 60
      consultType = 'Free'
      if (tData?.cost && parseInt(tData.cost) > 0) {
        consultType = 'Free paid'
      }
    }
    const c_type = freeSeconds > 0 ? 'free' : 'paid'
    let duration = Math.ceil((tData.time || this.state.minutes) / 60);

    if (tData.status === 'missed') {
      tData.status = 'Missed'
    }

    let walletBalance = parseInt(localStorage.getItem('walletBalance')) || 0;
    if (parseInt(tData.cost) > 0) {
      walletBalance = Math.max(walletBalance - parseInt(tData.cost), 0);
    }

    let points = tData.price.split("/");
    tData.points = parseInt(points[0]) || 0;
    const data = {
      event: {
        name: 'Consultation',
        attributes: {
          'Screen Name': 'Chat Screen',
          'Consultation Name': 'Call',
          'Consultation Type': consultType,
          'Consultation Status': tData.status,
          'Consultation Duration': duration,
          'Consultation amount': `${tData.points} pts/min `,
          'User ID': parseInt(this.state.currentUserId.split('-').at(-1)) || 0,
          'User Name': localStorage.getItem('name'),
          'Astrologer ID': this.state.astrologerId,
          'Astrologer Name': this.state.astrologerName,
          'Product': 'COT',
          'Consultation ID': consultId
        }
      },
      extra: {
        user: {
          'User ID': parseInt(this.state.currentUserId.split('-').at(-1)) || 0,
          'User Name': localStorage.getItem('name'),
          [`Last consulted ${c_type} call astrologer ID`]: this.state.astrologerId,
          [`Last consulted ${c_type} call astrologer name`]: this.state.astrologerName,
          'Last call attempt status': tData.status,
          'Last call attempt time stamp': Date.now(),
          'COT wallet balance': parseInt(walletBalance),
          'Current wallet balance': walletBalance
        }
      }
    }

    if (tData.status === 'completed') {
      localStorage.setItem('last_consulted_astrologer', this.state.astrologerId)
      if (this.state.minutes > 0 || parseInt(duration) > 0) {
        data.extra.user['Last consulted astrologer ID'] = this.state.astrologerId
        data.extra.user['Last consulted astrologer name'] = this.state.astrologerName
        data.extra.user['Last consulted Id'] = tData.consultId || this.state.consultId
        data.extra.user['Last consulted type'] = `${consultType} call`;
      }
    }
    //console.log(data);
    console.log(JSON.stringify(data));
    T2AEventTrack(data.event, data.extra)
  }

  pageViewEvent = () => {
    const data = {
      event: {
        name: 'Screen View Action',
        attributes: {
          'Screen Name': 'Call ongoing',
          'User ID': parseInt(this.state.currentUserId.split('-').at(-1)) || 0,
          'User Name': localStorage.getItem('name'),
          'Astrologer ID': this.state.astrologerId,
          'Astrologer Name': this.state.astrologerName,
          'Product': 'COT',
        }
      },
      extra: {
      }
    }
    console.log(data);
    T2AEventTrack(data.event, data.extra)
  }

  cancelCall = () => {
    this.setState({
      clicked: true
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.clicked !== this.state.clicked) {
      //console.log('componentDidUpdate-cancelled', JSON.stringify(this.state))
      const eData = {
        status: 'cancelled',
        price: '0/min',
        consultId: this.state.consultId || 0
      }

      if (this.state.freeMinute) {
        eData.free_minute = this.state.freeMinute
      }
      console.log(JSON.stringify(this.state))
      this.trackeventData(eData);
      (window.location.href = "/")
    }
  }

  componentDidMount = () => {
    this.rechargeRedirect.delete()
    /* this.setState({
      isLoading: false,
      caller_id: "+918000521522",
      astrologerName: "Chandra L Nair",
      seconds: 35,
      minutes: 0,
    });
    this.timer();
    return false; */

    //moengage("WAITING_PAGE_VIEWED");

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login";
    } else if (window.location.search) {
      if (this.state.filterParams.stkn && this.state.filterParams.stkn !== "") {
        if (typeof EventSource !== "undefined") {
          var source = new EventSource(
            process.env.REACT_APP_API_BASE_URL +
            "/consultation/status/" +
            this.props.match.params.id +
            "?token=" +
            this.state.filterParams.stkn
          );
          source.addEventListener(
            "message",
            (event) => {
              let data = JSON.parse(event.data);

              if (!this.state.isOnce) {
                this.setState({
                  minutes: Math.floor(data.time / 60),
                  seconds: data.time % 60,
                  isOffer: data.is_offer,
                  astrologerName: data.ast_name,
                  astrologerId: data.ast_id,
                });
                if (BrowserUtils.isNewUI()) {
                  this.pageViewEvent();
                }
                this.timer();
              }
              this.setState({
                isLoading: false,
                caller_id: data.caller_id,
                isOnce: true,
                consultId: this.props.match.params.id || 0
              });


              if (data.success) {
                const eData = {
                  status: (data.status) ? data.status : 'requested',
                  price: (data.price) ? data.price : '0/min',
                  consultId: this.props.match.params.id || 0,
                  cost: 0,
                  free_minute: 0
                }
                if (data.completed) {
                  eData.status = 'completed';
                  eData.time = data.time
                  let that = this;
                  setTimeout(function () {
                    window.location.href =
                      "/consultant-feedback/" + that.props.match.params.id + "?token=" + that.state.filterParams.token;
                    source.close();
                  }, 1000);
                } else if (data.status && data.status === "missed") {
                  this.props.history.push({
                    pathname: "/connect-fail",
                    state: {
                      showCallBackCTA: data.is_first_time ? true : false,
                      showSimilarAstCTA: true,
                      astrologerURL: data.url,
                      astrologerName: this.state.astrologerName,
                      astrologerId: this.state.astrologerId,
                      message: data.message,
                      status: data.status,
                    },
                  });
                  source.close();
                } else if (data.status && data.status === "failed") {
                  this.props.history.push({
                    pathname: "/connect-fail",
                    state: {
                      showCallBackCTA: true,
                      showSimilarAstCTA: true,
                      astrologerURL: data.url,
                      astrologerName: this.state.astrologerName,
                      astrologerId: this.state.astrologerId,
                      message: data.message,
                      status: data.status,
                      freeMinute: data.free_consult_min || 0
                    },
                  });
                  source.close();
                } else if (data.initiated) {
                  eData.status = 'initiated'
                  eData.time = data.time
                }
                if (BrowserUtils.isNewUI() && eData.status !== this.state.callStatus) {
                  this.setState({
                    callStatus: eData.status,
                    freeMinute: data?.free_consult_min ?? 0
                  });
                  if (data.cost) {
                    eData.cost = data.cost
                  }
                  if (data.free_consult_min) {
                    eData.free_minute = data.free_consult_min
                  }
                  this.trackeventData(eData);
                }
              } else {
                this.invalidRedirect();
              }
            },
            false
          );
          source.addEventListener(
            "error",
            () => {
              if (source.readyState !== 2) {
                this.invalidRedirect();
              }
            },
            false
          );
        } else {
          this.normalRequest();
          Sentry.captureMessage("Astrologer connect SSE request not triggered");
        }
      } else {
        this.invalidRedirect();
      }
    } else {
      this.invalidRedirect();
    }
    window.location.hash = "backtohome";
  };

  timer = () => {
    let myInterval = window.setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState({ seconds: this.state.seconds - 1 });
      } else if (this.state.seconds === 0) {
        if (this.state.minutes === 0 && this.state.isOnce) {
          clearInterval(myInterval);
        } else {
          this.setState({ minutes: this.state.minutes - 1 });
          this.setState({ seconds: 59 });
        }
      } else {
        clearInterval(myInterval);
      }
    }, 1000);
  };

  render() {
    return (
      <div className={[
        "astrologerConnectWrap",
        BrowserUtils.isNewUI() ? 'newUI' : ''
      ].join(' ').trim()}>
        <HelmetComponent
          title="Astrologer Connect at Findastro"
          url="https://www.findastro.com/astrologer-connect"
          description="Astrologer Connect at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <>
            <CloseIcon className="waitingCloseIcon" onClick={this.cancelCall} />
            <div className="timerImg">
              <div style={{ marginTop: "10vh", fontSize: "1rem" }}>Please wait, we are calling</div>
              <div style={{ fontWeight: "700", color: "#5e5e5e", fontSize: "1.3rem" }}>
                {this.state.astrologerName || "Astrologer"}
              </div>
              <div className="multi-ripple">
                <div className={"ripple"}></div>
                <div className={"ripple"}></div>
                <Avatar src={AstrologerUtils.createThumbImageURI(this.state.astrologerId)} />
              </div>
              {/* {this.state.seconds > 0 && (
                <div className="timerText">
                  {this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}
                </div>
              )} */}
            </div>
            <div className="pleaseReceiveText">
              You will receive a call shortly from <b>{this.state.caller_id}</b> once we connect with the astrologer
            </div>
          </>
        )}
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}
