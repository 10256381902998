import {Avatar, makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import AstrologerUtils, {trackFAEvent} from "./AstrologerUtils";
import CheckCircle from "@material-ui/icons/CheckCircle";
import defaultImage from "../../../../common/default_profile_image.svg";
import Star from "@material-ui/icons/Star";
import Phone from "@material-ui/icons/Phone";
import TimerIcon from "@material-ui/icons/Schedule";
import moengage from "../../../../common/moengage";
import BrowserUtils from "./UAScripts";
import StartHere from "./start_here.svg";
import {MIN_BALANCE_MINS} from "../../../../app.config";

const PRIORITY_LENGTH = 20;
const MAX_PAGES = 3;
const componentTheme = makeStyles({
  mainButon: {
    "& button.full": {
      left: "50%",
      color: "#fff",
      width: "100%",
      maxWidth: 460,
      bottom: "0",
      height: "60px",
      display: "block",
      zIndex: "90",
      position: "fixed",
      fontSize: "1rem",
      transform: "translate3d(-50%,0,4px)",
      background: "transparent",
      marginTop: "0",
      fontWeight: "700",
      padding: 0,
      paddingTop: "16px",
      borderRadius: "48px",
      boxShadow: "none",
      border: "none",
      "& img": {
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
      },
      "& span": {
        display: "block",
        height: 60,
        position: "absolute",
        width: "100%",
        textAlign: "center",
        left: 0,
        top: "50%",
      },
    },
  },
  startHereContainer: {
    left: "50%",
    color: "#ee600d",
    width: "calc(100vw - 8px)",
    bottom: -60,
    maxWidth: 460,
    height: 48,
    zIndex: "100",
    position: "fixed",
    background: "#fbead0",
    transform: "translateX(-50%)",
    borderRadius: "24px 24px 0 0",
    boxShadow: "-2px -4px 10px #0009",
    /* border: "2px solid #ee600d", */
    borderBottom: "none",
    transition: "bottom 0.2s ease-out",
    "&.slideOff": {
      bottom: -60,
    },
  },
  film: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    zIndex: "100",
    left: 0,
    top: 0,
    background: "#000a",
  },
  expanded: {
    height: "auto",
    maxHeight: "96vh",
    background: "#fbead0",
    overflow: "hidden",
    bottom: 0,
  },
  contentArea: {
    height: "calc(100% - 64px)",
    whiteSpace: "nowrap!important",
    width: "100%",
    maxWidth: "450px",
    margin: "auto",
    overflowX: "hidden",
    "& > div": {
      width: "100%",
      maxHeight: "calc(96vh - 64px)",
      /* borderRadius: "16px 16px 0 0", */
      height: "100%",
      overflowY: "auto",
      maxWidth: "450px",
      display: "inline-block",
      verticalAlign: "top",
      transition: "transform 0.4s ease-out",
    },
  },
  actionPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 48,
    "& button": {
      color: "#fff",
      border: "none",
      background: "#00c180",
      fontWeight: "700",
      borderRadius: "0",
      padding: "8px 16px",
      height: "100%",
      width: "60%",
      transition: "background 0.2s linear",
    },
    "& button svg": {
      fontSize: "16px",
      verticalAlign: "middle",
    },
    "& button.back": {
      background: "#cfcfcf",
      color: "#525252",
      width: "40%",
    },
    "& button:disabled": {
      background: "#dddddd",
      color: "#929292",
    },
    "& button.book": {
      background: "#ee600d",
    },
    "& button.flash": {
      animation: "flash 0.4s linear 0.1s",
    },
  },
});

const trackActions = (type, label, data) => {
  moengage(type);
  let ast_rating = data.reviewsData && data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
  const payload = {
    p_screen: "starthere",
    p_label: label,
    p_content: data.profileUrl || "NA",
    p_value: data.value || 0,
    value: 0,
    currency: "INR",
    p_ast_exp: data.experience ? data.experience.split(" ")[0] : "NA",
    p_ast_rating: ast_rating,
    p_ast_sort: "NA",
    p_ast_rank: data.rank ? data.rank.toString() : "NA",
    p_ast_fees: data.call_rate ? data.call_rate.split("/")[0] : "NA",
    p_usr_gender: BrowserUtils.CurrentUser().gender,
    p_usr_age: BrowserUtils.CurrentUser().age.toString(),
    p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
  };
  window.FAEventLog(type, payload);
};

const UserStartHere = ({
  promotionalList,
  mainAstrologersList,
  currentFilter,
  openProfile,
  handleCall,
  offerDiscount,
  promoDiscount,
  show,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [flash, setFlash] = useState("");
  const [showButton, setShowButton] = useState(false);

  const globalClasses = componentTheme();
  const prevPage = () => {
    if (pageIndex === 0) setExpanded(false);
    else setPageIndex(Math.max(pageIndex - 1, 0));
  };

  const nextPage = () => {
    setPageIndex(Math.min(pageIndex + 1, MAX_PAGES - 1));
  };

  const [expertiseList, setExpertiseList] = useState({});
  const onComponentMount = () => {
    const expertise = {};
    const others = {count: 0, status: false};
    [promotionalList, mainAstrologersList].forEach((list) => {
      list.forEach((astrologer) => {
        if (astrologer.category.length > 0) {
          astrologer.category.forEach((exp) => {
            exp = AstrologerUtils.upperCaseFirst(exp.toLowerCase());
            if (exp === "General") {
              others["count"] += 1;
            } else {
              expertise[exp] = expertise[exp] || {count: 0, status: false};
              expertise[exp]["count"] += 1;
            }
          });
        }
      });
    });
    expertise["General"] = others;
    setExpertiseList(expertise);
  };

  const onVisibilityChange = () => {
    setTimeout(() => {
      setShowButton(show);
    }, 100);
  };

  useEffect(onComponentMount, []);
  useEffect(onVisibilityChange, [show]);

  const [pricingList, setPricingList] = useState({});

  const openCallDialog = () => {
    const type = currentAstrologer.is_promotional ? "promotional" : "main";
    const extra = currentAstrologer.status === "online" ? {source: "starthere"} : {source: "starthere", booking: true};
    handleCall(currentAstrologer, type, extra);
    trackActions(extra.booking ? "fa_starthere_book" : "fa_starthere_consult", "NA", currentAstrologer);
    trackFAEvent({
      event_type: extra.booking ? "fa_starthere_book" : "fa_starthere_consult",
      event_action: extra.source,
      event_label: currentAstrologer.profileUrl,
      event_value: currentAstrologer.id,
      meta: {
        ast_id: currentAstrologer.id,
        ast_type: type,
        ast_url: currentAstrologer.profileUrl,
      },
    });
  };

  const toIntPrice = (price) => {
    return Number(String(price).split("/")[0]);
  };

  const sortAstrologersByRank = (astdata) => {
    let rank = [];
    const output = [];
    const rankedData = {};
    for (const key in astdata) {
      const astrologer = astdata[key];
      const prefix = astrologer.is_promotional ? 1 : 2;
      const id = Number(prefix + "" + astrologer.sequence);
      rank.push(id);
      astrologer.order = id;
      rankedData[id] = astrologer;
    }
    /** reverse sort by rank */
    rank.sort((a, b) => b - a);
    rank.forEach((seq) => output.push(rankedData[seq]));
    return output;
  };

  const onExpertiseListChanged = () => {
    const selectedExpertise = [];
    for (const exp in expertiseList) {
      if (expertiseList[exp].status) {
        selectedExpertise.push(exp);
      }
    }
    let filterAstrologerList = {};
    const priceRange = {min: Number.MAX_VALUE, max: Number.MIN_VALUE};

    [promotionalList, mainAstrologersList].forEach((list, index) => {
      list.forEach((astrologer) => {
        astrologer.category.forEach((exp) => {
          exp = AstrologerUtils.upperCaseFirst(exp.toLowerCase());
          if (selectedExpertise.includes(exp)) {
            astrologer["is_promotional"] = index === 0;
            astrologer["offerDiscountPercentage"] = index === 0 ? promoDiscount : offerDiscount;
            filterAstrologerList[astrologer.id] = astrologer;
            const price = toIntPrice(astrologer.price);
            if (priceRange.min > price) {
              priceRange.min = price;
            }
            if (priceRange.max < price) {
              priceRange.max = price;
            }
          }
        });
      });
    });

    filterAstrologerList = sortAstrologersByRank(filterAstrologerList);
    //console.log(filterAstrologerList);

    const priceList = {};
    for (const id in filterAstrologerList) {
      const astrologer = filterAstrologerList[id];
      const price = toIntPrice(astrologer.price);
      let key = "";
      if (price >= priceRange.min && price <= 25)
        key = "₹" + priceRange.min * MIN_BALANCE_MINS + ` - ₹125 for ${MIN_BALANCE_MINS} min.`;
      else if (price > 25 && price <= 50) key = `₹130 - ₹250 for ${MIN_BALANCE_MINS} min.`;
      else if (price > 50 && price <= 75) key = `₹255 - ₹375 for ${MIN_BALANCE_MINS} min.`;
      else if (price > 75 && price <= priceRange.max)
        key = "₹380 - ₹" + priceRange.max * MIN_BALANCE_MINS + ` for ${MIN_BALANCE_MINS} min.`;

      priceList[key] = priceList[key] || {list: [], status: false};
      if (astrologer.status === "online" || astrologer.bookingSlots === true) {
        priceList[key]["list"].push(astrologer);
      }
    }

    setPricingList(priceList);
  };

  const [displayAstrologers, setDisplayAstrologers] = useState([]);
  const onPriceListChanged = () => {
    let astrologers = [];
    //console.log(pricingList);
    for (const range in pricingList) {
      const plist = pricingList[range];
      if (plist.status) {
        plist.list.forEach((astrologer) => {
          if (astrologer.status === "online" && astrologers.length < PRIORITY_LENGTH) {
            astrologers.push(astrologer);
          } else if (astrologer.bookingSlots === true && astrologers.length < PRIORITY_LENGTH) {
            astrologers.push(astrologer);
          } else if (astrologers.length < PRIORITY_LENGTH) {
            astrologers.push(astrologer);
          }
        });
      }
    }
    //console.log(astrologers);
    setDisplayAstrologers(astrologers);
    setCurrentAstrologer(astrologers[0]);
    for (const astrologer of astrologers) {
      if (astrologer.status === "online") {
        setCurrentAstrologer(astrologer);
        break;
      }
    }
  };

  useEffect(onExpertiseListChanged, [expertiseList]);
  useEffect(onPriceListChanged, [pricingList]);

  const selectCategoryItem = (category, selected) => {
    expertiseList[category]["status"] = selected;
    setExpertiseList({...expertiseList});
  };
  const selectPriceRangeItem = (priceRange, selected) => {
    pricingList[priceRange]["status"] = selected;
    setPricingList({...pricingList});
  };

  //useEffect(() => console.log(pageIndex), [pageIndex]);
  //return <></>;
  const [currentAstrologer, setCurrentAstrologer] = useState({});
  /** flash */
  useEffect(() => {
    setFlash("flash");
    setTimeout(() => {
      setFlash("");
    }, 300);
  }, [currentAstrologer]);

  const pageOptionSelected = () => {
    if (pageIndex === 0) {
      for (const key in expertiseList) {
        if (expertiseList[key].status === true) return false;
      }
    } else if (pageIndex === 1) {
      for (const key in pricingList) {
        if (pricingList[key].status === true) return false;
      }
    } else if (pageIndex === 2) {
      return false;
    }
    return true;
  };

  return (
    <>
      {expanded && <div className={globalClasses.film} onClick={() => setExpanded(false)}></div>}
      <div
        className={[
          globalClasses.startHereContainer,
          expanded ? globalClasses.expanded : "",
          showButton ? "" : "slideOff",
        ].join(" ")}>
        <div className={globalClasses.contentArea}>
          {expanded && (
            <>
              <CategoryPage page={pageIndex} expertise={expertiseList} selectCategoryItem={selectCategoryItem} />
              <FilterPage page={pageIndex} pricing={pricingList} selectPriceRangeItem={selectPriceRangeItem} />
              <AstrologerPage
                page={pageIndex}
                astrologers={displayAstrologers}
                currentFilter={currentFilter}
                openProfile={openProfile}
                setCurrentAstrologer={setCurrentAstrologer}
                currentAstrologer={currentAstrologer}
                handleCall={handleCall}
              />
            </>
          )}
        </div>
        <div className={globalClasses.actionPanel}>
          {expanded && (
            <>
              <button onClick={prevPage} className="back">
                Back
              </button>
              {pageIndex === 2 ? (
                <button
                  disabled={pageOptionSelected()}
                  onClick={openCallDialog}
                  className={
                    currentAstrologer && currentAstrologer.status === "online" ? flash + " consult" : flash + " book"
                  }>
                  {currentAstrologer && currentAstrologer.status === "online" ? (
                    <>
                      <Phone /> Consult now
                    </>
                  ) : (
                    <>
                      <TimerIcon /> Book consultation now
                    </>
                  )}
                </button>
              ) : (
                <button disabled={pageOptionSelected()} onClick={nextPage}>
                  Next
                </button>
              )}
            </>
          )}
        </div>
      </div>
      {!expanded && (
        <div className={globalClasses.mainButon}>
          <button
            className="full"
            onClick={() => {
              setExpanded(true);
              trackActions("fa_starthere_open", "NA", {});
            }}>
            <img src={StartHere} alt="start" /> <span>Start here</span>
          </button>
        </div>
      )}
    </>
  );
};
const optionItemsTheme = makeStyles({
  optionItem: {
    display: "flex",
    alignItems: "center",
    color: "#525252",
    marginBottom: 16,
    "& svg": {
      marginRight: 8,
      marginLeft: 10,
    },
  },
  concernGroup: {
    padding: "16px",
    background: "transparent",
    borderRadius: 0,
  },
  sectionTitle: {
    fontSize: "1rem",
    fontWeight: "700",
    textAlign: "center",
    backgroundColor: "#8b2840",
    color: "#fff",
    padding: "16px 8px",
    width: "calc(100% + 32px)",
    marginLeft: "-16px",
    marginTop: "-16px",
    marginBottom: 24,
  },
  sectionContent: {
    color: "#5d4037",
    paddingBottom: 12,
    fontWeight: 400,
  },
});

const CategoryPage = ({page, expertise, selectCategoryItem}) => {
  const classes = optionItemsTheme();
  /** checkbox component */
  const cStyle = {
    fill: "#ee600d",
    color: "#ee600d",
  };
  const ucStyle = {
    fill: "#0003",
    color: "#525252",
  };
  const formatExpertise = (exp) => {
    return exp === "General" ? "Others" : exp;
  };
  const CategoryOption = ({exp, status}) => {
    const [checked, setChecked] = useState(status);
    const selectItem = () => {
      selectCategoryItem(exp, !checked);
      setChecked(!checked);
      trackActions("fa_starthere_category", formatExpertise(exp), {});
    };
    return (
      <div className={classes.optionItem} onClick={selectItem}>
        <CheckCircle style={checked ? cStyle : ucStyle} />
        <span style={checked ? cStyle : ucStyle}>{formatExpertise(exp)}</span>
      </div>
    );
  };
  /** checkbox component */
  return (
    <div style={{transform: `translateX(-${page * 100}%)`}}>
      <div className={classes.concernGroup}>
        <div className={classes.sectionTitle}>Select your area of concern</div>
        <div className={classes.sectionContent}>
          Please choose one or more topic for consultation. This will help us to suggest you astrologers specialized in
          that area.
        </div>
        <div>
          {Object.keys(expertise).map((exp) => (
            <CategoryOption exp={exp} status={expertise[exp].status} />
          ))}
        </div>
      </div>
    </div>
  );
};

const filterItemThemes = makeStyles({
  optionItem: {
    display: "flex",
    alignItems: "flex-start",
    color: "#525252",
    marginTop: 16,
    marginBottom: 24,
    "& svg": {
      marginRight: 8,
      marginLeft: 10,
    },
  },
  filterGroup: {
    padding: "16px",
    background: "transparent",
    borderRadius: 0,
  },
  sectionTitle: {
    fontSize: "1rem",
    fontWeight: "700",
    textAlign: "center",
    backgroundColor: "#8b2840",
    color: "#fff",
    padding: "16px 8px",
    width: "calc(100% + 32px)",
    marginLeft: "-16px",
    marginTop: "-16px",
    marginBottom: 24,
  },
  sectionContent: {
    color: "#5d4037",
    paddingBottom: 0,
    fontWeight: 400,
  },
});

const FilterPage = ({page, pricing, selectPriceRangeItem}) => {
  const pricingData = Object.keys(pricing);
  pricingData.sort((a, b) => {
    const an = parseInt(a.substring(1).split("-")[0]);
    const bn = parseInt(b.substring(1).split("-")[0]);
    return an - bn;
  });
  const classes = filterItemThemes();
  /** checkbox component */
  const cStyle = {
    fill: "#ee600d",
    color: "#ee600d",
  };
  const ucStyle = {
    fill: "#0003",
    color: "#525252",
  };
  const red = {
    color: "red",
  };
  const disabled = {
    opacity: 0.6,
  };
  const CategoryOption = ({prc, status}) => {
    const [checked, setChecked] = useState(status);
    const selectItem = () => {
      selectPriceRangeItem(prc, !checked);
      setChecked(!checked);
      trackActions("fa_starthere_pricing", prc, {});
    };
    return (
      <div
        className={classes.optionItem}
        style={pricing[prc]["list"].length === 0 ? disabled : null}
        onClick={pricing[prc]["list"].length > 0 ? selectItem : null}>
        <CheckCircle style={checked ? cStyle : ucStyle} />
        <span>
          {prc}
          <br />
          <small style={pricing[prc]["list"].length === 0 ? red : null}>
            {pricing[prc]["list"].length === 0
              ? "No astrologers available"
              : pricing[prc]["list"].length + " astrologers available"}
          </small>
        </span>
      </div>
    );
  };
  /** checkbox component */
  return (
    <div style={{transform: `translateX(-${page * 100}%)`}}>
      <div className={classes.filterGroup}>
        <div className={classes.sectionTitle}>Select a preferred consultation fee range</div>
        <div className={classes.sectionContent}>
          We have astrologers with various level of consultation charges. This usually varies based on their offline
          consultation fees, experience, fame, etc. Nevertheless, all astrologers in our panel are experts in their
          field. Please choose a consultation fee range suitable for you.
        </div>
        <div>
          {pricingData.map((prc) => (
            <CategoryOption prc={prc} status={pricing[prc].status} />
          ))}
          {pricingData.length === 0 && <>Please select a desired category first.</>}
        </div>
      </div>
    </div>
  );
};

const astrologerListTheme = makeStyles({
  astrologersList: {
    transition: "height 0.4s ease-out",
    transitionDelay: "0.4s",
    height: "auto",
    overflowY: "auto",
  },
  filterGroup: {
    padding: "0",
    background: "#fbead0",
    borderRadius: 0,
  },
  sectionTitle: {
    fontSize: "1rem",
    fontWeight: "700",
    textAlign: "center",
    backgroundColor: "#8b2840",
    color: "#fff",
    padding: "16px 8px",
    width: "100%",
    marginLeft: "0",
  },
  sepTitle: {
    padding: "20px 0 8px 16px",
    fontWeight: 700,
    textAlign: "center",
  },
  offlineList: {
    background: "#f8ddb4",
  },
});

const AstrologerPage = ({astrologers, page, openProfile, currentAstrologer, setCurrentAstrologer}) => {
  const onAstrologerPage = page === MAX_PAGES - 1;
  const classes = astrologerListTheme();

  const onlineAstrologers = astrologers.filter((astrologer) => astrologer.status === "online");
  const offlineAstrologers = astrologers.filter((astrologer) => astrologer.status !== "online");

  const calculatedHeight = (onlineAstrologers.length + offlineAstrologers.length) * 98;
  const titleOffset = onlineAstrologers.length > 0 && offlineAstrologers.length > 0 ? 98 : 0;

  return (
    <div style={{transform: `translateX(-${page * 100}%)`}}>
      <div className={classes.filterGroup}>
        <div className={classes.sectionTitle}>Select an astrologer to consult</div>
        <div
          className={classes.astrologersList}
          style={onAstrologerPage ? {height: calculatedHeight + titleOffset} : {height: 100}}>
          {onlineAstrologers.length > 0 && offlineAstrologers.length > 0 && (
            <div className={classes.sepTitle}>Consult now</div>
          )}
          {onlineAstrologers.map((astrologer) => (
            <AstrologerCard
              astrologer={astrologer}
              active={astrologer.id === currentAstrologer.id}
              openProfile={openProfile}
              setCurrentAstrologer={setCurrentAstrologer}
            />
          ))}
          <div className={classes.offlineList}>
            {onlineAstrologers.length > 0 && offlineAstrologers.length > 0 && (
              <div className={classes.sepTitle}>Book now & consult later</div>
            )}
            {offlineAstrologers.map((astrologer) => (
              <AstrologerCard
                astrologer={astrologer}
                active={astrologer.id === currentAstrologer.id}
                openProfile={openProfile}
                setCurrentAstrologer={setCurrentAstrologer}
              />
            ))}
          </div>
          {astrologers.length === 0 && <>Please select a desired category and price range first.</>}
        </div>
      </div>
    </div>
  );
};

const astrologerCardTheme = makeStyles({
  card: {
    background: "transparent",
    padding: "16px 0",
    paddingLeft: 16,
    color: "#525252",
    display: "flex",
    alignItems: "flex-start",
    borderBottom: "1px solid #cdb187",
    width: "100%",
  },
  star: {
    width: 16,
    fill: "#ffaa00",
  },
  online: {
    display: "inline-block",
    width: 8,
    height: 8,
    marginRight: 4,
    borderRadius: "50%",
    background: "#00c180",
    verticalAlign: "middle",
  },
  offline: {
    display: "inline-block",
    width: 8,
    height: 8,
    marginRight: 4,
    borderRadius: "50%",
    background: "#a5a5a5",
    verticalAlign: "middle",
  },
  name: {
    width: "calc(100% - 64px)",
    paddingLeft: 8,
    "& b, span": {
      display: "flex",
      whiteSpace: "nowrap",
      alignItems: "center",
    },
    "& i.offer": {
      fontWeight: "400",
      background: "#ffff00",
      marginLeft: "8px",
      color: "#000",
      padding: "0 4px",
      fontSize: "0.8rem",
    },
    "& i": {
      display: "inline-flex",
      alignItems: "center",
      fontStyle: "normal",
      minWidth: "40px",
      marginLeft: "12px",
    },
  },
  avatar: {
    width: 64,
    height: 64,
    borderRadius: 16,
  },
  active: {
    background: "#ffffff",
  },
});

const AstrologerCard = ({astrologer, openProfile, active, setCurrentAstrologer}) => {
  const classes = astrologerCardTheme();
  const TrimName = (name) => {
    if (name.length > 27) {
      return name.substring(0, 25).trim() + "...";
    }
    return name;
  };
  const astName =
    astrologer.salutation && astrologer.name.indexOf(astrologer.salutation) < 0
      ? AstrologerUtils.upperCaseWords([astrologer.salutation, astrologer.name].join(" "))
      : AstrologerUtils.upperCaseWords(astrologer.name);

  return (
    <div className={[classes.card, active ? classes.active : ""].join(" ")}>
      <Avatar
        alt={astrologer.name}
        imgProps={{loading: "lazy"}}
        onClick={() => {
          openProfile(astrologer);
          trackActions("fa_starthere_view_profile", "NA", astrologer);
        }}
        className={classes.avatar}
        src={astrologer.imageUrl ? AstrologerUtils.createThumbImageURI(astrologer.id) : defaultImage}
      />
      <div
        className={classes.name}
        onClick={() => {
          setCurrentAstrologer(astrologer);
          trackActions("fa_starthere_select_astrologer", "NA", astrologer);
        }}>
        <b>
          <div className={astrologer.status === "online" ? classes.online : classes.offline} />
          {TrimName(astName)}
        </b>
        <span>
          <i>
            Exp: {astrologer.experience}
            {astrologer.reviewsData.rating && (
              <>
                , {astrologer.reviewsData.rating.toFixed(1)}
                <Star className={classes.star} />
              </>
            )}
          </i>
        </span>
        <div>
          <i>
            Fees: ₹{astrologer.perMinutePrice * MIN_BALANCE_MINS} for {MIN_BALANCE_MINS} min
          </i>
        </div>
        {astrologer.offerDiscountPercentage && (
          <div>
            <i className="offer">get {astrologer.offerDiscountPercentage}% cashback after the consultation</i>
          </div>
        )}
      </div>
    </div>
  );
};
/**
 * <AstrologerDetailsComponent
    currentFilter={currentFilter}
    openProfile={openProfile}
    family={astrologer}
    key={astrologer.id}
    showMessage={showMessage}
    showLoading={showLoading}
    handleFavourite={handleFavourite}
    handleOfferIcon={handleOfferIcon}
    offerDiscount={astrologer.is_promotional ? promoDiscount : offerDiscount}
    isSearch={false}
    handleCall={handleCall}
    offerClaim={offerClaim}
    handleNotify={handleNotifyMe}
  />
 */
export default React.memo(UserStartHere, (prev, next) => {
  return prev.listUpdated === next.listUpdated && prev.show === next.show;
});
