export default function FA_localStorage(property, value = "") {
  try {
    localStorage.setItem("temp", "temp");
    if (value) {
      if (value === "remove") {
        localStorage.removeItem(property);
      } else {
        localStorage.setItem(property, value);
      }
      return true;
    } else {
      if (localStorage.getItem(property)) {
        return localStorage.getItem(property);
      } else return false;
    }
  } catch (e) {
    return false;
  }
}
