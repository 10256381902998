import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  logo: {
    margin: "auto",
    display: "flex",
    width: "200px",
  },
  container: {
    width: "100%",
    maxWidth: "520px",
    margin: "0 auto",
    padding: "50px 20px 5px 20px",
  },
  headText: {
    textAlign: "center",
    marginTop: "25px",
  },
  linkText: {
    color: "blue",
    cursor: "pointer",
    textDecoration: "none",
    wordWrap: "break-word",
  },
  bodyText: {
    textAlign: "center",
    lineHeight: "24px",
  },
  button: {
    display: "block",
    margin: "0 auto",
    background: "#e86029",
    color: "#fff",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    padding: "1.5% 1%",
    borderRadius: "10px",
    marginBottom: 0,
    border: "1px solid #e86029",
    cursor: "pointer",
    maxWidth: "40%",
    marginTop: "3%",
    textDecoration: "none",
  },
});

const base_domain = window.location.origin;
const AccountDeleted = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src="https://assets.findastro.com/findastro_logo_color.png"
        alt="Findastro Logo"
      />
      <h2 className={classes.headText}>Account Successfully Deleted</h2>
      <div className={classes.bodyText}>
        <p>Your account has been successfully deleted. We're sorry to see you go and hope to serve you again in the future. If you have any feedback or need further assistance, please feel free to contact us.</p>
        <p>Thank you for using our services.</p>
      </div><br />
      <a href={base_domain} className={classes.button} style={{ color: "#fff", textDecoration: "none" }} target="_blank">Home</a>
    </div>
  );
};

export default AccountDeleted;
