import React, {useEffect, useState} from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BlastConfetti from "./Confetti.component";

const Credits = (props) => {
  const creditList = {
    Engineering: [
      "Abhinav Mathur",
      "Anjali Praveen Raj",
      "Anurag Radhakrishnan",
      "Arun Krishnan",
      "Jeevan Reddy",
      "Jeevan S",
      "Mallika M",
      "Mangala Arjun",
      "Mansoorkhan T K",
      "Manoj CB",
      "Praseeda Themadath",
      "Santhi J Krishnan",
      "Sashish K Ashok",
      "Shama Anjoom",
      "Tapas K Panda",
      "Varun G Maniyan",
      "Venkata Subramanian S",
    ],
    Quality: ["Arya Surendran", "George Elias", "Gireesh KK", "Mithran V", "Neenu Joy"],
    "Astrologer & Customer relations": [
      "Alphons Cyriac",
      "Ananda NV",
      "Anjuna Suresh",
      "Lissby Lessly",
      "Riza Said",
      "Sangeeth Narayanan",
      "Siva Kumar",
      "Sunanda Mohan",
      "Vivek Kumar K V",
    ],
    Graphics: ["Arun CV", "Jayalal V", "Nigil George", "Shivappa Venkatesh"],
    "Content & Marketing": [
      "Annifred Solomon",
      "Anoop Mohanan",
      "Gayathri Nikhil",
      "Harikrishnan",
      "Manu Alias",
      "Maxon Livera",
      "Ramesh Kumar Roy",
      "Reshmi R Nair",
    ],
    Administration: ["Peter CD", "Unnikrishnan Rajappan"],
    Management: ["Arjun Ravindran", "Arun Nair"],
  };
  const [showConfetti, setConfetti] = useState(false);
  useEffect(() => {
    triggerConfetti(1000);
  }, []);

  const triggerConfetti = (to) => {
    setTimeout((e) => {
      setConfetti(true);
      setTimeout((e) => {
        setConfetti(false);
      }, 500);
    }, to);
  };
  return (
    <div
      style={{backgroundColor: "#e4d2b1", minHeight: "100vh", fontFamily: "Roboto,sans-serif"}}
      onClick={(e) => {
        triggerConfetti(100);
      }}>
      <div
        className="accountTopArea"
        style={{backgroundColor: "#ee600d", color: "#fff", padding: "16px 16px 32px 16px", margin: 0}}>
        <ArrowBackIcon
          className="accountArrowIcon2"
          onClick={() => {
            window.location.href = "/";
          }}
          style={{fill: "#fff"}}
        />
        <div className="accountChildTopArea">
          <p>The Findastro Team</p>
        </div>
      </div>
      <div>
        <BlastConfetti showConfetti={showConfetti} />
      </div>
      <div style={{height: "calc(100vh - 96px", padding: "32px", overflowY: "auto"}}>
        {Object.keys(creditList).map((head, i) => (
          <div style={{marginBottom: "32px"}} className="creditGroup">
            <div className="head">{head}</div>
            {creditList[head].map((name) => (
              <div className="name">{name}</div>
            ))}
          </div>
        ))}
        <div style={{fontSize: "0.8rem", color: "#802f00"}}>
          We also thank everyone else who constantly helped and motivated us in making Findasto a great platform. Also,
          extremely grateful to the family members of the entire team for being supportive during these pandemic times.
        </div>
        <br />
        <div style={{fontSize: "0.8rem", color: "#802f00"}}>
          We extend our gratitude to the entire astrologer family and the millions of our loyal customers spanning
          across India and the world for being with us through the amazing journey named Astro-Vision, since we started
          in 1984.
        </div>
      </div>
    </div>
  );
};

export default Credits;
