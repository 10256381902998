import {Avatar} from "@material-ui/core";
import React, {useState} from "react";
import AstrologerUtils, {formatAmount, secondsToDHM} from "../../user/astrologer/components/AstrologerUtils";
import defaultImage from "../../../common/default_profile_image.svg";
import TagIcon from "@material-ui/icons/LocalOffer";
import {ReactComponent as DiscountIcon} from "./discount.svg";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const Astrologer = (props) => {
  const data = props.data;

  /* data.online_available = true;
  data.offline_available = true;
  data.travel_nearby_state = true;
  data.travel_within_state = true;
  data.travel_all_india = false;
  data.location = "Arunachal Pradesh"; */

  const [loading, setLoading] = useState(false);
  const fullWidthButton = {
    width: "70%",
    borderRadius: 24,
    marginBottom: 10,
    marginTop: 10,
    height: 40,
  };
  const openProfile = () => {
    props.showAstrologerProfile({
      profileUrl: data.profile_url,
    });
  };
  const trigerBookPuja = () => {
    props.selectAstrologer(props.index);
  };

  const otherLanguages = (data) => {
    const langs = data.languages.split(", ");
    langs.forEach((lang, i) => {
      if (lang === data.preferred_language) {
        langs.splice(i, 1);
      }
    });
    return langs.join(", ");
  };

  return (
    <div className="priest">
      <div className="photo">
        <Avatar
          alt={data.ast_name}
          src={data.image_url ? AstrologerUtils.createThumbImageURI(data.ast_id) : defaultImage}
          onClick={openProfile}
        />
      </div>
      {/* <div className="offer-perc">{(data.price - data.discounted_price) / data.price / 0.01}% OFF</div> */}
      <div className="name">
        <div>
          <b>{data.salutation && data.salutation.length > 0 ? data.salutation + " " + data.ast_name : data.ast_name}</b>
        </div>
        <div style={{fontSize: "0.9rem"}}>
          Exp: {data.experience > 1 ? data.experience + " Years" : data.experience + " Year"}
        </div>
        {props.noAction !== true && (
          <>
            <div className="main-language">
              Preferred language: <b>{data.preferred_language}</b>
            </div>
            {data.languages.split(",").length > 1 && (
              <div className="languages">Also speaks: {otherLanguages(data)}</div>
            )}
            <div className="offer-perc-bttn">
              <span>
                <DiscountIcon className="perc" />
                <b>{Math.floor((data.price - data.discounted_price) / data.price / 0.01)}% OFF</b>
              </span>
              <span>
                <TagIcon className="price" />{" "}
                <div className="priceValue">
                  <strike>₹{formatAmount(data.price)}</strike>
                  <br />
                  <b>₹{formatAmount(data.discounted_price)}/-</b>
                </div>
              </span>
            </div>
          </>
        )}
        {props.noAction !== true && (
          <div
            style={{
              textAlign: "left",
              padding: "12px",
              fontSize: "0.8rem",
              fontWeight: "400",
              color: "#525252",
            }}>
            <div>
              I have {data.experience > 1 ? data.experience + " Years" : data.experience + " Year"} of experience in
              astrology. I will do the <b>{props.puja.name}</b> with utmost dedication and care, for the desired
              benefits.
            </div>
            {data.prasad === "Yes" && data.online_available && (
              <div>I will also send the {props.puja.type} prasad via courier to your home address.</div>
            )}
            <br />
            <div>
              You may book the {props.puja.type} now and Findastro will talk to you to understand the desired date of{" "}
              {props.puja.type} and finalize the date with me.
              {data.online_available && `You will get to see this ${props.puja.type} via video call(Google Meet)`}
            </div>
            {data.puja_info && (
              <div>
                <br />
                <b>Note:</b>
                <br />
                {data.puja_info}
              </div>
            )}
          </div>
        )}
        {props.noAction !== true && (
          <>
            <div className="availability-details" style={{marginTop: 12}}>
              <div>
                <b>Will do {props.puja.type} online?</b>
                {data.online_available ? <i className="yes-icon">yes</i> : <i className="no-icon">no</i>}
              </div>

              <div className={data.online_available ? "active" : "inactive"}>
                <div>
                  <span>Video call available for {props.puja.type}</span>
                  {data.online_available && data.video === "Yes" ? (
                    <CheckIcon style={{fill: "#00c180"}} />
                  ) : (
                    <CloseIcon style={{fill: "#f00"}} />
                  )}
                </div>
                <div>
                  <span>Prasad available via courier?</span>
                  {data.online_available && data.prasad === "Yes" ? (
                    <CheckIcon style={{fill: "#00c180"}} />
                  ) : (
                    <CloseIcon style={{fill: "#f00"}} />
                  )}
                </div>
              </div>
            </div>
            <div className="availability-details" style={{marginTop: 12}}>
              <div>
                <b>Will do {props.puja.type} at customer location?</b>
                {data.offline_available ? <i className="yes-icon">yes</i> : <i className="no-icon">no</i>}
              </div>

              <div className={data.offline_available ? "active" : "inactive"}>
                <div>
                  <span>Within {data.location}</span>
                  {data.offline_available && data.travel_within_state ? (
                    <CheckIcon style={{fill: "#00c180"}} />
                  ) : (
                    <CloseIcon style={{fill: "#f00"}} />
                  )}
                </div>
                <div>
                  <span>Within {data.location} and nearby states</span>
                  {data.offline_available && data.travel_nearby_state ? (
                    <CheckIcon style={{fill: "#00c180"}} />
                  ) : (
                    <CloseIcon style={{fill: "#f00"}} />
                  )}
                </div>
                <div>
                  <span>Anywhere in India</span>
                  {data.offline_available && data.travel_all_india ? (
                    <CheckIcon style={{fill: "#00c180"}} />
                  ) : (
                    <CloseIcon style={{fill: "#f00"}} />
                  )}
                </div>
              </div>
            </div>
            <div className="price-details" style={{marginTop: 8}}>
              <li>
                <div>
                  <b>Duration</b> {secondsToDHM(data.duration * 60)}
                </div>
                <div>
                  <b>Actual fees</b> ₹{data.price > 0 ? formatAmount(data.price) + "/-" : "NA"}
                </div>
                <div>
                  <b>Discount</b> ₹
                  {data.discounted_price > 0 ? formatAmount(data.price - data.discounted_price) + "/-" : "NA"}
                </div>
                <div>
                  <b>Final fees</b> ₹{data.discounted_price > 0 ? formatAmount(data.discounted_price) + "/-" : "NA"}
                </div>
              </li>
              <div>
                <button disabled={loading} onClick={trigerBookPuja} style={fullWidthButton}>
                  {loading ? "Please wait" : "Book " + props.puja.type + " now"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Astrologer;
