import React, { useEffect, useState } from "react";
import { useStopwatch, useTimer } from "react-timer-hook";
import { getDeviceSyncTimeUTC } from "./ChatUtls";
import BrowserUtils from "../UAScripts";
import clsx from "clsx";

const MyStopwatch = ({ autoStart, offsetTimestamp }) => {
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } = useStopwatch({
    autoStart: autoStart,
    offsetTimestamp: offsetTimestamp,
  });

  useEffect(() => {
    // console.log("reset timer to ", offsetTimestamp);
    const currentSecond = new Date();
    const diffSeconds = (Date.now() - new Date(offsetTimestamp).getTime()) / 1000;
    currentSecond.setSeconds(currentSecond.getSeconds() + diffSeconds);
    reset(currentSecond, true);
    start();
  }, [offsetTimestamp]);

  return (
    <>
      {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
    </>
  );
};

const MyTimer = ({ autoStart, expiryTimestamp, updateInfo }) => {
  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp,
    autoStart,
    // onExpire: () => console.warn('onExpire called')
  });
  const [visible, setVisible] = useState(false)
  const [info, setInfo] = useState({
    lastMinute: false
  })

  useEffect(() => {
    restart(expiryTimestamp)
    // start()
    setVisible(true)
  }, [expiryTimestamp]);

  useEffect(() => {
    if (minutes === 0 && hours === 0) {
      setInfo(i => setInfo({ ...i, lastMinute: true }))
    }
  }, [minutes, hours])

  useEffect(() => {
    updateInfo(info)
  }, [info])

  return (
    <>
      {visible &&
        <span className={clsx('chat-actionbar-timer', (minutes === 0 && hours === 0) ? 'danger' : 'normal')}>
          {hours > 0 ? String(hours).padStart(2, "0") + ':' : ''}{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </span>
      }
    </>
  );
};

export default function ChatTimer({ initiatedAt, currentServerTime, freeMinutes, initiatedDuration, updateInfo }) {
  const [offsetTimestamp, setOffsetTimestamp] = useState(false);
  const [expiryTimestamp, setExpiryTimestamp] = useState(false);
  const [info, setInfo] = useState({
    totalSeconds: 0,
    lastMinute: false
  })
  useEffect(() => {
    setOffsetTimestamp(initiatedAt);

    const mutatedInitiatedTime = getDeviceSyncTimeUTC(initiatedAt, currentServerTime);
    // console.log("initatied_at_utc", initiatedAt);
    // console.log("server_time_utc", currentServerTime);
    // console.log("mutated_utc", mutatedInitiatedTime);

    setOffsetTimestamp(mutatedInitiatedTime);
  }, [initiatedAt, currentServerTime]);


  useEffect(() => {
    // console.log(freeMinutes, wallet_balance)
    const time = new Date()
    const initiated_at_utc = parseInt(initiatedAt)
    const initiated_duration = parseInt(initiatedDuration)

    // Minutes based on wallet balance by astrologer fee
    const walletSeconds = initiated_duration * 60

    // Add free seconds
    const freeSeconds = 0

    // Deduct start seconds
    const now = Date.now()
    const offset = new Date(initiatedAt).getTime()
    const diffSeconds = offset ? (now - offset) / 1000 : 0

    // Total seconds 
    const totalSeconds = time.getSeconds() + walletSeconds + freeSeconds - diffSeconds

    time.setSeconds(totalSeconds)
    const d = new Date()

    // console.log('ChatTimer:wallet_balance:', wallet_balance, 'profile:', profile)
    // console.log(
    //   // 'ChatTimer:time:', time,
    //   'walletSeconds:', walletSeconds,
    //   'freeSeconds:', freeSeconds,
    //   'totalSeconds:', totalSeconds,
    //   'diffSeconds:', diffSeconds,
    //   'time:', time,
    //   'now:', now,
    //   'offset:', offset,
    //   'offsetTimestamp:', offsetTimestamp,
    //   // 'time:', time.getTime(),
    // )

    setExpiryTimestamp(time)
    setInfo(i => setInfo({ ...i, totalSeconds }))
  }, [initiatedDuration])

  useEffect(() => {
    updateInfo(info)
  }, [info])

  return (
    <>
      {
        Number.isInteger(offsetTimestamp) &&
        <>
          {BrowserUtils.isNewUI() ?
            expiryTimestamp &&
            <MyTimer
              autoStart={false}
              expiryTimestamp={expiryTimestamp}
              freeMinutes={freeMinutes}
              updateInfo={e => {
                setInfo(i => setInfo({ ...i, ...e }))
              }}
            /> :
            <MyStopwatch
              autoStart={false}
              offsetTimestamp={offsetTimestamp}
            />
          }
        </>
      }
    </>
  );
}
