import React, { useEffect, useState } from "react"
import axios from "../../../../common/interceptor"
import { Rating, Skeleton } from "@material-ui/lab"
import AstrologerUtils from "./AstrologerUtils"
import { Avatar, Button, Card, CardContent, CardHeader } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '1rem'
  },
}));

const ReviewsPanel = ({
  title, // Reviews panel title
  reviewsParameter = {}, // API parameter
  showMore = true, // Show more button
  noDataMessage = 'No reviews', // No data message
  excludeAstrologer = [], // Exclude astrologer
  perPage = 3, // Per page
  onShowMore: onShowMoreClick = () => { }, // on show more click
}) => {
  const [endIndex, setEndIndex] = useState(1 * perPage)
  const [loading, setLoading] = useState(true)
  const [reviews, setReviews] = useState({})
  const classes = useStyles();

  useEffect(() => {
    getReviews()
  }, [reviewsParameter.user_id])

  const getReviews = () => {

    // Common params for API
    let param = {
      num_of_reviews: 60,
      rating_from: 4,
      min_char_limit: 15,
      sort_by: 'shuffle', // For future changes
    }

    // For Internal purpose 
    let extra = {
      limit_comments: 2,
      random: true
    }

    if (reviewsParameter) {
      if (reviewsParameter?.extra) {
        extra = { ...extra, ...reviewsParameter.extra }
        // delete reviewsParameter.extra
      }
      param = { ...param, ...reviewsParameter }
    }

    // console.log({ param, extra })

    axios()
      .post("/getReviews", param)
      .then((res) => {
        if (res.data.status === 'success') {
          //display reviews only with comments
          let reviewData = res.data.reviews.reduce((accumulator, item) => {
            if (!excludeAstrologer.includes(parseInt(item.astrologer_id)) && item.comments) {
              accumulator.push(item);
            }
            return accumulator;
          }, []);

          setReviews(shuffleArray(reviewData))
          setLoading(false)
        } else {
        }
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const onShowMore = () => {
    const allReviews = reviews
    if (allReviews.length > (endIndex + perPage)) {
      setEndIndex(endIndex + perPage)
    } else {
      setEndIndex(endIndex + (allReviews.length - endIndex))
    }
    onShowMoreClick({
      reviewsParameter,
      endIndex
    })
  }

  const getRandomColor = (i) => {
    const colors = ["#9BB8ED", "#FEC6DF", "#DEB3E0", "#A39FE1", "#FFDDE4"];
    const indx = i % 5;
    return colors[indx];
  }

  return (
    <div className="reviews-panel">
      {title ? <div className="reviews-panel__title">{title}</div> : ''}
      {loading &&
        <div className="review-preload">
          {[1, 2, 3, 4].map(i =>
            <React.Fragment key={i}>
              <Card elevation={0} style={{ marginBottom: '16px' }}>
                <CardHeader
                  avatar={<Skeleton animation="wave" variant="circle" width={40} height={40} />}
                  title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
                  subheader={<Skeleton animation="wave" height={10} width="40%" />}
                />
                <Skeleton height={35} animation="wave" variant="rect" />
                <br />
                <Skeleton height={35} animation="wave" variant="rect" />
              </Card>
            </React.Fragment>
          )}
        </div>
      }
      {(!loading && Object.keys(reviews).length) ?
        reviews.slice(0, endIndex).map((review, reviewIndex) =>
          <div className="reviews-card" key={review.id}>
            <div className="col-div">
              <Avatar style={{ backgroundColor: getRandomColor(reviewIndex) }} className={classes.small}>
                {review.user_name ? review.user_name.charAt(0).toUpperCase() : 'G'}
              </Avatar>
              <div className="reviews-card__username">{review.user_name || 'Guest'}</div>
              <Rating className="reviews-panel__review-rating" value={parseInt(review.rating)} readOnly />
            </div>
            <div className="reviews-content">{review.comments}</div>
            <div className="col-div">
              <Avatar
                className="reviews-panel__astrologer-pic"
                alt={review.astrologer_name}
                src={AstrologerUtils.createThumbImageURI(review.astrologer_id)}
              />
              <div className="reviews-footer-text">
                Consulted with: {review.astrologer_name}<br />
                via {review.consult_type || ''} consultation
              </div>
            </div>
          </div>
        )
        :
        <div className="reviews-panel__nodata">{noDataMessage}</div>
      }
      {showMore ?
        <div className="astp" style={{ paddingBottom: 0 }}>
          <div className="showMoreReviewsWrap" style={{
            textAlign: 'right',
            marginTop: '-10px',
            paddingRight: '0'
          }}>
            <Button
              className="showMoreReviewsBtn"
              disabled={reviews.length <= endIndex}
              onClick={onShowMore}
            >
              Show More
            </Button>
          </div>
        </div>
        :
        <></>
      }
    </div >
  )
}

export default ReviewsPanel;