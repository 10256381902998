import React from "react";
import { Avatar } from "@material-ui/core"
import AstrologerUtils from "../AstrologerUtils"
import clsx from "clsx";
import AstrologerAvatar from "../AstrologerAvatar";

const InChatAvatar = ({ astrologer }) => {

  if (!astrologer) {
    return (null)
  }

  return <AstrologerAvatar
    astrologer={astrologer}
    className={clsx(
      'inChat-avatar',
      'inChat-avatar-astrologer',
      'inChat-avatar-start'
    )}
  />
}

export default InChatAvatar