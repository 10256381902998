import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import makeStyles from "./makeStyles.js";
import HelmetComponent from "../../../common/HelmetComponent.component";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import QueryString from "../../../common/util/QueryString";

const styles = (theme) => ({
  ...makeStyles,
});
class PrivacyPolicy extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.bodyJustify}>
        <HelmetComponent
          title="Privacy policy at Findastro"
          url="https://www.findastro.com/privacy-policy"
          description="Privacy policy at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        <div className={classes.sidebarScreensTopArea}>
          {this.props.location.search && !QueryString().isRegister ? (
            <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={this.props.history.goBack} />
          ) : (
            <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={this.props.history.goBack} />
          )}
          <div className={classes.sidebarScreensChildTopArea}>
            <p>Privacy Policy</p>
          </div>
        </div>
        <div style={{paddingTop: "32px"}}>
          <p>
            Please read this Privacy Policy very carefully. This Privacy Policy sets out the manner in which
            ASTRO-VISION FUTURETECH Pvt Ltd , .(hereinafter referred to as "Astro-Vision ", “We”, “Us”) collects, uses,
            maintains and discloses information collected from the users of the website www.findastro.com (including
            astrologers and customers whether registered or unregistered) (hereinafter referred to as “You”, “Your”,
            “User”). This Privacy Policy applies to the website www.findastro.com hereinafter referred as ‘the website’
            which is owned by Astro-vision. By accessing/availing services using or by registering with the website Your
            profile with the website, You are consenting to the use, collection, transfer, storage, disclosure and other
            uses of Your information in the manner set out in this Privacy policy.
          </p>
          <p>
            www.findastro.com is a web portal, owned, maintained and managed by Asto-Vision Futuretech Pvt Ltd , acting
            as an intermediary platform between astrologers and users , wherein the users can avail various astrological
            services including astrological reports, data or personal advice through telephone, video chat and email
            consultations from astrologers of their choice, as provided in the website subject to the terms and
            conditions. Astro-vision is committed to protect the privacy of the users of the website (including
            astrologers and buyers/customers whether registered or not registered). This Privacy Policy is published in
            accordance with Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 and
            Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or
            Information) Rules, 2011 which requires publishing of the Privacy policy for collection, use, storage and
            transfer of sensitive personal data or information.
          </p>
          <h3>USER’S CONSENT</h3>
          <p>
            This Privacy Policy, which may be updated/amended from time to time, deals with the information collected
            from its users in the form of personal identification, contact details, birth details and any forecast made
            using the supplied information and how such information is further used for the purposes of the Website. By
            accessing the website and using it, you indicate that you understand the terms and expressly consent to the
            privacy policy of this website. If you do not agree with the terms of this privacy policy, please do not use
            this website.
          </p>
          <p>
            Your continued use of this website shall confirm that you have provided your unconditional consent and
            confirm to the terms of this privacy policy as regards collecting, maintaining, using, processing and
            disclosing your personal and other information in accordance with this Privacy Policy.
          </p>
          <p>
            This Privacy Policy is to be read alongwith the respective Terms of Use or other terms and conditions as
            provided on the Website.
          </p>
          <p>
            This Privacy Policy does not apply to the practices of third parties that Astro-vision does not own,
            control, or manage including but not limited to any third party websites, services, applications, or
            businesses ("Third Party Services"). Astro-vision does not take responsibility for the content or privacy
            policies of those Third Party Services. Astro-vision encourages You to carefully review the privacy policies
            of all Third Party Services before You access it.
          </p>
          <h3>COMMITMENT</h3>
          <p>
            The Website intends to protect the privacy of all kinds of users visiting the platform irrespective whether
            the user is a registered user or merely a visitor. It is recommended to every user to understand what types
            of personally identifiable information is collected. The Website employs the personally identifiable
            information for certain predictions however it is guaranteed that no direct or indirect use of such
            information which is revealed in the prediction for a member will be done except for the explicit purpose of
            communicating the horoscope charts and predictions to the member itself disclosing such information. It is
            further clarified that the Website does not in any manner deal in selling or renting the information
            supplied to the Website.
          </p>
          <p>
            The Website does not commit to treat or provide solutions for users with weak mental health which is
            inclusive of any user who have thoughts related to committing suicide, self-destruction etc. Such users are
            advised to stop the use of the present website with immediate effect and any continued use of the website by
            such person would be considered solely at the user’s risk and the Website shall have no liability for any
            untoward event in such scenario. The Website declares that the information provided by such kind of user can
            be shared, if required, with law enforcement authorities. Such information is not protected from any kind of
            non-disclosure or confidential agreements either with the Website or with any third-party involved herein.
          </p>
          <p>
            The Website does not commit in any manner whatsoever for the accuracy of the predictions made by the
            astrologers to any user. The Website does not take any guarantee/responsibility/liability regarding the
            reliability or reality of the gems and other related items represented and sold on the website. It is
            further declared by the Website that no warranty on such service is provided by the Website in any manner.
          </p>
          <h3>INFORMATION COLLECTED BY WEBSITE</h3>
          <h4>PERSONAL IDENTIFIABLE INFORMATION:</h4>
          <p>
            The information qualifies as personal in nature when the information collected identifies a specific end
            user. Such information would be collected by the website during the following actions:-
          </p>
          <p>
            a. Creating an account/Registration data: While accessing the Website, the User of the Website may be
            required to create an account. The personal information which may be sought while creating an account shall
            include, but not limited to the Full name, Address, Telephone Number, Email-address, Date of Birth, Gender,
            Location, Photograph, any other items of ‘sensitive personal data or information” as such term is defined
            under the Information Technology (Reasonable Security Practices And Procedures And Sensitive Personal Data
            Of Information) Rules, 2011 enacted under the Information Technology Act, 2000, and any other detail
            required on the website during registration. It is hereby informed to all the Users that the e-mail address
            or phone number together with a password or OTP is used for the purpose of securing User’s profile and for
            effective implementation of the personalized E-mail and SMS Services provided by the Website to the User. In
            the event that no registration is made by the User, the Website may not be able to provide any services due
            to non-availability of the personal identifiable information of the User.
          </p>
          <p>
            b. Booking a paid service: While booking a service through Order Form, the personal information which may be
            sought would include, but not limited to the information as mentioned in Column 1(a), financial information
            inclusive of bank account information, credit card or debit card details or other payment instrument details
            through a secure third party gateway, IP (Internet protocol) Address and any other information that a User
            may provide during booking a paid service on the Website. Such information is kept highly confidential.
          </p>
          <p>
            c. Log Files, IP Address and Cookies: The website collects information that is stored by your browser on
            your computer’s hard drive i.e. through cookies. It further automatically log generic information about the
            user’s computer connection to the Internet i.e. Session Data. The website may store temporary or permanent
            ‘cookies’ on the user’s computer. Cookies would allow the web server to recognize the user computer each
            time the user returns to the website including the time and date of the visit, viewing of page, length of
            time, verify registration or password information etc. Such cookies are usually only read by the server
            placed and the user may choose to block these cookies on their computers. Please note that if the cookies
            are turned off, the user may be prevented from using certain features of the website. The website uses the
            cookies to personalize the user’s experience on the website and to display an advertisement according to the
            user’s preferences.
          </p>
          <p>
            Some of the services provided by the Website may direct the User to platform of third parties. Any
            Information provided by the User on such platforms may be dealt by them in the manner provided by the
            privacy policy formulated by such third-party platforms. The Website in this regard fully disclaims any
            liability(ies) or claim(s) which may arise by use/misuse of such information shared by the User, to any
            third party or any party not known to the Website. The website would not liable for the mis-use of such
            information shared by the User or by any third party. We also collect details including but not limited to
            User feedback, comments, etc. that may be disclosed/informed/mentioned on any article/blog or groups/forums
            or other pages which the User may have access to while visiting the Website. For such information which is
            in public domain and accessible to all the Users and visitors of the Website, the User is advised to
            exercise its discretion before disclosing it as this information is susceptible to misuse.
          </p>
          <p>
            d. Miscellaneous Activities: The Website may collect any other information which may be mandatory to be
            disclosed and further may receive any other information via email or other method inclusive of contract with
            regard to specific services availed from the Website or any products bought from the Website, such
            information may not be made part of the User-Member’s Profile but shall be used only for addressing the
            specific need or concern of the User.
          </p>
          <h4>NON-PERSONAL IDENTIFIABLE INFORMATION: </h4>
          <p>
            The information qualifies as non-personal in nature when the information collected does not identify a
            specific end user. Such information is collected when the user visits the Website, cookies, etc. and would
            include but not limited to the following:
          </p>
          <p>
            a. URL (Uniform Resource Locator) of the previous website visited by the User before visiting this website
            or the URL of the website the User visits after visiting this Website.
          </p>
          <p>b. Internet service provider/IP Address/Telecom service provider.</p>
          <p>c. Type of Browser used for accessing the website.</p>
          <p>d. Geographical Location</p>
          <p>
            Such non-personal identifiable information is used by the Website for the purposes including but not limited
            to troubleshoot connection problems, administer the website, analyze trends, gather demographic information,
            frequency of visits to the website, average length of visits, pages viewed during a visit, compliance with
            applicable law, and cooperate with law enforcement activities, etc.
          </p>
          <p>
            The information is used for improving the site content and performance and the website may share this
            information with Third Party Service Providers and Third Party Advertisers to measure the overall
            effectiveness of the website’s online advertising, content, programming and for other bonafide purpose as
            required.
          </p>
          <p>
            THE USER HEREBY REPRESENT AND CONFIRMS THAT THE INFORMATION PROVIDED TO THE WEBSITE IS AUTHENTIC, CORRECT,
            CURRENT AND UPDATED. THE WEBSITE AND ITS ENTITIES SHALL NOT BE RESPONSIBLE FOR THE AUTHENTICITY OF THE
            INFORMATION THAT THE USER MAY PROVIDE. THE USER SHALL BE PERSONALLY LIABLE AND INDEMNIFY THE WEBSITE FOR THE
            BREACH OF ANY PROVISION.
          </p>
          <h4>SECURITY MEASURES:</h4>
          <p>
            The security of the personal information supplied by the User is very important to the Website and the
            website for the purpose of securing the information takes various measures inclusive of taking reasonable
            steps such as physical and electronic security measures to guard against the unauthorized access to the
            information. The personal information of a user is collected on a secured server. The payment details are
            entered on the Payment Gateway’s or Bank’s page on a secured SSL. The data is transferred between Bank’s
            page and payment’s gateways in an encrypted manner. However please note that no data transmission can be
            guaranteed to be completely secure. Hence the user is advised to take precaution and care against any
            sharing of the details submitted on the website included the log-in details as generated after registration.
            The website is not responsible for the security or confidentiality of communications the user may send
            through the internet using email messages, etc.
          </p>
          <h4>USAGE OF THE INFORMATION: </h4>
          <p>
            The information collected by the Website may be used for any purpose as may be permissible under the
            applicable law and shall include but not limited to the following: -
          </p>
          <p>
            a. For providing a personalised browsing experience. While guaranteeing the anonymity of the user, the
            personal information collected in Clause “Personal Identifiable Information” may be used for research
            purposes, for improving the marketing and promotional efforts, to analyse usage, improve the content of the
            Website, product offering and for customising the Website’s layout for suiting the needs of its Users.
          </p>
          <p>
            b. With IP tracking details and Cookies data, the Website will use it only for facilitating the usage of the
            website and provide personalised experience and any information which is sensitive in nature will not be
            provided to any third party without the consent of the User.
          </p>
          <p>
            c. All information (and copies thereof) collected by Website, including without limitation Personal
            Information, User Data, and other information related to your access and use of the services offered by
            Website, may be retained by Website for such period as necessary, including but not limited to, for purposes
            such as compliance with statutory or legal obligations, tax laws and potential evidentiary purposes and for
            other reasonable purposes such as to implement, administer, and manage your access and use of our services,
            or resolution of any disputes.
          </p>
          <p>
            d. To ensure a seamless experience at the Website for you and to ensure your maximum benefit and comfort,
            the Website may use the data collected through cookies, log file, device identifiers, location data and
            clear gifs information to: (a) remember information so that you will not have to re-enter it during your
            visit or the next time you visit the site; (b) provide custom, personalized content and information,
            including advertising; (c) provide and monitor the effectiveness of Services offered by Website; (d) monitor
            aggregate metrics such as total number of visitors, traffic, usage, and demographic patterns on the Website
            and its Services; (e) diagnose or fix technology problems; and (f) otherwise to plan for and enhance the
            service.
          </p>
          <p>
            e. Website uses certain third-party analytics tools to measure traffic and usage trends for the Services. It
            is also used for analyzing the customer satisfaction, group analysis for understanding the requirements of
            different segments of the public and providing more effective services. These tools collect information,
            which is not personal or sensitive in nature sent by the User’s device, including the web pages visited,
            add-ons, and other information that assists the Website in improving the Services. Such information is
            collected from Users in the form of anonymized logs, so that it cannot reasonably be used to identify any
            particular individual User.
          </p>
          <h4>CONFIDENTIAL:</h4>
          <p>
            The website aspires to takes care of all the information provided to it by its Users which may be termed as
            confidential. Such confidential information which is not required to be disclosed to the website, is
            specifically excluded from the definition of Personal Information and shall not be collected/used. The
            confidential information of the User shall not be disclosed or shared by the Websites, its employees, its
            agents or any third-party contractors including the experts either orally or in writing except for the
            following circumstances:
          </p>
          <p>
            a. If Website believes that there is a significant/ real/ imminent threat or risk to User’s health, safety
            or life or to the health, safety or life of any other person or the public.
          </p>
          <p>
            b. If such confidential information must be shared in accordance with the law inclusive of any
            investigation, Court summons, judicial proceedings etc.
          </p>
          <p>c. To protect and defend the rights or property of the Website</p>
          <h4>ACCOUNT DELETION:</h4>
          <p>Deleting your Findastro account will permanently remove all associated data, including user profile, wallet points, and consultation history. This action is irreversible. To delete your account, navigate to Settings, select Delete Account, and follow the instructions. Your account will be deleted immediately and will not be accessible to you anymore. It will take 30 days to remove the entire data from our system. We may store your login information for fraud prevention purposes. Please note that deleting your account here will also affect your Chat/Talk account in Clickastro app. You'll cease to receive communications from Findastro, except for legal or security notices.
          </p>
          <br />
          <br />
          <br />
          <h3>GRIEVANCE OFFICER</h3>
          <h4>Grievance Officer Name: Lissby Lessly K</h4>
          <h4>Email: support@findastro.com</h4>
          <p>
            The above Officer is appointed in accordance with the Information Technology Act 2000 and rules made there
            under. The Officer can be contacted if there are any discrepancies found on the website or there is any
            breach of Terms of Use, Privacy Policy or other policies or any other complaints or concerns.
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PrivacyPolicy));
