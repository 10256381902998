/*
 * Feature Switch Config
 * feature on/off, a/b testing, other general configurations
 */
const FeatureSwitchConfig = {

  // Instant popup
  instant_popup: {
    // Enable this feature on entire chat screen
    enabled_on_chat_window: true,

    // Enable automatic showing on chat window, otherwise show FAB button
    enabled_automatic_on_chat_window: true,

    // Enable this feature on astrologer profile
    enabled_on_astrologer_profile: true,
  },
}

export default FeatureSwitchConfig

