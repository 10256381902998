import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  logo: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    width: "200px",
  },
  container: {
    width: "100%",
    maxWidth: "520px",
    margin: "0 auto",
    padding: "50px 20px 5px 20px",
    lineHeight: "24px",
  },
  headText: {
    textAlign: "center",
  },
  linkText: {
    color: "blue",
    cursor: "pointer",
    textDecoration: "none",
    wordWrap: "break-word",
  },
  button: {
    display: "block",
    margin: "0 auto",
    background: "#e86029",
    color: "#fff",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    padding: "1.5% 1%",
    borderRadius: "10px",
    marginBottom: 0,
    border: "1px solid #e86029",
    cursor: "pointer",
    maxWidth: "40%",
    marginTop: "3%",
    textDecoration: "none",
  },
  p: { marginBlockEnd: "14px" },
  li: { listStyle: "disc" },
});

const base_domain = window.location.origin;
const AccountDeletionProcess = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img
        className={classes.logo}
        src="https://assets.findastro.com/findastro_logo_color.png"
        alt="Findastro Logo"
      />
      <h2 className={classes.headText}>Account Deletion Process</h2>
      <p className={classes.p}>Important: Account Deletion Warning</p>
      <p className={classes.p}>Deleting your Findastro account will permanently remove all associated data, including user profile, wallet points, and consultation history. This action is irreversible. To delete your account, navigate to My Account, select Delete Account, and follow the instructions. Your account will be deleted immediately and will not be accessible to you anymore. It will take 30 days to remove the entire data from our system. We may store your login information for fraud prevention purposes. Please note that deleting your account here will also affect your Chat/Talk account in Clickastro app. You'll cease to receive communications from Findastro, except for legal or security notices.</p>

      <p>To delete your account, follow these steps:</p>
      <ul>
        <li className={classes.li}>Go to [<span><a href={base_domain} className="linkText" target="_blank">{base_domain}</a></span>]</li>
        <li className={classes.li}>Log in using your phone number and OTP.</li>
        <li className={classes.li}>Click on the menu button on top.</li>
        <li className={classes.li}>Click on 'My Account'.</li>
        <li className={classes.li}>Click on 'Delete My Account' and follow the instructions.</li>
      </ul>
      <p style={{ marginBottom: 0 }}>Your account will be permanently deleted from our system within 30 days.</p>
      <a href={base_domain} className={classes.button} target="_blank">Delete my account</a>
    </div>
  );
};

export default AccountDeletionProcess;
