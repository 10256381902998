import React, { useEffect, useState } from "react"
import { FCMessage } from "./Index"
import { ArrowBack, Send } from "@material-ui/icons"
import { Avatar } from "@material-ui/core"
import AstrologerUtils from "../AstrologerUtils"
import VerifyNumberComponent from "./VerifyNumber"
import BirthDetailsFormComponent from "./BirthDetails"
import Marquee from "../Marquee"
import clsx from "clsx"
import MetaMessage from "../chat/MetaMessage"
import SingleMessage from "../chat/SingleMessage"
import InChatAvatar from "../chat/InChatAvatar"
import { useRef } from "react"
import AstrologerAvatar from "../AstrologerAvatar"
import AstrologerDetail from "../view/AstrologerDetail"
import AudioPlay from "./AudioPlay"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import BrowserUtils from "../UAScripts"
import InsufficientBalanceComponent from "./InsufficientBalanceComponent"
import TryAnotherAstrologerComponent from "./TryAnotherAstrologerComponent"

const MessageType = {
  MINE: 1,
  OTHER: 2,
}

const FreeConsultWindow = ({
  freeMessages = [],
  freeMessageStatus = '',
  freeMessageEvent,
  onlyMessages = false,
}) => {
  const astrologer = freeMessageEvent.astrologer
  const inputRef = useRef(null)
  const messagesEndRef = useRef(null)
  const [audio, setAudio] = useState({ file: "https://assets.findastro.com/chat/fa_notification_sametab.mp3", play: false })
  const history = useHistory()

  useEffect(() => {
    scrollToBottom()
    playSound()
  }, [freeMessages.messages])

  useEffect(() => {
    scrollToBottom()
  }, [freeMessages.infoText])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (inputRef.current.value.trim()) {
      freeMessageEvent.handleUserMessage(inputRef.current.value)
      inputRef.current.value = ''
    }
  }

  const playSound = () => {
    setAudio(v => { return { ...v, play: Date.now() } })
  }

  useEffect(() => {
    window.document.addEventListener("visibilitychange", visibilityListener);
  }, []);

  const visibilityListener = () => {
    switch (document.visibilityState) {
      case "hidden":
        setAudio(v => { return { ...v, file: "https://assets.findastro.com/chat/fa_notification_othertab.mp3" } })
        break;
      case "visible":
        setAudio(v => { return { ...v, file: "https://assets.findastro.com/chat/fa_notification_sametab.mp3" } })
        break;
      default:
        console.log("tab error");
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const onBack = () => {
    history.push('/app-home-listing')
    // window.location.href = '/'
    // BrowserUtils.setFreeConsultationFlowEnded(true)
  }

  return (
    astrologer?.id &&
    <div className="free-consult-window">

      <AudioPlay {...audio} />

      <div className="chat-window-header">
        <span className="back" onClick={onBack}>
          <ArrowBack />
        </span>
        {astrologer.id &&
          <span className="astrologer-display">
            <AstrologerAvatar
              astrologer={astrologer}
            />
            <span className="name"><Marquee>FREE CONSULTATION: with {astrologer.fullName}</Marquee></span>
          </span>
        }
      </div>

      {/* <button onClick={() => freeMessageEvent.sendMessage(FCMessage.create('text in chat'))}>In Chat</button> */}

      <div className="chat-window-body">
        {/* {freeMessages.map(msg => <div key={msg.time}>{msg.text} [{msg.time}]</div>)} */}
        {freeMessages.messages.map((message, index) => {
          const msgStatus = 'sent'
          const msg = {
            body: {
              text: {
                action: "text",
                text: message.text,
                time: message.time,
                time_utc: message.time,
                type: message.type === 'bot' ? 'info' : 'chat',
                visibility: 'both'
              },
              extra: message.extra
            },
            created: message.time,
            from: message.type,
            id: message.time,
            timestamp: message.time,
            to: message.time,
            formattedTime: message.formattedTime
          }

          if (message.type !== 'bot') {
            msg.body.text = message.text
          }

          if ((msg.from === "bot" || msg.from.split("-")[0]) !== "user") {
            if (typeof msg.body.extra === 'undefined') {
              msg.body.extra = {}
            }
            msg.body.extra.before = <InChatAvatar astrologer={astrologer} />
          }

          return (
            // <div key={message.time} className={clsx(
            //   'chat-message',
            //   message.type === 'bot' ? 'meta' : '',
            //   message.type === 'astrologer' ? 'left astrologer' : '',
            //   message.type === 'user' ? 'right user' : '',
            //   !['bot', 'astrologer', 'user'].includes(message.type) ? 'full-width component' : '',
            // )}>

            // </div>
            <React.Fragment key={msg.id}>
              {message.text &&
                <>
                  {/* {message.text}
                  {message.formattedTime && <div className="time">{message.formattedTime}</div>} */}
                  {msg.from === "bot" ? (
                    ["user", "both"].indexOf(msg.body.text.visibility) >= 0 && (
                      <MetaMessage
                        message={msg}
                        msgStatus={msgStatus}
                        MessageType={MessageType}
                        astrolgerInfo={astrologer}
                      />
                    )
                  ) : (
                    <>
                      {msg.from !== "breaker" ? (
                        <SingleMessage
                          msg={msg}
                          msgStatus={msgStatus}
                          MessageType={MessageType}
                          key={msg.id}
                        />
                      ) : (
                        <div className="breaker">
                          <hr />
                        </div>
                      )}
                    </>
                  )}
                </>
              }
              {message.type === 'SHOW_ASTROLOGER_CARD'
                &&
                <div className="new-style astrologer-card-component">
                  <AstrologerDetail
                    currentConsultType="chat"
                    family={astrologer}
                    key={`oal-${astrologer.id}`}
                    isSearch={true}
                  />
                </div>
              }
              {message.type === 'SHOW_VERIFY_NUMBER_COMPONENT' && !onlyMessages
                &&
                <VerifyNumberComponent
                  onVerify={freeMessageEvent.onVerify}
                  events={{ scrollToBottom }}
                />
              }
              {message.type === 'SHOW_BIRTH_DETAILS_COMPONENT' && !onlyMessages
                &&
                <BirthDetailsFormComponent
                  onCorrectedBirthDetails={freeMessageEvent.onCorrectedBirthDetails}
                  onEnterBirthDetails={freeMessageEvent.onEnterBirthDetails}
                  astrologer={astrologer}
                  events={{ scrollToBottom }}
                />
              }
              {message.type === 'SHOW_INSUFFICIENT_BALANCE' && !onlyMessages
                &&
                <InsufficientBalanceComponent
                  astrologer={astrologer}
                  data={message.data}
                  events={{ onBack }}
                />
              }
              {message.type === 'SHOW_TRY_ANOTHER_ASTROLOGER' && !onlyMessages
                &&
                <TryAnotherAstrologerComponent
                  astrologer={astrologer}
                  events={{ onBack }}
                  onContinueConsultation={freeMessageEvent.onContinueConsultation}
                />
              }
            </React.Fragment>
          )
        })}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-window-footer">
        {freeMessages.inputText ?
          <form className="chat-form" onSubmit={handleSubmit}>
            <textarea
              className="chat-form-input-text"
              placeholder="Type something here"
              ref={inputRef}
            />
            <button className="chat-form-submit">
              <Send />
            </button>
          </form>

          : freeMessages.infoText ?
            <div className="info-text">
              {freeMessages.infoText}
            </div>
            : null
        }
      </div>


    </div >
  )
}

export default FreeConsultWindow