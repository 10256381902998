import React, { Component, useState } from "react";
import axios from "../../../../common/interceptor";
import axios_noheader from "../../../../common/interceptor_noheader";
import sentryError from "../../../../common/sentryError";
import moengage from "../../../../common/moengage";
import clsx from "clsx";
import { MIN_BALANCE_MINS, PUJA_IMAGE_PATH, STATUS_API_PATH } from "../../../../app.config";

import "./UserAstrologer.css";
import defaultImage from "../../../../common/default_profile_image.svg";
import ShareDrawerComponent from "./ShareDrawer.component";
import HelmetComponent from "../../../../common/HelmetComponent.component";
import AstrologerUtils, { isChatEnabled, isUserLoggedIn } from "./AstrologerUtils";
import ChatIcon from "@material-ui/icons/Chat";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import Phone from "@material-ui/icons/Phone";
import PhoneDisabled from "@material-ui/icons/PhoneDisabled";
import Favorite from "@material-ui/icons/Favorite";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Share from "@material-ui/icons/Share";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Snackbar from "../../../../common/SnackBarMessage";
import BrowserUtils from "./UAScripts.js";
import CloseIcon from "@material-ui/icons/Close";
import SadIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import AstrologerCallDialog from "./AstrologerCallDialog.component";
import AppInstallBanner from "./AppInstallBanner.component";
import FA_localStorage from "../../../../common/util/FA_localStorage";
import { ReactComponent as OfferStamp } from "./offer.svg";
import PhotoGallery from "../../../account/components/PhotoGallery.component";
import MessageGallery from "../../../account/components/MessageGallery.component";
import TimerIcon from "@material-ui/icons/Schedule";
import { ReactComponent as Separator } from "../../../../common/ornamental-bar.svg";
import { ChatWindowStateProvider, ChatWindowStateSetterProvider } from "./chat/ChatWindowContext";
import Conversations from "./chat/Conversations";

const ChatWrapper = ({ children, show }) => {
  const [chatWindowData, setChatWindowData] = useState({});
  const [isConvLoading, setIsConvLoading] = useState(isUserLoggedIn());
  return (
    <ChatWindowStateProvider value={chatWindowData}>
      <ChatWindowStateSetterProvider value={setChatWindowData}>
        <>
          {children}
          {isChatEnabled() && show && (
            <Conversations showAstrologerProfile={false} setIsConvLoading={setIsConvLoading} />
          )}
        </>
      </ChatWindowStateSetterProvider>
    </ChatWindowStateProvider>
  );
};
export default class AstrologerProfile extends Component {
  state = {
    toastBar: {},
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    astrologerInfo: {},
    currentUser: {},
    open: false,
    isLoading: true,
    isFavoriteLoading: false,
    isActive: false,
    callConfirmModalOpen: false,
    booking: false,
    callLoading: false,
    warningModalOpen: false,
    warningModalMessage: "Error Occurred",
    defRev: { 5: "0", 4: "0", 3: "0", 2: "0", 1: "0" },
    nextCallDiscount: null,
    reviews: {
      currentPage: 1,
      data: [],
      totalReviews: 0,
    },
    galleryData: null,
  };

  multiLineStyle = {
    display: "-webkit-box",
    WebkitLineClamp: "4",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };

  priceBarStyle = {
    height: "30px",
    background: "#ffffff",
    borderRadius: "0px 0px 10px 10px",
    display: "flex",
    alignItems: "center",
    padding: "0 0 0 8px",
    justifyContent: "space-between",
  };

  handleClickOpen = () => {
    this.setState({ callConfirmModalOpen: true });
  };

  handleHashChange = () => {
    if (["#closeprofilepopup", "#storyopen", "#dpexpanded", "#closefilterpopup"].indexOf(window.location.hash) < 0) {
      this.goBack();
    }
  };

  handleClose = () => {
    this.setState({ callConfirmModalOpen: false });
  };

  upperCaseWords = (str) => {
    if (str) {
      str = str.toLowerCase();
      return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
        return s.toUpperCase();
      });
    }
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  astrologerName = () => {
    return this.state.astrologerInfo.salutation &&
      this.state.astrologerInfo.name.indexOf(this.state.astrologerInfo.salutation) < 0
      ? this.upperCaseWords([this.state.astrologerInfo.salutation, this.state.astrologerInfo.name].join(" "))
      : this.upperCaseWords(this.state.astrologerInfo.name);
  };

  showDrawer = () => {
    moengage("SHARE_CLICKED");
    if (BrowserUtils.isAWebview) {
      if (typeof window.AndroidBridge !== "undefined") {
        window.AndroidBridge.call(
          "pushShareDetails",
          JSON.stringify({
            url:
              window.location.origin +
              "/astrologer/" +
              this.state.astrologerInfo.profileUrl +
              "?utm_source=Findastro_app&utm_medium=share&utm_campaign=In-app",
            subject: "Hey, checkout this astrologer:",
          })
        );
      } else if (typeof window.webkit !== "undefined") {
        // Do nothing: Handled from iOS webkit
      } else {
        this.setState({ open: !this.state.open });
      }
    } else {
      this.setState({ open: !this.state.open });
    }
  };

  onCategoryChange = (val) => {
    this.setState({
      modelCategory: val,
    });
  };

  similarAstrologer = () => {
    moengage("PROFILE_FIND_SIMILAR_CLICKED");
    window.location.href = "/similar-astrologer/" + this.state.astrologerInfo.profileUrl;
  };

  onCall = (e) => {
    moengage("PROFILE_OKAY_CLICKED");
    this.handleClose();
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + this.props.location.pathname;
    } else if (!BrowserUtils.hasUserProfile()) {
      window.location.href = "/userprofile?pathname=" + window.location.pathname;
    } else {
      this.setState({
        callLoading: true,
      });

      let that = this;
      axios()
        .post("/consultations/call", {
          id: this.state.astrologerInfo.id,
          mode: "audio",
        })
        .then((res) => {
          if (res.data.success) {
            const secondsSinceEpoch = Math.floor(Date.now() / 1000);
            localStorage.setItem(
              "progress_call",
              "1|" +
              (parseInt(secondsSinceEpoch) + parseInt(res.data.data.time)) +
              "|" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              that.state.astrologerInfo.profileUrl +
              "|" +
              this.state.astrologerInfo.name
            );
            localStorage.setItem("old_user", true);
            window.location.href =
              "/astrologer-connect/" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              that.state.astrologerInfo.profileUrl;
          } else if (res.data.isProfileUpdated) {
            this.showError("Please update your profile");
            window.setTimeout(function () {
              window.location.href = "/userprofile?pathname=/astrologer/" + that.state.astrologerInfo.profileUrl;
            }, 1000);
          } else if (res.data.isWalletRecharge) {
            this.showError(res.data.message);
            window.setTimeout(function () {
              window.location.href = "/my-wallet?pathname=/astrologer/" + that.state.astrologerInfo.profileUrl;
            }, 2000);
          } else if (res.data.isSimilar) {
            this.setState({ callLoading: false });
            this.setState({
              warningModalOpen: true,
              warningModalMessage: res.data.message,
            });
          } else {
            this.showError(res.data.message);
            this.setState({ callLoading: false });
          }
        })
        .catch((err) => {
          sentryError("ASTROLOGER_PROFILE_CALL", err);
          this.showError("Error occured, Please try again");
          this.setState({ callLoading: false });
        });
    }
  };

  onFavorite = (e) => {
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + this.props.location.pathname;
    } else {
      this.setState({ isFavoriteLoading: true });
      var sUrl = "";
      this.state.isActive ? (sUrl = "/favourite/remove") : (sUrl = "/favourite/add");
      const isFavourite = this.state.isActive ? false : true;
      if (!this.state.isActive) {
        moengage("FAVORITE_CLICKED");
      }
      axios()
        .post(sUrl, { astrologerId: this.state.astrologerInfo.id })
        .then((res) => {
          if (res.data.success) {
            this.showError(this.state.isActive ? "Removed from Favorites" : "Added to Favorites");
            this.setState({
              isActive: !this.state.isActive,
              isFavoriteLoading: false,
            });
            this.props.handleFavourite && this.props.handleFavourite(this.state.astrologerInfo.id, isFavourite);
          } else {
            this.showError(res.data.message);
            this.setState({ isFavoriteLoading: false });
          }
        })
        .catch((err) => {
          sentryError("ASTROLOGER_PROFILE_ONFAVOURITE", err);
          this.showError("Error occured, Please try again");
          this.setState({ isFavoriteLoading: false });
        });
    }
  };

  getName = (index) => {
    switch (parseInt(index)) {
      case 5: {
        return "Excellent";
      }
      case 4: {
        return "Best";
      }
      case 3: {
        return "Good";
      }
      case 2: {
        return "Average";
      }
      case 1: {
        return "Poor";
      }
      default:
        break;
    }
  };

  isAstrolgerFav = () => {
    this.setState({ isFavoriteLoading: true });
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      this.setState({ isFavoriteLoading: false });
    } else {
      axios()
        .post("/favourite/is-fav", { astrologerId: this.state.astrologerInfo.id })
        .then((res) => {
          if (res.data.success) {
            this.setState({ isActive: !this.state.isActive, isFavoriteLoading: false });
          } else {
            this.setState({ isFavoriteLoading: false });
          }
        })
        .catch((err) => {
          sentryError("ASTROLOGER_PROFILE_ISFAVOURITE", err);
          this.setState({ isFavoriteLoading: false });
        });
    }
  };

  updateAstrologerStatus = (update) => {
    const asCopy = { ...this.state.astrologerInfo };

    if (asCopy.id.toString() === update.message.astrologer_id.toString()) {
      asCopy.status = update.message.status_to;
      asCopy.away_until = update.message.away_until;
      //console.log("arun", new Date(asCopy.away_until));
      //const message = asCopy.name + " is " + update.message.status_to + " now.";
    }

    this.setState({ astrologerInfo: asCopy });
  };

  implementStatusListener = () => {
    const that = this;
    if (typeof EventSource !== "undefined" && !window.statusChangeListener) {
      try {
        const es = new EventSource(STATUS_API_PATH);
        window.statusChangeListener = es;
        var listener = function (event) {
          if (typeof event.data !== "undefined") {
            const update = JSON.parse(event.data);
            //console.log("arun", update);
            if (validateOrigin(update.origin) && update.type === "status" && update.message.astrologer_id) {
              that.updateAstrologerStatus(update);
            }
          }
        };
        window.statusChangeListener = es;
        es.addEventListener("message", listener);
        es.addEventListener("error", (event) => {
          //sentryError("SSE run:Error", event);
          console.error("SSEError", event);
        });
      } catch (err) {
        //sentryError("SSE init:Error", err);
        console.error("SSEError", err);
      }
    }

    const validateOrigin = (origin) => {
      return window.location.origin.indexOf("http://localhost") >= 0 || window.location.origin === origin;
    };
  };

  trackPageview = (data, tracker) => {
    let ast_rating = data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    /** event tracking */
    const type = "fa_screen_ast_profile";
    const payload = {
      p_screen: tracker.screen || "direct",
      p_label: "",
      p_content: data.profileUrl,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience.split(" ")[0],
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: tracker.rank ? tracker.rank.toString() : "NA",
      p_ast_fees: data.price.split("/")[0],
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  trackPujaClick = (type, pdata) => {
    const data = this.state.astrologerInfo;
    let ast_rating = data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    moengage(type);
    const payload = {
      p_screen: "remedy_list",
      p_label: pdata.puja_id,
      p_content: data.profileUrl,
      p_value: pdata ? pdata.discounted_price : 0,
      value: pdata ? pdata.discounted_price : 0,
      currency: "INR",
      p_ast_exp: data.experience.split(" ")[0],
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: data.price.split("/")[0],
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  getCategorizedList = (data) => {
    const out = {};
    const categories = new Set();
    data.forEach((puja) => {
      (puja.category || ["Common"]).forEach((category) => categories.add(category));
    });
    const sortedCList = Array.from(categories);
    sortedCList.sort();
    if (sortedCList.includes("Other")) {
      const oidx = sortedCList.indexOf("Other");
      sortedCList.splice(oidx, 1);
      sortedCList.push("Other");
    }
    sortedCList.forEach((category) => {
      data.forEach((puja) => {
        out[category] = out[category] || [];
        if ((puja.category || ["Common"]).includes(category)) out[category].push(puja);
      });
    });
    return out;
  };

  componentDidMount = () => {
    moengage("PROFILE_PAGE_VIEWED");
    moengage("fa_screen_ast_profile");
    const tracker = this.props.location.state ? this.props.location.state.tracker || {} : {};
    if (this.props.location.status === "fail") {
      this.showError("Something went wrong with the call. Please try again");
    }
    if (this.props.match.params.url && this.props.match.params.url !== "") {
      axios_noheader()
        .post("/astrologers/" + this.props.match.params.url)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              astrologerInfo: res.data.data,
              isLoading: false,
              categorizedPujalist: this.getCategorizedList(res.data.data.pujas),
            });
            this.trackPageview(res.data.data, tracker);
            this.setState({
              galleryData: res.data.data.media,
            });

            this.loadUserReviews();

            if (res.data.data.isPromotional === true) {
              if (FA_localStorage("promoPrice")) {
                this.setState({ nextCallDiscount: FA_localStorage("promoPrice") });
              } else {
                this.setState({ nextCallDiscount: res.data.data.promoDiscount });
              }
            } else {
              this.setState({ nextCallDiscount: FA_localStorage("nextCallPrice") });
            }

            if (this.props.location.isCTAClick) {
              this.setState({ callConfirmModalOpen: true });
            } else if (this.props.location.joinWaitlist) {
              this.onCall();
            }
            this.isAstrolgerFav();
            this.implementStatusListener();
          } else {
            if (res.data.message === "Astrologer not found") {
              window.location.href = "/notfound";
            } else {
              this.showError(res.data.message);
              this.setState({
                isLoading: false,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          sentryError("ASTROLOGER_PROFILE_DETAILS", err);
          this.showError("Error occured, Please try again");
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.showError("Error occured, Please reload the page & try again");
    }

    this.setState({
      nextCallDiscount: this.props.location.state
        ? this.props.location.state.offerDiscount
        : FA_localStorage("currentOffer"),
    });
    if (this.props.location.state) FA_localStorage("currentOffer", this.props.location.state.offerDiscount);
    if (this.props.type !== "popup") window.location.hash = "closeprofilepopup";
    // window.addEventListener("hashchange", this.handleHashChange, true); //commented and moved it to app.js - to fix chat issue
    //this.fetchGalleryItems();
  };

  componentWillUnmount = () => {
    console.log("unmounted profile");
    window.removeEventListener("hashchange", this.handleHashChange, true);
  };

  fetchGalleryItems = () => {
    axios()
      .post("/astrologer/gallery-items")
      .then((res) => {
        this.setState({
          galleryData: res.data.data,
        });
      })
      .catch((err) => {
        sentryError("ASTROLOGER_GALLERY_DETAILS", err);
        this.showError("Error occured, Please try again, ");
      });
  };

  loadUserReviews = () => {
    axios()
      .post("/astrologers/reviews/" + this.props.match.params.url + "?page=" + this.state.reviews.currentPage)
      .then((res) => {
        if (res.data.success) {
          const tdata = [...this.state.reviews.data].concat(res.data.reviews);
          this.setState({
            reviews: {
              currentPage: this.state.reviews.currentPage + 1,
              data: tdata,
              totalReviews: res.data.total_reviews,
            },
          });
        }
      })
      .catch((err) => {
        sentryError("ASTROLOGER_REVIEW_DETAILS", err);
        this.showError("Error occured, Please try again, ");
      });
  };

  goBack = () => {
    //console.log("arun", this.props.history);
    //return this.props.history.length > 1 ? this.props.history.goBack() : (window.location.href = "/");
    if (this.props.type === "popup") this.props.closePopup();
    else window.location.href = "/";
  };

  render() {
    return (
      <div id="astrologerBg">
        <HelmetComponent
          title={this.state.astrologerInfo.name + " @ Findastro"}
          url={"https://www.findastro.com/astrologer/" + this.state.astrologerInfo.profileUrl}
          description={this.state.astrologerInfo.name + "@ Findastro-Connect to your Astrologer."}
        />
        {this.state.isLoading || this.state.callLoading ? (
          <div className="circle-loading circle-loading-20px">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}

        <div className="astrologerTopArea">
          <ArrowBackIcon className="astrologerArrowIcon" onClick={this.goBack} />
          <div id="sideIcons">
            {this.state.astrologerInfo.profileType &&
              this.state.astrologerInfo.profileType.astrologer &&
              (this.state.isFavoriteLoading ? (
                <div>
                  <CircularProgress className="favoriteButtonLoading" />
                </div>
              ) : (
                <>
                  <Favorite
                    className={clsx("astrologerFavIcon", {
                      astrologerFavIconActive: this.state.isActive,
                    })}
                    onClick={this.onFavorite}
                  />
                </>
              ))}
            <Share id="astrologerShareIcon" onClick={this.showDrawer} />
          </div>
        </div>
        <div className="bottomCurve"></div>
        {this.state.astrologerInfo.expertise ? (
          <>
            <div className="profileImage">
              <Avatar
                alt={this.state.astrologerInfo.name}
                className="imageAvatar"
                src={
                  this.state.astrologerInfo.imageUrl
                    ? AstrologerUtils.createProfileImageURI(this.state.astrologerInfo.id)
                    : defaultImage
                }
              />
              {(this.state.astrologerInfo.reviewsData.count || 0) <= 3 ? <div className="newAstBadge">New</div> : <></>}
            </div>
            <div className="profileCard">
              <h3 style={{ color: "#ee600d", margin: "8px 0 0 0" }}>
                {this.state.astrologerInfo.salutation &&
                  this.state.astrologerInfo.name.indexOf(this.state.astrologerInfo.salutation) < 0
                  ? this.upperCaseWords(
                    [this.state.astrologerInfo.salutation, this.state.astrologerInfo.name].join(" ")
                  )
                  : this.upperCaseWords(this.state.astrologerInfo.name)}
              </h3>
              <div className="profileType">
                {this.state.astrologerInfo.profileType.astrologer && this.state.astrologerInfo.profileType.priest
                  ? "( Astrologer & Priest )"
                  : this.state.astrologerInfo.profileType.priest
                    ? "( Priest )"
                    : "( Astrologer )"}
              </div>
              {this.state.astrologerInfo.profileType.astrologer && (
                <div
                  style={{
                    fontSize: "0.8rem",
                    marginBottom: "16px",
                    overflow: "hidden",
                    padding: "0 16px",
                  }}>
                  Expertise in <b>{this.state.astrologerInfo.expertise.join(", ")}</b>
                </div>
              )}
              {this.state.astrologerInfo.profileType && this.state.astrologerInfo.profileType.astrologer && (
                <div>
                  <Button
                    style={{ marginLeft: 8 }}
                    startIcon={<TimerIcon />}
                    id="callButtonMiniButton"
                    className={["callButtonMiniButton", "booknow"]}
                    disabled={this.state.astrologerInfo.bookingAvailable ? false : true}
                    onClick={() => {
                      if (!BrowserUtils.isFALoggedIn()) {
                        if (this.state?.astrologerInfo?.profileUrl) {
                          window.location.href = "/login?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                        } else {
                          window.location.href = "/login?pathname=" + this.props.location.pathname;
                        }
                      } else if (BrowserUtils.hasUserProfile()) {
                        this.setState({ callConfirmModalOpen: true, booking: true });
                        moengage("PROFILE_BOOK_CLICKED");
                      } else if (this.state?.astrologerInfo?.profileUrl) {
                        window.location.href = "/userprofile?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                      } else {
                        window.location.href = "/userprofile?pathname=" + window.location.pathname;
                      }
                    }}>
                    Book consultation
                  </Button>

                  <>
                    {isChatEnabled() && this.state.astrologerInfo.chargesChat != null ? (
                      <>
                        <Button
                          style={{ marginLeft: 8 }}
                          startIcon={
                            this.state.astrologerInfo.status === "online" ? (
                              <>
                                <ChatIcon />
                                <Phone />
                              </>
                            ) : (
                              <PhoneDisabled />
                            )
                          }
                          id="callButtonMiniButton"
                          className={["callButtonMiniButton", this.state.astrologerInfo.status]}
                          onClick={() => {
                            if (!BrowserUtils.isFALoggedIn()) {
                              if (this.state?.astrologerInfo?.profileUrl) {
                                window.location.href = "/login?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                              } else {
                                window.location.href = "/login?pathname=" + this.props.location.pathname;
                              }
                            } else if (BrowserUtils.hasUserProfile()) {
                              this.setState({
                                callConfirmModalOpen: this.state.astrologerInfo.status === "online",
                                booking: false,
                              });
                              moengage("PROFILE_CALL_CLICKED");
                            } else if (this.state?.astrologerInfo?.profileUrl) {
                              window.location.href = "/userprofile?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                            } else {
                              window.location.href = "/userprofile?pathname=" + window.location.pathname;
                            }
                          }}>
                          {this.state.astrologerInfo.status === "online"
                            ? "Consult now"
                            : this.state.astrologerInfo.status}
                        </Button>
                      </>
                    ) : (
                      <Button
                        style={{ marginLeft: 8 }}
                        startIcon={this.state.astrologerInfo.status === "online" ? <Phone /> : <PhoneDisabled />}
                        id="callButtonMiniButton"
                        className={["callButtonMiniButton", this.state.astrologerInfo.status]}
                        onClick={() => {
                          if (!BrowserUtils.isFALoggedIn()) {
                            if (this.state?.astrologerInfo?.profileUrl) {
                              window.location.href = "/login?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                            } else {
                              window.location.href = "/login?pathname=" + this.props.location.pathname;
                            }
                          } else if (BrowserUtils.hasUserProfile()) {
                            this.setState({
                              callConfirmModalOpen: this.state.astrologerInfo.status === "online",
                              booking: false,
                            });
                            moengage("PROFILE_CALL_CLICKED");
                          } else if (this.state?.astrologerInfo?.profileUrl) {
                            window.location.href = "/userprofile?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                          } else {
                            window.location.href = "/userprofile?pathname=" + window.location.pathname;
                          }
                        }}>
                        {this.state.astrologerInfo.status === "online"
                          ? "Consult now"
                          : this.state.astrologerInfo.status}
                      </Button>
                    )}
                  </>
                </div>
              )}
              {this.state.astrologerInfo.profileType && this.state.astrologerInfo.profileType.astrologer && (
                <div className="highLights">
                  <span className="block">
                    <big>
                      {this.state.astrologerInfo.reviewsData.count > 0
                        ? this.state.astrologerInfo.reviewsData.rating.toFixed(1)
                        : "NA"}
                      <small style={{ color: "#ee600d" }}>
                        {this.state.astrologerInfo.reviewsData.count > 0 ? "★" : ""}
                      </small>
                    </big>
                    <small style={{ color: "#999" }}>Avg. rating</small>
                  </span>
                  <span className="block">
                    <big>₹{this.state.astrologerInfo.perMinutePrice * MIN_BALANCE_MINS}</big>
                    <small>for {MIN_BALANCE_MINS} minutes</small>
                  </span>
                  {/* {this.state.astrologerInfo.consultations && this.state.astrologerInfo.consultations.count > 5 ? (
                  <span className="block">
                    <big>{this.state.astrologerInfo.consultations.count}</big>
                    <small style={{color: "#999"}}>Consultations</small>
                  </span>
                ) : (
                  <span className="block">
                    <big>{this.state.astrologerInfo.reviewsData.count || 0}</big>
                    <small style={{color: "#999"}}>reviews</small>
                  </span>
                )} */}
                  <span className="block">
                    <big>{this.state.astrologerInfo.experience.split(" ")[0]} Yrs</big>
                    <small style={{ color: "#999" }}>of experience</small>
                  </span>
                </div>
              )}
              <div className="highLights" style={{ padding: "10px 16px", flexDirection: "column", textAlign: "left" }}>
                {/* <div style={{fontSize: "0.8rem", lineHeight: "1.3rem"}}>
                  <b>Experience: </b>
                  {this.state.astrologerInfo.experience}
                </div> */}
                <div style={{ fontSize: "0.8rem", lineHeight: "1.3rem" }}>
                  <b>My preferred language: </b>
                  {this.state.astrologerInfo.languages[0]}
                </div>
                {this.state.astrologerInfo.languages.length > 1 && (
                  <div style={{ fontSize: "0.8rem", lineHeight: "1.3rem" }}>
                    <b>I also speak & understand: </b>
                    {this.state.astrologerInfo.languages.slice(1).join(", ")}
                  </div>
                )}
              </div>
              {this.state.astrologerInfo.profileType &&
                this.state.astrologerInfo.profileType.astrologer &&
                (this.state.astrologerInfo.free_minutes || this.state.nextCallDiscount ? (
                  <div style={{ padding: "10px 24px", textAlign: "left" }}>
                    <div className="profileHeading">Offers</div>
                    {this.state.nextCallDiscount > 0 && (
                      <div>
                        <span className="offerStar">
                          <OfferStamp style={{ fill: "#f17c38", width: 36, height: 36 }} />
                          <small>
                            <b>{this.state.nextCallDiscount}</b>%
                          </small>
                        </span>
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "0.8rem",
                            verticalAlign: "middle",
                            paddingLeft: 8,
                          }}>
                          Get <b style={{ color: "#ee600d" }}>{this.state.nextCallDiscount}% CASHBACK</b> on this
                          consultation
                        </span>
                      </div>
                    )}
                    {this.state.astrologerInfo.free_minutes > 0 && (
                      <div>
                        <span className="offerStar">
                          <OfferStamp style={{ fill: "#ffcc00", width: 36, height: 36 }} />
                          <small style={{ color: "black" }}>
                            <b>{this.state.astrologerInfo.free_minutes}</b>
                          </small>
                        </span>
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "0.8rem",
                            verticalAlign: "middle",
                            paddingLeft: 8,
                          }}>
                          Get <b style={{ color: "#ee600d" }}>{this.state.astrologerInfo.free_minutes} minutes free</b> on
                          this consultation
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                ))}
              <div style={{ padding: "10px 24px", textAlign: "left" }}>
                <div className="profileHeading">About</div>
                <p
                  className="aboutAstrologerPara"
                  dangerouslySetInnerHTML={{ __html: this.state.astrologerInfo.info }}></p>
              </div>
              {this.state.astrologerInfo.pujas.length > 0 && (
                <div className="pujasByastrologer">
                  <div className="profileHeading" style={{ textAlign: "center", display: "block", margin: "10px 0" }}>
                    <big style={{ color: "#795548" }}>Pujas/havans done by {this.astrologerName()}</big>
                    <br />
                    <Separator className="separator" style={{ fill: "#795548" }} />
                  </div>
                  <div>
                    {Object.keys(this.state.categorizedPujalist).map((category) => (
                      <div>
                        <div className="cat-heading">
                          <b>Category: {category}</b>
                        </div>
                        {this.state.categorizedPujalist[category].map((puja) => (
                          <div
                            className="pujaItem"
                            onClick={() => {
                              this.trackPujaClick("fa_remedy_view_details", puja);
                              window.location.href =
                                "/puja/" + puja.type_puja_id + "?ref=" + this.props.location.pathname;
                            }}>
                            <img src={puja.image} alt={puja.name} />
                            <li>
                              <div className={"title type-" + puja.type}>
                                <b>{puja.name}</b>
                              </div>
                              <div style={this.multiLineStyle}>{puja.short_description.substring(0, 100)}</div>
                            </li>
                            <div style={this.priceBarStyle} className={"price-bar"}>
                              <div className="price">
                                <b>Fees:</b>&nbsp;
                                <strike>₹{puja.actual_price}</strike>
                                <span>₹{puja.discounted_price}</span>&nbsp;(
                                {Math.floor((puja.actual_price - puja.discounted_price) / puja.actual_price / 0.01)}%
                                OFF)
                              </div>
                              <button>Book now</button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {this.state.galleryData && (
                <div className="gallery">
                  {this.state.galleryData.awards && (
                    <div style={{ padding: "10px 24px", textAlign: "left" }}>
                      <div className="profileHeading">Awards and recognitions</div>
                      <div className="gallerySection">
                        <PhotoGallery
                          galleryData={this.state.galleryData.awards}
                          astrologer={this.state.astrologerInfo}
                          type="award"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.galleryData.photo && (
                    <div style={{ padding: "10px 24px", textAlign: "left" }}>
                      <div className="profileHeading">Photo gallery</div>
                      <div className="gallerySection">
                        <PhotoGallery
                          galleryData={this.state.galleryData.photo}
                          astrologer={this.state.astrologerInfo}
                          type="photo"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.galleryData.video && (
                    <div style={{ padding: "10px 24px", textAlign: "left" }}>
                      <div className="profileHeading">Video gallery</div>
                      <div className="gallerySection">
                        <PhotoGallery
                          galleryData={this.state.galleryData.video}
                          video={true}
                          astrologer={this.state.astrologerInfo}
                          type="video"
                        />
                      </div>
                    </div>
                  )}
                  {(this.state.galleryData.message.text ||
                    this.state.galleryData.message.audio ||
                    this.state.galleryData.message.video) && (
                      <div style={{ padding: "10px 24px", textAlign: "left" }}>
                        <div className="profileHeading">Message from {this.astrologerName()}</div>
                        <div className="gallerySection">
                          <MessageGallery
                            galleryData={this.state.galleryData.message}
                            author={this.state.astrologerInfo.name}
                            astrologer={this.state.astrologerInfo}
                          />
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="astrologerWhiteBg">
          <div style={{ height: 24, borderRadius: "0 0 18px 18px", backgroundColor: "#ffffff" }}></div>
          <div id="astrologerTopDiv">
            {!this.state.isLoading ? (
              <>
                {this.state.astrologerInfo.profileType && this.state.astrologerInfo.profileType.astrologer && (
                  <div className="profileReviewsWrap">
                    <Grid className="profileReviews" container spacing={2}>
                      <Grid item xs={4} style={{ textAlign: "center" }}>
                        {this.state.astrologerInfo.reviewsData && this.state.astrologerInfo.reviewsData.count > 0 ? (
                          <>
                            <h3 id="astrologerRatingNumber2">{this.state.astrologerInfo.reviewsData.rating}</h3>
                            <Rating
                              value={this.state.astrologerInfo.reviewsData.rating}
                              readOnly
                              id="astrologerStarRating2"
                            />
                            <p id="astrologerCustomerRating2">
                              {this.state.astrologerInfo.reviewsData.count}&nbsp; reviews
                            </p>
                          </>
                        ) : (
                          <div className="noReviews">
                            <b>No Reviews Yet!</b>
                          </div>
                        )}
                      </Grid>
                      {/*<Grid item xs={8}>
                      <div id="linearRatingDiv">
                        {this.state.astrologerInfo.reviewsData && this.state.astrologerInfo.reviewsData.count > 0 ? (
                          <>
                            {Object.keys(this.state.astrologerInfo.reviewsData.split_count)
                              .sort(function (a, b) {
                                return b - a;
                              })
                              .map((index, rating) => (
                                <div className="linearRatingSingle" key={index}>
                                  <label className="linearRatingSingleText">{this.getName(index)}</label>
                                  <LinearProgress
                                    id={this.getName(index)}
                                    variant="determinate"
                                    value={this.state.astrologerInfo.reviewsData.split[index]}
                                  />
                                  <span>{this.state.astrologerInfo.reviewsData.split_count[index]}&nbsp;</span>
                                </div>
                              ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Grid>*/}
                    </Grid>
                    {this.state.reviews.data.length > 0 ? (
                      <>
                        <div style={{ marginTop: 10, marginBottom: 10, textAlign: "center" }}>
                          <b>
                            Top user testimonials:
                            {/*this.state.reviews.data.length >= 5 ? " " + this.state.reviews.data.length + " " : " "*/}
                          </b>
                        </div>
                        {this.state.reviews.data.map((review, index) => (
                          <div className="astrologerReviewsDiv" key={index}>
                            <div>
                              {/* <Grid item xs={2}>
                              <Avatar
                                className="astrologerReviewsAvatar"
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "#e0c499",
                                }}>
                                {review.letter}
                              </Avatar>
                            </Grid> */}
                              <div style={{ paddingLeft: 10 }}>
                                <div className="astrologerReviewsStarRatingDiv">
                                  {/*<Rating className="astrologerReviewsStarRating" value={review.rating} readOnly />*/}
                                </div>
                                <div className="astrologerReviewReview">{review.comments}</div>
                              </div>
                              <div>
                                <span className="astrologerReviewsUName">~ {review.name}</span>
                                {/* <span className="astrologerReviewsTime">
                                {", "}
                                {review.time}
                              </span> */}
                              </div>
                            </div>
                          </div>
                        ))}

                        {this.state.reviews.totalReviews > this.state.reviews.data.length ? (
                          <div>
                            <button
                              style={{
                                background: "none",
                                border: "none",
                                padding: 20,
                                display: "block",
                                margin: "auto",
                              }}
                              onClick={this.loadUserReviews}>
                              Show more ▼
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
                <div
                  style={{
                    fontSize: "0.6rem",
                    color: "black",
                    opacity: "0.4",
                    padding: "24px 8px 8px 8px",
                    textAlign: "center",
                  }}>
                  AID:{this.state.astrologerInfo.id}
                </div>
                {this.state.astrologerInfo.status === "online" ? (
                  <div id="communicationDiv" style={{ display: "none" }}>
                    {this.state.astrologerInfo.chargesAudio ? (
                      <Button
                        id="callButton"
                        startIcon={<Phone />}
                        className={"callButtonMiniButton"}
                        onClick={() => {
                          if (!BrowserUtils.isFALoggedIn()) {
                            if (this.state?.astrologerInfo?.profileUrl) {
                              window.location.href = "/login?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                            } else {
                              window.location.href = "/login?pathname=" + this.props.location.pathname;
                            }
                          } else if (BrowserUtils.hasUserProfile()) {
                            this.setState({ callConfirmModalOpen: true });
                            moengage("PROFILE_CALL_CLICKED");
                          } else if (this.state?.astrologerInfo?.profileUrl) {
                            window.location.href = "/userprofile?pathname=/astrologer/" + this.state.astrologerInfo.profileUrl;
                          } else {
                            window.location.href = "/userprofile?pathname=" + window.location.pathname;
                          }
                        }}>
                        Consult now
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {BrowserUtils.isAWebview && this.state.callConfirmModalOpen ? (
          <ChatWrapper show={this.state.callConfirmModalOpen}>
            <AstrologerCallDialog
              className={"callDialog"}
              astrologer={this.state.astrologerInfo}
              user={this.state.currentUser}
              extras={{ source: "profile" }}
              handleClose={this.handleClose}
              cashBackDiscount={this.state.nextCallDiscount}
              isStateOpen={this.state.callConfirmModalOpen}
              dialogType={this.state.booking === true ? "booking" : "call"}
              runUserUpdates={this.props.runUserUpdates}
            />
          </ChatWrapper>
        ) : (
          <AppInstallBanner
            className={"installDialog"}
            handleClose={this.handleClose}
            isStateOpen={this.state.callConfirmModalOpen}
          />
        )}
        <Modal open={this.state.warningModalOpen}>
          <div className="warningModal">
            <CloseIcon
              className="profileCloseIcon"
              onClick={() => {
                this.setState({ warningModalOpen: false });
              }}
            />
            <SadIcon className="astrologerProfileSadIcon" />
            <div>{this.state.warningModalMessage}</div>
            <Button variant="contained" className="warningModalButton" onClick={this.similarAstrologer}>
              <span>Find Similar Astrologer</span>
            </Button>
            <div
              className="warningModalSkip"
              onClick={() => {
                moengage("PROFILE_PAGE_SKIP_CLICKED");
                this.setState({ warningModalOpen: false });
              }}>
              Skip and continue browsing
            </div>
          </div>
        </Modal>
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
        <ShareDrawerComponent
          open={this.state.open}
          onDrawerClose={this.showDrawer}
          showError={this.showError}
          title="Hey, checkout this astrologer:"
          profileUrl={
            window.location.origin +
            "/astrologer/" +
            this.state.astrologerInfo.profileUrl +
            "?utm_source=Findastro_web&utm_medium=share&utm_campaign=Web-app"
          }
        />
      </div>
    );
  }
}
