import React, {useEffect, useState} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";

import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import HelmetComponent from "../../../common/HelmetComponent.component";

const RefferAndEarn = (props) => {
  const [code, setCode] = useState(props.refId);

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const submitReferralCode = () => {
    axios()
      .post("/refer-earn/friend-signup", {
        referral_code: code,
      })
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_CANCEL", err);
      });
  };

  const goBack = () => {
    const params = new URLSearchParams(window.location.search);
    window.location.href = params.get("pathname") ? params.get("pathname") : "/";
  };

  useEffect(() => {
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + window.location.href;
    } else {
      axios()
        .post("/refer-earn/update-action", {
          referral_code: code,
          u_type: "view",
        })
        .then((res) => {
          // Do nothing
        })
        .catch((err) => {
          // Do nothing
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="accountParentDiv" style={{backgroundColor: "#ffffff"}}>
      <HelmetComponent
        title="My Wallet at Findastro"
        url="https://www.findastro.com/my-wallet"
        description="My Wallet at Findastro- Connect to your Favorite Astrologer at Findastro"
      />
      <div className="accountTopArea">
        <ArrowBackIcon className="accountArrowIcon2" onClick={goBack} />
        <div className="accountChildTopArea">
          <p>Refer & Earn</p>
        </div>
      </div>
      <img
        style={{maxWidth: "100%"}}
        alt="refer and earn"
        src="https://image.freepik.com/free-vector/bloggers-advertising-referrals-young-people-with-gadgets-loudspeakers-announcing-news-attracting-target-audience-vector-illustration-marketing-promotion-communication_74855-8244.jpg"
      />
      <div>
        <TextField
          autoComplete="code"
          id="code"
          name="code"
          value={code}
          fullWidth
          variant="outlined"
          label="Referral code"
          onChange={handleCodeChange}
          style={{marginTop: "20px"}}
        />
        <Button variant="contained" fullWidth onClick={submitReferralCode} style={{marginTop: "20px"}}>
          Claim Offer
        </Button>
      </div>
    </div>
  );
};

export default RefferAndEarn;
