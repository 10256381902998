import React from "react";
import {withRouter} from "react-router-dom";
import moengage from "../../../common/moengage";

import "./MyAccount.css";

import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FolderIcon from "@material-ui/icons/FolderShared";
import FavoriteIcon from "@material-ui/icons/Stars";
import FamilyIcon from "@material-ui/icons/SupervisedUserCircle";
import SubmitArrowIcon from "@material-ui/icons/ChevronRight";
import LanguageIcon from "@material-ui/icons/Language";

const MyAccountOptionsComponent = (props) => {
  const onNavClick = () => {
    if (props.option.icon === "Family") {
      moengage("FAMILY_PAGE_CLICKED");
      window.location.href = "/familyuserprofiles";
    } else if (props.option.icon === "Folder") {
      moengage("CONSULTATION_PAGE_CLICKED");
      window.location.href = "/user-consultation-history";
    } else if (props.option.icon === "Wallet") {
      moengage("WALLET_PAGE_CLICKED");
      window.location.href = "/my-wallet";
    } else if (props.option.icon === "Favorite") {
      moengage("FAVORITE_PAGE_CLICKED");
      window.location.href = "/my-favorite-astrologer";
    } else if (props.option.icon === "Language") {
      moengage("FAVORITE_PAGE_CLICKED");
      window.location.href = "/my-favorite-languages";
    }
  };

  return (
    <div>
      <div className="accountOptions" onClick={onNavClick}>
        {props.option.icon === "Wallet" ? (
          <WalletIcon className="accountOptionsIcon" />
        ) : props.option.icon === "Favorite" ? (
          <FavoriteIcon className="accountOptionsIcon" />
        ) : props.option.icon === "Folder" ? (
          <FolderIcon className="accountOptionsIcon" />
        ) : props.option.icon === "Family" ? (
          <FamilyIcon className="accountOptionsIcon" />
        ) : props.option.icon === "Language" ? (
          <LanguageIcon className="accountOptionsIcon" />
        ) : (
          <></>
        )}
        <span className="accountOptionsText">{props.option.text}</span>
        {props.option.icon === "Wallet" ? (
          <span id="accountWalletPoints">{props.wallet} Pts</span>
        ) : (
          <SubmitArrowIcon className="accountOptionsArrow" />
        )}
      </div>
    </div>
  );
};
export default withRouter(MyAccountOptionsComponent);
