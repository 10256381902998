import { makeStyles } from "@material-ui/core";
import { ReactComponent as PlayBttn } from "../../../common/play.svg";
import Stories from "react-insta-stories";
import { useEffect, useState } from "react";
import { getAverageRGB } from "../../../App";
import CancelIcon from "@material-ui/icons/Cancel";
import QueryString from "../../../common/util/QueryString";
import YoutubeEmbed from "./YoutubePlayer.component";
import { isMobile } from "react-device-detect";
import moengage from "../../../common/moengage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import T2AEventTrack from "../../../common/T2AEventTrack";

const useStyle = makeStyles({
  galleryImageHolder: {
    height: 110,
    width: "100%",
    display: "block",
    overflow: "hidden",
    "overflow-x": "auto",
    whiteSpace: "nowrap",
    marginTop: 4,
    "&::-webkit-scrollbar": {
      display: isMobile ? "none" : "block",
    },
  },
  galleryItem: {
    width: "auto",
    height: "110px",
    display: "inline-block",
    overflow: "hidden",
    marginRight: "8px",
    borderRadius: "14px",
    position: "relative",
    backgroundColor: "#e4e4e4",
    "& img": {
      width: "auto",
      height: "100%",
    },
  },
  playButton: {
    width: "56px",
    height: "auto",
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: "10",
    transform: "translate(-50%,-50%)",
  },
  instaStory: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    transform: "translate3d(0,0,10px)",
    transition: "opacity 0.4s ease-out",
    zIndex: 1000,
    backgroundColor: "rgba(0,0,0,0.98)",
    "&.fade-out": {
      opacity: 0,
    },
  },
  closeIcon: {
    fill: "#ff9801",
    left: "50%",
    width: "48px",
    mixBlendMode: "screen",
    bottom: "10px",
    height: "48px",
    opacity: "1",
    zIndex: "1002",
    position: "fixed",
    transform: "translateX(-50%)",
  },
  comment: {
    left: "0",
    color: "#fff",
    width: "100vw",
    bottom: "0",
    padding: "8px 16px",
    position: "absolute",
    background: "linear-gradient(0deg,rgb(0 0 0 / 90%),transparent)",
    paddingTop: "72px",
    paddingBottom: "72px",
    whiteSpace: "normal",
  },
});
const PhotoGallery = (props) => {
  const galleryId = "gl-" + Math.ceil(Math.random() * 1234) + Date.now();
  const [showStory, setShowStories] = useState(false);
  const [stories, setStories] = useState(false);
  const [comments, setComments] = useState(false);
  const defaultInterval = 10000;
  const [currentVideo, setCurrentVideoID] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaComment, setMediaComment] = useState("");
  const animInterval = defaultInterval / 1000;
  const [activeAnimation, setActiveAnimation] = useState(`flow-from-left-to-right ${animInterval}.5s linear`);
  const presetHash = window.location.hash;

  const classes = useStyle();
  const showGalleryAsInstaStory = (i) => {
    if (props.video) {
      setStories(false);
      setCurrentVideoID(extractVideoID(props.galleryData.urls[i]));
    } else if (!stories) {
      setCurrentVideoID(null);
      setStories([...props.galleryData.urls]);
      setComments([...props.galleryData.comments]);
    }
    setCurrentIndex(i);
    setShowStories(true);
    trackeventData(props.type, props.astrologer);
  };

  const handleHashChange = () => {
    if (window.location.hash !== "#storyopen") {
      setShowStories(false);
    }
  };

  window.addEventListener("hashchange", handleHashChange, true);

  useEffect(() => {
    window.location.hash = showStory ? "storyopen" : presetHash;
  }, [showStory]);

  const trackeventData = (btnName, data) => {
    if(btnName == 'award') {
      btnName = 'Award and recognitions'
    } else if(btnName == 'video') {
      btnName = 'Video Gallery'
    } else {
      btnName = 'Photo Gallery'
    }
    const payload = {
      event: {
        name: 'Button Action',
        attributes: {
          'Screen Name': 'Astrologer profile',
          'Astrologer ID': data.id,
          'Astrologer Name': data.name,
          'Button Name': btnName,
          'Product': 'COT'
        }
      },
      extra: {
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra)
  };

  const extractVideoID = (url) => {
    const URLParams = QueryString(url);
    return URLParams.v;
  };

  const onStoryChanged = (index) => {
    setActiveAnimation("none");
    setTimeout(() => {
      setActiveAnimation(`flow-from-left-to-right ${animInterval}.5s linear`);
    }, 50);

    setMediaComment(comments && (comments[index] || null));

    /*const image = document.querySelector("#" + galleryId + "-" + index + " > img");
    const rgb = getAverageRGB(image);
    const container = document.querySelector("#fa-ast-story > div");
    container.style.backgroundColor = "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")";*/
  };
  const closeStory = () => {
    const container = document.querySelector("#fa-ast-story");
    container.classList.add("fade-out");
    setTimeout(() => {
      setShowStories(null);
    }, 500);
  };

  return (
    <div className={classes.galleryImageHolder}>
      {props.galleryData &&
        props.galleryData.thumbs.length > 0 &&
        props.galleryData.thumbs.map((thumb, i) => {
          return (
            <div
              className={classes.galleryItem}
              id={galleryId + "-" + i}
              onClick={() => {
                showGalleryAsInstaStory(i);
              }}
              key={i}>
              <img src={thumb} alt="thumbnail" />
              {props.video && <PlayBttn className={classes.playButton} />}
            </div>
          );
        })}
      {showStory && (
        <div className={classes.instaStory} id={"fa-ast-story"}>
          {props.video ? (
            currentVideo ? (
              <YoutubeEmbed embedId={currentVideo} />
            ) : (
              ""
            )
          ) : (
            <Stories
              stories={stories}
              defaultInterval={defaultInterval}
              width={"100%"}
              height={"100%"}
              onStoryStart={onStoryChanged}
              onAllStoriesEnd={closeStory}
              currentIndex={currentIndex}
              storyStyles={{
                width: "auto",
                height: "100%",
                maxWidth: "auto",
                transform: "translate3d(0,0,0)",
                top: 0,
                left: 0,
                animation: activeAnimation,
                position: "absolute",
              }}
            />
          )}
          <span onClick={closeStory}>
            <CancelIcon className={classes.closeIcon} />
          </span>
          <div className={classes.comment}>{mediaComment}</div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
