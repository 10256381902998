import React from "react";

import ReferEarn from "../components/ReferEarn.component";
import QueryString from "../../../common/util/QueryString";

const ReferEarnScreen = (props) => {
  return (
    <div>
      <ReferEarn refId={QueryString().code} />
    </div>
  );
};

export default ReferEarnScreen;
