import React, { useEffect, useState } from "react"

import { withRouter, useHistory } from 'react-router-dom'
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core"
import Snackbar from "../../../../../common/SnackBarMessage"
import style from '../Modal/InsufficientModal.style.module.css'
import AstrologerUtils from "../AstrologerUtils"
import defaultImage from "../../../../../common/default_profile_image.svg"
import CallIcon from '@material-ui/icons/Call';
import ChatBubble from '@material-ui/icons/ChatBubble';
import FA_localStorage from "../../../../../common/util/FA_localStorage"

const InsufficientDialog = (props) => {
  const history = useHistory()
  const [astrologer, setAstrologer] = useState(history.location.state?.astrologer || props.astrologer)
  const [actionType, setActionType] = useState(history.location.state?.actionType || props.actionType)
  const [cancel, setCancel] = useState(history.location.state?.cancel || props.cancel)
  const [openModal, setOpenModal] = useState(props.open !== 'undefined' ? props.open : true)
  const walletBalance = parseInt(FA_localStorage("walletBalance")) || 0
  const minWalletBalance = astrologer?.astrologerFee * astrologer?.MIN_BALANCE_MINS

  // console.log('InsufficientDialog:astrologer', astrologer)
  // console.log('InsufficientDialog:actionType', actionType)
  // console.log('InsufficientDialog:cancel', cancel)
  // console.log('InsufficientDialog:props', props)
  // useEffect(() => {
  //   // console.log(props, props.open !== 'undefined')
  //   if (props.open !== 'undefined') {
  //     setOpenModal(props.open)
  //   }
  // }, [props])


  const [snackBar, setSnackBar] = useState({
    message: '',
    open: false
  })

  const onRechargeNow = () => {
    history.push('/user-wallet')
  }

  // const onClose = () => {
  //   if (props.open !== 'undefined') {
  //     setOpenModal(false)
  //   } {
  //     history.push(cancel || '/app-home')
  //   }
  // }

  return (
    <>
      <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle className="title" onClose={props.handleClose}>Insufficient wallet balance!</DialogTitle>
        <DialogContent dividers>
          <div className={style.insufficientBalance}>
            <strong>Your wallet balance:</strong>
            <h4>{walletBalance} pts</h4>
            <p>Your wallet balance is insufficient to start this
              consultation (Minimum {minWalletBalance} pts required). <span style={{ color: 'var(--clr-red)' }}>Please
                recharge for at least {minWalletBalance} pts to start this consultation.</span></p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button className="primary" variant="contained" onClick={onRechargeNow}>
            Recharge Now
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        vertical="top"
        horizontal="center"
        message={snackBar.message}
        closeError={() => setSnackBar({ ...snackBar, open: false })}
        open={snackBar.open}
      />
    </>
  )
}

export default InsufficientDialog