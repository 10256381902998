import React, { useState, useEffect } from 'react';
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import { useHistory } from "react-router-dom";
import { ChevronLeft } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: '0 .5em',
    boxShadow: 'none',
    backgroundColor: '#fff'
  },
  jsonText: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    padding: '.5em',
    backgroundColor: '#f7f7f7'
  },
  customAlert: {
    margin: '4px 16px'
  }
});
const CotLog = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loggedData, setLoggedData] = useState([]);
  const [checked, setChecked] = useState(BrowserUtils.getLogStatus());
  const [logSize, setLogSize] = useState(0);

  const loadData = () => {
    setLogSize(BrowserUtils.getUsedLocalStorageSpace());
    setLoggedData(BrowserUtils.getLogData())
  }

  useEffect(() => {
    loadData()
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const deleteLog = () => {
    const result = BrowserUtils.deleteLogData();
    if (result) {
      loadData()
    }
  };

  const saveDebugStatus = () => {
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    BrowserUtils.setLogStatus(checked)
  }, [checked]);

  return (
    <div>
      <div className="app-page__header accountTopArea">
        <IconButton className="app-page__header__back-icon" onClick={goBack}>
          <ChevronLeft />
        </IconButton>
        <div className="app-page__header__title">Log</div>
      </div>
      <List subheader={<ListSubheader>Settings</ListSubheader>} className={classes.root}>
        <ListItem>
          <ListItemText id="cot-log-status" primary="Save Log for debugging" />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              onChange={saveDebugStatus}
              checked={checked}
              inputProps={{ 'aria-labelledby': 'cot-log-status' }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Delete Log"
            secondary={`Currently used size is ${logSize}`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={deleteLog}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      {loggedData.length === 0 ?
        (<Alert severity="info" className={classes.customAlert}>No Log found!</Alert>)
        :
        (
          <Card className={classes.root}>
            <CardContent>
              {
                Object.keys(loggedData).map(key => (
                  <div key={key}>
                    <h3>{key}</h3>
                    {loggedData[key].map((row, index) => (
                      <pre className={classes.jsonText} key={index}>{JSON.stringify(row, null, 2)}</pre>
                    ))}

                  </div>
                ))
              }
            </CardContent>
          </Card>
        )
      }
    </div >
  );
}
export default CotLog;