import React, {useState} from "react";
import {isChatEnabled, isUserLoggedIn} from "../../user/astrologer/components/AstrologerUtils";
import Conversations from "../../user/astrologer/components/chat/Conversations";

function ChatPanel({showAstrologerProfile}) {
  const [isConvLoading, setIsConvLoading] = useState(isUserLoggedIn());
  return (
    <div>
      {isChatEnabled() && isUserLoggedIn() ? (
        <Conversations showAstrologerProfile={showAstrologerProfile} setIsConvLoading={setIsConvLoading} />
      ) : (
        <>
          {!isConvLoading && (
            <div id="chat-conv-empty">
              {isChatEnabled()
                ? `You have no chat consultations. Please go to astrologers tab to start chat a consultation.`
                : `Chat is disabled`}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ChatPanel;
