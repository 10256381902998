import React, { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import clsx from 'clsx'

const CountDownTimer = ({ autoStart, expiryTimestamp, className = '', onExpire }) => {
  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp,
    autoStart,
    onExpire,
  });
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    restart(expiryTimestamp)
    setVisible(true)
  }, [expiryTimestamp]);

  return (
    <>
      {visible &&
        <span className={clsx('astrologer-waiting-timer', className, (minutes === 0 && hours === 0) ? 'danger' : 'normal')}>
          {hours > 0 ? String(hours).padStart(2, "0") + ':' : ''}{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </span>
      }
    </>
  );
}

export default CountDownTimer