import defaultImage from "../../../../common/default_profile_image.svg";
import { IMAGE_CDN_PATH } from "../../../../app.config";
import axios from "../../../../common/interceptor";
import BrowserUtils from "./UAScripts";

const AstrologerUtils = {
  createThumbImageURI: (id) => {
    return IMAGE_CDN_PATH + id + "/t/1.jpg?ver=1";
  },
  createProfileImageURI: (id) => {
    return IMAGE_CDN_PATH + id + "/m/1.jpg?ver=1";
  },
  createProfileImage43URI: (id) => {
    return IMAGE_CDN_PATH + id + "/43/43.jpg?ver=1";
  },
  getDefaultImage: (id) => {
    return defaultImage;
  },
  upperCaseFirst: (name) => {
    return name[0].toUpperCase() + name.substring(1);
  },
  upperCaseWords: (str) => {
    str = str.toLowerCase();
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
      return s.toUpperCase();
    });
  },
};

export const debounce = (method, delay) => {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
};

export const secondsToDHM = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hr " : " hrs ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
  return dDisplay + hDisplay + mDisplay;
};

export const formatAmount = (amount) => {
  return Number(amount).toLocaleString("en-IN");
};

export const trackFAEvent = (payload) => {
  try {
    axios()
      .post("/user/add-events", payload)
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
};

export const isUserLoggedIn = () => {
  return localStorage.getItem("phone") !== null && localStorage.getItem("phone") !== "";
};

export const isChatEnabled = () => {
  // const testNumbers = ["9567891324", "9746038663"];
  // const testNumbers = ["9567891324", "9746038663", "9061983650", "9746514714"];
  // const testNumbers = ["8086339040", "9061983650", "9746514714", "9567891324", "9746038663", "9871591537"];

  // const isEnabled =
  //   !(window.location.host === "findastro.com" || window.location.host === "www.findastro.com") ||
  //   testNumbers.includes(localStorage.getItem("phone"));
  // return isEnabled;

  if (BrowserUtils.isNewUI()) {
    return true;
  }

  if (
    window.AndroidBridge &&
    window.AndroidBridge.call("getAppVersion").toString() !== "" &&
    window.compareVersion("4.0.28", window.AndroidBridge.call("getAppVersion")) > 0
  ) {
    return false;
  } else {
    return true;
  }
};

export default AstrologerUtils;
