import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import BrowserUtils from "./UAScripts"
import WalletComponent from "../../../../route/WalletComponent"
import { Dialog, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import WalletNativePage from "../../../account/components/WalletNativePage"
import WalletPage from "../../../account/components/WalletPage"
import DealPurchaseConfig from "../../../feature/deal-purchase/components/DealPurchaseConfig"

const useStyles = makeStyles({
  dialog: {
    overflow: 'visible',
    '& > .MuiDialog-container > .MuiPaper-root': {
      margin: 0,
      maxHeight: '100vh',
      boxShadow: 'none',
      borderRadius: '0',
      border: '0',
      transition: '0s',
    },
    '& > .MuiBackdrop-root': {
      background: '#e2d0b3',
    },
    '&.transparent-page': {
      '& > .MuiBackdrop-root, & > .MuiDialog-container > .MuiPaper-root, & .instant-payment': {
        background: 'transparent',
      },
      '& .new-wallet:not(.instant-payment)': {
        background: '#fff',
      }
    }
  },
})

export const useRechargeRedirect = () => {
  let res = {
    refID: ''
  }
  const LS_KEY = 'recharge_redirect'
  const recharge_redirect = localStorage.getItem(LS_KEY)
  if (recharge_redirect !== null) {
    try {
      res = JSON.parse(recharge_redirect)
      if (res?.refID) {
        const params = res.refID.split('/')
        res.ref = {
          id: res.refID,
          screen: params?.[0],
          action: params?.[1],
          condition: params?.[2],
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return {
    LS_KEY,
    isValid: () => res.refID !== '' && BrowserUtils.isNewUI(),
    getAll: () => res,
    getItem: key => res?.[key] ? res[key] : '',
    setItem: (key, value = '', del = false) => {
      const data = JSON.parse(recharge_redirect)
      if (del) {
        delete data[key]
      } else {
        data[key] = value
      }
      localStorage.setItem(LS_KEY, JSON.stringify(data))
    },
    delete: () => localStorage.removeItem(LS_KEY),
    setData: (refData) => {
      refData.created_time = new Date()
      localStorage.setItem(LS_KEY, JSON.stringify(refData))
    }
  }
}

const RechargeLinkRedirect = ({
  children,
  refData, // <SCREEN>/<ACTION>/<CONDITION>
  onClick = () => { },
  walletType: parentWalletType = 'default',
}) => {
  const history = useHistory()
  const recharge = useRechargeRedirect();
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const urlHash = 'back-button--wallet-recharge'
  const [walletType, setWalletType] = useState(parentWalletType)

  useEffect(() => {
    const handleHashChange = (e) => {
      if (!e.newURL.includes(`#${urlHash}`) && BrowserUtils.isNewUI()) {
        setOpen(false)
      }
    }

    window.addEventListener('hashchange', handleHashChange)
    // return () => {
    //   window.removeEventListener('hashchange', handleHashChange)
    // }
  }, [])

  useEffect(() => {
    if (
      open === true // Is Open
      && BrowserUtils.isNewUI() // Is New UI
      && window.location.hash !== urlHash // Is not the back button hash to avoid repeated hash change
    ) {
      window.location.hash = urlHash
    }
  }, [open])

  useEffect(() => {
    setWalletType(parentWalletType)
  }, [parentWalletType])

  const browserBackTrigger = () => {
    if (!window.location.hash.includes(`#${urlHash}`) && open === true && BrowserUtils.isNewUI()) {
      history.goBack()
    }
  }

  const refDataToStr = () => {
    // const rlr_id = 'RLR' + new Date().getTime().toString().split('').splice(5).sort(() => Math.random() - 0.5).join('')
    // console.log('refDataToStr:', rlr_id, btoa(JSON.stringify(refData)))
    return JSON.stringify(refData)
  }

  const onRecharge = () => {
    onClick({
      goToWallet
    })
  }

  const goToWallet = () => {
    // console.log('rr:recharge_redirect', refDataToStr())
    recharge.setData(refData)
    // history.push('/user-wallet')
    if (BrowserUtils.isNewUI()) {
      setOpen(true)
    } else {
      history.push('/my-wallet')
    }
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    }
    browserBackTrigger()
    setOpen(false)
  }

  return (
    <>
      {React.cloneElement(children, { onClick: onRecharge })}
      <Dialog
        className={clsx(
          classes.dialog,
          'wallet-recharge-dialog',
          refData?.wallet_transparent_page ? 'transparent-page' : '',
        )}
        open={open}
        onClose={handleClose}
      >
        {/* walletType: `{walletType}` */}
        {open === true ?
          // (walletType === 'native' ? <WalletNativePage /> : <WalletComponent />)
          <WalletComponent />
          : <></>
        }
      </Dialog>
    </>
  )
}

export default RechargeLinkRedirect