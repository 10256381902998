import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moengage from "../../../../../common/moengage";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { format } from "date-fns";

import "./css/AstrologerDetail.css";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import CallIcon from '@material-ui/icons/Call';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import PhoneDisabled from "@material-ui/icons/PhoneDisabled";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Favorite from "@material-ui/icons/Favorite";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import TimerIcon from "@material-ui/icons/Schedule";
import theme from "../../../../../theme/App.theme";
import { ReactComponent as OfferStamp } from "../offer.svg";
import AstrologerUtils, { isChatEnabled } from "../AstrologerUtils";
import defaultImage from "../../../../../common/default_profile_image.svg";
import FA_localStorage from "../../../../../common/util/FA_localStorage";
import axios from "../../../../../common/interceptor";
import sentryError from "../../../../../common/sentryError";
import BrowserUtils from "../UAScripts";
import Star from "@material-ui/icons/Star";
import { MIN_BALANCE_MINS } from "../../../../../app.config";
import PositionedSnackbar from "../../../../../common/SnackBarMessage";
import AstrologerCallDialog from "../AstrologerCallDialog";
import InsufficientModal from "../Modal/InsufficientModal";
import VerifyNumber from "../Modal/VerifyNumber";
import InsufficientDialog from "../Dialog/InsufficientDialog";
import T2AEventTrack from "../../../../../common/T2AEventTrack";
import RechargeLinkRedirect, { useRechargeRedirect } from "../RechargeLinkRedirect";
import DealPurchase from "../../../../feature/deal-purchase/components/DealPurchase";
import DealPurchaseDialog from "../../../../feature/deal-purchase/components/DealPurchaseDialog";
import DealPurchaseConfig from "../../../../feature/deal-purchase/components/DealPurchaseConfig";
import RatingStar from "../../../../common/components/RatingStar";

const useStyles = makeStyles({
  astrologerCardCTA: {
    zIndex: "11",
    background: theme.palette.green.main,
    color: "#fff",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "5px 15px",
    borderRadius: "20px",
    whiteSpace: "nowrap",
    minWidth: "80px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
      "& svg": {
        fontSize: "16px",
      },
    },
    // "&:hover": {
    //   background: 'var(--clr-grey)',
    // },
  },
  audio: {
    height: 36,
    width: "100%",
  },
  iconFavorite: {
    fill: "#f44336",
    top: "48px",
    right: "12px",
    position: "absolute",
  },
  iconFavoriteDisabled: {
    fill: "#f2d3a3",
    top: "48px",
    right: "12px",
    position: "absolute",
  },
  callCTABlock: {
    width: "calc(100% - 90px)",
    position: "absolute",
    right: "0",
    top: "0",
    height: "calc(100% - 32px)",
    textAlign: "right",
    padding: "8px 8px 8px 0",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    borderRadius: "0 12px 12px 0",
  },
  callCTABlockFavourite: {
    background: "linear-gradient(40deg, transparent 78%, #cacaca 79%,#cccccc 79%,#ec0060 80%, #ec0060)",
  },
  statusCaption: {
    display: "inline-block",
    paddingTop: "0px",
    paddingRight: "4px",
    verticalAlign: "middle",
    maxWidth: "calc(100% - 116px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  statusCaptionHome: {
    display: "inline-block",
    paddingTop: "0px",
    paddingRight: "4px",
    verticalAlign: "middle",
    maxWidth: "calc(100% - 66px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  astrologerCardDisCTA: {
    zIndex: "11",
    background: "#f2f2f2",
    fontSize: "11px",
    color: "#bbbbbb",
    padding: "5px 15px",
    borderRadius: "20px",
    marginRight: "5px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
      "& svg": {
        fontSize: "14px",
      },
    },
  },
  astrologerCardJoinCTA: {
    zIndex: "11",
    background: "#fff0",
    fontSize: "0.8rem",
    padding: "5px 15px",
    color: "#ffffff",
    fontWeight: "bold",
    borderRadius: "20px",
    marginLeft: "5px",
    "& .MuiButton-startIcon": {
      marginRight: "4px",
      "& svg": {
        fontSize: "18px",
      },
    },
    "&:hover": {
      background: "#f07c38",
    },
  },
  onSimilarClass: {
    zIndex: "10",
  },
  name: {
    fontSize: "0.9rem",
    fontWeight: "bold",
    display: "block",
    paddingRight: 64,
    width: "100%",
    alignItems: "center",
    color: "#ee600d",
    whiteSpace: "nowrap",
    paddingBottom: "4px",
    paddingTop: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
  },
  astrologerPlace: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "30%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.8rem",
  },
  message: {
    color: "#3c3c3c",
    fontSize: "0.7rem",
    fontWeight: "bold",
    width: "74px",
    textAlign: "center",
    display: "block",
    position: "absolute",
    top: "64px",
    left: "10px",
    zIndex: "4",
    background: "#ffff00",
    padding: "2px 0",
    borderRadius: "0 0 16px 16px",
    borderTop: "2px solid #fff",
  },
  marginB5: {
    marginBottom: "5px",
  },
  noReviews: {
    textAlign: "center",
    fontSize: "0.7rem",
  },
  verified: {
    width: "14px",
    transform: "translate(-2px,-1px)",
    verticalAlign: "middle",
    color: "#ee600d",
  },
  expertise: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    fontSize: "0.9rem",
    color: "#212121",
    fontWeight: "500",
    marginTop: "-6px",
    paddingRight: "64px",
  },
  langExpertise: {
    color: "#212121",
    display: "block",
    overflow: "hidden",
    maxWidth: "95%",
    paddingTop: "0px",
    whiteSpace: "nowrap",
    marginBottom: "8px",
    textOverflow: "ellipsis",
    opacity: "0.8",
    marginTop: "-4px",
  },
  langExpertiseMain: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    paddingTop: "0px",
    maxWidth: "95%",
    fontSize: "0.9rem",
    marginTop: 4,
    color: "#212121",
  },
  notifyMe: {
    background: "#9c9c9c",
    color: "#fff",
    "&:hover": {
      background: "#9c9c9c",
    },
    "&:hover svg": {
      animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97) both`,
    },
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0)" },
    "15%": { transform: "rotate(10deg)" },
    "30%": { transform: "rotate(-10deg)" },
    "45%": { transform: "rotate(5deg)" },
    "60%": { transform: "rotate(-5deg)" },
    "75%": { transform: "rotate(3deg)" },
    "85%": { transform: "rotate(-3deg)" },
    "92%": { transform: "rotate(1deg)" },
    "100%": { transform: "rotate(0)" },
  },
});

// For testing
const testNonLoggedUsers = false

const isDealAvailable = (astrologer) => {
  if (DealPurchaseConfig.is_enabled) {
    if (astrologer?.deal_id?.length) {
      return true
    }

    // Return deal available for testing non-logged in users
    if (testNonLoggedUsers) {
      return true
    }
  }

  return false
}

const getDeal = (astrologer = {}) => {
  let deal = {}
  let dealIds = astrologer?.deal_id
  let deals = astrologer?.deals
  // console.log('getDeal:deals:', astrologer.name, deals, dealIds)
  if (deals?.length && dealIds?.length) {
    const selectDeal = deals.find(deal => deal.id === dealIds[0])
    // console.log('selectDeal:', selectDeal)
    if (selectDeal?.id) {
      deal = selectDeal
    }
  }

  // Return dummy deal data for testing non-logged in users
  if (testNonLoggedUsers) {
    deal = {
      "id": 20,
      "amount": 1,
      "points": 0,
      "plan_type": "DEAL",
      "deal_type": "FREE_CONSULT_MINUTES",
      "minutes": 5,
      "gst": false
    }
  }
  return deal
}


const ActionBtn = (props) => {
  const history = useHistory()
  const type = props.type
  const onlineText = props.onlineText || ''
  const currentURL = props.currentURL || '/app-home'
  const family = props.family
  const walletBalance = parseInt(FA_localStorage("walletBalance")) || 0
  const [snackBar, setSnackBar] = useState({ message: '', open: false })
  const [isCallDlgStateOpen, setCallDlgStateOpen] = useState(false)
  const [callDlgType, setCallDlgType] = useState(type)
  const [callDlgExtra, setCallDlgExtra] = useState({})
  family.astrologerFee = family.price ? parseInt(family.price.split("/")[0], 10) : 0;
  family.MIN_BALANCE_MINS = MIN_BALANCE_MINS;
  const [openInsufficientModal, setOpenInsufficientModal] = useState(false)
  const [openVerifyNumberModal, setOpenVerifyNumberModal] = useState(false)
  const [verifyNumberAndroidLogin, setVerifyNumberAndroidLogin] = useState(false)
  const btnEl = useRef()
  const rechargeRedirect = useRechargeRedirect()
  const [deal, setDeal] = useState(getDeal(family))
  const hasDealAvailable = isDealAvailable(family)
  const [openDealPurchase, setOpenDealPurchase] = useState(false)

  useEffect(() => {
    if (props.triggerActionBtn?.open === true && openInsufficientModal === false) {
      // console.log('triggerActionBtn', props.triggerActionBtn, btnEl)
      const clList = [...btnEl.current.classList]
      if (clList.includes('btn-action--call') || !props.isSingle) {
        btnEl.current.click()
      }
    }
  }, [props.triggerActionBtn])

  useEffect(() => {
    if (btnEl?.current) {
      btnEl.current.astrologer = family
      btnEl.current.consult_type = type
    }
  }, [])

  useEffect(() => {
    setDeal(getDeal(family))
  }, [props.family])

  const handleDlgClose = () => {
    setCallDlgStateOpen(false);
  }

  const startNewConsultation = (data) => {
    if (props.startNewConsultation && typeof props.startNewConsultation === 'function') {
      props.startNewConsultation(data)
    }
  }

  const runUserUpdates = () => {
    // axios()
    //   .post("/profile/updates")
    //   .then((res) => {
    //     if (res && res.data.success) {
    //       // const details = { ...offerDetails };
    //       // details.nextCallDiscount = res.data.data.discount_per || 0;
    //       // setofferDetails(details);
    //       // setIsPaidUser(res.data.data.recharges > 0);
    //       // const pr = { ...res.data.data.last_consultation_review };
    //       // pr.rating = pr.rating || 5;
    //       // setPendingReview(pr);
    //       // setOfferClaim(res.data.isNUser);
    //       FA_localStorage("nextCallPrice", details.nextCallDiscount);
    //       FA_localStorage("user_languages", res.data.data.languages);
    //       FA_localStorage("walletBalance", res.data.data.wallet_balance || 0);
    //       setLangExpertise(res.data.data.language_expertise);
    //       checkPendingReferEarn(res.data.data.latest_refer_earn);

    //       checkPendingConsultation({ message: false });
    //       setUpcomingBookings(res.data.data.active_bookings);

    //       // if (FA_localStorage("registrationInit") === "true") {
    //       //   if (res.data.data.wallet_balance > 0) {
    //       //     setShowRegnOffer({ status: res.data.data.wallet_balance > 0 });
    //       //     setTimeout(() => {
    //       //       setShowRegnOffer({
    //       //         status: res.data.data.wallet_balance > 0,
    //       //         blastConfetti: true,
    //       //       });
    //       //     }, 100);
    //       //   } else {
    //       //     setTimeout(() => {
    //       //       setShowRegnOffer({ nextCallStatus: true });
    //       //     }, 2000);
    //       //     setTimeout(() => {
    //       //       setShowRegnOffer({ nextCallStatus: true, blastNextCallConfetti: true });
    //       //     }, 2100);
    //       //   }
    //       // }
    //       //FA_localStorage("activeConsultation", JSON.stringify(res.data.data.active_consultation));
    //     }
    //   })
    //   .catch((err) => {
    //     sentryError("ASTROLOGER_LIST_NEW_USER", err);
    //   });
  }

  const onActionBtnClick = (e, extra) => {
    e.stopPropagation()
    e.preventDefault()

    if (hasDealAvailable && family.status === 'online' && BrowserUtils.isLoggedIn()) {
      setOpenDealPurchase(true)
      return
    }

    // Trigger onConsultButtonClick
    if (props.onConsultButtonClick && typeof props.onConsultButtonClick === 'function') {
      props.onConsultButtonClick({ event: e, extra })
    }

    // if (BrowserUtils.isLoggedIn()) {
    //   props.handleCall(family, cardType, extra || { source: "astrologer_card" });
    //   if (extra && extra.booking) {
    //     trackActions("fa_booking_intent", cardType === "promotional" ? "promotional" : "main");
    //   } else {
    //     trackActions("fa_consult_intent", cardType === "promotional" ? "promotional" : "main");
    //   }
    // } else {
    //   // window.location.href = "/login?pathname=" + window.location.pathname;

    //   // history.push('#verify-number')
    // }

    // TODO: testing for Android
    // if (family.status === 'offline') {
    //   setOpenVerifyNumberModal(true)
    //   setVerifyNumberAndroidLogin(true)
    //   return
    // }

    // family.away_until_mins = 10
    // family.status = 'busy'

    let screenName = 'Chat or Talk';

    const clickFromToScreenName = {
      chat: 'Chat Screen',
      astrologerRecommendation: 'Chat Screen',
      'Astrologer profile bottom': 'Astrologer profile',
      'Astrologer profile top': 'Astrologer profile',
      'Try another astrologer - Yes, start consultation': 'Chat Screen',
    };

    if (props?.clickFrom) {
      if (clickFromToScreenName[props.clickFrom]) {
        screenName = clickFromToScreenName[props.clickFrom];
      } else if (props.clickFrom.includes('/astrologer-profile')) {
        screenName = 'Astrologer profile';
      }
    }

    if (BrowserUtils.isLoggedIn()) {

      const payload = {
        event: {
          name: "Button Action",
          attributes: {
            'Screen Name': screenName,
            'Button Name': extra.type,
            'Astrologer ID': family.id,
            'Astrologer Name': family.name,
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name,
            'Button Status': family.status,
            'Button Location': (extra.currentURL == '/app-home') ? 'List' : props.clickFrom,
            'Consultation Type': (family.free_minutes && family.free_minutes > 0) ? 'Free' : 'Paid',
            'Product': 'COT'
          }
        },
        extra: {
          user: {
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name
          }
        }
      }
      T2AEventTrack(payload.event, payload.extra);

      // console.log(family, extra)

      if (family.status === 'online') {
        // if (!(family.free_minutes > 0 || walletBalance > 0)) {
        if (!(family.free_minutes > 0 || family.astrologerFee * family.MIN_BALANCE_MINS <= walletBalance)) {
          // history.push('#insufficient-balance', { astrologer: family, actionType: extra.type, cancel: extra.currentURL })
          setCallDlgType(extra.type)
          setOpenInsufficientModal(true)
          return
        }
      }

      if (family.status === 'busy') {
        const awayStr = family.away_until_mins ? ' for ' + family.away_until_mins + ' mins' : '';
        setSnackBar({
          message: `Sorry, ${family.name} is currently busy${awayStr}. Please try again later.`,
          open: true
        })
        return
      }

      if (family.status === 'offline') {
        setSnackBar({ message: `Sorry, ${family.name} is offline now.`, open: true })
        return
      }

      if (family.status === 'away') {
        const awayStr = family.away_until_mins ? ' for ' + family.away_until_mins + ' mins' : ' for some time';
        setSnackBar({ message: `Sorry, ${family.name} is away${awayStr}.`, open: true })
        return
      }

      // console.log('extra.type:', extra.type)

      if (props.beforeNewConsultation && typeof props.beforeNewConsultation === 'function') {
        props.beforeNewConsultation()
      }
      setCallDlgType(extra.type)
      setCallDlgStateOpen(true)

      // history.push('#astrologer-action', { astrologer: family, actionType: extra.type, cancel: extra.currentURL })
    } else {
      const clList = [...e.target.classList]
      // console.log(clList, clList.includes('btn-action'), clList.includes('show--popup'))

      if (clList.includes('show--popup')) {
        rechargeRedirect.delete()
        if (hasDealAvailable) {
          const refData = DealPurchase.getRefData({
            astrologer: family,
            recharge: deal,
            type
          })

          const newRefData = {
            ...refData,
            refID: `${props.isSingle ? 'profile/' : 'listing/'}${type}-button`,
            astrologerID: props.family.id,
            astrologerName: props.family.name,
            astrologerProfileURL: props.family.profileUrl,
            astrologerCharge: props.family?.perMinutePrice || 0,
            location: 'Deal purchase login',
            highlight: 499,
            maxAmount: 1000,
            type,
            birthDetailsRequired: true, // Required: to show the birth details form
            afterLogin: true // Required: to show the deal purchase dialog
          }

          rechargeRedirect.setData(newRefData)
        }
      } else {
        const payload = {
          event: {
            name: "Button Action",
            attributes: {
              'Screen Name': screenName,
              'Button Name': e.target.textContent || 'Recharge',
              'Astrologer ID': family.id,
              'Astrologer Name': family.name,
              'User ID': BrowserUtils.CurrentUserInfo().id,
              'User Name': BrowserUtils.CurrentUserInfo().name,
              'Button Status': family.status,
              'Button Location': (extra.currentURL === '/app-home') ? 'List' : props.clickFrom,
              'Consultation Type': (family.free_minutes && family.free_minutes > 0) ? 'Free' : 'Paid',
              'Product': 'COT'
            }
          },
          extra: {
          }
        }
        T2AEventTrack(payload.event, payload.extra);

        if (hasDealAvailable) {
          const refData = DealPurchase.getRefData({
            astrologer: family,
            recharge: deal,
            type
          })

          const newRefData = {
            ...refData,
            refID: `${props.isSingle ? 'profile/' : 'listing/'}${type}-button`,
            astrologerID: props.family.id,
            astrologerName: props.family.name,
            astrologerProfileURL: props.family.profileUrl,
            astrologerCharge: props.family?.perMinutePrice || 0,
            location: 'Deal purchase login',
            highlight: 499,
            maxAmount: 1000,
            type,
            birthDetailsRequired: true, // Required: to show the birth details form
            afterLogin: true // Required: to show the deal purchase dialog
          }

          rechargeRedirect.setData(newRefData)
        } else {
          rechargeRedirect.setData({
            refID: `${props.isSingle ? 'profile/' : 'listing/'}${type}-button`,
            astrologerID: props.family.id,
            astrologerName: props.family.name,
            astrologerProfileURL: props.family.profileUrl,
            astrologerCharge: props.family?.perMinutePrice || 0,
            location: 'Insufficient balance',
            highlight: 499,
            maxAmount: 1000,
            type,
            birthDetailsRequired: true
          })
        }
      }

      if (clList.includes('show--popup')) {
        console.log('setOpenVerifyNumberModal from show--popup')
        setOpenVerifyNumberModal(true)
        e.target.classList.remove('show--popup')
      } else if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {

        if (window.isAppProfileDataHasEmail()) {
          BrowserUtils.devAlert('isAppProfileDataHasEmail - Open verify popup')
          setOpenVerifyNumberModal(true)
          return
        }
        BrowserUtils.devAlert('isReadyToLogin')
        window.AndroidBridge.call("isReadyToLogin")
      } else {
        setOpenVerifyNumberModal(true)
      }

      // history.push('#verify-number')
    }
    return
  }

  let attr = { text: family.status === 'online' && onlineText ? onlineText : 'Call', icon: <CallIcon /> }
  let busyText = ''

  if (family.chargesChat && type === 'chat') {
    attr = { text: family.status === 'online' && onlineText ? onlineText : 'Chat', icon: <ChatBubbleOutlineOutlinedIcon /> }
  }

  if (hasDealAvailable) {
    attr.text = <>{attr.text} for&nbsp;₹{deal?.amount}</>
  } else if (family.status === "away") {
    attr.text = 'Away'
  } else if (family.status === "busy") {
    attr.text = 'Busy'

    if (family?.away_until_mins) {
      busyText = `Wait - ${family.away_until_mins} mins`
    }
  }

  return (
    <>
      <Button
        className={clsx(
          // family.chargesAudio ? classes.astrologerCardCTA : classes.astrologerCardDisCTA,
          family.status,
          'btn-action',
          `btn-action--${type}`,
          props.className ? props.className : '',
          isDealAvailable(family) ? 'btn-action--deal-purchase' : ''
        )}
        startIcon={attr.icon}
        // onClick={props.isSearch ? onCTAClick : onCTAClickDialog}
        onClick={e => onActionBtnClick(e, { type, currentURL })}
        ref={btnEl}
        data-aid={family.id}
      >
        {busyText && <span className="top-text">{busyText}</span>}
        {attr.text}
      </Button>

      <PositionedSnackbar
        vertical="top"
        horizontal="center"
        message={snackBar.message}
        closeError={() => setSnackBar({ ...snackBar, open: false })}
        open={snackBar.open}
      />

      <AstrologerCallDialog
        className={"callDialog"}
        astrologer={family}
        handleClose={handleDlgClose}
        isStateOpen={isCallDlgStateOpen}
        dialogType={callDlgType}
        extras={callDlgExtra}
        cashBackDiscount={props.cashBackDiscount}
        runUserUpdates={runUserUpdates}
        clickFrom={currentURL}
        skipBirthDetailsForm={props.skipBirthDetailsForm || false}
        startNewConsultation={startNewConsultation}
      />

      {/* <InsufficientDialog
        astrologer={family}
        open={openInsufficientModal}
        handleClose={() => setOpenInsufficientModal(false)}
      /> */}

      <InsufficientModal
        clickFrom={currentURL}
        astrologer={family}
        dialogType={callDlgType}
        open={openInsufficientModal}
        handleClose={() => setOpenInsufficientModal(false)}
      />

      <VerifyNumber
        astrologer={family}
        open={openVerifyNumberModal}
        verifyNumberAndroidLogin={verifyNumberAndroidLogin}
        handleClose={() => setOpenVerifyNumberModal(false)}
      />

      <DealPurchaseDialog
        astrologer={family}
        deal={deal}
        type={type}
        open={openDealPurchase}
        clickFrom={props?.clickFrom || 'Chat or Talk'}
        handleClose={() => {
          // console.log('handleClose', openDealPurchase)
          setOpenDealPurchase(false)
        }}
      />
    </>
  )
}

const AstrologerDetail = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const family = props.family;
  const actionType = props?.currentTab?.type || props.currentConsultType;
  const offerDiscount = props.offerDiscount;
  const isSingle = props.isSingle || false;
  const cardType = props.type;
  const hide = props?.hide || false;
  const isLoggedIn =
    (!localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined") === false;
  const [snackBar, setSnackBar] = useState({ message: '', open: false })
  const [isFavActive, setIsFavActive] = useState(family.is_favourite)
  const walletBalance = parseInt(FA_localStorage("walletBalance")) || 0
  family.free_minutes = family?.[`free_${actionType}_minutes`]
  const [triggerActionBtn, setTriggerActionBtn] = useState({ open: false })
  const [deal, setDeal] = useState(getDeal(family))

  useEffect(() => {
    setDeal(getDeal(family))
  }, [props.family])

  const trackeventData = (data) => {
    const payload = {
      event: {
        name: (data.name) ? data.name : "Button Action",
        attributes: {
          'Screen Name': data.ScreenName,
          'Astrologer ID': data.AstrologerId,
          'Astrologer Name': data.AstrologerName,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Product': 'COT'
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }

    if (data.extra) {
      payload.extra = data.extra;
    }

    if (data.sourceScreenName) {
      payload.event.attributes['Source Screen Name'] = data.sourceScreenName;
    }

    if (data.buttonName) {
      payload.event.attributes['Button Name'] = data.buttonName;
    }
    if (data.product) {
      payload.event.attributes['Product'] = data.product;
    }

    if (data.buttonStatus) {
      payload.event.attributes['Button Status'] = data.buttonStatus;
    }

    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  };

  const strikeStyle = { color: "#5d5d5d", fontWeight: "400", fontSize: "0.8rem" };
  const offerStampStyle = { fill: "#795548", width: 36, height: 36 };

  const astrologerProfile = () => {
    window.stopAllAudio();
    if (props.isSearch) {
      console.log('astrologer profile search');
      const payload = {
        'buttonName': 'View astrologer profile',
        'ScreenName': 'Search astrologer',
        'AstrologerId': family.id,
        'AstrologerName': family.name,
        'buttonStatus': family.status
      }
      trackeventData(payload);
    }
    if (props.openProfile) {
      console.log(family);
      props.openProfile(family);
      console.log('astrologer - open profile');
      const payload = {
        'buttonName': 'Astrologer Card',
        'ScreenName': 'Call or Talk',
        'AstrologerId': family.id,
        'AstrologerName': family.name,
        'sourceScreenName': actionType
      }
      trackeventData(payload);

    } else {
      //console.log(offerDiscount)
      console.log('astrologer - dont profile');
      history.push({
        pathname: "/astrologer/" + family.profileUrl,
        state: {
          //offerDiscount: offerDiscount,
          tracker: {
            screen: props.isSearch ? "search" : "home",
            rank: family.rank || "NA",
          },
        },
      });

      if (!props.isSearch) {
        const payload = {
          'name': 'Screen View Action',
          'ScreenName': 'Astrologer profile',
          'AstrologerId': family.id,
          'AstrologerName': family.name,
          'sourceScreenName': actionType
        }
        trackeventData(payload);
      }

    }

  };
  const stopPropagation = (e) => {
    e && e.stopPropagation();
    window.stopAllAudio();
  };
  const onCTAClick = (e) => {
    e && stopPropagation(e);
    if (window.location.pathname === "/") moengage("LIST_PAGE_CALL_CTA");
    else if (window.location.pathname.startsWith("/search")) {
      moengage("SEARCH_PAGE_CALL_CTA");
    } else if (window.location.pathname.startsWith("/similar-astrologer")) {
      moengage("SIMILAR_ASTROLOGER_CALL_CTA");
    }
    history.push({
      pathname: "/astrologer/" + family.profileUrl,
      state: {
        tracker: {
          screen: props.isSearch ? "search" : "home",
          rank: family.rank || "NA",
        },
      },
      isCTAClick: false,
    });
  };
  const onCTAClickDialog = (e, extra) => {
    stopPropagation(e);
    if (BrowserUtils.isLoggedIn()) {
      props.handleCall(family, cardType, extra || { source: "astrologer_card" });
      if (extra && extra.booking) {
        trackActions("fa_booking_intent", cardType === "promotional" ? "promotional" : "main");
      } else {
        trackActions("fa_consult_intent", cardType === "promotional" ? "promotional" : "main");
      }
    } else {
      window.location.href = "/login?pathname=" + window.location.pathname;
    }
  };

  const doNothing = (e) => {
    stopPropagation(e);
  };

  const clickNotifyMe = (e) => {
    stopPropagation(e);
    props.handleNotify(family.id, "add");
    trackActions("fa_button_notify", "add");
  };

  const clickRemoveNotifyMe = (e) => {
    stopPropagation(e);
    props.handleNotify(family.id, "remove");
    trackActions("fa_button_notify", "remove");
  };

  const getAwayCaption = (status, awayuntiltime) => {
    if (status === "away") {
      return formatAwayCaption(status, awayuntiltime);
    } else {
      return statusCaption(status);
    }
  };

  const formatAwayCaption = (status, awayuntiltime) => {
    /* away for minutes adjustment */
    //console.log("arun", awayuntiltime);
    let now = new Date();
    now.setSeconds(0);
    awayuntiltime = awayuntiltime * 1000;

    if (new Date(awayuntiltime).getTime() <= now.getTime() || awayuntiltime === null) {
      return upperCaseWords(status);
    } else {
      const away = new Date(awayuntiltime);

      if (away.getDate() === now.getDate()) {
        return "is away till " + format(away, "hh:mm a");
      } else {
        return "is away till " + format(away, "MMM dd, hh:mm a");
      }
    }
  };

  const statusCaption = (status) => {
    if (status === "busy") return "is online";
    else return "is " + status.toLowerCase();
  };

  const upperCaseWords = (str) => {
    str = str.toLowerCase();
    return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
      return s.toUpperCase();
    });
  };

  const tryToLogin = () => {
    setTriggerActionBtn({
      open: true
    })
  }

  const onFavorite = (e) => {
    stopPropagation(e);
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      // window.location.href = "/login?pathname=" + window.location.pathname;
      tryToLogin()
    } else {
      var sUrl = "";
      props.showLoading(true);
      isFavActive ? (sUrl = "/favourite/remove") : (sUrl = "/favourite/add");

      const payload = {
        'ScreenName': (props.currentConsultType) ? 'Chat or Talk' : 'Astrologer profile',
        'AstrologerId': family.id,
        'AstrologerName': family.name,
        'buttonName': (isFavActive) ? 'Removed from favorite' : 'Add to favorite',
        'sourceScreenName': actionType
      }
      trackeventData(payload);

      const astName =
        family.salutation && family.name.indexOf(family.salutation) < 0
          ? upperCaseWords([family.salutation, family.name].join(" "))
          : upperCaseWords(family.name);
      axios()
        .post(sUrl, { astrologerId: family.id })
        .then((res) => {
          if (res.data.success) {
            props.showMessage(
              isFavActive ? "Removed " + astName + " from Favorites" : "Added  " + astName + " to Favorites"
            );
            //props.handleFavourite(family.id, !isFavActive);
            console.log('test', isFavActive)
            setIsFavActive(!isFavActive);
          } else {
            props.showMessage(res.data.message);
          }
          props.showLoading(false);
        })
        .catch((err) => {
          sentryError("ASTROLOGER_PROFILE_ONFAVOURITE", err);
          props.showMessage("Error occured, Please try again");
          props.showLoading(false);
        });
    }
  };

  const trackActions = (type, label) => {
    moengage(type);
    const data = family;
    let ast_rating = data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: props.isSearch ? "search" : "home",
      p_label: label,
      p_content: data.profileUrl,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience.split(" ")[0],
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.price.split("/")[0],
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  const trackPlay = () => {
    trackActions("fa_ast_msg_play", "NA");
  };

  const isOfferMessageShow = () => {
    /**
     * Show the free minutes offer to all users, if it's available
     */
    return family.free_minutes && family.free_minutes > 0;
  };

  const getActiveLangList = () => {
    const langs = [];
    if (parseInt(localStorage.phone) > 0 && props.currentFilter) {
      const langList = props.currentFilter[0].values;
      langList.map((lang) => {
        if (lang.selected) {
          langs.push(lang.text);
        }
      });
    }
    return langs;
  };
  const userLangStyle = { color: "#ee600d", fontWeight: 400 };
  const formatUserLang = (lang) => {
    const uLangs = getActiveLangList();
    return uLangs.indexOf(lang) >= 0 ? <b style={userLangStyle}>{lang}</b> : lang;
  };
  const stepMargin = { margin: "16px 9px 0 0" };
  const expStyleLocal = { color: "#0c0c0c", float: "left", paddingLeft: 4 };

  const formatSecondaryLanguages = (languages) => {
    const user = [],
      other = [];
    const uLangs = getActiveLangList();
    languages.map((language) => {
      if (uLangs.indexOf(language) >= 0) return user.push({ user: language });
      else return other.push({ other: language });
    });
    const list = user.length > 0 ? [user, other] : [other];
    return list.map((lang, l) =>
      lang.map((u, i) => (
        <span style={{ color: u.user ? "#ee600d" : "#212121" }}>
          {i > 0 || l > 0 ? ", " : ""}
          {u.user || u.other}
        </span>
      ))
    );
  };

  const spacerStyle = { marginTop: -8, paddingBottom: 4 };

  // console.log(family)

  return (

    <>
      <div hidden={hide} className={[
        "astrologerInfo",
        isSingle ? 'astrologerSingleInfo' : '',
        hide ? '' : 'visibleInCurrentTab'
      ].join(' ')}>
        <div className={[family.visible || props.isSearch || isSingle ? "visible" : "hidden", family.status].join(" ")}>
          {cardType === "promotional" && offerDiscount > 0.0 ? <></> : <></>}
          <div
            className={clsx(
              "astrologerDetailsWrap",
              family.status,
              family.masked,
              props.viewProfileBtn ? 'viewProfileBtn' : '',
              !BrowserUtils.isiOSDevice && family.media && family.media.audio ? "has-audio" : ""
            )}>
            {/*(family.reviewsData.count || 0) <= 3 ? <div className="newAstrologer"> New</div> : <></>*/}
            <div className="astrologer-infos">
              <div className="astrologerLeft" onClick={astrologerProfile}>
                <Avatar
                  alt={family.name}
                  imgProps={{ loading: "lazy" }}
                  src={family.imageUrl ? AstrologerUtils.createThumbImageURI(family.id) : defaultImage}
                />

                {/* {family.reviewsData.rating &&
                  <Rating className="rating" value={family.reviewsData.rating} readOnly size="small" />
                } */}

                {family.reviewsData.rating &&
                  <RatingStar value={family.reviewsData.rating} />
                }

                <div className="flexWrapDiv hidden">
                  {/* <div className="astrologerReview">
                <Rating value={family.reviewsData.rating} readOnly size="small" />
              </div> */}
                  <div className="astrologerRating">
                    {family.reviewsData.rating > 0 ? (
                      <span>
                        {family.reviewsData.rating.toFixed(1)}
                        <b>
                          <Star />
                        </b>
                      </span>
                    ) : (
                      "NA"
                    )}
                    {/* {family.reviewsData.rating ? <small>({family.reviewsData.count})</small> : <></>} */}
                  </div>
                </div>
                {/* <div className={classes.noReviews}>Not enough Reviews</div> */}
              </div>

              <div className="astrologerRight">
                <div className="astrologerDetails" onClick={astrologerProfile}>

                  {/* {isOfferMessageShow() ? (
                  <span className={clsx(classes.message, "freemin")}>{family.free_minutes} min FREE</span>
                ) : (
                  <> </>
                )} */}
                  {/* <div>{family.away_until_mins}</div> */}
                  <span className={clsx(classes.name, "n-title")}>
                    {/* {family.verified ? <VerifiedUser className={classes.verified} /> : <></>} */}
                    {family.salutation && family.name.indexOf(family.salutation) < 0
                      ? upperCaseWords([family.salutation, family.name].join(" "))
                      : upperCaseWords(family.name)}
                  </span>
                  {/* <div style={spacerStyle}>
                  <div
                    className={clsx("astrologerProfileImage astrologerProfileImageRed", {
                      astrologerProfileImageGreen: family.status === "online" || family.status === "busy",
                    })}></div>{" "}
                  &nbsp;
                  <span
                    className={clsx(
                      props.isSearch ? classes.statusCaption : classes.statusCaptionHome,
                      "status-caption"
                    )}>
                    {family.status === "online" ? "is online" : getAwayCaption(family.status, family.away_until)} now
                  </span>
                </div> */}
                  {/* <span className={classes.astrologerPlace}>- Kerala</span> */}


                  {/* {offerDiscount > 0 ? (
                  <div
                    className={"offerStamp"}
                    onClick={(e) => {
                      props.handleOfferIcon(e, offerDiscount);
                      window.stopAllAudio();
                    }}>
                    <OfferStamp style={offerStampStyle} />
                    <span>
                      {offerDiscount}
                      <small>%</small>
                    </span>
                  </div>
                ) : (
                  <></>
                )} */}


                  {family.expertise.length &&
                    <div className={clsx(
                      'info-block',
                      'expertise',
                      // !isSingle ? 'highlight-first' : ''
                    )}>
                      {family.expertise.map((item, i) => (
                        <React.Fragment key={i}>
                          {i !== 0 && ', '}<span>{item}</span>
                        </React.Fragment>
                      ))}
                    </div>
                  }

                  {family.languages.length &&
                    <div className={clsx(
                      'info-block limit-text',
                      'languages',
                      'highlight-first',
                    )}>
                      {family.languages.map((item, i) => (
                        <React.Fragment key={i}>
                          {i !== 0 && ', '}<span>{item}</span>
                        </React.Fragment>
                      ))}
                    </div>
                  }

                  {family.experience &&
                    <div className="info-block experience">Exp: {family.experience}</div>
                  }

                  <div
                    className={clsx(
                      isFavActive === "favourite" ? classes.callCTABlockFavourite : null,
                      classes.callCTABlock,
                      'fav',
                      // isSingle ? 'hidden' : 'visible',
                    )}>
                    {!props.isSearch && isFavActive === true ? (
                      <Favorite className={clsx(classes.iconFavorite, "fav-icon")} onClick={onFavorite} />
                    ) : !props.isSearch ? (
                      <Favorite className={clsx(classes.iconFavoriteDisabled, "fav-icon-dis")} onClick={onFavorite} />
                    ) : (
                      ""
                    )}

                    {/* <div style={stepMargin}>
                    <span style={expStyleLocal}>
                      <b>Exp:</b> {family.experience}
                    </span>
                    {family.price ? (
                      <span className="rupee-symbol price-group">
                        ₹{family.perMinutePrice * MIN_BALANCE_MINS} for {MIN_BALANCE_MINS} min
                      </span>
                    ) : (
                      ""
                    )}
                  </div> */}


                  </div>
                </div>
                <div className="footer">

                  <div className={clsx("price-wrap", isOfferMessageShow() ? 'free' : '')} onClick={astrologerProfile}>
                    {family.price && <span className="price">₹{family.price}</span>}
                    {isOfferMessageShow() &&
                      <span className="free-text">{/*{family.free_minutes} min */}FREE</span>
                    }
                  </div>

                  <div className="ast-card-ctabar">
                    {/* {!props.isSearch && (
                        <div className="item">
                          <Button
                            className={clsx(classes.astrologerCardJoinCTA, family.status, "book")}
                            onClick={(e) => onCTAClickDialog(e, { source: "astrologer_card", booking: true })}
                            startIcon={props.isSearch ? null : <TimerIcon />}
                            disabled={family.bookingSlots ? false : true}>
                            Book consultation
                          </Button>
                        </div>
                        )} */}

                    {/* 


                          {(family.status === "offline" || family.status === "away") && props.isNotifiedEnable ? (
                          <div className="item">
                            <Button
                              className={clsx(classes.astrologerCardJoinCTA, family.status)}
                              onClick={props.isSearch ? onCTAClick : clickRemoveNotifyMe}
                              startIcon={props.isSearch ? null : <NotificationsOffIcon />}>
                              {props.isSearch ? "View profile" : "Cancel notification"}
                            </Button>
                          </div>
                          ) : (family.status === "offline" || family.status === "away") && !props.isNotifiedEnable ? (
                          <div className="item">
                            <Button
                              className={clsx(classes.astrologerCardJoinCTA, family.status, classes.notifyMe)}
                              onClick={props.isSearch ? onCTAClick : clickNotifyMe}
                              startIcon={props.isSearch ? null : <NotificationsActiveIcon />}>
                              {props.isSearch ? "View profile" : "Notify when online"}
                            </Button>
                          </div>
                          ) : family.status === "away" || family.status === "busy" ? (
                          <div className="item">
                            <Button
                              className={clsx(classes.astrologerCardJoinCTA, family.status)}
                              onClick={props.isSearch ? onCTAClick : doNothing}
                              startIcon={props.isSearch ? null : <PhoneDisabled />}>
                              {props.isSearch
                                ? "View profile"
                                : family.status === "busy"
                                  ? "On consultation"
                                  : upperCaseWords(family.status)}
                            </Button>
                          </div>
                          ) : (
                          <div className="item">
                            <>
                              {isChatEnabled() && family.chargesChat != null ? (
                                <>
                                  <Button
                                    className={clsx(
                                      family.chargesAudio ? classes.astrologerCardCTA : classes.astrologerCardDisCTA,
                                      family.status
                                    )}
                                    startIcon={
                                      props.isSearch ? null : (
                                        <>
                                          <ChatIcon />
                                        </>
                                      )
                                    }
                                    onClick={props.isSearch ? onCTAClick : onCTAClickDialog}>
                                    {props.isSearch ? "View profile" : "Chat"}
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  className={clsx(
                                    family.chargesAudio ? classes.astrologerCardCTA : classes.astrologerCardDisCTA,
                                    family.status
                                  )}
                                  startIcon={props.isSearch ? null : <Phone />}
                                  onClick={props.isSearch ? onCTAClick : onCTAClickDialog}>
                                  {props.isSearch ? "View profile" : "Call"}
                                </Button>
                              )}
                            </>
                          </div>
                          )} 


                          */}

                    {!props.viewProfileBtn &&
                      <>
                        {isSingle ?
                          <>
                            {family.chargesChat &&
                              <ActionBtn
                                {...props}
                                type="chat"
                                clickFrom="Astrologer profile top"
                                triggerActionBtn={triggerActionBtn}
                              />
                            }

                            {family.chargesAudio &&
                              <ActionBtn
                                {...props}
                                type="call"
                                clickFrom="Astrologer profile top"
                                triggerActionBtn={triggerActionBtn}
                              />
                            }
                          </> :
                          <ActionBtn
                            {...props}
                            clickFrom="Astrologer profile"
                            type={actionType}
                            triggerActionBtn={triggerActionBtn}
                          />
                        }
                      </>
                    }

                  </div>
                </div>
              </div>

            </div>

            {
              isDealAvailable(family) ?
                <div className="ribbon-both">
                  <span>Recharge for ₹{deal.amount} and {isSingle ? 'consult' : actionType} for {deal.minutes} mins</span>
                </div>
                :
                <></>
            }
          </div>

          {props.viewProfileBtn &&
            <Button
              className={clsx(
                'btn-view-profile',
                family.status
              )}
              onClick={astrologerProfile}
            >
              View Profile
            </Button>
          }
        </div>
      </div>

      <PositionedSnackbar
        vertical="top"
        horizontal="center"
        message={snackBar.message}
        closeError={() => setSnackBar({ ...snackBar, open: false })}
        open={snackBar.open}
      />

    </>

  );
};

AstrologerDetail.ActionBtn = ActionBtn
export default AstrologerDetail;
