import React, { useEffect, useState } from "react"
import { Dialog, makeStyles } from "@material-ui/core"
import BrowserUtils from "../user/astrologer/components/UAScripts"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import clsx from "clsx"
import Invoices from "./components/Invoices.component"

const useStyles = makeStyles({
  dialog: {
    overflow: 'visible',
    '& > .MuiDialog-container > .MuiPaper-root': {
      margin: 0,
      height: '100vh',
      maxHeight: '100%',
      boxShadow: 'none',
      borderRadius: '0',
      border: '0',
      transition: '0s',
      maxWidth: 460,
      width: '100%',

      '& .invoice-page': {
        maxWidth: 460,
        width: '100%',
      }
    },
    '& > .MuiBackdrop-root': {
      background: '#e2d0b3',
    }
  },
})

const Transaction = ({
  open: openDialog,
  handleClose: handleCloseDialog,
}) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const urlHash = 'back-button--wallet-recharge/transactions'

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.endsWith(`#${urlHash}`) && open === true && BrowserUtils.isNewUI()) {
        handleClose()
      }
    })
  })

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  useEffect(() => {
    if (open === true && BrowserUtils.isNewUI()) {
      window.location.hash = urlHash
    }
  }, [open])

  const browserBackTrigger = () => {
    if (window.location.hash === `#${urlHash}` && open === true && BrowserUtils.isNewUI()) {
      history.goBack()
    }
  }

  const handleClose = () => {
    setOpen(false)
    handleCloseDialog()
  }

  const onCloseDrawer = () => {
    browserBackTrigger()
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseDrawer}
      className={clsx(
        "transaction-popup",
        classes.dialog,
        open ? 'transaction-popup--open' : ''
      )}
    >
      <Invoices />
    </Dialog>
  )
}

export default Transaction