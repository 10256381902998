import React, { useEffect, useState } from 'react'
import { Avatar } from '@material-ui/core'
import defaultImage from "../../../../common/default_profile_image.svg";
import AstrologerUtils from './AstrologerUtils';

/**
 * AstrologerAvatar component
 *
 * @param {object} astrologer - The astrologer's data, including name, imageUrl & id
 * @param {object} style - Custom CSS styling to apply to the avatar
 * @param {string} className - Custom class name to apply to the avatar
 * @returns {JSX.Element} - The rendered AstrologerAvatar component
 */
const AstrologerAvatar = ({ astrologer, style = {}, className = '' }) => {
  let image = AstrologerUtils.createThumbImageURI(astrologer.id)
  // console.log({ AstrologerAvatar: astrologer })
  if (astrologer?.deleted_at && astrologer.deleted_at?.length > 5) {
    image = null
  } else if (astrologer?.imageUrl) {
    image = image
  } else if (!astrologer?.id) {
    image = null
  } else {
    image = defaultImage
  }

  return <>
    <Avatar
      alt={astrologer.name}
      imgProps={{ loading: "lazy" }}
      src={image}
      style={style}
      className={className}
    >{astrologer?.name ? astrologer.name[0] : ''}</Avatar>
  </>
}


export default AstrologerAvatar