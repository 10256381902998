import React, {useState} from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export default function Faq(props) {
  const [isMore, setIsMore] = useState(true);
  const classes = props.classes;

  const onReadMore = () => {
    setIsMore(!isMore);
  };

  return (
    <Card className={classes.faqCard}>
      <CardContent>
        <div className={classes.faqQuestion}>{props.data.question}</div>
        {isMore ? (
          <div className={classes.faqAnswer} dangerouslySetInnerHTML={{__html: props.data.answer}}></div>
        ) : (
          <div className={classes.faqAnswer} dangerouslySetInnerHTML={{__html: props.data.answer2}}></div>
        )}
      </CardContent>
      <div className={classes.faqReadMore} onClick={onReadMore}>
        {isMore ? "Read More" : "Read Less"}
      </div>
    </Card>
  );
}
