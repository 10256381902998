import React from "react";
import Dialog from "@material-ui/core/Dialog";
import UpdateUerBanner from "../../../../common/critical_update.svg";
import {Button} from "@material-ui/core";
import {ReactComponent as GPIcon} from "../../../../common/googleplayicon.svg";

const AppInstallBannerDialog = (props) => {
  const handleClose = () => {
    console.log("closing..");
  };

  const handleDownload = () => {
    const source = "Findastro_app";
    const medium = "criticalupdate";
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3D" +
      source +
      "%26utm_medium%3D" +
      medium;
  };

  return (
    <div>
      <Dialog
        open={props.isStateOpen}
        onClose={handleClose}
        className={"astrologerDialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div
          className="dialog"
          onClick={handleDownload}
          style={{
            padding: 16,
            minWidth: "100%",
            minHeight: "auto",
            maxWidth: 320,
            textAlign: "center",
            position: "relative",
            backgroundColor: "#fff",
          }}>
          <img alt="banner" src={UpdateUerBanner} height="48" style={{width: 48, display: "block", margin: "auto"}} />
          <h3
            style={{
              color: "red",
              fontSize: "1.1rem",
              fontWeight: "400",
            }}>
            App version outdated!
          </h3>
          <p
            style={{
              fontSize: "0.9rem",
            }}>
            You are using an older version of Findastro app. Please update to the latest version to continue using all
            the features.
          </p>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#689f38",
              margin: "30px 0px 15px 0px",
              color: "white",
            }}>
            <GPIcon style={{marginRight: "10px"}} />
            <span>Download Update now</span>
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
export default AppInstallBannerDialog;
