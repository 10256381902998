import React from "react";

const CircularProgressWithLabel = (props) => {
  const value = Math.floor(props.value);
  const image = `conic-gradient(#ee600d ${value}%, #fbead0 ${value + 1}%)`;
  return (
    <div
      style={{
        height: "30px",
        width: "30px",
        backgroundColor: "#ee600d",
        backgroundImage: image,
        borderRadius: `50%`,
        border: "2px solid #fff",
      }}></div>
  );
};
export default CircularProgressWithLabel;
