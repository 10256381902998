import aries from "./signs/aries.png";
import taurus from "./signs/taurus.png";
import gemini from "./signs/gemini.png";
import cancer from "./signs/cancer.png";
import leo from "./signs/leo.png";
import virgo from "./signs/virgo.png";
import libra from "./signs/libra.png";
import scorpio from "./signs/scorpio.png";
import sagittarius from "./signs/sagittarius.png";
import capricorn from "./signs/capricorn.png";
import aquarius from "./signs/aquarius.png";
import pisces from "./signs/pisces.png";

const miscData = {
  signData: [
    {}, // skipping zero index
    {id: 1, sign: "Aries", moonSign: "Mesha", period: "21 Mar-20 Apr", icon: aries, letter: "J"},
    {id: 2, sign: "Taurus", moonSign: "Vrishabha", period: "21 Apr-21 May", icon: taurus, letter: "K"},
    {id: 3, sign: "Gemini", moonSign: "Mithuna", period: "22 May-21 Jun", icon: gemini, letter: "L"},
    {id: 4, sign: "Cancer", moonSign: "Karkata", period: "22 Jun-22 July", icon: cancer, letter: "M"},
    {id: 5, sign: "Leo", moonSign: "Simha", period: "23 Jul-23 Aug", icon: leo, letter: "N"},
    {id: 6, sign: "Virgo", moonSign: "Kanya", period: "24 Aug-22 Sep", icon: virgo, letter: "O"},
    {id: 7, sign: "Libra", moonSign: "Tula", period: "23 Sep-23 Oct", icon: libra, letter: "P"},
    {id: 8, sign: "Scorpio", moonSign: "Vrischika", period: "24 Oct-22 Nov", icon: scorpio, letter: "Q"},
    {id: 9, sign: "Sagittarius", moonSign: "Dhanu", period: "23 Nov-21 Dec", icon: sagittarius, letter: "R"},
    {id: 10, sign: "Capricorn", moonSign: "Makara", period: "22 Dec-20 Jan", icon: capricorn, letter: "S"},
    {id: 11, sign: "Aquarius", moonSign: "Kumbha", period: "21 Jan-18 Feb", icon: aquarius, letter: "T"},
    {id: 12, sign: "Pisces", moonSign: "Meena", period: "19 Feb-20 Mar", icon: pisces, letter: "U"},
  ],
  dailyHoroscope:
    "Findastro’s daily horoscope predictions offer astrology’s take on your life as depicted through the twelve zodiac signs, twelve houses and planetary placements in the universe. These daily astrology predictions can give you timely advice on how to take control of a particular aspect of your life or avoid potentially dangerous situations. Follow Findastro’s free daily horoscope to get access to important predictions about the impacts of the planets and stars on your daily lives. Simply click on your star sign to read the daily horoscope of the day.",
  weeklyHoroscope:
    "Got an important event scheduled for this week? If you are worried about whether things are going to work out in your favour or not, check out Findastro’s weekly horoscope predictions to make the best of your week. Findastro can offer accurate weekly forecasts to help you plan out your week. Follow our free weekly horoscopes to gain insights about the favourable and unfavourable events coming your way. Check this week’s horoscope so that you are well prepared for the upcoming challenges. Simply click on your star sign to read your free weekly horoscope.",
  faqData: [
    {
      question: "Daily Horoscope in English - What do the stars have planned",
      answer:
        "Everyday is a new start and brings with it a fresh set of opportunities to explore. However, life can be unpredictable, and thus, knowing today's horoscope & how your day will progress can sure...",
      answer2:
        "Every day is a new start and brings with it a fresh set of opportunities to explore. However, life can be unpredictable, and thus, knowing today’s horoscope & how your day will progress can surely benefit you. Check out Free Daily Horoscope for all zodiac signs here to understand what’s in store for you. Make your best moments count while gearing up for any obstacles you may encounter.",
    },
    {
      question: "What is a Horoscope?",
      answer:
        "The word 'Horoscope' is a combination of two words - 'Horo' meaning an hour, and 'Scope' meaning -to view. Hence, the word Horoscope signifies - the view of the hour. Horoscopes ...",
      answer2:
        "The word “Horoscope” is a combination of two words – ‘Horo’ meaning an hour, and ‘Scope’ meaning – to view. Hence, the word Horoscope signifies – the view of the hour. Horoscopes relate to the study of celestial bodies like the Sun, the planets, the Moon, etc. and how their movement influences your life.<p></p> Horoscopes are designed based on your birth timings and define the placement of different planets at specific timings at a given place and hence, they’re known to be unique to each individual.<p></p>Each horoscope consists of 12 houses (Bhavas) according to Hindu astrology. The right analysis of your horoscope can help you learn not only about your future but also about the past deeds and the present. Apart from 12 houses, horoscope charts also hold information about planets, zodiac signs, aspects, characteristics, behaviour, likes/dislikes of a particular individual.<p></p>Favourable and unfavourable timings in the life of an individual can be identified by reading horoscopes. The charts can also answer questions related to career, love life, relationships, zodiac compatibility with a person, etc. You can learn about fortunes to come your way, setbacks in life, the right time to get married, struggles, and more all from your Daily Horoscope/Astrology chart.",
    },
    {
      question: "Lagna and Rashi - How do they impact you?",
      answer:
        "According to the concept of Vedic astrology, both Lagna (the Sun sign) and Rashi (the Moon sign) are important factors in your horoscope and the chart details clear information about them. However,...",
      answer2:
        "According to the concept of Vedic astrology, both Lagna (the Sun sign) and Rashi (the Moon sign) are important factors in your horoscope and the chart details clear information about them. However, not everyone is quite aware of the difference between the two and often get confused. Let’s understand what influence each sign has in your life.<p></p>The Lagna (the Sun sign)<br>As per astrology, Lagna (the Sun sign) controls your zodiac personality, highlights important aspects of your characteristics, personality, behaviour, etc. and also details what your beliefs are as an individual. Figuring out your Lagna sign is easy and can be done by checking your birth date and the zodiac month you’re born in. There are 12 signs in the zodiac circle from Aries to Pisces and each zodiac sign is ruled by a planet that influences the personality of every individual.<p></p>The Rashi (the Moon sign)<br>Your Rashi gets determined based on the position of the Moon at the time of your birth. You need your exact birth time, date, and place to know what your Rashi is. The Moon signs revolve around the 12 zodiac symbols and stay in each one of them for nearly 2 days.<p></p>The Sun sign tells you about your personality, the Moon sign, on the contrary, talks about your moods and emotions. For example – If you’re a Capricorn, you’ll tend to have more discipline and will have more willpower. However, if you’re a Pisces, you will show signs of being passionate, dreamy, and will have an introspective behaviour. At times, an individual can get inclined towards a particular sign (Lagna or Rashi), and in such cases, depending on the side they’re inclined to, their behaviour starts getting influenced accordingly.",
    },
    {
      question: "Difference between Rashi and Lagna",
      answer:
        "Both Sun signs and the Moon signs follow the zodiac signs and require your date, time, and place of birth, however, one factor that differentiates the two is that - the Moon sign needs more accur...",
      answer2:
        "Both Sun signs and the Moon signs follow the zodiac signs and require your date, time, and place of birth, however, one factor that differentiates the two is that – the Moon sign needs more accurate information on the year, month, day, time, and place. The Sun revolves around all 12 zodiac signs in 12 months i.e., it stays with each one of them for a month. The Moon, on the contrary, stays with a particular sign only for two and a half days. Thus, to calculate your Moon Sign (aka Rashi), you must know your exact time and place of birth.",
    },
    {
      question: "How does reading your free daily horoscope benefit you?",
      answer:
        "Since time immemorial, human beings have looked up at the stars for inspiration or guidance in their life path. Astrology is an accident science that is perhaps the most mysterious system of the wis...",
      answer2:
        "Since time immemorial, human beings have looked up at the stars for inspiration or guidance in their life path. Astrology is an ancient science that is perhaps the most mysterious system of the wisdom of the universe connecting every life, creation and event on Earth. Therefore, many sages and astrologers have also called astrology as the art of divination.<p></p>Findastro understands astrology as both an art and a science. The scientific part of astrology is quite prominent as every prediction about a person’s life that astrologers make is done by calculations using mathematical, geometrical and astronomical methods. Astrological predictions are based on observations and the interdependence of scientific methods and divination.<p></p> In ancient times, the astrologers watched the sky and observed how the planets and other celestial bodies followed a path around the universe. They recorded them, and based on that, they came up with astrology as a science to make accurate predictions about an individual as to their future appearances.<p></p>Gradually, they also started to observe how the certain position at which the celestial bodies stayed corresponded to the changes in weather and events in someone’s lives. To summarize, astrology is an ancient system of the vast wisdom and understanding of the universe that gives us self-knowledge and awareness to take control of our lives using divination and natural phenomenon.<p></p>For today’s horoscope, you can check out Findastro’s daily astrology predictions specific to your zodiac signs. The twelve zodiac signs are categorised into groups of elements of Fire, Water, Air and Earth according to their familiarity with each other. For instance, the Fire group comprises of Aries, Leo and Sagittarius signs, but every individual of a particular zodiac will express their fiery temperament in a way unique to them.",
    },
    {
      question: "Why Findastro",
      answer:
        "Your Daily Horoscope report at Findastro can help you learn more about how your day will pan out. It highlights what all obstacles you would encounter in your daily routine based on the position a...",
      answer2:
        "Your Daily Horoscope report at Findastro can help you learn more about how your day will pan out. It highlights what all obstacles you would encounter in your daily routine based on the position and movement of the celestial bodies. Further, using your Today’s Horoscope in English report you can get deeper insights about your personality, career, love life, relationships with others, and even check your compatibility with other zodiac signs.",
    },
  ],
  weeklyUpdateContent: {
    question: "How does reading your weekly horoscope benefit you?",
    answer:
      "In astrology, horoscopes are astrological charts that are prepared according to the birth date of an individual as well as the time and place where they were born. As this data is distinct to individuals, the horoscope is a representation of their characteristics, tendencies, strengths, weakness, career path, etc. that is unique to them. While preparing horoscopes, the astrological study of the stars, the planets, their orbits and their influence on human lives are taken into account. The astrological chart contains the exact position of the sun, moon and planetary combinations that occurred during the individual’s birth.<p></p>A horoscope can also reveal what kind of person the native is and how they will react to certain things in their life. Hence, our horoscopes can offer us great insights and awareness about our life’s potentials and capabilities.<p></p>The twelve zodiac signs are determined by birth, and each one is governed by a particular element and the placement of a planet in a particular house within the horoscope. The governing elements are Fire, Water, Air and Earth. The Fire element comprises of Aries, Leo and Sagittarius zodiac signs. Water comprises of Cancer, Scorpio and Pisces. Gemini, Libra and Aquarius belong to the Air element, and Taurus, Virgo and Capricorn belong to the Earth element. Individuals belonging to a particular element will have a personality based on the mythological characteristics of the element although, each zodiac sign will express those characteristics in a different way. However, in general, Fire is associated with passion, Earth stands solid as a rock, Air is the cool breeze of reason, and Water symbolizes emotions.",
  },
};

export default miscData;
