import React from "react";
import { Helmet } from "react-helmet-async";

export default function HelmetComponent(props) {
  return (
    <Helmet>
      <title>{props.title ? props.title : "Astrologers List at Findastro"}</title>
      <meta
        name="description"
        content={props.description ? props.description : "Connect to your favorite Astrologers at Findastro"}
      />
      <meta property="og:url" content={props.url ? props.url : "https://www.findastro.com/astrologers"}></meta>
      <meta property="og:title" content={props.title ? props.title : "Astrologers List at Findastro"} />
      <meta property="og:image" content={props?.image || "%PUBLIC_URL%/logo192.png"} />
      <meta
        property="og:description"
        content={props.description ? props.description : "Connect to your favorite Astrologers at Findastro"}
      />
      {props?.icon ? (
        <link rel="icon" href={props.icon} type="image/x-icon" />
      ) : null}

    </Helmet>
  );
}
