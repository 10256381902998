/* eslint-disable import/no-anonymous-default-export */
export default {
  sidebarScreensParentDiv: {
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "white",
    "&.MuiInput-root": {
      border: "none",
      paddingBottom: "3px",
      paddingLeft: "33px",
      background: "none",
      marginRight: "20px",
      marginLeft: "20px",
      marginBottom: "9%",
    },
    "&.MuiCardContent-root": {
      padding: "0 !important",
    },
  },
  sidebarScreensTopArea: {
    display: "flex",
    flexDirection: "row",
  },
  sidebarScreensArrowIcon: {
    color: "#ff8400",
    margin: "24px 15px 0 0px",
  },
  sidebarScreensChildTopArea: {
    marginTop: "36px",
    lineHeight: "6px",
    fontSize: "19px",
  },
  sidebarScreensText: {
    color: "#ee600d",
    marginBottom: "37px",
  },
  sidebarScreensLogo: {
    margin: "100px auto 24px auto",
    display: "block",
    height: "44px",
    borderRadius: "15px",
  },
  sidebarScreensHeading: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "5%",
  },
  sidebarScreensParagraph: {
    marginBottom: "5%",
  },
  sidebarScreensRating: {
    fontSize: "2.0rem",
    margin: 8,
  },
  sidebarScreensAckMessage: {
    fontSize: "14px",
    color: "#3b9800",
  },
  sidebarScreensLeaveCommentDiv: {
    marginTop: "5%",
  },
  sidebarScreensLeaveComment: {
    fontSize: "0.8923em",
    paddingBottom: "5px",
  },
  sidebarScreensCommentBox: {
    width: "100%",
    paddingBottom: "34px !important",
    padding: "14px",
    borderRadius: "4px",
    fontSize: "0.8923em",
    backgroundColor: "#fbead0",
    borderColor: "#b6b6b6",
    border: "none",
    minHeight: "33vh",
    color: "#5d5d5d",
    fontFamily: "sans-serif",
    resize: "none",
  },
  sidebarScreensNameBox: {
    width: "100%",
    padding: "14px",
    borderRadius: "4px",
    fontSize: "0.8923em",
    backgroundColor: "#fbead0",
    borderColor: "#b6b6b6",
    color: "#5d5d5d",
    fontFamily: "sans-serif",
    border: "none",
    resize: "none",
  },
  sidebarScreensSubmitButton: {
    color: "white",
    width: "100%",
    height: "48px",
    background: "#00c180",
    marginTop: "4px",
    fontWeight: "bold",
    borderRadius: "0",
    boxShadow: "none",
    fontSize: "0.9rem",
  },
  sidebarScreensSubmitArrow: {
    color: "white",
    background: "rgba(255, 255, 255, 0.35)",
    border: "none !important",
    position: "absolute",
    borderRadius: "20px !important",
    right: "15px",
  },
  sidebarScreensCenter: {
    margin: "0 auto !important",
    textAlign: "center",
  },
  dailyHoroscopeSelectText: {
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "50px",
    height: "50px",
    color: "white",
    borderRadius: "10px 10px 0px 0px",
    fontWeight: "bold",
    backgroundColor: "rgb(255 99 0)",
  },
  dailyHoroscopeText: {
    textAlign: "justify",
    marginTop: "10%",
    lineHeight: "25px",
  },
  starSignGrid: {
    textAlign: "center",
    height: "140px",
    backgroundColor: "#fdf2ec",
    padding: "8px",
    border: "1px solid #e5633978",
  },
  starSignText: {
    fontWeight: "bold",
  },
  signImage: {
    fontFamily: "astrovisionregular",
    fontSize: "2.5em",
    color: "#ee600d",
    textAlign: "center",
  },
  starSignPeriod: {
    fontSize: "14px",
  },
  faqCard: {
    margin: "10% 0px !important",
  },
  faqQuestion: {
    backgroundColor: "#dedede",
    padding: "15px",
    fontWeight: "bold",
    color: "#ee600d",
  },
  faqReadMore: {
    textAlign: "right",
    padding: "15px",
    fontWeight: "bold",
    color: "#ee600d",
  },
  signInfo: {
    textAlign: "center",
    "& .MuiGrid-container": {
      textAlign: "center",
    },
  },
  starSignContent: {
    textAlign: "justify",
  },
  selectOtherSignFlex: {
    marginBottom: "20px",
  },
  signImageBig: {
    fontFamily: "astrovisionregular",
    fontSize: "7em",
    color: "#ee600d",
  },
  starSignSpecific: {
    fontSize: "20px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  starSignPeriodSpecific: {
    marginBottom: "15%",
  },
  bodyJustify: {
    textAlign: "left",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: "#ffffff",
  },
};
