import React, {useEffect, useState} from "react";
import {PUJA_SUPPORT_NUMBER, SUPPORT_NUMBER} from "../../../app.config";
import moengage from "../../../common/moengage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import Phone from "@material-ui/icons/Phone";
import axios from "../../../common/interceptor";
import {formatAmount} from "../../user/astrologer/components/AstrologerUtils";

const PujaInfoBanner = (props) => {
  const refId = props.refId || "NA";
  const [maxDiscountAmount, setMaxDiscountAmount] = useState(null);

  useEffect(() => {
    axios()
      .post("/puja/discount-info")
      .then(({data}) => {
        setMaxDiscountAmount(data.data.max_discount);
      })
      .catch((err) => {
        setMaxDiscountAmount(5000);
      });
  }, []);

  const trackClick = () => {
    const type = "fa_remedy_assistance";
    moengage(type);
    const payload = {
      p_screen: props.refId ? "remedy" : "remedy_list",
      p_label: refId,
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };
  return (
    <div
      style={{
        margin: "0 10px",
        padding: "10px 14px",
        borderRadius: "14px",
        fontSize: "0.9rem",
        color: "#fff",
        backgroundImage: "linear-gradient(40deg, rgb(255, 42, 0), rgb(255, 154, 0))",
      }}>
      {maxDiscountAmount && (
        <div style={{marginTop: 8, lineHeight: "1rem"}}>
          As an introductory offer, <b>Discounts up to ₹{formatAmount(maxDiscountAmount)}</b> are available on each
          Pujas and Havans.
        </div>
      )}
      <div style={{marginTop: 8, lineHeight: "1rem"}}>
        You may book a Puja/Havan directly from here or call <b>{PUJA_SUPPORT_NUMBER}</b> to take our assistance
      </div>
      <div>
        <a target="_blank" href={"tel:" + String(PUJA_SUPPORT_NUMBER).replace(/\s/g, "")}>
          <button
            style={{
              border: "none",
              padding: "10px 24px 8px 10px",
              height: 40,
              borderRadius: "24px",
              margin: "12px auto 8px auto",
              display: "block",
              backgroundColor: "#fff",
              color: "#f5423e",
              fontWeight: "700",
              width: "240px",
              whiteSpace: "nowrap",
            }}
            onClick={trackClick}>
            <Phone
              style={{
                width: "16px",
                marginTop: -4,
                verticalAlign: "middle",
              }}
            />{" "}
            Contact Support for assistance
          </button>
        </a>
      </div>
    </div>
  );
};

export default PujaInfoBanner;
