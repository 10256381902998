import React, { Component } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import gTag from "../../../common/google_analytics";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import "./MyAccount.css";
import walletData from "../mock/MyAccountData.mock";
import HelmetComponent from "../../../common/HelmetComponent.component";
import ModalComponent from "./WalletModal.component";
import MyWalletItemComponent from "./MyWalletItem.component";
import SideDrawerComponent from "../../user/astrologer/components/SideDrawer.component";
import Snackbar from "../../../common/SnackBarMessage";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReloadIcon from "@material-ui/icons/Cached";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import QueryString from "../../../common/util/QueryString";
import ReferEarnModel from "./ReferEarnModal.component";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import FA_localStorage from "../../../common/util/FA_localStorage";
import { ReactComponent as RefAndEarn } from "../../../common/refer-and-earn.svg";
import ReferEarnModal from "./ReferEarnModal.component";

const styles = (theme) => ({
  error: {
    color: "red",
    fontSize: 10,
    marginTop: 5,
    paddingLeft: 20,
  },
  info: {
    color: "green",
    fontSize: 10,
    marginTop: 5,
    paddingLeft: 20,
    marginBottom: 15,
  },
  offerIcon: {
    width: "30px",
    margin: "-5px auto -10px -10px",
  },
  offerInfo: {
    color: "green",
    fontSize: "13px",
    lineHeight: "26px",
    padding: "0 20px",
  },
});
class MyWallet extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    walletPoints: localStorage.getItem("walletBalance") ? localStorage.getItem("walletBalance") : "0",
    amount: 0,
    name: "",
    isLoading: true,
    userData: {},
    tId: "",
    orderId: "",
    status: "failed",
    message: "",
    reData: {},
    drawerOpen: false,
    modalOpen: false,
    referModalOpen: false,
    walletOptions: [],
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  goBack = () => {
    const params = new URLSearchParams(window.location.search);
    window.location.href = params.get("pathname") ? params.get("pathname") : "/";
  };

  closeREModal = () => {
    this.setState({ referModalOpen: false });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onSelect = (value) => {
    this.setState({ amount: value });
    setTimeout(() => {
      this.onProceed();
    }, 100);
  };

  onlyNumbers = (e) => {
    var validChars = walletData.validChars;
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  onAmountEdit = (e) => {
    this.setState({ amount: e.target.value });
  };

  getPaymentInfo = () => {
    let GST = (this.state.amount * 18) / 100;
    return (
      "GST @ 18% - Rs." +
      parseFloat(GST).toFixed(2) +
      " | Total Payable - Rs." +
      (parseFloat(this.state.amount) + parseFloat(GST)).toFixed(2)
    );
  };

  clearHistory = (url) => {
    this.props.history.replace(url);
  };

  onRefresh = () => {
    this.setState({ isLoading: true });
    axios()
      .post("/my-account")
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("name", res.data.data.name);
          localStorage.setItem("email", res.data.data.email);
          localStorage.setItem("phone", res.data.data.phone);
          localStorage.setItem("image", res.data.data.profileUrl);
          localStorage.setItem("walletBalance", res.data.data.walletBalance ? res.data.data.walletBalance : "0");
          this.setState({
            walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
            name: res.data.data.name ? res.data.data.name : "",
            isLoading: false,
          });
        } else {
          this.showError(res.data.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        sentryError("MY_WALLET_REFRESH", err);
        this.showError("Error occured, Please try again");
        this.setState({ isLoading: false });
      });
  };

  onProceed = () => {
    if (!this.state.userData.email && !this.state.userData.phone && !this.state.userData.name) {
      this.showError("Please update your profile");
      window.setTimeout(function () {
        window.location.href = "/userprofile?pathname=/my-wallet";
      }, 1000);
    } else if (!this.state.amount) {
      this.showError("Please select a plan");
    } else if (this.state.amount < 99) {
      this.showError("Minimum recharge amount is ₹99.");
    } else {
      this.setState({ isLoading: true });
      axios()
        .post("/wallet/recharge/rp", {
          email: this.state.userData.email,
          phone: this.state.userData.phone,
          amount: this.state.amount,
          mobile_country_code: "91",
        })
        .then((res) => {
          if (res.data.success) {
            this.triggerRazorpay(res.data);
            moengage("WALLET_RECHARGE_ATTEMPTED");
          } else {
            this.showError(res.data.message);
            this.setState({ isLoading: false });
          }
        })
        .catch((err) => {
          sentryError("MY_WALLET_PROCEED", err);
          this.showError("Error occured, Please try again");
          this.setState({ isLoading: false });
        });

      const succ_payload = {
        p_screen: "wallet",
        p_label: "",
        p_content: "NA",
        p_value: this.state.amount,
        value: this.state.amount,
        currency: "INR",
        p_ast_exp: "",
        p_ast_rating: "",
        p_ast_sort: "NA",
        p_ast_rank: "",
        p_ast_fees: "",
        p_usr_gender: BrowserUtils.CurrentUser().gender,
        p_usr_age: BrowserUtils.CurrentUser().age.toString(),
        p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
      };
      window.FAEventLog("fa_recharge_init", succ_payload);
      moengage("fa_recharge_init");
    }
  };

  onClickReferAndEarn = () => {
    this.setState({ isLoading: true });

    axios()
      .post("/refer-earn/install-offer-link")
      .then((res) => {
        this.setState({ reData: res.data, referModalOpen: true, isLoading: false });
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_CANCEL", err);
      });
  };

  triggerRazorpay = (data) => {
    let that = this;
    this.setState({
      isLoading: true,
      tId: data.t_id,
      orderId: data.order_id,
    });

    let options = {
      key: data.key,
      amount: parseInt(data.amount, 10),
      currency: "INR",
      name: "Findastro",
      description: "#" + data.order_id,
      order_id: data.payment_reference_id,
      image: "https://s3.ap-south-1.amazonaws.com/assets.findastro.com/findastro_logo_color.png",
      notes: {
        merchant_order_id: data.order_id,
      },
      handler: function (response) {
        that.razorCapturePayment(response);
      },
      prefill: {
        name: data.name,
        email: data.email,
        contact: data.phone,
        method: "",
      },
      theme: {
        color: "#ee600d",
      },
      modal: {
        ondismiss: function () {
          that.razorCancelTransaction();
        },
      },
      config: {
        display: {
          hide: [{ method: "paylater" }],
        },
      },
    };
    if (data.redirect) {
      options["callback_url"] =
        process.env.REACT_APP_API_BASE_URL +
        "/wallet/recharge/rp-wv/success?t_id=" +
        this.state.tId +
        "&attr=" +
        (localStorage.getItem("user_attribution") || "");
      options["redirect"] = true;
    }
    this.setState({ isLoading: false });
    let rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      let comments = JSON.stringify(response.error);
      that.razorFailedTransaction(comments);
    });
    rzp1.open();
    window.location.hash = "backtohome";
  };

  razorCapturePayment = (response) => {
    this.setState({ isLoading: true });
    axios()
      .post("/wallet/recharge/rp/success", {
        t_id: this.state.tId,
        pay_id: response.razorpay_payment_id,
      })
      .then((res) => {
        if (res) {
          if (res.data.success) {
            if (res.data.balance > 0) {
              moengage("WALLET_RECHARGE_SUCCESS", { amount: Number(res.data.recharge_amount) });
              /** event tracking */
              const payload = {
                affiliation: "",
                sku: "wallet_recharge",
                quantity: 1,
                coupon: "",
                transactionId: "",
                value: Number(res.data.recharge_amount),
                currency: "INR",
                shipping: 0,
                tax: 0,
              };
              window.FAEventLog("purchase", payload);

              const succ_payload = {
                p_screen: "wallet",
                p_label: "",
                p_content: "NA",
                p_value: Number(res.data.recharge_amount),
                value: Number(res.data.recharge_amount),
                currency: "INR",
                p_ast_exp: "",
                p_ast_rating: "",
                p_ast_sort: "NA",
                p_ast_rank: "",
                p_ast_fees: "",
                p_usr_gender: BrowserUtils.CurrentUser().gender,
                p_usr_age: BrowserUtils.CurrentUser().age.toString(),
                p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
              };
              window.FAEventLog("fa_recharge_success", succ_payload);
              moengage("fa_recharge_success");

              const points = res.data.balance - parseInt(localStorage("walletBalance"));
              const credit_payload = {
                p_screen: "wallet",
                p_label: "",
                p_content: "NA",
                p_value: Number(points),
                value: Number(points),
                currency: "INR",
                p_ast_exp: "",
                p_ast_rating: "",
                p_ast_sort: "NA",
                p_ast_rank: "",
                p_ast_fees: "",
                p_usr_gender: BrowserUtils.CurrentUser().gender,
                p_usr_age: BrowserUtils.CurrentUser().age.toString(),
                p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
              };
              window.FAEventLog("fa_wallet_credit", credit_payload);
              moengage("fa_wallet_credit");
              /** event tracking */
              if (typeof window.Moengage !== 'undefined') {
                window.Moengage.add_user_attribute("WALLET_BALANCE", res.data.balance);
              }
              localStorage.setItem("walletBalance", res.data.balance);
              this.setState({
                walletPoints: res.data.balance,
                rechargeValue: Number(res.data.recharge_amount),
              });
            }
            this.setState({ modalOpen: true, status: "success" });
          } else {
            this.setState({ modalOpen: true, status: "failed" });
          }
          this.setState({ isLoading: false });
        } else {
          this.setState({ modalOpen: true, status: "failed" });
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE", err);
        this.setState({ modalOpen: true, status: "failed" });
        this.setState({ isLoading: false });
      });
  };

  razorCancelTransaction = () => {
    this.setState({ isLoading: true });

    axios()
      .post("/wallet/recharge/rp/cancel", {
        t_id: this.state.tId,
        order_id: this.state.orderId,
      })
      .then((res) => {
        this.setState({ isLoading: false });
        this.showError("Transaction is cancelled, Please try again");
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_CANCEL", err);
      });
  };

  razorFailedTransaction = ($res) => {
    axios()
      .post("/wallet/recharge/rp/failed", {
        t_id: this.state.tId,
        order_id: this.state.orderId,
        comments: $res,
      })
      .then((res) => {
        // Do nothing
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_FAILED", err);
      });
  };

  trackPageview = () => {
    /** event tracking */
    const type = "fa_screen_wallet";
    const payload = {
      p_screen: "home",
      p_label: "NA",
      p_content: "NA",
      p_value: 0,
      value: 0,
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  componentDidMount = () => {
    if (this.props.location.pathname === "/my-wallet/invalid") {
      this.setState({ modalOpen: true, status: "failed", isLoading: false });
    } else if (
      this.props.location.pathname.startsWith("/recharge/success") ||
      this.props.location.pathname.startsWith("/recharge/fail")
    ) {
      axios()
        .post("/wallet/transaction/" + this.props.match.params.id, {
          token: QueryString().t,
        })
        .then((res) => {
          if (res) {
            if (res.data.success) {
              if (res.data.balance > 0) {
                if (typeof window.Moengage !== "undefined") {
                  if (this.props.location.pathname.startsWith("/recharge/success")) {
                    moengage("WALLET_RECHARGE_SUCCESS", { amount: Number(res.data.recharge_amount) });
                    moengage("fa_recharge_success", { amount: Number(res.data.recharge_amount) });
                    /** event tracking */
                  } else {
                    moengage("WALLET_RECHARGE_FAIL");
                  }
                  window.Moengage.add_user_attribute("WALLET_BALANCE", res.data.balance);
                }
                if (this.props.location.pathname.startsWith("/recharge/success")) {
                  /** event tracking */
                  const payload = {
                    affiliation: "",
                    sku: "wallet_recharge",
                    quantity: 1,
                    coupon: "",
                    transactionId: "",
                    value: Number(res.data.recharge_amount),
                    currency: "INR",
                    shipping: 0,
                    tax: 0,
                  };
                  window.FAEventLog("purchase", payload);

                  const succ_payload = {
                    p_screen: "wallet",
                    p_label: "",
                    p_content: "NA",
                    p_value: Number(res.data.recharge_amount),
                    value: Number(res.data.recharge_amount),
                    currency: "INR",
                    p_ast_exp: "",
                    p_ast_rating: "",
                    p_ast_sort: "NA",
                    p_ast_rank: "",
                    p_ast_fees: "",
                    p_usr_gender: BrowserUtils.CurrentUser().gender,
                    p_usr_age: BrowserUtils.CurrentUser().age.toString(),
                    p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
                  };
                  window.FAEventLog("fa_recharge_success", succ_payload); // ON LIVE
                }
                localStorage.setItem("walletBalance", res.data.balance);
                this.setState({
                  walletPoints: res.data.balance,
                  rechargeValue: Number(res.data.recharge_points),
                });
              }
              this.setState({ modalOpen: true, status: "success" });
            } else {
              this.setState({ modalOpen: true, status: "failed" });
              this.setState({ isLoading: false });
            }
          } else {
            this.setState({ modalOpen: true, status: "failed" });
            this.setState({ isLoading: false });
          }
        })
        .catch((err) => {
          sentryError("MY_WALLET_RECHARGE", err);
          this.setState({ modalOpen: true, status: "failed" });
          this.setState({ isLoading: false });
        });
    } else if (this.props.location.pathname === "/my-wallet") {
      if (
        !localStorage.getItem("btkn") ||
        localStorage.getItem("btkn") === "" ||
        localStorage.getItem("btkn") === "undefined" ||
        !localStorage.getItem("utkn") ||
        localStorage.getItem("utkn") === "" ||
        localStorage.getItem("utkn") === "undefined"
      ) {
        window.location.href = "/login?pathname=" + this.props.location.pathname;
      } else if (!BrowserUtils.hasUserProfile()) {
        window.location.href = "/userprofile?pathname=" + this.props.location.pathname;
      } else {
        moengage("WALLET_PAGE_VIEWED");
        axios()
          .post("/my-account")
          .then((res) => {
            if (res.data.success) {
              if (res.data.data.email) {
                localStorage.setItem("name", res.data.data.name);
                localStorage.setItem("email", res.data.data.email);
                localStorage.setItem("phone", res.data.data.phone);
                localStorage.setItem("image", res.data.data.profileUrl);
                localStorage.setItem("walletBalance", res.data.data.walletBalance ? res.data.data.walletBalance : "0");
                this.setState({
                  userData: res.data.data,
                  walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
                  name: res.data.data.name ? res.data.data.name : "",
                  isLoading: false,
                });
              } else {
                this.setState({
                  walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
                  isLoading: false,
                });
              }
            } else {
              this.showError(res.data.message);
              this.setState({ isLoading: false });
            }
          })
          .catch((err) => {
            sentryError("MY_WALLET_PAGELOAD", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false });
          });

        this.setState({ isLoading: true });
        axios()
          .get("/wallet/rechargeoptions")
          .then((res) => {
            if (res.data.success) {
              this.setState({ walletOptions: res.data.options });
            }
          })
          .catch((err) => {
            sentryError("MY_WALLET_OPTIONS", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false });
          });
      }
      window.location.hash = "backtohome";
    }

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    moengage("fa_screen_wallet");
    this.trackPageview();
  };

  setRefAndEarnModelOpen = (value) => {
    this.setState({ refAndEarnModelOpen: value });
  };

  render() {
    return (
      <div className="accountParentDiv" style={{ backgroundColor: "#ffffff" }}>
        <div>
          <HelmetComponent
            title="My Wallet at Findastro"
            url="https://www.findastro.com/my-wallet"
            description="My Wallet at Findastro- Connect to your Favorite Astrologer at Findastro"
          />
          {this.state.isLoading ? (
            <div className="circle-loading">
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          <div className="accountTopArea">
            <ArrowBackIcon className="accountArrowIcon2" onClick={this.goBack} />
            <div className="accountChildTopArea">
              <p>My Wallet</p>
            </div>
          </div>
          <div style={{ textAlign: "right", marginRight: 10, fontSize: 14 }}>
            <a href="/invoices" style={{ color: "#ee600d", textDecoration: "underline" }}>
              TRANSACTIONS
            </a>
          </div>
          <div className="walletDiv">
            <div className="reloadButtonDiv">
              <Button id="reloadButton" variant="contained" onClick={this.onRefresh}>
                <ReloadIcon id="reloadIcon" />
                <span id="reloadText">Refresh</span>
              </Button>
            </div>
            <div className="walletPointsText">
              {this.state.walletPoints}
              <span className="pointsText">pts</span>
            </div>
          </div>

          {/* <div
            style={{
              border: "1px solid #FFC7A6",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "0 20px 20px 20px",
            }}>
            <h3>Refer and Earn</h3>
            <p style={{color: "#5d5d5d", marginBottom: "20px"}}>
              Refer a friend &amp; we'll credit you &amp; <br />
              your friend credits worth Rs.20.
            </p>
            <Button
              style={{background: "#ffaa00", backgroundColor: "#ee600d", color: "#ffffff", fontWeight: "400"}}
              variant="contained"
              fullWidth
              onClick={this.onClickReferAndEarn}>
              <span>Invite &amp; Earn</span>
            </Button>
          </div> */}

          {FA_localStorage("phone") && FA_localStorage("email") && FA_localStorage("strict_app_home") != 1 && (
            <div style={{ backgroundImage: "linear-gradient(40deg,#ff2a00,#ff9a00)", borderRadius: 10, marginTop: 14 }}>
              <RefAndEarn className="banner-svg" onClick={(e) => this.setRefAndEarnModelOpen(true)} />
              {this.state.refAndEarnModelOpen !== null && (
                <ReferEarnModal
                  modalOpen={this.state.refAndEarnModelOpen}
                  setModalStatus={this.setRefAndEarnModelOpen}
                  screen="wallet"
                />
              )}
            </div>
          )}

          <Grid id="walletAmountOptions" container>
            {this.state.walletOptions.map((wallet) => (
              <MyWalletItemComponent wallet={wallet} onSelectAmount={this.onSelect} key={wallet.amount} />
            ))}
          </Grid>
          <SideDrawerComponent
            drawerOpen={this.state.drawerOpen}
            onDrawerClose={() => {
              this.setState({ drawerOpen: false });
            }}
          />
          {Object.keys(this.state.reData).length > 0 ? (
            <ReferEarnModel
              modalOpen={this.state.referModalOpen}
              reData={this.state.reData}
              closeREModal={this.closeREModal}
            />
          ) : (
            <></>
          )}

          <ModalComponent
            modalOpen={this.state.modalOpen}
            status={this.state.status}
            orderId={this.state.orderId}
            resetHistory={this.clearHistory}
            goBack={this.goBack}
            rechargeValue={this.state.rechargeValue}
          />

          <Snackbar
            vertical="top"
            horizontal="right"
            message={this.state.snackBar.message}
            closeError={this.closeError}
            open={this.state.snackBar.open}></Snackbar>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MyWallet));
