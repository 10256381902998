import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import clsx from "clsx";
import v3AppIcon from "./common/icon_playstore_v3.png";

import Register from "./modules/user-actions/components/Register.component";
import Login from "./modules/user-actions/components/Login.component";
import Otp from "./modules/user-actions/components/Otp.component";
import AppLogin from "./modules/user-actions/components/AppLogin.component";
import UserProfile from "./modules/user-profile/components/UserProfile.component";
import ReferEarnScreen from "./modules/refer-earn/screen";
import MyAccount from "./modules/account/components/MyAccount.component";
import Invoices from "./modules/account/components/Invoices.component";
import MyFavoriteAstrologers from "./modules/account/components/MyFavoriteAstrologers.component";
import MyLanguagePreference from "./modules/account/components/MyLanguagePreference.component";
import MyLanguagePreferenceNew from "./modules/account/components/MyLanguagePreference";
import MyWallet from "./modules/account/components/MyWallet.component";
import MyConsultations from "./modules/account/components/MyConsultations.component";
import MyConsultationsNew from "./modules/account/components/MyConsultations";
import FamilyUserProfiles from "./modules/user-profile/components/FamilyUserProfiles.component";
import AstrologerProfile from "./modules/user/astrologer/components/AstrologerProfile.component";
import Thankyou from "./modules/consultation/components/Thankyou.component";
import AstrologerConnect from "./modules/consultation/components/AstrologerConnect.component";
import AstrologerConnectFail from "./modules/consultation/components/AstrologerConnectFail.component";
import Search from "./modules/search/components/Search.component";
import SearchNew from "./modules/search/components/Search";
import SimilarSearch from "./modules/search/components/SimilarSearch.component";
import DailyUpdates from "./modules/misc-screens/components/DailyUpdates.component";
import DailyUpdatesSpecific from "./modules/misc-screens/components/DailyUpdatesSpecific.component";
import WeeklyUpdates from "./modules/misc-screens/components/WeeklyUpdates.component";
import WeeklyUpdatesSpecific from "./modules/misc-screens/components/WeeklyUpdatesSpecific.component";
import AboutUs from "./modules/misc-screens/components/AboutUs.component";
import AppLogout from "./modules/misc-screens/components/Logout.component";
import RateUs from "./modules/misc-screens/components/RateUs.component";
import RateClickastro from "./modules/misc-screens/components/RateClickastro.component";
import TermsAndConditions from "./modules/misc-screens/components/TermsAndConditions.component";
import PrivacyPolicy from "./modules/misc-screens/components/PrivacyPolicy.component";
import AstrologersList from "./modules/user/astrologer/components/AstrologersList.component";
import NotFound from "./common/NotFound.component";
import Credits from "./modules/misc-screens/components/Credits.component";
import PaymentLink from "./modules/account/components/PaymentLinkComponent";
import PaymentLinkThanks from "./modules/account/components/PaymentLinkThanks";
import AstrologerProfileNew from "./modules/user/astrologer/components/AstrologerProfile";

import * as Sentry from "@sentry/react";
import BrowserUtils from "./modules/user/astrologer/components/UAScripts";
import { SUPPORT_NUMBER, VERSION } from "./app.config";
import axios from "./common/interceptor";
import QueryString from "./common/util/QueryString";
import PujaPayment from "./modules/account/components/PujaPayment.component";
import SinglePujaItemComponent from "./modules/account/components/SinglePujaItem.component";
import PujaDetailsComponent from "./modules/account/components/PujaDetails.component";
import IntroScreenOrange from "./modules/intro-screens/components/IntroScreenOrange.Component";
import AstrologersListApp from "./modules/user/astrologer/components/AstrologersListApp.component";
import AppHomeNew from "./modules/user/astrologer/components/AppHome";
import AppHome from "./modules/user/astrologer/components/TalkToAstrologers";
import AppHome2 from "./modules/user/astrologer/components/AppHome2";
import VerifyNumber from "./modules/user/astrologer/components/Modal/VerifyNumber";
import AstrologerCall from "./modules/user/astrologer/components/Modal/AstrologerModal";
import InsufficientModal from "./modules/user/astrologer/components/Modal/InsufficientModal";
import UserWallet from "./modules/account/components/UserWallet"
import FreeConsultation from "./modules/user/astrologer/components/FreeConsultation/Index";
import CotLog from "./modules/account/components/CotLog";
import WalletPage from "./modules/account/components/WalletPage";
import WalletResponsePage from "./modules/account/components/WalletResponsePage";
import WalletComponent from "./route/WalletComponent";
import AppDirect from "./modules/feature/app-direct/AppDirect";
import AppDynamicNotificationDirect from "./modules/feature/app-direct/AppDynamicNotificationDirect";
import DeleteMyAccount from "./modules/misc-screens/components/DeleteMyAccount";
import AccountDeletionProcess from "./modules/misc-screens/components/AccountDeletionProcess";
import AccountDeleted from "./modules/misc-screens/components/AccountDeleted";

if (window.location.href.indexOf("http://localhost:3000") < 0) {
  Sentry.init({
    dsn: "https://0bb86a7c93b0422386c362b1c3116017@o542083.ingest.sentry.io/5687422",
    ignoreErrors: [
      "InvalidStateError: A mutation operation was attempted on a database that did not allow mutations.",
      "t.includes is not a function",
    ],
    denyUrls: [
      /https:\/\/cdn\.inspectlet\.com/i,
      /https:\/\/sdk-01\.moengage\.com/i,
      /https:\/\/cdn\.moengage\.com/i,
      /https:\/\/placesapis\.clickastro\.com/i,
    ],
  });
}

export const getAverageRGB = (imgEl) => {
  const defaultRGB = { r: 0, g: 0, b: 0 }; // for non-supporting envs
  if (imgEl) {
    var blockSize = 25, // only visit every 5 pixels
      canvas = document.createElement("canvas"),
      context = canvas.getContext && canvas.getContext("2d"),
      data,
      width,
      height,
      i = -4,
      length,
      rgb = { r: 0, g: 0, b: 0 },
      count = 0;

    if (!context) {
      return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
      data = context.getImageData(0, 0, width, height);
    } catch (e) {
      /* security error, img on diff domain */
      return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;
  } else {
    return defaultRGB;
  }
};

function App(props) {
  useEffect(() => {
    const URLParams = QueryString();
    //console.log(URLParams);
    /**
     * ref_code tracking -
     * utm_campaign as a reliable utm parameter from branch
     * we pass ref code embedded in campaign
     */
    if (URLParams.utm_campaign) {
      const refCode = URLParams.utm_campaign.split(":")[1];
      if (refCode && refCode.match(/^([a-z,0-9]{4,16}\-[0-9]{5})+\-([a-z]{4,16})$/)) {
        localStorage["ref_code"] = refCode;
      }
    }
    /** attribution tracking */
    if (URLParams.utm_source && URLParams.utm_medium) {
      localStorage.setItem(
        "user_attribution",
        BrowserUtils.encodeFA(
          JSON.stringify({
            utm_source: URLParams.utm_source ? URLParams.utm_source : "no_source",
            utm_medium: URLParams.utm_medium ? URLParams.utm_medium : "no_medium",
            utm_campaign: URLParams.utm_campaign ? URLParams.utm_campaign.split(":")[0] : "no_campaign",
            utm_term: URLParams.utm_term ? URLParams.utm_term : "no_term",
            utm_content: URLParams.utm_content ? URLParams.utm_content : "no_content",
            utm_client: URLParams.utm_client ? URLParams.utm_client : "no_client",
            utm_platform: URLParams.utm_platform ? URLParams.utm_platform : window.AndroidBridge ? "android" : "web",
            ref_code: localStorage["ref_code"],
            client_version:
              "a." + (window.AndroidBridge ? window.AndroidBridge.call("getAppVersion") : "0.0.0") + " | b." + VERSION,
          })
        )
      );
    }

    RegisterSession();

    if (!window.lastHash) {
      //console.log("falog", window.lastHash);
      window.addEventListener("hashchange", function (e) {
        if (window.location.hash.startsWith("#back-button--")) {
          console.log("do noting");
        } else if (window.location.hash === "#alert-confirm") {
          console.log("do noting");
        } else if (window.lastHash === "#backtohome") {
          window.history.replaceState(props, "", "/");
          window.location.href = "/";
        } else if (window.lastHash === "#backtowallet") {
          window.history.replaceState(props, "", "/");
          window.location.href = "/my-wallet";
        } else if (window.lastHash === "#backtomain" && window.location.hash === "#pujadetails") {
          console.log("do noting");
        } else if (window.lastHash === "#backtomain" && window.location.hash === "#searchpuja") {
          console.log("do noting");
        } else if (window.lastHash === "#backtomain" && window.location.hash.includes("#pujasubc-")) {
          console.log("do noting");
        } else if (window.lastHash === "#backtochat" && window.location.hash.includes("#insufficient-balance")) {
          console.log("do noting");
        } else if (window.lastHash === "#backtomain" && window.location.hash !== "#backtochat") {
          window.history.replaceState(props, "", "/");
          window.location.href = "/";
        } else if (window.lastHash === "#closeprofilepopup" && window.location.hash.includes("#storyopen")) {
          console.log("do noting");
        } else if (window.lastHash === "#closeprofilepopup" && window.location.hash.includes("#confirm-birth-details")) {
          console.log("do noting");
        } else if (window.lastHash === "#backtochat" && window.location.hash.includes("#confirm-birth-details")) {
          console.log("do noting");
        } else if (window.lastHash === "#backtomain" && window.location.hash.includes("#confirm-birth-details")) {
          console.log("do noting");
        } else if (window.lastHash === "#closeprofilepopup" && window.location.hash.includes("#verify-mobile-number")) {
          console.log("do noting");
        } else if (window.lastHash === "#closeprofilepopup" && window.location.hash.includes("#insufficient-balance")) {
          console.log("do noting");
        } else if (window.lastHash === "#closeprofilepopup" && window.location.hash !== "#backtochat") {
          window.history.replaceState(props, "", "/");
          window.location.href = "/";
        } else if (window.lastHash === "#backtochat") {
          window.history.replaceState(props, "", "/");
          window.location.href = "/?view=chat#backtomain";
        } else {
          // do nothing.
        }
        window.lastHash = window.location.hash;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RegisterSession = () => {
    /*
      track session on opening the app
    */
    if (localStorage.getItem("phone") !== null && sessionStorage && !sessionStorage["sessionRegistered"]) {
      sessionStorage["sessionRegistered"] = true;
      axios()
        .post("/user/attribute-stats", { type: "session" })
        .then((res) => {
          // Do nothing
        })
        .catch((err) => {
          // Do nothing
        });
    }
  };

  function isClickastroApp(version) {
    return (
      window.AndroidBridge.call("getAppVersion") === null ||
      window.AndroidBridge.call("getAppVersion") === "" ||
      window.AndroidBridge.call("getAppVersion").split(".").length === 4
    );
  }

  const HashRoute = ({ component: Component, path, ...routeProps }) => (
    <Route
      {...routeProps}
      component={({ location, ...props }) =>
        location.hash === path && <Component {...props} />
      }
    />
  )

  const RootComponent = () => {

    // Free consultation flow. To enable FreeConsultation, remove false condition.
    let enableFreeConsultation = false; // disabled free consultation

    if (localStorage.getItem('enableFreeConsultation') !== null) {
      enableFreeConsultation = localStorage.getItem('enableFreeConsultation') === 'true' ? true : false
    }

    if (enableFreeConsultation && localStorage.getItem("strict_app_home") && BrowserUtils.isFreeConsultationFlow()) {
      return <FreeConsultation />
    }

    // strict_app_home mode
    if (localStorage.getItem("strict_app_home")) {
      return <AppHome />
    }

    if (localStorage.getItem("old_user")) {
      return <AstrologersList />
    }
    return <IntroScreenOrange />
  }

  /** typeof window.AndroidBridge === "undefined" ||
    window.compareVersion("3.0.0", window.AndroidBridge.call("getAppVersion")) <= 0  */
  return typeof window.AndroidBridge === "undefined" ||
    isClickastroApp(window.AndroidBridge.call("getAppVersion")) ||
    window.compareVersion("4.0.0", window.AndroidBridge.call("getAppVersion")) <= 0 ? (
    /* alow using the app only if app version is >= 3.0.0 or its a web traffic */
    <div
      className={clsx("App", {
        desktop:
          window.location.pathname !== "/" &&
          window.location.pathname !== "/astrologers" &&
          !window.location.pathname.startsWith("/astrologer/"),
      })}>
      <Router>
        <Switch>
          <Route path="/my-favorite-languages" exact component={BrowserUtils.isNewUI() ? MyLanguagePreferenceNew : MyLanguagePreference} />
          <Route path="/astrologers" exact component={AstrologersList} />
          <Route path="/astrologer/:url" exact component={BrowserUtils.isNewUI() ? AstrologerProfileNew : AstrologerProfile} />
          <Route path="/astrologer-profile/:url" exact component={AstrologerProfileNew} />
          <Route path="/puja/:id" exact component={PujaDetailsComponent} />
          <Route path="/intro" exact component={IntroScreenOrange} />
          <Route path="/register" exact component={Register} />
          <Route path="/login" exact component={Login} />
          <Route path="/otp" exact component={Otp} />
          <Route path="/language-select" exact component={MyLanguagePreference} />
          <Route path="/familyuserprofiles" exact component={FamilyUserProfiles} />
          <Route path="/userprofile" exact component={UserProfile} />
          <Route path="/childprofile/add" exact component={UserProfile} />
          <Route path="/edit-page/:id" exact component={UserProfile} />
          <Route path="/refer-earn" exact component={ReferEarnScreen} />
          <Route path="/my-account" exact component={MyAccount} />
          <Route path="/my-favorite-astrologer" exact component={MyFavoriteAstrologers} />
          <Route path="/invoices" exact component={Invoices} />
          <Route path="/payment/:id" exact component={PaymentLink} />
          <Route path="/payment-thanks/success/:id" exact component={PaymentLinkThanks} />
          <Route path="/payment-thanks/fail/:id" exact component={PaymentLinkThanks} />
          <Route path="/my-wallet" exact component={WalletComponent} />
          <Route path="/my-wallet/invalid" exact component={WalletComponent} />
          <Route path="/recharge/success/:id" exact component={WalletComponent} />
          <Route path="/recharge/fail/:id" exact component={WalletComponent} />
          <Route path="/user-consultation-history" exact component={BrowserUtils.isNewUI() ? MyConsultationsNew : MyConsultations} />
          <Route path="/consultant-feedback/:id" exact component={Thankyou} />
          <Route path="/astrologer-connect/:id" exact component={AstrologerConnect} />
          <Route path="/connect-fail" exact component={AstrologerConnectFail} />
          <Route path="/similar-astrologer/:url" exact component={SimilarSearch} />
          <Route path="/search-results" exact component={BrowserUtils.isNewUI() ? SearchNew : Search} />
          <Route path="/daily-updates" exact component={DailyUpdates} />
          <Route path="/daily-updates/:id" exact component={DailyUpdatesSpecific} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/delete-my-account" exact component={DeleteMyAccount} />
            <Route path="/account-deletion" exact component={AccountDeletionProcess} />
            <Route path="/account-deleted" exact component={AccountDeleted} />
          <Route path="/log" exact component={CotLog} />
          <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
          <Route path="/weekly-updates" exact component={WeeklyUpdates} />
          <Route path="/weekly-updates/:id" exact component={WeeklyUpdatesSpecific} />
          <Route path="/rate-us" exact component={RateUs} />
          <Route path="/rate-clickastro" exact component={RateClickastro} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/app/login" exact component={AppLogin} />
          <Route path="/app/logout" exact component={AppLogout} />
          <Route path="/app-astrologers" exact component={AstrologersListApp} />
          <Route path="/app-home" component={RootComponent} />
          {/* Direct astrologer listing w/o additional checking */}
          <Route path="/app-home-listing" component={AppHome} />
          <Route path="/app-home2" exact component={AppHome2} />
          <Route path="/" exact component={RootComponent} />
          <Route path="/redeem" exact component={AstrologersList} />
          <Route path="/credits" exact component={Credits} />
          <Route path="/puja-booking" component={PujaPayment} />
          <Route path="/user-wallet" exact component={WalletComponent} />
          <Route path="/canpg-complete" exact component={WalletResponsePage} />
          <Route path="/app-direct" exact component={AppDirect} />
          <Route path="/app-redirect" exact component={AppDynamicNotificationDirect} />
          <Route path="/free-consultation" exact component={FreeConsultation} />
          <Route path="/capg-complete" exact component={WalletResponsePage} />
          <Route path="" component={NotFound} />
        </Switch>
        {/* <HashRoute path="#verify-number" component={VerifyNumber} />
        <HashRoute path="#verify-number-AndroidLogin" component={VerifyNumber} />
        <HashRoute path="#astrologer-action" component={AstrologerCall} />
        <HashRoute path="#insufficient-balance" component={InsufficientModal} /> */}
      </Router>
    </div>
  ) : (
    <div className="forceupdate">
      <div className="dialog">
        <div>
          <h2>App update required!</h2>
          <img src={v3AppIcon} alt="app icon" />
          <div className="sub">Download the latest app from Playstore</div>
          <p>
            This is an important update, download the new app for the new chat consultation feature and other overall
            improvements.
            <br />
            <br />
            For any assistance, please contact:
            <a target="_blank" href={"tel:" + String(SUPPORT_NUMBER).replace(/\s/g, "")}>
              {SUPPORT_NUMBER}
            </a>
          </p>
        </div>
        {/* show the update cta for playstore compatible apks, ie ver: >= 2.0.17 */}
        {window.AndroidBridge && window.compareVersion("2.0.17", window.AndroidBridge.call("getAppVersion")) <= 0 && (
          <div className="cta">
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3DFindastro_app%26utm_medium%3Dapp%20update%20banner%26utm_content%3D" +
                window.AndroidBridge.call("getAppVersion")
              }
              target="_blank"
              rel="noreferrer">
              <button>Install the new Findastro app</button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
