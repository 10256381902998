import axios from "axios";
import { VERSION } from "../app.config";
import BrowserUtils from "../modules/user/astrologer/components/UAScripts";

const axiosInstance = (history = null) => {

  const inst = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 60000,
  }

  if (localStorage.getItem("btkn") || localStorage.getItem("utkn")) {
    inst.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      Authorization: localStorage.getItem("btkn") ? `Bearer ${localStorage.getItem("btkn")}` : "",
      token: localStorage.getItem("utkn") ? `${localStorage.getItem("utkn")}` : "",
      Attribution: localStorage.getItem("user_attribution") || "",
      clientVersion:
        "a." + (window.AndroidBridge ? window.AndroidBridge.call("getAppVersion") : "0.0.0") + " | b." + VERSION,
    }
  }

  const axiosInstance = axios.create(inst)

  axiosInstance.interceptors.request.use((config) => {
    if (config.method === 'post' && localStorage.getItem("strict_app_home")) {
      if (config.data === undefined) {
        config.data = { app_id: 'CAEN' }
      } else {
        config.data = { ...config.data, app_id: 'CAEN' }
      }
      // console.log(config)
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem("utkn");
        localStorage.removeItem("btkn");

        if (BrowserUtils.isFreeConsultationFlow()) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        } else {
          if (history) {
            history.push("/app/logout");
          } else {
            window.location.href = "/app/logout";
          }
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );
  return axiosInstance;
};
export default axiosInstance;
