import React from "react";
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const MyWalletItemComponent = (props) => {
  const onSelectAmount = (e) => {
    props.onSelectAmount(props.wallet.amount);
    e.preventDefault();
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <li className="walletItemDiv" key={props.wallet.amount}>
      <span>
        ₹ {props.wallet.amount} {props.wallet.gst === true ? <small>+ GST</small> : <></>}
      </span>
      <span
        className={clsx(
          "points",
          {premium: props.wallet.points > 4000},
          {bign: props.wallet.points > 10000},
          {econ: props.wallet.points > 1000}
        )}>
        {numberWithCommas(props.wallet.points)} pts
      </span>
      <span>
        <Button onClick={onSelectAmount} className={"rc-button"}>
          Recharge
        </Button>
      </span>
    </li>
  );
};
export default withRouter(MyWalletItemComponent);
