import { extract } from "query-string";
import BrowserUtils from "../modules/user/astrologer/components/UAScripts";

/**
 * Track a user event and additional attributes
 *
 * @param {object} event - An object representing the event.
 * @param {string} event.name - The name of the event.
 * @param {object} event.attributes - Additional attributes for the event.
 * @param {object} [extra] - An optional object representing additional attributes.
 */
const T2AEventTrack = (event, extra = {}) => {
  console.log({ event: event, extra: extra })
  if (Object.keys(event).length > 0) {
    trackEventWithAndroidBridge(event, extra.user || false)
  } else if (extra?.actionType === 'purchase') {
    T2ATrackBranchEvents(extra)
  } else if (extra) {
    T2AEventTrackUserAttributes(extra)
  }
}

const T2ATrackBranchEvents = (payload) => {
  try {
    if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
      BrowserUtils.devAlert(JSON.stringify(payload))
      window.AndroidBridge.call("postBranchEvent", JSON.stringify(payload))
      payload.eventType = 'COT_ALL_PURCHASE';
      window.AndroidBridge.call("postBranchEvent", JSON.stringify(payload))
    }
  } catch (error) {
    console.error('Error trackEventWithAndroidBridge:', error.message);
  }
}

const T2AEventTrackUserAttributes = (user) => {
  try {
    if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined' && user) {
      BrowserUtils.devAlert(JSON.stringify(user))
      window.AndroidBridge.call("pushMoeUserAttributes", JSON.stringify(user))
    }
  } catch (error) {
    console.error('Error trackUserEventWithAndroidBridge:', error.message);
  }
}

/**
 * Helper function to track events and user attributes using window.AndroidBridge
 *
 * @param {object} event - An object representing the event
 * @param {object} user - An object representing additional user attributes
 *
 * @throws {Error} Throws an error if there's an issue with tracking the event.
 */
const trackEventWithAndroidBridge = (event, user) => {
  // console.log('T2AEventTrack:trackEventWithAndroidBridge:', event, user)
  try {
    if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
      if (event.name === 'Screen View Action') {
        event.attributes['COT User Status'] = BrowserUtils.CurrentUserInfo().userType;
        event.attributes['CA Paid Status'] = BrowserUtils.CurrentUserInfo().paidStatus;
      }
      const eventData = {
        event_name: event.name,
        event_params: event.attributes
      }
      BrowserUtils.devAlert(JSON.stringify(eventData))
      // unset User ID from eventData.event_params to avoid conflicting COT and CA user ID.
      eventData.event_params['User ID'] = undefined;

      // unset User ID from user to avoid conflicting COT and CA user ID.
      user && (user['User ID'] = undefined);

      window.AndroidBridge.call("pushMoeEvents", JSON.stringify(eventData))
      if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined' && user) {
        BrowserUtils.devAlert(JSON.stringify(user))
        window.AndroidBridge.call("pushMoeUserAttributes", JSON.stringify(user))
      }
    }
  } catch (error) {
    console.error('Error trackEventWithAndroidBridge:', error.message);
  }
}

export default T2AEventTrack;