import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import SearchIcon from "@material-ui/icons/Search";
import {Dialog, Grid, InputBase} from "@material-ui/core";
import SinglePujaDetails from "./SinglePujaItem.component";
import {ReactComponent as Separator} from "../../../common/ornamental-bar.svg";
import AstrologerUtils, {formatAmount} from "../../user/astrologer/components/AstrologerUtils";

import {ReactComponent as PrasadIcon} from "./prasad.svg";
import {ReactComponent as HavanIcon} from "./havan.svg";
import {ReactComponent as ExpIcon} from "./experience.svg";
import {ReactComponent as DiscountIcon} from "./discount.svg";
import {ReactComponent as VideoIcon} from "./videocall.svg";
import {ReactComponent as LocationIcon} from "./location.svg";
import defaultImage from "../../../common/default_profile_image.svg";
import SearchPujaItemComponent from "./SearchPujaItem.component";
import FireGif from "../../../common/fire.gif";
import QueryString from "../../../common/util/QueryString";
import PujaInfoBanner from "./PujaInfo.component";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import UpArrow from "@material-ui/icons/ExpandLess";

const formatClass = (activeGrouping) => {
  return activeGrouping === "type" ? activeGrouping : "multi";
};
const PujaList = (props) => {
  /* const [specialHomas, setSpecialHomas] = useState([]);
  const [specialPujas, setSpecialPujas] = useState([]); */
  const [pujaHavanList, setPujaHavanList] = useState([]);
  const [pujasCount, setPujasCount] = useState(null);
  const [pujasMaxPercentage, setPujasMaxPercentage] = useState(null);

  const params = QueryString();
  const scrollToItem = (e, id = null) => {
    if (id || window.location.hash.length > 0) {
      const selector = id || window.location.hash;
      const el = document.querySelector(selector);
      if (el) {
        props.scrollPanel.current.scrollTo(0, el.offsetTop - 120);
        return id === null && trackClick("fa_remedy_subgroup", "NA", window.location.hash.substr(1));
      }
    }
  };

  const trackClick = (type, content, label, data) => {
    moengage(type);
    const payload = {
      p_screen: "remedy_list",
      p_label: label,
      p_content: content,
      p_value: data ? data.discounted_price || 0 : 0,
      value: data ? data.discounted_price || 0 : 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  const componentUnmount = () => {
    window.removeEventListener("hashchange", scrollToItem);
  };

  useEffect(() => {
    console.log("PujaList", "mounted");
    axios()
      .post("/puja/list")
      .then((res) => {
        setPujaHavanList(res.data.data);
        setPujasCount(res.data.puja_count);
        setPujasMaxPercentage(res.data.overall_max_discount_percentage);
        categorizePujaList(res.data.data);
        if (params.puja === "1") {
          for (const puja of res.data.data) {
            if (puja.puja_id.toString() === params.id) {
              selectPuja(puja);
              break;
            }
          }
        }
        /* const specialHomas = res.data.data.filter((puja) => puja.type === "homa");
          const specialPujas = res.data.data.filter((puja) => puja.type === "puja");
          setSpecialHomas(specialHomas);
          setSpecialPujas(specialPujas);
           */
        /*if (window.location.hash === "#pujadetails") {
            selectPuja(dailyPujas[0]);
          }*/
      })
      .catch((err) => {
        sentryError("PUJA_LIST_ERROR", err);
      });

    window.addEventListener("hashchange", scrollToItem);
    return componentUnmount;
  }, []);

  const selectPuja = (puja) => {
    axios()
      .post("/puja/details", {puja_id: puja.puja_id})
      .then((res) => {
        setSelectedPuja(res.data);
        window.location.hash = "#pujadetails";
      })
      .catch((err) => {
        props.showError("Unable to get Puja or Havan details");
      });
    trackClick("fa_remedy_view_details", "NA", puja.product_id, puja);
  };

  const closeSinglePuja = () => {
    setSelectedPuja(null);
  };

  const [selectedPuja, setSelectedPuja] = useState(null);
  const [activeGrouping, setActiveGrouping] = useState("category");
  const [categorizedList, setCategorizedList] = useState({});
  const [categorizing, setCategorizing] = useState(false);

  const changeGrouping = (e) => {
    const group = e.target.innerText.toLowerCase();
    if (!e.target.disabled && activeGrouping !== group) {
      e.target.disabled = true;
      setCategorizing(true);
      setActiveGrouping(group);
      trackClick("fa_remedy_group", "NA", group);
      setTimeout(() => (e.target.disabled = false), 400);
    }
  };

  const categorizePujaList = (data = null) => {
    data = data ? data : pujaHavanList;
    const out = {};
    switch (activeGrouping) {
      case "alphabetical":
        const map = {};
        data.forEach((puja) => {
          map[puja.name] = map[puja.name] || [];
          map[puja.name].push(puja);
        });
        const Names = Object.keys(map);
        Names.sort();

        Names.forEach((name) => {
          const key = "Starts with " + name[0];
          out[key] = out[key] || [];
          out[key].push(map[name][0]);
        });
        setCategorizedList(out);
        break;
      case "diety":
        const dieties = new Set();
        data.forEach((puja) => {
          (puja.diety || ["All"]).forEach((diety) => dieties.add(diety));
        });
        const sortedDList = Array.from(dieties);
        sortedDList.sort();
        if (sortedDList.includes("Other")) {
          const oidx = sortedDList.indexOf("Other");
          sortedDList.splice(oidx, 1);
          sortedDList.push("Other");
        }
        sortedDList.forEach((diety) => {
          data.forEach((puja) => {
            out[diety] = out[diety] || [];
            if ((puja.diety || ["All"]).includes(diety)) out[diety].push(puja);
          });
        });
        setCategorizedList(out);
        break;
      case "category":
        const categories = new Set();
        data.forEach((puja) => {
          (puja.category || ["Common"]).forEach((category) => categories.add(category));
        });
        const sortedCList = Array.from(categories);
        sortedCList.sort();
        if (sortedCList.includes("Other")) {
          const oidx = sortedCList.indexOf("Other");
          sortedCList.splice(oidx, 1);
          sortedCList.push("Other");
        }
        sortedCList.forEach((category) => {
          data.forEach((puja) => {
            out[category] = out[category] || [];
            if ((puja.category || ["Common"]).includes(category)) out[category].push(puja);
          });
        });
        setCategorizedList(out);
        break;
      case "type":
        data.forEach((puja) => {
          out[puja.type] = out[puja.type] || [];
          out[puja.type].push(puja);
        });
        setCategorizedList(out);
        break;
      default:
        setCategorizedList(out);
        break;
    }
    setCategorizing(false);
  };

  useEffect(() => {
    categorizePujaList();
  }, [activeGrouping]);

  const [showSearchWindow, setShowSearchWindow] = useState(false);

  const triggerSearch = (e) => {
    setShowSearchWindow(true);
    window.location.hash = "searchpuja";
  };

  const closeSearch = () => {
    setShowSearchWindow(false);
  };

  const prepareAlphabeticalList = () => {
    const alphabets = {};
    Object.values(categorizedList).map((item) => {
      return item.map((puja) => {
        const alphabet = puja.name[0];
        alphabets[alphabet] = alphabets[alphabet] || 0;
        alphabets[alphabet]++;
        return item;
      });
    });
    return Object.keys(alphabets).map((alphabet) => (
      <a href={"#Starts-with-" + alphabet}>
        <span className={`item`}>
          {alphabet} ({alphabets[alphabet]})
        </span>
      </a>
    ));
  };

  return (
    <div className="puja-list">
      <div className="cover-image">
        <img src={FireGif} alt="fire" className="puja-fire-img" />
        <h3>Pujas &amp; Havans</h3>
      </div>
      <div className="white-marker">
        <div className="puja-highlights">
          <PujaInfoBanner screen={"remedy_list"} />
          <div className="significance">
            <div className="title">
              <b>Significance of Pujas and Havans</b>
            </div>
            <div>
              Prayers and Pujas (also spelled as Pooja) are part of everybody's life. It is how we communicate with god.
              Pujas and Homas (havan) are done to make the divine powers happy so that they bless us by fulfilling our
              desires. Pujas is the worship of god through the offering of light, flowers, water or food to the divine
              powers. A picture or statue of the god is kept before the devotee, and the offerings are made to it.
              <br />
              <br />
              Havan (Homam) is similar, but the offering is made to the fire. Havan is a Sanskrit word that refers to
              any ritual where offers are made into a consecrated fire. Another way is the practice of Yagna, which
              means to offer sacrifice to a particular deity for a specific purpose. You can choose the prayer you need
              from an exhaustive list of Pujas and Homas we have prepared for you.
            </div>
            <br />
            <br />
            <div className="title">
              <b>Our Pujas/Havans highlights</b>
            </div>
            {pujasCount && (
              <div className="item">
                <span>
                  <HavanIcon />
                  {pujasCount} different pujas and havan(homa).
                </span>
              </div>
            )}
            <div className="item">
              <span>
                <ExpIcon />
                Done by priests with years of experience.
              </span>
            </div>
            <div className="item">
              <span>
                <VideoIcon />
                Online participation via video call.
              </span>
            </div>
            <div className="item">
              <span>
                <LocationIcon />
                Puja/havan at your location as well
              </span>
            </div>
            <div className="item">
              <span>
                <DiscountIcon />
                Findastro special discount upto {pujasMaxPercentage}%.
              </span>
            </div>
            <div className="item">
              <span>
                <PrasadIcon />
                Prasad/prasadam delivered via courier.
              </span>
            </div>
          </div>
        </div>
        {(pujaHavanList.length > 0 || pujaHavanList.length > 0) && (
          <div className="puja-steps">
            <div className="title">
              <b>How to book a Puja or Havan?</b>
            </div>
            <div>
              Booking a Puja or Havan is extremely simple and won't take more than a minute. Our support team will
              always be there to guide you, if required.
            </div>
            <div className="list">
              <li>
                <em>1</em>
                <span>Search for the Puja or Havan by name or ID and click on the show details button.</span>
              </li>
              <li>
                <em>2</em>
                <span>Select the priest of your choice &amp; make payment</span>
              </li>
              <li>
                <em>3</em>
                <span>
                  Our support team will contact you and the priest within an hour to finalize the Puja/Havan date &amp;
                  time.
                </span>
              </li>
            </div>
          </div>
        )}
      </div>
      <Grid
        container
        className="searchWrap"
        style={{
          margin: "24px 16px",
          marginTop: "-16px",
          padding: "24px 0px 0px",
          width: "auto",
        }}>
        <Grid item xs={11} className="searchInput white">
          <InputBase
            value={null}
            style={{width: "calc(100% - 16px)", height: 40}}
            placeholder="Search Puja or Havan by name, category, ID, etc."
            onClick={triggerSearch}
          />
        </Grid>
        <Grid item xs={1} className="searchIcon white">
          <SearchIcon onClick={triggerSearch} />
        </Grid>
      </Grid>
      {/* {searchDone && searchResults.length > 0 ? (
        <div className="section-title">
          Search results for <big>"{pujaTerm}"</big>
        </div>
      ) : (
        pujaTerm.length > 2 &&
        searchResults &&
        searchResults.length === 0 && (
          <div className="section-title err">
            No pujas/homas/havans found for the search term <big>"{pujaTerm}"</big>
          </div>
        )
      )}
      {searchResults && searchResults.map((puja) => <div>{puja.name}</div>)} */}
      {showSearchWindow && (
        <SearchPujaItemComponent
          closeSearch={closeSearch}
          showError={props.showError}
          PujaComponent={Puja}
          selectPuja={selectPuja}
        />
      )}
      <div className="filter-pujas" id="cat-list">
        <div className="section-title">
          <b>Group Remedies by:</b>
        </div>
        <div>
          <li>
            <span className={`item ${activeGrouping === "category" ? "active" : ""}`} onClick={changeGrouping}>
              Category
            </span>
            <span className={`item ${activeGrouping === "diety" ? "active" : ""}`} onClick={changeGrouping}>
              Diety
            </span>
            <span className={`item ${activeGrouping === "type" ? "active" : ""}`} onClick={changeGrouping}>
              Type
            </span>
            <span className={`item ${activeGrouping === "alphabetical" ? "active" : ""}`} onClick={changeGrouping}>
              Alphabetical
            </span>
          </li>
        </div>
      </div>
      <div className="subfilter">
        <li>
          {Object.keys(categorizedList).length === 0 && <div>Please wait..</div>}
          {!categorizing ? (
            categorizedList && activeGrouping !== "alphabetical" ? (
              Object.keys(categorizedList).map((category) => (
                <a href={"#pujasubc-" + (category || "").replace(/\s/g, "-")}>
                  <span className={`item`}>
                    {category} ({categorizedList[category].length})
                  </span>
                </a>
              ))
            ) : (
              prepareAlphabeticalList()
            )
          ) : (
            <div style={{minHeight: 200, paddingTop: 100}}>Please wait..</div>
          )}
        </li>
      </div>
      {/* {specialPujas.length > 0 && <div className="section-title">Pujas</div>}
      {specialPujas.map((puja) => puja.status === "active" && <Puja data={puja} />)}
      {specialHomas.length > 0 && <div className="section-title">Havans/Homas</div>}
      {specialHomas.map((puja) => puja.status === "active" && <Puja data={puja} />)} */}
      <div>
        {categorizedList &&
          Object.keys(categorizedList).map((category) => (
            /*<PujaHavanList categoryName={category} selectPuja={selectPuja} />*/
            <PujaHavanList
              categoryName={category}
              pujaList={categorizedList[category]}
              groupName={activeGrouping}
              selectPuja={selectPuja}
              scrollToItem={scrollToItem}
            />
          ))}
      </div>
      {selectedPuja && (
        <SinglePujaDetails
          data={selectedPuja}
          closePuja={closeSinglePuja}
          showError={props.showError}
          showAstrologerProfile={props.showAstrologerProfile}
        />
      )}
    </div>
  );
};

/** Puja item component */
const Puja = (props) => {
  const puja = props.data;

  return (
    <div className={["puja", puja.type].join(" ")} onClick={(e) => props.selectPuja(puja)}>
      <div className="title">
        <div>
          <b>{puja.name}</b>
        </div>
        <Separator className={`separator svg-${puja.type}`} />
      </div>
      <div className="info">
        <div className="image">
          <div className="id-label">ID: {puja.product_id}</div>
          <img loading="lazy" src={puja.image} alt={puja.identifier} />
        </div>
        <div className="details">
          <div
            className="short-desc"
            dangerouslySetInnerHTML={{__html: (puja.short_description || "").replace(/\\n/g, "")}}
          />
        </div>
        <div className="fees">
          <b>{AstrologerUtils.upperCaseFirst(puja.type)} Fees </b>:&nbsp;
          <div class="price-block">
            {puja.min_price < puja.max_price ? (
              <span>
                <strike>
                  ₹{formatAmount(puja.min_price)} to ₹{formatAmount(puja.max_price)}
                </strike>
              </span>
            ) : (
              <span>
                <strike>₹{puja.min_price}</strike>
              </span>
            )}
            {puja.min_discounted_price < puja.max_discounted_price ? (
              <span className="price">
                ₹{formatAmount(puja.min_discounted_price)} to ₹{formatAmount(puja.max_discounted_price)}
              </span>
            ) : (
              <span className="price">₹{formatAmount(puja.max_discounted_price)}</span>
            )}
          </div>
          <div>
            <b>Discount </b>:&nbsp;
            {puja.min_discount_percentage !== puja.max_discount_percentage ? (
              <span>
                {puja.min_discount_percentage}% to {puja.max_discount_percentage}%
              </span>
            ) : (
              <span>{formatAmount(puja.min_discount_percentage)}%</span>
            )}
          </div>
          <div className="priests">
            {puja.astrologers.map((ast_id) => (
              <span class="avatar" key={ast_id}>
                <img
                  src={AstrologerUtils.createThumbImageURI(ast_id)}
                  onLoad={(e) => e.target.classList.add("visible")}
                  alt="priests"
                />
                <img loading="lazy" src={defaultImage} className="visible" alt="default" />
              </span>
            ))}
            {puja.astrologers.length} {puja.astrologers.length === 1 ? "priest" : "priests"} available
          </div>
        </div>
      </div>
      <div className="action">
        <button>Show {puja.type} details</button>
      </div>
    </div>
  );
};

const PujaHavanList = (props) => {
  const list = props.pujaList;
  const UAStyle = {
    verticalAlign: "middle",
    fill: "#ffffff",
    marginLeft: "10px",
    borderRadius: "50%",
    marginTop: "-4px",
    backgroundColor: "#f3c37c",
  };
  return (
    <div className={`categorized-list ${formatClass(props.groupName)}`}>
      <div className="section-title" id={`pujasubc-${(props.categoryName || "").replace(/\s/g, "-")}`}>
        <b>{AstrologerUtils.upperCaseFirst(props.categoryName)}</b>&nbsp;
        <small>({list.length})</small>
        <UpArrow style={UAStyle} onClick={(e) => props.scrollToItem(e, "#cat-list")} />
      </div>

      <div className="list-items">
        {list.map((puja) => (
          <Puja data={puja} selectPuja={props.selectPuja} />
        ))}
      </div>
    </div>
  );
};

export default PujaList;
