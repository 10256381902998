import React, {Component} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import "./Search.css";
import Snackbar from "../../../common/SnackBarMessage";
import HelmetComponent from "../../../common/HelmetComponent.component";
import AstrologerDetailsComponent from "../../user/astrologer/components/Astrologer-Details.component";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import {STATUS_API_PATH} from "../../../app.config";
import QueryString from "../../../common/util/QueryString";
import clsx from "clsx";
import {FormControlLabel, Switch} from "@material-ui/core";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

export default class Search extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    isLoading: true,
    isOthersLoading: true,
    astrologersData: [],
    searchText: "",
    isFetching: false,
    hasMore: true,
    currentPage: 1,
    maxPages: 1,
    recommendedData: [],
    offerClaim: true,
    category: "all",
    toastBar: {},
    showOnlyOnline: false,
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  requestAndFetchData = (page = 1) => {
    axios()
      .post("/search?q=" + QueryString().q + "&page=" + page)
      .then((res) => {
        if (res.data.success) {
          this.setState({isLoading: false});
          if (res.data.data.length === 0) {
            axios()
              .post("/astrologers/all/d")
              .then((res) => {
                if (res.data.success) {
                  this.setState({recommendedData: res.data.data, isOthersLoading: false});
                } else {
                  this.showError(res.data.message);
                }
              })
              .catch((err) => {
                sentryError("SEARCH_RECOMMENDED", err);
                this.showError("Something went wrong. please try again");
              });
          } else {
            if (localStorage.getItem("btkn") && localStorage.getItem("utkn") && page === 1) {
              this.setState({offerClaim: false});
              axios()
                .post("/offer-claim")
                .then((res) => {
                  if (res.data.success) {
                    this.setState({offerClaim: res.data.isNUser});
                  }
                })
                .catch((err) => {
                  sentryError("SEARCH_OFFERCLAIM", err);
                });
            }
            let temp = this.state.astrologersData;
            for (let key in res.data.data) {
              temp.push(res.data.data[key]);
            }
            this.setState({
              astrologersData: temp,
              isLoading: false,
              isFetching: false,
              currentPage: this.state.currentPage + 1,
              maxPages: res.data.pagination.last_page,
            });
          }
          this.implementStatusListener();
        } else {
          this.showError(res.data.message);
          this.setState({isLoading: false});
        }
      })
      .catch((err) => {
        sentryError("SEARCH_PAGELOAD", err);
        this.showError("Something went wrong. please try again");
        this.setState({isLoading: false});
      });
  };

  fetchMoreData = () => {
    if (this.state.currentPage > this.state.maxPages) {
      this.setState({hasMore: false});
      return;
    } else {
      this.setState({isFetching: true});
      this.requestAndFetchData(this.state.currentPage);

      if (this.state.currentPage >= this.state.maxPages) {
        this.setState({hasMore: false});
      }
    }
  };

  onSearch = () => {
    moengage("SEARCH_CLICKED");
    let url = "/search-results?p=search&q=" + this.state.searchText.q;
    if (this.state.searchText.q && this.state.searchText.q !== "") {
      if (this.state.category !== "all") {
        url = url + "&category=" + this.state.category;
      }
      window.location.href = url;
    } else {
      this.showError("Please enter a valid search input");
    }
  };

  onSearchText = (e) => {
    this.setState({searchText: {q: e.target.value}});
  };

  updateAstrologerStatus = (update) => {
    const asCopy =
      this.state.astrologersData.length > 0 ? [...this.state.astrologersData] : [...this.state.recommendedData];
    asCopy.map((data, i) => {
      if (data.id.toString() === update.message.astrologer_id.toString()) {
        data.status = update.message.status_to;
        data.away_until = update.message.away_until;
        //console.log("arun", new Date(data.away_until));
        const message = data.name + " is " + update.message.status_to + " now.";
        this.showStatusToast(message, update.message.status_to);
      }
      return true;
    });
    this.setState(this.state.astrologersData.length > 0 ? {astrologersData: asCopy} : {recommendedData: asCopy});
  };

  showStatusToast = (message, status) => {
    this.setState({toastBar: {message: message, status: status, open: true}});

    if (window.toastHandle) clearTimeout(window.toastHandle);
    window.toastHandle = setTimeout(() => {
      this.setState({toastBar: {message: message, status: status, open: false}});
    }, 3000);
  };

  implementStatusListener = () => {
    const that = this;
    if (typeof EventSource !== "undefined" && !window.statusChangeListener) {
      try {
        const es = new EventSource(STATUS_API_PATH);
        window.statusChangeListener = es;
        var listener = function (event) {
          if (typeof event.data !== "undefined") {
            const update = JSON.parse(event.data);
            //console.log("arun", update);
            if (validateOrigin(update.origin) && update.type === "status" && update.message.astrologer_id) {
              that.updateAstrologerStatus(update);
            }
          }
        };
        window.statusChangeListener = es;
        es.addEventListener("message", listener);
        es.addEventListener("error", (event) => {
          //sentryError("SSE run:Error", event);
          console.error("SSEError", event);
        });
      } catch (err) {
        //sentryError("SSE init:Error", err);
        console.error("SSEError", err);
      }
    }

    const validateOrigin = (origin) => {
      return window.location.origin.indexOf("http://localhost") >= 0 || window.location.origin === origin;
    };
  };

  trackPageview = () => {
    /** event tracking */
    const type = "fa_search";
    const payload = {
      p_screen: QueryString().p ? "search" : "home",
      p_label: "NA",
      p_content: this.state.searchText ? this.state.searchText.q : QueryString().q,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  componentDidMount = () => {
    if (!this.props.location.search || !QueryString().q) {
      this.setState({isLoading: false});
      //this.home();
    } else {
      this.setState({searchText: QueryString()});
      moengage("SEARCH_PAGE_VIEWED");
      moengage("fa_search");
      this.requestAndFetchData();
    }
    this.trackPageview();
  };

  openCallDialog = (astrologer, astrologerType) => {
    window.location.href = "/astrologer/" + astrologer.profileUrl;
  };

  home = () => {
    window.location.href = "/";
  };

  toggleshowOnlyOnline = (e) => {
    let checked = e.target.checked;
    sessionStorage.setItem("onlyOnlineinSearch", checked);
    this.setState({showOnlyOnline: checked});
    if (checked) this.showStatusToast("Showing only online astrologers");
    else this.showStatusToast("Showing all astrologers");
  };

  render() {
    const astrologerLlist = this.state.astrologersData.map((family) => (
      <AstrologerDetailsComponent
        family={family}
        key={family.id}
        isSearch={true}
        offerClaim={this.state.offerClaim}
        handleCall={this.openCallDialog}
      />
    ));

    return (
      <div className="searchParentDiv">
        <HelmetComponent
          title="Search Results at Findastro"
          url="https://www.findastro.com/search-results"
          description="Search Results at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        <div className="searchTopArea">
          <ArrowBackIcon className="searchArrowIcon" onClick={this.home} />
          <div className="searchChildTopArea">
            <p className="similarSearchText">Search astrologers:</p>
          </div>
        </div>
        <Grid item xs={12} lg={12} className={"searchBar"}>
          <Grid container className="searchWrap" style={{marginTop: 0, padding: "24px 0 0 0"}}>
            <Grid item xs={11} className="searchInput">
              <InputBase
                value={this.state.searchText.q}
                style={{width: "calc(100% - 16px)", height: 40}}
                placeholder="Search Astrologer by name, skills, category, etc."
                onChange={this.onSearchText}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    this.onSearch();
                  }
                }}
              />
            </Grid>
            <Grid item xs={1} className="searchIcon">
              <SearchIcon onClick={this.onSearch} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div style={{display: "flex", justifyContent: "initial"}}>
            <span
              style={{
                display: "inline-block",
                padding: "8px",
                fontSize: "0.8rem",
                paddingTop: "10px",
                color: "#323232",
              }}>
              Show only online
            </span>
            <FormControlLabel
              control={
                <Switch
                  value={this.state.showOnlyOnline}
                  checked={this.state.showOnlyOnline}
                  onChange={this.toggleshowOnlyOnline}
                />
              }
            />
          </div>
        </Grid>
        {this.state.isLoading ? (
          <div className="circle-loading extra-circle">
            <CircularProgress />
          </div>
        ) : this.state.astrologersData.length > 0 ? (
          <InfiniteScroll
            className={clsx({onlineCardsOnly: this.state.showOnlyOnline === true})}
            dataLength={this.state.astrologersData.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={
              this.state.isFetching && (
                <div className="circle-loading extra-circle2">
                  <CircularProgress />
                </div>
              )
            }>
            {astrologerLlist}
          </InfiniteScroll>
        ) : (
          (this.props.location.search || window.location.search.length > 0) && (
            <div>
              <Paper className="searchPageNotFoundPaper" elevation={3}>
                <div>No astrologers found.</div>
              </Paper>
              {this.state.isOthersLoading ? (
                <></>
              ) : (
                <div className={clsx({onlineCardsOnly: this.state.showOnlyOnline === true})}>
                  {this.state.recommendedData.length > 0 && (
                    <div className="searchPageyoumightalsolikeText">Other Astrologers you might like</div>
                  )}
                  {this.state.recommendedData.map((astrologer) => (
                    <AstrologerDetailsComponent
                      family={astrologer}
                      key={astrologer.id}
                      isSearch={true}
                      handleCall={this.openCallDialog}
                    />
                  ))}
                </div>
              )}
            </div>
          )
        )}
        <div
          style={{
            position: "fixed",
            maxWidth: "300px",
            width: "auto",
            padding: "0 16px",
            borderRadius: "8px",
            border: "none",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "56px",
            top: "auto",
          }}
          className={clsx("toastBar", this.state.toastBar.status, {toastBarOpen: this.state.toastBar.open === true})}>
          {this.state.toastBar.message}
        </div>
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}
