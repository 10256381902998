import React, {useEffect, useRef, useState} from "react";
import {Avatar} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ArrowBack";
import AstrologerUtils, {formatAmount} from "../../user/astrologer/components/AstrologerUtils";
import defaultImage from "../../../common/default_profile_image.svg";
import sentryError from "../../../common/sentryError";
import axios from "../../../common/interceptor";
import {
  DEFAULT_PRASAD_COST,
  PUJA_SUPPORT_NUMBER,
  SUPPORT_NUMBER,
  VALID_EMAIL_ID,
  VALID_NAME,
  VALID_PHONE,
} from "../../../app.config";
import {ReactComponent as Separator} from "../../../common/ornamental-bar.svg";

import QueryString from "../../../common/util/QueryString";
import PujaInfoBanner from "./PujaInfo.component";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import moengage from "../../../common/moengage";
import Astrologer from "./PriestCard.component";

const SinglePujaDetails = (props) => {
  const data = props.data;

  const params = QueryString();

  const hashChangeHandler = function () {
    if (!["#pujadetails", "#closeprofilepopup"].includes(window.location.hash)) {
      goBack();
    }
  };
  const componentWillUnmount = function () {
    console.log("unmounted puja search");
    window.removeEventListener("hashchange", hashChangeHandler, true);
  };

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return componentWillUnmount;
  }, []);

  const cFn = function (data) {
    props.showError("Payment failed. Please try again.");
  };
  const razorCapturePayment = cFn,
    razorCancelTransaction = cFn,
    razorFailedTransaction = cFn;

  const triggerRazorpay = (data) => {
    //console.log(data);
    let options = {
      key: data.key,
      amount: parseInt(data.amount, 10),
      currency: "INR",
      name: "Findastro",
      description: "#" + data.order_id,
      order_id: data.payment_reference_id,
      image: "https://s3.ap-south-1.amazonaws.com/assets.findastro.com/findastro_logo_color.png",
      notes: {
        merchant_order_id: data.order_id,
      },
      handler: function (response) {
        razorCapturePayment(response);
      },
      prefill: {
        name: data.name,
        email: data.email,
        contact: data.phone,
        method: "",
      },
      theme: {
        color: "#ee600d",
      },
      modal: {
        ondismiss: function () {
          razorCancelTransaction();
        },
      },
      config: {
        display: {
          hide: [{method: "paylater"}],
        },
      },
    };

    if (data.redirect) {
      options["callback_url"] =
        process.env.REACT_APP_API_BASE_URL +
        "/wallet/recharge/rp-wv/success?t_id=" +
        data.t_id +
        "&attr=" +
        (localStorage.getItem("user_attribution") || "");
      options["redirect"] = true;
    }

    let rzp1 = new window.Razorpay(options);
    console.log(options, rzp1);
    rzp1.on("payment.failed", function (response) {
      let comments = JSON.stringify(response.error);
      razorFailedTransaction(comments);
    });
    rzp1.open();
  };

  const goBack = (e) => {
    if (params.puja === "1") {
      window.location.href = params.ref || "/";
    } else {
      props.closePuja();
    }
  };
  /** Checkout component */
  const Checkout = (props) => {
    //component defenitions
    const [coupon, setCoupon] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [showCoupon, setShowCoupon] = useState(false);
    const showCouponInput = () => {
      setShowCoupon(true);
    };

    const isLoggedIn = () => {
      return (localStorage["phone"] === "" || localStorage["phone"] === null || !localStorage["phone"]) === false;
    };

    const [offlineBookingDone, setOfflineBooking] = useState(false);
    const [loading, setLoading] = useState(false);
    const [partialBookingId, setPartialBookingId] = useState(null);
    const bookPuja = () => {
      //

      if (!isLoggedIn()) {
        if (!userName || userName.length < 3) {
          props.showError("Please fill your name to proceed!");
          return false;
        } else if (!userPhone || userPhone.length < 7) {
          props.showError("Please fill your contact number to proceed!");
          return false;
        } else if (!userEmail || !VALID_EMAIL_ID.test(userEmail)) {
          props.showError("Please fill your E-mail address to proceed!");
          return false;
        }
      }

      setLoading(true);
      axios()
        .post(isLoggedIn() ? "/puja/book" : "/puja/book-offline", {
          id: props.puja.id,
          astrologer_id: props.data.ast_id,
          coupon: coupon,
          is_prasad_required: prasadCost > 0,
          userName: userName,
          userPhone: userPhone,
          userEmail: userEmail,
          locationOption: locationOption,
          bookingId: partialBookingId,
        })
        .then((res) => {
          if (res.data.success) {
            //console.log(res.data.paymentTransaction, window.Razorpay);
            if (res.data.proceed_to_payment === false) {
              setOfflineBooking(res.data);
              const price = selectedAstrologerPuja.discounted_price;
              trackEvent("fa_remedy_book_success", props.puja.puja_id, price, selectedAstrologerPuja);
              if (isLoggedIn()) {
                trackEvent("fa_remedy_book_success_reg", props.puja.puja_id, price, selectedAstrologerPuja);
              } else {
                trackEvent("fa_remedy_book_success_unreg", props.puja.puja_id, price, selectedAstrologerPuja);
              }
            } else {
              triggerRazorpay(res.data.paymentTransaction);
              props.clearSelection(null);
            }
            setLoading(false);
          } else {
            setLoading(false);
            props.showError("Server error: Unable to book the puja now. Try again later!");
          }
        })
        .catch((err) => {
          console.log(err);
          props.showError("Server error: Unable to book the puja now. Try again later!");
          sentryError("BOOK_PUJA_ERROR", err);
        });

      trackEvent(
        isLoggedIn() ? "fa_remedy_book_init" : "fa_remedy_book_offline_init",
        props.puja.puja_id,
        selectedAstrologerPuja.discounted_price,
        selectedAstrologerPuja
      );
    };

    const closePanel = (e) => {
      setPosition("hide");
      setTimeout(() => {
        props.clearSelection(null);
      }, 400);
    };

    const [error, setError] = useState(null);
    const couponText = useRef();
    const applyCoupon = () => {
      setError(null);
      axios()
        .post("/puja/verify-coupon", {
          coupon: couponText.current.value.toUpperCase(),
        })
        .then((res) => {
          if (res.data.success) {
            setCoupon(res.data.code);
            setDiscount(props.data.discounted_price * res.data.discount);
          } else {
            setError("Invalid coupon or coupon expired!");
          }
        })
        .catch((err) => {
          props.showError("Server error: Unable to verify the coupon. Try again later!");
        });
    };

    const continueToPuja = () => {
      if (!loading) {
        bookPuja();
      }
    };

    const clearCoupon = () => {
      setCoupon(null);
      setShowCoupon(false);
      setDiscount(null);
      setError(null);
    };

    const needPrasad = useRef();
    const [currentStep, setCurrentStep] = useState(0);
    const [userName, setUserName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userPhone, setUserPhone] = useState(null);
    const [locationOption, setLocationOption] = useState(null);

    const continueToCheckout = () => {
      if (!isLoggedIn()) {
        console.log(userName, userEmail, userPhone);
        if (!userName || userName.length < 3) {
          props.showError("Please fill your name to proceed!");
          return false;
        } else if (!VALID_NAME.test(userName)) {
          props.showError("Name should not contain any special characters");
          return false;
        } else if (!userPhone || userPhone.length < 7) {
          props.showError("Please fill your contact number to proceed!");
          return false;
        } else if (!VALID_PHONE.test(userPhone)) {
          props.showError("Phone number should start with + and only contain numbers");
          return false;
        } else if (!userEmail || !VALID_EMAIL_ID.test(userEmail)) {
          props.showError("Please fill your E-mail address to proceed!");
          return false;
        }
      }

      if (locationOption) {
        setCurrentStep(currentStep + 1);
        initiatePartialBooking();
      } else {
        props.showError("Please choose a location preference");
      }
    };

    const initiatePartialBooking = () => {
      axios()
        .post(isLoggedIn() ? "/puja/book" : "/puja/book-offline", {
          id: props.puja.id,
          astrologer_id: props.data.ast_id,
          userName: userName,
          userPhone: userPhone,
          userEmail: userEmail,
          locationOption: locationOption,
        })
        .then((res) => {
          if (res.data.success) {
            setPartialBookingId(res.data.bookingId);
          }
        })
        .catch((err) => {
          sentryError("PARTIAL_BOOK_PUJA_ERROR", err);
        });
    };

    const oneStepBack = () => {
      setCurrentStep(currentStep - 1);
    };

    const needPrasadChange = (e) => {
      const cost = selectedAstrologerPuja.prasadCost || DEFAULT_PRASAD_COST;
      console.log(cost);
      setAddPrasad(e.target.checked ? cost : 0);
    };
    const [prasadCost, setAddPrasad] = useState(0);
    const [position, setPosition] = useState("");
    useEffect(() => {
      setTimeout(() => {
        setPosition("show");
      }, 100);
    }, []);

    const astrologerName = () => {
      return (
        (selectedAstrologerPuja.salutation ? selectedAstrologerPuja.salutation.trim() + " " : "") +
        selectedAstrologerPuja.ast_name.trim()
      );
    };

    return (
      <div className="checkout" id="checkout-panel">
        <div className={"body " + position}>
          {/* <div>
            <Astrologer data={selectedAstrologerPuja} puja={data.puja} key={"ast0"} index={0} noAction={true}  />
          </div> */}
          <div className={`priest-puja-info type-${data.puja.type}`}>
            <div>
              <div className="photo">
                <Avatar
                  alt={selectedAstrologerPuja.ast_name}
                  src={
                    selectedAstrologerPuja.image_url
                      ? AstrologerUtils.createThumbImageURI(selectedAstrologerPuja.ast_id)
                      : defaultImage
                  }
                />
              </div>
            </div>
            <div className="title">
              Book {data.puja.name} with <br />
              {astrologerName()}
            </div>
          </div>
          <div className="scroller">
            {offlineBookingDone ? (
              <div
                style={{
                  color: "#4caf50",
                  fontWeight: "700",
                  fontSize: "0.9rem",
                }}>
                <div>{offlineBookingDone.message}</div>
                <div style={{color: "#323232", margin: "10px 0"}}>
                  Booking ID: {offlineBookingDone.offline_booking_id}
                </div>
                <div style={{color: "#323232"}}>
                  You can also contact our customer support team on {PUJA_SUPPORT_NUMBER} for more information or any
                  further assistance
                </div>
              </div>
            ) : (
              <>
                <div className="items" style={{display: currentStep === 0 ? "block" : "none"}}>
                  <div className="loc-item">
                    <span className={selectedAstrologerPuja.online_available ? "" : "op50"}>
                      <label>
                        <input
                          type="radio"
                          name="puja-type"
                          disabled={!selectedAstrologerPuja.online_available}
                          onChange={() => setLocationOption("online")}
                        />
                        I want online {data.puja.type} (via video call)
                      </label>
                    </span>
                  </div>
                  <div className="loc-item">
                    <span className={selectedAstrologerPuja.offline_available ? "" : "op50"}>
                      <label>
                        <input
                          type="radio"
                          name="puja-type"
                          disabled={!selectedAstrologerPuja.offline_available}
                          onChange={() => setLocationOption("offline")}
                        />
                        I want {data.puja.type} at my location
                      </label>
                    </span>
                    {locationOption === "offline" && (
                      <div className="notice">
                        The location of {astrologerName()} is in {selectedAstrologerPuja.location}. Travelling and stay
                        expenses (if applicable) for the priest(s) will be charged extra according to the final
                        requirements of the priest(s).
                      </div>
                    )}
                  </div>
                </div>

                <div className="items" style={{display: currentStep === 1 ? "block" : "none", marginBottom: 24}}>
                  <span>
                    Actual {data.puja.type} Fees <i>:</i>
                  </span>
                  <span> ₹{formatAmount(selectedAstrologerPuja.price.toFixed(2))}</span>
                  <span>
                    Findastro discount <i>:</i>
                  </span>
                  <span>
                    {" "}
                    ₹{formatAmount((selectedAstrologerPuja.price - selectedAstrologerPuja.discounted_price).toFixed(2))}
                  </span>
                  {coupon && discount > 0 ? (
                    <>
                      <span style={{color: "#00c180"}}>
                        <b>Referral discount applied</b>&nbsp;
                        <u style={{color: "#06f"}} onClick={clearCoupon}>
                          CLEAR
                        </u>
                        <i>:</i>
                      </span>
                      <span> ₹{formatAmount((discount || 0).toFixed(2))}</span>
                    </>
                  ) : (
                    <>
                      <div>
                        {showCoupon ? (
                          <>
                            <input type="text" className="coupon" placeholder="Coupon Code" ref={couponText} />
                            &nbsp;
                            <u style={{color: "#06f"}} onClick={applyCoupon}>
                              APPLY
                            </u>
                            &nbsp;&nbsp;
                            <u style={{color: "#06f"}} onClick={clearCoupon}>
                              CLEAR
                            </u>
                          </>
                        ) : (
                          <>
                            <span>
                              <u style={{color: "#06f"}} onClick={showCouponInput}>
                                Referral discount
                              </u>
                              <i>:</i>
                            </span>
                            <span> ₹{formatAmount((discount || 0).toFixed(2))}</span>
                          </>
                        )}
                      </div>
                    </>
                  )}
                  {error && <div style={{color: "red"}}>{error}</div>}
                  {selectedAstrologerPuja.prasad === "Yes" && locationOption === "online" && (
                    <div style={{marginTop: 16}}>
                      <span>
                        <i onClick={(e) => needPrasad.current.click()}>
                          <input
                            type="checkbox"
                            ref={needPrasad}
                            onClick={(e) => e.stopPropagation()}
                            onChange={needPrasadChange}
                          />{" "}
                          I need the prasad via courier
                        </i>
                        <i>:</i>
                      </span>
                      <span>
                        ₹
                        {formatAmount(
                          (prasadCost > 0 ? selectedAstrologerPuja.prasadCost || DEFAULT_PRASAD_COST : 0).toFixed(2)
                        )}
                      </span>
                    </div>
                  )}
                  <div
                    style={{
                      marginTop: "16px",
                      borderTop: "1px solid #999",
                      paddingTop: "16px",
                    }}>
                    <span>
                      Final fees <i>:</i>
                    </span>
                    <span>
                      {" "}
                      ₹{formatAmount((selectedAstrologerPuja.discounted_price + prasadCost - discount).toFixed(2))}
                    </span>
                  </div>
                  <div style={{marginTop: 0}}>
                    <span>
                      GST <i>:</i>
                    </span>
                    <span>
                      {" "}
                      ₹
                      {formatAmount(
                        ((selectedAstrologerPuja.discounted_price + prasadCost - discount) * 0.18).toFixed(2)
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "0px",
                      color: "#ee623c",
                      fontWeight: "700",
                    }}>
                    <span>
                      Amount payable <i>:</i>
                    </span>
                    <span>
                      {" "}
                      ₹
                      {formatAmount(
                        ((selectedAstrologerPuja.discounted_price + prasadCost - discount) * 1.18).toFixed(2)
                      )}
                    </span>
                  </div>
                </div>
              </>
            )}
            {currentStep === 0 && (
              <div style={{padding: 8, marginTop: 24, marginBottom: 24, backgroundColor: "#f1f1f1", borderRadius: 8}}>
                <div style={{marginTop: 0, opacity: 0.6}}>
                  <b>Date of {props.puja.type}:</b> The date of {props.puja.type} will be finalized after discussing
                  with you and the priest. Our customer support executive will contact you within 1 hour after the
                  successful booking.
                </div>
                {selectedAstrologerPuja.prasad === "Yes" && locationOption === "online" && (
                  <div style={{marginTop: 8, opacity: 0.6}}>
                    <b>Address to send prasad:</b> If you opt for the prasad, the address to send the prasad will be
                    collected when our customer support executive contacts you to finalize the {props.puja.type} date.
                  </div>
                )}
              </div>
            )}

            {!isLoggedIn() && !offlineBookingDone && currentStep === 0 && (
              <div className="non-loggedin-user">
                <div>
                  <b>Since you are not registered with us, please fill the following details for us to contact you.</b>
                </div>
                <div>
                  <label>
                    Name<i>*</i>
                  </label>
                  :{" "}
                  <input
                    type="name"
                    value={userName}
                    onInput={(e) => setUserName(e.target.value)}
                    placeholder="Your name here"
                  />
                </div>
                <div>
                  <label>
                    Phone number<i>*</i>
                  </label>
                  :{" "}
                  <input
                    type="tel"
                    value={userPhone}
                    onInput={(e) => setUserPhone(e.target.value)}
                    placeholder="Ph. No. with country code"
                  />
                </div>
                <div>
                  <label>
                    Email address<i>*</i>
                  </label>
                  :{" "}
                  <input
                    type="name"
                    value={userEmail}
                    onInput={(e) => setUserEmail(e.target.value)}
                    placeholder="Your e-mail here"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "absolute",
            left: "0px",
            bottom: "0px",
            boxShadow: "2px -5px 16px 3px #00000054",
          }}>
          {currentStep === 0 ? (
            <div
              style={{
                backgroundColor: offlineBookingDone ? "#00c180" : "#efefef",
                color: offlineBookingDone ? "#fff" : "#555",
                height: "48px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "700",
                width: offlineBookingDone ? "100%" : "30%",
                marginRight: 0,
                borderRadius: 0,
              }}
              onClick={closePanel}>
              {offlineBookingDone ? "Okay" : "Close"}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: offlineBookingDone ? "#00c180" : "#efefef",
                color: offlineBookingDone ? "#fff" : "#555",
                height: "48px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "700",
                width: offlineBookingDone ? "100%" : "30%",
                marginRight: 0,
                borderRadius: 0,
              }}
              onClick={offlineBookingDone ? closePanel : oneStepBack}>
              {offlineBookingDone ? "Okay" : "Back"}
            </div>
          )}
          {!offlineBookingDone && currentStep === 0 ? (
            <div
              style={{
                backgroundColor: "#00c180",
                color: "#fff",
                height: "48px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "700",
                width: "70%",
                borderRadius: 0,
              }}
              onClick={continueToCheckout}>
              {loading ? "Please wait.." : isLoggedIn() ? "Continue to checkout" : "Next"}
            </div>
          ) : (
            !offlineBookingDone && (
              <div
                style={{
                  backgroundColor: "#00c180",
                  color: "#fff",
                  height: "48px",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "700",
                  width: "70%",
                  borderRadius: 0,
                }}
                onClick={continueToPuja}>
                {loading ? "Please wait.." : isLoggedIn() ? "Proceed to payment" : "Confirm & request callback"}
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const [selectedAstrologerPuja, setSelectedAstrologerPuja] = useState(null);

  const selectAstrologer = (index) => {
    setSelectedAstrologerPuja(data.astrologer_details[index]);
    trackEvent(
      "fa_remedy_book_intent",
      data.puja.puja_id,
      data.astrologer_details[index].discounted_price,
      data.astrologer_details[index]
    );
  };

  const adjustTitleBar = (e) => {
    const container = e.target;
    if (container.scrollTop > 150) titleBar.current.classList.add("active");
    else titleBar.current.classList.remove("active");
  };

  const titleBar = useRef();

  const backPressed = () => {
    window.history.back();
  };

  return (
    <div className={`puja-dialog dialog type-${data.puja.type}`}>
      <div className="title" ref={titleBar}>
        <ArrowIcon onClick={backPressed} style={{marginLeft: -8}} />
        <span style={{marginLeft: 16}}>Details of {data.puja.name}</span>
      </div>
      <div className="content" onScroll={adjustTitleBar}>
        <img style={{marginBottom: 24}} width={"100%"} src={data.puja.image} alt={data.puja.identifier} />
        <PujaInfoBanner screen={"remedy"} refId={data.puja.puja_id} />
        <div
          dangerouslySetInnerHTML={{__html: (data.puja.long_description || "").replace(/\\n/g, "")}}
          style={{
            padding: "8px 16px",
            lineHeight: "1.4rem",
          }}
        />
        {data.astrologer_details.length > 1 ? (
          <div className="priest-list">
            <div>
              <b>
                Following are the priests who can do the <big>{data.puja.name}</big> for you
              </b>
            </div>
            <Separator className="separator" />
          </div>
        ) : (
          <div className="priest-list">
            <div>
              <b>
                The following priest can do the <big>{data.puja.name}</big> for you
              </b>
            </div>
            <Separator className="separator" />
          </div>
        )}
        {data.astrologer_details.map((priest, index) => (
          <Astrologer
            data={priest}
            puja={data.puja}
            key={"ast" + index}
            index={index}
            showError={props.showError}
            selectAstrologer={selectAstrologer}
            showAstrologerProfile={props.showAstrologerProfile}
          />
        ))}
        <div style={{textAlign: "center", color: "#555", fontSize: "0.75rem", marginTop: 16}}>
          ID:{data.puja.puja_id}
        </div>
      </div>
      {selectedAstrologerPuja && (
        <Checkout
          data={selectedAstrologerPuja}
          puja={data.puja}
          clearSelection={setSelectedAstrologerPuja}
          showError={props.showError}
        />
      )}
    </div>
  );
};

export default React.memo(SinglePujaDetails, function (prev, next) {
  return prev.data.puja.puja_id === next.data.puja.puja_id;
});

const trackEvent = (type, label, value, data) => {
  moengage(type);
  const payload = {
    p_screen: "remedy",
    p_label: label,
    p_content: data.profile_url,
    p_value: value,
    value: value,
    currency: "INR",
    p_ast_exp: String(data.experience).split(" ")[0],
    p_ast_rating: "NA",
    p_ast_sort: "NA",
    p_ast_rank: "NA",
    p_ast_fees: "NA",
    p_usr_gender: BrowserUtils.CurrentUser().gender,
    p_usr_age: BrowserUtils.CurrentUser().age.toString(),
    p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
  };
  window.FAEventLog(type, payload);
};
