import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PositionedSnackbar(props) {
  const [openSnack, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    props.closeError();
  };

  useEffect(() => {
    if (props.open) {
      setOpen(props.open);
    }
  }, [props.open]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: "center",
      }}
      open={openSnack}
      onClose={handleClose}
      autoHideDuration={3000}>
      <Alert onClose={handleClose} className={"warningSnackBar " + (openSnack ? "open" : "closed")} severity="warning">
        {props.message}
      </Alert>
    </Snackbar>
  );
}
