import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import DealPurchase from "./DealPurchase";
import T2AEventTrack from "../../../../common/T2AEventTrack";
import BrowserUtils from "../../../user/astrologer/components/UAScripts";

const useStyles = makeStyles(() => ({
  image: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 0.7,
    },
    '&:active': {
      opacity: 0.5,
    },
  },
}))
const images = {
  call: 'https://s3.ap-south-1.amazonaws.com/assets.findastro.com/images/offers/deal-first-call-<AMOUNT>-rupee-banner.min.png',
  chat: 'https://s3.ap-south-1.amazonaws.com/assets.findastro.com/images/offers/deal-first-chat-<AMOUNT>-rupee-banner.min.png'
}

const trackEventData = (data) => {
  const payload = {
    event: {
      name: data?.name,
      attributes: {
        'Screen Name': data?.screenName,
        'Source Screen Name': 'Chat or Talk',
        'Product': 'COT',
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name
      }
    },
    extra: {
      user: {
        //'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name
      }
    }
  }
  if (data?.buttonName) {
    payload.event.attributes['Button Name'] = data.buttonName;
    payload.event.attributes['Button Location'] = 'Deal Purchase Banner';
    payload.event.attributes['Target Screen Name'] = data.targetScreenName;
  }
  T2AEventTrack(payload.event, payload.extra)
}

// https://s3.ap-south-1.amazonaws.com/assets.findastro.com/images/offers/deal-first-call-one-rupee-banner.min.png

const DealPurchaseBanner = ({
  dealData,
  onDealPurchaseUpdate
}) => {
  const classes = useStyles()
  const [bannerImageURL, setBannerImageURL] = useState('')

  useEffect(() => {
    if (dealData.type === 'call' || dealData.type === 'chat') {
      const deal = dealData.deals.find(deal => deal.deal_type === 'FREE_CONSULT_MINUTES')
      if (deal?.amount > 0 && images[dealData.type]) {
        let image = images[dealData.type]
        image = image.replace(/<AMOUNT>/g, deal.amount)
        setBannerImageURL(image)
        trackEventData({ name: 'Screen View Action', screenName: 'Deal Purchase Banner' })
      }
    }
    //console.log('dealData', dealData)
  }, [dealData])

  const handleClick = () => {
    const deal = dealData.deals.find(deal => deal.deal_type === 'FREE_CONSULT_MINUTES')
    // console.log('deal', deal)

    const refData = DealPurchase.getRefData({
      astrologer: null,
      recharge: deal,
      type: dealData.type
    })

    trackEventData({
      name: 'Button Action',
      screenName: 'Chat or Talk',
      buttonName: (deal?.deal_type === 'FREE_CONSULT_MINUTES' && deal?.amount) ? 'Recharge for Rs ' + deal.amount : 'Recharge',
      targetScreenName: BrowserUtils.isLoggedIn() ? 'Deal Purchase Popup' : 'Chat or Talk Mobile verification',
    })

    onDealPurchaseUpdate({ ...dealData, open: true, refData, deal })
  }

  return (
    bannerImageURL ?
      <Grid container>
        <Grid item xs={12}>
          <img
            src={bannerImageURL}
            alt={`Deal banner ${dealData.type}`}
            className={classes.image}
            onClick={handleClick}
          />
        </Grid>
      </Grid>
      :
      <></>
  );
};

export default DealPurchaseBanner;