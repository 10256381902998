import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Drawer, IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import blurredImage from "../../../../src/common/blurred-payment-bg.png"
import WalletModal from './WalletModal.component'
import { Close } from '@material-ui/icons'
import DealPurchase from '../../feature/deal-purchase/components/DealPurchase'
import { WalletUtils, setWalletEvent } from '../../../route/WalletComponent'

const useStyles = makeStyles({
  dialog: {
    overflow: 'visible',
    '& .MuiDrawer-paper': {
      maxWidth: 460,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '0.5rem 0.5rem 0 0',
      paddingTop: '.75rem',
    },
    '&.payment-popup--open .payment-popup-close-btn': {
      transform: 'translateY(0)'
    },
    '& .MuiPaper-root': {
      overflow: 'visible'
    }
  },
  backdropLoader: {
    position: 'absolute',
    zIndex: -1
  },
  walletModal: {
    '& .MuiBackdrop-root': {
      display: 'none',
    },
    '& .MuiDialog-paper': {
      boxShadow: 'none',
    },
    '& svg.error-icon, & .error-para > br ~ br': {
      display: 'none',
    },
    '& .error-text': {
      fontSize: '15px',
    },
  },
  blurredBG: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    left: 0,
    top: 0,
    maxWidth: '460px',
    margin: 'auto',
    zIndex: 100,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'left top',
    }
  },
  errorButton: {
    // backgroundColor: "#f56307",
    backgroundColor: "var(--clr-green)",
    color: "white",
    fontWeight: "bold",
    width: "160px",
    boxShadow: "none",
    borderRadius: "40px",
    padding: "10px",
    width: '100%',
    "&:hover, &:focus": {
      backgroundColor: "var(--clr-green) !important",
    },
  },
  closeBtn: {
    marginTop: '-0.75rem',
    position: 'absolute',
    // top: props => props.fullScreen ? '90vh' : '75vh',
    top: '-2.5rem',
    background: '#fff',
    width: '2.5rem',
    height: '2.5rem',
    transition: 'all ease .3s',
    transform: 'translateY(0.75rem)',
    '&:hover': {
      background: '#fff',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.3rem',
    }
  }
})

const PaymentFailedDialog = (props) => {
  const {
    open = false,
    status = 'failed',
    handleClose = null,
    handleCreateOrderAfterPaymentFailed = null,
    fullScreen = false,
  } = props
  const classes = useStyles(props)
  const [modalOpen, setModalOpen] = useState(false)
  const [rechargeValue, setRechargeValue] = useState(0)

  useEffect(() => {
    setModalOpen(true);
    setRechargeValue(500);
  }, [])

  const onCloseDrawer = () => {
    if (DealPurchase.isLastPurchaseIsDealPurchase() && open) {
      WalletUtils.setType(WalletUtils.getDefaultType())
      console.log('walletType:onCloseDrawer', 'default')
      window.location.hash = '#back-button--wallet-recharge/no-failed-popup'
      setTimeout(() => {
        handleClose()
      }, 1000)
    } else {
      handleClose()
    }

  }

  const onActionButton = () => {
    handleCreateOrderAfterPaymentFailed()
  }

  const ActionButton = () => {
    return (
      <>
        <Button variant="contained" className={classes.errorButton} onClick={onActionButton}>
          Choose payment options
        </Button>
      </>
    )
  }

  return (
    <>
      {open ?
        <div className={classes.blurredBG}>
          <img src={blurredImage} alt="blurredImage" />
        </div>
        :
        null
      }
      <Drawer
        className={clsx(
          "payment-popup",
          classes.dialog,
          fullScreen ? 'full-screen' : '',
          open ? 'payment-popup--open' : ''
        )}
        anchor="bottom"
        open={open}
        onClose={onCloseDrawer}
      >
        <IconButton
          className={clsx(classes.closeBtn, 'payment-popup-close-btn')}
          onClick={onCloseDrawer}
        >
          <Close />
        </IconButton>
        <WalletModal
          modalOpen={modalOpen}
          status={status}
          orderId={0}
          // resetHistory={clearHistory}
          rechargeValue={rechargeValue}
          className={clsx(
            classes.walletModal
          )}
          targetURI="/"
          contentOnly={true}
          actionButton={<ActionButton />}
        />
      </Drawer>
    </>
  )
}

export default PaymentFailedDialog