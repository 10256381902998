import {io} from "socket.io-client";
import {ConnectionStatus} from "./types/chat.types";
import {EventEmitter} from "events";

class SimpleChat extends EventEmitter {
  constructor(config) {
    super();
    this.socket = null;
    this.status = ConnectionStatus.DISCONNECTED;
    /**
     * Start client and connection
     */
    this.start = () => {
      const {host, port, userId, accessToken, maxReconnectAttempts} = this.config;
      const url = `${host}:${port}`;
      this.socket = io(url, {
        auth: {
          userId,
          token: accessToken,
        },
        reconnectionAttempts: maxReconnectAttempts,
        transports: ["websocket"],
      });
      this.socket.on("connect", this.onConnect);
      this.socket.on("disconnect", this.onDisconnect);
      this.socket.on("message", this.onMessage);
      this.socket.io.on("connect_error", this.onConnectError);
      this.socket.io.on("reconnect", this.onReconnect);
      this.socket.io.on("reconnect_attempt", this.onReconnectAttempt);
      this.socket.io.on("reconnect_error", this.onReconnectError);
      this.socket.io.on("reconnect_failed", this.onReconnectFailed);
      this.socket.io.on("error", this.onError);
      this.socket.on("typing", this.onTyping);
    };
    /**
     * Manually disconnect socket
     */
    this.disconnect = () => {
      var _a;
      (_a = this.socket) === null || _a === void 0 ? void 0 : _a.disconnect();
    };
    /**
     * Triggered on client connected to server
     */
    this.onConnect = () => {
      this.status = ConnectionStatus.CONNECTED;
      this.emit("connected");
    };
    /**
     * Triggered on client disconnect from server
     */
    this.onDisconnect = () => {
      this.status = ConnectionStatus.DISCONNECTED;
      this.emit("disconnected");
    };
    /**
     * Fired when an namespace middleware error occurs.
     *
     * @param error
     */
    this.onConnectError = (error) => {
      this.status = ConnectionStatus.DISCONNECTED;
      this.emit("error", error);
    };
    /**
     * Will be triggered when error received
     *
     * @param error error object from socket
     */
    this.onError = (error) => {
      this.status = ConnectionStatus.DISCONNECTED;
      this.emit("error", error);
    };
    /**
     * Will be triggered after reconnect
     *
     * @param attempt reconnection attampt number
     */
    this.onReconnect = (attempt) => {
      this.emit("reconnect", attempt);
    };
    /**
     * Will be triggered before reconnect
     *
     * @param attempt reconnection attampt number
     */
    this.onReconnectAttempt = (attempt) => {
      this.emit("reconnect_attempt", attempt);
    };
    /**
     * Fired upon a reconnection attempt error.
     *
     * @param error Error of reconnection attempt
     */
    this.onReconnectError = (error) => {
      this.emit("reconnect_error", error);
    };
    /**
     * Will be fired when reconnection attempts exceeded (by default no limit for reconnections)
     */
    this.onReconnectFailed = () => {
      this.emit("reconnect_failed");
    };
    /**
     * Will be triggered when message received from server
     * When app received message it needs to be acknowledged
     * otherwise it will be moved to offline message queue
     *
     * @param message message object received from server
     */
    this.onMessage = (message, ack) => {
      this.emit("message", message, ack);
    };

    this.onTyping = (message, ack) => {
      this.emit("typing", message, ack);
    };
    /**
     * Send message to chat users. Possible messages are text message and typing
     * User can send typing event without message body or with message body
     *
     * @example
     * id?: string;
     * to: string;
     * timestamp: number;
     * body?: MessageBody;
     * typing?: boolean;
     *
     * @param message message object
     */
    this.sendMessage = async (message) => {
      return new Promise((resolve, reject) => {
        var _a;
        (_a = this.socket) === null || _a === void 0
          ? void 0
          : _a.emit("message", message, (messageId, error) => {
              if (error) {
                return reject(error);
              }
              if (messageId) {
                resolve(messageId);
              }
            });
      });
    };

    this.typingMessage = async (message) => {
      return new Promise((resolve, reject) => {
        var _a;
        (_a = this.socket) === null || _a === void 0
          ? void 0
          : _a.emit("typing", message, (messageId, error) => {
              if (error) {
                return reject(error);
              }
              if (messageId) {
                resolve(messageId);
              }
            });
      });
    };
    /**
     * Load message archive for chat. If no @param after provided then latest messages will be returned,
     * if param provided then messages after specific id will be returned
     *
     * @param chatId chat id for which to load archive
     * @param limit count of messages to return
     * @param after message id after which to load messages @optional
     */
    this.loadArchive = (chatId, limit, after = null) => {
      return new Promise((resolve, reject) => {
        var _a;
        (_a = this.socket) === null || _a === void 0
          ? void 0
          : _a.emit("load_archive", chatId, limit, after, (messages, error) => {
              if (error) {
                return reject(error);
              }
              if (messages) {
                resolve(messages);
              }
            });
      });
    };
    /**
     * Join chat permanently or temporary. Temporary means that on disconnect chat join will be removed.
     *
     * @param chatId chat id to which join user
     * @param temp flag which indicates wether join is temporary
     */
    this.joinChat = async (chatId, temp = false) => {
      return new Promise((resolve, reject) => {
        var _a;
        (_a = this.socket) === null || _a === void 0
          ? void 0
          : _a.emit("join_chat", chatId, temp, (success, error) => {
              if (error) {
                return reject(error);
              }
              if (success) {
                resolve(success);
              }
            });
      });
    };
    /**
     * Leave chat, when user left chat he will not receive any notifications and messages
     *
     * @param chatId chat id which to leave
     */
    this.leaveChat = async (chatId) => {
      return new Promise((resolve, reject) => {
        var _a;
        (_a = this.socket) === null || _a === void 0
          ? void 0
          : _a.emit("leave_chat", chatId, (success, error) => {
              if (error) {
                return reject(error);
              }
              if (success) {
                resolve(success);
              }
            });
      });
    };
    /**
     * Create chat which will be used in communication. Possibe types of chat are Multi User chat, and Single User chat
     * For single user chat it is needed to provide list of two users who will be participant of the chat
     *
     * @param type type of the chat. @MUC or @SUC
     * @param users list of users for @SUC, should be only two users
     */
    this.createChat = async (type, users) => {
      return new Promise((resolve, reject) => {
        var _a;
        if (users.length > 2) {
          reject("In user list should be only two users, and type of chat should be SUC.");
        }
        (_a = this.socket) === null || _a === void 0
          ? void 0
          : _a.emit("create_chat", type, users, (chatId, error) => {
              if (error) {
                return reject(error);
              }
              if (chatId) {
                resolve(chatId);
              }
            });
      });
    };
    this.config = config;
  }
}
export default SimpleChat;
