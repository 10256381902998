import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import moengage from "../../../../common/moengage";
import FA_localStorage from "../../../../common/util/FA_localStorage";

import logo from "../../../../../src/common/findastro_logo.png";

import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import WalletIcon from "@material-ui/icons/AccountBalanceWallet";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import Edit from "@material-ui/icons/Edit";
import ConsultIcon from "@material-ui/icons/Forum";
import FamilyIcon from "@material-ui/icons/SupervisedUserCircle";
import LanguageIcon from "@material-ui/icons/Language";
import Transaction from "@material-ui/icons/BarChart";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as OfferStamp } from "./offerper.svg";
import { SUPPORT_NUMBER } from "../../../../app.config";
import { DeleteForeverOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
  drawerWrap: {
    color: "#ffffff",
    background: "#ee600d !important",
  },
  size: {
    fontSize: "15px",
  },
  draweraccountDiv: {
    position: "relative",
    padding: "20px 15px",
  },
  bottomline: {
    borderBottom: "1px solid#ff903869 !important",
  },
  icons: {
    fontSize: "15px",
    marginBottom: "-4px",
    marginRight: "7px",
  },
  profileImg: {
    height: "50px",
    width: "50px",
    marginTop: "8px",
  },
  editIcon: {
    position: "absolute",
    color: "white",
    padding: "5px",
    top: "0",
    right: "0",
    margin: "20px 10px 0px 0px",
  },
  dropdownIcon: {
    color: "#ffe6d1",
    maxWidth: 42,
    minWidth: 42,
  },
  userWrap: {
    fontSize: "0.7em",
    lineHeight: "2em",
  },
  userName: {
    fontSize: "14px",
  },
  subCategory: {
    backgroundColor: "#ffffff1a",
    padding: "0px",
  },
  logout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },
  customerCare: {
    marginTop: "20%",
    fontWeight: "400",
    paddingLeft: "16px",
    marginBottom: "5px",
    fontSize: "0.9rem",
    color: "#efefef",
  },
  customerCareWhite: {
    color: "white !important",
  },
});

const SideDrawerComponent = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleCategoryClick = () => {
    setOpen(!open);
  };

  const handleListClick = (val) => {
    if (val === "Login") {
      window.location.href = "/login";
    } else if (val === "Profile") {
      window.location.href = "/my-account";
    } else if (val === "Wallet") {
      window.location.href = "/my-wallet";
    } else if (val === "Consultations & Bookings") {
      window.location.href = "/user-consultation-history";
    } else if (val === "Language preference") {
      window.location.href = "/my-favorite-languages";
    } else if (val === "Transactions") {
      window.location.href = "/invoices";
    } else if (val === "My Family") {
      window.location.href = "/familyuserprofiles";
    } else if (val === "My Favorite Astrologers") {
      window.location.href = "/my-favorite-astrologer";
    } else if (val === "Daily Predictions") {
      moengage("DAILY_PRED_CLICKED");
      window.location.href = "/daily-updates";
    } else if (val === "Weekly Predictions") {
      moengage("WEEKLY_PRED_CLICKED");
      window.location.href = "/weekly-updates";
    } else if (val === "About Us") {
      window.location.href = "/about-us";
    } else if (val === "Share your story") {
      window.location.href = "/rate-us";
    } else if (val === "Terms & Conditions") {
      window.location.href = "/terms-and-conditions";
    } else if (val === "Privacy Policy") {
      window.location.href = "/privacy-policy";
    } else if (val === "Redeem coupon") {
      props.onpageEventHandler("couponRedeem");
    } else if (val === "Delete My Account") {
      window.location.href = "/delete-my-account";
    } else {
      window.location.href = "/";
    }
  };

  const onEdit = () => {
    moengage("EDIT_PROFILE_CLICKED");
    window.location.href = "/userprofile?pathname=" + props.location.pathname;
  };

  return (
    <Drawer
      classes={{ paper: classes.drawerWrap }}
      className={"navDrawer"}
      open={props.drawerOpen}
      onClose={() => {
        props.onDrawerClose();
      }}>
      <div className="sideDrawerList" role="presentation">
        <div id="sideDrawerLogoDiv">
          <img id="sideDrawerLogo" src={logo} alt="Logo" />
        </div>
        {FA_localStorage("phone") && FA_localStorage("email") ? (
          <div className={classes.draweraccountDiv}>
            <Grid container>
              <Grid item xs={3}>
                <Avatar
                  alt="profile image"
                  classes={{ root: classes.profileImg }}
                  src={FA_localStorage("image") ? FA_localStorage("image") : ""}>
                  {FA_localStorage("name") !== "null" ? FA_localStorage("name")[0] : "N"}
                </Avatar>
              </Grid>
              <Grid item xs={9} style={{ paddingLeft: "8px" }}>
                <div className={classes.userWrap}>
                  <div className={classes.userName}>
                    {FA_localStorage("name") !== "null" && FA_localStorage("name").length > 13
                      ? FA_localStorage("name").substring(0, 13) + "..."
                      : FA_localStorage("name") !== "null"
                        ? FA_localStorage("name")
                        : "No name"}
                  </div>
                  <div>
                    <MailIcon className={classes.icons} />
                    {FA_localStorage("email").length > 20
                      ? FA_localStorage("email").substring(0, 20) + "..."
                      : FA_localStorage("email") !== "null"
                        ? FA_localStorage("email")
                        : "No email"}
                  </div>
                  <div>
                    <PhoneIcon className={classes.icons} />
                    {FA_localStorage("phone")}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Edit className={classes.editIcon} onClick={onEdit} />
          </div>
        ) : (
          <div id="helloGuestDrawerText">Hello Guest</div>
        )}
        <List>
          {!FA_localStorage("btkn") || !FA_localStorage("utkn") ? (
            <ListItem classes={{ root: classes.bottomline }} onClick={() => handleListClick("Login")}>
              <ListItemText classes={{ primary: classes.size }} primary="Login" />
            </ListItem>
          ) : (
            <></>
          )}
          <ListItem classes={{ root: classes.bottomline }} onClick={() => handleListClick()}>
            <ListItemText classes={{ primary: classes.size }} primary="Home" />
          </ListItem>
          <ListItem classes={{ root: classes.bottomline }} onClick={handleCategoryClick}>
            <ListItemText classes={{ primary: classes.size }} primary="My Account" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto">
            <List className={classes.subCategory}>
              {["Profile", "Wallet", "Consultations & Bookings", "Transactions", "Language preference",
                ...(FA_localStorage("btkn") && FA_localStorage("utkn") ? ["Delete My Account"] : [])
              ].map(
                (text, index) => (
                  <ListItem classes={{ root: classes.bottomline }} key={text} onClick={() => handleListClick(text)}>
                    <ListItemIcon className={`${classes.dropdownIcon} listItemIcon`} style={{ minWindth: 36 }}>
                      {text === "Profile" ? (
                        <FamilyIcon />
                      ) : text === "Wallet" ? (
                        <WalletIcon />
                      ) : text === "Consultations & Bookings" ? (
                        <ConsultIcon />
                      ) : text === "Transactions" ? (
                        <Transaction />
                      ) : text === "Language preference" ? (
                        <LanguageIcon />
                      ) : text === "Delete My Account" ? (
                        <DeleteForeverOutlined />
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.size }} primary={text} />
                  </ListItem>
                )
              )}
            </List>
          </Collapse>
          {["Redeem coupon", "About Us", "Share your story", "Terms & Conditions", "Privacy Policy"].map(
            (text, index) => (
              <ListItem classes={{ root: classes.bottomline }} key={text} onClick={() => handleListClick(text)}>
                <ListItemText classes={{ primary: classes.size }} primary={text} />
                {text === "Redeem coupon" ? (
                  <OfferStamp style={{ fill: "#ffffff", transform: "translateX(-72px)", width: 24 }} />
                ) : (
                  <></>
                )}
              </ListItem>
            )
          )}
        </List>
        <div className={classes.customerCare}>
          <div>
            Support:&nbsp;
            <a href={"tel:" + String(SUPPORT_NUMBER).replace(/\s/g, "")} className={classes.customerCareWhite}>
              {SUPPORT_NUMBER}
            </a>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default withRouter(SideDrawerComponent);
