import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

class AppLogout extends Component {
  trackPageview = () => {
    /** event tracking */
    const type = "fa_logout";
    const payload = {
      p_screen: "logout",
      p_label: "NA",
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  componentDidMount = () => {
    this.trackPageview();
    BrowserUtils.logout()
  };

  render() {
    return <div></div>;
  }
}

export default withRouter(AppLogout);
