import React, { Component } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import { withRouter } from "react-router-dom";

import domtoimage from "dom-to-image";

import "./UserActions.css";
import HelmetComponent from "../../../common/HelmetComponent.component";
import userActionData from "../mock/UserActionData.mock";
import Snackbar from "../../../common/SnackBarMessage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SubmitArrowIcon from "@material-ui/icons/ChevronRight";
import InfoIcon from "@material-ui/icons/Error";
import TextField from "@material-ui/core/TextField";
import moengage from "../../../common/moengage";

class Otp extends Component {
  // Input refs will create issues when using setstate, so declared in constructor
  constructor(props) {
    super(props);
    this.inputRefs = [];
  }

  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    length:
      this.props.location.state.data && this.props.location.state.data.otp_length
        ? this.props.location.state.data.otp_length
        : 1,
    phone: this.props.location.state.data && this.props.location.state.data.to ? this.props.location.state.data.to : "",
    otp: "",
    seconds: "",
    tries: this.props.location.state.data && this.props.location.state.data.to ? 2 : 0,
    isResendLoading: false,
    isLoading: false,
    checkbox1: true,
    checkbox2: true,
    screenshot: "",
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onCheckBox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  goBack = () => {
    if (document.referrer === "") {
      window.location.href = "/";
    } else {
      window.history.back();
    }
  };

  onlyNumbers = (e) => {
    var validChars = userActionData.validChars;
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  updateOTP = (e) => {
    this.setState({
      otp: e.target.value,
    });
  };

  showTimer = (timer) => {
    let count = 0;
    let tempState = this;
    tempState.setState({
      seconds: timer,
    });
    let timerInterval = window.setInterval(function () {
      count++;
      tempState.setState({
        seconds: timer - count,
      });
      if (count > timer - 1) {
        clearInterval(timerInterval);
      }
    }, 1000);
  };

  showTimerOnResend = () => {
    if (this.state.phone.length !== 10) {
      this.setState({
        snackBar: {
          open: true,
          message: "Error occured, Please try again",
        },
        tries: 0,
      });
    } else {
      this.setState({
        isResendLoading: true,
      });
      axios()
        .post("/user/otp/" + this.props.location.state.data.id, {
          phone: this.state.phone,
        })
        .then((res) => {
          if (res.data.data.success) {
            if (this.state.tries > 1) {
              this.showTimer(30);
            }
            this.setState({
              tries: this.state.tries - 1,
            });
          } else {
            this.showError(res.data.data.message);
          }

          this.setState({
            isResendLoading: false,
          });
        })
        .catch((err) => {
          sentryError("OTP_RESEND", err);
          this.showError("Error occured, Please try again");
          this.setState({
            isResendLoading: false,
          });
        });
    }
  };

  trackActions = (type, label, value) => {
    moengage(type);
    const payload = {
      p_screen: this.props.location.state.doRegister ? "signup" : "login",
      p_label: label,
      p_content: "NA",
      p_value: value || 0,
      value: value || 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(this.state.phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  submitOTP = () => {
    //clear pending login session
    sessionStorage.removeItem("sessionLoginData");
    let otp = this.state.otp;
    this.setState({
      isLoading: true,
    });
    const url = this.props.location.state.doRegister ? "/user/otp/register/" : "/user/otp/login/";
    axios()
      .post(url + this.props.location.state.data.id, {
        phone: this.state.phone,
        otp: otp,
        is_trai: this.state.checkbox1,
        is_whatsapp: this.state.checkbox2,
        data_dom: this.state.screenshot,
      })
      .then((res) => {
        if (res.data.success) {
          if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
            window.AndroidBridge.call("pushUserPhone", res.data.userProfile.phone);
          }
          if (this.props.location.state.doRegister) {
            this.trackActions("fa_wallet_credit", "NA", 75); //free 75 wallet credit
          }

          localStorage.setItem("utkn", res.data.token);
          localStorage.setItem("btkn", res.data.bearer);
          localStorage.setItem("name", res.data.userProfile.name);
          localStorage.setItem("email", res.data.userProfile.email);
          localStorage.setItem("phone", res.data.userProfile.phone);
          localStorage.setItem("image", res.data.userProfile.profileUrl);
          localStorage.setItem("walletBalance", res.data.userProfile.balance ? res.data.userProfile.balance : "0");

          if (res.data.isLoginElapse) {
            if (window.Moengage) {
              window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            }
            this.props.history.replace("/");
          } else if (this.props.location.state.doRegister) {
            this.props.history.replace("/language-select?register=1");
          } else if (res.data.isRegister) {
            this.props.history.replace("/language-select?register=1");
          } else if (res.data.userProfile.name === null || res.data.userProfile.email.includes('@email.com')) {
            if (window.Moengage) {
              window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            }
            this.props.history.replace("/userprofile?pathname=/&redirect=/");
          } else if (this.props.location.state.historyData) {
            if (window.Moengage) {
              window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            }
            window.location.href = decodeURIComponent(this.props.location.state.historyData);
          } else {
            if (window.Moengage) {
              window.Moengage.add_unique_user_id(res.data.userProfile.phone);
            }
            this.props.history.replace("/");
          }
          //remove local crj if logged in
          localStorage.removeItem("userCRJ");
          localStorage.removeItem("sunsignData");
          localStorage.removeItem("moonsignData");
          localStorage.removeItem("dailyPrediciton");
          //remove local crj if logged in
          this.trackActions("fa_otp_success", "NA");
          this.trackActions("fa_registration_success", "NA");
        } else {
          this.showError(res.data.message);
          this.setState({
            isLoading: false,
          });
          if (!this.props.location.state.doRegister && res.data.isRegister) {
            let that = this;
            window.setTimeout(function () {
              window.location.href = "/register?phone=" + that.state.phone;
            }, 2000);
          }
        }
      })
      .catch((err) => {
        sentryError("OTP_SUBMIT", err);
        this.showError("Something went wrong. please try again");
        this.setState({
          isLoading: false,
        });
      });
  };

  screenshot = () => {
    let otp = this.state.otp;
    if (this.state.phone.length !== 10) {
      this.setState({
        snackBar: {
          open: true,
          message: "Phone number is invalid, Please try again.",
        },
        tries: 0,
      });
    } else if (otp.length !== this.state.length) {
      this.setState({
        snackBar: {
          open: true,
          message: "Please enter a valid OTP",
        },
      });
    } else if (this.props.location.state.doRegister) {
      if (!this.state.checkbox1) {
        this.setState({
          snackBar: {
            open: true,
            message: "Please accept our Terms of Service",
          },
        });
      } else {
        var node = document.getElementById("wrapper");
        let that = this;
        domtoimage
          .toPng(node)
          .then((dataUrl) => {
            that.setState({ screenshot: dataUrl });
          })
          .catch(function (error) {
            sentryError("OTP_SCREENSHOT", error);
            that.setState({ screenshot: "" });
          });
        this.submitOTP();
      }
    } else {
      this.submitOTP();
    }
  };

  componentDidMount() {
    if (
      !this.props.location.state.data ||
      !this.props.location.state.data.to ||
      this.props.location.state.data.to === ""
    ) {
      window.location.href = "/login";
    }

    const time =
      this.props.location.state.data && this.props.location.state.data.resend_after
        ? this.props.location.state.data.resend_after
        : 1;
    this.showTimer(time);
    /* setting up registration flow flag */
    localStorage.setItem("registrationInit", this.props.location.state.doRegister);
    if (window.AndroidBridge) {
      window.AndroidBridge.call("onScreenLoaded", "otp"); // notify otp screen loaded
    }
  }

  render() {
    return (
      <div className="userActionParent" id="wrapper">
        <HelmetComponent
          title="OTP at Findastro"
          url="https://www.findastro.com/otp"
          description="OTP at Findastro-get OTP and verify Findastro account."
        />
        <Box id="otpTopArrow">
          <ArrowBackIcon onClick={this.goBack} />
        </Box>
        <img alt="Phone Logo" id="otpPhoneLogo" src={userActionData.phoneImg} />
        <p id="otpText">OTP Verification</p>
        <p id="otpInfoText">
          Enter {this.state.length} digits OTP sent to {this.state.phone}
        </p>
        {this.state.seconds > 0 && this.state.tries > 0 ? (
          <span id="otpTimer">Re-send code in {this.state.seconds}s</span>
        ) : (
          <></>
        )}
        {this.state.seconds === 0 && this.state.tries > 0 ? (
          <div className="resendBtnWrap">
            {!this.state.isResendLoading ? (
              <>
                <Button id="resendBtn" variant="contained" onClick={this.showTimerOnResend}>
                  <span>RESEND OTP</span>
                  {this.state.tries >= 1 ? (
                    <span id="triesTextS">
                      &nbsp;({this.state.tries} {this.state.tries > 1 ? "attempts" : "attempt"} left)
                    </span>
                  ) : (
                    <></>
                  )}
                </Button>
              </>
            ) : (
              <div id="loading">
                <CircularProgress />
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div id="otpFormDiv">
          <form>
            <TextField
              autoFocus
              id="loginOTPInput"
              type="tel"
              variant="outlined"
              placeholder="000000"
              inputProps={{ maxLength: 6 }}
              onKeyDown={this.onlyNumbers}
              onInput={(e) => this.updateOTP(e)}
            />
          </form>
          {this.props.location.state.doRegister ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checkbox1}
                    onChange={this.onCheckBox}
                    name="checkbox1"
                    color="primary"
                  />
                }
                label="I agree to receive all communications at the mobile number provided overriding DND/NCPR list under TRAI regulations. I also agree to receive transactional messages over Whatsapp."
              />
              {/* <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={this.state.checkbox2}
                    onChange={this.onCheckBox}
                    name="checkbox2"
                    color="primary"
                  />
                }
                label="I agree to receive messages over Whatsapp"
              /> */}
            </>
          ) : (
            <></>
          )}
          {!this.state.isLoading ? (
            <Button variant="contained" id="otpSubmitButton" onClick={() => this.screenshot()}>
              {this.props.location.state.doRegister ? "REGISTER" : "LOGIN"}
              <SubmitArrowIcon id="otpSubmitArrow"></SubmitArrowIcon>
            </Button>
          ) : (
            <div id="loading">
              <CircularProgress />
            </div>
          )}
        </div>
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}

export default withRouter(Otp);
