import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { Check, CheckCircle, Close, Done } from '@material-ui/icons';
import axios from '../../../../common/interceptor';

const getRandomQuestions = ({ allQuestions, excludeQuestions = [], shuffle = false }) => {
  const filteredQuestions = allQuestions.filter(question => !excludeQuestions.includes(question.id))
  const limit = filteredQuestions.length < 2 ? 1 : 2
  if (filteredQuestions.length < limit) {
    // console.log('Not enough questions to choose from.')
    return [];
  }
  if (shuffle) {
    const shuffledQuestions = filteredQuestions.sort(() => Math.random() - 0.5)
    return shuffledQuestions.slice(0, limit)
  }

  return filteredQuestions.slice(0, limit)
}

const useStyles = makeStyles({
  container: {
    maxWidth: '460px',
    margin: 'auto',
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    bottom: '60px',
    padding: 0,
    padding: 0,
    zIndex: 0,
    transition: 'all ease .3s',
    // transform: 'translateZ(10px) translateY(100px)',
    backgroundColor: 'var(--clr-chat-questions)',
    // overflow: 'scroll',
    // maxHeight: 200,
    '&.open': {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateZ(10px) translateY(0px)',
    },
    '&.messageSent, &:not(.open)': {
      pointerEvents: 'none',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: '0px -70px',
      zIndex: '10',
      boxShadow: `0 0 9px 9px inset var(--clr-chat-questions),
      0 3px 14px 16px var(--clr-chat-questions),
      0 180px var(--clr-chat-questions)`,
      pointerEvents: 'none',
    },
    '&.open .chat-question-item': {
      opacity: 1,
      transform: 'translateZ(10px) translateY(0px)',
    },
    '&.open .chat-question-close': {
      transform: 'scale(1)',
    },
  },
  questions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.35rem',
    maxHeight: '200px',
    overflow: 'hidden',
    padding: '16px',
    paddingTop: 0,
    overflowY: 'scroll',
    transition: 'all ease .3s',
  },
  questionItem: {
    background: 'var(--clr-chat-right)',
    boxShadow: '0px 4px 14px rgb(0 0 0 / 33%)',
    display: 'flex',
    borderRadius: '.5rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    opacity: 0,
    transform: 'translateZ(10px) translateY(10px)',
    transition: 'all ease .3s',
    '&:hover,&:focus,&:active': {
      background: 'var(--clr-chat-left)',
    },
    '&.selected > .question': {
      transform: 'scale(0)',
      height: '0',
      padding: '0',
    },
    '&.selected > .sent': {
      transform: 'scale(1)',
      height: '40px',
      padding: '.65rem',
    },
    '&:nth-of-type(1)': {
      marginTop: 12
    },
    '&.m-selected': {
      background: '#f2f0e3',
    },
    '&.m-selected .check-icon': {
      display: 'block',
    }
  },
  questionText: {
    padding: '.5rem',
    borderRadius: '.5rem',
    transition: 'all ease .3s',
    transitionProperty: 'transform, opacity, background-color',
    fontSize: '0.9rem',
  },
  questionSent: {
    borderRadius: '.5rem',
    background: 'var(--clr-green)',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    gap: '.75rem',
    display: 'flex',
    transition: 'all ease .3s',
    transitionProperty: 'transform, opacity, background-color',
    transform: 'scale(0)',
    height: '0',
    padding: '0',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
    },
  },
  close: {
    background: '#737373 !important',
    color: '#fff',
    position: 'absolute',
    top: -30,
    right: 16,
    zIndex: 10,
    padding: 5,
    transition: 'all ease .3s .2s',
    transform: 'scale(1)',
  },
  checkIcon: {
    margin: 3,
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 14,
    display: 'none',
    '& .MuiSvgIcon-root': {
      fontSize: 13,
      opacity: .5,
    },
  },
  questionID: {
    display: 'none',
  }
})

const ChatQuestion = ({
  open, // open state
  type = 'normal', // for different type 
  excludeQuestions = [], // excludeQuestions @Deprecated
  onQuestion, // onQuestion callback
  onClose, // onClose callback
  allQuestions = [], // All questions array
}) => {
  const classes = useStyles()
  const [questions, setQuestions] = useState([])
  const [isOpen, setIsOpen] = useState(open)
  const containerRef = useRef(null)
  const [selected, setSelected] = useState({})
  const [messageSent, setMessageSent] = useState(false)
  const questionsItemsRef = useRef(null)

  useEffect(() => {
    // console.log({ openState: open, excludeQuestions, allQuestions })
    setIsOpen(open)
    if (open === true) {
      let LSChatIds = localStorage.getItem('chat_excluded_questions')
      // setQuestions(getRandomQuestions({ allQuestions, excludeQuestions }))

      if (LSChatIds) {
        let questionsList = allQuestions
        LSChatIds = JSON.parse(LSChatIds)
        // Generate the excluded list
        if (LSChatIds.length) {
          LSChatIds.map(id => {
            questionsList = moveItemToEndById(questionsList, id)
          })
        }

        // Reset questions array if id's are empty
        else {
          questionsList = allQuestions.map(i => {
            delete i.selected
            return i
          })
        }

        // console.log({ LSChatIds, questionsList })
        setQuestions(questionsList)
      }
      setMessageSent(false)
    }
    scrollToTop()
  }, [open])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false)
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerRef])

  const updateQuestion = (question) => {
    axios()
      .post("/user-questions/update", {
        id: question.id
      })
      .then((res) => {
        if (res.data.success && res.data.data) {
          // console.log('cq:handleOnQuestion', res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      });
  }

  const handleOnQuestion = (e) => {
    setSelected(e.question)
    setMessageSent(true)
    updateQuestion(e.question)
    // Moved item to end if question not selected
    if (e.question.selected !== true) {
      setQuestions(moveItemToEndById(questions, e.question.id))
      setTimeout(() => {
        const storeIds = questions.filter(item => item.selected === true)
          .map(item => item.id)
        localStorage.setItem('chat_excluded_questions', JSON.stringify(storeIds))
      }, 1000)
    }
    // setTimeout(() => {
    onClose()
    onQuestion(e)
    // }, 1200)
  }

  const moveItemToEndById = (array, itemId) => {
    const filteredArray = array.filter(item => item.id !== itemId)
    const selectedItem = array.find(item => item.id === itemId)
    if (selectedItem) {
      selectedItem.selected = true
      return filteredArray.concat(selectedItem)
    }
    return array;
  }

  const scrollToTop = () => {
    if (questionsItemsRef.current) {
      questionsItemsRef.current.scrollTop = 0
    }
  }
  const scrollToBottom = () => {
    if (questionsItemsRef.current) {
      questionsItemsRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }


  return (
    <div
      className={clsx(
        'chat-question',
        classes.container,
        isOpen ? 'open' : '',
        messageSent ? 'messageSent' : '',
        `chat-question-${type}`,
      )}
      ref={containerRef}
    >
      <IconButton
        className={clsx(classes.close, 'chat-question-close')}
        component="span"
        onClick={onClose}
      >
        <Close />
      </IconButton>

      {questions.length ?
        <>
          <div
            className={clsx(classes.questions, 'chat-question-items')}
            ref={questionsItemsRef}
          >
            {questions.map((question, index) => <div
              key={question.id}
              onClick={e => handleOnQuestion({ question })}
              style={{ transitionDelay: `${open ? index * .2 : 0}s, ${open ? index * .1 : 0}s, 0s` }}
              className={clsx(
                classes.questionItem,
                'chat-question-item',
                selected.id === question.id ? 'd-selected' : '',
                question.selected ? 'm-selected' : ''
              )}
            >
              <div className={clsx(classes.questionText, "question")}>
                <b className={classes.questionID}>{question.id}. </b>
                {question.text}
                <div className={clsx(classes.checkIcon, "check-icon")}><CheckCircle /></div>
              </div>
              <div className={clsx(classes.questionSent, "sent")}>Message sent <Check /></div>
            </div>
            )}
          </div>
        </> :
        <></>
      }

    </div>
  )
}

export default ChatQuestion