import React from "react";
import {withRouter} from "react-router-dom";

import "./UserProfile.css";

import Avatar from "@material-ui/core/Avatar";
import Edit from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";

const FamilyUserProfileBoxComponent = (props) => {
  const onEdit = () => {
    props.history.push({
      pathname: props.isPrimary ? "/userprofile" : "/edit-page/" + props.family.id,
      data: {
        name: props.family.name,
        dateOfBirth: props.family.dateOfBirth,
        timeOfBirth: props.family.timeOfBirth,
        placeOfBirth: props.family.placeOfBirth,
        relation: props.family.relation,
        gender: props.family.gender,
        email: props.family.email,
        phone: props.family.phone,
        horoscopeStyle: props.family.horoscopeStyle,
        profileUrl: props.family.profileUrl,
      },
    });
  };

  return (
    <div className={props.isPrimary ? "familyProfileDiv" : "familyProfileDivOthers"}>
      <Grid container>
        <Grid item xs={4}>
          <Avatar alt="profile image" src={props.family.profileUrl ? props.family.profileUrl : ""}>
            {props.family.name[0]}
          </Avatar>
        </Grid>
        <Grid item xs={8}>
          <div className="familyProfileDetailDiv">
            <span className="familyUserProfileName">{props.family.name} </span>
            <span>DOB: {props.family.dateOfBirth}</span>
            <span>Time: {props.family.timeOfBirth}</span>
            <span className="placeOfBirthTrim">Place: {props.family.placeOfBirth}</span>
          </div>
        </Grid>
      </Grid>
      <Edit className="familyEditButton" onClick={onEdit} />
    </div>
  );
};

export default withRouter(FamilyUserProfileBoxComponent);
