import React, {useState, useEffect, useRef} from "react";
import "./dailyHoroscope.css";
import SunsignMoonsignWidget from "./SunsignMoonsignWidget.component";
import CancelIcon from "@material-ui/icons/Cancel";
import {Button} from "@material-ui/core";
import Phone from "@material-ui/icons/Phone";
import axios from "../../../../common/interceptor";
import CommonPredictionWidget from "./CommonPredictionWidget.component";
import md5 from "md5";
import BrowserUtils from "./UAScripts";

const DailyPredictionWidget = (props) => {
  const [expanded, setExpanded] = useState(props.expanded);
  const signKey = md5(new Date().toDateString());
  const isProfileCreated = localStorage["email"] && localStorage["email"] !== "null" && localStorage["email"] !== "";
  const dailyPrediciton = JSON.parse(localStorage["dailyPrediciton"] || "{}");
  const onExpand = (e) => {
    //setExpanded(true);
    window.location.hash = "dpexpanded";
    props.expandView(true);
  };
  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);
  //
  useEffect(() => {
    document.body.style.overflow = expanded ? "hidden" : "auto";
  }, [expanded]);
  const [widgetItems, setWidgetItems] = useState([]);
  const markerWidth = "calc(" + 1 / widgetItems.length / 0.01 + "% - 12px)";
  const [activeMarker, setActiveMarker] = useState(0);
  const widgetList = useRef();
  const [dailyPredicitonData, setDailyPredicitonData] = useState(dailyPrediciton);

  const snapToPosition = () => {
    markerScrollTo(activeMarker);
    if (widgetItems[activeMarker]) {
      trackEvent("fa_daily_pred_view", widgetItems[activeMarker].props.type);
    }
  };

  const trackEvent = (type, label) => {
    const payload = {
      p_screen: "home",
      p_label: "NA",
      p_content: label,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  const prepareWidgets = (data) => {
    //console.log("falog-", data);
    setWidgetItems([
      <SunsignMoonsignWidget ctaAction={props.ctaAction} onExpand={onExpand} type="sunsign" />,
      <SunsignMoonsignWidget ctaAction={props.ctaAction} onExpand={onExpand} type="moonsign" />,
      <CommonPredictionWidget
        ctaAction={props.ctaAction}
        onExpand={onExpand}
        data={data}
        type="chart"
        trackEvent={trackEvent}
      />,
      <CommonPredictionWidget ctaAction={props.ctaAction} onExpand={onExpand} data={data} type="sookshma" />,
      <CommonPredictionWidget ctaAction={props.ctaAction} onExpand={onExpand} data={data} type="numerology" />,
      <CommonPredictionWidget ctaAction={props.ctaAction} onExpand={onExpand} data={data} type="panchanga" />,
      <CommonPredictionWidget ctaAction={props.ctaAction} onExpand={onExpand} data={data} type="muhurtha" />,
      <CommonPredictionWidget ctaAction={props.ctaAction} onExpand={onExpand} data={data} type="mantra" />,
    ]);
  };

  useEffect(() => {
    if (isProfileCreated && !dailyPrediciton[signKey]) {
      axios()
        .post("/daily-predictions")
        .then((res) => {
          if (res.data.success) {
            const data = {};
            data[signKey] = res.data.data;
            localStorage["dailyPrediciton"] = JSON.stringify(data);
            prepareWidgets(data);
            setDailyPredicitonData(data);
          } else {
            prepareWidgets({});
            setDailyPredicitonData({});
          }
        });
    } else {
      prepareWidgets(dailyPredicitonData);
    }
  }, []);
  useEffect(snapToPosition, [activeMarker]);

  let onProgress = false;
  const handleScroll = () => {
    if (!onProgress) {
      onProgress = true;
      setTimeout(() => {
        const element = widgetList.current;
        const scrollIndex = Math.round(element.scrollLeft / element.clientWidth);
        if (activeMarker !== scrollIndex) {
          setActiveMarker(scrollIndex);
        }
        //console.log("falog", "ended:" + scrollIndex);
        onProgress = false;
      }, 1000);
    }
    window.stopAllAudio();
  };

  const moveLeft = (e) => {
    const marker = Math.max(0, activeMarker - 1);
    markerScrollTo(marker);
    setActiveMarker(marker);
    const element = e.target;
    element.classList.add("active");
    setTimeout((e) => element.classList.remove("active"), 400);
  };

  const moveRight = (e) => {
    const marker = Math.min(widgetItems.length - 1, activeMarker + 1);
    markerScrollTo(marker);
    setActiveMarker(marker);
    const element = e.target;
    element.classList.add("active");
    setTimeout((e) => element.classList.remove("active"), 400);
  };

  const markerScrollTo = (widget) => {
    if (widgetList.current) {
      const left = widget * widgetList.current.clientWidth;
      widgetList.current.scrollLeft = left;
    }
  };

  function closePop() {
    props.handleClose();
    setExpanded(false);
  }
  return (
    <div className={["daily-widgets", expanded ? "expanded" : ""].join(" ")} onClick={window.stopAllAudio}>
      {dailyPredicitonData && (
        <>
          <div className="widget-slide-marker">
            {widgetItems.map((item, i) => (
              <span
                className={["marker", i === activeMarker ? "active" : ""].join(" ")}
                onClick={(e) => setActiveMarker(i)}
                style={{width: markerWidth}}></span>
            ))}
          </div>
          <div className="widget-item-list" ref={widgetList} onTouchStart={handleScroll}>
            {widgetItems.map((item) => (
              <div className="widget-item">{item}</div>
            ))}
          </div>
          <div className="tap-left" onClick={moveLeft}></div>
          <div className="tap-right" onClick={moveRight}></div>
          <div className="sign-cta-panel">
            <div>Consult now &amp; know more about your day!</div>
            <div>
              <Button
                className="sign-cta"
                startIcon={<Phone />}
                onClick={(e) => {
                  props.ctaAction();
                }}>
                Consult now
              </Button>
              <CancelIcon className="close-icon" onClick={(e) => closePop()} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default DailyPredictionWidget;
