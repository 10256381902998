import React, { useEffect, useState } from "react"
import Modal from "./Modal"
import Otp from "../../../../user-actions/components/Otp.component"
import userActionData from "../../../../user-actions/mock/UserActionData.mock"

import { withRouter, useHistory } from 'react-router-dom'
import { Avatar, Button, TextField } from "@material-ui/core"
import Snackbar from "../../../../../common/SnackBarMessage"
import style from './InsufficientModal.style.module.css'
import AstrologerUtils from "../AstrologerUtils"
import defaultImage from "../../../../../common/default_profile_image.svg"
import CallIcon from '@material-ui/icons/Call';
import ChatBubble from '@material-ui/icons/ChatBubble';
import FA_localStorage from "../../../../../common/util/FA_localStorage"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import RechargeLinkRedirect from "../RechargeLinkRedirect"
import BrowserUtils from "../UAScripts";
import T2AEventTrack from "../../../../../common/T2AEventTrack";
import axios from "../../../../../common/interceptor"

const InsufficientModal = (props) => {
  const history = useHistory()
  const [astrologer, setAstrologer] = useState(history.location.state?.astrologer || props.astrologer)
  const [actionType, setActionType] = useState(history.location.state?.actionType || props.actionType)
  const [cancel, setCancel] = useState(history.location.state?.cancel || props.cancel)
  const [openModal, setOpenModal] = useState(props.open !== 'undefined' ? props.open : true)
  const walletBalance = parseInt(FA_localStorage("walletBalance")) || 0
  const minWalletBalance = astrologer?.astrologerFee * astrologer?.MIN_BALANCE_MINS
  const diffBalance = minWalletBalance - walletBalance > 0 ? minWalletBalance - walletBalance : minWalletBalance
  const [recharges, setRecharges] = useState('')

  // console.log(props)

  // console.log(astrologer?.astrologerFee, astrologer?.MIN_BALANCE_MINS)

  // console.log('InsufficientModal:astrologer', astrologer)
  // console.log('InsufficientModal:actionType', actionType)
  // console.log('InsufficientModal:cancel', cancel)
  // console.log('InsufficientModal:props', props)
  // useEffect(() => {
  //   // console.log(props, props.open !== 'undefined')
  //   if (props.open !== 'undefined') {
  //     setOpenModal(props.open)
  //   }
  // }, [props])

  const checkUserUpdates = () => {
    axios()
      .post("/profile/updates")
      .then((res) => {
        if (res.data.success === true) {
          setRecharges(res.data.data.recharges)
          if (res?.data?.data && res.data.data.hasOwnProperty('user_status')) {
            BrowserUtils.setCachedKey('userStatus', res.data.data.user_status);
          }
        }
      })
      .catch((err) => {
        console.warn(err)
      })
  }

  useEffect(() => {
    if (props.open === true) {
      checkUserUpdates()
    }
  }, [props.open])


  const browserBackTrigger = () => {
    if (window.location.hash === '#insufficient-balance' && props.open === true) {
      history.goBack()
    }
  }

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.endsWith('#insufficient-balance')
        && !e.newURL.endsWith('#back-button--wallet-recharge')
        && props.open === true) {
        props.handleClose()
      }
    })
  })

  const trackeventData = (data) => {
    const eData = {
      event: {
        name: data.eventName,
        attributes: {
          'Screen Name': 'Insufficient balance',
          'Astrologer ID': data.id,
          'Astrologer Name': data.name,
          'Price': parseInt(diffBalance),
          'Consultation Type': (data.free_minutes && data.free_minutes > 0) ? 'Free' : 'Paid',
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }

    if (data.buttonName) {
      eData.event.attributes['Button Name'] = data.buttonName;
    }
    if (data.location) {
      eData.event.attributes['Button Location'] = data.location;
    }
    if (data.sourceScreen) {
      eData.event.attributes['Source Screen Name'] = data.sourceScreen;
    }
    console.log(eData);
    T2AEventTrack(eData.event, eData.extra);
  }

  useEffect(() => {
    if (props.open === true) {
      const eData = astrologer;
      eData.eventName = 'Screen View Action';
      eData.sourceScreen = 'Chat or Talk';
      const clickFromToScreenName = {
        chat: 'Chat Screen',
        astrologerRecommendation: 'Chat Screen',
        'Astrologer profile bottom': 'Astrologer profile',
        'Astrologer profile top': 'Astrologer profile',
        'Try another astrologer - Yes, start consultation': 'Chat Screen',
      };

      if (props?.clickFrom) {
        if (clickFromToScreenName[props.clickFrom]) {
          eData.sourceScreen = clickFromToScreenName[props.clickFrom];
        } else if (props.clickFrom.includes('/astrologer-profile')) {
          eData.sourceScreen = 'Astrologer profile';
        }
      }

      //console.log(JSON.stringify(props))

      trackeventData(eData);
      window.location.hash = "insufficient-balance";
    }
  }, [props.open])


  const [snackBar, setSnackBar] = useState({
    message: '',
    open: false
  })

  const onRechargeNow = (e) => {
    const eData = astrologer;
    eData.eventName = 'Buy Now Action';
    eData.buttonName = 'Recharge';
    eData.location = 'Insufficient balance';
    eData.sourceScreen = 'Insufficient balance';
    trackeventData(eData);
    if (e?.goToWallet) {
      e.goToWallet()
    } else {
      history.push('/user-wallet')
    }
  }

  const onClose = () => {
    console.log(props)
    browserBackTrigger()
    props.handleClose()
    const eData = astrologer;
    eData.eventName = 'Button Action';
    eData.location = 'Insufficient balance'
    eData.buttonName = 'Cancel';
    trackeventData(eData);
  }

  return (
    <>
      <Modal
        type="normal"
        overlayClose={true}
        open={props.open && recharges !== ''}
        handleClose={onClose}
      >

        <>
          <Modal.Header type="error">
            {recharges > 0 ? 'Insufficient wallet balance!' : 'Boost Your Wallet Balance!'}
          </Modal.Header>
          <Modal.Content>
            <div className={style.insufficientBalance}>
              {/* <strong>Your wallet balance:</strong>
              <h4>{walletBalance} pts</h4> */}

              {recharges === 0 && <p style={{ marginBottom: '1.5rem' }}>To chat with this astrologer recharge now.</p>}

              <div className={style.walletStatus}>
                <div className="icon">
                  <AccountBalanceWalletIcon fontSize="default" />
                </div>
                <div className="status">
                  <div className="title">Wallet balance:</div>
                  <div className="pts">
                    <div className="name">Current</div>
                    <div className="pt">: {walletBalance} pts</div>
                  </div>
                  <div className="pts">
                    <div className="name">Required</div>
                    <div className="pt">: <span className="red">{minWalletBalance} pts</span></div>
                  </div>
                </div>
              </div>
              {recharges > 0 ?
                <p>Your wallet balance is insufficient to start this
                  consultation (Minimum {minWalletBalance} pts required). <span style={{ color: 'var(--clr-red)' }}>Please
                    recharge for at least <b>{diffBalance} pts</b> to start this consultation.</span></p>
                :
                null
              }

            </div>
          </Modal.Content>

          <Modal.Action>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <RechargeLinkRedirect
              refData={{
                refID: 'insufficient-balance-modal/recharge-now-button',
                type: props.dialogType || 'insufficient-balance',
                astrologerID: astrologer.id,
                astrologerName: astrologer.name,
                astrologerProfileURL: astrologer.profile_url || astrologer.profileUrl,
                astrologerCharge: astrologer?.perMinutePrice || 0,
                maxAmount: 1000,
                highlight: 499,
                location: 'Insufficient balance',
              }}
              onClick={(e) => {
                onRechargeNow(e)
              }}
            >
              <Button className="primary" variant="contained">
                Recharge Now
              </Button>
            </RechargeLinkRedirect>
          </Modal.Action>
        </>

      </Modal >

      <Snackbar
        vertical="top"
        horizontal="center"
        message={snackBar.message}
        closeError={() => setSnackBar({ ...snackBar, open: false })}
        open={snackBar.open}
      />
    </>
  )
}

export default InsufficientModal