import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {isMobile} from "react-device-detect";
import moengage from "../../../common/moengage";

import introData from "../mock/IntroScreenOrangeData.mock";
import HelmetComponent from "../../../common/HelmetComponent.component";

import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  body: {
    width: "100%",
    margin: isMobile ? "0 auto" : "0 auto",
    textAlign: "center",
    backgroundColor: "#fbead0",
    height: "100vh",
    color: "#000000",
    "& .MuiMobileStepper-dot": {
      backgroundColor: "#8b0a2e3d",
    },
    "& .MuiMobileStepper-root": {
      background: "none !important",
      // position: isMobile ? "fixed" : "",
      // bottom: isMobile ? "24px" : "",
      // maxWidth: "450px",
      // marginTop: isMobile ? "" : "40px",
      // width: "95%",
      width: "100%",
      padding: "20px",
    },
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: "#8b0a2e",
    },
  },
  introContainer: {
    display: "flex",
    height: "100vh",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  heading: {
    color: "#8b0a2e",
    fontWeight: "600",
    fontSize: "2em",
    lineHeight: "1.1em",
    marginBottom: "8px",
  },
  textDiv: {
    marginLeft: "36px",
    marginRight: "36px",
    // maxWidth: "450px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "80vh",
    textAlign: "left",
    maxWidth: "100%",
  },
  text: {
    lineHeight: "16px",
  },
  skipText: {
    color: "#8b0a2e",
  },
  introScreenLabel: {
    fontWeight: "600",
    color: "#8b0a2e",
  },
  textContentWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "50%",
  },
  poweredByWrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "12px",
    textAlign: "center",
  },
  logo: {
    display: "flex",
    height: "50%",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      maxWidth: "50%",
      margin: "0 auto",
    },
  },
  poweredBy: {
    color: "#8b0a2e",
  },
  poweredByDesc: {
    color: "#000000a3",
  },
});

const IntroScreenOrange = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    console.log(introData.length);
  }, []);

  const handleNext = () => {
    if (activeStep === introData.length - 1) {
      moengage("INTRO_PAGE_FINISH");
      localStorage.setItem("old_user", true);
      window.location.href = "/login";
    } else {
      moengage("INTRO_PAGE_NEXT");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep !== 0) {
      moengage("INTRO_PAGE_PREVIOUS");
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSkip = () => {
    moengage("INTRO_PAGE_SKIP");
    localStorage.setItem("old_user", true);
    window.location.href = "/";
  };

  useEffect(() => {
    moengage("INTRO_PAGE_VIEWED");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.body}>
      <HelmetComponent
        title="Welcome To Findastro"
        url="https://www.findastro.com/intro"
        description="Welcome To Findastro-We are number # 1 Astrolger website in India."
      />
      {/* <img className={classes.image} src={introData[activeStep].image} alt={introData[activeStep].heading} /> */}
      <div className={classes.introContainer}>
        <div className={classes.textDiv}>
          <div className={classes.logo}>
            <img src="https://assets.findastro.com/findastro_logo_color.png" alt="logo" />
            <div className={classes.poweredByWrapper}>
              <div className={classes.poweredBy}>
                Powered by <b>clickastro</b>
              </div>
              <div className={classes.poweredByDesc}>India's leading astrology service provider</div>
            </div>
          </div>
          <div className={classes.textContentWrapper}>
            <div className={classes.heading} dangerouslySetInnerHTML={{__html: introData[activeStep].heading}}></div>
            <div className={classes.text} dangerouslySetInnerHTML={{__html: introData[activeStep].text}}></div>
          </div>
        </div>
        <MobileStepper
          steps={introData.length}
          position="static"
          variant="dots"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext}>
              <span className={classes.introScreenLabel}>{activeStep === introData.length - 1 ? "NEXT" : "NEXT"}</span>
            </Button>
          }
          backButton={
            <Button size="small" onClick={handlePrevious}>
              {activeStep === 0 ? <></> : <span class={classes.skipText}>PREVIOUS</span>}
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default IntroScreenOrange;
