export const API_BASE_URL = "https://devapi.findastro.co/api/v1";
// export const API_BASE_URL = "http://dev-apis.find-astro.com/api/v1";
export const FB_APP_ID = "";
export const GOOGLE_APP_ID = "";
export const GOOGLE_PLACES_API_KEY = "504c8b15ff1ff73e20b1c62ba27a600a649b64c8af";
export const CURRENT_APP_VERSION = "1.0.5-r";
export const IMAGE_CDN_PATH = "https://findastro-user.s3.ap-south-1.amazonaws.com/partner-media/images/";
//export const STATUS_API_PATH = "https://secure-earth-78619.herokuapp.com/events";
export const STATUS_API_PATH = "https://sse.findastro.com/events";
export const MIN_BALANCE_MINS = 5;
export const BOOKING_CHARGE = 10;
export const SUPPORT_NUMBER = "+91 8048767766";
export const CONSULTATION_NUMBER = "+91 8047248888";
export const ASTROLOGER_RELATIONS_NUMBER = "+91 8048767766";
export const PUJA_SUPPORT_NUMBER = "+91 7034900222";
export const PUJA_COUPON_DISCOUNT = 0.05;
export const DEFAULT_PRASAD_COST = 200;
export const PUJA_IMAGE_PATH = "https://s3.ap-south-1.amazonaws.com/assets.findastro.com/pujas/";
export const VALID_EMAIL_ID = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const VALID_NAME = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const VALID_PHONE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\./0-9]*$/;
export const MIN_SCROLL_TO_HIDE = 0.02;
export const APPLOZIC_APP_ID = "astrovisiononline1b469e4b440b37142f50ccb64178b0fbe";
export const T2A_SUPPORT_NUMBER = "+91 9778426205";

/** client version */
export const VERSION = "4.0.28";
