/**
 * It's a common component for both Sunsign and Moonsign
 * predicitons
 *  */
import React, {useState, useEffect} from "react";
import axios from "../../../../common/interceptor";
import md5 from "md5";
import "./dailyHoroscope.css";
import "../../../../modules/misc-screens/components/astrovision-font.css";
import miscScreensData from "../../../misc-screens/mock/MiscScreensData.mock";
import Modal from "@material-ui/core/Modal";
import {Button} from "@material-ui/core";
import Phone from "@material-ui/icons/Phone";
import {format} from "date-fns";
import separator from "./separator.png";
import BrowserUtils from "./UAScripts";

const SunsignMoonsignWidget = (props) => {
  //
  const signKey = md5(new Date().toDateString());
  const userCRJ = JSON.parse(localStorage["userCRJ"] || "{}");
  const [isProfileCreated, setIsProfileCreated] = useState(
    localStorage["email"] && localStorage["email"] !== "null" && localStorage["email"] !== ""
  );
  const [LocalSunSignData, setLocalSunSignData] = useState(JSON.parse(localStorage["sunsignData"] || "{}"));
  const [LocalMoonSignData, setLocalMoonSignData] = useState(JSON.parse(localStorage["moonsignData"] || "{}"));
  const [switchType, setSwitchType] = useState(props.type || "sunsign");
  const [signSwitchType, setSignSwitchType] = useState(props.type || "sunsign");
  const [signSelectorOpen, setSignSelectorOpen] = useState(false);
  const [showPopupPrediciton, setShowPopupPrediciton] = useState(false);
  const [currentMoonsign, setCurrentMoonSign] = useState(userCRJ.MoonSign ? parseInt(userCRJ.MoonSign.Value) : null);
  const [currentSunsign, setCurrentSunSign] = useState(userCRJ.SunSign ? parseInt(userCRJ.SunSign.Value) : null);

  const getSunSignData = (sign) => {
    setCurrentSunSign(sign);
    axios()
      .post("/horoscope/daily", {sign: sign})
      .then((res) => {
        if (res.data.success) {
          setSunsignData(res.data.data.sunsign.replace(/<\/?[^>]+(>|$)/g, " "));
          setSignSelectorOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setSunsignData = (data) => {
    const signdata = {};
    signdata[signKey] = data;
    localStorage.setItem("sunsignData", JSON.stringify(signdata));
    setLocalSunSignData(signdata);
  };

  const setMoonsignData = (data) => {
    const signdata = {};
    signdata[signKey] = data;
    localStorage.setItem("moonsignData", JSON.stringify(signdata));
    setLocalMoonSignData(signdata);
  };

  const getMoonSignData = (sign) => {
    setCurrentMoonSign(sign);
    axios()
      .post("/horoscope/daily", {sign: sign})
      .then((res) => {
        if (res.data.success) {
          setMoonsignData(res.data.data.moonsign.replace(/<\/?[^>]+(>|$)/g, ""));
          setSignSelectorOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //console.log("crj", LocalSunSignData);
    const LocalSignData = props.type === "sunsign" ? LocalSunSignData : LocalMoonSignData;
    if (isProfileCreated && !LocalSignData[signKey]) {
      axios()
        .post("/crj")
        .then((res) => {
          if (res.data.success && res.data.crj.SunSign.Value) {
            localStorage.setItem("userCRJ", JSON.stringify(res.data.crj));
            if (props.type === "sunsign") {
              getSunSignData(parseInt(res.data.crj.SunSign.Value));
            } else if (props.type === "moonsign") {
              getMoonSignData(parseInt(res.data.crj.MoonSign.Value));
            }
          } else {
            //remove local crj if logged in
            localStorage.removeItem("userCRJ");
            localStorage.removeItem("sunsignData");
            localStorage.removeItem("moonsignData");
            localStorage.removeItem("dailyPrediciton");
            setIsProfileCreated(false);
            //remove local crj if logged in
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [LocalSunSignData]);

  const showSignSelector = () => {
    setSignSelectorOpen(true);
  };

  const showPredicitonPopup = () => {
    const payload = {
      p_screen: "home",
      p_label: (signSwitchType === "sunsign" ? currentSunsign : currentMoonsign).toString(),
      p_content: signSwitchType,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog("fa_daily_pred_read_more", payload);
    props.onExpand();
  };

  const formatType = (type) => {
    const map = {
      sunsign: "Sunsign",
      moonsign: "Moonsign",
    };
    return map[type] || "Today's horoscope";
  };

  const editProfile = () => {
    window.location.href = "/userprofile";
  };
  //
  return (
    <div className="daily-placeholder">
      <div className="predictions">
        <div className="title-block">
          <span className="title">
            Today's {formatType(props.type)} horoscope <small>({format(new Date(), "dd MMM")})</small>
          </span>
          <img src={separator} alt="separator" className="separator" />
          {!props.type && (
            <span className="switch">
              <input id="switch-input" type="checkbox" checked={switchType === "sunsign"} />
              <label
                onClick={(e) => {
                  setSwitchType("sunsign");
                  setSignSwitchType("sunsign");
                }}>
                Sunsign
              </label>
              <label
                onClick={(e) => {
                  setSwitchType("moonsign");
                  setSignSwitchType("moonsign");
                }}>
                Moonsign
              </label>
            </span>
          )}
        </div>
        {!isProfileCreated ? (
          <div className="content-block dp-no-profile">
            <div className="sign-block">
              <div className="infobox">
                <div>Please complete your birth details to read your FREE daily horoscope</div>
                <div>
                  <button className="upd-bttn" onClick={editProfile}>
                    Update profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-block">
            {LocalSunSignData[signKey] && currentSunsign !== null && props.type === "sunsign" && (
              <div className={["sign-block", switchType === "sunsign" ? "" : "moved"].join(" ")}>
                <span>
                  <img
                    onClick={showSignSelector}
                    src={miscScreensData.signData[currentSunsign].icon}
                    alt="sign-icon"
                    width={56}
                    align="left"
                  />
                  {LocalSunSignData[signKey]}
                  <b className="sign-name">{miscScreensData.signData[currentSunsign].sign}</b>
                  {/* <b className="sign-icon-text">
                      {miscScreensData.signData[parseInt(userCRJ.SunSign.Value) - 1].letter}
                    </b> */}
                </span>
              </div>
            )}
            {LocalMoonSignData[signKey] && currentMoonsign !== null && props.type === "moonsign" && (
              <div className="sign-block">
                <span>
                  <img
                    onClick={showSignSelector}
                    src={miscScreensData.signData[currentMoonsign].icon}
                    alt="sign-icon"
                    width={56}
                    align="left"
                  />
                  {LocalMoonSignData[signKey]}
                  <b className="sign-name">{miscScreensData.signData[currentMoonsign].moonSign}</b>
                  {/* <b className="sign-icon-text">
                      {miscScreensData.signData[parseInt(userCRJ.MoonSign.Value) - 1].letter}
                    </b> */}
                </span>
              </div>
            )}
            {((LocalSunSignData[signKey] && currentSunsign !== null) ||
              (LocalMoonSignData[signKey] && currentMoonsign !== null)) && (
              <>
                <div className="read-more" onClick={showPredicitonPopup}>
                  <span>Read more...</span>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <Modal open={signSelectorOpen} onClose={(e) => setSignSelectorOpen(false)}>
        <div className="sign-selector">
          <div className="title-block">
            <span className="title">Choose your sign</span>
            {/* <span className="switch">
                <input id="switch-input" type="checkbox" checked={signSwitchType === "sunsign"} />
                <label onClick={(e) => setSignSwitchType("sunsign")}>Sunsign</label>
                <label onClick={(e) => setSignSwitchType("moonsign")}>Moonsign</label>
              </span> */}
          </div>
          <div className={["sunsign", "sign-options", signSwitchType === "sunsign" ? "" : "moved"].join(" ")}>
            {miscScreensData.signData.map(
              (sign) =>
                sign.icon && (
                  <div className="sign-item" onClick={(e) => getSunSignData(sign.id)}>
                    {/* <div className="sign-icon-text">{sign.letter}</div> */}
                    <div className="sign-icon-text">
                      <img src={sign.icon} alt="sign-icon" width={56} align="left" />
                    </div>
                    <div className="sign-name">{sign.sign}</div>
                    <div className="sign-ref">{sign.period}</div>
                  </div>
                )
            )}
          </div>
          <div className="moonsign sign-options">
            {miscScreensData.signData.map(
              (sign) =>
                sign.icon && (
                  <div className="sign-item" onClick={(e) => getMoonSignData(sign.id)}>
                    {/* <div className="sign-icon-text">{sign.letter}</div> */}
                    <div className="sign-icon-text">
                      <img src={sign.icon} alt="sign-icon" width={56} align="left" />
                    </div>
                    <div className="sign-name">{sign.moonSign}</div>
                  </div>
                )
            )}
          </div>
        </div>
      </Modal>
      {currentSunsign && (
        <Modal open={showPopupPrediciton} onClose={(e) => setShowPopupPrediciton(false)}>
          <div className="sign-selector">
            <div className="title-block">
              <span className="title h3">
                Today's horoscope for{" "}
                {switchType === "sunsign"
                  ? miscScreensData.signData[currentSunsign].sign
                  : miscScreensData.signData[currentMoonsign].moonSign}
              </span>
            </div>
            <div className="content-block open">
              {LocalSunSignData[signKey] && currentSunsign !== null && switchType === "sunsign" && (
                <div className={["sign-block"].join(" ")}>
                  <span>
                    {/* <img src={miscScreensData.signData[currentSunsign].icon} alt="sign-icon" width={56} align="left" /> */}
                    {LocalSunSignData[signKey]}
                    {/* <b className="sign-name">{miscScreensData.signData[currentSunsign].sign}</b> */}
                    {/* <b className="sign-icon-text">
                      {miscScreensData.signData[parseInt(userCRJ.SunSign.Value) - 1].letter}
                    </b> */}
                  </span>
                </div>
              )}
              {LocalMoonSignData[signKey] && currentMoonsign !== null && switchType === "moonsign" && (
                <div className="sign-block">
                  <span>
                    {/* <img src={miscScreensData.signData[currentMoonsign].icon} alt="sign-icon" width={56} align="left" /> */}
                    {LocalMoonSignData[signKey]}
                    {/* <b className="sign-name">{miscScreensData.signData[currentMoonsign].moonSign}</b> */}
                    {/* <b className="sign-icon-text">
                      {miscScreensData.signData[parseInt(userCRJ.MoonSign.Value) - 1].letter}
                    </b> */}
                  </span>
                </div>
              )}
            </div>
            <div className="sign-cta-panel">
              <div>Consult now and know more about your day!</div>
              <div>
                <Button
                  className="sign-cta"
                  startIcon={<Phone />}
                  onClick={(e) => {
                    setShowPopupPrediciton(false);
                    props.ctaAction();
                  }}>
                  Consult now
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
export default SunsignMoonsignWidget;
