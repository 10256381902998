import React, {useEffect, useState} from "react";
import sentryError from "../sentryError";
import Snackbar from "../SnackBarMessage";

const IsSupported = () => {
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  useEffect(() => {
    try {
      const testKey = "FA_FC_CE";
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
    } catch (e) {
      if (window.navigator.cookieEnabled) {
        sentryError("LOCALSTORAGE[Cookie:Yes]", e);
      } else {
        sentryError("LOCALSTORAGE[Cookie:No]", e);
      }
      showError("COOKIES DISABLED! Please enable cookies in your Web browser to continue browsing.");
    }
  }, []);

  return (
    <Snackbar
      vertical="top"
      horizontal="right"
      message={snackBar.message}
      closeError={closeError}
      open={snackBar.open}></Snackbar>
  );
};
export default IsSupported;
