import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import style from './style.module.css'
import { CircularProgress } from "@material-ui/core";

const Header = ({ children, invert, type = 'default' }) => {
  return (
    <div className={[
      style.modalHeader,
      type,
      invert ? 'invert' : '',
      invert ? 'invert' : '',
    ].join(' ')}>{children}</div>
  )
}

const Action = ({ children }) => {
  return (
    <div className={style.modalAction}>{children}</div>
  )
}

const Content = ({ children }) => {
  return (
    <div className={style.modalContent}>
      <div className="content">{children}</div>
      <div className="loading"><CircularProgress /></div>
    </div>
  )
}
const defaultTransition = { transform: 'translateY(20px)', opacity: 0 }
const defaultTransitionBackdrop = { opacity: 0 }

const Modal = ({
  children,
  type,
  overlayClose = false,
  loading = false,
  waiting = false,
  open = true,
  handleClose = () => { }
}) => {
  const modalRef = useRef();
  const { id } = useParams();
  const history = useHistory();
  const [transition, setTransition] = useState(defaultTransition)
  const [transitionOverlay, setTransitionOverlay] = useState(defaultTransitionBackdrop)
  const [openModal, setOpenModal] = useState(open)

  // useEffect(() => {
  //   // document.body.style.overflow = 'hidden'
  //   // openModalAnim()
  //   return () => {
  //     // document.body.style.overflow = null
  //   }
  // }, []);

  useEffect(() => {
    // document.body.style.overflow = 'hidden'
    // console.log(open)
    setOpenModal(open)
  }, [open])

  useEffect(() => {
    openModalAnim()
  }, [openModal])

  const openModalAnim = () => {
    // console.log(openModal)
    if (openModal) {
      setTransition({ transform: 'translateY(0px)', opacity: 1 })
      setTransitionOverlay({ opacity: .5 })
    }
  }

  const onClose = (e) => {
    // e.stopPropagation()
    // // console.log('hi')
    // history.push('/app-home')
    handleClose()
  }

  return (
    <>
      {openModal &&
        <div
          ref={modalRef}
          className={[
            style.modalWrapper,
            type,
            loading ? 'loading' : '',
            waiting ? 'waiting' : '',
          ].join(' ')}
        >
          <div className={style.modalWrap}>
            <div style={transitionOverlay} className={style.modalOverlay} onClick={e => overlayClose ? onClose(e) : ''}></div>
            <CircularProgress className="waiting-loader" />
            <div style={transition} className={style.modalHolder}>
              {children}
            </div>
          </div>
        </div>
      }
    </>

  )
}

Modal.Header = Header
Modal.Content = Content
Modal.Action = Action

export default Modal