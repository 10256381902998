import React from "react";
import { displayDateTime } from "./ChatUtls";
import { Check } from "@material-ui/icons";

const SingleMessage = ({ msg, msgStatus, MessageType, metaText }) => {
  return (
    <div className={"chat-message " + ((msg.from === "bot" || msg.from.split("-")[0]) !== "user" ? "left" : "right")}>
      <div className="chat-message-text">
        {msg.body?.extra?.before && msg.body.extra.before}
        <span
          className="chat-message-text-only"
          dangerouslySetInnerHTML={{
            __html:
              msg.from === "bot" ? metaText ? metaText : msg.body.text.text.replaceAll("\n", "<br>") : msg.body.text.replaceAll("\n", "<br>"),
          }}></span>
      </div>

      <div className="chat-message-meta">
        <div className="chat-message-meta-time">{displayDateTime(msg.created)}</div>
        {(msg.from.split("-")[0] === "user" || msg.from.split("-")[0] === "astrologer") && (
          <div className="chat-message-meta-status">
            <span className={`chat-status-${msgStatus}`}>
              {/* {msgStatusDisplay[msgStatus]} */}
              {/* {msgStatus === "delivered" ? <>&#10003;&#10003;</> : <>&#10003;</>} */}
              <Check className="check-icon" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleMessage;
