const TestimonialData = {
  data: [
    {
      Testimonial:
        "Thank you mam for guiding me. I was reassured by your consultation and advice on dasa nivaranas. However, one thing that I missed was the option of video calls which would have been much better. I think this would make the consultations more personal and also credible. Mam, I’ll be contacting you for further queries regarding my child’s future.",
      Name: "Manju",
      Place: "Hyderabad",
    },
    {
      Testimonial:
        "Thank you sir for the advice which has helped to resolve the problems between me and my fiancé and we are set to get married next month! I hope next time there won’t be a long wait time for the consultation.",
      Name: "Pragya",
      Place: "Mumbai",
    },
    {
      Testimonial:
        "Thank you mam for your consultation. The recent pandemic had put a dent in my business plans but I’m following your advice now and it looks like things are getting better. I have initiated by boutique opening plans in September :)",
      Name: "Preeti",
      Place: "Indore",
    },
    {
      Testimonial:
        "Hello sir, this is Bishwa. Recently I came to know about this app through one of my family members. Actually had my daughter’s marriage this month. But due to Covid and all we had to change the marriage date to next coming month so regarding that I called to Findastro. The astrologer was from South and he was so kind to me got all the solutions for my queries from him. But one thing I must say that at the beginning when I tried to recharge my wallet it showed some kind of error and the amount debited then I thought that my money was lost then got tensed. But when I found your customer support number and I called you, and you guys helped me to clear all my problems that I faced and fixed my money issues. Also one thing I must say is that your customer support is very excellent and you have a quick response team.",
      Name: "Bishwa",
      Place: "Delhi",
    },
    {
      Testimonial:
        "The best astrology app I have ever used! I am thankful to this app for the wonderful consultation. It was intuitive and affirming and gave me a lot of inspiration in these difficult times. So much to reflect on. I had a great experience with your well-behaved astrologers.",
      Name: "Sanjeev",
      Place: "Agra",
    },
    {
      Testimonial:
        "It really helped me in the past one month. I would really like to meet your team when I am in cochin. I’ll say, if you are worried about your future, this is the app one must go for!!! Your 24/7 availability of astrologers is what I loved.",
      Name: "Rohit",
      Place: "Mumbai",
    },
    {
      Testimonial:
        "I was struggling with some financial issues that time my sister told me she has a discount coupon of Findastro, that’s how I registered here and got the five minutes free offer. But Mujhe ek Cheez ki Kami lagi that you are not providing international consultation Agar yeh facilities bhi provide Kardete toh I can recommend this app to some of my colleagues who are abroad. Otherwise, it was very pleasant for me. Great work Findastro.",
      Name: "Supriya",
      Place: "London",
    },
    {
      Testimonial:
        "I’m a product designer; I must say Findastro is easy to use, great service, and a great design. The app is user-friendly, I could get things done very easily and quickly in this. But the best is your quality of Astrologers. The only drawback I found is you don’t support multiple profiles, I hope you will have it in the future. Your app is far better than any other Astrology Software in the Google play store. Best wishes.",
      Name: "Rahul",
      Place: "Bengaluru",
    },
    {
      Testimonial:
        "You wanted to hear my story, here it is! I was really hopeless about my future life, especially in this pandemic situation. I lost my job, had a lot of financial problems and I was broken. I didn't know what to do next, where to start. To cut it short I thought of going for some spiritual guidance and ended up downloading Findastro. Even though I am not a complete believer of these things, I think it happened for the best. I had a consultation with guruji and I must say it was an eye-opener. I will say it was more of psychological counseling along with astrology. He was so polite and positive; he listened to me patiently and consulted very genuinely. After that consultation, I have decided not to give up. Thank you Findastro!",
      Name: "Someone",
      Place: "Patna",
    },
    {
      Testimonial:
        "Are you guys for real???? Yesterday I was feeling really low about my life and contemplating various things at 3:00 in the morning and I wanted a consultation so desperately. I couldn’t find anyone online in other apps, but in Findastro there were Astrologers available!!! Congratulation on your team’s dedication. The consultation was satisfactory. I think I'll consult again but during day time :)",
      Name: "Rahul",
      Place: "Gurgaon",
    },
    {
      Testimonial:
        "I have consulted with many astrologers by visiting them as well as online. So far in Findastro, I did 5-6 consultations, I’ll say your astrologers are very professional, polite and experienced. They don't take too much time to give the predictions. Every time after the consultation I am completely satisfied with your service and best wishes. It would be good if you guys add some bonus points to us after multiple consultations.",
      Name: "Ajay",
      Place: "Hyderabad",
    },
    {
      Testimonial:
        "You guys are just awesome ;) I had a concern in my consultation (my call got disconnected in between and later the astrologer went offline) so I mailed my concern to the support team and within 5 minutes I got a call back from the team asking about my concern and arranged a call back immediately. Your team is too good!",
      Name: "Athira",
      Place: "Trivandrum",
    },
    {
      Testimonial:
        "I’m a primary school teacher and I have been a regular customer of Findastro for some time. I liked Findastro because your astrologers are always available (also the offers and discounts hahaha) I’m also very much impressed with your customer support. A few times I got payment issues with my credit card and each time they were so helpful. Thank you for your patience.",
      Name: "Ananya",
      Place: "Chennai",
    },
    {
      Testimonial:
        "I’ll be honest.. I never thought an astrologer consultation can bring some peace of mind and confidence! I was very worried about some personal problems and very stressed out, a couple of days back my friend’s mother suggested me casually that I have a consultation to know when I will be able to resolve it and be peaceful again. I did my consultation and was really happy with it. I think mothers always know what’s best. :)",
      Name: "Nimisha",
      Place: "Pune",
    },
    {
      Testimonial:
        "Hmm this is what I noticed about Findastro… Extremely experienced Astrologers are charging very less!!! I was worried about my exams and all and was really tense. But I didn’t consider having a consultancy because I didn't have much money with me…. I saw Findastro ad with some discount for new users, which really helped! But after going through the application I also realised that it's very much affordable otherwise too. Thank you for making it so affordable…",
      Name: "Arpit",
      Place: "Noida",
    },
    {
      Testimonial:
        "Guys, you have the best list of astrologers and consulting them was very easy! Thank you for those free 5 minutes :) mam, your predictions were so accurate!!! You are my fav now. I wish Findastro all the best.",
      Name: "Supriya",
      Place: "Bhopal",
    },
    {
      Testimonial:
        "Thank you guruji for helping me to get clarity in life. I couldn't make any sense of what was happening to me and felt cornered before. Thank you again guruji. I wish you had video consultations too.",
      Name: "Lokesh",
      Place: "Pune",
    },
    {
      Testimonial:
        "Never believed in astrology before, now I do!!! Sir, u gave me the exact career guidance during these covid-related uncertainties. Thank u for making me aware of where I needed to apply focus and within a month I got the results! unbelievable. In short @ 15/minute, I got my life back. Thank you Findastro :)",
      Name: "Ajith",
      Place: "Bengaluru",
    },
    {
      Testimonial:
        "I was sceptical about the online astrologer consulting till I had the first consultation in Findastro. u guys are really good…! I’m a regular customer here, each rupee I spent was worth spending and I am glad I did not uninstall the app. Findastro, u are doing a great service… best wishes…",
      Name: "Michale",
      Place: "Kottayam",
    },
    {
      Testimonial:
        "We were having some troubles at home. Unexpected expenses kept derailing our financial planning and then the frustration. As per my wife’s wish, we decided to consult an astrologer online and discovered your app. Guruji was a complete professional. He did not try to soothe us, instead, he presented us with solutions, which we followed. I’m a regular customer here, can I get some membership?",
      Name: "Nitin",
      Place: "Noida",
    },
    {
      Testimonial:
        "Thank you Sir for taking me out of the trouble I was in!!!! Because of you now the marriage of my daughter went well, just like you predicted. Thank you sir againnnn and thank you findastro.",
      Name: "Kavitha",
      Place: "Chennai",
    },
    {
      Testimonial:
        "Sir, you told me to have faith in myself and forget about the past. You also told me I'll transfer to the south. I didn't expect it to be in Kerala. a really nice place, so no worries :) I’ll consult you again sir. findastro, can you include more astrologers in Assamese?",
      Name: "Bhupen",
      Place: "Delhi",
    },
    {
      Testimonial:
        "The rate was Rs 45/minute I thought was a little unaffordable but totally worth it! mam listened to my experience and told me to get creative while looking at life. I did and made a switch in my career. Now a budding entrepreneur. I think it was the right choice, now I’m peaceful!",
      Name: "Rithika",
      Place: "Mandi",
    },
  ],
};

export default TestimonialData;
