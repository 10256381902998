import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import OpenAstrologerProfile from "./OpenAstrologerProfile";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
})
const AppDynamicNotificationDirect = () => {
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const defaultRedirect = setTimeout(() => {
      openURL('/')
    }, 5000)
    const handleEvent = (event) => {
      if (event?.detail?.task == 'openchatorprofile' && event?.detail?.astrologer) {
        OpenAstrologerProfile({
          astrologerID: event.detail.astrologer,
          task: event.detail.task,
          openURL
        })
      }
    }
    window.addEventListener('appDynamicNotification', handleEvent)
    return () => {
      clearTimeout(defaultRedirect)
    }
  }, [])

  const openURL = (url = '/') => {
    history.push(url)
  }

  return (
    <div
      className={classes.container}
    >
      <CircularProgress />
    </div>
  )
}

export default AppDynamicNotificationDirect