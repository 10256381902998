import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  notFoundWrap: {
    textAlign: "center",
    position: "absolute",
    marginRight: "10px",
    top: "30%",
    marginLeft: "10px",
  },
  notFoundTitle: {
    fontSize: "2.4em",
    color: "#ff5e00",
  },
  notFoundText: {
    fontSize: "1em",
  },
  notfoundButton: {
    color: "white",
    marginBottom: "10px",
    width: "90%",
    marginTop: "30px",
    borderRadius: "40px",
    padding: "10px",
    background: "#f56307",
    fontWeight: "bold",
  },
});

export default function NotFound() {
  const classes = useStyles();

  const onGoBack = () => {
    window.location.href = "/";
  };

  return (
    <div className={classes.notFoundWrap}>
      <h3 className={classes.notFoundTitle}>
        {window.location.pathname === "/cookies-disabled" ? (
          "COOKIES DISABLED"
        ) : (
          <>
            404
            <br />
            page not found
          </>
        )}
      </h3>
      <p className={classes.notFoundText}>
        {window.location.pathname === "/cookies-disabled"
          ? "To continue browsing on Findastro, please enable cookies in your Web browser."
          : "We are sorry but the page/link you are trying is either broken or does not exist! Please recheck."}
      </p>
      <Button className={classes.notfoundButton} variant="contained" onClick={onGoBack}>
        Go to home page
      </Button>
    </div>
  );
}
