import Moment from "moment";
import axios from "../../../../../common/interceptor";

const getURLParams = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const MAX_INACTIVITY_SEC = 1 * 60;

const initChat = async (id) => {
  return new Promise((resolve, reject) => {
    axios()
      .post("/user/chat/init", { receiver: id }) // user create, astrolger create, user logged in, get conversation with the astrologer.
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProfileUpdates = async () => {
  return new Promise((resolve, reject) => {
    axios()
      .post("/profile/updates") // user create, astrolger create, user logged in, get conversation with the astrologer.
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const notifyChat = async (id) => {
  return new Promise((resolve, reject) => {
    axios()
      .post("/user/chat/notify-astrologer", { consult_id: id }) // send call notificaton for astrologer
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getChatUserID = async () => {
  return new Promise((resolve, reject) => {
    axios()
      .post("/user/chat/chat-user-id") // user create, astrolger create, user logged in, get conversation with the astrologer.
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ConsolutationRequests = {
  cancel: async (chatID) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/cancel", {
          chatID,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  forceEnd: async (chatID) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/end", {
          chatID,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  latestChat: async (chatID) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/latest-chat-info", {
          chatID,
        })
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  pushNotificationData: async (params) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/push-notification-info", params)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const ChatMessages = {
  listPreviousConversations: async (params) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/previous-chat", params)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listConversations: async (params) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/list", params)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  listOneToOneConversations: async (params) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/list", params)
        .then((res) => {
          if (res.data.success) {
            console.log('/user/chat/list:', res)
            resolve({
              messages: res.data.messages,
              astrologer: res.data.receiver,
              user: res.data.sender,
              conversation: {
                id: res.data.lastConsultation.id,
                status: res.data.lastConsultation.status,
                initiated_utc: res.data.initiated_at_utc,
                initiated_duration: res.data.initiated_duration,
                current_time_utc: res.data.current_time_utc,
              },
            });
          } else {
            resolve({});
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  send: async (params) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/save", { ...params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const updateLatestMessage = async (chatID, message) => {
  let params = {
    message,
    chatID,
  };
  return await ChatMessages.send(params);
};

const listPreviousConversation = async (params) => {
  const list = await ChatMessages.listPreviousConversations(params);
  // console.log("prev-cov-list", list);
  return list;
};

const listConversations = async () => {
  let params = {
    endTime: Date.now(),
  };
  const list = await ChatMessages.listConversations(params);
  // console.log("cov-list", list);
  return list;
};

const listOneToOneConversations = async (chatID, includeMessages) => {
  let params = {
    endTime: Date.now(),
    chatID: chatID,
    includeMessages: includeMessages,
  };
  // console.log("one to one fn call", params);
  const list = await ChatMessages.listOneToOneConversations(params);
  // console.log("single-conv-msgs", list);
  return list;
};

const displayDateTime = (timestamp) => {
  return Moment(timestamp).format("h:mm a");
};

// const prepareSingleMessage = (messageBox, userID, chatID) => {
//   const msg = messageBox.current.value;
//   sendMessage(msg);
//   const sentMessage = {
//     body: {
//       text: msg,
//     },
//     created: Moment.utc().format(), // "2022-02-10T06:02:22.348Z", //new Date(Date.now()),
//     from: userID,
//     id: Date.now(),
//     timestamp: Date.now(),
//     to: chatID,
//   };
//   return sentMessage;
// };

const prepareSingleMessage = (msg, userID, chatID, extra = {}) => {
  const sentMessage = {
    body: {
      text: msg,
      extra
    },
    created: Moment.utc().format(), // "2022-02-10T06:02:22.348Z", //new Date(Date.now()),
    from: userID,
    id: Date.now(),
    timestamp: Date.now(),
    to: chatID,
  };
  return sentMessage;
};

const JSONMessage = {
  send: ({ chatClient, chatID, sender, message }) => {
    const defaultMessage = {
      action: 'in-json',
      text: '',
      time: Moment.utc().format(),
      time_utc: Date.now(),
      type: "info",
      isJSON: true,
      visibility: "both",
    }
    const botMessage = JSON.stringify({ ...defaultMessage, ...message })
    const preparedSingleMessage = prepareSingleMessage(botMessage, `${sender}`, chatID)
    sendMessage(chatClient, chatID, botMessage)
    return preparedSingleMessage;
  },
  isJSON: (str) => {
    try {
      const obj = JSON.parse(str)
      return obj
    } catch (e) {
      return false
    }
  },
  parse: (msg) => {
    const jsonObject = JSONMessage.isJSON(msg.body.text)
    if (jsonObject) {
      if (jsonObject.isJSON) {
        msg.body.text = jsonObject
        msg.from = 'bot'
      }
    }
    return msg
  }
}

/*
  instant-payment-open,
  instant-payment-recharge-click,
  instant-payment-success,
  instant-payment-failed,
  instant-payment-initializing-chat,
  instant-payment-timeout
*/
const BotMessage = {
  send: (chatID, key) => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/user/chat/notify-bot", { chatID, templateName: key })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

const sendMessage = async (chatClient, chatID, msg) => {
  const latestMessageResponse = await updateLatestMessage(chatID, msg);

  if (latestMessageResponse.success) {
    const message = {
      to: chatID, // muc
      body: {
        text: msg,
      },
      timestamp: latestMessageResponse.current_time_utc,
    };
    await chatClient?.sendMessage(message);
    return msg;
  } else {
    return false;
  }
};
const sendMessageTyping = async (chatClient, chatID, msg) => {
  const message = {
    to: chatID, // muc
    body: {
      text: msg,
    },
    timestamp: Date.now(),
    typing: true,
  };
  await chatClient?.typingMessage(message);
};

const loadMessages = async (chatClient, chatID) => {
  const messages = await chatClient?.loadArchive(chatID, 1000);
  // console.log("messages: ", messages);
  return messages.reverse();
};

const getChatServerConfig = () => {
  switch (window.location.host) {
    case "findastro.com":
    case "www.findastro.com":
      return {
        host: "https://chat.findastro.com",
        port: "443",
      };
      break;
    case "findastro.co":
    case "www.findastro.co":
      return {
        host: "https://chat.findastro.co",
        port: "443",
      };
      break;
    case "dev.findastro.co":
      return {
        host: "https://devchat.findastro.co",
        port: "443",
      };
      break;

    case "localhost:3000":
    case "localhost:3001":
    case "cot.local:3000":
    case "cot.local:3001":
      return {
        host: "https://devchat.findastro.co",
        port: "443",
      };
      break;

    default:
      window.location.href = "https://findastro.com/no-access";
      break;
  }
};

const getDeviceSyncTimeUTC = (inputUTC, currentServerUTC) => {
  const currentTimeDevice = new Date().valueOf();
  const timeDiffDeviceServer = currentServerUTC - currentTimeDevice;
  // console.log("devic_dif_utc", timeDiffDeviceServer);
  const result = inputUTC - timeDiffDeviceServer;
  // console.log("result_utc", result);
  // console.log("device_utc", currentTimeDevice);

  return result;
};

const messageComponent = {
  insert: ({ name, astrologerID }) => {
    return {
      id: 'component_' + Date.now(),
      type: 'component',
      name,
      astrologerID,
      body: {
        text: ''
      }
    }
  }
}

export {
  listConversations,
  listOneToOneConversations,
  displayDateTime,
  initChat,
  updateLatestMessage,
  MAX_INACTIVITY_SEC,
  prepareSingleMessage,
  loadMessages,
  sendMessage,
  getURLParams,
  getChatUserID,
  ConsolutationRequests,
  sendMessageTyping,
  listPreviousConversation,
  getChatServerConfig,
  getDeviceSyncTimeUTC,
  JSONMessage,
  messageComponent,
  BotMessage,
  notifyChat,
  getProfileUpdates
};
