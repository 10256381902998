import React, {useEffect, useRef, useState} from "react";
import ArrowIcon from "@material-ui/icons/ArrowBack";
import axios from "../../../common/interceptor";
import SearchIcon from "@material-ui/icons/Search";
import {Dialog, Grid, InputBase} from "@material-ui/core";
import moengage from "../../../common/moengage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

const SearchPuja = (props) => {
  const [pujaTerm, setPujaTerm] = useState("");
  const [searchDone, setSearchDone] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const searchBox = useRef();

  const hashChangeHandler = function () {
    if (!["#searchpuja", "#pujadetails", "#closeprofilepopup"].includes(window.location.hash)) {
      goBack();
    }
  };
  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler);
    return function () {
      window.removeEventListener("hashchange", hashChangeHandler, true);
    };
  }, []);

  useEffect(() => {
    const txt = searchBox.current.querySelector("input");
    txt && txt.focus();
  }, []);

  const PujaComponent = props.PujaComponent;
  const selectPuja = props.selectPuja;

  const goBack = (e) => {
    props.closeSearch();
  };

  const backPressed = () => {
    window.history.back();
  };

  const trackClick = () => {
    if (pujaTerm.length > 2) {
      const type = "fa_remedy_search";
      moengage(type);
      const payload = {
        p_screen: "remedy_search",
        p_label: "NA",
        p_content: pujaTerm,
        p_value: 0,
        value: 0,
        currency: "INR",
        p_ast_exp: "NA",
        p_ast_rating: "NA",
        p_ast_sort: "NA",
        p_ast_rank: "NA",
        p_ast_fees: "NA",
        p_usr_gender: BrowserUtils.CurrentUser().gender,
        p_usr_age: BrowserUtils.CurrentUser().age.toString(),
        p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
      };
      window.FAEventLog(type, payload);
    }
  };

  const onSearchText = () => {
    trackClick();
    axios()
      .post("/puja/search", {search: pujaTerm})
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) setSearchResults(res.data.data);
          else setSearchResults(null);
          setSearchDone(true);
        } else {
          props.showError("Unable to get puja/havan details");
        }
      })
      .catch((err) => {
        props.showError("Unable to get puja/havan details");
      });
  };

  return (
    <div className="puja-dialog dialog type-puja" style={{backgroundColor: "#fbead0", width: "101vw"}}>
      <div className="title" style={{backgroundColor: "#ee600d"}}>
        <ArrowIcon onClick={backPressed} />
        &nbsp;&nbsp;Search Remedies
      </div>
      <Grid container className="searchWrap" style={{marginTop: 64, padding: "24px 8px 8px 8px"}}>
        <Grid item xs={11} className="searchInput white">
          <InputBase
            value={pujaTerm}
            style={{width: "calc(100% - 16px)", height: 40}}
            placeholder="Search puja/havan by name, category, ID, etc."
            onChange={(e) => setPujaTerm(e.target.value)}
            ref={searchBox}
            onKeyUp={(ev) => {
              setSearchDone(false);
              if (ev.key === "Enter") {
                onSearchText();
              }
            }}
          />
        </Grid>
        <Grid item xs={1} className="searchIcon white">
          <SearchIcon onClick={onSearchText} />
        </Grid>
      </Grid>
      <div style={{padding: "0 12px 0 12px", height: "calc(100% - 124px)", overflowY: "auto"}}>
        <div className="section-title" style={{paddingTop: 8, textTransform: "none"}}>
          {pujaTerm.length > 2 && searchDone && searchResults && searchResults.length > 0 && (
            <b>Search results for "{pujaTerm}"</b>
          )}
        </div>
        {searchDone &&
          searchResults &&
          searchResults.map((puja) => <PujaComponent key={puja.product_id} data={puja} selectPuja={selectPuja} />)}
        {pujaTerm.length > 2 && searchDone && (!searchResults || searchResults.length === 0) && (
          <div className="section-title" style={{paddingTop: 8, textTransform: "none"}}>
            <b>No remedies found for "{pujaTerm}"</b>
          </div>
        )}
        <div>&nbsp;</div>
      </div>
    </div>
  );
};
export default SearchPuja;
