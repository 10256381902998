import React, {useState, useEffect, makeStyles} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import {withRouter} from "react-router-dom";

import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import QueryString from "../../../common/util/QueryString";
import classes from "../css/PaymentLink.module.css";

import {ArrowBack} from "@material-ui/icons";
import ModalComponent from "./WalletModal.component";

const PayLinkThanks = (props) => {
  const [name, setName] = useState(localStorage["name"]);
  const [phone, setPhone] = useState(localStorage["phone"]);
  const [isLoading, setIsLoading] = useState(true);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [status, setPaymentStatus] = useState(null);
  const [orderId, setOrderId] = useState("");
  const [rechargeValue, setRechargeValue] = useState(null);

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  const clearHistory = (url) => {
    window.history.replaceState(null, "", "/");
  };

  const goBack = () => {
    window.location.replace("/");
  };

  useEffect(() => {
    setIsLoading(false);
    verifyTransaction(props.match.params.id, QueryString().t);
  }, []);

  const verifyTransaction = (id, token) => {
    setIsLoading(true);

    axios()
      .post("/payment-link/get-details", {
        t_id: id,
        token: token,
      })
      .then((res) => {
        //console.log("arun", res);
        setIsLoading(false);
        if (res.data.success) {
          setPaymentStatus("success");
          setModalOpen(true);
          setRechargeValue(res.data.recharge_points);
        } else {
          setPaymentStatus("failed");
          setModalOpen(true);
        }
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_CANCEL", err);
      });
  };

  return (
    <div style={{backgroundColor: "#fbead0", minHeight: "100vh", paddingBottom: 0}}>
      <HelmetComponent
        title="My Wallet at Findastro"
        url="https://www.findastro.com/my-wallet"
        description="My Wallet at Findastro- Connect to your Favorite Astrologer at Findastro"
      />
      {isLoading ? (
        <div className="circle-loading" style={{transform: "translateX(20px)"}}>
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}

      <div className={classes.header}>
        <div className={classes.navbar}>
          <ArrowBack
            onClick={(e) => {
              window.location.replace("/");
            }}
          />
          <h2 className={classes.title}>Special Recharge Offer - {status}</h2>
        </div>
      </div>
      {/* <div className={classes.offerDetails}></div> */}
      <ModalComponent
        modalOpen={modalOpen}
        status={status}
        orderId={orderId}
        resetHistory={clearHistory}
        goBack={goBack}
        rechargeValue={rechargeValue}
        targetURI="/"
      />

      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBar.message}
        closeError={closeError}
        open={snackBar.open}></Snackbar>
    </div>
  );
};

export default withRouter(PayLinkThanks);
