const EndMessages = [
    `Did it get disconnected? There's more to discuss. Please reconnect.`,
    `Looks like it got disconnected. Please reconnect.`,
    `Sorry, did we get disconnected. Please try to reconnect. `,
    `Can you please reconnect? We have more to chat about.`,
    `I think we got disconnected. Please try again.`,
    `Oh, is the balance over? Please reconnect. There is more to discuss.`,
    `Disconnected? Please check your balance. 
    Can you reconnect?`,
]

export default EndMessages