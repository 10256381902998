import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { StarRounded } from "@material-ui/icons";

const useStyles = makeStyles({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.3rem",
    fontSize: "1rem",
    gap: "0.15rem",
    fontWeight: 700,
    color: '#2f3148',
    marginRight: -5,
  },
  icon: {
    color: 'var(--clr-yellow)',
    position: 'relative',
    top: '0.08rem',
  },
  text: {
    // width: 26,
    // textAlign: 'center',
  }
})
const RatingStar = ({ value }) => {
  const classes = useStyles()
  return (
    <div className={clsx('rating-bar', classes.main)}>

      <div className={clsx(classes.text)}>{value}</div>
      <div className={clsx(classes.icon)}><StarRounded className={clsx(classes.iconSVG)} /></div>
    </div>
  )
}

export default RatingStar