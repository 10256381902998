/**
 * It's a common component for different
 * daily predicitons like sookshma, manthra, muhurtha, etc
 *  */
import React, {useState, useEffect, useRef} from "react";
import md5 from "md5";
import "./dailyHoroscope.css";
import {format, parse} from "date-fns";
import {drawChart} from "./avDrawChartUtf";
import compass from "./compass.png";
import compassNeedle from "./compass_needle.png";
import noImage from "./gods/nogod.png";
import separator from "./separator.png";
import RoomIcon from "@material-ui/icons/Room";
import BrowserUtils from "./UAScripts";

const CommonPredictionWidget = (props) => {
  //
  const signKey = md5(new Date().toDateString());
  //  const userCRJ = JSON.parse(localStorage["userCRJ"] || "{}");
  const globalTrackEvent = props.trackEvent;

  const strToDate = (date, format) => {
    try {
      return parse(date, format || "yyyy-MM-dd HH:mm:ss", new Date());
    } catch (err) {
      return null;
    }
  };

  const predictionWidgetByType = (type) => {
    switch (type) {
      case "chart":
        return <ChartComponent data={props.data} id={props.id} />;
      case "sookshma":
        return <SookshmaComponent data={props.data} id={props.id} />;
      case "numerology":
        return <NumerologyComponent data={props.data} id={props.id} />;
      case "panchanga":
        return <PanchangaComponent data={props.data} id={props.id} />;
      case "mantra":
        return <MantraComponent data={props.data} id={props.id} />;
      case "muhurtha":
        return <MuhurthaComponent data={props.data} id={props.id} />;
      default:
        return <div className="no-prediction">Nothing to show</div>;
    }
  };

  const predictionTitleByType = (type) => {
    switch (type) {
      case "chart":
        return (
          <div className="title-block">
            <span className="title">
              Today's chart <small>({format(new Date(), "dd MMM")})</small>
            </span>
            <img src={separator} alt="separator" className="separator" />
          </div>
        );
      case "sookshma":
        return (
          <div className="title-block">
            <span className="title">
              Dasha and Apahara predictions <small>({format(new Date(), "dd MMM")})</small>
            </span>
            <img src={separator} alt="separator" className="separator" />
          </div>
        );
      case "numerology":
        return (
          <div className="title-block">
            <span className="title">
              Today's Numerology predictions <small>({format(new Date(), "dd MMM")})</small>
            </span>
            <img src={separator} alt="separator" className="separator" />
          </div>
        );
      case "panchanga":
        return (
          <div className="title-block">
            <span className="title">
              Today's panchanga <small>({format(new Date(), "dd MMM")})</small>
            </span>
            <img src={separator} alt="separator" className="separator" />
          </div>
        );
      case "mantra":
        return (
          <div className="title-block">
            <span className="title">
              Today's mantra <small>({format(new Date(), "dd MMM")})</small>
            </span>
            <img src={separator} alt="separator" className="separator" />
          </div>
        );
      case "muhurtha":
        return (
          <div className="title-block">
            <span className="title">
              Today's muhurtha <small>({format(new Date(), "dd MMM")})</small>
            </span>
            <img src={separator} alt="separator" className="separator" />
          </div>
        );
      default:
        return <div>&nbsp;</div>;
    }
  };

  const MuhurthaComponent = (props) => {
    const compId = "__muhurtha-" + Date.now();
    const current = Object.keys(props.data[signKey].Prediction)[0];
    const predictionData = props.data[signKey].Prediction[current].Muhurtha;
    const consolidatePredictionData = (data) => {
      const result = {};
      data.map((muhurtha) => {
        result[muhurtha.MType] = result[muhurtha.MType] || [];
        result[muhurtha.MType].push(muhurtha);
        return false;
      });
      return result;
    };

    const consolidatedData = consolidatePredictionData(predictionData);
    const isElapsed = (time) => {
      const ref = strToDate("2001-01-01 " + format(new Date(), "HH:mm:ss"));
      const pred = strToDate("2001-01-01 " + time, "yyyy-MM-dd h:mm a");
      return ref && pred && ref.getTime() > pred.getTime();
    };

    return (
      <div className={"muhurtha" + (Object.keys(consolidatedData).length > 0 ? "" : " no-prediction")}>
        <div id={compId} className="sign-block">
          <div>
            {Object.keys(consolidatedData).length > 0 ? (
              Object.keys(consolidatedData).map((key) => (
                <div className="muhurtha-row">
                  <div>
                    <b>{key} :</b>
                  </div>
                  {consolidatedData[key].map((muhurtha) => (
                    <div className={isElapsed(muhurtha.MEnd) ? "elapsed" : ""}>
                      {muhurtha.MStart} to {muhurtha.MEnd}
                      <small>{isElapsed(muhurtha.MEnd) ? " (time elapsed)" : ""}</small>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div className="muhurtha-row no-prediction">
                <div align="center">
                  <b>No muhurtha found!</b>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const MantraComponent = (props) => {
    const compId = "__mantra-" + Date.now();
    const current = Object.keys(props.data[signKey].Prediction)[0];
    const predictionData = props.data[signKey].Prediction[current];
    const mantraConfig = {
      Deity: "Deity",
      DietyMantraSmall: "Short mantra",
      DietyMantraBig: "Long mantra",
    };

    const getImageResource = (lord) => {
      const image = lord.toLowerCase().split(" ").join("-") + ".jpg";
      try {
        return require(`./gods/${image}`).default;
      } catch (err) {
        return noImage;
      }
    };

    return (
      <div className="mantra">
        <div id={compId} className="sign-block">
          <div>
            {Object.keys(mantraConfig).map((key) => {
              return (
                <>
                  <li className={key.toLowerCase()}>
                    <b>{mantraConfig[key]} :</b>
                    <span>{predictionData[key]}</span>
                  </li>
                  {mantraConfig[key] === "Deity" && (
                    <li className={key.toLowerCase() + "-image"}>
                      <img src={getImageResource(predictionData[key])} alt="lord" className="lord" />
                    </li>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const PanchangaComponent = (props) => {
    const compId = "__panchanga-" + Date.now();
    const current = Object.keys(props.data[signKey].Prediction)[0];
    const predictionData = props.data[signKey].Prediction[current];
    const panchangaConfig = {
      /* pob: "Location", */
      Day: "Day",
      DOW: "Week",
      Month: "Month",
      Year: "Year",
      Star: "Star",
      Tithi: "Tithi",
      RaahuKaalStart: "Rahu kaal start",
      RaahuKaalEnd: "Rahu kaal end",
      AbhijitStart: "Abhijit start",
      AbhijitEnd: "Abhijit end",
      DiaryFirstPrediction: "Today's prediciton",
    };

    return (
      <div className="panchanga">
        <div id={compId} className="sign-block">
          {/* <div className="location">
               <RoomIcon />
               <span>{localStorage["pob"]}</span>
             </div> */}
          <div>
            {Object.keys(panchangaConfig).map((key) => {
              return panchangaConfig[key] === "Location" ? (
                <li className={key.toLowerCase()}>
                  <b>{panchangaConfig[key]}</b>:<span>{localStorage["pob"]}</span>
                </li>
              ) : (
                <li className={key.toLowerCase()}>
                  <b>{panchangaConfig[key]}</b>:<span>{predictionData[key]}</span>
                </li>
              );
            })}
            <div className={"pob"}>
              <span>This panchanga is prepared for: </span>
              <br />
              <b>{localStorage["pob"]}</b>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const NumerologyComponent = (props) => {
    const compId = "__numerology-" + Date.now();
    const current = Object.keys(props.data[signKey].Prediction)[0];
    const predictionData = props.data[signKey].Prediction[current];
    const LuckyColors = predictionData.LuckyColor.split(",");
    const LuckyDirection = predictionData.LuckyDirection;
    const LuckyNumber = predictionData.LuckyNumber;
    const Direction = (props) => {
      return (
        <span className={"ldir " + props.value.toLowerCase()}>
          <img src={compass} className="compass" alt="compass" />
          <img src={compassNeedle} className="needle" alt="needle" />
        </span>
      );
    };
    const Colors = (props) => {
      return (
        <div className={"numerology colors"}>
          {props.value.map((color) => (
            <div className="box">
              <span className={"color " + color.toLowerCase()}></span>
              <span className="name">{color}</span>
            </div>
          ))}
        </div>
      );
    };
    return (
      <div>
        <div id={compId} className="sign-block">
          <div className="numerology-items">
            <li>
              <b>Lucky color(s):</b>
              <Colors value={LuckyColors} />
            </li>
            <li>
              <b>Lucky direction:</b>
              <Direction value={LuckyDirection} />
            </li>
            <li>
              <b>Lucky number:</b>
              <span className="lucky-number">{LuckyNumber}</span>
            </li>
          </div>
          <div>
            <p>{predictionData.NumerologyGuidelineForTheDay}</p>
          </div>
        </div>
      </div>
    );
  };

  const SookshmaComponent = (props) => {
    const compId = "__sookshma-" + Date.now();
    const getCurrent = (data) => {
      let current = null;
      data.map((sp) => {
        const date = strToDate(sp.End);
        if (date && date.getTime() > Date.now() && current == null) current = sp;
        return true;
      });
      return current;
    };
    const predictionData = getCurrent(props.data[signKey].SookshmaPrana.Result);
    return (
      <div
        className={
          parseInt(props.data[signKey].SookshmaPrana.Count) > 0 && predictionData ? "sookshma" : "no-prediction"
        }>
        {parseInt(props.data[signKey].SookshmaPrana.Count) > 0 && predictionData ? (
          <div id={compId} className="sign-block">
            <div className="timing-caption">
              This prediction is applicable from {format(strToDate(predictionData.Start), "MMMM dd, hh:mm a")} to{" "}
              {format(strToDate(predictionData.End), "MMMM dd, hh:mm a")}
            </div>
            <div>
              {predictionData.Prediction.split(". ").map((para) => (
                <p>{para}.</p>
              ))}
            </div>
          </div>
        ) : (
          <div id={compId} className="sign-block no-prediction">
            <div align="center">
              <b>Nothing to show!</b>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ChartComponent = (props) => {
    const compId = "__chart-" + Date.now();
    const chartComp = useRef();
    const [style, setStyle] = useState("SO");

    useEffect(() => {
      if (chartComp.current) {
        chartComp.current.childNodes[0] && chartComp.current.childNodes[0].remove();
        //console.log("falog", userCRJ);
        const current = Object.keys(props.data[signKey].Prediction)[0];
        const chartData = props.data[signKey].Prediction[current].Chart;
        const inputData = {
          ChartStyle: style,
          BorderStyle: "B_S1",
          Language: "ENG",
          Encoding: "ASCII",
          PlanetPos: {
            MOO: chartData.Moo,
            SUN: chartData.Sun,
            MER: chartData.Mer,
            VEN: chartData.Ven,
            MAR: chartData.Mar,
            JUP: chartData.Jup,
            SAT: chartData.Sat,
            RAH: chartData.Rah,
            KET: chartData.Ket,
            LAG: chartData.Lag,
            GUL: chartData.Gul,
            ARU: "",
          },
          Caption: [
            {Caption: chartData.Caption1, Style: "B", Color: "#00a"},
            {Caption: chartData.Caption2, Style: "N", Color: "#666"},
            {Caption: chartData.Caption3, Style: "N", Color: "#666"},
          ],
          Target: {ID: compId, width: 300, height: 300},
          ChartType: "PS",
        };
        //console.log("falog", drawChart);
        drawChart(inputData);
      }
    }, [chartComp, style]);
    // track events
    const trackChartSwitch = (type) => {
      const cs = {SO: "south", NIC: "north"};
      globalTrackEvent("fa_daily_pred_chart_switch", cs[type] || "NA");
    };
    //
    return (
      <div>
        <div id={compId} ref={chartComp} className="chart" align="center"></div>
        <div className="title-block">
          <span className="switch">
            <input id="switch-input" type="checkbox" checked={style === "SO"} />
            <label
              onClick={(e) => {
                trackChartSwitch("SO");
                setStyle("SO");
              }}>
              South Indian
            </label>
            <label
              onClick={(e) => {
                trackChartSwitch("NIC");
                setStyle("NIC");
              }}>
              North Indian
            </label>
          </span>
        </div>
      </div>
    );
  };

  const showPredicitonPopup = () => {
    const payload = {
      p_screen: "home",
      p_label: "NA",
      p_content: props.type,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog("fa_daily_pred_read_more", payload);
    props.onExpand();
  };

  const editProfile = () => {
    window.location.href = "/userprofile";
  };

  return (
    <div className="daily-placeholder">
      <div className="predictions">
        {predictionTitleByType(props.type)}
        {props.data[signKey] ? (
          <div className="content-block">
            {predictionWidgetByType(props.type)}
            <div className="read-more" onClick={showPredicitonPopup}>
              <span>Read more...</span>
            </div>
          </div>
        ) : (
          <div className="content-block dp-no-profile">
            <div className="sign-block">
              <div className="infobox">
                <div>Please complete your birth details to read your FREE daily horoscope</div>
                <div>
                  <button className="upd-bttn" onClick={editProfile}>
                    Update profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default CommonPredictionWidget;
