import React from "react";
import DealPurchaseConfig from "../../../../feature/deal-purchase/components/DealPurchaseConfig";
import { useRechargeRedirect } from "../RechargeLinkRedirect";
import { WalletUtils } from "../../../../../route/WalletComponent";

const MoengageTest = () => {

    const rechargeRedirect = useRechargeRedirect()
    const refData = rechargeRedirect.getAll()

    const moengageTrackEvent = () => {
        const eventData = {
            event_name: "Test EventTA",
            event_params: {
                "test_current_time": Date.now(),
                "test_event_data": "test event data",
            }
        };
        alert(JSON.stringify(eventData));
        if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
            window.AndroidBridge.call("pushMoeEvents", JSON.stringify(eventData));
        } else {
            if (typeof window.AndroidBridge === 'undefined') {
                alert("AndroidBridge is undefined");
            } else {
                alert("AndroidBridge is defined");
            }
        }
    }
    const moengageTrackUserData = () => {
        const userData = {
            "test_last_update_time": Date.now(),
            "test_user_data": "test user data",
        };
        alert(JSON.stringify(userData));
        if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
            window.AndroidBridge.call("pushMoeUserAttributes", JSON.stringify(userData));
        } else {
            if (typeof window.AndroidBridge === 'undefined') {
                alert("AndroidBridge is undefined");
            } else {
                alert("AndroidBridge is defined");
            }
        }
    }

    const clearWebViewCache = () => {
        if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {

            // generate a random text to clear the webview cache
            const randomText = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

            window.AndroidBridge.call("clearWebViewCache", "CWC-IN-" + randomText);
        }
    }

    const getLatestPurchaseDetails = () => {
        if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
            window.AndroidBridge.call("getLatestPurchaseDetails");
        }
    }

    const enableNativePayment = () => {
        localStorage.setItem('enableNativePayment', 'true');
        localStorage.setItem('enableNewPayment', 'false');
        WalletUtils.setType('rz_native')
        clearWebViewCache("Enabled Native");
    }
    const disableNativePayment = () => {
        localStorage.setItem('enableNativePayment', 'false');
        WalletUtils.setType(WalletUtils.getDefaultType())
        clearWebViewCache("Disabled Native");
    }

    const enableNewPayment = () => {
        localStorage.setItem('enableNativePayment', 'false');
        localStorage.setItem('enableNewPayment', 'true');
        WalletUtils.setType('capg')
        clearWebViewCache("Enabled CAPG");
    }
    const disableNewPayment = () => {
        localStorage.setItem('enableNewPayment', 'false');
        WalletUtils.setType(WalletUtils.getDefaultType())
        clearWebViewCache("Disabled CAPG");
    }

    const enableDealPurchase = () => {
        localStorage.setItem('enableDealPurchase', 'true');
        clearWebViewCache("Enabled DealPurchase");
    }
    const disableDealPurchase = () => {
        localStorage.setItem('enableDealPurchase', 'false');
        clearWebViewCache("Enabled DealPurchase");
    }

    const enableNativePaymentForDealPurchase = () => {
        localStorage.setItem('enableNativePaymentForDealPurchase', 'true');
        clearWebViewCache("Enabled DealPurchase payment");
    }
    const disableNativePaymentForDealPurchase = () => {
        localStorage.setItem('enableNativePaymentForDealPurchase', 'false');
        clearWebViewCache("Enabled DealPurchase payment");
    }

    const testBranchEvent = (eventType) => {

        if (typeof window.AndroidBridge !== 'undefined' && typeof window.AndroidBridge.call !== 'undefined') {
            const randomText = "TA0004004044" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            alert(eventType + " | " + randomText)
            const payload = {
                eventType: eventType,
                affiliation: "all purchase",
                value: "239.00",
                currency: "INR",
                shipping: "first purchase",
                tax: "0",
                sku: "subscription_249",
                quantity: "1",
                transactionId: randomText,
                coupon: "NA",
                actionType: "purchase",
            }
            window.AndroidBridge.call("postBranchEvent", JSON.stringify(payload));
        } else {
            alert("AndroidBridge is undefined");
        }

    }

    return (
        <div>
            <button onClick={moengageTrackEvent}>Track Event</button>
            <button onClick={moengageTrackUserData}>Track User Data</button>
            <button onClick={clearWebViewCache}>clearWebViewCache</button>
            <button onClick={getLatestPurchaseDetails}>getLatestPurchaseDetails</button>
            {localStorage.getItem('enableNativePayment') === 'true' ?
                <button onClick={disableNativePayment}>disableNativePayment</button>
                :
                <button onClick={enableNativePayment}>enableNativePayment</button>
            }
            {localStorage.getItem('enableNewPayment') === 'true' ?
                <button onClick={disableNewPayment}>disableCAPGPayment</button>
                :
                <button onClick={enableNewPayment}>enableCAPGPayment</button>
            }
            {DealPurchaseConfig.is_enabled ?
                <button onClick={disableDealPurchase}>disableDealPurchase</button>
                :
                <button onClick={enableDealPurchase}>enableDealPurchase</button>
            }
            <button onClick={() => localStorage.setItem('app_google_email', '1')}>enableAppLogin</button>
            {/* {DealPurchaseConfig.paymentMethod({ refData }) === 'native' ?
                <button onClick={disableNativePaymentForDealPurchase}>disableNativePaymentForDealPurchase</button>
                :
                <button onClick={enableNativePaymentForDealPurchase}>enableNativePaymentForDealPurchase</button>
            } */}
            <button onClick={e => window.location.href = '/app/logout'}>User logout</button>
            <div>
                <h2>Branch</h2>
                <button onClick={() => testBranchEvent('COT_ALL_PURCHASE')}>COT_ALL_PURCHASE</button> <br />
                <button onClick={() => testBranchEvent('COT_DEAL_PURCHASE')}>COT_DEAL_PURCHASE</button> <br />
                <button onClick={() => testBranchEvent('COT_NONDEAL_PURCHASE')}>COT_NONDEAL_PURCHASE</button> <br />
                <button onClick={() => testBranchEvent('CA_ALL_PURCHASE')}>CA_ALL_PURCHASE</button> <br />
            </div>
        </div>
    );
}
export default MoengageTest;