import React from "react";
import { getURLParams } from "./ChatUtls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sessionKey } from "../../../../feature/app-direct/AppDirect";

const DirectLoadingByChatNotification = async ({
  profileLoad = () => { }, // Profile load callback
  setChatWindowData = () => { },
  history,
}) => {

  const redirectToListing = () => {
    history.push('/')
  }

  const sessionData = sessionStorage.getItem(sessionKey)
  // const history = useHistory()
  if (sessionData) {
    const pushData = JSON.parse(sessionData)
    if (pushData?.consultation?.chatID && pushData?.astrologer?.chatEnabled) {
      sessionStorage.removeItem(sessionKey)
      const chatInfo = {
        astrologer: pushData.astrologer.id,
        time: Date.now(),
        chatID: pushData.consultation.chatID,
        status: pushData.consultation.status,
      };
      setChatWindowData(chatInfo)
    } else if (pushData.astrologer.profileUrl) {
      profileLoad()
    } else {
      redirectToListing()
    }
  }
}

export default DirectLoadingByChatNotification