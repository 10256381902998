import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../../../common/interceptor";
import axios_noheader from "../../../../common/interceptor_noheader";
import sentryError from "../../../../common/sentryError";
import moengage from "../../../../common/moengage";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "react-device-detect";
import BrowserUtils from "./UAScripts.js";
import { VERSION, STATUS_API_PATH, MIN_SCROLL_TO_HIDE, SUPPORT_NUMBER } from "../../../../app.config";
import AstrologerUtils, { debounce, isChatEnabled } from "./AstrologerUtils";
import { ReactComponent as RefAndEarn } from "../../../../common/refer-and-earn.svg";
import ReferEarnModal from "../../../account/components/ReferEarnModal.component";
import clsx from "clsx";
import CloseIcon from '@material-ui/icons/Close';

import "./view/css/Astrologers-List-Updated.css";
import logo from "../../../../../src/common/findastro_logo.png";
import playstore from "../../../../../src/common/download_playstore.png";
import HelmetComponent from "../../../../common/HelmetComponent.component";
import Snackbar from "../../../../common/SnackBarMessage";
import SideDrawerComponent from "./SideDrawer.component";
import FilterDrawerComponent from "./FilterDrawer.component";
import SortDrawerComponent from "./SortDrawer.component";
import AstrologerCallDialog from "./AstrologerCallDialog.component";
import AppInstallBanner from "./AppInstallBanner.component";
import UpdateApp from "./UpdateApp.component";
import FA_localStorage from "../../../../common/util/FA_localStorage";
import IsSupported from "../../../../common/util/localStorageCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import Modal from "@material-ui/core/Modal";

import { Box, IconButton, Menu } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import FilterIcon from "@material-ui/icons/FilterList";
import FilterIconRound from "@material-ui/icons/CheckCircle";
import BookingIcon from "@material-ui/icons/Schedule";

import CallIcon from '@material-ui/icons/Call';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';

import Divider from "@material-ui/core/Divider";
/* import NotificationsIcon from "@material-ui/icons/Notifications"; */
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import TestimonialData from "../../../../common/TestimonialData";
import defaultImage from "../../../../common/default_profile_image.svg";
import Rating from "@material-ui/lab/Rating";
import Dialog from "@material-ui/core/Dialog";
import theme from "../../../../theme/App.theme";

import FlareIcon from "@material-ui/icons/Flare";
import GradeIcon from "@material-ui/icons/Grade";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Assistant from "@material-ui/icons/Assistant";
import { ReactComponent as OfferStamp } from "./offer.svg";
import astrologerListData from "../mock/Astrologers.mock";
import CouponRedeemDialog from "./CouponRedeemDialog.component";
import Button from "@material-ui/core/Button";
import { ReactComponent as TYBackdrop } from "../../../../common/tybackdrop.svg";
import { ReactComponent as FlashIcon } from "./flash.svg";
import BlastConfetti from "../../../misc-screens/components/Confetti.component";
import QueryString from "../../../../common/util/QueryString";
import DailyPredictionWidget from "./DailyPredictionWidget.component";
import AstrologerProfile from "./AstrologerProfile.component";
import AstrologerProfileNew from "./AstrologerProfile";
import FilterList from "./FilterView.component";
import ReferEarnPendingCallout from "../../../account/components/ReferEarnPendingCallout.component";
import { parse } from "date-fns";
import PujaList from "../../../account/components/PujaList.component";
import UserStartHere from "./UserStartHere.component";
import AstrologerCardComponent from "./AstrologerCardComponent.component";
import ChatPanel from "../../../account/components/ChatPanel.component";
import { ChatWindowStateProvider, ChatWindowStateSetterProvider } from "./chat/ChatWindowContext";
import ChatNotificationTrigger from "./chat/ChatNotificationTrigger";
import AstrologerDetail from "./view/AstrologerDetail";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Conversations from "./chat/Conversations";

import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getURLParams, initChat, notifyChat } from "./chat/ChatUtls";
import AstrologerLoadingModal from "./Modal/AstrologerLoadingModal";
import RechargeLinkRedirect, { useRechargeRedirect } from "./RechargeLinkRedirect";
import MoengageTest from "./dev/MoengageTest";
import T2AEventTrack from "../../../../common/T2AEventTrack";
import md5 from "md5";
import userProfileData from "../../../user-profile/mock/UserProfileData.mock";
import moment from "moment/moment";
import RechargeBanner from "./RechargeBanner";
import DealPurchaseDialog from "../../../feature/deal-purchase/components/DealPurchaseDialog.js";
import DealPurchaseBanner from "../../../feature/deal-purchase/components/DealPurchaseBanner.js";
import DealPurchaseConfig from "../../../feature/deal-purchase/components/DealPurchaseConfig.js";
import DealPurchaseUtils from "../../../feature/deal-purchase/components/DealPurchaseUtils.js";
import { WalletUtils } from "../../../../route/WalletComponent.js";

const useStyles = makeStyles({
  filterButton: {
    color: "#323232",
    padding: "2px 10px",
    borderRadius: "16px",
    backgroundColor: "#efefef",
    display: "inline-block",
    margin: "8px 4px 0 0",
    fontSize: "0.8rem",
    fontWeight: 400,
  },
  sectionHeading: {
    left: "0%",
    color: "#323232",
    margin: "10px 0 10px 0",
    display: "inline-block",
    position: "relative",
    textAlign: "center",
    fontWeight: "700",
    fontSize: "0.8rem",
    borderRadius: "12px",
    /* background: "#efefef", */
    padding: "4px 16px ",
    padding: "4px 0",
    width: "100%",
    "&:only-child": {
      display: "none",
    },
  },
  desktopSearchBar: {
    "& .MuiInputBase-root": {
      display: isMobile ? "inline-flex" : "block",
    },
  },
  searchInput: {
    width: "calc(100% - 16px)",
    height: 40,
  },
  container: {
    overflow: "auto",
    padding: "0px",
    margin: "0px",
    backgroundColor: "#f8ddb4",
    whiteSpace: "nowrap",
    maxHeight: "100vh",
    "& div": {
      // whiteSpace: "pre-wrap",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollSnapType: "x mandatory",
  },
  fullList: {
    width: "auto",
  },
  header: {
    padding: isMobile ? "10px 10px 50px 10px" : 10,
    backgroundColor: "var(--primary)",
    height: 72,
  },
  rounded: {
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  right: {
    float: "right",
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  wallet: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  headerWalletWrap: {
    justifyContent: "flex-end",
    display: "flex",
  },
  headerWalletSection: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    fontSize: "14px",
    color: "#ffffff",
    backgroundColor: "transparent",
    borderRadius: "4px",
    padding: "0 7px",
  },
  titleFilterIcons: {
    width: "auto",
    height: "30px",
    fontSize: "0.8rem",
    color: "white",
    backgroundColor: "transparent",
    padding: "0 5px",
    marginLeft: "8px",
  },
  color: {
    color: theme.palette.primary.main,
    marginTop: 15,
  },
  colorMobile: {
    color: theme.palette.primary.main,
    marginTop: "50px",
    position: "absolute",
    left: 0,
    paddingLeft: 10,
  },
  marTop5: {
    marginTop: 0,
    fontSize: "25px",
  },
  marTop5Mobile: {
    marginTop: "10px",
    fontSize: "20px",
    justifyContent: "start",
    display: "flex",
  },
  familyDiv: {
    /* marginRight: "10px",
    marginLeft: "10px", */
    marginBottom: "22px",
  },
  youmightalsolikeText: {
    textAlign: "center",
    color: "grey",
    padding: "0px 10px",
    fontWeight: "bold",
  },
  clearFilter: {
    color: "#ffeadf",
    padding: "5px 10px",
    fontSize: "16px",
    borderRadius: "3px",
    backgroundColor: theme.palette.primary.main,
    margin: "20px auto 0 auto",
    display: "inline-block",
  },
  desktopScroller: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "hidden !important",
  },
  desktopContainer: {
    maxWidth: 460,
    margin: "0 auto",
    backgroundColor: "#f8ddb4",
  },
  desktopTitleFilterIcons: {
    padding: "0px 20px",
    marginLeft: "20px",
    width: "auto",
    height: "30px",
    fontSize: "12px",
    backgroundColor: "transparent",
    color: "#ffffff",
  },
  desktopLogo: {
    height: "36px",
    marginTop: "8px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "14px",
  },
  mobileLogo: {
    height: "25px",
    marginLeft: isMobile ? -12 : 8,
    marginRight: isMobile ? 12 : 0,
  },
  desktopTopFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  desktopCustomerCare: {
    color: "black",
    marginTop: "12px",
    fontSize: 14,
  },
  footer: {
    height: "auto",
    display: "flex",
    lineHeight: "27px",
    justifyContent: "space-around",
    padding: "16px",
    backgroundColor: "#ffffff",
    whiteSpace: 'normal',
  },
  footerHeading: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 8,
  },
  footerDivider: {
    marginTop: 70,
  },
  copyright: { fontSize: 14, marginTop: "20% !important", marginBottom: "20px !important" },
  mobileFindYour: {
    color: "black",
    fontWeight: "normal",
  },
  activeCall: {
    "& small": {
      fontSize: "0.9rem",
    },
    "& small.failed": {
      color: "red",
      textTransform: "uppercase",
    },
    "& small.initiated": {
      color: "#006699",
      textTransform: "uppercase",
    },
    "& small.completed": {
      color: "#00c180",
      textTransform: "uppercase",
    },
    left: "50%",
    transform: "translate3d(-50%,0,16px)",
    width: "calc(100% - 18px)",
    bottom: "var(--footer-height)",
    padding: "8px",
    zIndex: "107",
    position: "fixed",
    maxWidth: "460px",
    background: "white",
    boxShadow: "0 0 6px rgb(0 0 0 / 50%)",
    borderRadius: "8px 8px 0 0",
  },
  rateusModal: {
    borderRadius: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fbead0",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    padding: "30px 20px",
    maxWidth: "450px",
    width: "90%",
    margin: "auto",
    textAlign: "center",
    outline: "none",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      borderRadius: 12,
    },
  },
  reWrap: {
    padding: "20px",
  },
  paperCenter: {
    textAlign: "center",
  },
  reMessage: {
    fontSize: "1.2rem",
  },
  reClaimButton: {
    backgroundColor: "#f56307",
    color: "white",
    fontWeight: "bold",
    width: "160px",
    boxShadow: "none",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f56307 !important",
    },
  },
  freeCnsltTopBnrImg: {
    width: "100%",
    margin: 0,
    borderRadius: 0,
    display: "block",
    // boxShadow: "0 0 0.5em rgba(0, 0, 0, 0.15)",
  },
  greyTopBnr: { display: "flex", justifyContent: "space-between", padding: "6px 16px", width: "100%", backgroundColor: "#4d4f65", color: "#fff", alignItems: "center" },
  greyTopBnrBtn: { padding: "5px 18px", backgroundColor: "#fff", color: "#e63934", borderRadius: "16px" },
  cancelButton: { color: "#d5cfcf", width: "30px" }
});

const headerOptMenuOptions = [
  ['Language Preference', '/my-favorite-languages', 'Language Preference Click'],
  ['Consultation History', '/user-consultation-history', 'Consultation History Click'],
];

const TalkToAstrologers = (props) => {
  const [testimonialId, setTestimonialId] = useState(false);
  const [regnOffer, setShowRegnOffer] = useState({});
  const [pendingCashbackOffer, setShowCashbackOffer] = useState({});

  const classes = useStyles();
  const [duplicatesInList, setduplicatesInList] = useState(false);
  const [category, setCategory] = useState("all");
  const [drawerOpen, setDrawerOpenState] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isOthersLoading, setOthersLoading] = useState(true);
  const [astProfileOpen, setAstProfileOpen] = useState(false);
  const [astProfileDataParams, setAstProfileData] = useState(null);
  const [langExpertiseMatrix, setLangExpertise] = useState(null);
  const [refAndEarnModelOpen, setRefAndEarnModelOpen] = useState(null);
  const [referEarnPendingCallout, setReferEarnPendingCallout] = useState(false);
  const [chatWindowData, setChatWindowData] = useState({});
  const [astrologerConsultLoading, setAstrologerConsultLoading] = useState({});
  const history = useHistory()
  const rechargeRedirect = useRechargeRedirect()
  const [trackEventsData, setTrackEventsData] = useState({})
  const [currentConsultType, setCurrentConsultType] = useState('chat')
  const [hasAstrologer, setHasAstrologer] = useState(0)
  const [hasRecharge, setHasRecharge] = useState({
    loaded: 0,
    count: 0
  })
  const [dealPurchase, setDealPurchase] = useState({
    open: false,
    deal: {},
    astrologer: {},
    type: 'chat',
    refData: {},
    have_deal_purchase: false,
    deals: [],
    isRepeatUser: false,
  })

  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1)
  }

  useEffect(() => {
    // if (window.AndroidBridge) {
    //   const getAppProfileData = window.AndroidBridge.call("getAppProfileData")
    //   alert(JSON.stringify({ function: 'AndroidBridge.getAppProfileData', data: getAppProfileData }))
    // }

    localStorage.setItem("strict_app_home", 1)

    // Set wallet default payment gateway
    WalletUtils.setType(WalletUtils.getDefaultType())

    // moengage('Talk To Astrologer Tab View')
    // moengage('Chat Tab View')
  }, [])

  useEffect(() => {
    handleRechargeRedirect()
  }, [window.location.search]) // handleRechargeRedirect should only be triggered when changes in the search occur

  const handleRechargeRedirect = () => {

    if (window.location.href.includes(`verify-number-by-url`)) {
      handleUnLoggedRedirect()
      return;
    }

    if (window.location.href.includes(`${rechargeRedirect.LS_KEY}=1`)) {
      closeAllModalPopups()
      if (rechargeRedirect.isValid() && rechargeRedirect.getItem('afterLogin')) {
        handleDealPurchaseNavigate()
      } else {
        handleRechargeRedirectNavigate()
      }
    }
  };

  const handleDealPurchaseNavigate = () => {
    setLoading(true)
    axios()
      .post("/profile/updates")
      .then((res) => {
        if (res && res.data.success) {
          setLoading(false)
          const refData = rechargeRedirect.getAll()
          const openDealPurchaseDialog = () => {
            delete refData.afterLogin
            setDealPurchase(deal => ({
              type: deal.type,
              open: true,
              deal: refData.recharge,
              refData,
              deals: res.data.data?.deals,
              isRepeatUser: res.data.data?.free_consult?.status === 'completed'
            }))
          }

          // Open deal purchase banner
          if (res.data.data?.deals?.length // to show the normal content to recharge the deal
            || res.data.data?.free_consult?.status === 'completed' // to show the user is already taken the free consult
          ) {
            openDealPurchaseDialog()
            setTimeout(() => { initialLoad() }, 500)
          }

          // Connect to Chat/Call if freeConsult is available
          else if (res.data.data?.free_consult?.status === 'available' && refData.astrologerID) {
            setLoading(true)
            axios()
              .post("/consultations/free-consult-status", { astrologerId: refData.astrologerID })
              .then((res) => {
                setLoading(false)
                // Connect to Chat/Call if freeConsult is available
                if (res && res.data.success && res.data?.freeConsult) {
                  delete refData.afterLogin
                  delete refData.dealPurchase
                  rechargeRedirect.setData(refData)
                  handleRechargeRedirectNavigate()
                }
                setTimeout(() => { initialLoad() }, 500)
              });
          } else {
            handleRechargeRedirectNavigate()
            setTimeout(() => { initialLoad() }, 500)
          }
        }
      })
  }

  const handleRechargeRedirectNavigate = () => {
    setLoading(true)
    if (!window.location.href.includes('#back-button--wallet-recharge')
      && rechargeRedirect.isValid()
      && rechargeRedirect.getItem('astrologerID')) {
      setLoading(false)
      const refData = rechargeRedirect.getAll()
      const onComplete = () => {
        const activeConsultationEle = document.getElementById('chat-active-consultation')
        if (activeConsultationEle && activeConsultationEle.getAttribute('data-astrologer-id') == refData.astrologerID) {
          activeConsultationEle.click()
        } else {
          continueChatCall(refData)
        }
      }
      setAstrologerConsultLoading({
        astrologer: refData,
        actionType: rechargeRedirect.getItem('type'),
        open: true,
        onComplete,
        modalId: refData.astrologerID
      })
    }
  }

  const handleUnLoggedRedirect = () => {
    const astrologerID = getURLParams('astrologerID')
    const task = getURLParams('task')
    if (astrologerID && task) {
      const data = {
        task,
        astrologerID,
        astrologer: astrologerID
      }
      setTimeout(() => { handleUnLogged(data) }, 1000)
    }
  }

  useEffect(() => {
    // console.log("chatWindowData", chatWindowData);
  }, [chatWindowData]);

  // Init chat from recharge redirect

  const continueChatCall = async (refData) => {
    let consultId = 0;
    let freeMinutes = 0;
    const astroInfo = await getAstrologerInfo(refData);
    console.log(refData)
    console.log('astroInfo for event', astroInfo)

    if (refData.type === 'call') {
      axios()
        .post("/consultations/call", {
          id: refData.astrologerID,
          mode: "audio",
          proceed_short_call: true,
        })
        .then((res) => {
          if (res.data.success) {
            const secondsSinceEpoch = Math.floor(Date.now() / 1000);
            consultId = res.data.data.id;
            freeMinutes = parseInt(astroInfo?.free_call_minutes || '0');

            FA_localStorage(
              "lastActiveConsultation",
              JSON.stringify({
                id: res.data.data.id,
                key: md5(res.data.data.id.toString()),
                token: res.data.data.token,
                status_token: res.data.data.status_token,
                astrologer: refData.astrologerName,
                astrologerUrl: refData.astrologerProfileURL,
              })
            );

            localStorage.setItem(
              "progress_call",
              "1|" +
              (parseInt(secondsSinceEpoch) + parseInt(res.data.data.time)) +
              "|" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              refData.astrologerProfileURL +
              "|" +
              refData.astrologerName
            );

            window.location.href =
              "/astrologer-connect/" +
              res.data.data.id +
              "?token=" +
              res.data.data.token +
              "&stkn=" +
              res.data.data.status_token +
              "&prl=%2Fastrologer%2F" +
              refData.astrologerProfileURL;
          }
        })
        .catch((err) => {
        })

    } else {
      const chatInfo = await initChat(refData.astrologerID);
      if (refData.astrologerID && chatInfo && chatInfo.success === true) {
        setChatWindowData({
          astrologer: refData.astrologerID,
          time: Date.now(),
          chatID: chatInfo.chatID,
          status: chatInfo.status,
        })
      }
      consultId = chatInfo?.receiver?.consultationId || 0;
      if (consultId) {
        notifyChat(consultId)
      }
      freeMinutes = parseInt(astroInfo?.free_chat_minutes || '0')
      updateUserConsulationId(chatInfo.chatID, consultId)
    }

    console.log(astroInfo)

    const c_type = freeMinutes > 0 ? 'free' : 'paid'

    const payload = {
      event: {
        name: 'Consultation',
        attributes: {
          'Product': 'COT',
          'Screen Name': 'Chat or Talk',
          'Consultation Name': (refData.type === 'call') ? 'Call' : 'Chat',
          'Consultation Type': freeMinutes > 0 ? 'Free' : 'Paid',
          'Consultation Status': (consultId) ? 'requested' : 'failed',
          'Consultation Duration': 0,
          'Consultation amount': `0 pts`,
          'Astrologer ID': refData.astrologerID,
          'Astrologer Name': refData.astrologerName,
          'Consultation ID': consultId,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          [`Last consulted ${c_type} chat astrologer ID`]: refData.astrologerID,
          [`Last consulted ${c_type} chat astrologer name`]: refData.astrologerName,
          'COT wallet balance': localStorage.getItem('walletBalance'),
          'Current wallet balance': localStorage.getItem('walletBalance')
        }
      },
      redirect: 1,
      time: Date.now()
    }

    console.log('payload event : ', payload)
    setTrackEventsData(payload)

  }

  const getAstrologerInfo = async (data) => {
    return new Promise((resolve, reject) => {
      axios()
        .post(`/astrologers/${data.astrologerProfileURL}`)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data) {
              resolve(res.data.data)
            } else {
              reject(res)
            }
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const updateUserConsulationId = (chatID, newConsultationId) => {
    Object.entries(localStorage).forEach(([key, value]) => {
      if (key.length > 9 && key.substr(0, 9) === 'cnslt_id_') {
        localStorage.removeItem(key)
      }
    })
    if (newConsultationId) {
      localStorage.setItem('cnslt_id_' + chatID, newConsultationId);
    }
  }

  const showVerifyPopup = () => {
    // BrowserUtils.devAlert('isReadyToLogin')
    // window.appLoginComplete()

    if (!window.isAppProfileDataHasEmail() && window.AndroidBridge) {
      BrowserUtils.devAlert('isReadyToLogin')
      window.AndroidBridge.call("isReadyToLogin")
      return
    }

    const btn = document.querySelectorAll('.astrologerInfo.visibleInCurrentTab .btn-action')[0]
    btn.classList.add('show--popup')
    btn.click()


  }

  const params = QueryString();

  const showBVarient = false; /* Number(String(localStorage["phone"]).substring(-1)) % 2 === 1; */ //show b varient if phone number ends in odd no
  //const showBVarient = ["8907523075", "9871591537", "9567891324"].indexOf(localStorage["phone"]) >= 0;
  //if (params.sl === "1") {
  sessionStorage["skipLogin"] = "true";
  //}

  // preload with cached data
  let cachedAstrologersData = null;
  try {
    cachedAstrologersData = JSON.parse(sessionStorage["astrologersData"]);
  } catch (err) {
    cachedAstrologersData = null;
  }
  const [showPromoList, setShowPromoList] = useState(true);
  const [astrologersData, setAstrologersData] = useState(cachedAstrologersData || []);
  const [promotionalList, setPromotionalList] = useState([]);
  const [recommendedData, setRecommendedData] = useState([]);
  const [filterData, setFilterData] = useState(JSON.parse(sessionStorage.getItem("filterData") || "{}"));
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [offerClaim, setOfferClaim] = useState(true);
  const [isAPaidUser, setIsPaidUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [activeConsultation, setActiveConsultation] = useState({});
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });
  const [toastBar, setToastBar] = useState({});
  const [showOnlyOnline, setshowOnlyOnline] = useState(sessionStorage.getItem("showOnlyOnline") === "true");
  const [offerDetails, setofferDetails] = useState({});
  const [offerPromoDetails, setofferPromoDetails] = useState({});
  const [advancedFilter, setAdvancedFilter] = useState({
    categories: {},
    languages: {},
    expertise: {},
  });
  const [selectedAstrologer, setSelectedAstrologer] = useState({});
  const [isCallDlgStateOpen, setCallDlgStateOpen] = useState(false);
  const [callDlgType, setCallDlgType] = useState(null);
  const [callDlgExtra, setCallDlgExtra] = useState({});
  const [isRedeemDialogOpen, setRedeemDlgStateOpen] = useState(window.location.pathname === "/redeem");
  const [activecashBack, setActivecashBack] = useState(0);
  const [pendingReview, setPendingReview] = useState({ rating: 5 });
  const [onlineAstrologers, setOnlineAstrologers] = useState({});
  const [showConfetti, setShowConfetti] = useState(false);
  const [loadingPromoList, setLoadingPromoList] = useState(false);
  const [currentOffer, setShowCurrentOffer] = useState({});
  const [upcomingBookings, setUpcomingBookings] = useState([]);

  const showAstrologerProfile = (details) => {
    const profile = {
      listingProfileData: details,
      params: {
        url: details.profileUrl,
      },
    };
    setAstProfileData(profile);
    // document.body.style.overflow = "hidden";
    window.location.hash = "closeprofilepopup";
    setAstProfileOpen(true);
  };
  /** Filter data code */
  const prepareValuesObject = (values) => {
    var out = [];
    values.map((v) => {
      return out.push({ text: v, selected: true, disabled: false });
    });
    return out;
  };

  const noOnlineAstrologers = () => {
    return onlineAstrologers.main === 0 && onlineAstrologers.promo === 0 && onlineAstrologers.fav === 0;
  };

  const isUserLoggedIn = () => {
    return localStorage.getItem("phone") !== null && localStorage.getItem("phone") !== "";
  };

  const offlineBookingAvailable = () => {
    for (const astrologer of otherAstrologersList) {
      if (astrologer.status === "offline" || astrologer.status === "away") {
        return true;
      }
    }
    return false;
  };

  const getDefaultFilter = () => {
    if (localStorage["currentFilterData"]) {
      try {
        //console.log("falog", "loading..");
        const providedFilter = JSON.parse(localStorage["currentFilterData"]);
        if (providedFilter[0].version > 0) {
          setCurrentFilter(providedFilter);
          return true;
        }
      } catch (err) {
        console.log(err);
      }
    }
    const langObject = localStorage.user_languages ? prepareValuesObject(localStorage.user_languages.split(",")) : [];
    const filter = [
      {
        title: "Language(s):",
        type: "absolute",
        values: langObject,
        field: "languages:all",
        disabled: localStorage.user_languages ? false : true,
        warningCaption: true,
        action: isUserLoggedIn() ? { fn: "changeLanguage", caption: "Add/remove languages" } : null,
        version: 1,
      },
      {
        title: "Show astrologers whose expertise is:",
        shortTitle: "Astrologer expertise:",
        type: "absolute",
        /**
         * this is the order in which the UI is rendered
         * */
        values: prepareValuesObject([
          "Vedic",
          "Kp",
          "Gemology",
          "Prashna",
          "Nadi",
          "Lal Kitab",
          "Western",
          "Vastu",
          "Numerology",
          "Fengshui",
          "Tarot",
        ]),
        /**
         * IMPORTANT:
         * value map format: [#id, caption]. Never ever cange the #id in the list
         * however you can reorder the items without changing the #ids
         * */
        valueMap: {
          Vedic: [0, "Vedic astrology"],
          Kp: [1, "KP method"],
          Gemology: [2, "Gemology/Rasi kal"],
          Prashna: [3, "Prashna/ Kavadi prashnam/ Sozi prashnam/ Ashtamangala prashnam"],
          Nadi: [4, "Nadi jothidam/Nadi astrology"],
          "Lal Kitab": [5, "Lal kitab"],
          Western: [6, "Western astrology"],
          Vastu: [7, "Vastu/Manaiyadi shastra"],
          Numerology: [8, "Numerology/En ganitham"],
          Fengshui: [9, "Feng shui"],
          Tarot: [10, "Tarot"],
        },

        field: "expertise",
        showInfo: true,
        warningCaption: true,
      },
      /*
      {
        title: "Show Astrologers whose mother tongue is:",
        type: "absolute",
        values: langObjectNative,
        field: "languages:native",
        disabled: localStorage.user_languages ? false : true,
        warningCaption: "There is no native astrologers available for __S__",
      },
      {
        title: "Also show astrologers who understands and speaks:",
        type: "absolute",
        values: langObjectOther,
        field: "languages:second",
        disabled: localStorage.user_languages ? false : true,
        warningCaption: "There is no astrologers available for __S__",
      },
      {
        title: "Astrologer experience",
        type: "range",
        field: "experience",
        tail: "years",
        values: [
          {from: 1, to: 5},
          {from: 5, to: 10},
          {from: 10, to: 20},
          {from: 20, to: 30},
          {from: 30, to: 500, suffix: "or above"}, //above 30
        ],
        disabled: true,
      },
      {
        title: "Astrologer rating",
        type: "range",
        field: "reviewsData.rating",
        values: [
          {from: 4, to: 5},
          {from: 3, to: 4},
          {from: 2, to: 3},
          {from: 1, to: 2},
          {from: 0, to: 0, caption: "no rating"},
        ],
        disabled: true,
      },
      {
        title: "Astrologer fees",
        type: "range",
        field: "price",
        values: [
          {from: 10, to: 15},
          {from: 15, to: 20},
          {from: 20, to: 25},
          {from: 25, to: 30},
          {from: 30, to: 40},
          {from: 40, to: 50},
          {from: 50, to: 70},
          {from: 70, to: 90},
          {from: 90, to: 150},
        ],
        disabled: true,
      },*/
    ];
    setTimeout((e) => setCurrentFilter(filter), 100);
    localStorage["currentFilterData"] = JSON.stringify(filter);
  };
  const [currentFilter, setCurrentFilter] = useState(null);
  const [showFilterView, setShowFilterView] = useState(false);
  const [showInstallBanner, setShowInstallBanner] = useState(false);

  const handleDownload = () => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get("utm_source") || "Findastro_web";
    const medium = params.get("utm_medium") || "install_optional_banner";
    const campaign = params.get("utm_campaign") || "";
    const term = params.get("utm_term") || "";
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3D" +
      source +
      "%26utm_medium%3D" +
      medium +
      "%26utm_campaign%3D" +
      campaign +
      "%26utm_term%3D" +
      term;

    trackActions("fa_appinstall_init", "install_optional_banner", {});
  };

  const processFilter = (filter, data) => {
    setLoading(true);
    const asCopy = [...astrologersData];
    const paCopy = [...promotionalList];
    const tobeChecked = [];
    filter.map((f) => {
      const type = f.type;
      const field = f.field;
      f.values.map((v) => {
        if (v.selected) {
          tobeChecked.push({ type: type, field: field, value: v });
        }
      });
    });

    if (tobeChecked.length > 0) {
      const filterAstrologers = (astrologer) => {
        astrologer.visible = false;
        const visibleFlags = {};
        tobeChecked.map((check) => {
          if (check.field === "languages:native") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.languages.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) === 0;
          } else if (check.field === "languages:second") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.languages.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) > 0;
          } else if (check.field === "languages:all") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.languages.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) >= 0;
          } else if (check.field === "expertise") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.expertise.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) >= 0;
          }
          astrologer.visible = Object.values(visibleFlags).reduce((a, b) => a && b);
          return astrologer;
        });
      };

      //console.log("falog", tobeChecked);
      if (!data) {
        asCopy.map((astrologer) => {
          return filterAstrologers(astrologer);
        });
        setAstrologersData(asCopy);

        setShowPromoList(false);
        paCopy.map((astrologer) => {
          filterAstrologers(astrologer);
          if (astrologer.visible) setShowPromoList(true);
          return astrologer;
        });
        setPromotionalList(paCopy);
      } else {
        data.map((astrologer) => {
          return filterAstrologers(astrologer);
        });
      }

      //console.log("falog", tobeChecked, asCopy, paCopy);
    }
    setLoading(false);
    return data;
  };

  const showFilterFeature = () => {
    //return showFilterView && currentFilter && langExpertiseMatrix && currentTab === 0;
    return false;
  };

  const processFilterMap = (providedFilter) => {
    if (providedFilter && providedFilter.length > 0) {
      processFilter(providedFilter);
    }
  };

  const closeAllModalPopups = () => {
    //window.history.pushState("", document.title, window.location.pathname + window.location.search);
    setAstProfileData(null);
    setAstProfileOpen(false);
    setDailyPredictionOpen(false);
    // document.body.style.overflow = "auto";
    if (window.lastHash === '#closeprofilepopup') {
      window.lastHash = ''
    }
  };

  const [dailyPredictionOpen, setDailyPredictionOpen] = useState(window.location.hash === "#dpexpanded");
  // Hook

  const handleHashChange = () => {
    if (window.location.hash === "") {
      closeAllModalPopups();
    }
  };

  window.addEventListener("hashchange", handleHashChange, true);

  const checkDuplicatesInList = () => {
    const counter = [];
    astrologersData.map((astrologer, id) => {
      counter[astrologer.id] = (counter[astrologer.id] || 0) + 1;
      if (counter[astrologer.id] > 1) {
        //console.log("arun", "Duplicate:", astrologer);
        return true;
      }
    });
    return false;
  };

  const handleOfferIcon = (e, offer) => {
    e.stopPropagation();
    setShowCurrentOffer({ nextCallStatus: true, offer: offer });
  };

  const handleFavourite = async (id, status) => {
    /** check for the favourite change in both normal
     * and promo list and set status accordingly */
    let data = [...astrologersData];
    let found = false;
    for (const i in data) {
      const astrologer = data[i];
      if (astrologer.id === id) {
        astrologer.is_favourite = status;
        found = true;
        break;
      }
    }
    if (found) {
      setAstrologersData(data);
    } else {
      data = [...promotionalList];
      let found = false;
      for (const i in data) {
        const astrologer = data[i];
        if (astrologer.id === id) {
          astrologer.is_favourite = status;
          found = true;
          break;
        }
      }
      if (found) {
        setPromotionalList(data);
      }
    }
  };

  const imageLoaded = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("loaded");
  };

  const postReview = () => {
    const pr = { ...pendingReview };
    console.log("postReview:pendingReview", pr);
    if (pr.rating > 0) {
      onReviewSubmit(pr);
    } else {
      pr.ratingError = true;
      setPendingReview(pr);
      /*reset error*/
      setTimeout(() => {
        const lpr = { ...pendingReview };
        lpr.ratingError = false;
        setPendingReview(lpr);
      }, 1800);
    }
  };
  /* Forced update for findastro 1.0.x users */
  const forceUpdate = () => {
    return (
      //2.0.1-r
      BrowserUtils.isFindAstroWebview === true && typeof window.AndroidBridge === "undefined"
    );
  };
  /* 
  const isVersionExpired = (version) => {
    const currentVersion = CURRENT_APP_VERSION;
    const currentVerTag = parseInt(currentVersion.split(".")[0] + currentVersion.split(".")[1]);
    const expectedVersion = parseInt(version.split(".")[0] + version.split(".")[1]);
    return expectedVersion > currentVerTag;
  }; */

  const tabPanelChat = useRef();

  const closeReview = () => {
    const pr = { ...pendingReview };
    pr.ratingError = false;
    pr.is_submitted = true;
    setPendingReview(pr);
  };

  const onpageEventHandler = (action) => {
    switch (action) {
      case "couponRedeem":
        setDrawerOpenState(false);
        setRedeemDlgStateOpen(true);
        break;
      default:
        return false;
    }
  };

  const CouponHandleClose = () => {
    setRedeemDlgStateOpen(false);
    if (window.location.pathname === "/redeem") {
      window.location.href = "/";
    }
  };

  const callFirstOnlineAstrologer = () => {
    let targetAst = [];
    const refinedData = sortByRank([...astrologersData]);
    //console.log(refinedData);
    refinedData.map((astrologer) => {
      if (targetAst.length < 5 && astrologer.status === "online") {
        targetAst.push(astrologer);
      }
    });
    const seed = parseInt((Math.random() * 1000) % targetAst.length);
    if (targetAst.length > 0) {
      openCallDialog(targetAst[seed], "main");
      trackActions("fa_daily_pred_consult", "daily_prediction", targetAst[seed]);
      trackActions("fa_consult_intent", "daily_prediction", targetAst[seed]);
    } else showError("No astrologers online. Please try later");
  };

  const handleDlgClose = () => {
    setCallDlgStateOpen(false);
  };

  const openCallDialog = (astrologer, astrologerType, extra) => {
    //console.log("arun", astrologer, astrologerType);
    setSelectedAstrologer(astrologer);
    setActivecashBack(
      astrologerType === "promotional" ? offerPromoDetails.promotionalDiscount : offerDetails.nextCallDiscount
    );

    setCallDlgType(extra && extra.booking ? "booking" : "call");
    setCallDlgExtra(extra);
    setCallDlgStateOpen(true);
  };

  const onReviewSubmit = (pr) => {
    setLoading(true);
    trackReviewRating('Rating Action', pr)
    axios()
      .post("/review/consultation", {
        id: pr.id,
        answers: {
          1: pr.rating,
        },
        comment: pr.reviewText,
      })
      .then((res) => {
        setLoading(false);
        pr.is_submitted = true;
        pr.ratingError = false;
        setPendingReview(pr);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const trackReviewRating = (label, pr) => {
    console.log(pr);
    if (pr.astrologer?.id) {
      const payload = {
        event: {
          name: label,
          attributes: {
            'Screen Name': 'Astrologer rating',
            'Product': 'COT',
            'Consultation Name': (pr.consult_type) ? pr.consult_type : 'Chat',
            'Consultation Type': (pr.cost) ? 'Paid' : 'Free',
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name
          }
        },
        extra: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        },
        time: Date.now()
      }

      if (label == 'Rating Action') {
        payload.event.attributes['Rating'] = pr.rating
        payload.event.attributes['Astrologer ID'] = pr.astrologer.id
        payload.event.attributes['Astrologer Name'] = pr.astrologer.name
      }
      setTrackEventsData(payload);
    }
  }

  const trackActions = (type, label, data) => {
    moengage(type);
    let ast_rating = data.reviewsData && data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: "home",
      p_label: label,
      p_content: data.profileUrl || "NA",
      p_value: data.value || 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience ? data.experience.split(" ")[0] : "NA",
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.call_rate ? data.call_rate.split("/")[0] : "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  const toggleHeader = (scrollTop) => {
    const header = document.getElementsByClassName("autoHideHeader")[0];
    if (scrollTop > 10) {
      header.classList.add("folded");
    } else {
      header.classList.remove("folded");
    }
  };

  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isConvLoading, setIsConvLoading] = useState(isUserLoggedIn());
  const ScrollViewHandler = (e) => {
    const value = Math.round((e.target.scrollTop / e.target.scrollHeight) * 1500) / 100;
    // toggleHeader(e.target.scrollTop); //toggle static header for big cards.
    //console.log(value);
    /* if (scrollPercentage !== value) {
      setScrollPercentage(value);
    }
    const bannerstatus = e.target.scrollTop > 50;
    if (showInstallBanner !== bannerstatus) {
      setShowInstallBanner(bannerstatus);
    } */
  };
  /*
  useEffect(() => {
    console.log(scrollPercentage);
  }, [scrollPercentage]);
  */
  const showFilterBox = () => {
    setFilterOpen(!filterOpen);
  };

  // const filterByCategory = (e) => {
  //   e.preventDefault();
  //   const el = e.currentTarget;
  //   el.classList.toggle("active");
  //   const category = el.getAttribute("category");
  //   const data = {...advancedFilter};
  //   const value = el.classList.contains("active");
  //   advancedFilter.categories[category] = value;
  //   setTimeout(() => {
  //     setAdvancedFilter(data);
  //     showStatusToast(" Filter " + (value ? "applied" : "removed") + ": " + category, "status");
  //   }, 300);
  // };

  const AstrologerListMaker = (data) => {
    const astrologerCopy = data;
    let refinedData = [];

    switch (filterData.sortBy) {
      case "plth":
      case "phtl":
        refinedData = sortByPrice(astrologerCopy, filterData.sortBy);
        break;
      case "exp":
        refinedData = sortByExperience(astrologerCopy);
        break;
      case "reviews":
        refinedData = sortByRating(astrologerCopy);
        break;
      case "rank":
      default:
        refinedData = sortByRank(astrologerCopy);
        break;
    }

    // refinedData.sort(({ free_minutes: a }, { free_minutes: b }) => (a === null) - (b === null) || a - b)

    // console.log('refinedData', refinedData)

    return refinedData;
  };

  const sortByPrice = (data, type) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let price = parseInt(ast.price.split("/")[0]);
      sortedList[price] = sortedList[price] || [];
      sortedList[price].push(ast);
    }
    let finalList = [];
    for (var price in sortedList) {
      for (var ast in sortedList[price]) {
        finalList.push(sortedList[price][ast]);
      }
    }
    if (type === "phtl") {
      finalList.reverse();
    }
    return finalList;
  };
  const sortByExperience = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = parseInt(ast.experience.match(/\d{1,3}/)[0]) || 0;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }

    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }

    finalList.reverse();
    return finalList;
  };

  /* const sortByStatus = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = ast.status === "online" ? 0 : 1;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }
   
    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }
    return finalList;
  }; */

  const sortByRank = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = ast.sequence;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }
    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }

    finalList.reverse();
    return finalList;
  };

  const sortByRating = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = parseFloat(ast.reviewsData.rating) * 10 * Math.pow(ast.reviewsData.count, 2) || 0;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }
    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }

    finalList.reverse();
    return finalList;
  };
  useEffect(() => {
    sessionStorage.setItem("showOnlyOnline", showOnlyOnline);
  }, [showOnlyOnline]);

  useEffect(() => {
    sessionStorage.setItem("filterData", JSON.stringify(filterData));
    applySort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    sessionStorage.setItem("advancedFilter", JSON.stringify(advancedFilter));
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedFilter]);

  const [postUpdate, setPostUpdate] = useState(null);
  useEffect(() => {
    updateAstrologerStatus(postUpdate);
  }, [postUpdate]);

  const updateAstrologerStatus = (update) => {
    const asCopy = [...astrologersData];
    //console.log("arun--+", asCopy.length);
    if (update) {
      let changed = false;
      asCopy.map((data, i) => {
        if (data.id.toString() === update.message.astrologer_id.toString()) {
          data.status = update.message.status_to;
          data.away_until = update.message.away_until;
          //console.log("arun", data, update);
          const message = data.name + " is " + update.message.status_to + " now.";
          showStatusToast(message, update.message.status_to);
          changed = true;
        }
        return true;
      });
      if (changed === true) {
        setAstrologersData(asCopy);
      }

      //reset
      changed = false;

      const pasCopy = [...promotionalList];
      pasCopy.map((data, i) => {
        if (data.id.toString() === update.message.astrologer_id.toString()) {
          data.status = update.message.status_to;
          data.away_until = update.message.away_until;
          //console.log("arun", data, update);
          const message = data.name + " is " + update.message.status_to + " now.";
          showStatusToast(message, update.message.status_to);
          changed = true;
        }
        return true;
      });
      if (changed === true) {
        setPromotionalList(pasCopy);
      }
    }
  };
  const applyFilter = () => {
    const data = [...astrologersData];
    for (let key in data) {
      let astrologer = data[key];
      for (let category in advancedFilter.categories) {
        if (advancedFilter.categories[category] === true) {
          if (astrologer.category.indexOf(category) >= 0) {
            astrologer.masked = "";
          } else {
            astrologer.masked = "masked";
          }
        }
      }
    }
    setAstrologersData(data);
  };
  const sortTypes = {
    plth: "Fees low to high",
    phtl: "Fees high to low",
    rank: "Clickastro recommendation",
    exp: "Experience",
    reviews: "Popularity",
  };
  const setSortBy = (sortBy) => {
    const filter = { ...filterData };
    filter.sortBy = sortBy;
    setTimeout(() => {
      setFilterData(filter);
      showStatusToast("Sorted astrologers list by: " + sortTypes[sortBy], "status");
      //trackActions("fa_sort", sortBy, {});
    }, 100);
    var eData = {
      'screenName': 'Sort Astrologer',
      'buttonName': sortTypes[sortBy]
    }
    trackeventData(eData);
  };

  const applySort = () => {
    const data = applySortingOnDataset([...astrologersData]);
    setAstrologersData(data);
  };

  const applySortingOnDataset = (data) => {
    data = AstrologerListMaker(data);
    return data;
  };

  const toggleShowOnlyOnline = () => {
    window.stopAllAudio();
    const input = document.querySelector(".MuiAvatar-rounded .switch-slider input");
    input.checked = !input.checked;
    setTimeout(() => {
      showStatusToast(input.checked ? "Showing only online astrologers" : "Showing all astrologers", "status");
      setshowOnlyOnline(input.checked);
    }, 300);

    /* if (!window.statusAutomated) {
      window.statusAutomated = true;
      setInterval(() => {
        const data = [...astrologersData];
        const d = Date.now() % 3;
        data[d].status = ["offline", "online", "away"][Date.now() % 3];
        setAstrologersData(data);
        showStatusToast("Astrolgoer " + data[d].name + " is " + data[d].status + " now.", data[d].status);
      }, 20000);
    } */
  };

  const showSortBox = () => {
    window.stopAllAudio();
    setSortOpen(!sortOpen);
    if (!sortOpen) {
      const eData = {
        'buttonName': 'Sort Astrologer',
        'targetScreenName': 'Chat or Talk',
        'location': 'Header'
      }
      trackeventData(eData);
    }
  };

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  const trackeventData = (data) => {
    const payload = {
      event: {
        name: (data.name) ? data.name : 'Button Action',
        attributes: {
          'Screen Name': (data.screenName) ? data.screenName : 'Chat or Talk',
          'Button Name': data.buttonName,
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      time: Date.now()
    }

    if (data.targetScreenName) {
      payload.event.attributes['Target Screen Name'] = data.targetScreenName;
    }

    if (data.location) {
      payload.event.attributes['Button Location'] = data.location;
    }

    if (data.sourceScreen) {
      payload.event.attributes['Source Screen Name'] = data.sourceScreen;
    }

    if (data.variant) {
      payload.event.attributes['Variant Name'] = data.variant;
    }

    if (data.consultationType) {
      payload.event.attributes['Consultation Type'] = data.consultationType;
    }
    if (data.redirect) {
      T2AEventTrack(payload.event, payload.extra)
      console.log(payload);
    } else {
      setTrackEventsData(payload);
    }
  };

  const trackScreenViewEvent = (data) => {
    const payload = {
      event: {
        name: 'Screen View Action',
        attributes: {
          'Screen Name': (data.screenName) ? data.screenName : 'Chat or Talk',
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      extra: {
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name
      },
      time: Date.now()
    }
    setTrackEventsData(payload);
  };

  const onSearch = () => {
    moengage("Search Icon Click");
    let url = "/search-results";
    if (searchText !== "") {
      url += "?q=" + searchText;
      if (category !== "all") {
        url = url + "&category=" + category;
      }
    }
    const eData = {
      'redirect': 1,
      'buttonName': 'Search astrologer',
      'targetScreenName': 'Search Astrologers',
      'location': 'Header'
    }
    trackeventData(eData);
    window.location.href = url;
  };

  const onSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const onClear = () => {
    window.location.href = "/";
  };

  const onJoinBanner = (e) => {
    trackActions("fa_join_banner", "", {});
    onWallet();
  };

  const onWallet = (e) => {
    // window.location.href = "/my-wallet";
    if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
      e?.goToWallet ? e.goToWallet() : history.push('/user-wallet')
    } else {
      showVerifyPopup()
    }
  };

  const handleUnLogged = (d) => {
    // window.location.href = "/my-wallet";
    if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
      return false;
    } else {
      showVerifyPopup();
      if (d && d.task) {
        rechargeRedirect.setData({
          refID: 'pushnotification/openchatorprofile',
          astrologerID: d.astrologerID,
          task: d.task,
          taskData: JSON.stringify(d),
        });
      }
      return true
    }
  };

  const showLoading = () => {
    setLoading(true);
    setFilterOpen(false);
  };

  const fetchMoreData = () => {
    if (currentPage > maxPages) {
      setHasMore(false);
      return;
    } else {
      setIsFetching(true);
      requestAndFetchData(currentPage);
      if (currentPage >= maxPages) {
        setHasMore(false);
      }
    }
  };

  const requestAndFetchData = (page = 1) => {
    let tempParams = "";
    if (window.location.search) {
      tempParams = window.location.search + "&";
    }
    tempParams += (tempParams === "" ? "?" : "&") + "phone=" + FA_localStorage("phone");
    let url = "ma"; //isMobile ? "m" : "d"
    const loader = FA_localStorage("phone") && FA_localStorage("phone") !== "" ? axios : axios_noheader;
    let postData = { user_age: 0 };
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      let profileData = window.AndroidBridge.call("getAppProfileData")
      if (profileData) {
        try {
          profileData = JSON.parse(profileData);
        } catch (er) { profileData = {}; }

        const dob = moment(profileData.dob, 'DD-MM-YYYY');
        if (dob.isValid()) {
          postData.user_dob = dob.format('YYYY-MM-DD');
          postData.user_age = moment().diff(dob, 'years');
        }
        postData.app_language = profileData.appLanguage;
        postData.app_chartstyle = userProfileData.horoscopeStyle[profileData.chartStyle ? profileData.chartStyle : 0];
        postData.user_gender = profileData.gender;
        postData.user_country = profileData.country;
      }
    }
    loader()
      .post("/astrologers/all/" + url + tempParams + "&page=" + page, { ...postData, ...DealPurchaseUtils.userPostData() })
      .then((res) => {
        if (res.data.success) {
          if (res.data?.deals && DealPurchaseConfig.is_enabled) {
            res.data.data.map(data => data.deals = res.data.deals)
            setDealPurchase(deal => ({
              ...deal,
              have_deal_purchase: res.data?.have_deal_purchase > 0 ? true : false,
              deals: res.data.deals
            }))
          }
          if (res.data.hasOwnProperty('have_free_consult'))
            setShowFreeConsultTopBanner((res.data.have_free_consult === 1));
          if (res.data.hasOwnProperty('have_recharge')) {
            setHasRecharge({
              loaded: 1,
              count: res.data.have_recharge
            })
          }

          trackUserAttributes(res.data);

          if (res.data.hasOwnProperty('user_status')) {
            if (res.data.user_status !== 'Guest') {
              BrowserUtils.setCachedKey('userStatus', res.data.user_status);
            }
          }
          res.data.data = [...astrologersData, ...res.data.data];
          res.data.data = addSequencing(res.data.data);
          const data = applySortingOnDataset(res.data.data);
          setAstrologersData(data);
          setIsFetching(false);
          setCurrentPage(currentPage + 1);
        } else {
          showError(res.data.message);
          setIsFetching(false);
        }
      })
      .catch((err) => {
        sentryError("ASTROLOGER_LIST_PAGENATION", err);
        showError("Something went wrong. please try again");
        setIsFetching(false);
      });
  };

  const handleNotifyMe = (id, type) => {
    setLoading(true);
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login";
    } else {
      axios()
        .post("/notifications/" + (type === "add" ? "notify-me" : "revoke-notify-me"), {
          ast_id: id,
        })
        .then((res) => {
          if (res.data.success) {
            if (type === "add") {
              showStatusToast("You will be notified when astrologer is online.");
              let temp = { ...notifications };
              if (temp.initiated) {
                temp.initiated.push(id);
                setNotifications(temp);
              }
              setLoading(false);
            } else {
              showStatusToast("Cancelled astrologer online notification.");
              let temp = { ...notifications };
              if (temp.initiated) {
                const index = temp.initiated.indexOf(id);
                if (index > -1) {
                  temp.initiated.splice(index, 1);
                  setNotifications(temp);
                }
              }
              setLoading(false);
            }
          } else {
            showError(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          showError("Something went wrong. please try again");
        });
    }
  };

  const onReferEarnClaim = () => {
    const paramsData = QueryString();
    window.location.href = "/refer-earn/?code=" + paramsData.code;
  };

  useEffect(() => {
    if (currentPage > 1 && currentPage <= maxPages) {
      setTimeout(fetchMoreData, 500);
    } else {
      setHasMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, maxPages]);

  const [otherAstrologersList, setOtherAstrologersList] = useState([]);
  const [favAstrologersList, setFavAstrologersList] = useState([]);
  const [favPromoAstrologersList, setFavPromoAstrologersList] = useState([]);
  const [showFreeConsultTopBanner, setShowFreeConsultTopBanner] = useState(false);

  useEffect(() => {
    /*display pending offer message*/
    if (FA_localStorage("pendingCashbackOffer") && FA_localStorage("pendingCashbackOffer") !== "") {
      setTimeout((e) => {
        let pcbo = JSON.parse(FA_localStorage("pendingCashbackOffer") || null);
        if (pcbo && pcbo.cashbackOffer) {
          pcbo.cashbackOffer.blastConfetti = false;
          setShowCashbackOffer(pcbo.cashbackOffer);

          setTimeout((e) => {
            pcbo = JSON.parse(FA_localStorage("pendingCashbackOffer"));
            pcbo.cashbackOffer.blastConfetti = true;
            setShowCashbackOffer(pcbo.cashbackOffer);
          }, 200);
        }
      }, 5000);
    }
    /* prepare and separate fav and other astrologers */
    (function prepareFavAndOtherAstrologersList() {
      let favouriteList = [];
      let normalListOnline = [];
      let normalListOffline = [];
      astrologersData.map((astrologer) => {
        if (astrologer.status === "online" || astrologer.status === "busy") normalListOnline.push(astrologer);
        else normalListOffline.push(astrologer);
        return true;
      });
      setOtherAstrologersList(normalListOnline.concat(normalListOffline));
      setFavAstrologersList(favouriteList);
    })();

    bindRealtimeEvents();
    recalculateOnlineCount();
    /** show filterview once the astrologers data is loaded completely */
    setShowFilterView(maxPages > 0 && currentPage >= maxPages);
    //setTimeout((e) => console.log("falog", astrologersData.length, currentPage, maxPages), 1500);
    //console.log("arun--", astrologersData.length);
  }, [astrologersData]);

  const updateUserDetailsLocal = () => {
    if (localStorage.getItem("phone") && localStorage.getItem("phone") !== "undefined") {
      axios()
        .post("/profile/user")
        .then((res) => {
          if (res && res.data.data.success) {
            localStorage.setItem("dob", res.data.data.user.dateOfBirth);
            localStorage.setItem("tob", res.data.data.user.timeOfBirth);
            localStorage.setItem("pob", res.data.data.user.placeOfBirth);
            localStorage.setItem("gender", res.data.data.user.gender);
            localStorage.setItem("user_languages", res.data.data.user.languages);
            getDefaultFilter();
          }
        })
        .catch((err) => {
          sentryError("MY_ACCOUNT", err);
        });
    } else {
      /*axios()
        .get("/list/languages")
        .then((res) => {
          if (res.data.success === true) {
            const ulang = [];
            Object.values(res.data.languages).map((lang) => {
              ulang.push(lang.name);
            });
            localStorage.setItem("user_languages", ulang.join(","));
            getDefaultFilter();
          }
        })
        .catch((err) => {
          sentryError("languageListFetch:Error", err);
        });*/
    }
  };

  const checkPendingReferEarn = (refdata) => {
    try {
      if (refdata) {
        const date = parse(refdata.created_at, "yyyy-MM-dd HH:mm:ss", new Date());
        if (localStorage.lastRefEarnId !== refdata.id.toString()) {
          const _72HRS = 72 * 60 * 60 * 1000;
          if (Date.now() - date.getTime() < _72HRS) {
            setReferEarnPendingCallout(refdata);
          }
        }
      }
    } catch (err) {
      sentryError("checkPendingReferEarn:Error", err);
    }
  };

  const [listUpdated, setListUpdated] = useState(Date.now());

  useEffect(() => {
    (function prepareFavPromoAstrologersList() {
      let favouriteList = [];
      promotionalList.map((astrologer) => {
        if (astrologer.is_favourite === true) {
          favouriteList.push(astrologer);
        }
        return true;
      });
      setFavPromoAstrologersList(favouriteList);
    })();
    bindRealtimeEvents();
    recalculateOnlineCount();
  }, [promotionalList]);

  const initialLoad = () => {
    // force login
    /* if (!FA_localStorage("phone") && sessionStorage["skipLogin"] !== "true") {
      window.location.href = "/login";
    } */
    //force web use
    setLoading(true);
    if (window.location.search.indexOf("test=1") >= 0) {
      sessionStorage.setItem("forceWebUse", "force");
    }

    //moengage("LIST_PAGE_VIEWED");
    if (!FA_localStorage("phone") || !FA_localStorage("name") || !FA_localStorage("email")) {
      FA_localStorage("name", "remove");
      FA_localStorage("email", "remove");
      FA_localStorage("phone", "remove");
      FA_localStorage("image", "remove");
      FA_localStorage("utkn", "remove");
      FA_localStorage("btkn", "remove");
      FA_localStorage("walletBalance", "remove");
      FA_localStorage("rate_us", "remove");
      FA_localStorage("user_languages", "remove");
    }
    if (FA_localStorage("btkn") && FA_localStorage("utkn") && FA_localStorage("phone")) {
      setOfferClaim(false);
      if (window.Moengage) {
        window.Moengage.add_unique_user_id(FA_localStorage("phone"));
      }
    }
    let filterParams = "";
    /*
    const paramsData = QueryString();
    if (window.location.search) {
      filterParams = window.location.search;
      if (paramsData.category || paramsData.expertise || paramsData.language)
        moengage("FILTERS_SELECTED", {
          category: paramsData.category ? paramsData.category : "",
          expertise: paramsData.expertise ? paramsData.expertise : "",
          language: paramsData.language ? paramsData.language : "",
        });
      if (paramsData.sort)
        moengage("SORT_APPLIED", {
          sort_type: paramsData.sort,
        });
      if (paramsData.availability) moengage("ONLINE_FILTER_CLICKED");
    }
    setFilterData(paramsData);
    */
    // try to get astrologers data from the sessioncache

    //

    let url = "ma"; //isMobile ? "m" : "d"
    let postData = { user_age: 0 };
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      let profileData = window.AndroidBridge.call("getAppProfileData")
      if (profileData) {
        try {
          profileData = JSON.parse(profileData);
        } catch (er) { profileData = {}; }

        const dob = moment(profileData.dob, 'DD-MM-YYYY');
        if (dob.isValid()) {
          postData.user_dob = dob.format('YYYY-MM-DD');
          postData.user_age = moment().diff(dob, 'years');
        }
        postData.app_language = profileData.appLanguage;
        postData.app_chartstyle = userProfileData.horoscopeStyle[profileData.chartStyle ? profileData.chartStyle : 0];
        postData.user_gender = profileData.gender;
        postData.user_country = profileData.country;
        if (profileData?.ca_prev_payment) {
          BrowserUtils.setCachedKey('CAPaid', profileData.ca_prev_payment)
        }
      }
    }

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      axios_noheader()
        .post("/astrologers/all/" + url + filterParams, { ...postData, ...DealPurchaseUtils.userPostData() })
        .then((res) => {
          if (res.data.success) {
            if (res.data?.deals && DealPurchaseConfig.is_enabled) {
              res.data.data.map(data => data.deals = res.data.deals)
              setDealPurchase(deal => ({
                ...deal,
                have_deal_purchase: res.data?.have_deal_purchase > 0 ? true : false,
                deals: res.data.deals
              }))
            }
            if (res.data.hasOwnProperty('have_free_consult'))
              setShowFreeConsultTopBanner((res.data.have_free_consult === 1));
            if (res.data.hasOwnProperty('have_recharge')) {
              setHasRecharge({
                loaded: 1,
                count: res.data.have_recharge
              })
            }
            trackUserAttributes(res.data)
            if (res.data.hasOwnProperty('user_status')) {
              if (res.data.user_status !== 'Guest') {
                BrowserUtils.setCachedKey('userStatus', res.data.user_status);
              }
            }
            if (res.data.data.length === 0 && window.location.search !== "") {
              axios_noheader()
                .post("/astrologers/all/ma", { ...postData, ...DealPurchaseUtils.userPostData() })
                .then((res) => {
                  if (res.data.success) {
                    if (res.data?.deals && DealPurchaseConfig.is_enabled) {
                      res.data.data.map(data => data.deals = res.data.deals)
                      setDealPurchase(deal => ({
                        ...deal,
                        have_deal_purchase: res.data?.have_deal_purchase > 0 ? true : false,
                        deals: res.data.deals
                      }))
                    }
                    if (res.data.hasOwnProperty('have_free_consult'))
                      setShowFreeConsultTopBanner((res.data.have_free_consult === 1));
                    if (res.data.hasOwnProperty('have_recharge')) {
                      setHasRecharge({
                        loaded: 1,
                        count: res.data.have_recharge
                      })
                    }
                    trackUserAttributes(res.data)
                    if (res.data.hasOwnProperty('user_status')) {
                      if (res.data.user_status !== 'Guest') {
                        BrowserUtils.setCachedKey('userStatus', res.data.user_status);
                      }
                    }
                    setRecommendedData(res.data.data);
                    setOthersLoading(false);
                    setHasAstrologer(1)
                  } else {
                    showError(res.data.message);
                  }
                })
                .catch((err) => {
                  sentryError("ASTROLOGER_LIST_RECOMMENDED", err);
                  showError("Something went wrong. please try again");
                });
            } else {
              /*axios_noheader()
                .post("/profile-unauth/updates")
                .then((res) => {
                  if (res.data.success) {
                    const details = {...offerDetails};
                    details.nextCallDiscount = res.data.data.discount_per;
                    setLangExpertise(res.data.data.language_expertise);
                    FA_localStorage("nextCallPrice", details.nextCallDiscount);
                    setofferDetails(details);
                    setOfferClaim(res.data.isNUser);
                    setIsPaidUser(res.data.data.recharges > 0);
                  }
                })
                .catch((err) => {
                  sentryError("ASTROLOGER_LIST_NEW_USER", err);
                });*/

              res.data.data = addSequencing(res.data.data);
              res.data.data = applySortingOnDataset(res.data.data);
              setMaxPages(res.data.paginate.last_page);
              setAstrologersData(res.data.data);
              sessionStorage["astrologersData"] = JSON.stringify(res.data.data);
              setCurrentPage(currentPage + 1);
              setLoading(false);
              setHasAstrologer(1)
            }
          } else {
            showError(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          sentryError("ASTROLOGER_LIST_DETAILS", err);
          showError("Something went wrong. please try again");
          setLoading(false);
        });
      //updateUserDetailsLocal();
    } else {
      filterParams += (filterParams === "" ? "?" : "&") + "phone=" + FA_localStorage("phone");
      axios()
        .post("/astrologers/all/" + url + filterParams, { ...postData, ...DealPurchaseUtils.userPostData() })
        .then((res) => {
          if (res.data.success) {
            if (res.data?.deals && DealPurchaseConfig.is_enabled) {
              res.data.data.map(data => data.deals = res.data.deals)
              setDealPurchase(deal => ({
                ...deal,
                have_deal_purchase: res.data?.have_deal_purchase > 0 ? true : false,
                deals: res.data.deals
              }))
            }
            if (res.data.hasOwnProperty('have_free_consult'))
              setShowFreeConsultTopBanner((res.data.have_free_consult === 1));
            if (res.data.hasOwnProperty('user_status')) {
              if (res.data.user_status !== 'Guest') {
                BrowserUtils.setCachedKey('userStatus', res.data.user_status);
              }
            }
            if (res.data.hasOwnProperty('have_recharge')) {
              setHasRecharge({
                loaded: 1,
                count: res.data.have_recharge
              })
            }
            trackUserAttributes(res.data)
            if (res.data.data.length === 0 && window.location.search !== "") {
              axios_noheader()
                .post("/astrologers/all/ma", { ...postData, ...DealPurchaseUtils.userPostData() })
                .then((res) => {
                  if (res.data.success) {
                    if (res.data?.deals && DealPurchaseConfig.is_enabled) {
                      res.data.data.map(data => data.deals = res.data.deals)
                    }
                    if (res.data.hasOwnProperty('have_recharge')) {
                      setHasRecharge({
                        loaded: 1,
                        count: res.data.have_recharge
                      })
                    }
                    trackUserAttributes(res.data)
                    if (res.data.hasOwnProperty('have_free_consult'))
                      setShowFreeConsultTopBanner((res.data.have_free_consult === 1));

                    if (res.data.hasOwnProperty('user_status')) {
                      if (res.data.user_status !== 'Guest') {
                        BrowserUtils.setCachedKey('userStatus', res.data.user_status);
                      }
                    }
                    setRecommendedData(res.data.data);
                    setOthersLoading(false);
                    setHasAstrologer(1)
                  } else {
                    showError(res.data.message);
                  }
                })
                .catch((err) => {
                  sentryError("ASTROLOGER_LIST_RECOMMENDED", err);
                  showError("Something went wrong. please try again");
                });
            } else {
              if (FA_localStorage("btkn") && FA_localStorage("utkn")) {
                runUserUpdates();
              }
              res.data.data = addSequencing(res.data.data);
              res.data.data = applySortingOnDataset(res.data.data);
              setMaxPages(res.data.paginate.last_page);
              setAstrologersData(res.data.data);
              sessionStorage["astrologersData"] = JSON.stringify(res.data.data);
              setCurrentPage(currentPage + 1);
              setLoading(false);
              setHasAstrologer(1)
            }
          } else {
            showError(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          sentryError("ASTROLOGER_LIST_DETAILS", err);
          showError("Something went wrong. please try again");
          setLoading(false);
        });
    }

    //fetchPromotionalList();
    fetchNotifiedUsers();
    updateUserDetailsLocal();
    setTestimonialId(Date.now() % TestimonialData.data.length);
    window.addEventListener("focus", onAppScreenFocus);
    window.addEventListener("blur", onAppScreenLostFocus);

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    //document.body.appendChild(script);
    //console.log({currentConsultType,page : 'tab view'})

    if (isUserLoggedIn() && localStorage.getItem("showLangPrefBnr") == 1)
      trackScreenViewEvent({ 'screenName': 'Language preference banner' });
  }

  useEffect(() => {
    initialLoad();
    return () => {
      window.removeEventListener("focus", onAppScreenFocus);
      window.removeEventListener("blur", onAppScreenLostFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasAstrologer) {
      setTimeout(() => {
        switchCallChatOnlineAstrologers(currentConsultType)
      }, 200);
    }
  }, [hasAstrologer])

  const runUserUpdates = () => {
    axios()
      .post("/profile/updates")
      .then((res) => {
        if (res && res.data.success) {
          const details = { ...offerDetails };
          details.nextCallDiscount = res.data.data.discount_per || 0;
          setofferDetails(details);
          setIsPaidUser(res.data.data.recharges > 0);
          const pr = { ...res.data.data.last_consultation_review };
          pr.rating = pr.rating || 5;
          setPendingReview(pr);

          setOfferClaim(res.data.isNUser);
          FA_localStorage("nextCallPrice", details.nextCallDiscount);
          FA_localStorage("user_languages", res.data.data.languages);
          FA_localStorage("walletBalance", res.data.data.wallet_balance || 0);
          setLangExpertise(res.data.data.language_expertise);
          checkPendingReferEarn(res.data.data.latest_refer_earn);

          checkPendingConsultation({ message: false });
          setUpcomingBookings(res.data.data.active_bookings);

          if (FA_localStorage("registrationInit") === "true") {
            if (res.data.data.wallet_balance > 0) {
              setShowRegnOffer({ status: res.data.data.wallet_balance > 0 });
              setTimeout(() => {
                setShowRegnOffer({
                  status: res.data.data.wallet_balance > 0,
                  blastConfetti: true,
                });
              }, 100);
            } else {
              setTimeout(() => {
                setShowRegnOffer({ nextCallStatus: true });
              }, 2000);
              setTimeout(() => {
                setShowRegnOffer({ nextCallStatus: true, blastNextCallConfetti: true });
              }, 2100);
            }
          }

          if (BrowserUtils.isNewUI() && res.data?.data.hasOwnProperty('user_status')) {
            if (res.data.data.user_status !== 'Guest') {
              BrowserUtils.setCachedKey('userStatus', res.data.data.user_status);
            }
          }
          //FA_localStorage("activeConsultation", JSON.stringify(res.data.data.active_consultation));
        }
      })
      .catch((err) => {
        sentryError("ASTROLOGER_LIST_NEW_USER", err);
      });
  };

  const onAppScreenFocus = () => {
    //console.log("arun", "on the window");
    bindRealtimeEvents();
  };

  const onAppScreenLostFocus = () => {
    //console.log("arun", "out of the window");
    if (window.statusChangeListener) {
      window.statusChangeListener.close();
      window.statusChangeListener = false;
    }
    //window.stopAllAudio(); //iOs issue
  };

  /*useEffect(() => {
    //console.log("arun", loadingPromoList);
    if (loadingPromoList === false) {
      fetchPromotionalList();
    }
  }, [loadingPromoList]);*/

  const fetchPromotionalList = () => {
    if (loadingPromoList === false && window.location.pathname === "/") {
      setLoadingPromoList(true);
      //console.log("arun", "fetching promo.." + Date.now());
      if (FA_localStorage("phone")) {
        axios()
          .post("/astrologers/promotions?phone=" + FA_localStorage("phone"))
          .then((res) => {
            if (res.data.success) {
              const details = { ...offerPromoDetails };
              details.promotionalDiscount = res.data.discount_per;
              setofferPromoDetails(details);
              res.data.data = addSequencing(res.data.data);
              const onlinePromoList = res.data.data; //promoData.data; //
              setPromotionalList(onlinePromoList.filter((ast) => ast.status === "online" || ast.status === "busy"));
              FA_localStorage("promoPrice", res.data.discount_per);
              setLoading(false);
            }
          })
          .catch((err) => {
            sentryError("PROMOTIONAL_LIST_DETAILS", err);
            if (promotionalList.length === 0) {
              showError("Something went wrong. please try again");
            }
            setLoading(false);
          });
      } else {
        axios()
          .post("/astrologers/promotions")
          .then((res) => {
            if (res.data.success) {
              const details = { ...offerPromoDetails };
              details.promotionalDiscount = res.data.discount_per;
              setofferPromoDetails(details);
              res.data.data = addSequencing(res.data.data);
              setPromotionalList(res.data.data);
              FA_localStorage("promoPrice", res.data.discount_per);
              setLoading(false);
            }
          })
          .catch((err) => {
            sentryError("PROMOTIONAL_LIST_DETAILS", err);
            if (promotionalList.length === 0) {
              showError("Something went wrong. please try again");
            }
            setLoading(false);
          });
      }
    }
  };

  const fetchNotifiedUsers = () => {
    if (FA_localStorage("phone")) {
      axios()
        .post("/notifications/all")
        .then((res) => {
          if (res.data.success) {
            setNotifications(res.data.data);
          }
        })
        .catch((err) => {
          sentryError("NOTIFICATIONS", err);
          showError("Error occured, Please try again");
        });
    }
  };

  const addSequencing = (data) => {
    for (let i in data) {
      data[i].sequence = data.length - i;
      data[i].rank = parseInt(i) + 1;
      data[i].visible = true;
    }
    return data;
  };

  const clearRecentConsultation = () => {
    if (activeConsultation?.active === true) {
      trackConsultation(activeConsultation);
      setActiveConsultation({ active: false });
      FA_localStorage("lastActiveConsultation", "remove");
    }
  };

  const trackConsultation = (tData) => {
    let lastCallData = {};
    try {
      let lastCallInfo = localStorage.getItem('lastCallData');
      if (lastCallInfo) {
        lastCallData = JSON.parse(lastCallInfo);
      }
    } catch (error) {
      console.error('Error parsing JSON from localStorage:', error);
    }
    if (lastCallData?.consult_id && parseInt(lastCallData.consult_id) === parseInt(tData.id)) {
      if (tData.status === lastCallData.status || tData.status === 'initiated') {
        console.log("Already send : ", JSON.stringify(lastCallData))
        return;
      }
      tData.astrologerId = lastCallData.astro_id
    }

    if (tData?.astrologerId) {
      localStorage.removeItem('lastCallData');
    } else {
      console.log({ tData, lastCallData })
      return;
    }

    let consultType = 'Paid'
    let freeSeconds = 0
    if (tData?.free_minute) {
      freeSeconds = parseInt(tData.free_minute) * 60
      consultType = 'Free'
      if (tData?.cost && parseInt(tData.cost) > 0) {
        consultType = 'Free paid'
      }
    }
    const c_type = freeSeconds > 0 ? 'free' : 'paid'
    const duration = isNaN(parseInt(tData.time)) ? 0 : parseInt(tData.time);


    let walletBalance = parseInt(localStorage.getItem('walletBalance')) || 0;
    if (parseInt(tData.cost) > 0) {
      walletBalance = Math.max(walletBalance - parseInt(tData.cost), 0);
    }

    let points = tData.price.split("/");
    tData.points = parseInt(points[0]) || 0;

    if (tData.status === 'missed') {
      tData.status = 'Missed'
    }
    const data = {
      event: {
        name: 'Consultation',
        attributes: {
          'Screen Name': 'Chat Screen',
          'Consultation Name': 'Call',
          'Consultation Type': consultType,
          'Consultation Status': tData.status,
          'Consultation Duration': duration,
          'Consultation amount': `${tData.points} pts/min `,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Astrologer ID': tData.astrologerId,
          'Astrologer Name': tData.astrologer,
          'Product': 'COT',
          'Consultation ID': tData.id
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          [`Last consulted ${c_type} call astrologer ID`]: tData.astrologerId,
          [`Last consulted ${c_type} call astrologer name`]: tData.astrologer,
          'Last call attempt status': tData.status,
          'Last call attempt time stamp': Date.now(),
          'COT wallet balance': walletBalance,
          'Current wallet balance': walletBalance
        }
      },
      time: Date.now()
    }

    if (tData.status === 'completed') {
      localStorage.setItem('last_consulted_astrologer', tData.astrologerId)
      if (parseInt(tData.duration) > 0) {
        data.extra.user['Last consulted astrologer ID'] = tData.astrologerId
        data.extra.user['Last consulted astrologer name'] = tData.astrologer
        data.extra.user['Last consulted Id'] = tData.id
        data.extra.user['Last consulted type'] = `${consultType} call`;
      }
      if (tData?.totalRecharge) {
        data.extra.user['COT LTV'] = tData.totalRecharge;
      }
      if (tData?.consultCost) {
        data.extra.user['COT consultation charges'] = tData.consultCost;
      }
    }
    console.log(JSON.stringify(data));
    setTrackEventsData(data)
  }
  const showConsultationDetails = () => {
    if (activeConsultation.status === "completed") {
      clearRecentConsultation();
      window.location.href = "/consultant-feedback/" + activeConsultation.id + "?token=" + activeConsultation.token;
    } else if (activeConsultation.status === "initiated") {
      window.location.href =
        "/astrologer-connect/" +
        activeConsultation.id +
        "?token=" +
        activeConsultation.token +
        "&stkn=" +
        activeConsultation.status_token +
        "&prl=%2Fastrologer%2F" +
        activeConsultation.astrologerUrl;
    } else {
      checkRecentConsultations();
    }
  };

  const checkRecentConsultations = () => {
    clearRecentConsultation();
    window.location.href = "/user-consultation-history";
  };
  const checkPendingConsultation = (update) => {
    const lacData = JSON.parse(FA_localStorage("lastActiveConsultation") || "{}");
    if (lacData.token) {
      if (update.message === false || lacData.key === update.message.key) {
        axios()
          .post("/consultations/call/status", {
            id: lacData.id,
            token: lacData.token,
          })
          .then((res) => {
            //console.log("arun", res.data);
            if (res.data.status) {
              setActiveConsultation({
                id: lacData.id,
                astrologer: lacData.astrologer,
                active: true,
                status: res.data.status,
                token: lacData.token,
                status_token: lacData.status_token,
                astrologerUrl: lacData.astrologerUrl,
                time: res.data.time || 0,
                free_minute: res.data.free_consult_min || 0,
                cost: res.data.cost || 0,
                price: res.data.price || '0/min',
              });
            } else {
              clearRecentConsultation();
            }
          })
          .catch((err) => {
            sentryError("Call status check error", err);
          });
      }
    }
  };
  const bindRealtimeEvents = () => {
    if (astrologersData.length > 0 || promotionalList.length > 0) {
      if (typeof EventSource !== "undefined" && !window.statusChangeListener) {
        try {
          const es = new EventSource(STATUS_API_PATH);
          window.statusChangeListener = es;
          var listener = function (event) {
            if (typeof event.data !== "undefined") {
              const update = JSON.parse(event.data);
              if (validateOrigin(update.origin)) {
                //console.log("arun", update);
                if (update.type === "status" && update.message.astrologer_id) {
                  setPostUpdate(update);
                } else if (update.type === "consultation") {
                  checkPendingConsultation(update);
                }
              }
            }
          };
          window.simStatusUpdate = listener;
          window.statusChangeListener = es;
          es.addEventListener("message", listener);
          es.addEventListener("error", (event) => {
            //sentryError("SSE run:Error", event);
            console.error("SSEError", event);
          });
        } catch (err) {
          //sentryError("SSE init:Error", err);
          console.error("SSEError", err);
        }
      }
    }

    const validateOrigin = (origin) => {
      return window.location.origin.indexOf("http://localhost") >= 0 || window.location.origin === origin;
    };
  };

  const recalculateOnlineCount = () => {
    let onlineCount = 0;
    let promoOnlineCount = 0;
    let favOnlineCount = 0;

    astrologersData.map((astrologer) => {
      if (astrologer.visible) {
        if (astrologer.is_favourite) favOnlineCount = favOnlineCount + (astrologer.status === "online" ? 1 : 0);
        else onlineCount = onlineCount + (astrologer.status === "online" ? 1 : 0);
      }
    });
    promotionalList.map((astrologer) => {
      if (astrologer.visible) {
        if (astrologer.is_favourite) favOnlineCount = favOnlineCount + (astrologer.status === "online" ? 1 : 0);
        else promoOnlineCount = promoOnlineCount + (astrologer.status === "online" ? 1 : 0);
      }
    });
    setOnlineAstrologers({
      main: onlineCount,
      promo: promoOnlineCount,
      fav: favOnlineCount,
    });
    setduplicatesInList(checkDuplicatesInList());
    setListUpdated(Date.now());
  };

  const blastConfetti = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 1500);
    showStatusToast(offerDetails.nextCallDiscount + "% cashback already applied", "status");
  };
  const blastConfettiGuest = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 1500);
    showStatusToast("Register now & get " + offerDetails.nextCallDiscount + "% CASHBACK", "status");
  };

  const showStatusToast = (message, status) => {
    setToastBar({ message: message, status: status, open: true });

    if (window.toastHandle) clearTimeout(window.toastHandle);
    window.toastHandle = setTimeout(() => {
      setToastBar({ message: message, status: status, open: false });
    }, 3000);
  };

  const handleLogoClick = () => {
    window.logoClick = (window.logoClick || 0) + 1;
    if (window.logoClick > 5) {
      /** show credits */
      window.location.href = "/credits";
      window.logoClick = 0;
    } else if (window.logoClick > 3) {
      /** enable debug mode */
      const isInDebug = localStorage.debug ? true : false;
      if (isInDebug) {
        localStorage.removeItem("debug");
        showStatusToast("Debug mode disabled");
      } else {
        localStorage.setItem("debug", "yes");
        showStatusToast("Debug mode enabled");
      }
    }
  };
  const isFavAstrologersExisits = () => {
    if (favAstrologersList) {
      for (var i in favAstrologersList) {
        const astrologer = favAstrologersList[i];
        if (astrologer.visible === true) return true;
      }
    }
    return false;
  };
  /** tab config */
  const CONSULT_TAB = 0;
  const REMEDY_TAB = isChatEnabled ? 2 : 1;
  const CHAT_TAB = 1;
  const [currentTab, setCurrentTab] = useState(
    params.view === "remedy" ? REMEDY_TAB : params.view === "chat" ? CHAT_TAB : CONSULT_TAB
  ); //window.location.hash === "#pujadetails" ? 1 : 0);
  const freeCnsltTopBnrImg = (currentConsultType === 'call' ?
    "https://s3.ap-south-1.amazonaws.com/assets.findastro.com/images/offers/deal-claim-first-call-free.min.png"
    :
    "https://s3.ap-south-1.amazonaws.com/assets.findastro.com/images/offers/deal-claim-first-chat-free.min.png"
  );

  const switchPanel = (type) => {
    const event = {
      [CONSULT_TAB]: "fa_consult_tab",
      [REMEDY_TAB]: "fa_remedy_tab",
      [CHAT_TAB]: "fa_chat_tab",
    };
    trackActions(event[type], "NA", {});
    if (event[type] !== "fa_consult_tab") {
      window.location.hash = "#backtomain";
    } else {
      window.location.hash = "";
      window.lastHash = undefined;
    }
    setCurrentTab(type);
  };

  const checkConsultType = (astrologer, type) => {
    const ctype = type || currentConsultType
    if (ctype === 'chat' && !astrologer.chargesChat) {
      return false
    }

    if (ctype === 'call' && !astrologer.chargesAudio) {
      return false
    }

    return true
  }

  const switchCallChatOnlineAstrologers = (type) => {
    // console.log({type, recommendedData,promotionalList, otherAstrologersList});
    // const onlineAstrologers = [...recommendedData,...promotionalList, ...otherAstrologersList]
    // console.log({ 'astrologer': astrologersData, 'recommended': recommendedData })
    const astrologersStatusData = [...astrologersData, ...recommendedData]
      .reduce((acc, item) => {
        const existingItem = acc.ids.find(current => current === item.id);
        if (!existingItem && checkConsultType(item, type)) {
          if (item.status === 'online') {
            acc.online += 1;
          } else if (item.status === 'away') {
            acc.away += 1;
          } else if (item.status === 'busy') {
            acc.busy += 1;
          }
          acc.ids.push(item.id)
        }
        return acc;
      }, { online: 0, away: 0, busy: 0, ids: [] });

    //console.log({type, online: onlineAstrologersData})
    //console.log(astrologersStatusData)
    const payload = {
      event: {
        name: 'Screen View Action',
        attributes: {
          'Screen Name': 'Chat or Talk',
          'Product': 'COT',
          'Tab Name': type,
          'Online Astrologer Count': astrologersStatusData.online,
          'Busy Astrologer Count': astrologersStatusData.busy,
          'Away Astrologer Count': astrologersStatusData.away,
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          //'COT User Status': (BrowserUtils.CurrentUserInfo().id) ? 'Registered' : 'Guest'
        }
      },
      extra: {
      },
      time: Date.now()
    }
    console.log('send moengage event:payload:', payload)
    //setTrackEventsData(payload);
    T2AEventTrack(payload.event, payload.extra)
  }

  const switchConsultType = (type) => {
    //moengage(capitalizeFirstLetter(type) + ' Tab View')
    setCurrentConsultType(type)
    setDealPurchase(deal => ({ ...deal, type: type }))
    switchCallChatOnlineAstrologers(type)
  }
  /** tab config */
  const container = useRef();

  useEffect(() => {
    const left = currentTab * container.current.clientWidth;
    container.current.scrollLeft = left;
    //if (currentTab > 0) setScrollPercentage(0.0);
  }, [currentTab]);
  //method debouncer to deal with keypress
  //scroll etc

  useEffect(() => {
    if (pendingReview.astrologer?.id && !pendingReview.is_submitted) {
      console.log('update review on moengage event')
      trackReviewRating('Screen View Action', pendingReview)
    }
  }, [pendingReview.astrologer]);

  useEffect(() => {
    if (DealPurchaseConfig?.is_enabled && dealPurchase?.have_deal_purchase && dealPurchase?.deal) { //No changes
    } else if (showFreeConsultTopBanner) {
      setTrackEventsData({
        event: {
          name: 'Screen View Action',
          attributes: {
            'Screen Name': 'Deal claim banner',
            'Source Screen Name': 'Chat or Talk',
            'Product': 'COT',
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name
          }
        },
        extra: {
        },
        time: Date.now()
      });
    }
  }, [showFreeConsultTopBanner, dealPurchase]);

  useEffect(() => {
    //console.log('send moengage event:', trackEventsData)
    if (trackEventsData?.time) {
      T2AEventTrack(trackEventsData.event, trackEventsData.extra)
      console.log(trackEventsData)
    }
    return () => {
      if (trackEventsData?.time && trackEventsData?.redirect) {
        T2AEventTrack(trackEventsData.event, trackEventsData.extra)
        console.log('run event before page redirect', trackEventsData)
      }
    };
  }, [trackEventsData.time]);


  useEffect(() => {
    if (activeConsultation?.active) {
      if (activeConsultation?.active === true && activeConsultation?.status !== 'initiated' && activeConsultation?.status !== 'accepted') {
        setTimeout(() => {
          clearRecentConsultation();
        }, 3000);
      }
    }
  }, [activeConsultation]);

  useEffect(() => {
    if (dealPurchase?.deals?.length && localStorage.getItem('dealOptions') === null) {
      localStorage.setItem('dealOptions', JSON.stringify(dealPurchase.deals))
    }
  }, [dealPurchase.deals]);

  const tabViewIndex = {};
  const handleTabSwitch = (e) => {
    debounce(() => {
      tabViewIndex.current = Math.round(container.current.scrollLeft / container.current.clientWidth);
      if (tabViewIndex.current !== currentTab) {
        switchPanel(tabViewIndex.current);
      }
    }, 600);
  };

  const filterAstrologerList = (astrologers) => {
    return astrologers.filter(astrologer => {

      // Check busy, away
      // if (astrologer.name === 'Subha L Nair') {
      //   astrologer.status = 'busy'
      // }

      // if (astrologer.id === 62) {
      //   astrologer.status = 'away'
      // }

      // if (astrologer.name === 'Saritha K G') {
      //   astrologer.status = 'online'
      // }

      // if (astrologer.name === 'Vasanth T') {
      //   astrologer.status = 'online'
      // }

      // if (astrologer.name === 'Anilkumar Shanthi' || astrologer.name === 'Madhu N Aravindakshan') {
      //   astrologer.status = 'online'
      //   astrologer.free_call_minutes = 5
      //   astrologer.free_chat_minutes = 5
      // }

      // Chat enabled astrologers
      if (currentConsultType === 'chat') {
        if (astrologer.chargesChat) {
          return astrologer
        } else {
          return null
        }
      }

      return astrologer
    })
  }

  const testAndroid = () => {
    console.log('testAndroid')
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      const profileData = window.AndroidBridge.call("getAppProfileData")
      BrowserUtils.devAlert('AndroidBridge.getAppProfileData: ' + profileData)
    }
  }

  const trackUserAttributes = (data) => {
    if (data?.totalRecharge || data?.consultCost) {
      let userData = {
        event: {},
        time: Date.now(),
        extra: {
          user: {}
        }
      };
      if (data?.totalRecharge) {
        userData.extra.user['COT LTV'] = data.totalRecharge;
      }
      if (data?.consultCost) {
        userData.extra.user['COT consultation charges'] = data.consultCost;
      }
      setTrackEventsData(userData)
    }
  }


  const [headerOptMenuEl, setHeaderOptMenuEl] = useState(null);
  const headerOptMenuOpen = Boolean(headerOptMenuEl);

  const headerOptMenuClick = (event) => {
    const eData = {
      'buttonName': 'Kebab Menu',
      'targetScreenName': 'Chat or Talk',
      'location': 'Header',
      'variant': 'Open Kebab Menu'
    }
    trackeventData(eData);
    setHeaderOptMenuEl(event.currentTarget)
  }

  const headerOptMenuClose = (path) => {
    setHeaderOptMenuEl(null)
    BrowserUtils.devAlert('headerOptMenuClose outside click')
    const eData = {
      'buttonName': 'Kebab Menu',
      'targetScreenName': 'Chat or Talk',
      'location': 'Header',
      'variant': 'Close Kebab Menu'
    }
    trackeventData(eData);
  }

  const headerOptMenuItemClick = (path, event_name) => {
    setHeaderOptMenuEl(null)
    BrowserUtils.devAlert('headerOptMenuItemClick')
    BrowserUtils.devAlert(path)

    if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn() && path) {
      history.push(path)
      BrowserUtils.devAlert('headerOptMenuItemClick > go to link')
      const eData = {
        'redirect': 1,
        'buttonName': event_name,
        'targetScreenName': path.replace(/\//g, ''),
        'location': 'Kebab menu'
      }
      trackeventData(eData);
    } else {
      showVerifyPopup()
    }
  }

  const hamburgerMenuTrigger = () => {
    window.hamburgerMenuClick()
  }

  const gotoLog = () => {
    history.push("/log");
  }

  const onDealPurchaseUpdate = (dealData) => {
    if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
      setDealPurchase(dealData)
    } else {
      showVerifyPopup()
      setDealPurchase(data => {
        const newData = { ...data, ...{ ...dealData, open: false } }
        if (newData.refData) {
          newData.refData.afterLogin = true
          rechargeRedirect.setData(newData.refData)
        }
        return newData
      })
    }
  }

  return (
    <ChatWindowStateProvider value={chatWindowData}>
      <ChatWindowStateSetterProvider value={setChatWindowData}>
        <ChatNotificationTrigger setChatWindowData={setChatWindowData} setLoading={setLoading} openProfile={showAstrologerProfile} handleUnLogged={handleUnLogged} />
        <Box
          className={clsx(
            isMobile ? classes.container : classes.container,
            "tabbled-container-app",
            'new-style',
            'new-t2a',
            isUserLoggedIn() ? 'logged-in' : 'not-logged-in',
            isMobile ? 'is-mobile' : ''
          )}
          ref={container}
          id={"tab-switch-control"}
        // onTouchEnd={handleTabSwitch}
        >
          <IsSupported />
          <HelmetComponent
            title="Welcome To FindAstro, Get Connected and Talk with Astrologers in India"
            url="https://www.findastro.com/astrologers"
            description="Welcome To FindAstro-We are # 1 Astrologer website in India. Get connected and Talk with reputed astrologers in India at best rates."
          />
          {astrologerConsultLoading?.astrologer && <AstrologerLoadingModal opt={astrologerConsultLoading} />}
          {isLoading ? (
            <div className="home-main-circle-loading circle-loading circle-loading-20px">
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          <div className={`autoHideHeader`}>
            <Grid container className={clsx(classes.header, 'header-bar')}>
              <Grid id="hamburgerMenu" style={{ visibility: BrowserUtils.isDevMode() ? 'visible' : 'hidden' }} item xs={2} lg={1}>
                <Avatar
                  variant="rounded"
                  className={classes.rounded}
                  onClick={() => {
                    moengage("HAMBURGER_CLICKED");
                    setDrawerOpenState(true);
                    window.stopAllAudio();
                    testAndroid();
                  }}>
                  <MenuIcon />
                </Avatar>
              </Grid>
              {/* {isMobile || true ? (
                <Grid item xs={4} className={classes.marTop5Mobile}>
                  <br></br>
                  <img src={logo} alt="Logo" className={classes.mobileLogo} onClick={handleLogoClick} />
                </Grid>
              ) : (
                <Grid item xs={5} className={classes.marTop5}>
                  <div className={classes.desktopTopFlex}>
                    <img src={logo} alt="Logo" className={classes.desktopLogo} />
                    <div className={classes.desktopCustomerCare}>
                      Support:{" "}
                      <a
                        target="_blank"
                        href={"tel:" + String(SUPPORT_NUMBER).replace(/\s/g, "")}
                        className={classes.desktopCustomerCare}>
                        {SUPPORT_NUMBER}
                      </a>
                    </div>
                  </div>
                  Find your <b className={classes.color}>Astrologer</b>
                </Grid>
              )} */}
              <Grid container item xs={8} style={{ justifyContent: "flex-end" }}>
                <div className={"titleBarIcon"}>
                  <SearchIcon
                    onClick={onSearch}
                    fontSize="small"
                    style={{
                      marginRight: 8,
                      display: currentTab === CONSULT_TAB ? "inline-block" : "none",
                    }}
                  />
                </div>
                <div className={"titleBarIcon"}>
                  {filterData.sortBy ? (
                    <FilterIconRound
                      onClick={showSortBox}
                      fontSize="small"
                      style={{
                        marginRight: 8,
                        display: currentTab === CONSULT_TAB ? "inline-block" : "none",
                      }}
                    />
                  ) : (
                    <FilterIcon
                      onClick={showSortBox}
                      fontSize="small"
                      style={{
                        marginRight: 8,
                        display: currentTab === CONSULT_TAB ? "inline-block" : "none",
                      }}
                    />
                  )}
                </div>
                <RechargeLinkRedirect
                  refData={{
                    refID: 'home/wallet-top-icon',
                    highlight: 499,
                    location: 'Wallet Icon',
                    // astrologerID: astrologerFullProfile.id,
                    // astrologerName: astrolgerInfo.name,
                    // type: 'chat',
                    // maxAmount: 1000,
                  }}
                  onClick={(e) => {
                    const eData = {
                      'redirect': 1,
                      'buttonName': 'Wallet Icon',
                      'targetScreenName': 'My Wallet',
                      'location': 'Header'
                    }
                    trackeventData(eData);
                    if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
                      onWallet(e)
                    } else {
                      showVerifyPopup()
                    }
                  }}
                >
                  <div className={"titleBarIcon"} >
                    <AccountBalanceWalletIcon fontSize="small" />
                    &nbsp;{localStorage["walletBalance"] || 0} pts
                  </div>
                </RechargeLinkRedirect>

                <DealPurchaseDialog
                  astrologer={dealPurchase.astrologer}
                  deal={dealPurchase.deal}
                  type={dealPurchase.type}
                  open={dealPurchase.open}
                  refData={dealPurchase.refData}
                  isRepeatUser={dealPurchase.isRepeatUser}
                  clickFrom={props?.clickFrom || 'Chat or Talk'}
                  handleClose={() => {
                    setDealPurchase(deal => ({ ...deal, open: false }))
                  }}
                />

                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={headerOptMenuClick}
                  className={"titleBarIcon headerOptMenuIcon"}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="top-el-menu"
                  anchorEl={headerOptMenuEl}
                  keepMounted
                  open={headerOptMenuOpen}
                  onClose={headerOptMenuClose}
                  className="headerOptMenu"
                >
                  {headerOptMenuOptions.map((option) => (
                    <MenuItem
                      key={option[0]}
                      // selected={option === 'Pyxis'}
                      onClick={() => headerOptMenuItemClick(option[1], option[0])}
                    >
                      {option[0]}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
          </div>
          <Grid container className="footer-bar">
            <Grid item xs={12} id={"headerBar"}>
              <div className={!isChatEnabled() ? "tabbed-head tabbed-head-col-2" : "tabbed-head"}>
                {/* <span
                  className={currentTab === CONSULT_TAB ? "current" : ""}
                  onClick={(e) => switchPanel(CONSULT_TAB)}
                  onTouchEnd={(e) => e.stopPropagation()}>
                  <b>{isChatEnabled() ? `Astrologers` : `Consultations`}</b>
                </span>
                {isChatEnabled() && (
                  <span
                    className={currentTab === CHAT_TAB ? "current" : ""}
                    onClick={(e) => switchPanel(CHAT_TAB)}
                    onTouchEnd={(e) => e.stopPropagation()}>
                    <b>Chat</b>
                  </span>
                )} */}

                <span
                  className={currentConsultType === 'chat' ? "current" : ""}
                  onClick={(e) => switchConsultType('chat')}
                  onTouchEnd={(e) => e.stopPropagation()}>
                  <b>
                    <ChatBubbleOutlineOutlinedIcon />
                    <span>Chat</span>
                  </b>
                </span>
                <span
                  className={currentConsultType === 'call' ? "current" : ""}
                  onClick={(e) => switchConsultType('call')}
                  onTouchEnd={(e) => e.stopPropagation()}>
                  <b>
                    <CallIcon />
                    <span>Call</span>
                  </b>
                </span>

                {/* <span
                    className={currentTab === REMEDY_TAB ? "current" : ""}
                    onClick={(e) => switchPanel(REMEDY_TAB)}
                    onTouchEnd={(e) => e.stopPropagation()}>
                    <b>Call</b>
                  </span> */}
              </div>
            </Grid>
            <div
              className={clsx("toastBar", toastBar.status, {
                toastBarOpen: toastBar.open === true,
              })}>
              {toastBar.message}
            </div>
          </Grid>
          {isMobile && BrowserUtils.GooglePlaySupport && !window.AndroidBridge && (
            <div className="app-install-banner" onClick={handleDownload}>
              <span>Install Findastro app from Google Play</span>
              <button>Install</button>
            </div>
          )}
          <Grid item xs={12} lg={12} className={[classes.desktopSearchBar, "searchBar"]}>
            <Grid
              className="header-spacer smooth-height"
              style={{
                transition: "height 0.4s ease-out 0.2s",
              }}></Grid>
            {/* {!isUserLoggedIn() ? (
              <div
                style={{
                  padding: "8px 20px",
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#fbead0",
                  color: "#000000",
                  position: "fixed",
                  width: "100%",
                  bottom: "0",
                  zIndex: "100",
                  maxWidth: 460,
                  boxShadow: "0 -2px 20px rgb(0 0 0 / 50%)",
                }}
                onClick={() => {
                  window.location.href = "/login";
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(100% - 80px)",
                  }}>
                  <div>
                    <WarningRoundedIcon
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block",
                        fill: "#ee600d",
                      }}
                    />

                    <b
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block",
                      }}>
                      You are not logged in
                    </b>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                    }}>
                    Login to use the consultancy services
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "80px",
                  }}>
                  <button
                    style={{
                      border: "none",
                      padding: "8px",
                      borderRadius: "18px",
                      boxShadow: "none",
                      backgroundColor: "#ed6237",
                      color: "#fff",
                    }}>
                    Login
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )} */}
            {/* <Grid container className="searchWrap">
          <Grid item xs={11} className="searchInput">
            <InputBase
              value={searchText}
              className={(classes.margin, classes.searchInput)}
              placeholder="Search Astrologer by name, skills, category, etc."
              onChange={onSearchText}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  onSearch();
                }
              }}
            />
          </Grid>
          <Grid item xs={1} className="searchIcon">
            <SearchIcon onClick={onSearch} />
          </Grid>
        </Grid> */}
          </Grid>

          {(isChatEnabled() && isUserLoggedIn()) &&
            <div id="new-chat">
              <Conversations showAstrologerProfile={showAstrologerProfile} setIsConvLoading={setIsConvLoading} />
            </div>
          }

          <div className={["consultation", "tab-panel"].join(" ")} onScroll={ScrollViewHandler}>
            {isUserLoggedIn() && parseInt(localStorage["walletBalance"]) < 300 && (
              <Grid container>
                <Grid item xs={12} id={"csheaderBar"}>
                  <RechargeLinkRedirect
                    refData={{
                      refID: 'home/low-balance-recharge-now-button',
                      highlight: 499,
                      maxAmount: 1000,
                      location: 'Low balance',
                      // astrologerID: astrologerFullProfile.id,
                      // astrologerName: astrolgerInfo.name,
                      // type: 'chat',
                    }}
                    onClick={(e) => {
                      const eData = {
                        'redirect': 1,
                        'buttonName': 'Recharge now',
                        'targetScreenName': 'User Wallet',
                        'location': 'Low balance banner',
                        'sourceScreen': 'Low balance banner',
                        'name': 'Buy Now Action'
                      }
                      trackeventData(eData);
                      if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
                        onWallet(e)
                      } else {
                        showVerifyPopup()
                      }
                    }}
                  >

                    <div className="titleSectionWrap" onClick={() => {
                    }}>
                      <b>Low Wallet balance: {localStorage["walletBalance"] || 0} pts.</b>
                      <button>Recharge now</button>
                      {/* {isUserLoggedIn() && parseInt(localStorage["walletBalance"]) < 300 ? (
                  <>
                    <b>Low Wallet balance: {localStorage["walletBalance"] || 0} pts.</b>
                    <button>Recharge now</button>
                  </>
                ) : (
                  <b>Please login to view your wallet balance</b>
                )} */}
                      {/*<div style={{textAlign: "center"}}> <Avatar
                  variant="rounded"
                  className={clsx(isMobile ? classes.titleFilterIcons : classes.desktopTitleFilterIcons, {
                    availableFilterActive: filterData.availability === "online",
                  })}
                  onClick={toggleShowOnlyOnline}>
                  <b>
                    Show only online ({onlineAstrologers.main + onlineAstrologers.fav + onlineAstrologers.promo || 0})
                  </b>
                  <span class="switch-slider">
                    <i class="handle"></i>
                    <input type="checkbox" defaultChecked={showOnlyOnline} />
                    <i class="roll"></i>
                  </span>
                </Avatar>
                <Avatar
                  variant="rounded"
                  onClick={showSortBox}
                  className={clsx(isMobile ? classes.titleFilterIcons : classes.desktopTitleFilterIcons, {
                    availableFilterActive: filterData.category || filterData.expertise || filterData.language,
                  })}>
                  <b>Sorted by:</b>&nbsp;
                  <b>
                    {filterData.sortBy === "rank" ? (
                      <Assistant />
                    ) : filterData.sortBy === "exp" ? (
                      <FlareIcon />
                    ) : filterData.sortBy === "phtl" ? (
                      <TrendingDownIcon />
                    ) : filterData.sortBy === "plth" ? (
                      <TrendingUpIcon />
                    ) : filterData.sortBy === "reviews" ? (
                      <GradeIcon />
                    ) : (
                      <Assistant />
                    )}
                  </b>
                </Avatar></div> */}
                    </div>
                  </RechargeLinkRedirect>
                </Grid>
              </Grid>
            )}
            {isUserLoggedIn() && localStorage.getItem("showLangPrefBnr") == 1 && (
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.greyTopBnr}>
                    Language preference applied
                    <span className={classes.greyTopBnrBtn} onClick={e => {
                      localStorage.removeItem("showLangPrefBnr");
                      trackeventData({
                        'screenName': 'Language preference banner',
                        'redirect': 1,
                        'buttonName': 'Verify Now',
                        'targetScreenName': 'Language preference',
                        'location': 'Top banner'
                      });
                      history.push('/my-favorite-languages')
                    }}>Verify Now</span>
                  </div>
                </Grid>
              </Grid>
            )}

            {
              DealPurchaseConfig.is_enabled && dealPurchase.have_deal_purchase && dealPurchase.deal && dealPurchase?.deals?.length ?
                <DealPurchaseBanner
                  dealData={dealPurchase}
                  onDealPurchaseUpdate={onDealPurchaseUpdate}
                /> :
                (
                  showFreeConsultTopBanner ?
                    <Grid container>
                      <Grid item xs={12}>
                        <img src={freeCnsltTopBnrImg} alt="free consultation" className={classes.freeCnsltTopBnrImg} />
                      </Grid>
                    </Grid> : <RechargeBanner hasRecharge={hasRecharge} />
                )
            }

            {astrologersData.length > 0 ? (
              <div
                className={clsx(classes.familyDiv, {
                  onlineCardsOnly: showOnlyOnline === true,
                })}>
                <InfiniteScroll
                  dataLength={astrologersData.length}
                  // next={fetchMoreData}
                  onScroll={ScrollViewHandler}
                  hasMore={hasMore}
                  loader={
                    isFetching && (
                      <div className="circle-loading extra-circle2">
                        <CircularProgress />
                      </div>
                    )
                  }
                  className={isMobile ? "" : classes.desktopScroller}>
                  {/* <Grid item xs={12} lg={12} className={"expertiseFilter"}>
              <div>I need expert advice on...</div>
              <div>
                {astrologerListData.filterCategories[0].options.map((category) => (
                  <span
                    className={clsx(classes.filterButton, "filterButton")}
                    category={category.text}
                    onClick={filterByCategory}>
                    <CheckCircle className={"filterIcon"} />
                    <b>{category.text}</b>
                  </span>
                ))}
              </div>
            </Grid> */}
                  {/* <div className="group-container-dp predictions-gp">
                    <DailyPredictionWidget
                      ctaAction={callFirstOnlineAstrologer}
                      expanded={dailyPredictionOpen}
                      handleClose={closeAllModalPopups}
                      expandView={setDailyPredictionOpen}
                    />
                  </div> */}
                  {!showBVarient && (
                    <div className="group-container offer-astrologers">
                      {showPromoList &&
                        promotionalList.length > 0 &&
                        !(showOnlyOnline && onlineAstrologers.promo === 0) ? (
                        <Grid xs={12}>
                          <div className="pred-cover"></div>
                          <div className={"promotionalList-no"}>
                            <div className={"promoListItems"}>
                              <div className="row" onScroll={window.stopAllAudio}>
                                {promotionalList.map((astrologer, index) => {
                                  if (
                                    astrologer.is_favourite === false &&
                                    astrologer.visible &&
                                    !(showOnlyOnline && astrologer.status !== "online")
                                    // && checkConsultType(astrologer)
                                  )
                                    return (
                                      <AstrologerDetail
                                        hide={!checkConsultType(astrologer)}
                                        currentConsultType={currentConsultType}
                                        currentFilter={currentFilter}
                                        openProfile={showAstrologerProfile}
                                        family={astrologer}
                                        handleCall={openCallDialog}
                                        handleFavourite={handleFavourite}
                                        handleOfferIcon={handleOfferIcon}
                                        offerDiscount={offerPromoDetails.promotionalDiscount}
                                        type={"promotional"}
                                        key={`pl-${astrologer.id}`}
                                        isSearch={false}
                                        showMessage={showStatusToast}
                                        showLoading={setLoading}
                                        offerClaim={offerClaim}
                                        handleNotify={handleNotifyMe}
                                        isNotifiedEnable={
                                          notifications.initiated &&
                                          notifications.initiated.indexOf(astrologer.id) >= 0
                                        }
                                      />
                                    );
                                  else return "";
                                })}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                  {/* <div className="group-container">
                {noOnlineAstrologers() && showOnlyOnline ? (
                  <div style={{padding: "0 4px"}}>
                    <Paper className="notFoundPaper" elevation={3}>
                      <div>Sorry, no astrologers are online now!</div>
                    </Paper>
                  </div>
                ) : (
                  <></>
                )}

                {favAstrologersList.length + favPromoAstrologersList.length > 0 ? (
                  <div className={classes.sectionHeading}>Your favourite astrologers</div>
                ) : (
                  ""
                )}

                {showOnlyOnline && onlineAstrologers.fav === 0 ? (
                  favAstrologersList.length + favPromoAstrologersList.length > 1 ? (
                    <div className="noFavAstrologer">
                      <div>None of your favourite astrologers are online now.</div>
                    </div>
                  ) : (
                    favAstrologersList.length + favPromoAstrologersList.length > 0 && (
                      <div className="noFavAstrologer">
                        <div>Your favourite astrologer is not online now.</div>
                      </div>
                    )
                  )
                ) : (
                  ""
                )}
                {favPromoAstrologersList.map(
                  (astrologer, index) =>
                    astrologer.visible && (
                      <div className="fapromotionalList full">
                        <Grid item xs={12} key={astrologer.id}>
                          <AstrologerDetail
                                          hide={!checkConsultType(astrologer)}
                            currentConsultType={currentConsultType}
                            currentFilter={currentFilter}
                            openProfile={showAstrologerProfile}
                            family={astrologer}
                            handleFavourite={handleFavourite}
                            handleOfferIcon={handleOfferIcon}
                            offerDiscount={offerPromoDetails.promotionalDiscount}
                            key={astrologer.id}
                            isSearch={false}
                            type={"promotional"}
                            showMessage={showStatusToast}
                            showLoading={setLoading}
                            handleCall={openCallDialog}
                            offerClaim={offerClaim}
                            handleNotify={handleNotifyMe}
                            isNotifiedEnable={
                              notifications.initiated && notifications.initiated.indexOf(astrologer.id) >= 0
                            }
                          />
                        </Grid>
                      </div>
                    )
                )}
                {favAstrologersList.map(
                  (astrologer, index) =>
                    astrologer.visible && (
                      <>
                        <Grid item xs={12} key={astrologer.id}>
                          <AstrologerDetail
                                          hide={!checkConsultType(astrologer)}
                            currentConsultType={currentConsultType}
                            currentFilter={currentFilter}
                            openProfile={showAstrologerProfile}
                            family={astrologer}
                            handleFavourite={handleFavourite}
                            handleOfferIcon={handleOfferIcon}
                            offerDiscount={offerDetails.nextCallDiscount}
                            key={astrologer.id}
                            isSearch={false}
                            showMessage={showStatusToast}
                            showLoading={setLoading}
                            handleCall={openCallDialog}
                            offerClaim={offerClaim}
                            handleNotify={handleNotifyMe}
                            isNotifiedEnable={
                              notifications.initiated && notifications.initiated.indexOf(astrologer.id) >= 0
                            }
                          />
                        </Grid>
                      </>
                    )
                )}
              </div> */}
                  <div className="group-container no-padding" onClick={window.stopAllAudio}>
                    <div>
                      {isFavAstrologersExisits() && otherAstrologersList.length > 0 ? (
                        <div className={classes.sectionHeading}>More astrologers</div>
                      ) : (
                        ""
                      )}
                      {/* next call CASHBACK banner {FA_localStorage("phone") && FA_localStorage("email") ? (
                    offerDetails.nextCallDiscount > 0 ? (
                      <div className={"offerSection"}>
                        <div className={"nextCallOffer"}>
                          <Grid xs={12} className={"offerStampBanner"} onClick={blastConfetti}>
                            <OfferStamp className={"offerIcon"} />
                            <TYBackdrop className={"backdrop"} />
                            <span className={"txtIcon"}>
                              {offerDetails.nextCallDiscount}
                              <small>%</small>
                            </span>
                            <div className={"offerText"}>
                              <div>
                                <div>
                                  <b>Congratulations!</b>
                                  <TYBackdrop
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      marginLeft: "8px",
                                      verticalAlign: "middle",
                                      marginTop: "-4px",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    fontWeight: 700,
                                    color: "#ee600d",
                                    marginBottom: 16,
                                  }}>
                                  {" "}
                                  Next call CASHBACK unlocked.
                                </div>
                                <div>
                                  Get a minimum of{" "}
                                  <b style={{color: "#ee600d"}}>{offerDetails.nextCallDiscount}% CASHBACK</b>
                                  on any of your next consultation.
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </div>
                        <BlastConfetti showConfetti={showConfetti} />
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className={"offerSection"}>
                      <div className={"nextCallOffer"}>
                        <Grid
                          xs={12}
                          className={"offerStampBanner"}
                          style={{padding: "14px 8px"}}
                          onClick={blastConfettiGuest}>
                          <OfferStamp className={"offerIcon"} />
                          <TYBackdrop className={"backdrop"} />
                          <span className={"txtIcon"}>
                            {offerDetails.nextCallDiscount}
                            <small>%</small>
                          </span>
                          <div className={"offerText"}>
                            <div>
                              <div
                                style={{
                                  fontWeight: 700,
                                  color: "#ee600d",
                                  marginBottom: 8,
                                }}>
                                {" "}
                                GET SPECIAL DISCOUNTS!
                              </div>
                              <div style={{lineHeight: 1.3}}>
                                Register now &amp; get{" "}
                                <b style={{color: "#ee600d"}}>{offerDetails.nextCallDiscount}% CASHBACK</b> on your
                                first consultation and{" "}
                                <b style={{color: "#ee600d"}}>FREE EXTRA POINTS ON EVERY RECHARGE</b>.
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </div>
                      <BlastConfetti showConfetti={showConfetti} />
                    </div>
                  )} */}
                      {/* {FA_localStorage("phone") && FA_localStorage("email") && (
                    <div
                      style={{
                        backgroundImage: "linear-gradient(40deg,#ff2a00,#ff9a00)",
                        borderRadius: 10,
                        marginTop: 14,
                      }}>
                      <RefAndEarn className="banner-svg" onClick={(e) => setRefAndEarnModelOpen(true)} />
                      {refAndEarnModelOpen !== null && (
                        <ReferEarnModal
                          modalOpen={refAndEarnModelOpen}
                          setModalStatus={setRefAndEarnModelOpen}
                          showStatusToast={showStatusToast}
                          screen="home"
                        />
                      )}
                      {referEarnPendingCallout && (
                        <ReferEarnPendingCallout
                          data={referEarnPendingCallout}
                          modalOpen={referEarnPendingCallout}
                          BlastConfetti={BlastConfetti}
                          TYBackdrop={TYBackdrop}
                          onClose={setReferEarnPendingCallout}
                          screen="home"
                        />
                      )}
                    </div>
                  )} */}
                    </div>
                    <div className="ast-section-title online">
                      <b>Online Astrologers ({onlineAstrologers.main + onlineAstrologers.promo})</b>
                    </div>
                    {onlineAstrologers.main === 0 && onlineAstrologers.promo === 0 && (
                      <div style={{ textAlign: "center", padding: "8px 16px" }}>
                        No astrologers are currently online. Please change your language preference to see more
                        astrologers.
                      </div>
                    )}
                    {showBVarient ? (
                      <div className="astrologer-cards-holder">
                        {(function () {
                          let promoRendered = 0;
                          const renderStack = otherAstrologersList.map((astrologer, index) => {
                            promoRendered += promotionalList[index] ? 1 : 0;
                            return (
                              astrologer.visible &&
                              (astrologer.status === "online" || astrologer.status === "busy") && (
                                <React.Fragment key={astrologer.id}>
                                  <AstrologerCardComponent
                                    currentFilter={currentFilter}
                                    openProfile={showAstrologerProfile}
                                    family={astrologer}
                                    offerDiscount={offerDetails.nextCallDiscount}
                                    promoDiscount={offerPromoDetails.promotionalDiscount}

                                    isSearch={false}
                                    showMessage={showStatusToast}
                                    handleFavourite={handleFavourite}
                                    handleOfferIcon={handleOfferIcon}
                                    showLoading={setLoading}
                                    handleCall={openCallDialog}
                                    offerClaim={offerClaim}
                                    handleNotify={handleNotifyMe}
                                    isNotifiedEnable={
                                      notifications.initiated && notifications.initiated.indexOf(astrologer.id) >= 0
                                    }
                                  />
                                  {promotionalList[index] && (
                                    <AstrologerCardComponent
                                      currentFilter={currentFilter}
                                      promotional={true}
                                      openProfile={showAstrologerProfile}
                                      family={promotionalList[index]}
                                      offerDiscount={offerPromoDetails.promotionalDiscount}
                                      promoDiscount={offerPromoDetails.promotionalDiscount}
                                      key={promotionalList[index].id}
                                      isSearch={false}
                                      showMessage={showStatusToast}
                                      handleFavourite={handleFavourite}
                                      handleOfferIcon={handleOfferIcon}
                                      showLoading={setLoading}
                                      handleCall={openCallDialog}
                                      offerClaim={offerClaim}
                                      handleNotify={handleNotifyMe}
                                      isNotifiedEnable={
                                        notifications.initiated &&
                                        notifications.initiated.indexOf(promotionalList[index].id) >= 0
                                      }
                                    />
                                  )}
                                </React.Fragment>
                              )
                            );
                          });
                          if (promoRendered < promotionalList.length - 1) {
                            promotionalList.slice(promoRendered).forEach((astrologer) => {
                              renderStack.push(
                                <AstrologerCardComponent
                                  currentFilter={currentFilter}
                                  promotional={true}
                                  openProfile={showAstrologerProfile}
                                  family={astrologer}
                                  offerDiscount={offerPromoDetails.promotionalDiscount}
                                  key={astrologer.id}
                                  isSearch={false}
                                  showMessage={showStatusToast}
                                  handleFavourite={handleFavourite}
                                  handleOfferIcon={handleOfferIcon}
                                  showLoading={setLoading}
                                  handleCall={openCallDialog}
                                  offerClaim={offerClaim}
                                  handleNotify={handleNotifyMe}
                                  isNotifiedEnable={
                                    notifications.initiated && notifications.initiated.indexOf(astrologer.id) >= 0
                                  }
                                />
                              );
                            });
                          }
                          return renderStack;
                        })()}
                      </div>
                    ) : (
                      <>
                        <div style={{ padding: "1px 8px" }}>
                          {otherAstrologersList.map(
                            (astrologer, index) => {
                              return (
                                astrologer.visible &&
                                (astrologer.status === "online" || astrologer.status === "busy")
                                // && checkConsultType(astrologer)
                              ) && (
                                  <Grid item xs={12} key={astrologer.id}>
                                    <AstrologerDetail
                                      hide={!checkConsultType(astrologer)}
                                      currentConsultType={currentConsultType}
                                      currentFilter={currentFilter}
                                      openProfile={showAstrologerProfile}
                                      family={astrologer}
                                      offerDiscount={offerDetails.nextCallDiscount}
                                      key={`oal-${astrologer.id}`}
                                      isSearch={false}
                                      showMessage={showStatusToast}
                                      handleFavourite={handleFavourite}
                                      handleOfferIcon={handleOfferIcon}
                                      showLoading={setLoading}
                                      handleCall={openCallDialog}
                                      offerClaim={offerClaim}
                                      handleNotify={handleNotifyMe}
                                      isNotifiedEnable={
                                        notifications.initiated && notifications.initiated.indexOf(astrologer.id) >= 0
                                      }
                                    />
                                  </Grid>
                                )
                            }
                          )}
                        </div>
                      </>
                    )}
                    <div className="ast-section-title" style={{ marginBottom: "-14px" }}>
                      <b>Currently Offline Astrologers - Book for later</b>
                    </div>
                    <div style={{ padding: "1px 8px" }}>
                      {otherAstrologersList.map(
                        (astrologer, index) =>
                          (
                            astrologer.visible &&
                            (astrologer.status === "offline" || astrologer.status === "away")
                            // && checkConsultType(astrologer)
                          ) && (
                            <Grid item xs={12} key={astrologer.id}>
                              <AstrologerDetail
                                hide={!checkConsultType(astrologer)}
                                currentConsultType={currentConsultType}
                                currentFilter={currentFilter}
                                openProfile={showAstrologerProfile}
                                family={astrologer}
                                offerDiscount={offerDetails.nextCallDiscount}
                                key={`oal2-${astrologer.id}`}
                                isSearch={false}
                                showMessage={showStatusToast}
                                handleFavourite={handleFavourite}
                                handleOfferIcon={handleOfferIcon}
                                showLoading={setLoading}
                                handleCall={openCallDialog}
                                offerClaim={offerClaim}
                                handleNotify={handleNotifyMe}
                                isNotifiedEnable={
                                  notifications.initiated && notifications.initiated.indexOf(astrologer.id) >= 0
                                }
                              />
                            </Grid>
                          )
                      )}
                    </div>
                    {offlineBookingAvailable() === false && (
                      <div style={{ textAlign: "center", padding: "8px 16px" }}>
                        No astrologers are currently available for booking. Please change your language preference to
                        see more astrologers.
                      </div>
                    )}
                  </div>
                  {(astrologersData.length >= 0 && BrowserUtils.isLoggedIn()) ? (
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "8px",
                        lineHeight: "1rem",
                        marginBottom: "10px",
                        color: "#323232",
                      }}>
                      <div style={{ fontSize: "0.8rem" }}>
                        Tip: Add more languages to your preference to see more astrologers here.{" "}
                        <u
                          style={{ color: "#ee600d" }}
                          onClick={(e) => {
                            const eData = {
                              'redirect': 1,
                              'buttonName': 'Edit language preferences',
                              'consultationType': (showFreeConsultTopBanner) ? 'Free' : 'Paid',
                              'location': 'Bottom'
                            }
                            trackeventData(eData);
                            window.location.href = "/my-favorite-languages";
                          }}>
                          Edit language preference
                        </u>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {testimonialId !== false ? (
                    <div style={{ margin: "0 18px 10px 18px" }}>
                      <div className="testimonial">
                        <span style={{ paddingBottom: 4 }}>
                          <img
                            height={48}
                            src={defaultImage}
                            alt="user"
                            style={{
                              width: "48px",
                              borderRadius: "50%",
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginRight: "4px",
                            }}
                          />
                          <b style={{ display: "inline-block" }}>{TestimonialData.data[testimonialId].Name}</b>
                          <small> from {TestimonialData.data[testimonialId].Place}</small>
                        </span>
                        <div
                          style={{
                            paddingLeft: "14px",
                            fontFamily: "serif",
                            lineHeight: "1.4rem",
                            borderLeft: "2px solid #ee600d",
                            marginLeft: "23px",
                            fontSize: "0.8rem",
                          }}>
                          {TestimonialData.data[testimonialId].Testimonial}
                        </div>
                      </div>
                      {FA_localStorage("phone") && FA_localStorage("phone") !== "" ? (
                        <>
                          <div
                            className="review"
                            onClick={() => {
                              const eData = {
                                'redirect': 1,
                                'buttonName': 'Tell us your experience',
                              }
                              trackeventData(eData);
                              window.location.href = "/rate-clickastro";
                            }}>
                            Tell us your experience with Clickastro
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {window.location.host !== 'www.findastro.com' && (
                    <button id="hamburgerMenuTrigger" onClick={hamburgerMenuTrigger}>Testing</button>)}
                  {window.location.host !== 'www.findastro.com' && (
                    <button id="logPage" onClick={gotoLog}>Log page</button>
                  )}
                  {window.location.host !== 'www.findastro.com' && (
                    <MoengageTest />
                  )}
                </InfiniteScroll>

                {/* {pendingCashbackOffer.showOfferPopup === true ? (
                  <Modal open={true}>
                    <div
                      className={classes.rateusModal}
                      style={{
                        paddingBottom: 48,
                        overflow: "hidden",
                        backgroundColor: "#f5ff8e",
                        maxWidth: 320,
                      }}>
                      <TYBackdrop
                        style={{
                          height: "50vh",
                          position: "absolute",
                          left: "0",
                          opacity: "0.18",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "400",
                          color: "#5d5d5d",
                          margin: "24px 0px 24px 0",
                        }}>
                        <b>Congratulations! </b>
                        <br />
                        <small>you are rewarded with</small>
                      </div>
                      <div style={{ fontSize: "1.9rem", color: "#f17c38", fontWeight: "600" }}>
                        {pendingCashbackOffer.offer}
                        <small>%</small> CASHBACK
                      </div>
                      <div
                        style={{
                          padding: "0 24px 24px 24px",
                          fontSize: "1rem",
                          fontWeight: 700,
                          color: "#5d5d5d",
                        }}>
                        for your next consultation, no matter how long you talk!
                      </div>
                      <Button
                        variant="contained"
                        style={{
                          borderRadius: "24px",
                          boxShadow: "none",
                          backgroundColor: "#f17c38",
                          color: "rgb(255, 255, 255)",
                          padding: "4px 20px",
                        }}
                        onClick={() => {
                          setShowCashbackOffer({});
                          FA_localStorage("pendingCashbackOffer", "remove");
                        }}>
                        <span>Okay</span>
                      </Button>
                      <BlastConfetti isFloating={true} showConfetti={pendingCashbackOffer.blastConfetti} />
                    </div>
                  </Modal>
                ) : (
                  <></>
                )} */}

                {activeConsultation.active === true ? (
                  <div className={classes.activeCall}>
                    <p>
                      {(activeConsultation.status === "initiated" || activeConsultation.status === "accepted") ? (
                        <span
                          style={{
                            display: "inline-block",
                            width: "calc(100% - 65px)",
                            verticalAlign: "middle",
                            fontSize: "0.8rem",
                            paddingRight: 8,
                          }}>
                          Your astrology consultation with <b>{activeConsultation.astrologer || "our astrologer"}</b>
                          {" is now "}
                          <small className={activeConsultation.status}> in progress</small>
                        </span>
                      ) : (
                        <>
                          <IconButton aria-label="close" size="small" className={classes.cancelButton} onClick={clearRecentConsultation}>
                            <CloseIcon />
                          </IconButton>
                          <span
                            style={{
                              display: "inline-block",
                              width: "calc(100% - 95px)",
                              verticalAlign: "middle",
                              fontSize: "0.8rem",
                              paddingRight: 8,
                            }}>
                            Your recent consultation with <b>{activeConsultation.astrologer || "our astrologer"}</b>{" "}
                            is&nbsp;
                            <small
                              className={activeConsultation.status === "missed" ? "failed" : activeConsultation.status}>
                              {activeConsultation.status === "missed" ? "failed" : activeConsultation.status}
                            </small>
                          </span>
                        </>
                      )}
                      {activeConsultation.status === "completed" ? (
                        <button
                          style={{
                            padding: 8,
                            backgroundColor: "#ee600d",
                            color: "#ffffff",
                            border: "none",
                            borderRadius: 8,
                            width: 65,
                          }}
                          onClick={showConsultationDetails}>
                          Details
                        </button>
                      ) : (
                        <button
                          style={{
                            padding: 8,
                            backgroundColor: "#ee600d",
                            color: "#ffffff",
                            border: "none",
                            borderRadius: 8,
                            width: 65,
                          }}
                          onClick={showConsultationDetails}>
                          Details
                        </button>
                      )}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                {/* <textarea>{localStorage.getItem("errorLog")}</textarea> */}
                {forceUpdate() ? (
                  <UpdateApp className={"installDialog"} handleClose={false} isStateOpen={true} />
                ) : (
                  <></>
                )}
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "64px",
                    fontSize: "0.9rem",
                    color: "#6f6f6f",
                    paddingBottom: "64px",
                  }}>
                  <div>
                    &copy; {new Date().getFullYear()} - Clickastro.com
                  </div>
                  <div style={{ fontSize: "0.8rem" }}>
                    {"Ver "}
                    a.
                    {(typeof window.AndroidBridge !== "undefined"
                      ? window.AndroidBridge.call("getAppVersion")
                      : "0.0.0") +
                      " | b." +
                      VERSION}
                  </div>
                  {duplicatesInList ? <div>Duplicates in list</div> : ""}
                </div>
              </div>
            ) : astrologersData.length === 0 && !isLoading && false ? (
              <div className={classes.familyDiv}>
                <Paper
                  className="notFoundPaper"
                  elevation={0}
                  style={{ boxShadow: "none", fontSize: "0.9rem", color: "#626262" }}>
                  <div>
                    No astrologer is here,<br></br> we are working on it!
                  </div>
                  {window.location.search ? (
                    <div className={classes.clearFilter} onClick={onClear}>
                      Clear filters
                    </div>
                  ) : (
                    <></>
                  )}
                </Paper>
                {isOthersLoading ? (
                  <></>
                ) : (
                  <>
                    <div className={classes.youmightalsolikeText}>Other Astrologers you might like</div>
                    <div className={isMobile ? "" : classes.desktopScroller}>
                      {recommendedData.map(
                        (astrologer) =>
                          (
                            astrologer.visible
                            // && checkConsultType(astrologer)
                          ) && (
                            <Grid item xs={12} lg={4} key={astrologer.id}>
                              <AstrologerDetail
                                hide={!checkConsultType(astrologer)}
                                currentConsultType={currentConsultType}
                                currentFilter={currentFilter}
                                openProfile={showAstrologerProfile}
                                family={astrologer}
                                key={`rd-${astrologer.id}`}
                                showMessage={showStatusToast}
                                showLoading={setLoading}
                                handleFavourite={handleFavourite}
                                handleOfferIcon={handleOfferIcon}
                                isSearch={false}
                                handleCall={openCallDialog}
                              />
                            </Grid>
                          )
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* {isChatEnabled() && (
            <div className={["chat", "tab-panel"].join(" ")} ref={tabPanelChat} onScroll={ScrollViewHandler}>
              <ChatPanel
                showError={showError}
                scrollPanel={tabPanelChat}
                showAstrologerProfile={showAstrologerProfile}
              />
            </div>
          )} */}
          {/* <div className={["puja-homa", "tab-panel"].join(" ")} ref={tabPanelPuja} onScroll={ScrollViewHandler}>
            <PujaList showError={showError} scrollPanel={tabPanelPuja} showAstrologerProfile={showAstrologerProfile} />
          </div> */}
          {/* {otherAstrologersList.length > 0 && hasMore === false && isUserLoggedIn() && offerDetails.nextCallDiscount && (
        <UserStartHere
          promotionalList={promotionalList}
          mainAstrologersList={astrologersData}
          currentFilter={currentFilter}
          openProfile={showAstrologerProfile}
          handleCall={openCallDialog}
          handleFavourite={handleFavourite}
          handleOfferIcon={handleOfferIcon}
          offerDiscount={offerDetails.nextCallDiscount}
          promoDiscount={offerPromoDetails.promotionalDiscount}
          key={"start_here_widget"}
          showMessage={showStatusToast}
          showLoading={setLoading}
          offerClaim={offerClaim}
          handleNotify={handleNotifyMe}
          notifications={notifications}
          listUpdated={listUpdated}
          show={true}
        />
      )} */}
          <SideDrawerComponent
            drawerOpen={drawerOpen}
            onDrawerClose={() => {
              setDrawerOpenState(false);
            }}
            onpageEventHandler={onpageEventHandler}
          />
          {upcomingBookings.length > 0 && (
            <div
              className="upcoming-bookings"
              style={{ bottom: localStorage["filterApplied"] === "yes" ? 28 : 28 }}
              onClick={(e) => (window.location.href = "/user-consultation-history?v=booking")}>
              <BookingIcon />
              <span>{upcomingBookings.length}</span>
            </div>
          )}
          <FilterDrawerComponent
            filterOpen={filterOpen}
            onFilterDrawerClose={showFilterBox}
            showLoading={showLoading}
            filterData={filterData}
          />
          <SortDrawerComponent
            sortOpen={sortOpen}
            setSortBy={setSortBy}
            onSortDrawerClose={showSortBox}
            filterData={filterData}
          />
          {(BrowserUtils.isAWebview || sessionStorage.forceWebUse === "force") && isCallDlgStateOpen ? (
            <AstrologerCallDialog
              className={"callDialog"}
              astrologer={selectedAstrologer}
              handleClose={handleDlgClose}
              isStateOpen={isCallDlgStateOpen}
              dialogType={callDlgType}
              extras={callDlgExtra}
              cashBackDiscount={activecashBack}
              runUserUpdates={runUserUpdates}
              clickFrom="List"
            />
          ) : (
            <AppInstallBanner
              className={"installDialog"}
              handleClose={handleDlgClose}
              isStateOpen={isCallDlgStateOpen}
            />
          )}

          <CouponRedeemDialog
            isStateOpen={isRedeemDialogOpen}
            handleClose={CouponHandleClose}
            showLoading={(value) => {
              setLoading(value);
            }}
            showError={showError}
          />
          {pendingReview.astrologer ? (
            <div
              className={clsx("pendingReviewPanel", {
                open: pendingReview.is_submitted === false,
              })}>
              <span className={"text"}>
                How was your last consultation experience with Astrologer <b>{pendingReview.astrologer.name}</b>?
              </span>
              <div>
                <div className="dialogImage" style={{ background: "#efefef" }}>
                  <Avatar
                    alt={pendingReview.astrologer.name}
                    src={
                      pendingReview.astrologer.id
                        ? AstrologerUtils.createProfileImageURI(pendingReview.astrologer.id)
                        : defaultImage
                    }
                    onLoad={imageLoaded}
                  />
                </div>
                <div className={"ratingBar"}>
                  <div>
                    <Rating
                      name="simple-controlled"
                      value={pendingReview.rating}
                      precision={1}
                      className={pendingReview.ratingError === true ? "rating-error" : ""}
                      onChange={(event, newValue) => {
                        const pr = { ...pendingReview };
                        pr.rating = newValue;
                        setPendingReview(pr);
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <textarea
                      maxLength={400}
                      value={pendingReview.reviewText}
                      className={"reviewText"}
                      placeholder={"Your review here."}
                      onChange={(event) => {
                        const pr = { ...pendingReview };
                        pr.reviewText = event.target.value;
                        setPendingReview(pr);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div onClick={closeReview} style={{ color: "#aaa", textAlign: "center", paddingTop: 8 }}>
                Later
              </div>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  left: "0",
                  bottom: "0",
                  height: "48px",
                  fontWeight: 700,
                }}>
                <Button
                  onClick={postReview}
                  variant="contained"
                  style={{ width: "100%", borderRadius: "0", height: "100%" }}
                  className={"dialogButton primary"}>
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/*QueryString().code && QueryString().code.length > 0 ? (
        <Dialog open={true} className={classes.dialogContainer}>
          <div className={classes.reWrap}>
            <div className={classes.paperCenter}>
              <div className={classes.reMessage}>
                <div>You are one step away to claim the reward</div>
              </div>
              <Button variant="contained" className={classes.reClaimButton} onClick={onReferEarnClaim}>
                Claim Rewards
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <></>
      )*/}
          {regnOffer.status === true ? (
            <Modal open={true}>
              <div
                className={classes.rateusModal}
                style={{
                  paddingBottom: 48,
                  overflow: "hidden",
                  backgroundColor: "#f5ff8e",
                  maxWidth: 320,
                }}>
                <TYBackdrop style={{ height: "50vh", position: "absolute", left: "0", opacity: "0.18" }} />
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    color: "#5d5d5d",
                    margin: "24px 0px 4px 0",
                  }}>
                  <b>Congratulations! </b>
                  <br />
                  <small>You are rewarded with points worth</small>
                </div>
                <div style={{ fontSize: "4rem", color: "#f17c38", fontWeight: "400" }}>
                  ₹{FA_localStorage("walletBalance")}
                </div>
                <div
                  style={{
                    padding: "0 24px 24px 24px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#5d5d5d",
                  }}>
                  Happy consulting!
                </div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "24px",
                    boxShadow: "none",
                    backgroundColor: "#f17c38",
                    color: "rgb(255, 255, 255)",
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    FA_localStorage("registrationInit", "remove");
                    setShowRegnOffer({});
                    setTimeout(() => {
                      setShowRegnOffer({ nextCallStatus: true });
                    }, 2000);
                    setTimeout(() => {
                      setShowRegnOffer({ nextCallStatus: true, blastNextCallConfetti: true });
                    }, 2100);
                  }}>
                  <span>Okay</span>
                </Button>
                <BlastConfetti isFloating={true} showConfetti={regnOffer.blastConfetti} />
              </div>
            </Modal>
          ) : (
            <></>
          )}

          {/* {regnOffer.nextCallStatus ? (
            <Modal open={true}>
              <div
                className={classes.rateusModal}
                style={{
                  paddingBottom: 48,
                  overflow: "hidden",
                  backgroundColor: "#f5ff8e",
                  maxWidth: 320,
                }}>
                <TYBackdrop style={{ height: "50vh", position: "absolute", left: "0", opacity: "0.18" }} />
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    color: "#5d5d5d",
                    margin: "24px 0px 24px 0",
                  }}>
                  <b>Congratulations! </b>
                  <br />
                  <small>you will get a</small>
                </div>
                <div style={{ fontSize: "1.9rem", color: "#f17c38", fontWeight: "600" }}>
                  {FA_localStorage("nextCallPrice")}
                  <small>%</small> CASHBACK
                </div>
                <div
                  style={{
                    padding: "0 24px 24px 24px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#5d5d5d",
                  }}>
                  for your first consultation, no matter how long you talk!
                </div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "24px",
                    boxShadow: "none",
                    backgroundColor: "#f17c38",
                    color: "rgb(255, 255, 255)",
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    setShowRegnOffer({});
                    FA_localStorage("registrationInit", "remove");
                  }}>
                  <span>Okay</span>
                </Button>
                <BlastConfetti isFloating={true} showConfetti={regnOffer.blastNextCallConfetti} />
              </div>
            </Modal>
          ) : (
            <></>
          )} */}

          {/* {currentOffer.nextCallStatus ? (
            <Modal open={true}>
              <div
                className={classes.rateusModal}
                style={{
                  paddingBottom: 48,
                  overflow: "hidden",
                  backgroundColor: "#f5ff8e",
                  maxWidth: 320,
                }}>
                <TYBackdrop style={{ height: "50vh", position: "absolute", left: "0", opacity: "0.18" }} />
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    color: "#5d5d5d",
                    margin: "24px 0px 8px 0",
                  }}>
                  <b>You will get </b>
                </div>
                <div style={{ fontSize: "1.9rem", color: "#f17c38", fontWeight: "600" }}>
                  {currentOffer.offer}
                  <small>%</small> CASHBACK
                </div>
                <div
                  style={{
                    padding: "0 24px 24px 24px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#5d5d5d",
                  }}>
                  points after the consultation with this astrologer.
                </div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "24px",
                    boxShadow: "none",
                    backgroundColor: "#f17c38",
                    color: "rgb(255, 255, 255)",
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    setShowCurrentOffer({});
                  }}>
                  <span>Okay</span>
                </Button>
                <BlastConfetti isFloating={true} showConfetti={regnOffer.blastNextCallConfetti} />
              </div>
            </Modal>
          ) : (
            <></>
          )} */}

          {showFilterFeature() && (
            <FilterList
              filter={currentFilter}
              processFilterMap={processFilterMap}
              astrologerList={astrologersData}
              promotionalList={promotionalList}
              showStatusToast={showStatusToast}
              handleClose={closeAllModalPopups}
              langExpertiseMatrix={langExpertiseMatrix}
            />
          )}
          <Snackbar
            vertical="top"
            horizontal="right"
            message={snackBar.message}
            closeError={closeError}
            open={snackBar.open}></Snackbar>
          <div className={["astrologer-profile", astProfileOpen && astProfileDataParams ? "open" : ""].join(" ")}>
            {astProfileDataParams && (
              <AstrologerProfileNew
                type={"popup"}
                runUserUpdates={runUserUpdates}
                closePopup={closeAllModalPopups}
                location={{}}
                match={astProfileDataParams}
                handleFavourite={handleFavourite}
                currentConsultType={currentConsultType}
              />
            )}
          </div>
          {isMobile ? (
            <></>
          ) : (
            <>
              <Divider className={classes.footerDivider} />
              <Grid container className={classes.footer} direction="row">
                <Grid item container direction="column" xs={12}>
                  <Grid item className={classes.footerHeading}>
                    About Findastro
                  </Grid>
                  <Grid item>
                    Findastro, by Clickastro, is one of India's pioneering astrology services company. It presents a
                    unique platform to meet all your astrological needs. Consult your favourite astrologer and get
                    solutions for all your astrological queries related to love, relationships, marriage, career,
                    wealth, over chat, calls, or reports. Findastro helps you to connect to your favourite astrologer
                    any time from the comforts of your home or office. All we require is your accurate birth details,
                    and you are good to go. What's more, the calls are charged at nominal rates. Our panel of
                    astrologers is ready to help you with all your queries and provide precise predictions and remedial
                    measures. Join our customer base of over 120 million satisfied customers.
                  </Grid>
                  <Grid item>
                    <div className={classes.copyright}>
                      &copy; {new Date().getFullYear()} Astro-Vision Futuretech Pvt.Ltd. All rights reserved
                    </div>
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={12}>
                  <Grid item className={classes.footerHeading}>
                    Contact Us
                  </Grid>
                  <Grid item>Phone: +918047248888</Grid>
                  <Grid item>Email: support@findastro.com</Grid>
                  <br />
                  <Grid item className={classes.footerHeading}>
                    Powered By
                  </Grid>
                  <Grid item>
                    Astro-Vision Futuretech Pvt.Ltd., 1st Floor,White Tower, Thammanam P O, Cochin - 682032 Kerala,
                    India.
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={12}>
                  <Grid item onClick={() => (window.location.href = "/")}>
                    Home
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/about-us")}>
                    About Us
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/daily-updates")}>
                    Daily Predictions
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/weekly-updates")}>
                    Weekly Predictions
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/terms-and-conditions")}>
                    Terms &amp; Conditions
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/privacy-policy")}>
                    Privacy Policy
                  </Grid>
                  <br />
                  <Grid item className={classes.footerHeading}>
                    Download APP
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      window.location.href =
                        "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3DFindastro_web%26utm_medium%3Dapplink";
                    }}>
                    <img style={{ height: "50px" }} alt="playstore" src={playstore} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </ChatWindowStateSetterProvider>
    </ChatWindowStateProvider >
  );
};

export default TalkToAstrologers;
