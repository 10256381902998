import React, {Component} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import {withRouter} from "react-router-dom";

import "./MyAccount.css";
import MyAccountOptionsComponent from "./MyAccountOptions.component";
import HelmetComponent from "../../../common/HelmetComponent.component";
import accountData from "../mock/MyAccountData.mock";
import Snackbar from "../../../common/SnackBarMessage";

import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Edit from "@material-ui/icons/Edit";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneIcon from "@material-ui/icons/Phone";
import Location from "@material-ui/icons/LocationOn";
import Calendar from "@material-ui/icons/EventNote";
import ExitIcon from "@material-ui/icons/ExitToApp";
// import DeleteIcon from "@material-ui/icons/Delete";

// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import FA_localStorage from "../../../common/util/FA_localStorage";

class MyAccount extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    name: "",
    email: "",
    phone: "",
    image: "",
    walletPoints: "0",
    isLoading: true,
    deleteProfile: {},
  };

  formatDate = (date, format) => {
    if (date && format === "d/m/Y") {
      let d = date.split("/");
      return new Date(d.reverse().join("-")).toDateString().substring(4);
    }
    return date;
  };

  closeDelete = () => {
    this.setState({deleteProfile: {openDialog: false}});
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  componentDidMount = () => {
    moengage("ACCOUNT_PAGE_VIEWED");
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + this.props.location.pathname;
    } else {
      axios()
        .post("/my-account")
        .then((res) => {
          if (res && res.data.success) {
            if (res.data.data.email) {
              localStorage.setItem("name", res.data.data.name);
              localStorage.setItem("email", res.data.data.email);
              localStorage.setItem("phone", res.data.data.phone);
              localStorage.setItem("image", res.data.data.profileUrl);
              localStorage.setItem("walletBalance", res.data.data.walletBalance ? res.data.data.walletBalance : "0");
              this.setState({
                walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
                isLoading: false,
              });
            } else {
              this.setState({
                walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
                isLoading: false,
              });
            }
          } else {
            this.showError("Error occured, Please try again");
            this.setState({isLoading: false});
          }
        })
        .catch((err) => {
          sentryError("MY_ACCOUNT", err);
          this.showError("Error occured, Please try again");
          this.setState({isLoading: false});
        });

      axios()
        .post("/profile/user")
        .then((res) => {
          if (res && res.data.data.success) {
            localStorage.setItem("dob", res.data.data.user.dateOfBirth);
            localStorage.setItem("tob", res.data.data.user.timeOfBirth);
            localStorage.setItem("pob", res.data.data.user.placeOfBirth);
            localStorage.setItem("gender", res.data.data.user.gender);
            localStorage.setItem("user_languages", res.data.data.user.languages);
          }
        })
        .catch((err) => {
          sentryError("MY_ACCOUNT", err);
          this.showError("Error occured, Please try again");
          this.setState({isLoading: false});
        });
    }
    window.location.hash = "backtohome";
  };

  onEdit = () => {
    moengage("EDIT_PROFILE_CLICKED");
    window.location.href = "/userprofile?pathname=" + this.props.location.pathname;
  };

  onLogout = () => {
    window.location.href = "/app/logout";
  };

  onDelete = () => {
    if (this.state.deleteProfile.confirmDelete) {
      this.setState({isLoading: true});
      axios()
        .post("/profile/delete")
        .then((res) => {
          console.log("arun", res);
          if (res.data.success === true) {
            this.showError("Profile successfully deleted!");
            setTimeout(this.onLogout, 500);
          } else {
            this.showError("Error deleting account, Please try again");
            this.setState({isLoading: false});
          }
        })
        .catch((err) => {
          sentryError("MY_ACCOUNT", err);
          this.showError("Error deleting account, Please try again");
          this.setState({isLoading: false});
        });
    } else {
      this.setState({deleteProfile: {openDialog: true, confirmDelete: false}});
    }
  };

  deleteProfile = () => {
    this.setState({deleteProfile: {openDialog: false, confirmDelete: true}});
    setTimeout(this.onDelete, 100);
  };

  render() {
    return (
      <div className="accountParentDiv">
        <HelmetComponent
          title="My account at Findastro"
          url="https://www.findastro.com/my-account"
          description="My account at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        <div className="accountTopArea">
          <ArrowBackIcon
            className="accountArrowIcon2"
            onClick={() => {
              this.props.history.replace("/");
            }}
          />
          <div className="accountChildTopArea2">
            <p>My Account</p>
          </div>
        </div>
        <div id="accountDiv">
          {!this.state.isLoading ? (
            <Grid container onClick={this.onEdit}>
              <Grid item xs={4}>
                <Avatar
                  alt={localStorage.getItem("name")}
                  src={localStorage.getItem("image") ? localStorage.getItem("image") : ""}>
                  {localStorage.getItem("name") === "null" ? "N" : localStorage.getItem("name")[0]}
                </Avatar>
              </Grid>
              <Grid item xs={8}>
                <div id="accountDetailDiv">
                  <span className="familyUserProfileName">
                    <b
                      style={{
                        whiteSpace: "nowrap",
                        maxWidth: "calc(100% - 64px)",
                        display: "inline-block",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        verticalAlign: "middle",
                        fontSize: "1rem",
                      }}>
                      {localStorage.getItem("name") === "null" ? "Name not provided" : localStorage.getItem("name")}
                    </b>
                    <small
                      style={{
                        display: "inline-block",
                        fontSize: "1rem",
                        verticalAlign: "middle",
                      }}>
                      {localStorage.getItem("gender") === "" ? "" : ", " + localStorage.getItem("gender")}
                    </small>
                  </span>
                  <span>
                    <PhoneIcon id="phoneIcon" />
                    {localStorage.getItem("phone")}
                  </span>
                  <span>
                    <Calendar id="mailIcon" />
                    {localStorage.getItem("dob") === "null"
                      ? "DOB not provided"
                      : this.formatDate(localStorage.getItem("dob"), "d/m/Y") + ", " + localStorage.getItem("tob")}
                  </span>
                  <span>
                    <Location id="phoneIcon" />
                    {localStorage.getItem("pob") === "null" ? "Place not provided" : localStorage.getItem("pob")}
                  </span>
                </div>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Edit id="accountEditButton" onClick={this.onEdit} />
        </div>
        <div style={{paddingTop: "50px"}}>
          {accountData.accountOption.map((option) => (
            <MyAccountOptionsComponent
              wallet={this.state.walletPoints}
              option={option}
              name={localStorage.getItem("name")}
              key={option.icon}
            />
          ))}
        </div>
        <div id="accountButton">
          <Button id="accountLogoutButton" variant="contained" onClick={this.onLogout}>
            <ExitIcon id="accountLogoutIcon" style={{transform: "scaleX(-1)"}}></ExitIcon>
            <span id="accountLogoutText">Logout</span>
          </Button>
        </div>
        {/* <div style={{display: "flex", justifyContent: "center", paddingBottom: 36}}>
          <Button
            id="accountDeleteButton"
            variant="contained"
            onClick={this.onDelete}
            style={{
              backgroundColor: "#ffffff",
              color: "#9c9c9c",
              marginTop: "24px",
              padding: "10px",
              width: "auto",
              boxShadow: "none",
            }}>
            <span style={{whiteSpace: "nowrap", fontWeight: 400}}>
              <u>Delete Account</u>
            </span>
          </Button>
        </div> */}
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
        {/* <Dialog
          className={"deleteDialog"}
          open={this.state.deleteProfile.openDialog}
          onClose={this.closeDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            style={{backgroundColor: "#f13939", color: "#ffffff", fontSize: "1rem", padding: "10px 24px"}}>
            {"Delete account?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{color: "#323232"}}>
              Do you want to delete your Findastro account permanently? Please confirm.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{backgroundColor: "#ffffff", justifyContent: "space-around", paddingBottom: "16px"}}>
            <Button onClick={this.closeDelete} color="grey">
              Cancel
            </Button>
            <Button
              onClick={this.deleteProfile}
              style={{backgroundColor: "#f13939", width: "70%", color: "#ffffff"}}
              autoFocus>
              Yes, delete my account
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default withRouter(MyAccount);
