import React from 'react'

import TouchCarousel, { clamp } from 'react-touch-carousel'
import NonPassiveTouchTarget from './NonPassiveTouchTarget'
import './index.css'
import clsx from 'clsx'
import touchWithMouseHOC from './touchWithMouseHOC.js'

// console.log(typeof touchWithMouseHOC)

/**
 * Carousel Component
 *
 * A React component for creating a carousel to display a collection of items.
 *
 * @param {Object} props - An object containing properties for configuring the Carousel component.
 * @param {Array} props.data - An array of data items to be displayed in the carousel.
 * @param {function} props.renderItem - A function that renders each item in the carousel.
 * @param {boolean} [props.loop=false] - A boolean indicating whether the carousel should loop back to the beginning when it reaches the end.
 * @param {number} [props.autoplay=3000] - The duration, in milliseconds, for automatically advancing the carousel items (set to 0 to disable autoplay).
 * @param {number} [props.cardSize=300] - The width of each item card within the carousel.
 * @param {number} [props.cardPadCount=3] - The number of padding items to be displayed on each side of the visible items.
 * @param {string} [props.classes] - A string containing additional CSS classes for styling the carousel.
 * @param {boolean} [props.indicators=false] - A boolean indicating whether navigation indicators should be displayed.
 * @param {boolean} [props.swipe=false] - A boolean indicating whether swipe navigation should be enabled on touch devices.
 *
 * @returns {JSX.Element} - A React component representing a carousel for displaying a collection of items.
 */
const Carousel = ({
  data,
  renderItem,
  loop = false,
  autoplay = 3000,
  cardSize = 300,
  cardPadCount = 3,
  classes,
  indicators = false,
  swipe = false,
}) => {
  const carouselWidth = clamp(window.innerWidth, 0, 960)
  // console.log({ cardSize, cardPadCount, carouselWidth, 'c': 'x' })

  function CarouselContainer(props) {
    const { cursor, carouselState: { active, dragging }, ...rest } = props
    let current = -Math.round(cursor) % data.length
    while (current < 0) {
      current += data.length
    }

    const translateX = (cursor - cardPadCount) * cardSize + (carouselWidth - cardSize) / 2
    // console.log({ current })

    return (
      <>
        <NonPassiveTouchTarget
          className={clsx(
            'carousel-container',
            active ? 'is-active' : '',
            dragging ? 'is-dragging' : '',
            !swipe ? 'swipe-disabled' : '',
          )}
        >
          <NonPassiveTouchTarget
            className='carousel-track'
            style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
            {...rest}
          />

          {indicators ?
            <div className='carousel-pagination-wrapper'>
              <ol className='carousel-pagination'>
                {data.map((_, index) => (
                  <li
                    key={index}
                    className={current === index ? 'current' : ''}
                  />
                ))}
              </ol>
            </div>
            :
            null
          }

        </NonPassiveTouchTarget>
      </>
    )
  }

  const Container = CarouselContainer // touchWithMouseHOC(CarouselContainer)

  function renderCard(index, modIndex, cursor) {
    const item = data[modIndex]
    let current = -Math.round(cursor) % data.length
    while (current < 0) {
      current += data.length
    }
    return renderItem(item, index, current)
  }

  return (
    <>
      <TouchCarousel
        component={Container}
        cardCount={data.length}
        cardSize={cardSize}
        renderCard={renderCard}
        loop={loop}
        autoplay={autoplay}
      />
    </>
  )
}

export default Carousel