const getEventPrefix = (host) => {
  const validHosts = ["www.findastro.com"];
  let prefix = validHosts.indexOf(host) < 0 ? "test_" : "";
  if (window.isAppHome()) {
    prefix = 'TA-';
  }
  return prefix;
};

const moengage = (event_name, event_attributes = "") => {
  const event_name_with_prefix = getEventPrefix(window.location.host) + event_name;
  if (window.Moengage) {
    if (event_attributes !== "") {
      window.Moengage.track_event(event_name_with_prefix, event_attributes);
    } else {
      window.Moengage.track_event(event_name_with_prefix);
    }
  }
};

export default moengage;
