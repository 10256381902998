import React, {Component} from "react";
import axios_noheader from "../../../common/interceptor_noheader";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import {withRouter} from "react-router-dom";

import "./UserActions.css";
import userActionData from "../mock/UserActionData.mock";
import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SubmitArrowIcon from "@material-ui/icons/ChevronRight";

import QueryString from "../../../common/util/QueryString";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

class Login extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    phone: "",
    code: "+91",
    isLoading: false,
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  goBack = () => {
    if (document.referrer === "") {
      window.location.href = "/";
    } else {
      window.history.back();
    }
  };

  onlyNumbers = (e) => {
    var validChars = userActionData.validChars;
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  updateNumber = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  updateCode = (e, value) => {
    if (value && value.phone !== "") {
      this.setState({
        code: value.phone,
      });
    } else {
      this.setState({
        code: "",
      });
    }
  };

  onNotMember = () => {
    window.location.href = "/register";
  };

  skipLogin = () => {
    sessionStorage.setItem("skipLogin", "true");
    this.trackActions("fa_login_skip", "NA");
    window.location.href = "/";
  };

  submitOTP = () => {
    if (this.state.phone.length !== 10) {
      this.setState({
        snackBar: {
          open: true,
          message: "Please enter a valid phone number",
        },
      });
    } else if (this.state.code === "") {
      this.setState({
        snackBar: {
          open: true,
          message: "Please select a country code",
        },
      });
    } else {
      this.setState({
        isLoading: true,
      });
      axios_noheader()
        .post("/user/get-otp", {
          phone: this.state.phone,
          code: this.state.code,
          slide: QueryString().slide,
        })
        .then((res) => {
          if (res.data.data.success) {
            sessionStorage.setItem("sessionLoginData", JSON.stringify(res.data.data));
            this.forwardToOTP(res.data.data);
          } else {
            this.showError("Error occured, Please try again");
            this.setState({
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          sentryError("LOGIN", err);
          this.showError("Error occured, Please try again");
          this.setState({
            isLoading: false,
          });
        });

      this.trackActions("fa_otp_init", "NA");
    }
  };

  forwardToOTP = (data) => {
    this.props.history.push({
      pathname: "/otp",
      state: {
        data: data,
        doRegister: !data.is_registered,
        historyData: QueryString().pathname,
      },
    });
  };

  trackActions = (type, label) => {
    moengage(type);
    const payload = {
      p_screen: "login",
      p_label: label,
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(this.state.phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  trackPageview = () => {
    /** event tracking */
    const type = "fa_login";
    const payload = {
      p_screen: "login",
      p_label: "NA",
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  componentDidMount = () => {
    if (localStorage.getItem("btkn") && localStorage.getItem("utkn")) {
      window.location.href = "/";
    } else if (sessionStorage.sessionLoginData) {
      try {
        const loginData = JSON.parse(sessionStorage.sessionLoginData);
        if (loginData) {
          this.forwardToOTP(loginData);
          return;
        }
      } catch (err) {}
    } else {
      moengage("LOGIN_PAGE_VIEWED");
      moengage("fa_login");
      localStorage.removeItem("currentFilterData");
      localStorage.removeItem("filterApplied");
    }
    this.trackPageview();
  };

  render() {
    return (
      <div className="userActionParent">
        <HelmetComponent
          title="Login at Findastro"
          url="https://www.findastro.com/login"
          description="Login at Findastro-Login and find your desired Astrologers at Findastro"
        />
        <Box id="loginTopBar">
          {/* <ArrowBackIcon className="userActionsArrow" onClick={this.goBack} /> 
          <span className="loginTroubleText">
            <span className="signupnowCTA">Talk to your astrologer in Findastro</span>
          </span>*/}
          <span className="loginTitle">
            <span className="loginTitle">Login / Sign-up</span>
          </span>
        </Box>
        <img alt="Phone Logo" id="loginPhoneLogo" src={userActionData.phoneImg} />
        <p id="loginText">Enter mobile number</p>
        <p id="loginInfoText">
          For astrologer consultation, registering with an Indian mobile number is mandatory. However, this is not
          mandatory for booking Puja/Havans, because it happens via Video Conference. <br />
          <br />
          Please enter your mobile number and complete the OTP verification.
        </p>
        <div id="loginFormDiv">
          <div id="loginFormInputAlign">
            {/* <div id="loginLocSelect">
              <Autocomplete
                closeIcon=""
                defaultValue={userActionData.extensions[0]}
                onChange={this.updateCode}
                options={userActionData.extensions}
                getOptionLabel={(option) => option.label}
                disabled={true}
                renderOption={(option) => (
                  <React.Fragment>
                    <span className="loginExtensionOptions">
                      {option.label} {option.phone}
                    </span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </div> */}
            <span
              style={{
                lineHeight: "42px",
                fontSize: "1rem",
                padding: "0 4px",
                color: "#5d5d5d",
              }}>
              +91
            </span>
            <TextField
              id="loginPhoneInput"
              type="tel"
              variant="outlined"
              placeholder="0000000000"
              phone={this.state.phone}
              inputProps={{maxLength: 10}}
              onKeyDown={this.onlyNumbers}
              onChange={(e) => this.updateNumber(e)}
            />
          </div>
          <div
            style={{
              fontSize: "0.75rem",
              textAlign: "center",
              lineHeight: "0.9rem",
              color: "#5d5d5d",
              marginTop: "4px",
            }}>
            Currently we support only Indian mobile numbers
          </div>
          {!this.state.isLoading ? (
            <>
              <Button variant="contained" id="loginSubmitButton" onClick={this.submitOTP}>
                Continue
                <SubmitArrowIcon id="loginSubmitArrow"></SubmitArrowIcon>
              </Button>
              <Button variant="contained" id="loginLaterButton" onClick={this.skipLogin}>
                Later
              </Button>
            </>
          ) : (
            <div id="loading">
              <CircularProgress />
            </div>
          )}
        </div>
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}

export default withRouter(Login);
