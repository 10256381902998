import React, { useState, useEffect } from 'react';
import T2AEventTrack from "../../../../common/T2AEventTrack";
import BrowserUtils from "./UAScripts.js";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import RechargeLinkRedirect from "./RechargeLinkRedirect";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
  offerBannerImage: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 0.7, // Change the opacity on hover
    },
    '&:active': {
      opacity: 0.5, // Change the opacity on active
    },
  },
}));

const RechargeBanner = ({ hasRecharge }) => {
  const classes = useStyles();
  const [currentBanner, setCurrentBanner] = useState({})
  const history = useHistory()
  const notRechargedUsers = [
    // {
    //   name: 'Free is good',
    //   url: 'free-is-good-400x100.jpg',
    // },
    // {
    //   name: 'Loved your free chat',
    //   url: 'loved-your-free-chat-400x100.jpg',
    // },
    {
      name: 'Exclusive loved 5min',
      url: 'exclusive-loved-5min-chat.min.png',
    }
  ];
  const rechargedUsers = [
    // {
    //   name: 'Paid shaadi on mind',
    //   url: 'paid-shaadi-on-mind-400x100.jpg',
    // },
    // {
    //   name: 'Paid stress anxiety',
    //   url: 'paid-stress-anxiety-400x100.jpg',
    // },
    {
      name: 'Exclusive find harmony',
      url: 'exclusive-find-harmony.min.png',
    }
  ];

  const getRandomBanner = (bannersArray) => {
    const randomIndex = Math.floor(Math.random() * bannersArray.length);
    return bannersArray[randomIndex];
  };

  const showVerifyPopup = () => {
    // BrowserUtils.devAlert('isReadyToLogin')
    // window.appLoginComplete()

    if (!window.isAppProfileDataHasEmail() && window.AndroidBridge) {
      BrowserUtils.devAlert('isReadyToLogin')
      window.AndroidBridge.call("isReadyToLogin")
      return
    }

    const btn = document.querySelectorAll('.astrologerInfo.visibleInCurrentTab .btn-action')[0]
    btn.classList.add('show--popup')
    btn.click()
  }

  const trackeventData = (data) => {
    const payload = {
      event: {
        name: data.name,
        attributes: {
          'Variant Name': data.variant,
          'Screen Name': 'Offer banner',
          'Target Screen Name': 'My wallet',
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }
    if (data?.buttonName) {
      payload.event.attributes['Button Name'] = data.buttonName;
      payload.event.attributes['Button Location'] = 'Recharge promo banner';
    }
    T2AEventTrack(payload.event, payload.extra)
    if (data.redirectUrl) {
      data.e?.goToWallet ? data.e.goToWallet() : history.push(data.redirectUrl)
    }
  }

  const loadBanner = (count) => {
    const bannersToDisplay = count ? rechargedUsers : notRechargedUsers;
    const randomBanner = getRandomBanner(bannersToDisplay)
    var eData = {
      variant: randomBanner.name,
      name: 'Screen View Action'
    }
    setCurrentBanner(randomBanner);
    trackeventData(eData)
  }

  useEffect(() => {
    // console.log('hasRecharge', hasRecharge)
    if (hasRecharge.loaded === 1) {
      loadBanner(hasRecharge.count)
    }
  }, [hasRecharge.loaded]);

  return currentBanner && hasRecharge.loaded === 1 && (
    <RechargeLinkRedirect
      refData={{
        refID: 'home/recharge-banner',
        highlight: 499,
        location: 'Recharge promo banner',
        // astrologerID: astrologerFullProfile.id,
        // astrologerName: astrolgerInfo.name,
        // type: 'chat',
        // maxAmount: 1000,
      }}
      onClick={(e) => {
        const eData = {
          name: 'Button Action',
          buttonName: 'Offer banner',
          variant: currentBanner.name,
        };
        if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
          eData.redirectUrl = '/user-wallet'
          eData.e = e
        } else {
          showVerifyPopup()
        }
        trackeventData(eData);
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <img
            // src={process.env.REACT_APP_ASSETS_URL + '/images/promo/' + currentBanner.url}
            src={'https://s3.ap-south-1.amazonaws.com/assets.findastro.com/images/offers/' + currentBanner.url}
            alt="Offers banner"
            className={classes.offerBannerImage}
          />
        </Grid>
      </Grid>
    </RechargeLinkRedirect>
  );
};

export default RechargeBanner;