import React, { useState, useEffect } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import { withRouter } from "react-router-dom";

import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import QueryString from "../../../common/util/QueryString";
import classes from "../css/PaymentLink.module.css";

import { ArrowBack } from "@material-ui/icons";
import { InputAdornment, TextField } from "@material-ui/core";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import T2AEventTrack from "../../../common/T2AEventTrack";
import { SUPPORT_NUMBER, T2A_SUPPORT_NUMBER } from "../../../app.config";
import { CallRounded, WhatsApp } from "@material-ui/icons";

const MyWallet = (props) => {
  const [MIN_AMOUNT, setMinAmount] = useState(300);
  const [name, setName] = useState(localStorage["name"]);
  const [phone, setPhone] = useState(localStorage["phone"]);
  const [amount, setAmount] = useState(MIN_AMOUNT);
  const [linkExpiry, setLinkExpiry] = useState(null);
  const [discount, setDiscount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('')
  const [linkExpired, setLinkExpired] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });
  const eventData = {
    event: {
      name: 'Screen View Action',
      attributes: {
        'Screen Name': 'COT Payment link',
        'SKU': 'COT',
        'Product': 'COT',
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name
      }
    },
    extra: {
      user: {
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name
      }
    }
  }
  const [eventPaymentData, setEventPaymentData] = useState({})
  const supportPhone = T2A_SUPPORT_NUMBER.toString().replace(' ', '')
  const callHREF = `tel:${supportPhone}`
  const whatsAppMessage = ''
  const whatsAppHREF = `https://wa.me/${supportPhone}?text=${whatsAppMessage}`


  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  const handleInputChange = (event) => {
    if (event.target.value.length <= 10) {
      if (event.target.name === "name") setName(event.target.value);
      else if (event.target.name === "phone") setPhone(event.target.value);
      else if (event.target.name === "amount") setAmount(event.target.value);
    }
  };

  // goBack = () => {
  //   const params = new URLSearchParams(window.location.search);
  //   window.location.href = params.get("pathname") ? params.get("pathname") : "/";
  // };

  // clearHistory = (url) => {
  //   this.props.history.replace(url);
  // };

  const onProceed = () => {
    if (!phone && !name) {
      showError("Please enter your phone");
    } else if (!amount) {
      showError("Please enter amount");
    } else if (amount < MIN_AMOUNT) {
      showError("Minimum recharge amount is ₹" + MIN_AMOUNT);
    } else {
      setIsLoading(true);
      axios()
        .post("/payment-link/recharge", {
          phone: phone,
          name: name,
          amount: amount,
          url: props.match.params.id,
          token: QueryString().token,
          mobile_country_code: "91",
        })
        .then((res) => {
          if (res.data.success) {
            triggerRazorpay(res.data);
            moengage("WALLET_RECHARGE_ATTEMPTED");
          } else {
            showError(res.data.message);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          sentryError("MY_WALLET_PROCEED", err);
          showError("Error occured, Please try again");
          setIsLoading(false);
        });

      const eData = eventData;
      eData.event.name = 'Button Action'
      eData.event.attributes['Button Name'] = 'Continue to payment'
      eData.event.attributes.SKU = 'COT' + amount
      eData.time = Date.now()
      setEventPaymentData(eData)
    }
  };

  const triggerRazorpay = (data) => {
    setIsLoading(true);

    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: parseInt(data.amount, 10),
      currency: "INR",
      name: "Clickastro",
      description: "#" + data.order_id,
      order_id: data.payment_reference_id,
      image: process.env.REACT_APP_ASSETS_URL + "/images/ca/ca_logo_with_tag_line_S.png",
      notes: {
        merchant_order_id: data.order_id,
      },
      prefill: {
        name: data.name,
        email: data.email,
        contact: data.phone,
        method: "",
      },
      theme: {
        color: "#ee600d",
      },
      modal: {
        ondismiss: function () {
          razorCancelTransaction(data.t_id, data.link_token);
        },
      },
      config: {
        display: {
          hide: [{ method: "paylater" }],
        },
      },
    };
    if (data.redirect) {
      options["callback_url"] =
        process.env.REACT_APP_API_BASE_URL +
        "/wallet/recharge/rp-wv/success?t_id=" +
        data.t_id +
        "&type=pL" +
        "&attr=" +
        (localStorage.getItem("user_attribution") || "");
      options["redirect"] = true;
    }
    setIsLoading(false);
    let rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      let comments = JSON.stringify(response.error);
      razorFailedTransaction(data.t_id, data.link_token, comments);
    });
    rzp1.open();
    window.location.hash = "backtohome";
  };

  const razorCancelTransaction = (id, token) => {
    setIsLoading(true);

    axios()
      .post("/payment-link/cancel", {
        t_id: id,
        token: token,
      })
      .then((res) => {
        setIsLoading(false);
        showError("Transaction is cancelled, Please try again");
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_CANCEL", err);
      });
  };

  const razorFailedTransaction = (id, token, comments) => {
    axios()
      .post("/payment-link/failed", {
        t_id: id,
        token: token,
        comments: comments,
      })
      .then((res) => {
        // Do nothing
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_FAILED", err);
      });
  };

  useEffect(() => {
    const eData = eventData;
    eData.event.attributes.SKU = 'COT' + amount
    axios()
      .post("/payment-link/validate-url/" + props.match.params.id, {
        token: QueryString().token,
      })
      .then((res) => {
        if (res && res.data.success) {
          if (res.data.is_link_expired) {
            showError("This payment link has expired. Please contact customer care for further support.");
            setErrorMessage("This payment link has expired. Please contact customer care for further support. ")
            setLinkExpired(true)
            eData.event.attributes['Screen Name'] = 'Link expired';
            eData.event.attributes['Source Screen Name'] = 'COT Payment link';
          } else if (res.data.discount_type === "percentage") {
            eData.event.attributes.SKU = 'COT' + res.data.minimum_recharge;
            setDiscount(res.data.discount);
            setMinAmount(res.data.minimum_recharge);
            setAmount(res.data.minimum_recharge);
            setLinkExpiry(res.data.expiry);
          }
        } else if (res) {
          showError(res.data.message);
          setErrorMessage(res.data.message)
          eData.event.attributes['Screen Name'] = 'Invalid payment link';
          eData.event.attributes['Source Screen Name'] = 'COT Payment link';
        } else {
          showError("Something went wrong. please try again");
          setErrorMessage("Something went wrong. please try again")
        }
        setIsLoading(false);
        eData.time = Date.now()
        setEventPaymentData(eData)
      })
      .catch((err) => {
        sentryError("PAYMENT_LINK", err);
        showError("Something went wrong. please try again");
        setIsLoading(false);
        eData.time = Date.now()
        setEventPaymentData(eData)
      });

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventPaymentData?.time) {
      T2AEventTrack(eventPaymentData.event, eventPaymentData.extra)
    }
    //console.log('send event datas', eventPaymentData)
    return () => {
      //console.log('clear all event data')
    }
  }, [eventPaymentData.time])

  return (
    <div style={{ backgroundColor: "#fbead0", minHeight: "100vh", paddingBottom: 0 }} className="pay-link">
      <HelmetComponent
        title="My Wallet at Clickastro"
        url="https://www.findastro.com/my-wallet"
        description="My Wallet at Clickastro- Connect to your Favorite Astrologer at Clickastro"
        icon={process.env.REACT_APP_ASSETS_URL + "/images/ca/favicon.ico"}
        image={process.env.REACT_APP_ASSETS_URL + "/images/ca/ca_logo_with_tag_line_S.png"}
      />
      {isLoading ? (
        <div className="circle-loading" style={{ transform: "translateX(20px)" }}>
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}

      <div className={classes.header}>
        <div className={classes.navbar}>
          {/* <ArrowBack
            onClick={(e) => {
              window.location.replace("/");
            }}
          /> */}
          <h2 className={classes.title}>Special Recharge Offer</h2>
        </div>
      </div>
      {errorMessage && (<div style={{
        textAlign: 'center', padding: '0.5em', color: '#ec0909', fontWeight: 600
      }}>{errorMessage}
        {linkExpired && (<div>
          <a className="a-link a-link-icon" href={callHREF}> {T2A_SUPPORT_NUMBER}
          </a>{' '} or{' '}
          <a className="a-link a-link-icon a-link-whatsapp" href={whatsAppHREF}>
            <WhatsApp /> whatsapp
          </a>{' '}
        </div>)}
      </div>)}
      <div className={classes.titleBubble}>Recharge for ₹{MIN_AMOUNT} or above and get 10% EXTRA</div>
      <div className={classes.formwrap}>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            autoComplete="phone"
            id="phone"
            name="phone"
            className={classes.userInput}
            value={phone}
            variant="outlined"
            label="Your Clickastro phone number"
            placeholder="Your Clickastro phone number"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">+91</InputAdornment>,
            }}
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            autoComplete="number"
            id="amount"
            name="amount"
            className={classes.userInput}
            value={amount}
            variant="outlined"
            label="Recharge amount"
            placeholder="Recharge amount"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <b className={classes.rupeeicon}>₹</b>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <div className={classes.warning}>{amount < MIN_AMOUNT ? "Minimum recharge amount is Rs " + MIN_AMOUNT : ""}</div>
        <div className={classes.gst}>
          {amount > 0 ? "+ 18% GST of ₹" + (amount ? amount * 0.18 : 0).toFixed(2) + " applicable" : ""}
        </div>
        <div className={classes.infopanel}>
          {[
            { name: "Points", value: amount < MIN_AMOUNT ? 0 : amount || 0 },
            { name: "Bonus Points", value: amount >= MIN_AMOUNT ? (amount * discount) / 100 : 0 },
            {
              name: "Total Points",
              value:
                amount < MIN_AMOUNT
                  ? 0
                  : parseInt(amount) + (amount >= MIN_AMOUNT ? (amount * discount) / 100 : 0) || 0,
            },
          ].map((row) => (
            <div key={row.name} className={classes.labelitem}>
              <div className={classes.labelname}>{row.name}</div>
              <div align="right" className={classes.labelvalue}>
                {row.value}
              </div>
            </div>
          ))}
        </div>
        <div style={{ textAlign: "center" }}>
          <Button variant="contained" fullWidth className={classes.submitButton} onClick={onProceed}>
            Continue to Payment
          </Button>
        </div>
        {linkExpiry ? (
          <div className={classes.offer}>
            <div>This offer will expire in {linkExpiry}</div>
          </div>
        ) : (
          ""
        )}
        <div className={classes.logo}>
          <img width="100" src={process.env.REACT_APP_ASSETS_URL + "/images/ca/ca_logo_with_tag_line_S.png"} alt="clickastro logo" />
        </div>
      </div>
      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBar.message}
        closeError={closeError}
        open={snackBar.open}></Snackbar>
    </div>
  );
};

export default withRouter(MyWallet);
