import React, {Component} from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import {withRouter} from "react-router-dom";

import "./MyAccount.css";
import Snackbar from "../../../common/SnackBarMessage";
import AstrologerDetailsComponent from "../../user/astrologer/components/Astrologer-Details.component";
import HelmetComponent from "../../../common/HelmetComponent.component";

import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Paper from "@material-ui/core/Paper";

class MyFavoriteAstrologers extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    isLoading: true,
    isOthersLoading: true,
    astrologersData: [],
    recommendedData: [],
    offerClaim: false,
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  componentDidMount = () => {
    moengage("FAVORITES_PAGE_VIEWED");
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + this.props.location.pathname;
    } else {
      axios()
        .post("/favourite/list")
        .then((res) => {
          if (res.data.success) {
            this.setState({isLoading: false});
            if (res.data.data.length === 0) {
              axios()
                .post("/astrologers/all/d")
                .then((res) => {
                  if (res.data.success) {
                    this.setState({recommendedData: res.data.data, isOthersLoading: false});
                  } else {
                    this.showError(res.data.message);
                  }
                })
                .catch((err) => {
                  sentryError("MY_FAVOURITE_ASTROLOGER_RECOMMENDED", err);
                  this.showError("Something went wrong. please try again");
                });
            } else {
              axios()
                .post("/offer-claim")
                .then((res) => {
                  if (res.data.success) {
                    this.setState({offerClaim: res.data.isNUser});
                  }
                })
                .catch((err) => {
                  sentryError("MY_FAVOURITE_ASTROLOGER_OFFERCLAIM", err);
                });
              this.setState({astrologersData: res.data.data});
              this.setState({isLoading: false});
            }
          } else {
            this.showError(res.data.message);
            this.setState({isLoading: false});
          }
        })
        .catch((err) => {
          sentryError("MY_FAVOURITE_ASTROLOGER_LIST", err);
          this.showError("Something went wrong. please try again");
          this.setState({isLoading: false});
        });
    }
  };

  render() {
    return (
      <div className="accountParentDiv">
        <HelmetComponent
          title="My Favorite at Findastro"
          url="https://www.findastro.com/my-favorite-astrologer"
          description="My Favorite at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="accountTopArea">
              <ArrowBackIcon className="accountArrowIcon2" onClick={this.props.history.goBack} />
              <div className="accountChildTopArea2">
                <p>My Favorite</p>
                <h3 className="accountText">Astrologers</h3>
              </div>
            </div>
            {this.state.astrologersData.length > 0 ? (
              <>
                {this.state.astrologersData.map((family) => (
                  <AstrologerDetailsComponent
                    family={family}
                    key={family.id}
                    isSearch={false}
                    offerClaim={this.state.offerClaim}
                  />
                ))}
              </>
            ) : (
              <div>
                <Paper className="favoritesNotFoundPaper" elevation={3}>
                  <div>No astrologers found.</div>
                </Paper>
                <div className="noResults">Other astrologers you might like</div>
                {this.state.recommendedData.map((family) => (
                  <AstrologerDetailsComponent family={family} key={family.id} isSearch={false} />
                ))}
              </div>
            )}
          </>
        )}

        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}

export default withRouter(MyFavoriteAstrologers);
