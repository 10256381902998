import React, { useState } from 'react';
import BirthDetailsForm from './BirthDetailsForm'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx';
import { useRef } from 'react';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'transparent !important',

  },
  title: {
    marginTop: 0,
    marginBottom: '5px',
    fontSize: '14px',
    color: '#000',
    fontWeight: 'normal',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    width: 150,
    margin: 'auto',
    gap: '.5rem',
    '& .MuiButtonBase-root': {
      backgroundColor: 'var(--clr-green)',
      color: '#fff',
      borderRadius: '.75rem',
      padding: '.5rem .25rem',
      '&.no': {
        backgroundColor: 'var(--clr-red3)',
      }
    }
  },
  selectedButton: {
    marginBottom: -10,
    '& strong': {
      fontSize: '14px',
      color: 'var(--clr-green)',
      '&.no': {
        color: 'var(--clr-red3)',
      }
    }
  },
  form: {
    backgroundColor: 'var(--clr-meta-bg)',
    margin: '20px -20px 0px -20px',
    padding: '0 1rem',
    '& h3': {
      color: '#000',
    },
    '& #whiteBg, & #orangeBg': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiFormControl-root': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&.horoscope-style-input': {
        display: 'none',
      },
      '& .MuiFormLabel-root': {
        color: '#000',
      },
      '& .MuiInputLabel-formControl': {
        position: 'static',
        width: 110,
        textAlign: 'left',
        transform: 'none',
        fontSize: '14px !important',
        fontWeight: 'bold',
        '&.MuiInputLabel-outlined.gender-select[data-shrink="false"]': {
          color: '#000 !important'
        }
      },
      '& .MuiToggleButtonGroup-root': {
        flex: '100%',
        borderRadius: '.75rem',
        overflow: 'hidden',
        '& .MuiToggleButton-root': {
          width: '100%',
          background: '#fff',
          border: 0,
          linHeight: '1.6em',
          textTransform: 'none',
          color: '#000',
          '&.Mui-selected': {
            background: 'var(--clr-green)',
            color: '#fff',
          }
        }
      },
      '& .MuiInputBase-root': {
        flex: '100%',
        backgroundColor: '#fff !important',
        borderRadius: '.75rem',
        '& .MuiInputBase-input': {
          fontSize: '14px !important',
          boxShadow: '0 0 0 20px inset #fff',
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none',
      }
    },
    '& .dialogButton': {
      height: '3rem',
      borderRadius: '0.75rem',
      padding: '0 1.5rem',
      marginTop: '-10px',
    }
  }
})

const BirthDetailsFormComponent = ({ onCorrectedBirthDetails, onEnterBirthDetails, astrologer }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const classes = useStyles()
  const formRef = useRef(null)

  const handleOptionSelection = (option) => {
    setSelectedOption(option)
    if (option === 'yes') {
      setSubmitted(true)
      handleCorrectedBirthDetails({ option })
    } else if (option === 'no') {
      onEnterBirthDetails()
      setTimeout(() => formRef.current?.scrollIntoView({ behavior: "smooth" }), 100)
    }
  };

  const handleCorrectedBirthDetails = ({ user, option }) => {
    if (user) {
      user.dob = user.dateOfBirth
      user.pob = user.placeOfBirth
      user.tob = user.timeOfBirth
    }
    onCorrectedBirthDetails({ user, selectedOption: option, submitted: true })
  }

  return (
    <div className={clsx('chat-message meta birth-details-component', classes.container, submitted ? 'hidden' : '')}>
      {!submitted && <h2 className={classes.title}>Birth details: Select your choice</h2>}

      {selectedOption === null ? (
        <div className={classes.buttons}>
          <Button className='yes' onClick={() => handleOptionSelection('yes')}>Yes, this is correct</Button>
          <Button className='no' onClick={() => handleOptionSelection('no')}>No, not correct</Button>
        </div>
      ) : null
      }

      {/* {selectedOption === 'no' || (selectedOption === 'no' && submitted) ? (
        <div className={classes.selectedButton}>
          <strong className='no'>No, not correct</strong>
        </div>
      ) : null
      }

      {selectedOption === 'yes' || (selectedOption === 'yes' && submitted) ? (
        <div className={classes.selectedButton}>
          <strong className='yes'>Yes, this is correct</strong>
        </div>
      ) : null
      } */}

      {selectedOption === 'no' && !submitted ? (
        <div className={clsx('UPFormPreCC', classes.form)} ref={formRef}>
          <h3>Enter birth details</h3>
          <BirthDetailsForm
            location={{
              pathname: '/userprofile'
            }}
            handleClose={() => {

            }}
            continue={({ user }) => {
              handleCorrectedBirthDetails({ user })
              setSubmitted(true)
            }}
            astrologer={astrologer}
          />
        </div>
      ) : null}
    </div>
  );
};

export default BirthDetailsFormComponent;