import React from "react";

import miscScreensData from "../mock/MiscScreensData.mock";

import Grid from "@material-ui/core/Grid";

export default function Horoscope(props) {
  const classes = props.classes;

  const onSignClick = (val) => {
    if (props.pathname.split("/")[1] === "daily-updates") window.location.href = "/daily-updates/" + val;
    else if (props.pathname.split("/")[1] === "weekly-updates") window.location.href = "/weekly-updates/" + val;
  };
  return (
    <div>
      <div className={classes.signInfo}>
        <div className={classes.signImageBig}>{props.signInfo.letter}</div>
        <div className={classes.starSignSpecific}>{props.signInfo.sign}</div>
        <div className={classes.starSignPeriodSpecific}>{props.signInfo.period}</div>
      </div>
      <div>
        <h3>Moon Sign:</h3>
        <div className={classes.starSignContent}>{props.moonsign}</div>
        <h3>Sun Sign:</h3>
        <div className={classes.starSignContent}>{props.sunsign}</div>
        <h4>Check Another Zodiac Sign</h4>
        <Grid container className={classes.selectOtherSignFlex}>
          {miscScreensData.signData.map((sign) => (
            <Grid xs={2} key={sign.sign}>
              <div
                className={classes.signImage}
                onClick={() => {
                  onSignClick(sign.sign);
                }}>
                {sign.letter}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
