import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import WAIcon from "@material-ui/icons/WhatsApp";
import CopyIcon from "@material-ui/icons/Link";
import sentryError from "../../../common/sentryError";
import axios from "../../../common/interceptor";
import {CircularProgress} from "@material-ui/core";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import moengage from "../../../common/moengage";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    backgroundColor: "#000a",
    "& .MuiDialog-paperScrollPaper": {
      color: "#323232",
      backgroundColor: "transparent",
      borderRadius: 14,
    },
  },
  dialogContent: {
    borderRadius: 12,
    backgroundColor: "#fdf7ec",
    boxShadow: "0 0 6px #000a",
    "& .progressBar": {
      margin: "24px auto",
      display: "block",
    },
    "& p": {
      padding: "16px 16px 0 16px",
    },
    "& button": {
      color: "#fff",
      width: "70%",
      padding: "4px 16px 4px 4px",
      border: "none",
      height: "40px",
      fontWeight: "700",
      backgroundColor: "#555",
      display: "block",
      margin: "4px auto",
      borderRadius: 20,
      "& > *": {
        verticalAlign: "middle",
        marginLeft: "4px",
      },
    },
    "& button:last-child": {
      backgroundColor: "transparent",
      width: "40%",
      color: "#555",
      fontWeight: 400,
      padding: "4px 16px 4px 16px",
    },
    "& h3": {
      width: "100%",
      padding: "16px",
      textAlign: "left",
      margin: "0",
      backgroundColor: "#ee600d",
      color: "#fff",
    },
    "& .status": {
      textAlign: "center",
      padding: "10px 16px",
      position: "absolute",
      display: "inline-Block",
      left: "50%",
      top: "50%",
      transform: "Translate(-50%, -50%)",
      backgroundColor: "#000d",
      borderRadius: "24px",
      whiteSpace: "nowrap",
      maxWidth: "80%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#fff",
    },
    "& .closeIcon": {
      top: "4px",
      fill: "#f00",
      right: "4px",
      position: "absolute",
      background: "#fff",
      borderRadius: "50%",
    },
    "& .link": {
      padding: "16px 0",
      wordBreak: "break-word",
      color: "#626262",
    },
    "& .link:empty": {
      display: "none",
    },
    "& .info": {
      backgroundColor: "#ffee99",
      margin: "16px",
      borderRadius: "14px",
    },
    "& .wa-share": {
      backgroundColor: "#01e675",
    },
    "& textarea": {
      position: "absolute",
      left: "150vw",
      width: "20vw",
      top: "-100vh",
    },
  },
}));

export default function ReferEarnModal(props) {
  const classes = useStyles();
  const [reData, setReData] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const textArea = useRef();

  const onURLCopy = () => {
    if (textArea.current) {
      if (document.execCommand) {
        textArea.current.select();
        document.execCommand("copy");
        proceed();
      } else {
        navigator.clipboard.writeText(reData.app_share_url).then(function () {
          proceed();
        });
      }
    }
    function proceed() {
      setStatusMessage("Referral Link copied");
      setTimeout(function () {
        setStatusMessage(null);
      }, 3000);
      trackActions("fa_refer_earn_share", "link");
    }
  };

  const onClose = () => {
    props.setModalStatus(false);
  };

  const shareText = (nolink) => {
    return (
      `Hey, I found this wonderful app that allows you to talk to ` +
      `the Best and eminent Astrologers to solve your day to day problems. ` +
      `Install now from ${nolink ? "" : reData.app_share_url}`
    );
  };

  const shareOnWhatsapp = () => {
    if (typeof window.AndroidBridge === "undefined") {
      window.open(`https://wa.me/?text=${shareText()}`);
    } else {
      window.AndroidBridge.call(
        "pushShareDetails",
        JSON.stringify({
          url: reData.app_share_url,
          subject: shareText(true),
        })
      );
    }
    trackActions("fa_refer_earn_share", "whatsapp");
    onClose();
  };

  const trackActions = (type, label) => {
    moengage(type);
    const payload = {
      p_screen: props.screen,
      p_label: label,
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  useEffect(() => {
    if (!reData) {
      axios()
        .post("/refer-earn/install-offer-link")
        .then((res) => {
          setReData(res.data);
        })
        .catch((err) => {
          sentryError("MY_WALLET_RECHARGE_CANCEL", err);
        });
    }
    trackActions("fa_refer_earn_open", "NA");
  }, []);

  return (
    <div>
      <Dialog open={props.modalOpen} className={classes.dialogContainer} onClose={onClose}>
        <div className={classes.dialogContent}>
          <h3>Refer friends &amp; get bonus points</h3>
          {!reData ? (
            <CircularProgress className="progressBar" />
          ) : (
            <>
              <p>
                We have noticed many of you refer your friends to Findastro. We would like to extend our gratitude by
                giving you some FREE credits from now on.
              </p>
              <p className="info">
                Refer Findastro to your friends and get free points worth Rs.10 for each of your friends who joins
                Findastro.
                <div className="link">{reData.app_share_url || ""}</div>
              </p>
              {statusMessage && <div className="status">{statusMessage}</div>}
              <div className="action-items">
                {window.AndroidBridge && (
                  <>
                    <button onClick={shareOnWhatsapp} className="wa-share">
                      <WAIcon />
                      <span>Share link via Whatsapp</span>
                    </button>
                    <div style={{textAlign: "center"}}>OR</div>
                  </>
                )}
                <button onClick={onURLCopy}>
                  <CopyIcon />
                  <span>Copy link to share</span>
                </button>
                <button onClick={onClose}>Cancel</button>
              </div>
              <textarea readOnly={true} ref={textArea}>
                {reData.app_share_url}
              </textarea>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}
