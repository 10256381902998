import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import {Modal} from "@material-ui/core";
import moengage from "../../../common/moengage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

const useStyles = makeStyles((theme) => ({
  rateusModal: {
    borderRadius: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fbead0",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    padding: "30px 20px",
    maxWidth: "450px",
    width: "90%",
    margin: "auto",
    textAlign: "center",
    outline: "none",
  },
}));

export default function ReferEarnPendingCallout(props) {
  const classes = useStyles();
  const TYBackdrop = props.TYBackdrop;
  const BlastConfetti = props.BlastConfetti;
  const [blastConfetti, setBlast] = useState(false);

  setTimeout((e) => setBlast(true), 1000);
  localStorage.setItem("lastRefEarnId", props.data.id);

  const trackActions = (type, label) => {
    moengage(type);
    const payload = {
      p_screen: props.screen,
      p_label: label,
      p_content: "NA",
      p_value: props.data.amount,
      value: props.data.amount,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };
  useEffect(() => {
    trackActions("fa_refer_earn_message", "NA");
  }, []);

  return (
    <div>
      <Modal open={props.modalOpen}>
        <div
          className={classes.rateusModal}
          style={{paddingBottom: 48, overflow: "hidden", backgroundColor: "#f5ff8e", maxWidth: 320}}>
          <TYBackdrop style={{height: "50vh", position: "absolute", left: "0", opacity: "0.18"}} />
          <div style={{fontSize: "1.6rem", fontWeight: "400", color: "#5d5d5d", margin: "4px 0 16px 0"}}>
            <b>Congratulations! </b>
          </div>
          <div style={{fontSize: "1.9rem", color: "#f17c38", fontWeight: "600"}}>
            <div>
              <small>You have received</small>
            </div>
            <div style={{marginTop: "-10px", lineHeight: "normal"}}>
              <big style={{fontSize: "3rem", fontWeight: 400}}>₹{props.data.amount}</big>
            </div>
            <div style={{marginTop: "-16px", marginBottom: "16px"}}>
              <small>referral credits so far</small>
            </div>
          </div>
          <button
            variant="contained"
            style={{
              borderRadius: "24px",
              boxShadow: "none",
              backgroundColor: "#f17c38",
              color: "rgb(255, 255, 255)",
              padding: "10px 24px",
              border: "none",
              transform: "translateZ(10px)",
            }}
            onClick={() => {
              props.onClose(null);
            }}>
            <span>Okay</span>
          </button>
          <BlastConfetti isFloating={true} showConfetti={blastConfetti} />
        </div>
      </Modal>
    </div>
  );
}
