import BrowserUtils from "../../user/astrologer/components/UAScripts"
import { ConsolutationRequests } from "../../user/astrologer/components/chat/ChatUtls"

export const sessionKey = 'cndata'

export default function OpenAstrologerProfile({
  astrologerID = '',
  task = '',
  openURL = () => { },
}) {

  const handleRedirection = () => {
    try {
      (async () => {
        await ConsolutationRequests.pushNotificationData({
          chatID: '',
          task,
          astrologerID
        })
          .then(pushData => {
            let redirectURL = ''
            if (pushData?.success && pushData?.astrologer?.profileUrl) {
              const data = pushData
              delete data.success
              data.astrologer.id = parseInt(astrologerID)
              data.task = task
              sessionStorage.setItem(sessionKey, JSON.stringify(data))
              redirectURL = `/astrologer-profile/${pushData.astrologer.profileUrl}?direct-loading=chat-notification`
            }
            openURL(redirectURL)
          }, err => {
            openURL()
            console.error('ConsolutationRequests.pushNotificationData => Reject error:', err)
          })
      })()
    } catch (err) {
      openURL()
      console.log('ConsolutationRequests.pushNotificationData => Error catch:', err)
    }
  }

  if (BrowserUtils.isLoggedIn()) {
    handleRedirection()
  } else {
    const redirectURL = `/?verify-number-by-url=1&astrologerID=${astrologerID}&task=${task}`
    openURL(redirectURL)
  }

}