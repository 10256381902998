import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles({
  dialog: {
    maxWidth: '460px',
    margin: 'auto',
    fontFamily: 'Roboto,sans-serif',
    '& .MuiBackdrop-root': {
      background: 'rgba(47, 49, 72, 0.75)',
    },
    '& .MuiDialog-paperScrollPaper': {
      background: '#fff',
      boxShadow: 'none',
      width: '100%',
      maxWidth: '350px',
      margin: '1.25rem',
      borderRadius: '18px',
      overflow: 'hidden',
    },
  },
  title: {
    backgroundColor: '#f6f6f6',
    color: 'var(--clr-green)',
    textAlign: 'center',
    padding: '0.75rem 0',
    height: 'var(--height)',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-h6': {
      fontSize: '1.15rem',
      lineHeight: 'normal',
      fontWeight: 'bold',
    }
  },
  content: {
    textAlign: 'center',
    margin: '1rem 0',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    // margin: '0 -20px',
  },
  actionBtn: {
    boxShadow: 'none',
    borderRadius: '0',
    margin: '0 !important',
    flex: '1 1',
    padding: '1rem !important',
    height: 'inherit',
    borderTop: '1px solid #dcdcdc',
    '&.danger': {
      color: 'red',
      backgroundColor: '#e5e5e5 !important',
    }
  },
  actionBtnConfirm: {
    backgroundColor: 'var(--clr-green) !important',
    borderColor: 'transparent',
    color: '#fff',
  }
})

const AlertConfirm = ({
  children,
  title = 'Alert',
  type = 'default', // default, danger, warning, info
  content = 'Alert content',
  onConfirm = e => { },
  confirmText = 'Confirm',
  hideCancel = false,
  open,
  handleClose,
  confirmType = 'default', // danger,
  cancelText = 'Cancel'
}) => {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.endsWith('#alert-confirm') && open === true) {
        handleClose()
      }
    })
  })

  useEffect(() => {
    if (open === true) {
      window.location.hash = "alert-confirm"
    }
  }, [open])

  const onClose = () => {
    browserBackTrigger()
    handleClose()
  }

  const browserBackTrigger = () => {
    if (window.location.hash === '#alert-confirm' && open === true) {
      history.goBack()
    }
  }

  return <Dialog
    className={clsx(
      classes.dialog,
      `alert-type-${type}`
    )}
    open={open}
    onClose={onClose}
  >
    <DialogTitle className={classes.title}>{title}</DialogTitle>
    <DialogContent className={classes.content}>
      {children || content}
    </DialogContent>
    <DialogActions className={classes.actions}>
      {hideCancel === false &&
        <Button
          className={classes.actionBtn}
          onClick={onClose}
        >
          {cancelText}
        </Button>
      }
      <Button
        className={clsx(classes.actionBtn, classes.actionBtnConfirm, confirmType)}
        onClick={e => {
          onClose()
          onConfirm()
        }}
      >{confirmText}</Button>
    </DialogActions>
  </Dialog>
}

export default AlertConfirm