import React, {Component} from "react";
import sentryError from "./sentryError";
import T2AEventTrack from "./T2AEventTrack";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === "production") {
      // You can also log the error to an error reporting service
      //localStorage.setItem("errorLog", JSON.stringify(errorInfo));
      let temp = "";
      let i = error ? error.toString() : "No Error Message";
      if (window.navigator.cookieEnabled) {
        temp += "EB[C:Y]" + this.isSupported() + "- " + i;
      } else {
        temp += "EB[C:N]" + this.isSupported() + "- " + i;
      }

      sentryError(temp, JSON.stringify(errorInfo.componentStack));
    }

    if(localStorage.getItem('strict_app_home') || window.location.pathname.startsWith('/app-home')) {
      const eData = {
        event: {
          name : 'Something went wrong back to home',
          attributes : {
            'User Name' : localStorage["name"] ? localStorage["name"].toLowerCase() : "NA",
            'User ID' : localStorage["userid"] ? parseInt(localStorage["userid"].split('-').at(-1)) || 0 : 0,
            'Product' : 'COT'
          }
        },
        extra : {

        }
      }
      T2AEventTrack(eData.event, eData.extra)
    }
  }

  isSupported() {
    try {
      const testKey = "FA_FC_CE";
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return " ";
    } catch (e) {
      return e;
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            height: "100vh",
            flexDirection: "column",
            backgroundColor: "rgb(251, 234, 208)",
            fontSize: "1rem",
            alignContent: "stretch",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}>
          <span style={{lineHeight: "1.4rem", color: "#5d5d5d"}}>
            We are sorry, something went wrong! <br />
            This is not expected
          </span>
          <button
            onClick={() => {
              window.location.href = "/";
            }}
            style={{
              backgroundColor: "#ee600d",
              border: "0",
              fontSize: "0.9rem",
              padding: "10px 16px",
              color: "#fff",
              borderRadius: "10px",
              marginTop: "36px",
            }}>
            Back to home
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
