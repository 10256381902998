import React from "react";

const SlotItem = (props) => {
  const getCaption = (status, marker) => {
    if (status === "booked") {
      return status;
    } else if (marker === "inactive") {
      return "not available";
    } else if (marker === "selected") {
      return marker;
    } else {
      return "available";
    }
  };
  return (
    <div
      className={"book-slot " + props.status + " " + props.active}
      id={"__slt" + props.index}
      onClick={() => props.bookFrom(props.index)}>
      <span>{props.caption}</span>
      <span>{getCaption(props.status, props.active)}</span>
    </div>
  );
};

// function areEqual(prevProps, nextProps) {
//   /*
//     return true if passing nextProps to render would return
//     the same result as passing prevProps to render,
//     otherwise return false
//     */
//   //return prevProps.active === nextProps.active && prevProps.dateInput.toString() === nextProps.dateInput.toString();
//   return false;
// }
export default SlotItem; //React.memo(SlotItem, areEqual);
