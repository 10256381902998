import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, IconButton, makeStyles } from '@material-ui/core'
import RechargeLinkRedirect, { useRechargeRedirect } from '../../../user/astrologer/components/RechargeLinkRedirect'
import BrowserUtils from '../../../user/astrologer/components/UAScripts'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import clsx from 'clsx'
import { ChevronRight, Close } from '@material-ui/icons'
import { WalletUtils, setWalletEvent } from '../../../../route/WalletComponent'
import { GatewayConfig } from '../../../../gateway.config'
import DealPurchase from './DealPurchase'

const useDealInitiatorStyles = makeStyles({
  dialog: {
    padding: '.8rem',
    overflow: 'visible',
    '& > .MuiDialog-container > .MuiPaper-root': {
      margin: 0,
      maxHeight: '100vh',
      boxShadow: 'none',
      border: '0',
      // transition: '0s',

      maxWidth: 370,
      width: '100%',
      borderRadius: '0.5rem',
      padding: '2rem',

      "@media (max-width: 365px)": {
        padding: '1.5rem',
      },
    },
    '& > .MuiBackdrop-root': {
      // background: '#e2d0b3',
    },
    '&.transparent-page': {
      '& > .MuiBackdrop-root, & > .MuiDialog-container > .MuiPaper-root, & .instant-payment': {
        background: 'transparent',
      },
      '& .new-wallet:not(.instant-payment)': {
        background: '#fff',
      }
    }
  },
  closeWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeBtn: {
    padding: "0.35rem 0.5rem",
    marginTop: "-17px",
    marginBottom: "-10px",
    marginRight: "-21px",
    position: 'relative',
    top: -5,
    height: 36,
    width: 36,
    '& svg': {
      fontSize: '1.25rem',
    }
  },
  ribbon: {
    '--ribbon-size': '1.5rem',
    fontSize: '1rem',
    lineHeight: '1.1rem',
    paddingTop: '0.4rem',
    marginBottom: '1rem',
    "@media (max-width: 365px)": {
      fontSize: '.9rem',
    }
  },
  boxText: {
    padding: "0.5rem 0.75rem",
    background: "var(--clr-light-grey2)",
    borderRadius: "0.5rem",
    margin: "0.25rem 0.75rem",

    "@media (max-width: 365px)": {
      margin: '0.25rem 0.25rem',
    },
  },
  infoText: {
    color: "var(--clr-red2)",
    textAlign: "center",
    marginTop: "1rem",
    fontSize: ".8rem"
  },
  footer: {
    textAlign: "center",
  },
  footerRU: {
    marginTop: "1rem",
  },
  recharge: {
    background: "var(--clr-green) !important",
    color: "#fff",
    fontSize: "var(--fz-large)",
    boxShadow: "none",
    padding: "0.65rem 1.25rem",
    borderRadius: "2rem",
    '& .MuiButton-label': {
      display: "flex",
      alignItems: "center",
      '& .icon': {
        display: 'flex',
      }
    }
  }
})

const DealPurchasePaymentResponseDialog = ({
  type = 'chat', // chat/call
  open: parentOpen = false,
  response: parentResponse = {},
  handleClose: parentHandleClose = () => { },
}) => {
  const history = useHistory()
  const classes = useDealInitiatorStyles()
  const [open, setOpen] = useState(false)
  const urlHash = 'back-button--wallet-recharge/deal-purchase-payment-response-dialog'
  const commonRRBtnEle = useRef(null)
  const [commonRRBtnData, setCommonRRBtnData] = useState({})
  const [deal, setDeal] = useState(false)
  const rechargeRedirect = useRechargeRedirect()

  useEffect(() => {
    updateDealData()
  }, [])

  useEffect(() => {
    const handleHashChange = (e) => {
      // if (!e.newURL.includes(`#${urlHash}`) && BrowserUtils.isNewUI()) {
      //   setOpen(false)
      // }

      if (e.oldURL.endsWith(`#${urlHash}`)
        && e.newURL.endsWith('#back-button--wallet-recharge')
        && open === true) {
        setOpen(false)
      }

      // Reset wallet type to default after close the native payment dialog & showing default wallet page
      if (e.oldURL.endsWith('#back-button--wallet-recharge')
        && open === true
        && (GatewayConfig.dealsGateway !== 'default' && WalletUtils.getType() !== WalletUtils.getDefaultType())
      ) {
        // const refData = rechargeRedirect.getAll()
        // delete refData.recharge
        // delete refData.amount
        // delete refData.dealPurchase
        // rechargeRedirect.setData(refData)
        // setTimeout(() => {
        //   WalletUtils.setType(WalletUtils.getDefaultType())
        // }, 500)
      }
    }

    window.addEventListener('hashchange', handleHashChange)
  })

  useEffect(() => {
    // console.log('parentOpen:', parentOpen)
    if (parentOpen) {
      // runUserUpdates()
      // console.log(parentResponse)
      updateDealData()
      setOpen(true)
    }
  }, [parentOpen])

  useEffect(async () => {
    if (open === true) {
      window.location.hash = urlHash
    } else if (open === false) {
      parentHandleClose()
    }
  }, [open])

  const updateDealData = () => {
    // const refData = rechargeRedirect.getAll()
    // // console.log('DealPurchasePaymentResponseDialog refData', refData)
    // if (refData?.recharge) {
    //   setDeal(refData.recharge)
    // }

    if (localStorage.getItem('dealOptions') !== null) {
      const dealOptions = JSON.parse(localStorage.getItem('dealOptions'))
      const data = dealOptions.find(deal => deal.deal_type === 'FREE_CONSULT_MINUTES')
      setDeal(data)
    }
  }

  const browserBackTrigger = () => {
    if (!window.location.hash.includes(`#${urlHash}`) && open === true && BrowserUtils.isNewUI()) {
      // history.goBack()
    }
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    }
    browserBackTrigger()
    setOpen(false)
  }

  const handleBtnClick = () => {
    if (rechargeRedirect.isValid()
      && (rechargeRedirect.getItem('astrologerID') || DealPurchase.isPaymentRequest())
    ) {
      const type = rechargeRedirect.getItem('type') || 'chat'
      if (rechargeRedirect.getItem('astrologerID')) {
        window.location.href = `/?recharge_redirect=1#${type}request`
      } else {
        window.location.href = `/`
      }
      return
    }
  }

  const CloseBtn = () => {
    const handleClose = () => {
      history.goBack()
      setOpen(false)
    }
    return (
      <div className={clsx('close-wrapper', classes.closeWrapper)}>
        <IconButton
          className={clsx('close-btn', classes.closeBtn)}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div>
    )
  }

  const DialogContent = () => {
    return (
      <>
        <CloseBtn />
        <div className={clsx('ribbon-both', classes.ribbon)}>
          <span>Claim your<br /><b>{deal.minutes} MIN {type === 'call' ? 'CALL' : 'CHAT'} now!</b><br /></span>
        </div>
        <div className={clsx('box', classes.boxText)}>Your ₹{deal.amount} Recharge is successful.</div>
        <div className={clsx('box', classes.boxText)}>You can claim your {deal.minutes} min {type === 'call' ? 'call' : 'chat'} with your preferred astrologer.</div>
        <div className={clsx('info', classes.infoText)}>Offer valid for first time consultation only.</div>
        <div className={clsx('footer', classes.footer)}>
          <Button className={clsx('recharge', classes.recharge)} onClick={handleBtnClick}>
            <div className="text">CLAIM NOW</div>
            <div className="icon"><ChevronRight /></div>
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <Dialog
        className={clsx(
          classes.dialog,
          'deal-recharge-dialog',
          // refData?.deal_transparent_page ? 'transparent-page' : '',
        )}
        open={open}
        onClose={handleClose}
      >
        {open === true ? <DialogContent /> : <></>}
      </Dialog>
      <RechargeLinkRedirect
        refData={commonRRBtnData}
        onClick={(e) => {
          e.goToWallet()
        }}
      >
        <button style={{ display: "none" }} ref={commonRRBtnEle}></button>
      </RechargeLinkRedirect>
    </>
  )
}

export default DealPurchasePaymentResponseDialog