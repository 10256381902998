import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import CountDownTimer from './CountDownTimer'
import Carousel from './TouchCarousel/Carousel'
import clsx from 'clsx'
import AstrologerAvatar from './AstrologerAvatar'
import { AssistantRounded, ChatBubbleOutlineRounded, CheckCircleOutlineRounded, DoneAll, LooksOne, MergeTypeRounded, PlaylistAddCheckRounded, RecordVoiceOverRounded, Star, StarRounded, TranslateRounded } from '@material-ui/icons'
import { ContentFreeChatInfo, ContentFreeChatInfoForWaitingScreen, ContentPaidChatInfoForWaitingScreen } from './StaticContent'


const useStyles = makeStyles({
  astrologerWaiting: {
    '--gap': '10px',
    backgroundColor: 'var(--clr-green)',
    position: 'absolute',
    bottom: '-3px',
    right: '0',
    left: '0',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    gap: 'var(--gap)',
    padding: 'var(--gap) 0',
    fontSize: '13px',
    lineHeight: 'normal',
    "& .carousel-container": {
      height: "50px",
    },
  },
  headerContent: {
    padding: '0 var(--gap)',
    textAlign: 'center',
  },
  timer: {
    opacity: .8
  },
  card: {
    '& .MuiAvatar-root': {
      width: '100%',
      maxWidth: '100px',
      height: 'auto',
    }
  },
  cardInner: {
    backgroundColor: "#fff",
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    padding: '0 10px',
    borderRadius: '12px',
  },
  text: {
    width: 'calc(100% - 84px)',
    fontSize: 13,
    lineHeight: 'normal'
  },
  textBefore: {
    width: '32px',
  },
  textAfter: {
    width: '32px'
  }
})

/**
 * Limits the number of words in a given array and returns a formatted string
 *
 * This function takes an array of words and limits the number of words to three (or fewer).
 * It then formats and returns a string that joins the words with commas and an optional " & " if more than one word remains.
 *
 * @param {Array} words - An array of words to be processed.
 *
 * @returns {string} - A formatted string containing the limited words.
 */
const limitWords = (words) => {
  if (words.length > 3) {
    words.length = 3
  }
  return (
    words.slice(0, -1).join(', ')
    + (words.length > 1 ? ' & ' : '')
    + words.slice(-1).join(', ')
  )
}

/**
 * Generates data for a carousel component
 *
 * @param {Object} params - An object containing parameters for generating the carousel data
 * @param {string} params.astrologer - An astrologer object
 * @param {boolean} [params.freeConsultation=false] - A boolean indicating whether the astrologer offers free consultation
 *
 * @returns {Object} - An object containing carousel data for the specified astrologer
 */
const carouselData = ({ astrologer, freeConsultation = false }) => {
  const avatar = <AstrologerAvatar astrologer={astrologer} />
  const expertise = limitWords(astrologer.expertise)
  const languages = limitWords(astrologer.languages)

  const data = [
    {
      text: `Kindly wait as we connect ${astrologer.name}.`,
      before: avatar,
      after: <ChatBubbleOutlineRounded style={{ color: 'var(--clr-green)' }} />
    },
    {
      text: `${astrologer.name} is one of our top 
        astrologers${astrologer.reviewsData.rating ? ` with a rating of ${astrologer.reviewsData.rating}` : ``}.`,
      before: avatar,
      after: <StarRounded style={{ color: 'var(--clr-yellow)' }} />
    },
    {
      text: `We have already sent your birth details to ${astrologer.name}.`,
      before: avatar,
      after: <CheckCircleOutlineRounded style={{ color: 'var(--clr-green)' }} />
    },
    {
      text: `${astrologer.name} is an expert in ${expertise}.`,
      before: avatar,
      after: <AssistantRounded style={{ color: 'var(--clr-yellow)' }} />
    },
    {
      text: `${astrologer.name} speaks ${languages}.`,
      before: avatar,
      after: <RecordVoiceOverRounded style={{ color: 'var(--clr-green)' }} />
    },
  ]

  if (freeConsultation) {
    data.push({
      text: 'This consultation will be FREE',
      before: avatar,
      after: <PlaylistAddCheckRounded style={{ color: 'var(--clr-green)' }} />
    })
    data.push({
      text: <ContentFreeChatInfoForWaitingScreen astrologer={astrologer} />,
      before: avatar,
      after: <DoneAll style={{ color: 'var(--clr-yellow)' }} />
    })
  } else {
    data.push({
      text: <ContentPaidChatInfoForWaitingScreen astrologer={astrologer} />,
      before: avatar,
      after: <DoneAll style={{ color: 'var(--clr-yellow)' }} />
    })
  }

  return data
}

/**
 * ContentCarousel Component
 *
 * A React component for displaying content related to an astrologer within a carousel.
 *
 * @param {Object} props - An object containing properties for configuring the ContentCarousel
 * @param {string} props.astrologer - An astrologer object
 * @param {boolean} [props.freeConsultation=false] - A boolean indicating whether the astrologer offers free consultation.
 *
 * @returns {JSX.Element} - A React component for displaying content within a carousel.
 */
const ContentCarousel = ({
  astrologer,
  freeConsultation = false
}) => {
  const data = carouselData({ astrologer, freeConsultation })
  const classes = useStyles()

  return (
    <>
      <Carousel
        data={data}
        loop={true}
        autoplay={5000}
        cardPadCount={2}
        classes={classes}
        swipe={false}
        renderItem={(item, index, current) => {
          return (
            <div
              key={index}
              className={clsx('carousel-card', classes.card, current === index ? 'current-active' : '')}
            >
              <div className={clsx('carousel-card-inner', classes.cardInner)}>
                <div className={classes.textBefore}>{item.before || <>&nbsp;</>}</div>
                <div className={classes.text}>{item.text}</div>
                <div className={classes.textAfter}>{item.after || <>&nbsp;</>}</div>
              </div>
            </div>
          )
        }}
      />
    </>
  )
}

/**
 * AstrologerWaiting Component
 *
 * A React component for displaying a waiting indicator related to an astrologer.
 *
 * @param {Object} props - An object containing properties for configuring the AstrologerWaiting component.
 * @param {string} props.astrologer - An astrologer object
 * @param {number} [props.timer=3] - The duration, in minutes, of the waiting timer.
 * @param {function} [props.onTimerExpire=() => { }] - A callback function to execute when the timer expires.
 * @param {boolean} [props.freeConsultation=false] - A boolean indicating whether the astrologer offers free consultation.
 *
 * @returns {JSX.Element} - A React component for displaying a waiting indicator related to the astrologer.
 */
const AstrologerWaiting = ({
  astrologer,
  timer = 3,
  onTimerExpire = () => { },
  freeConsultation = false
}) => {
  const [expiryTimestamp, setExpiryTimestamp] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    const time = new Date()
    const totalSeconds = time.getSeconds() + timer * 60
    time.setSeconds(totalSeconds)
    setExpiryTimestamp(time)
  }, [timer])

  return (
    <div className={classes.astrologerWaiting}>

      <div className={classes.headerContent}>
        Please wait for the astrologer to join <br />
        this consultation, it takes only less than a minute usually.
      </div>

      <ContentCarousel
        astrologer={astrologer}
        freeConsultation={freeConsultation}
      />

      <CountDownTimer
        className={classes.timer}
        autoStart={false}
        expiryTimestamp={expiryTimestamp}
        onExpire={onTimerExpire}
      />
    </div>
  )
}

export default AstrologerWaiting