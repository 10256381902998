import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ConsolutationRequests } from "./ChatUtls";

const ChatNotificationTrigger = ({ setChatWindowData, setLoading, openProfile, handleUnLogged }) => {
  const [triggerPushNotificationAstrologerID, setTriggerPushNotificationAstrologerID] = useState(false);
  const [triggerPushNotificationTask, setTriggerPushNotificationTask] = useState(false);
  const [triggerChatFromNotificationChatID, setTriggerChatFromNotificationChatID] = useState(false);
  const [triggerChatFromNotificationAstrologer, setTriggerChatFromNotificationAstrologer] = useState(false);
  const [triggerChatFromNotificationStatus, setTriggerChatFromNotificationStatus] = useState(false);
  const history = useHistory();

  const handleTriggerChatFromNotificationChatID = (e) => {
    setTriggerChatFromNotificationChatID(e.target.value);
  };
  const handleTriggerChatFromNotificationAstrologer = (e) => {
    setTriggerChatFromNotificationAstrologer(e.target.value);
  };
  const handleTriggerChatFromNotificationStatus = (e) => {
    setTriggerChatFromNotificationStatus(e.target.value);
  };
  const handleTriggerPushNotificationAstrologerID = (e) => {
    setTriggerPushNotificationAstrologerID(e.target.value);
  };
  const handleTriggerPushNotificationTask = (e) => {
    setTriggerPushNotificationTask(e.target.value);
  };

  const triggerChatFromNotification = async () => {
    setLoading(true);
    const latestChatInfo = await ConsolutationRequests.latestChat(triggerChatFromNotificationChatID);
    const chatInfo = {
      astrologer: triggerChatFromNotificationAstrologer,
      time: Date.now(),
      chatID: latestChatInfo.chatID,
      status: latestChatInfo.status,
    };
    setLoading(false);
    setChatWindowData(chatInfo);
  };

  const triggerPushNotification = async () => {
    if (handleUnLogged({ task: triggerPushNotificationTask, astrologer: triggerPushNotificationAstrologerID }))
      return;

    var data = { chatID: "", task: triggerPushNotificationTask, astrologerID: triggerPushNotificationAstrologerID }
    setLoading(true);
    const pushData = await ConsolutationRequests.pushNotificationData(data);
    if (pushData.consultation.chatID) { //|| pushData.astrologer.chatEnabled
      const chatInfo = {
        astrologer: triggerPushNotificationAstrologerID,
        time: Date.now(),
        chatID: pushData.consultation.chatID,
        status: pushData.consultation.status,
      };
      setLoading(false);
      setChatWindowData(chatInfo);
    } else if (pushData.astrologer.profileUrl) {
      openProfile({ profileUrl: pushData.astrologer.profileUrl });
      //history.push("/astrologer/" + pushData.astrologer.profileUrl)
    }
  };

  return (
    <div style={{ display: "none" }}>
      <button type="button" id="triggerPushNotificationBtn" onClick={triggerPushNotification}>
        Open
      </button>
      <button type="button" id="triggerChatFromNotificationBtn" onClick={triggerChatFromNotification}>
        Open
      </button>
      <input type="text" id="triggerChatFromNotificationChatID" onChange={handleTriggerChatFromNotificationChatID} />
      <input type="text" id="triggerPushNotificationAstrologerID" onChange={handleTriggerPushNotificationAstrologerID} />
      <input type="text" id="triggerPushNotificationTask" onChange={handleTriggerPushNotificationTask} />
      <input
        type="text"
        id="triggerChatFromNotificationAstrologer"
        onChange={handleTriggerChatFromNotificationAstrologer}
      />
      <input type="text" id="triggerChatFromNotificationStatus" onChange={handleTriggerChatFromNotificationStatus} />
    </div>
  );
};
export default ChatNotificationTrigger;
