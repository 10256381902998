import React, { useEffect, useState, useRef } from "react";
import axios from "../../../common/interceptor";
import axios_noheader from "../../../common/interceptor_noheader";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";

import "./UserProfile.css";
import HelmetComponent from "../../../common/HelmetComponent.component";
import userProfileData from "../mock/UserProfileData.mock";
import loadScript from "./SearchLocationInput";
import Snackbar from "../../../common/SnackBarMessage";
import BrowserUtils from "../../user/astrologer/components/UAScripts";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import Moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import QueryString from "../../../common/util/QueryString";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import T2AEventTrack from "../../../common/T2AEventTrack";

const regExpSpecial = /^[a-zA-Z ]*$/;

const UserProfileForPreCallChat = (props) => {
  const hiddenFileInput = useRef(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });
  const [details, setDetails] = useState({
    name: "",
    relation: "",
    gender: "",
    email: "",
    phone: "",
    horoscopeStyle: "",
    dateOfBirth: "",
    placeOfBirth: "",
    timeOfBirth: "",
    profileUrl: "",
    lat: "",
    long: "",
    country: "",
  });

  const isInRegisterFlow = window.location.search.indexOf("register") >= 0;

  const [pageType, setPageType] = useState("");
  const [tempDate, setDate] = useState("");
  const [time, setTime] = useState(null);
  const [submitUrl, setSubmitURL] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [placesScriptLoaded, setPlacesScriptLoaded] = useState(false);
  const [counter, setCounter] = useState(1);
  const [DOBOpen, setDOBOpen] = useState(false);
  const [TOBOpen, setTOBOpen] = useState(false);

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  const onNumValidate = (e) => {
    var validChars = userProfileData.validChars;
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const onDateEdit = (e, value) => {
    if (value && value.length > 0) {
      let temp = value.split("/");
      setDetails({ ...details, dateOfBirth: temp[1] + "/" + temp[0] + "/" + temp[2] });
      setDate(value);
    } else setDate("");
  };

  const onTimeEdit = (e, date) => {
    if (date !== null) {
      setTime(e);
      setDetails({ ...details, timeOfBirth: date });
    }
  };

  const onInputEdit = (e) => {
    switch (e.target.id) {
      case "nameInput":
        setDetails({ ...details, name: e.target.value });
        break;
      case "relationInput":
        setDetails({ ...details, relation: e.target.value });
        break;
      case "mobInput":
        setDetails({ ...details, phone: e.target.value });
        break;
      case "emailInput":
        setDetails({ ...details, email: e.target.value.replaceAll(/\s/g, "") });
        break;
      case "bgImageUpload":
        let file = e.target.files[0];
        setImage(file);
        let tempUrl = "";
        try {
          tempUrl = URL.createObjectURL(file);
        } catch (error) {
          tempUrl = "";
        }
        setDetails({ ...details, profileUrl: tempUrl });
        break;
      default:
    }
  };

  const validateEmail = (email) => {
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test(email);
  };

  const onSubmitUserDetails = () => {
    if (details.name === "" || !details.name) {
      showError("Please enter your Name");
      return;
    } else if (!regExpSpecial.test(details.name)) {
      showError("Name can't contain special characters.");
      return;
    } else if (pageType === "childProfile" && (details.relation === "" || !details.relation)) {
      showError("Please enter your relation.");
      return;
    } else if (pageType === "childProfile" && !regExpSpecial.test(details.relation)) {
      showError("Relation can't contain special characters.");
      return;
    } else if (pageType === "childProfile" && details.phone && details.phone.length < 10) {
      showError("Mobile number must be 10 characters long.");
      return;
    } else if (details.gender === "" || !details.gender) {
      showError("Please select a gender.");
      return;
    } else if (details.dateOfBirth === "" || !details.dateOfBirth) {
      showError("Please enter date of birth.");
      return;
    } else if (details.timeOfBirth === "" || !details.timeOfBirth) {
      showError("Please enter time of birth.");
      return;
    } else if (
      query !== "Test POB" &&
      (query === "" || !query || (
        ((details?.lat || "") === "" || (details?.long || "") === "") &&
        ((details?.lat_dir || "") === "" || (details?.long_dir || "") === "")
      ))
    ) {
      showError("Please enter place of birth.");
      return;
    } else if (details.horoscopeStyle === "" || !details.horoscopeStyle) {
      showError("Please select a horoscope style.");
      return;
    } else if (pageType === "userProfile" && !validateEmail(details.email)) {
      showError("Please enter a valid email.");
      return;
    } else {
      const eData = {
        name: 'Button Action',
        buttonName: 'Confirm birth details'
      }
      trackeventData(eData);

      setLoading(true);
      let file = image;
      if (file) {
        // For cases where user selects a file using explorer
        let fileName = file.name;
        let fileType = file.type;
        let allowedExtensions = [
          "image/jpg",
          "image/png",
          "image/jpeg",
          "image/webp",
          "image/JPG",
          "image/PNG",
          "image/JPEG",
          "image/WEBP",
        ];

        if (!allowedExtensions.includes(file.type)) {
          showError("Invalid file type. Please use jpg/ping/webp formats.");
        }
        if (file.size > 4e6) {
          showError("File size must be less than 4 MB.");
        }

        axios()
          .post("/user/upload-access", {
            fileName: fileName,
            fileType: fileType,
          })
          .then((response) => {
            let accessUrl = response.data.access_url;
            let signedRequest = response.data.url;
            let options = {
              headers: {
                "Content-Type": fileType,
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
                "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization",
              },
            };

            axios_noheader()
              .put(signedRequest, file, options)
              .then(async (result) => {
                sendData(accessUrl);
              })
              .catch((error) => {
                sentryError("USER_PROFILE_IMAGE_UPLOAD", error);
                showError("Something went wrong. please try again");
                setLoading(false);
              });
          })
          .catch((err) => {
            sentryError("USER_PROFILE_SUBMIT", err);
            showError("Something went wrong. please try again");
            setLoading(false);
          });
      } else {
        //For cases where user submits without opening explorer
        sendData();
      }
    }
  };

  const sendData = (data) => {
    const postData = {
      name: details.name,
      relation: details.relation,
      gender: details.gender,
      email: details.email,
      phone: details.phone,
      horoscopeStyle: details.horoscopeStyle,
      dateOfBirth: tempDate,
      placeOfBirth: query,
      timeOfBirth: details.timeOfBirth,
      profileUrl: data ? data : details.profileUrl ? details.profileUrl : "",
      lat: details.lat,
      long: details.long,
      country: details.country,
    }

    if (details?.lat_dir) {
      postData.lat_deg = details.lat_deg;
      postData.lat_min = details.lat_min;
      postData.lat_dir = details.lat_dir;
      postData.long_deg = details.long_deg;
      postData.long_min = details.long_min;
      postData.long_dir = details.long_dir;
    }
    if (details?.time_zone) {
      postData.timeCorrectionValue = details.timeCorrectionValue;
      postData.timeCorrection = details.timeCorrection;
      postData.time_zone = details.time_zone;
    }
    localStorage.setItem('dob', postData.dateOfBirth)
    localStorage.setItem('tob', postData.timeOfBirth)
    localStorage.setItem('pob', postData.placeOfBirth)
    axios()
      .post(submitUrl, postData)
      .then((res) => {
        if (res.data.success) {
          if (pageType === "userProfile") {
            if (BrowserUtils.isAWebview) {
              if (typeof window.AndroidBridge !== "undefined") {
                //let myVar = window.AndroidBridge.call("getAndroidVersion");
                window.AndroidBridge.call(
                  "pushUserProfileDetails",
                  JSON.stringify({
                    name: details.name,
                    relation: details.relation,
                    gender: details.gender,
                    email: details.email,
                    phone: details.phone,
                    horoscopeStyle: details.horoscopeStyle,
                    dateOfBirth: tempDate,
                    placeOfBirth: query,
                    timeOfBirth: details.timeOfBirth,
                    profileUrl: data ? data : details.profileUrl ? details.profileUrl : "",
                    lat: details.lat,
                    long: details.long,
                    country: details.country,
                  })
                );
              }
            } else {
              if (window.Moengage) {
                window.Moengage.add_unique_user_id(details.phone);
                window.Moengage.add_first_name(details.name);
                window.Moengage.add_user_name(details.name);
                window.Moengage.add_email(details.email);
                window.Moengage.add_mobile(Number(details.phone));
                window.Moengage.add_gender(details.gender);
                window.Moengage.add_user_attribute("MOBILE_NUMBER", Number(details.phone));
                window.Moengage.add_user_attribute("DATE_OF_BIRTH", tempDate);
                window.Moengage.add_user_attribute("PLACE_OF_BIRTH", query);
                window.Moengage.add_user_attribute("HOROSCOPE_STYLE", details.horoscopeStyle);
                window.Moengage.add_user_attribute("TIME_OF_BIRTH", details.timeOfBirth);
              }
            }

            localStorage.setItem("name", details.name);
            localStorage.setItem("email", details.email);
            localStorage.setItem("phone", details.phone);
            localStorage.setItem("image", data ? data : details.profileUrl ? details.profileUrl : "");
            //remove local crj if logged in
            localStorage.removeItem("userCRJ");
            localStorage.removeItem("sunsignData");
            localStorage.removeItem("moonsignData");
            localStorage.removeItem("dailyPrediciton");
            //remove local crj if logged in
          }
          window.setTimeout(() => {
            moengage("USERPROFILE_CREATED");
            if (props.location.search !== "") {
              let redirectPath = QueryString().pathname;
              if (redirectPath === "/my-account") {
                props.history.replace("/my-account");
                //window.location.href = "/my-account";
              } else if (redirectPath === "/my-wallet") {
                props.history.replace("/my-wallet");
                //window.location.href = "/my-wallet";
              } else if (redirectPath === "/") {
                props.history.replace("/");
                //window.location.href = "/";
              } else if (redirectPath && redirectPath.indexOf("/astrologer") >= 0) {
                props.history.replace(redirectPath);
                //window.location.href = redirectPath;
              } else {
                // window.location.href = "/";
                props.continue()
              }
            } else {
              //props.history.replace("/");
              // window.location.href = "/";
              props.continue()
            }
          }, 1000);
        } else {
          showError(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        sentryError("USER_PROFILE_SUBMIT_DETAILS", err);
        showError("Error occured, Please try again");
        setLoading(false);
      });
  };

  const getFormattedTime = (date = null) => {
    let formattedTime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    let time = formattedTime.split(":");
    if (time[0] < 10) {
      time[0] = "0" + time[0];
      formattedTime = time.join(":");
    }

    return formattedTime.toUpperCase();
  };

  const updateData = (data = null) => {
    let defaultDate = new Date();
    //defaultDate.setFullYear(2011);

    if (data && data.name) {
      if (!data.email && props.location.pathname === "/userprofile") {
        showError("Please update your profile details.");
      }

      if (data.timeOfBirth) {
        let time = data.timeOfBirth.split(" ");
        defaultDate.setHours(
          time[1] === "PM" && time[0].split(":")[0] !== "12"
            ? parseInt(time[0].split(":")[0]) + 12
            : time[1] === "AM" && time[0].split(":")[0] === "12"
              ? 0
              : time[0].split(":")[0]
        );
        defaultDate.setMinutes(time[0].split(":")[1]);
        setTime(defaultDate);
      }
      if (data.dateOfBirth) {
        const a = data.dateOfBirth;
        setDate(a);
        let dob = data.dateOfBirth.split("/");
        data.dateOfBirth = dob[1] + "/" + dob[0] + "/" + dob[2];
      } else {
        setDate(Moment(defaultDate).format("DD/MM/YYYY"));
      }
      const newDetails = {
        name: data.name,
        relation: data.relation,
        gender: data.gender,
        email: data.email,
        phone: data.phone,
        horoscopeStyle: data.horoscopeStyle,
        dateOfBirth: data.dateOfBirth ? data.dateOfBirth : Moment(defaultDate).format("MM/DD/YYYY"),
        placeOfBirth: data.placeOfBirth,
        timeOfBirth: data.timeOfBirth ? data.timeOfBirth : getFormattedTime(defaultDate),
        profileUrl: data.profileUrl,
        //lat: data.lat,
        //long: data.lon,
        country: data.country,
      }
      if (data?.lat_dir) {
        newDetails.lat_deg = data.lat_deg;
        newDetails.lat_min = data.lat_min;
        newDetails.lat_dir = data.lat_dir;
        newDetails.long_deg = data.long_deg;
        newDetails.long_min = data.long_min;
        newDetails.long_dir = data.long_dir;
      }
      if (data?.time_zone) {
        newDetails.timeCorrectionValue = data.timeCorrectionValue;
        newDetails.timeCorrection = data.timeCorrection;
        newDetails.time_zone = data.time_zone;
      }
      if (data?.lat || data?.lon) {
        newDetails.lat = data.lat;
        newDetails.long = data.lon;
      }
      setDetails(newDetails);
      setQuery((data.lat || data.lat_deg) ? data.placeOfBirth : null);
    } else {
      //setTime(defaultDate); // no default time
      setDate(Moment(defaultDate).format("DD/MM/YYYY"));
      const oldDetails = details;
      if (!details.horoscopeStyle && data.horoscopeStyle) {
        oldDetails.horoscopeStyle = data.horoscopeStyle;
      }
      if (!details.email && data.email) {
        oldDetails.email = data.email;
      }
      if (data && data.phone) {
        setDetails({
          ...oldDetails,
          phone: data.phone,
          dateOfBirth: null, //Moment(defaultDate).format("MM/DD/YYYY"),
          timeOfBirth: null, //getFormattedTime(defaultDate),
        });
      } else {
        setDetails({
          ...oldDetails,
          dateOfBirth: null, //Moment(defaultDate).format("MM/DD/YYYY"),
          timeOfBirth: null, //getFormattedTime(defaultDate),
        });
      }
    }
  };

  const resetPlace = () => {
    setDetails({
      ...details,
      lat: "",
      long: "",
      country: "",
    });
  };

  const onPlacesChange = (e) => {
    setQuery(e.target.value);
    resetPlace();
    if (counter === 1 && placesScriptLoaded) {
      let autoComplete = new window.clickastro.places.Autocomplete(document.getElementById("placeInput"), {
        types: ["(cities)"],
      });

      autoComplete.addListener("place_changed", function () {
        setQuery(document.getElementById("placeInput").value);
        let place = this.getPlace();
        let countryname = "";
        for (let i = 0; i < place.address_components.length; i++) {
          if (place.address_components[i].types[0].indexOf("country") === 0) {
            countryname = place.address_components[i].long_name;
          }
        }
        let oldDetails = details;
        if (oldDetails.hasOwnProperty('lat_deg')) {
          delete oldDetails['lat_deg'];
          delete oldDetails['lat_min'];
          delete oldDetails['lat_dir'];
          delete oldDetails['long_deg'];
          delete oldDetails['long_min'];
          delete oldDetails['long_dir'];
        }
        if (oldDetails.hasOwnProperty('timeCorrectionValue')) {
          delete oldDetails['timeCorrectionValue'];
          delete oldDetails['timeCorrection'];
          delete oldDetails['time_zone'];
        }
        setDetails({
          ...oldDetails,
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
          country: countryname,
        });
      });
    }
    setCounter(counter + 1);
  };

  const handleBackButton = () => {
    if (localStorage.getItem("email") && localStorage.getItem("email") !== "") {
      props.history.goBack();
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + props.location.pathname;
    } else {
      let fetchDetailsUrl = "";
      let userprofileData = {};
      if (props.location.pathname === "/userprofile") {
        setSubmitURL("/profile/user/edit");
        setPageType("userProfile");
        //fetchDetailsUrl = "/profile/user";

        if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
          const appProfileData = window.AndroidBridge.call("getAppProfileData")
          if (appProfileData) {
            try {
              const profileData = JSON.parse(appProfileData)
              userprofileData = {
                name: profileData.name,
                relation: profileData?.relation || null,
                email: profileData.email,
                phone: '',
                gender: profileData.gender,
                placeOfBirth: profileData.place_name,
                dateOfBirth: profileData?.dob.replaceAll('-', '/'),
                timeOfBirth: '10:00 AM',
                horoscopeStyle: 'North Indian',
                profileUrl: null,
                languages: [],
                country: profileData?.country || '',
                lat_deg: profileData.latitude_deg,
                lat_min: profileData.latitude_min,
                lat_dir: profileData.lat_dir,
                long_deg: profileData.longitude_deg,
                long_min: profileData.longitude_min,
                long_dir: profileData.long_dir,
                timeCorrectionValue: profileData?.timeCorrectionValue,
                timeCorrection: profileData?.timeCorrection,
                time_zone: profileData?.time_zone,
              }
              if (profileData?.userId) {
                userprofileData.app_user_id = profileData.userId;
              }
              if (profileData?.chartStyle) {
                if (userProfileData.horoscopeStyle[profileData.chartStyle]) {
                  userprofileData.horoscopeStyle = userProfileData.horoscopeStyle[profileData.chartStyle]
                }
              } else {
                userprofileData.horoscopeStyle = userProfileData.horoscopeStyle[0]
              }
              if (localStorage.getItem('phone') !== undefined) {
                userprofileData.phone = localStorage.getItem('phone')
              } else if (details?.phone) {
                userprofileData.phone = details.phone
              }
              if (profileData.phone == 'undefined') {
                userprofileData.phone = ''
              }
              if (profileData?.tob) {
                userprofileData.timeOfBirth = new Date('1970-01-01T' + profileData.tob + 'Z')
                  .toLocaleTimeString('en-US',
                    { timeZone: 'UTC', hour12: true, hour: '2-digit', minute: '2-digit' }
                  )
              }

              if (profileData?.ca_prev_payment) {
                BrowserUtils.setCachedKey('CAPaid', profileData.ca_prev_payment)
              }
            } catch {
              fetchDetailsUrl = "/profile/user";
            }
          } else {
            fetchDetailsUrl = "/profile/user";
          }
        } else {
          fetchDetailsUrl = "/profile/user";
        }
        moengage("USERPROFILE_PAGE_VIEWED");
        moengage("fa_screen_usr_profile");
      } else if (props.location.pathname === "/childprofile/add") {
        setSubmitURL("/profile/add");
        setPageType("childProfile");
        setLoading(false);
        fetchDetailsUrl = "";
      } else if (props.location.pathname && props.location.pathname.indexOf("/edit-page") >= 0) {
        setSubmitURL("/profile/edit/" + props.match.params.id);
        setPageType("childProfile");
        fetchDetailsUrl = "/profile/" + props.match.params.id;
      } else {
        window.location.href = "/familyuserprofiles";
      }

      if (fetchDetailsUrl !== "") {
        if (props.location.data) {
          updateData(props.location.data);
          setLoading(false);
        } else {
          axios()
            .post(fetchDetailsUrl)
            .then((res) => {
              if (res.data.data.success) {
                updateData(res.data.data.user);
              } else {
                showError(res.data.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              sentryError("USER_PROFILE_DETAILS", err);
              showError("Error occured, Please try again");
              setLoading(false);
            });
        }
      } else if (userprofileData) {
        updateData(userprofileData);
        setLoading(false);
      } else {
        updateData();
      }
    }
    let googleKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY
    loadScript(`https://placesapis.clickastro.com/capac/api/?key=${googleKey}`, () =>
      setPlacesScriptLoaded(true)
    );
    const eData = {
      name: 'Screen View Action'
    }
    trackeventData(eData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackeventData = (data) => {
    const payload = {
      event: {
        name: data.name,
        attributes: {
          'Screen Name': 'Birth details confirmation',
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name,
      }
    }

    if (data.buttonName) {
      payload.event.attributes['Button Name'] = data.buttonName;
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  };

  return (
    <div id="orangeBg">
      <HelmetComponent
        title={
          (props.location.pathname === "/userprofile"
            ? "User Profile Edit"
            : props.location.pathname === "/childprofile/add"
              ? "Add Child Profile"
              : "Edit Child Profile") + " at Findastro"
        }
        url={"https://www.findastro.com" + props.location.pathname}
        description={
          (props.location.pathname === "/userprofile"
            ? "User Profile Edit"
            : props.location.pathname === "/childprofile/add"
              ? "Add Child Profile"
              : "Edit Child Profile") + " at Findastro-Connect to your Favorite Astrologer at Findastro"
        }
      />
      {isLoading ? (
        <div className="circle-loading circle-loading-20px">
          <CircularProgress />
        </div>
      ) : (
        <></>
      )}
      <div id="parentDiv">
        <Box className="userProfileTopArea">
          <ArrowBackIcon id="userProfileArrowIcon" onClick={handleBackButton} />
          <div className="userProfileChildTopArea">
            <div style={{ paddingTop: 6, fontSize: "1.2rem", color: "#fff" }}>User profile</div>
          </div>
        </Box>
        <div id="whiteBg">
          {/* <div id="userProfileImageBorder"></div>
          <Avatar alt="profile image" id="userProfileImage" src={details.profileUrl ? details.profileUrl : ""}>
            {tempName ? tempName[0] : null}
          </Avatar>
          <Camera id="camera" onClick={onCamera} />
          <p id="userProfileNameText2"></p> */}
          <form id="userProfileFormDiv" autoComplete="off">
            <TextField
              autoComplete="new-password"
              id="bgImageUpload"
              type="file"
              accept="image/*"
              onChange={onInputEdit}
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />
            <label htmlFor="nameInput" id="nameLabel">
              Name
            </label>
            <TextField
              autoComplete="name"
              id="nameInput"
              value={details.name ? details.name : ""}
              className="formInputs"
              variant="outlined"
              label="Name"
              placeholder="Enter your name"
              onChange={onInputEdit}
            />
            {pageType === "childProfile" ? (
              <>
                <label htmlFor="relationInput" id="relationLabel">
                  Relationship
                </label>
                <TextField
                  autoComplete="new-password"
                  id="relationInput"
                  value={details.relation ? details.relation : ""}
                  className="formInputs"
                  variant="outlined"
                  label="Relashionship"
                  placeholder="Enter your relationship"
                  onChange={onInputEdit}
                />
                <label htmlFor="mobInput" id="mobLabel">
                  Mobile Number
                </label>
                <TextField
                  autoComplete="off"
                  id="mobInput"
                  value={details.phone ? details.phone : ""}
                  className="formInputs"
                  variant="outlined"
                  label="Mobile number"
                  placeholder="Enter your mobile number"
                  type="text"
                  inputProps={{ maxLength: 10 }}
                  onKeyDown={onNumValidate}
                  onChange={onInputEdit}
                />
              </>
            ) : (
              <></>
            )}

            <FormControl variant="outlined" className="formInputs">
              <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
              <Select
                value={details.gender ? details.gender : ""}
                onChange={(e, value) => setDetails({ ...details, gender: e.target.value })}
                label="Gender"
                id="genderInput">
                {userProfileData.gender.map((gender, index) => (
                  <MenuItem value={gender} key={index}>{gender}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <label htmlFor="genderInput" id="genderLabel">
              Gender
            </label>
            <Autocomplete
              className="formInputs"
              autoSelect={true}
              value={details.gender ? details.gender : null}
              onChange={(e, value) => setDetails({...details, gender: value})}
              id="genderInput"
              options={userProfileData.gender}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Gender" placeholder="Gender" />}
            /> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <label htmlFor="dobInput" id="dobLabel">
                Date of Birth
              </label>
              <KeyboardDatePicker
                margin="normal"
                id="dobInput"
                format="dd/MM/yyyy"
                disableFuture={true}
                className="formInputs"
                placeholder="dd/mm/yyyy"
                views={["year", "month", "date"]}
                inputVariant="outlined"
                openTo="year"
                label="Date of birth"
                minDate={new Date("01/01/" + (new Date().getFullYear() - 100))}
                maxDate={new Date()}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
                value={details.dateOfBirth ? details.dateOfBirth : null}
                onChange={onDateEdit}
                open={DOBOpen}
                onClick={() => {
                  setDOBOpen(true);
                }}
                onClose={() => {
                  setDOBOpen(false);
                }}
              />
              <label htmlFor="timeInput" id="timeLabel">
                Time of Birth
              </label>
              <KeyboardTimePicker
                margin="normal"
                id="timeInput"
                inputVariant="outlined"
                label="Time of birth"
                className="formInputs"
                placeholder="hh:mm"
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
                open={TOBOpen}
                onClick={() => {
                  setTOBOpen(true);
                }}
                onClose={() => {
                  setTOBOpen(false);
                }}
                value={time ? time : null}
                onChange={onTimeEdit}
                keyboardIcon={<AccessTimeIcon />}
              />
            </MuiPickersUtilsProvider>
            <label htmlFor="placeInput" id="placeLabel">
              Place of Birth
            </label>
            <TextField
              autoComplete="street-address"
              id="placeInput"
              onFocus={(e) => window.scrollTo(0, 1000)}
              value={query ? query : ""}
              className="formInputs"
              variant="outlined"
              label="Place of Birth"
              placeholder="Enter your location"
              onChange={onPlacesChange}
            />
            {/* <label htmlFor="horoInput" id="horoLabel">
              Horoscope Style
            </label>
            <Autocomplete
              className="formInputs"
              value={details.horoscopeStyle ? details.horoscopeStyle : null}
              onChange={(e, value) => setDetails({...details, horoscopeStyle: value})}
              id="horoInput"
              options={userProfileData.horoscope}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  readonly
                  label="Horoscope Style"
                  placeholder="Horoscope Style"
                />
              )}
            /> */}
            <FormControl variant="outlined" className="formInputs horoscope-style-input">
              <InputLabel id="demo-simple-select-outlined-label">Horoscope Style</InputLabel>
              <Select
                value={details.horoscopeStyle ? details.horoscopeStyle : ""}
                onChange={(e, value) => setDetails({ ...details, horoscopeStyle: e.target.value })}
                id="horoInput"
                label="Horoscope Style">
                {userProfileData.horoscope.map((horoscope, index) => (
                  <MenuItem key={index} value={horoscope}>{horoscope}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <label htmlFor="emailInput" id="emailLabel">
              Email
            </label>
            <TextField
              autoComplete="one-time-code"
              id="emailInput"
              value={details.email ? details.email : ""}
              className="formInputs"
              variant="outlined"
              label="Email"
              placeholder="Enter your email"
              onChange={onInputEdit}
            />
            {isInRegisterFlow === true ? (
              <>
                <Button
                  id="userProfileSaveButton"
                  variant="contained"
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  style={{ width: "30%", backgroundColor: "#efefef", color: "#9a9a9a" }}>
                  Later
                </Button>
                <Button
                  id="userProfileSaveButton"
                  variant="contained"
                  onClick={onSubmitUserDetails}
                  style={{ left: "30%", width: "70%" }}>
                  Save profile
                </Button>
              </>
            ) : (
              <div className="buttons footer">
                {/* <Button
                  className={"dialogButton secondary"}
                  onClick={() => {
                    props.handleClose();
                  }}>
                  Cancel
                </Button> */}
                <Button className={"dialogButton primary"} onClick={onSubmitUserDetails}>
                  Continue
                </Button>
              </div>
            )}
            <input
              autoComplete="new-password"
              style={{ display: "none" }}
              id="fake-hidden-input-to-stop-google-address-lookup"></input>
          </form>
          <div className="text-info" style={{ padding: "24px", color: "#929292" }}>
            We will use this birth details to give you daily horoscope predictions. Your data will always be secure.
          </div>
        </div>
        <Snackbar
          vertical="top"
          horizontal="right"
          message={snackBar.message}
          closeError={closeError}
          open={snackBar.open}></Snackbar>
      </div>
    </div>
  );
};

export default UserProfileForPreCallChat;
