const accountData = {
  userImage:
    "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  accountOption: [
    {text: "My Wallet Balance", icon: "Wallet"},
    // {text: "My Favourite Astrologers", icon: "Favorite"},
    {text: "My Consultations", icon: "Folder"},
    // {text: "My Family", icon: "Family"},
    {text: "My Languages", icon: "Language"},
  ],
  walletAmount: [
    {amount: "99", points: "100"},
    {amount: "199", points: "210"},
    {amount: "499", points: "530"},
    {amount: "999", points: "1070"},
    {amount: "1999", points: "2150"},
    {amount: "4999", points: "5450"},
    {amount: "9999", points: "11090"},
  ],
  validChars: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "ArrowLeft", "ArrowRight", "Delete"],
  payHistory: [
    {
      text: "TODAY",
      data: [
        {
          title: "Wallet Recharge",
          date: "on 29 Aug 2020, 11:59 AM",
          amount: "+50000",
          key: "1",
        },
        {
          title: "Parameswaran Numbari",
          date: "on 29 Aug 2020, 11:59 AM",
          amount: "-5000",
          key: "2",
        },
        {
          title: "Wallet Recharge",
          date: "on 29 Aug 2020, 11:59 AM",
          amount: "-800",
          key: "3",
        },
      ],
    },
    {
      text: "YESTERDAY",
      data: [
        {
          title: "Wallet Recharge",
          date: "on 29 Aug 2020, 11:59 AM",
          amount: "+50000",
          key: "1",
        },
        {
          title: "Parameswaran Numbari",
          date: "on 29 Aug 2020, 11:59 AM",
          amount: "-5000",
          key: "2",
        },
        {
          title: "Wallet Recharge",
          date: "on 29 Aug 2020, 11:59 AM",
          amount: "-800",
          key: "3",
        },
      ],
    },
  ],
};

export default accountData;
