import React, { Component } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import "./MyAccount.css";
import walletData from "../mock/MyAccountData.mock";
import HelmetComponent from "../../../common/HelmetComponent.component";
import ModalComponent from "./WalletModal.component";
import Snackbar from "../../../common/SnackBarMessage";


import QueryString from "../../../common/util/QueryString";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import { useRechargeRedirect as getRechargeRedirect } from "../../user/astrologer/components/RechargeLinkRedirect";
import T2AEventTrack from "../../../common/T2AEventTrack";
import clsx from "clsx";
import RechargeOptionItem from "../../user/astrologer/components/RechargeOptionItem";
import paymentImage from "../../../../src/common/secure-payment.png"
import { Button, IconButton } from "@material-ui/core";
import ReviewsPanel from "../../user/astrologer/components/ReviewsPanel";
import { Skeleton } from "@material-ui/lab";
import { ChevronLeft } from "@material-ui/icons";
import AstrologerReservedMessage from "../../user/astrologer/components/AstrologerReservedMessage";
import { MIN_BALANCE_MINS } from "../../../app.config";
import Transaction from "../Transaction";
import DealPurchase from "../../feature/deal-purchase/components/DealPurchase";

const styles = (theme) => ({
  error: {
    color: "red",
    fontSize: 10,
    marginTop: 5,
    paddingLeft: 20,
  },
  info: {
    color: "green",
    fontSize: 10,
    marginTop: 5,
    paddingLeft: 20,
    marginBottom: 15,
  },
  offerIcon: {
    width: "30px",
    margin: "-5px auto -10px -10px",
  },
  offerInfo: {
    color: "green",
    fontSize: "13px",
    lineHeight: "26px",
    padding: "0 20px",
  },
});

class UserWallet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackBar: {
        open: false,
        message: "Error occurred, Please try again",
      },
      walletPoints: localStorage.getItem("walletBalance") ? localStorage.getItem("walletBalance") : "0",
      amount: 0,
      name: "",
      isLoading: true,
      isRechargeOptionsLoading: true,
      userData: {},
      tId: "",
      orderId: "",
      rpOrderId: "",
      points: 0,
      amountPaid: 0,
      method: "",
      status: "failed",
      message: "",
      reData: {},
      drawerOpen: false,
      modalOpen: false,
      referModalOpen: false,
      walletOptions: [],
      instantPaymentMethod: false,
      reviewsParameter: {},
      highlightPlan: 499,
      transactionOpen: false,
    }

    this.rechargeRedirect = getRechargeRedirect()

    this.eventsPayload = {
      event: {
        attributes: {
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        'User ID': BrowserUtils.CurrentUserInfo().id,
        'User Name': BrowserUtils.CurrentUserInfo().name,
      }
    }
  }

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  goBack = () => {

    if (window.location.hash === `#back-button--wallet-recharge`) {
      this.props.history.goBack()
      return
    }

    this.props.history.push('/app-home')
    // const params = new URLSearchParams(window.location.search);
    // window.location.href = params.get("pathname") ? params.get("pathname") : "/";
  };

  closeREModal = () => {
    this.setState({ referModalOpen: false });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onSelect = (value) => {
    const point = this.getWalletPoints(value)
    this.setState({ amount: value, points: point });
    setTimeout(() => {
      this.onProceed();
    }, 100);
    this.trackRechargeClick(value)
  };

  onlyNumbers = (e) => {
    var validChars = walletData.validChars;
    if (!validChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  onAmountEdit = (e) => {
    this.setState({ amount: e.target.value });
  };

  getPaymentInfo = () => {
    let GST = (this.state.amount * 18) / 100;
    return (
      "GST @ 18% - Rs." +
      parseFloat(GST).toFixed(2) +
      " | Total Payable - Rs." +
      (parseFloat(this.state.amount) + parseFloat(GST)).toFixed(2)
    );
  };

  clearHistory = (url) => {
    this.props.history.replace(url);
  };

  onRefresh = () => {
    this.setState({ isLoading: true });
    axios()
      .post("/my-account")
      .then((res) => {
        if (res.data.success) {
          localStorage.setItem("name", res.data.data.name);
          localStorage.setItem("email", res.data.data.email);
          localStorage.setItem("phone", res.data.data.phone);
          localStorage.setItem("image", res.data.data.profileUrl);
          localStorage.setItem("walletBalance", res.data.data.walletBalance ? res.data.data.walletBalance : "0");
          this.setState({
            walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
            name: res.data.data.name ? res.data.data.name : "",
            isLoading: false
          });
        } else {
          this.showError(res.data.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        sentryError("MY_WALLET_REFRESH", err);
        this.showError("Error occured, Please try again");
        this.setState({ isLoading: false });
      });
  };

  onProceed = () => {
    if (!this.state.userData.email && !this.state.userData.phone && !this.state.userData.name) {
      const that = this
      this.showError("Please update your profile");
      setTimeout(function () {
        // window.location.href = "/userprofile?pathname=/my-wallet";
        that.props.history.push('/userprofile?pathname=/my-wallet')
      }, 1000);
    } else if (!this.state.amount) {
      this.showError("Please select a plan");
    } else if (this.state.amount < 99) {
      this.showError("Minimum recharge amount is ₹99.");
    } else if (this.state.isLoading) {
      this.showError("Loading, please wait..");
    } else {
      this.setState({ isLoading: true });

      const eData = {
        name: 'Payment Initiate',
        status: 'Initiated',
        amount: this.state.amount,
        points: this.state.points,
        amountPaid: this.state.amount,
        orderId: this.state.orderId,
        transactionId: this.state.tId,
        gateway: 'Razorpay',
        gatewayResponse: 'Unprocessed',
        instantPaymentMethod: this.state.instantPaymentMethod,
        walletBalance: this.state.walletPoints
      };
      axios()
        .post("/wallet/recharge/rp", {
          email: this.state.userData.email,
          phone: this.state.userData.phone,
          amount: this.state.amount,
          mobile_country_code: "91",
        })
        .then((res) => {
          if (res.data.success) {
            this.triggerRazorpay(res.data);
            eData.orderId = res.data.order_id;
            eData.transactionId = res.data.payment_reference_id;
            eData.gatewayResponse = 'Initiated';
            if (res.data.amount) {
              eData.amountPaid = parseInt(res.data?.amount) / 100
            }
          } else {
            this.showError(res.data.message);
            this.setState({ isLoading: false });
          }
          this.trackeventData(eData);
        })
        .catch((err) => {
          sentryError("MY_WALLET_PROCEED", err);
          this.showError("Error occured, Please try again");
          this.setState({ isLoading: false });
          this.trackeventData(eData);
        });

    }
  };

  getWalletDiscount = (amount) => {
    let discount = 0;
    this.state.walletOptions.map(function (wallet) {
      if (wallet.amount == amount) {
        discount = wallet.points - wallet.amount;
      }
    });
    return discount;
  }

  getWalletPoints = (amount) => {
    const wallet = this.state.walletOptions.find((wallet) => wallet.amount === amount);
    return wallet ? wallet.points : amount;
  }

  onClickReferAndEarn = () => {
    this.setState({ isLoading: true });

    axios()
      .post("/refer-earn/install-offer-link")
      .then((res) => {
        this.setState({ reData: res.data, referModalOpen: true, isLoading: false });
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_CANCEL", err);
        this.setState({ isLoading: false });
      });
  };

  triggerRazorpay = (data) => {
    let that = this;
    this.setState({
      isLoading: true,
      tId: data.t_id,
      rpOrderId: data.payment_reference_id,
      orderId: data.order_id,
      amountPaid: parseFloat(data.amount) / 100
    });

    let options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: parseInt(data.amount, 10),
      currency: "INR",
      name: "Clickastro",
      description: "#" + data.order_id,
      order_id: data.payment_reference_id,
      image: "https://images.clickastro.com/ca-logo/ca_logo_with_tag_line_L.png",
      notes: {
        merchant_order_id: data.order_id,
      },
      handler: function (response) {
        that.razorCapturePayment(response);
      },
      prefill: {
        name: data.name,
        email: data.email,
        contact: data.phone,
        method: "",
      },
      theme: {
        color: "#ee600d",
      },
      modal: {
        ondismiss: function () {
          that.razorCancelTransaction();
        },
      },
      config: {
        display: {
          hide: [{ method: "paylater" }],
        },
      },
    };
    if (data.redirect) {
      options["callback_url"] =
        process.env.REACT_APP_API_BASE_URL +
        "/wallet/recharge/rp-wv/success?t_id=" +
        this.state.tId +
        "&oinfo=orderinfo&attr=" +
        (localStorage.getItem("user_attribution") || "");
      options["redirect"] = true;
    }
    this.setState({ isLoading: false });
    let rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      let comments = JSON.stringify(response.error);
      that.razorFailedTransaction(comments);
      this.setState({ instantPaymentMethod: false });
    });
    rzp1.on("payment.submit", function (response) {
      that.razorCreateTransaction(response);
    });
    rzp1.on('payment.error', function (response) {
      BrowserUtils.setLogData('payment', { data: data, response: response })
    });
    rzp1.open();
    // window.location.hash = "backtohome";
  };

  razorCapturePayment = (response) => {
    this.setState({ isLoading: true });
    //console.log(response)
    axios()
      .post("/wallet/recharge/rp/success", {
        t_id: this.state.tId,
        pay_id: response.razorpay_payment_id,
      })
      .then((res) => {
        let success = 0
        if (res) {
          if (res.data.success) {
            success = 1
            if (res.data.balance > 0) {
              localStorage.setItem("walletBalance", res.data.balance);
              BrowserUtils.setCachedKey('userStatus', 'Paid user');
              this.setState({
                walletPoints: res.data.balance,
                rechargeValue: Number(res.data.recharge_amount),
              });
            }
            const eData = {
              name: 'Purchase Action',
              points: res.data.recharge_points,
              amountPaid: res.data.recharge_amount ? parseFloat(res.data.recharge_amount) : 0,
              status: 'Success',
              amount: res.data.recharge_amount,
              orderId: res.data.order_id,
              transactionId: res.data.rp_order_id,
              gateway: 'razorPay',
              gatewayResponse: 'Captured',
              instantPaymentMethod: this.state.instantPaymentMethod,
              walletBalance: res.data.balance
            };
            if (res.data.gst) {
              eData.amountPaid += eData.amountPaid + parseFloat(res.data.gst)
            }
            this.trackeventData(eData);
            this.setState({ modalOpen: true, status: "success" });

            const isDeal = DealPurchase.isLastPurchaseIsDealPurchase();
            T2AEventTrack({}, {
              eventType: isDeal ? 'COT_DEAL_PURCHASE' : 'COT_NONDEAL_PURCHASE',
              actionType: 'purchase',
              value: res.data.recharge_amount,
              sku: isDeal ? `COTDEAL${res.data.recharge_amount}` : `COT${res.data.recharge_amount}`,
              quantity: 1,
              transactionId: res.data.rp_order_id,
              affiliation: 'razorPay',
              tax: res.data.gst || 0
            });
          } else {
            this.setState({ modalOpen: true, status: "failed" });
          }
          this.setState({ isLoading: false, instantPaymentMethod: false });
        } else {
          this.setState({ modalOpen: true, status: "failed" });
          this.setState({ isLoading: false, instantPaymentMethod: false });
        }

        if (success == 0) {
          this.paymentFailedBeforeGateway({
            'props': this.props,
            'queryString': QueryString()
          })
        }
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE", err);
        this.setState({ modalOpen: true, status: "failed" });
        this.setState({ isLoading: false });
        this.paymentFailedBeforeGateway({
          'catch': 1,
          'props': this.props,
          'queryString': QueryString()
        })
        this.setState({ isLoading: false, instantPaymentMethod: false });
      });
  };

  trackeventData = (data) => {
    if (!data.amountPaid) {
      data.amountPaid = data.amount;
    }
    if (!data.points) {
      data.points = data.amount;
    }
    const payload = {
      event: {
        name: data?.name || 'Payment Initiate',
        attributes: {
          'Screen Name': (data.instantPaymentMethod) ? 'Instant popup' : 'My Wallet',
          'Status': data.status,
          'SKU': 'COT' + data.amount,
          'Order ID': data.orderId,
          'Transaction ID': data.transactionId,
          'Price': parseFloat(data.amountPaid),
          'Wallet Amount': parseFloat(data.points),
          'Gateway': data.gateway,
          'Gateway Response': data.gatewayResponse,
          'Cashback': 0,
          'Discount': parseFloat(data.points) - parseFloat(data.amount),
          'Source Screen Name': this.rechargeRedirect.getItem('location') || 'My Wallet',
          'Reward Used': 0,
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Last pay attempt status': data.status,
          'Last pay attempt time stamp': Date.now(),
          'COT wallet balance': parseInt(data.walletBalance),
          'Current wallet balance': parseInt(data.walletBalance)
        }
      }
    }

    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  paymentFailedBeforeGateway = (data) => {
    const payload = {
      event: {
        name: 'Screen View Action',
        attributes: {
          'Screen Name': 'Payment Failed Before Gateway',
          'Source Screen Name': this.rechargeRedirect.getItem('location') || 'My Wallet',
          'Devdata': JSON.stringify(data),
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  trackRechargeClick = (amount) => {
    const payload = {
      event: {
        name: 'Buy Now Action',
        attributes: {
          'Screen Name': 'My Wallet',
          'Button Name': 'Recharge',
          'Target Screen Name': 'Payment Popup',
          'Source Screen Name': this.rechargeRedirect.getItem('location') || 'My Wallet',
          'Button Location': 'My wallet',
          'SKU': 'COT' + amount,
          'Price': amount,
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name
        }
      }
    }
    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  razorCancelTransaction = () => {
    this.setState({ isLoading: true, method: "" });
    const instantPay = this.state.instantPaymentMethod;
    const eData = {
      name: 'Payment Failure',
      status: 'Cancelled',
      amount: this.state.amount,
      instantPaymentMethod: instantPay,
      points: this.state.points,
      amountPaid: this.state.amountPaid || this.state.amount,
      orderId: this.state.orderId,
      transactionId: this.state.rpOrderId,
      gateway: 'Razorpay',
      gatewayResponse: 'Cancelled',
      walletBalance: this.state.walletPoints
    };
    this.trackeventData(eData);

    axios()
      .post("/wallet/recharge/rp/cancel", {
        t_id: this.state.tId,
        order_id: this.state.orderId,
      })
      .then((res) => {
        this.setState({ isLoading: false, instantPaymentMethod: false });
        this.showError("Transaction is cancelled, Please try again");
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_CANCEL", err);
        this.setState({ isLoading: false });
      });

    if (instantPay) {
      this.rechargeRedirect.setItem('instantPayment', false)
    }
  };

  razorCreateTransaction = ($res) => {
    console.log('create transaction', $res)
    if (this.state.method) {
      const eData = {
        name: 'Payment Initiate',
        status: 'Initiated',
        amount: this.state.amount,
        orderId: this.state.orderId,
        transactionId: this.state.rpOrderId,
        gateway: 'Razorpay',
        gatewayResponse: 'Created',
        walletBalance: this.state.walletPoints,
        instantPaymentMethod: this.state.instantPaymentMethod,
        amountPaid: this.state.amountPaid || this.state.amount
      };
      this.trackeventData(eData);
    }
    //this.setState({ method: $res });
  };

  razorFailedTransaction = ($res) => {
    axios()
      .post("/wallet/recharge/rp/failed", {
        t_id: this.state.tId,
        order_id: this.state.orderId,
        comments: $res,
      })
      .then((res) => {
        const eData = {
          name: 'Payment Failure',
          status: 'Cancelled',
          amount: this.state.amount,
          points: this.state.points,
          orderId: this.state.orderId,
          amountPaid: this.state.amountPaid,
          transactionId: this.state.rpOrderId,
          gateway: 'Razorpay',
          gatewayResponse: 'Failed',
          instantPaymentMethod: this.state.instantPaymentMethod,
          walletBalance: this.state.walletPoints
        };
        this.trackeventData(eData);
      })
      .catch((err) => {
        sentryError("MY_WALLET_RECHARGE_FAILED", err);
      });
  };
  isInstantPaymentRequest = () => {
    return (this.rechargeRedirect.isValid() &&
      this.rechargeRedirect.getItem('instantPayment') &&
      this.rechargeRedirect.getItem('amount'))
  }

  tryInstantPayment = () => {
    if (this.isInstantPaymentRequest()) {
      //console.log('recharge', this.rechargeRedirect.getItem('recharge'))
      const instantPayData = this.rechargeRedirect.getItem('recharge')
      const instAmount = this.rechargeRedirect.getItem('amount')

      const eData = {
        name: 'Payment Initiate',
        instantPaymentMethod: true,
        status: 'Initiated',
        amount: instantPayData.amount || instAmount,
        orderId: '',
        transactionId: '',
        gateway: 'Razorpay',
        gatewayResponse: 'Unprocessed',
        walletBalance: this.state.walletPoints,
        points: instantPayData.actualAmount || instAmount,
        amountPaid: instantPayData.amount
      };
      this.setState({ amount: eData.amount, points: eData.points })
      axios()
        .post("/wallet/recharge/rp", {
          email: this.state.userData.email,
          phone: this.state.userData.phone,
          amount: this.rechargeRedirect.getItem('amount'),
          mobile_country_code: "91",
          minutes: this.rechargeRedirect.getItem('minute'),
        })
        .then((res) => {
          if (res.data.success) {
            this.triggerRazorpay(res.data);
            //moengage("WALLET_RECHARGE_ATTEMPTED");
            eData.orderId = res.data.order_id;
            eData.transactionId = res.data.payment_reference_id;
            eData.gatewayResponse = 'Initiated';
            if (res.data.amount) {
              eData.amountPaid = parseInt(res.data?.amount) / 100
              this.setState({ amountPaid: eData.amountPaid })
            }
          } else {
            eData.orderId = res?.data?.order_id || '';
            eData.transactionId = res?.data?.payment_reference_id || '';
            this.showError(res.data.message);
            this.setState({ isLoading: false, instantPaymentMethod: false });
          }
          this.trackeventData(eData);
        })
        .catch((err) => {
          sentryError("MY_WALLET_PROCEED", err);
          this.showError("Error occured, Please try again");
          this.setState({ isLoading: false, instantPaymentMethod: false });
          this.trackeventData(eData);
        });
      return
    }
  }

  userUpdates = async () => {
    return new Promise((resolve, reject) => {
      axios()
        .post('/profile/updates')
        .then((res) => {
          if (res && res.data.success && res.data.data) {
            resolve(res.data.data)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  componentDidMount = () => {
    // console.log({ defaultHighlightPlan: this.state.highlightPlan, rrHighlight: this.rechargeRedirect.getItem('highlight') })
    if (this.rechargeRedirect.getItem('highlight')) {
      this.setState({ highlightPlan: this.rechargeRedirect.getItem('highlight') })
    }

    window.addEventListener('popstate', this.handlePopState);

    const reviewsParameter = {}
    if (localStorage.getItem('userProfileId')) {
      reviewsParameter.user_id = localStorage.getItem('userProfileId')
    } else if (localStorage.getItem('userid')) {
      reviewsParameter.user_id = localStorage.getItem('userid').split('-')[1]
    }

    // Astrologer ID from the recharge request
    const recharge_astrologer = this.rechargeRedirect.getItem('astrologerID')

    // If a last consulted astrologer ID is available in the localStorage
    if (localStorage.getItem('last_consulted_astrologer')) {
      const last_consulted_astrologer = parseInt(localStorage.getItem('last_consulted_astrologer'))

      let param = {
        num_of_reviews: 60,
        rating_from: 4,
        min_char_limit: 15,
        sort_by: 'shuffle',
        astrologer: [last_consulted_astrologer]
      }
      // Check if the astrologer has any reviews
      axios()
        .post("/getReviews", param)
        .then((res) => {
          if (res.data.status === 'success' && res.data.reviews && res.data.reviews.length) {
            reviewsParameter.astrologer = [last_consulted_astrologer]
          } else if (recharge_astrologer) {
            reviewsParameter.astrologer = [recharge_astrologer]
          }
          reviewsParameter.extra = {
            limit_comments: 3,
            random: false
          }
          this.setState({ reviewsParameter })
        })
        .catch((err) => {
          console.warn(err)
        })
    } else {
      reviewsParameter.extra = {
        limit_comments: 3,
        random: false,
        astrologer: [recharge_astrologer]
      }
      this.setState({ reviewsParameter })
    }

    let instantPay = 0;
    if (this.isInstantPaymentRequest()) {
      this.setState({ instantPaymentMethod: true })
      instantPay = 1;
    }

    if (this.props.location.pathname.startsWith("/my-wallet/invalid")) {
      const modal = { modalOpen: true, status: "failed", isLoading: false }
      if (QueryString().t) {
        const params = new URLSearchParams(atob(QueryString().t));
        if (params.get('rp_oid')) {
          modal.orderId = params.get('oid')
          modal.amount = params.get('amount')
          modal.points = params.get('points') || params.get('amount')
          const eData = {
            name: 'Payment Failure',
            instantPaymentMethod: instantPay,
            status: 'Failed',
            amount: params.get('amount'),
            points: params.get('points'),
            amountPaid: params.get('amount'),
            orderId: params.get('oid'),
            transactionId: params.get('rp_oid'),
            gateway: 'Razorpay',
            gatewayResponse: 'Failed',
            walletBalance: parseInt(localStorage.getItem('walletBalance'))
          };
          //console.log(eData)
          this.trackeventData(eData);
        }
      }
      this.setState(modal);
      if (!modal.hasOwnProperty("orderId")) {
        this.paymentFailedBeforeGateway({
          'props': this.props,
          'queryString': QueryString()
        })
      }
      if (instantPay) {
        this.rechargeRedirect.setItem('instantPayment', false)
      }
    } else if (
      this.props.location.pathname.startsWith("/recharge/success") ||
      this.props.location.pathname.startsWith("/recharge/fail")
    ) {
      axios()
        .post("/wallet/transaction/" + this.props.match.params.id, {
          token: QueryString().t,
        })
        .then((res) => {
          if (res && res.data.order_id) {
            const eData = {
              name: 'Payment Failure',
              points: res.data.recharge_points,
              amountPaid: parseFloat(res.data.recharge_amount),
              amount: res.data.recharge_amount,
              orderId: res.data.order_id,
              transactionId: res.data.rp_order_id,
              gateway: 'Razorpay',
              instantPaymentMethod: this.state.instantPaymentMethod,
              walletBalance: parseInt(localStorage.getItem("walletBalance"))
            };

            if (res.data.gst) {
              eData.amountPaid += parseFloat(res.data.gst)
            }

            if (this.props.location.pathname.startsWith("/recharge/success")) {
              eData.name = 'Purchase Action';
              eData.status = 'Success';
              eData.gatewayResponse = 'Success';
              const isDeal = DealPurchase.isLastPurchaseIsDealPurchase();
              T2AEventTrack({}, {
                eventType: isDeal ? 'COT_DEAL_PURCHASE' : 'COT_NONDEAL_PURCHASE',
                actionType: 'purchase',
                value: res.data.recharge_amount,
                sku: isDeal ? `COTDEAL${res.data.recharge_amount}` : `COT${res.data.recharge_amount}`,
                quantity: 1,
                transactionId: res.data.rp_order_id,
                affiliation: 'razorPay',
                tax: res.data.gst || 0
              });
            } else {
              eData.status = 'Failed';
              eData.gatewayResponse = 'Failed';
            }

            if (res.data.success) {
              if (res.data.balance > 0) {
                localStorage.setItem("walletBalance", res.data.balance);
                BrowserUtils.setCachedKey('userStatus', 'Paid user');
                this.setState({
                  walletPoints: res.data.balance,
                  rechargeValue: Number(res.data.recharge_points),
                });
                eData.walletBalance = res.data.balance;
              }
              this.setState({ modalOpen: true, status: "success" });
            } else {
              this.setState({ modalOpen: true, status: "failed" });
              this.setState({ isLoading: false, instantPaymentMethod: false });
            }
            this.trackeventData(eData);
          } else {
            this.setState({ modalOpen: true, status: "failed" });
            this.setState({ isLoading: false, instantPaymentMethod: false });
            this.paymentFailedBeforeGateway({
              'props': this.props,
              'queryString': QueryString()
            })
          }
        })
        .catch((err) => {
          sentryError("MY_WALLET_RECHARGE", err);
          this.setState({ modalOpen: true, status: "failed" });
          this.setState({ isLoading: false, instantPaymentMethod: false });
          this.paymentFailedBeforeGateway({
            'catch': 1,
            'props': this.props,
            'queryString': QueryString()
          })
        });
      if (instantPay) {
        this.rechargeRedirect.setItem('instantPayment', false)
      }
    } else if (this.props.location.pathname === "/user-wallet"
      || this.props.location.pathname === "/my-wallet"
      || this.props.location.hash === "#back-button--wallet-recharge") {
      if (
        !localStorage.getItem("btkn") ||
        localStorage.getItem("btkn") === "" ||
        localStorage.getItem("btkn") === "undefined" ||
        !localStorage.getItem("utkn") ||
        localStorage.getItem("utkn") === "" ||
        localStorage.getItem("utkn") === "undefined"
      ) {
        window.location.href = "/login?pathname=" + this.props.location.pathname;
      } else {
        const eData = this.eventsPayload;
        eData.event.name = 'Screen View Action';
        eData.event.attributes['Screen Name'] = 'My Wallet';
        eData.event.attributes['Wallet Balance'] = localStorage.getItem("walletBalance");
        console.log(eData);
        T2AEventTrack(eData.event, eData.extra);

        axios()
          .post("/my-account")
          .then((res) => {
            if (res.data.success) {
              if (res.data.data.email) {
                localStorage.setItem("name", res.data.data.name);
                localStorage.setItem("email", res.data.data.email);
                localStorage.setItem("phone", res.data.data.phone);
                localStorage.setItem("image", res.data.data.profileUrl);
                localStorage.setItem("walletBalance", res.data.data.walletBalance ? res.data.data.walletBalance : "0");
                this.setState({
                  userData: res.data.data,
                  walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
                  name: res.data.data.name ? res.data.data.name : "",
                  isLoading: false
                });
                this.tryInstantPayment()
              } else {
                this.setState({
                  walletPoints: res.data.data.walletBalance ? res.data.data.walletBalance : "0",
                  isLoading: false
                });
              }
            } else {
              this.showError(res.data.message);
              this.setState({ isLoading: false, instantPaymentMethod: false });
            }
          })
          .catch((err) => {
            sentryError("MY_WALLET_PAGELOAD", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false, instantPaymentMethod: false });
          });

        this.setState({ isLoading: true, isRechargeOptionsLoading: true });
        axios()
          .get("/wallet/rechargeoptions")
          .then((res) => {
            console.log('rechargeoptions:', res)
            if (res.data.success) {
              (async () => {
                let walletOptions = []
                const user = await this.userUpdates()
                if (user.hasOwnProperty('user_status')) {
                  BrowserUtils.setCachedKey('userStatus', user.user_status);
                }
                const highest_recharge_amount = user?.highest_recharge_amount // similar key `last_recharge_amount`
                // const highest_recharge_amount = 0 // Test case 0/104(instant popup cases)/199/499/999/1999/9999/9999999

                // Previous logic - w/o based on recharge amount
                let previous_logic = false
                if (this.rechargeRedirect.getItem('refID') === 'home/wallet-top-icon' || this.rechargeRedirect.getItem('refID') === 'home/recharge-banner') {
                  previous_logic = true
                }

                // removed COT_22 by adding false condition
                if (false && recharge_astrologer) {
                  let minimumOptionShowCount = 3;
                  if (res.data.options.length < minimumOptionShowCount) {
                    minimumOptionShowCount = res.data.options.length;
                  }

                  const astrologerCharge = this.rechargeRedirect.getItem('astrologerCharge') || 0;

                  let minAmount = parseInt(astrologerCharge) * MIN_BALANCE_MINS;

                  let balance = parseInt(localStorage.getItem("walletBalance")) || 0;
                  if (minAmount > balance) {
                    minAmount -= balance
                  } else {
                    minAmount = 0;
                  }
                  if (minAmount > 0) {
                    let lastRemoved = -1;
                    res.data.options.map((w, key) => {

                      if (w.points >= minAmount) {
                        walletOptions.push(w);
                      } else {
                        lastRemoved = key;
                      }

                    })

                    // If options less than minimum options show
                    const needOptionCount = minimumOptionShowCount - walletOptions.length
                    if (needOptionCount > 0 && lastRemoved > -1) {
                      // Adding lower options
                      for (let index = 0; index < needOptionCount; index++) {
                        // If options available
                        if (res.data.options[lastRemoved - index]) {
                          walletOptions.push(res.data.options[lastRemoved - index])
                        }
                      }
                    }
                  } else {
                    walletOptions = res.data.options;
                  }
                } else if (highest_recharge_amount !== null && !previous_logic) {

                  // Add next higher options count
                  let addNextHigherOptionCount = 2
                  const minimumOptionShowCount = 3
                  res.data.options.map((w) => {

                    // Amount will be lower or same
                    if (highest_recharge_amount >= w.amount) {
                      walletOptions.push(w)
                    }

                    // Choosing next two higher options
                    else if (addNextHigherOptionCount) {
                      walletOptions.push(w)
                      addNextHigherOptionCount--
                    }

                  })

                  // If options less than minimum options show
                  const needOptionCount = minimumOptionShowCount - walletOptions.length
                  if (needOptionCount) {

                    // Cache the array length
                    const cachedLength = walletOptions.length

                    // Adding higher options
                    for (let index = 0; index < needOptionCount; index++) {

                      // If options available
                      if (res.data.options[cachedLength + index]) {
                        walletOptions.push(res.data.options[cachedLength + index])
                      }
                    }
                  }
                }

                // Existing logic for recharge redirect min max filter
                else {
                  walletOptions = res.data.options.filter(w => {
                    if (this.rechargeRedirect.getItem('minAmount') && this.rechargeRedirect.getItem('maxAmount')) {
                      return w.amount > this.rechargeRedirect.getItem('minAmount') &&
                        w.amount < this.rechargeRedirect.getItem('maxAmount')
                    }
                    if (this.rechargeRedirect.getItem('minAmount')) {
                      return w.amount > this.rechargeRedirect.getItem('minAmount')
                    }
                    if (this.rechargeRedirect.getItem('maxAmount')) {
                      return w.amount < this.rechargeRedirect.getItem('maxAmount')
                    }
                    return true
                  })
                }

                // Reversing plans orders
                // walletOptions = walletOptions.reverse()

                this.setState({ walletOptions })
                this.setState({ isLoading: false, isRechargeOptionsLoading: false })

              })()
            } else {
              this.showError("Error occurred, Please try again (Failed to list the recharge options)")
              this.setState({ isLoading: false, isRechargeOptionsLoading: false })
            }
          })
          .catch((err) => {
            sentryError("MY_WALLET_OPTIONS", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false, isRechargeOptionsLoading: false });
          });
      }
      //window.location.hash = "backtohome";
    }

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  };

  componentWillUnmount() {
    setTimeout(() => {
      window.removeEventListener('popstate', this.handlePopState, false);
    }, 1000)
  }

  handlePopState = () => {

    // if (this.props.location.pathname.startsWith("/recharge/success") ||
    //   this.props.location.pathname.startsWith("/recharge/fail")) {
    //   return
    // }

    // window.history.pushState(null, null, window.location.pathname)
    // this.props.history.push('/app-home')
  }

  setRefAndEarnModelOpen = (value) => {
    this.setState({ refAndEarnModelOpen: value });
  };

  goToTransaction = () => {
    const eData = this.eventsPayload;
    eData.event.name = 'Button Action';
    eData.event.attributes['Screen Name'] = 'My Wallet';
    eData.event.attributes['Button Name'] = 'Transactions';

    console.log(eData);
    T2AEventTrack(eData.event, eData.extra);
    // window.location.href = '/invoices';
    //this.props.history.push('/invoices')
    this.setState({ transactionOpen: true })
  }

  onRechargeCalculationView = (item) => {
    const eData = this.eventsPayload
    eData.event.name = 'Button Action'
    eData.event.attributes['Screen Name'] = 'My Wallet'
    eData.event.attributes['Button Name'] = 'View Calculation'
    eData.event.attributes['SKU'] = `COT${item.amount}`

    // console.log({ 'on expand': 1, item, eData })
    T2AEventTrack(eData.event, eData.extra)
  }

  onReviewsShowMore = (review) => {
    const eData = this.eventsPayload
    eData.event.name = 'Button Action'
    eData.event.attributes['Screen Name'] = 'My Wallet'
    eData.event.attributes['Button Name'] = 'Show more reviews'

    // console.log({ 'on show more': 1, review, eData })
    T2AEventTrack(eData.event, eData.extra)
  }

  render() {

    return (
      <div className={clsx("app-page new-wallet accountParentDiv", this.state.instantPaymentMethod ? 'instant-payment' : '')}>

        <HelmetComponent
          title="My Wallet at Findastro"
          url="https://www.findastro.com/my-wallet"
          description="My Wallet at Findastro- Connect to your Favorite Astrologer at Findastro"
        />

        <div className="app-page__header accountTopArea">
          <IconButton className="app-page__header__back-icon" onClick={this.goBack}>
            <ChevronLeft />
          </IconButton>
          <div className="app-page__header__title">Wallet</div>
        </div>

        <Button className="wallet__title wallet__transactions_link" onClick={this.goToTransaction}>Transactions</Button>

        {this.rechargeRedirect.getItem('astrologerID') ?
          <AstrologerReservedMessage
            astrologer={{
              id: this.rechargeRedirect.getItem('astrologerID'),
              name: this.rechargeRedirect.getItem('astrologerName'),
            }}
            style={{ marginTop: '1.5rem' }}
          />
          :
          null
        }

        <div className="wallet__info">
          <div className="wallet__title">Your wallet balance:</div>
          <div className="wallet__info__points">{this.state.walletPoints}<sub>pts</sub></div>
          <ins className="wallet__info__ins">1 Point = ₹1</ins>
        </div>

        <div className="recharge-options">
          <div className="wallet__title">Recharge Options:</div>
          <div className="recharge-options">

            {this.state.isRechargeOptionsLoading ?
              <div className="recharge-preload">
                {[1, 2, 3].map(i => <Skeleton key={i} animation="wave">
                  <RechargeOptionItem
                    amount="500"
                    points="550"
                    gst={true}
                    type="wallet-item"
                    rechargeButtonExpand={true}
                    showOptionLabel={true}
                  />
                </Skeleton>)}
              </div>
              :
              <div className="card-v2__options_option recharge-option">
                {this.state.walletOptions
                  .map(item =>
                    <RechargeOptionItem
                      key={item.amount}
                      amount={item.amount}
                      points={item.points}
                      gst={item.gst}
                      type="wallet-item"
                      rechargeClick={() => this.onSelect(item.amount)}
                      highlight={item.amount === this.state.highlightPlan}
                      onExpand={this.onRechargeCalculationView}
                      rechargeButtonExpand={true}
                      showOptionLabel={true}
                    />
                  )}
              </div>
            }
          </div>
        </div>

        <div className="secure-payment">
          <img style={{ maxWidth: '100%', height: 'auto' }} src={paymentImage} alt="Secure Payment" />
        </div>

        {(this.state.reviewsParameter?.astrologer) &&
          <div className="reviews">
            <ReviewsPanel
              reviewsParameter={{
                user_id: this.state.reviewsParameter.user_id,
                astrologer: this.state.reviewsParameter.astrologer,
                extra: this.state.reviewsParameter.extra
              }}
              showMore={false}
              title="User Testimonials"
              noDataMessage=""
            />
          </div>
        }

        {(this.state.reviewsParameter?.user_id) &&
          <div className="reviews">
            <ReviewsPanel reviewsParameter={{
              user_id: this.state.reviewsParameter.user_id
            }}
              title={this.state.reviewsParameter?.astrologer ? '' : 'User Testimonials'}
              excludeAstrologer={this.state.reviewsParameter?.astrologer ? this.state.reviewsParameter.astrologer : []}
              perPage={4}
              onShowMore={this.onReviewsShowMore}
            />
          </div>
        }

        <ModalComponent
          modalOpen={this.state.modalOpen}
          status={this.state.status}
          orderId={this.state.orderId}
          resetHistory={this.clearHistory}
          goBack={this.goBack}
          rechargeValue={this.state.rechargeValue}
        />

        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}
        />

        <Transaction
          open={this.state.transactionOpen}
          handleClose={() => {
            this.setState({ transactionOpen: false })
          }}
        />

      </div>
    );
  }
}

export default withRouter(withStyles(styles)(UserWallet));
