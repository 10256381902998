import React, { Component } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import gTag from "../../../common/google_analytics";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import HelmetComponent from "../../../common/HelmetComponent.component";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SubmitArrowIcon from "@material-ui/icons/ChevronRight";
import Snackbar from "../../../common/SnackBarMessage";
import { FormControlLabel, Switch } from "@material-ui/core";

import "./MyAccount.css";
import FA_localStorage from "../../../common/util/FA_localStorage";

const styles = (theme) => ({
  error: {
    color: "red",
    fontSize: 10,
    marginTop: 5,
    paddingLeft: 20,
  },
  info: {
    color: "green",
    fontSize: 10,
    marginTop: 5,
    paddingLeft: 20,
    marginBottom: 15,
  },
  offerIcon: {
    width: "30px",
    margin: "-5px auto -10px -10px",
  },
  offerInfo: {
    color: "green",
    fontSize: "13px",
    lineHeight: "26px",
    padding: "0 20px",
  },
});
class MyLanguagePreference extends Component {
  localStyles = {
    langHead: {
      fontSize: "0.9rem",
      fontWeight: "700",
      color: "#ee600d",
      paddingBottom: "8px",
    },
    langSection: {
      paddingLeft: 10,
      paddingBottom: 24,
    },
  };

  state = {
    snackBar: {
      open: false,
    },
    languagePreference: [],
    sortedLanguageOption: [],
    sortedLanguageCount: {},
    missedLanguages: [],
    isLoading: true,
    isInRegisterFlow: window.location.search.indexOf("register") >= 0,
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  sortLanguageList = (languageOption) => {
    let lngs = [];
    let astcount = {};
    let lngidmap = {};
    for (let id in languageOption) {
      let lng = languageOption[id].name;
      lngs.push(lng);
      astcount[lng] = languageOption[id].astrologers_count;
      lngidmap[lng] = id;
    }
    lngs.sort();
    this.setState({ sortedLanguageCount: astcount });
    this.setState({ sortedLanguageOption: lngs });
    this.setState({ languageIdMap: lngidmap });
  };

  countCaption = (count) => {
    if (count >= 10) {
      return "More than " + Math.floor(count / 10) * 10 + " astrologers available";
    } else {
      return "Upto 5 astrologers available";
    }
  };

  componentDidMount = () => {
    moengage("LANGUAGE_PAGE_VIEWED");

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + this.props.location.pathname;
    } else {
      axios()
        .post("/profile/user")
        .then((res) => {
          if (res.data.data.success) {
            //res.data.data.user.languages.push("Belarus");
            this.setState({ languagePreference: res.data.data.user.languages });
            this.getLanguageList();
          }
        })
        .catch((err) => {
          sentryError("userLanguageFetch:Error", err);
          this.showError("Unable to fetch user's language preference");
        });
    }
    window.location.hash = "backtohome";
  };

  filterMissingLanguages = () => {
    let misses = [];
    this.state.languagePreference.forEach((lang) => {
      if (this.state.sortedLanguageOption.indexOf(lang) < 0) {
        misses.push(lang);
      }
    });
    this.setState({ isLoading: false });
    this.setState({ missedLanguages: misses });
  };

  getLanguageList = () => {
    axios()
      .get("/list/languages")
      .then((res) => {
        if (res.data.success === true) {
          this.sortLanguageList(res.data.languages);
          setTimeout(this.filterMissingLanguages, 100);
        }
      })
      .catch((err) => {
        sentryError("languageListFetch:Error", err);
        this.showError("Unable to fetch the language list");
      });
  };

  toggleChecked = (e) => {
    let lng = e.target.value;
    let checked = e.target.checked;
    let langs = [...this.state.languagePreference];
    let pos = langs.indexOf(lng);
    if (!checked) {
      langs.splice(pos, 1);
    } else {
      langs.push(lng);
    }
    this.setState({ languagePreference: langs });
  };

  goBack = () => {
    if (this.state.languagePreference.length === 0) {
      this.showError("Select atleast one preferred language for consultation");
    } else {
      this.props.history.goBack();
    }
  };

  uploadLanguagePref = () => {
    if (this.state.languagePreference.length > 0) {
      let selectedIds = [];
      this.state.languagePreference.map((lang) => {
        selectedIds.push(this.state.languageIdMap[lang]);
      });
      axios()
        .post("/profile/user/language", { languageIds: selectedIds })
        .then((res) => {
          if (!res.data.success) {
            this.setState({ isLoading: false });
            this.showError("Unable to save the language preference");
          } else {
            localStorage.removeItem("currentFilterData");
            localStorage.removeItem("filterApplied");
            FA_localStorage("user_languages", this.state.languagePreference.join(","));
            if (this.state.isInRegisterFlow) window.location.href = "/userprofile?register=1";
            else window.location.href = "/";
          }
        })
        .catch((err) => {
          sentryError("LANGUAGE", err);
          this.setState({ isLoading: false });
          this.showError("Unable to proceed, please try again");
        });
    } else {
      this.showError("Select atleast one preferred language for consultation");
    }
  };

  saveLanguagePreference = () => {
    setTimeout(this.uploadLanguagePref, 100);
  };

  commaFormatedText = (arr) => {
    let st = arr.slice(0, arr.length - 1).join(", ");
    return st + " & " + arr.pop();
  };

  render() {
    return (
      <div className="accountParentDiv" style={{ backgroundColor: "#ffffff", fontSize: "0.9rem" }}>
        <div>
          <HelmetComponent
            title="My Wallet at Findastro"
            url="https://www.findastro.com/my-wallet"
            description="My Wallet at Findastro- Connect to your Favorite Astrologer at Findastro"
          />
          {this.state.isLoading ? (
            <div className="circle-loading">
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          <div className="accountTopArea">
            <ArrowBackIcon className="accountArrowIcon2" onClick={this.goBack} />
            <div className="accountChildTopArea">
              <p>My Languages</p>
            </div>
          </div>
          <div style={{ fontWeight: "600", color: "#ee600d" }}>Select preferred language(s) for your consultation:</div>
          {localStorage["filterApplied"] === "yes" && (
            <div style={{ fontSize: "0.8rem", color: "#323232", background: "#ff0", padding: 8, borderRadius: 4 }}>
              Note: changing preferred languages will reset your filters
            </div>
          )}
          {this.state.missedLanguages.length > 0 ? (
            this.state.missedLanguages.length === 1 ? (
              <div
                style={{
                  color: "white",
                  background: "#e82222",
                  padding: "8px",
                  borderRadius: "8px",
                  fontSize: "0.8rem",
                  marginTop: "8px",
                }}>
                Your preferred language {this.state.missedLanguages.join(", ")} is not available now. Please select any
                alternative
              </div>
            ) : (
              <div
                style={{
                  color: "white",
                  background: "#e82222",
                  padding: "8px",
                  borderRadius: "8px",
                  fontSize: "0.8rem",
                  marginTop: "8px",
                }}>
                Your language choice of {this.commaFormatedText(this.state.missedLanguages)} are not available now.
                Please select alternatives
              </div>
            )
          ) : (
            ""
          )}
          <div style={{ padding: "24px 0" }}>
            <div style={this.localStyles.langHead}>Popular:</div>
            <div style={this.localStyles.langSection}>
              {this.state.sortedLanguageOption.map((language) =>
                this.state.sortedLanguageCount[language] >= 10 ? (
                  <div style={{ display: "flex", paddingBottom: "16px", justifyContent: "space-between" }}>
                    <span style={{ display: "inline-block" }}>
                      <div>{language}</div>
                      <small style={{ color: "#999999" }}>
                        {this.countCaption(this.state.sortedLanguageCount[language])}
                      </small>
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          value={language}
                          checked={this.state.languagePreference.indexOf(language) >= 0}
                          onChange={this.toggleChecked}
                        />
                      }
                    />
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
            <div style={this.localStyles.langHead}>Others:</div>
            <div style={this.localStyles.langSection}>
              {this.state.sortedLanguageOption.map((language) =>
                this.state.sortedLanguageCount[language] < 10 ? (
                  <div style={{ display: "flex", paddingBottom: "16px", justifyContent: "space-between" }}>
                    <span style={{ display: "inline-block" }}>
                      <div>{language}</div>
                      <small style={{ color: "#999999" }}>
                        {this.countCaption(this.state.sortedLanguageCount[language])}
                      </small>
                    </span>
                    <FormControlLabel
                      control={
                        <Switch
                          value={language}
                          checked={this.state.languagePreference.indexOf(language) >= 0}
                          onChange={this.toggleChecked}
                        />
                      }
                    />
                  </div>
                ) : (
                  ""
                )
              )}
            </div>
            {
              <div style={{ height: 48 }}>
                <Button
                  style={{
                    color: "white",
                    width: "100%",
                    marginTop: "24px",
                    padding: "8px 10px",
                    background: this.state.languagePreference.length === 0 ? "rgb(208, 208, 208)" : "rgb(0, 193, 128)",
                    position: "fixed",
                    left: "50%",
                    bottom: "0px",
                    borderRadius: "0px",
                    boxShadow: "0 0 6px rgb(0 0 0 / 50%)",
                    maxWidth: "450px",
                    transform: "translate(-50%)",
                  }}
                  disabled={this.state.languagePreference.length === 0}
                  variant="contained"
                  id="langSubmitButton"
                  onClick={() => {
                    this.saveLanguagePreference();
                  }}>
                  {"Save language preference"}
                </Button>
              </div>
            }
          </div>
          <Snackbar
            vertical="top"
            horizontal="right"
            message={this.state.snackBar.message}
            closeError={this.closeError}
            open={this.state.snackBar.open}></Snackbar>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MyLanguagePreference));
