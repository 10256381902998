import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import {withStyles} from "@material-ui/core/styles";

import "./astrovision-font.css";
import makeStyles from "./makeStyles.js";
import HelmetComponent from "../../../common/HelmetComponent.component";
import miscScreensData from "../mock/MiscScreensData.mock";
import Horoscope from "./Horoscope.component";
import Snackbar from "../../../common/SnackBarMessage";

import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const styles = (theme) => ({
  ...makeStyles,
});
class DailyUpdatesSpecific extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    signInfo: {},
    isLoading: true,
    moonsign: "",
    sunsign: "",
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  componentDidMount = () => {
    let sign = this.props.location.pathname.split("/")[2];
    for (let i = 0; i < 12; i++) {
      if (sign === miscScreensData.signData[i].sign) {
        this.setState({signInfo: miscScreensData.signData[i]});
        axios()
          .post("/horoscope/daily", {sign: miscScreensData.signData[i].id})
          .then((res) => {
            if (res.data.success) {
              console.log(res.data);
              this.setState({
                moonsign: res.data.data.moonsign ? res.data.data.moonsign.replace(/<\/?[^>]+(>|$)/g, "") : "",
                sunsign: res.data.data.moonsign ? res.data.data.sunsign.replace(/<\/?[^>]+(>|$)/g, "") : "",
                isLoading: false,
              });
            } else {
              this.showError(res.data.message);
              this.setState({isLoading: false});
            }
          })
          .catch((err) => {
            sentryError("DAILY_UPDATES", err);
            this.showError("Error occured, Please try again");
            this.setState({isLoading: false});
          });
        return;
      }
    }
    //window.location.href = "/notfound";
  };

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.sidebarScreensParentDiv}>
        <HelmetComponent
          title="Daily updates at Findastro"
          url="https://www.findastro.com/daily-updates"
          description="Daily updates at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        <div className={classes.sidebarScreensTopArea}>
          <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={this.props.history.goBack} />
          <div className={classes.sidebarScreensChildTopArea}>
            <p>Daily</p>
            <h3 className={classes.sidebarScreensText}>Updates</h3>
          </div>
        </div>
        <Horoscope
          moonsign={this.state.moonsign}
          sunsign={this.state.sunsign}
          signInfo={this.state.signInfo}
          pathname={this.props.location.pathname}
          classes={classes}
        />
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DailyUpdatesSpecific));
