import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import axios from '../../../../../common/interceptor'
import { useState } from 'react'
import AstrologerDetail from '../view/AstrologerDetail'
import { useEffect } from 'react'
import clsx from 'clsx'
import { useHistory } from "react-router-dom"
import { useRef } from 'react'
import BrowserUtils from '../UAScripts'
import T2AEventTrack from '../../../../../common/T2AEventTrack'
import moment from "moment/moment"
import userProfileData from '../../../../user-profile/mock/UserProfileData.mock'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '1rem',
    '& .ast-card-ctabar': {
      display: 'none !important',
    },
    '& .astrologerInfo': {
      width: '100%',
      marginBottom: '0',
    },
    '& .astrologerDetailsWrap': {
      marginTop: '0',
    },
    '& .astrologerDetailsWrap': {
      marginTop: '0',
    },
  },
  content: {
    fontSize: '13px',
    textAlign: 'center',
    padding: '1rem 1rem 0 1rem',
    '& h4': {
      fontSize: '14px',
      marginTop: '0px',
      marginBottom: '5px',
    }
  },
  buttons: {
    maxWidth: '200px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '.5rem',
    textAlign: 'initial',
  },
  actionButton: {
    background: 'var(--clr-red) !important',
    color: 'white',
    padding: '0.5rem 0.85rem',
    borderRadius: '0.5rem',
    width: '100%',
    '&.continue': {
      background: 'var(--clr-green) !important',
      marginBottom: '0.5rem',
    },
    '&.btn-action .MuiButton-startIcon': {
      display: 'none',
    }
  }
})

const AstrologerRecommendation = ({
  type,
  currentAstrologer,
  onFailed = () => { },
  content = {
    top: `We are sorry, this astrologer is currently not available.
    Try another astrologer?`
  },
  APIParams = {},
  afterLoad = () => { },
  beforeNewConsultation = () => { },
  startNewConsultation = (data) => { },
  onYesButton = false,
  onNoButton = false,
  additionalData = {} // for tracking
}) => {
  const classes = useStyles()
  const [astrologer, setAstrologer] = useState(false)
  const history = useHistory()
  const containerRef = useRef(null)
  const [eventData, setEventData] = useState({})

  useEffect(() => {
    tryAstrologer()
  }, [])

  useEffect(() => {
    if (eventData?.END_TIME) {
      console.trace('AstrologerRecommendation:T2AEventTrack:eventData', eventData)
      T2AEventTrack(eventData.event, eventData.extra)
    }
  }, [eventData.END_TIME])

  const getAstrologerPostData = () => {

    let postData = {
      user_age: 0,
      // page_count: 10,
      ...APIParams
    }

    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      let profileData = window.AndroidBridge.call("getAppProfileData")
      if (profileData) {
        try {
          profileData = JSON.parse(profileData);
        } catch (er) { profileData = {}; }

        const dob = moment(profileData.dob, 'DD-MM-YYYY');
        if (dob.isValid()) {
          postData.user_dob = dob.format('YYYY-MM-DD');
          postData.user_age = moment().diff(dob, 'years');
        }
        postData.app_language = profileData.appLanguage;
        postData.app_chartstyle = userProfileData.horoscopeStyle[profileData.chartStyle ? profileData.chartStyle : 0];
        postData.user_gender = profileData.gender;
        postData.user_country = profileData.country;
      }
    }

    return postData
  }

  const tryAstrologer = () => {
    // @Deprecated - random astrologer API 
    // http://devapi.findastro.co/api/v1/astrologers/random-astrologer?lang=english&exclusion_list=255

    // console.log({ params })

    axios()

      .post('/astrologers/all/ma', getAstrologerPostData())
      .then((res) => {
        if (res.data.success && res.data.data.length) {

          const astrologers = res.data.data.filter(a => a.chargesChat)
          const ast = astrologers[0]
          setAstrologer(ast)
          afterLoad()

          // Tracking event
          let consultationType = 'Paid'
          if (additionalData.wallet_balance > 0 && additionalData.freeChat) {
            consultationType = 'Free paid'
          } else if (additionalData.freeChat) {
            consultationType = 'Free'
          }
          trackEventData({
            name: 'Screen View Action',
            attributes: {
              'Consultation Type': consultationType,
              'Astrologer ID': ast.id,
              'Astrologer Name': astrologerName(ast),
            }
          })
        } else {
          console.error('[astrologers/all/ma]: No astrologer found')
          onFailed('No astrologer found.', { res })
        }
      })
      .catch((err) => {
        onFailed('No astrologer found.', err)
      })

  }

  const astrologerName = (ast) => {
    return (
      ast.salutation && ast.name.indexOf(ast.salutation) < 0
        ? [ast.salutation, ast.name].join(" ")
        : ast.name
    )
  }

  const handleYesButton = () => {

    trackEventData({
      name: 'Button Action',
      attributes: {
        'Button Name': 'Yes start consultation',
        'Button Location': 'In chat'
      }
    })

    if (onYesButton) {
      onYesButton(astrologer)
    }
  }

  const handleNoButton = () => {

    trackEventData({
      name: 'Button Action',
      attributes: {
        'Button Name': 'No try another astrologer',
        'Button Location': 'In chat',
      }
    })

    // if (onNoButton) {
    //   onNoButton(astrologer)
    // } else {
    //   window.location.href = '/app-home-listing'
    // }
    window.location.href = '/app-home-listing'
  }

  const trackEventData = (data) => {
    const payload = {
      event: {
        name: data.name,
        attributes: {
          'Screen Name': 'Astrologer recommendation in chat',
          'Astrologer ID': astrologer.id,
          'Astrologer Name': astrologerName(astrologer),
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Source Screen Name': 'Chat screen',
          ...data.attributes
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'COT wallet balance': localStorage.getItem("walletBalance") ? parseInt(localStorage.getItem("walletBalance")) : 0,
          'Current wallet balance': localStorage.getItem("walletBalance") ? parseInt(localStorage.getItem("walletBalance")) : 0
        }
      },
      END_TIME: Date.now()
    }

    setEventData(payload)
  }

  return (
    <>
      {astrologer ?
        <div
          className={clsx(
            'try-another-astrologer',
            'new-style',
            classes.container,
            `type-${type}`
          )}
          ref={containerRef}
        >

          <div className={classes.content}>
            <h4> {astrologerName(currentAstrologer)} is currently not available</h4>
            <div className="text">{content.top}</div>
          </div>

          <AstrologerDetail
            currentConsultType="chat"
            family={astrologer}
            key={`oal-${astrologer.id}`}
            isSearch={true}
          />

          <div className={classes.content}>
            Start consultation with {astrologerName(astrologer)}
            <div className={classes.buttons}>
              {/* <Button onClick={handleYesButton} className={clsx(classes.actionButton, 'continue')}>Yes, start consultation</Button> */}
              <AstrologerDetail.ActionBtn
                type="chat"
                clickFrom="Try another astrologer - Yes, start consultation"
                onlineText="Yes, start consultation"
                family={astrologer}
                className={clsx(classes.actionButton, 'continue')}
                beforeNewConsultation={beforeNewConsultation}
                onConsultButtonClick={handleYesButton}
                skipBirthDetailsForm={true}
                startNewConsultation={() => startNewConsultation(astrologer)}
                currentURL="astrologerRecommendation"
              />
              <Button onClick={handleNoButton} className={clsx(classes.actionButton)}>No, try another astrologer</Button>
            </div>
          </div>

        </div>
        :
        null
      }
    </>
  )
}

export default AstrologerRecommendation