import {createSlice} from "@reduxjs/toolkit";

export const astrologerSlice = createSlice({
  name: "consoleReducer",
  initialState: [],
  reducers: {
    //Todo this will be changed according to the page components action,
    astrologerAction: (state, action) => {
      //Todo state updation logic will implement here
    },
  },
});

export const {astrologerAction} = astrologerSlice.actions;
export const astrologerReducer = astrologerSlice.reducer;
