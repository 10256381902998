const isEnabled = () => {
  return localStorage.getItem('enableDealPurchase') === 'false' ? false : true
}

const DealPurchaseConfig = {
  is_enabled: isEnabled(),
  paymentMethod: ({ refData }) => {
    let method = 'default'
    if (localStorage.getItem('enableNativePaymentForDealPurchase') === 'true') {
      method = 'native'
    } else if (localStorage.getItem('enableNativePaymentForDealPurchase') === 'false') {
      method = 'default'
    } else if (refData?.dealPurchase) {
      method = 'native'
    }

    console.log('DealPurchaseConfig:paymentMethod:method', method)

    return method
  }
}

export default DealPurchaseConfig