import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../../../common/interceptor";
import axios_noheader from "../../../../common/interceptor_noheader";
import sentryError from "../../../../common/sentryError";
import moengage from "../../../../common/moengage";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "react-device-detect";
import BrowserUtils from "./UAScripts.js";
import { VERSION, STATUS_API_PATH, MIN_SCROLL_TO_HIDE, SUPPORT_NUMBER } from "../../../../app.config";
import AstrologerUtils, { debounce, isChatEnabled } from "./AstrologerUtils";
import clsx from "clsx";
import { useHistory } from 'react-router-dom'


import "./view/css/Astrologers-List-Updated.css";
import logo from "../../../../../src/common/findastro_logo.png";
import playstore from "../../../../../src/common/download_playstore.png";
import HelmetComponent from "../../../../common/HelmetComponent.component";
import Snackbar from "../../../../common/SnackBarMessage";
import SideDrawerComponent from "./SideDrawer.component";
import FilterDrawerComponent from "./FilterDrawer.component";
import SortDrawerComponent from "./SortDrawer.component";
import AstrologerCallDialog from "./AstrologerCallDialog.component";
import AppInstallBanner from "./AppInstallBanner.component";
import UpdateApp from "./UpdateApp.component";
import AstrologerDetailsComponent from "./Astrologer-Details.component";
import FA_localStorage from "../../../../common/util/FA_localStorage";
import IsSupported from "../../../../common/util/localStorageCheck";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import Modal from "@material-ui/core/Modal";

import { Box } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import FilterIcon from "@material-ui/icons/FilterList";
import FilterIconRound from "@material-ui/icons/CheckCircle";
import BookingIcon from "@material-ui/icons/Schedule";
import Divider from "@material-ui/core/Divider";
/* import NotificationsIcon from "@material-ui/icons/Notifications"; */
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CallIcon from '@material-ui/icons/Call';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import TestimonialData from "../../../../common/TestimonialData";
import defaultImage from "../../../../common/default_profile_image.svg";
import Rating from "@material-ui/lab/Rating";
import theme from "../../../../theme/App-new.theme";

import astrologerListData from "../mock/Astrologers.mock";
import CouponRedeemDialog from "./CouponRedeemDialog.component";
import Button from "@material-ui/core/Button";
import { ReactComponent as TYBackdrop } from "../../../../common/tybackdrop.svg";
import BlastConfetti from "../../../misc-screens/components/Confetti.component";
import QueryString from "../../../../common/util/QueryString";
import AstrologerProfile from "./AstrologerProfile";
import FilterList from "./FilterView.component";
import { parse } from "date-fns";
import AstrologerCardComponent from "./AstrologerCardComponent.component";
import ChatPanel from "../../../account/components/ChatPanel.component";
import { ChatWindowStateProvider, ChatWindowStateSetterProvider } from "./chat/ChatWindowContext";
import ChatNotificationTrigger from "./chat/ChatNotificationTrigger";
import AstrologersList from "./view/AstrologersList";
import Conversations from "./chat/Conversations";

const useStyles = makeStyles({
  filterButton: {
    color: "#323232",
    padding: "2px 10px",
    borderRadius: "16px",
    backgroundColor: "#efefef",
    display: "inline-block",
    margin: "8px 4px 0 0",
    fontSize: "0.8rem",
    fontWeight: 400,
  },
  sectionHeading: {
    left: "0%",
    color: "#323232",
    margin: "10px 0 10px 0",
    display: "inline-block",
    position: "relative",
    textAlign: "center",
    fontWeight: "700",
    fontSize: "0.8rem",
    borderRadius: "12px",
    /* background: "#efefef", */
    padding: "4px 16px ",
    padding: "4px 0",
    width: "100%",
    "&:only-child": {
      display: "none",
    },
  },
  desktopSearchBar: {
    "& .MuiInputBase-root": {
      display: isMobile ? "inline-flex" : "block",
    },
  },
  searchInput: {
    width: "calc(100% - 16px)",
    height: 40,
  },
  container: {
    overflow: "auto",
    padding: "0px",
    margin: "0px",
    backgroundColor: "#f8ddb4",
    whiteSpace: "nowrap",
    maxHeight: "100vh",
    "& div": {
      // whiteSpace: "pre-wrap",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollSnapType: "x mandatory",
  },
  fullList: {
    width: "auto",
  },
  header: {
    padding: isMobile ? "10px 10px 50px 10px" : 10,
    backgroundColor: "var(--primary)",
    height: 72,
  },
  rounded: {
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  right: {
    float: "right",
    color: "#ffffff",
    backgroundColor: "transparent",
  },
  wallet: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  headerWalletWrap: {
    justifyContent: "flex-end",
    display: "flex",
  },
  headerWalletSection: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    fontSize: "14px",
    color: "#ffffff",
    backgroundColor: "transparent",
    borderRadius: "4px",
    padding: "0 7px",
  },
  titleFilterIcons: {
    width: "auto",
    height: "30px",
    fontSize: "0.8rem",
    color: "white",
    backgroundColor: "transparent",
    padding: "0 5px",
    marginLeft: "8px",
  },
  color: {
    color: theme.palette.primary.main,
    marginTop: 15,
  },
  colorMobile: {
    color: theme.palette.primary.main,
    marginTop: "50px",
    position: "absolute",
    left: 0,
    paddingLeft: 10,
  },
  marTop5: {
    marginTop: 0,
    fontSize: "25px",
  },
  marTop5Mobile: {
    marginTop: "10px",
    fontSize: "20px",
    justifyContent: "start",
    display: "flex",
  },
  familyDiv: {
    /* marginRight: "10px",
    marginLeft: "10px", */
    marginBottom: "22px",
  },
  youmightalsolikeText: {
    textAlign: "center",
    color: "grey",
    padding: "0px 10px",
    fontWeight: "bold",
  },
  clearFilter: {
    color: "#ffeadf",
    padding: "5px 10px",
    fontSize: "16px",
    borderRadius: "3px",
    backgroundColor: theme.palette.primary.main,
    margin: "20px auto 0 auto",
    display: "inline-block",
  },
  desktopScroller: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "hidden !important",
  },
  desktopContainer: {
    maxWidth: 460,
    margin: "0 auto",
    backgroundColor: "#f8ddb4",
  },
  desktopTitleFilterIcons: {
    padding: "0px 20px",
    marginLeft: "20px",
    width: "auto",
    height: "30px",
    fontSize: "12px",
    backgroundColor: "transparent",
    color: "#ffffff",
  },
  desktopLogo: {
    height: "36px",
    marginTop: "8px",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: "14px",
  },
  mobileLogo: {
    height: "25px",
    marginLeft: isMobile ? -12 : 8,
    marginRight: isMobile ? 12 : 0,
  },
  desktopTopFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  desktopCustomerCare: {
    color: "black",
    marginTop: "12px",
    fontSize: 14,
  },
  footer: {
    height: "auto",
    display: "flex",
    lineHeight: "27px",
    justifyContent: "space-around",
    padding: "16px",
    backgroundColor: "#ffffff",
    whiteSpace: 'normal',
  },
  footerHeading: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 8,
  },
  footerDivider: {
    marginTop: 70,
  },
  copyright: { fontSize: 14, marginTop: "20% !important", marginBottom: "20px !important" },
  mobileFindYour: {
    color: "black",
    fontWeight: "normal",
  },
  activeCall: {
    "& small": {
      fontSize: "0.9rem",
    },
    "& small.failed": {
      color: "red",
      textTransform: "uppercase",
    },
    "& small.initiated": {
      color: "#006699",
      textTransform: "uppercase",
    },
    "& small.completed": {
      color: "#00c180",
      textTransform: "uppercase",
    },
    left: "50%",
    transform: "translate3d(-50%,0,16px)",
    width: "calc(100% - 18px)",
    bottom: "0",
    padding: "8px",
    zIndex: "107",
    position: "fixed",
    maxWidth: "460px",
    background: "white",
    boxShadow: "0 0 6px rgb(0 0 0 / 50%)",
    borderRadius: "8px 8px 0 0",
  },
  rateusModal: {
    borderRadius: "20px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fbead0",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    padding: "30px 20px",
    maxWidth: "450px",
    width: "90%",
    margin: "auto",
    textAlign: "center",
    outline: "none",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      borderRadius: 12,
    },
  },
  reWrap: {
    padding: "20px",
  },
  paperCenter: {
    textAlign: "center",
  },
  reMessage: {
    fontSize: "1.2rem",
  },
  reClaimButton: {
    backgroundColor: "#f56307",
    color: "white",
    fontWeight: "bold",
    width: "160px",
    boxShadow: "none",
    borderRadius: "40px",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f56307 !important",
    },
  },
});

const tabInfo = [
  {
    type: 'chat'
  },
  {
    type: 'call'
  },
]

const AppHome = (props) => {
  const [testimonialId, setTestimonialId] = useState(false);
  const [regnOffer, setShowRegnOffer] = useState({});
  const [pendingCashbackOffer, setShowCashbackOffer] = useState({});

  const classes = useStyles();
  const [duplicatesInList, setduplicatesInList] = useState(false);
  const [category, setCategory] = useState("all");
  const [drawerOpen, setDrawerOpenState] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isOthersLoading, setOthersLoading] = useState(true);
  const [astProfileOpen, setAstProfileOpen] = useState(false);
  const [astProfileDataParams, setAstProfileData] = useState(null);
  const [langExpertiseMatrix, setLangExpertise] = useState(null);
  const [refAndEarnModelOpen, setRefAndEarnModelOpen] = useState(null);
  const [referEarnPendingCallout, setReferEarnPendingCallout] = useState(false);
  const [chatWindowData, setChatWindowData] = useState({})
  const history = useHistory()

  useEffect(() => {
    // if (window.AndroidBridge) {
    //   const getAppProfileData = window.AndroidBridge.call("getAppProfileData")
    //   alert(JSON.stringify({ function: 'AndroidBridge.getAppProfileData', data: getAppProfileData }))
    // }

    localStorage.setItem("strict_app_home", 1)
  }, [])

  useEffect(() => {
    console.log("chatWindowData", chatWindowData);
  }, [chatWindowData])

  const params = QueryString();

  const showBVarient = false; /* Number(String(localStorage["phone"]).substring(-1)) % 2 === 1; */ //show b varient if phone number ends in odd no
  //const showBVarient = ["8907523075", "9871591537", "9567891324"].indexOf(localStorage["phone"]) >= 0;
  //if (params.sl === "1") {
  sessionStorage["skipLogin"] = "true";
  //}

  // preload with cached data
  let cachedAstrologersData = null;
  try {
    cachedAstrologersData = JSON.parse(sessionStorage["astrologersData"]);
  } catch (err) {
    cachedAstrologersData = null;
  }
  const [showPromoList, setShowPromoList] = useState(true);
  const [astrologersData, setAstrologersData] = useState(cachedAstrologersData || []);
  const [promotionalList, setPromotionalList] = useState([]);
  const [recommendedData, setRecommendedData] = useState([]);
  const [filterData, setFilterData] = useState(JSON.parse(sessionStorage.getItem("filterData") || "{}"));
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [offerClaim, setOfferClaim] = useState(true);
  const [isAPaidUser, setIsPaidUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [activeConsultation, setActiveConsultation] = useState({});
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "Error occurred, Please try again",
  });
  const [toastBar, setToastBar] = useState({});
  const [showOnlyOnline, setshowOnlyOnline] = useState(sessionStorage.getItem("showOnlyOnline") === "true");
  const [offerDetails, setofferDetails] = useState({});
  const [offerPromoDetails, setofferPromoDetails] = useState({});
  const [advancedFilter, setAdvancedFilter] = useState({
    categories: {},
    languages: {},
    expertise: {},
  });
  const [selectedAstrologer, setSelectedAstrologer] = useState({});
  const [isCallDlgStateOpen, setCallDlgStateOpen] = useState(false);
  const [callDlgType, setCallDlgType] = useState(null);
  const [callDlgExtra, setCallDlgExtra] = useState({});
  const [isRedeemDialogOpen, setRedeemDlgStateOpen] = useState(window.location.pathname === "/redeem");
  const [activecashBack, setActivecashBack] = useState(0);
  const [pendingReview, setPendingReview] = useState({});
  const [onlineAstrologers, setOnlineAstrologers] = useState({});
  const [showConfetti, setShowConfetti] = useState(false);
  const [loadingPromoList, setLoadingPromoList] = useState(false);
  const [currentOffer, setShowCurrentOffer] = useState({});
  const [upcomingBookings, setUpcomingBookings] = useState([]);

  const showAstrologerProfile = (details) => {
    const profile = {
      params: {
        url: details.profileUrl,
      },
    };
    setAstProfileData(profile);
    document.body.style.overflow = "hidden";
    window.location.hash = "closeprofilepopup";
    setAstProfileOpen(true);
  };
  /** Filter data code */
  const prepareValuesObject = (values) => {
    var out = [];
    values.map((v) => {
      return out.push({ text: v, selected: true, disabled: false });
    });
    return out;
  };

  const noOnlineAstrologers = () => {
    return onlineAstrologers.main === 0 && onlineAstrologers.promo === 0 && onlineAstrologers.fav === 0;
  };

  const offlineBookingAvailable = () => {
    for (const astrologer of otherAstrologersList) {
      if (astrologer.status === "offline" || astrologer.status === "away") {
        return true;
      }
    }
    return false;
  };

  const getDefaultFilter = () => {
    if (localStorage["currentFilterData"]) {
      try {
        //console.log("falog", "loading..");
        const providedFilter = JSON.parse(localStorage["currentFilterData"]);
        if (providedFilter[0].version > 0) {
          setCurrentFilter(providedFilter);
          return true;
        }
      } catch (err) {
        console.log(err);
      }
    }
    const langObject = localStorage.user_languages ? prepareValuesObject(localStorage.user_languages.split(",")) : [];
    const filter = [
      {
        title: "Language(s):",
        type: "absolute",
        values: langObject,
        field: "languages:all",
        disabled: localStorage.user_languages ? false : true,
        warningCaption: true,
        action: isUserLoggedIn() ? { fn: "changeLanguage", caption: "Add/remove languages" } : null,
        version: 1,
      },
      {
        title: "Show astrologers whose expertise is:",
        shortTitle: "Astrologer expertise:",
        type: "absolute",
        /**
         * this is the order in which the UI is rendered
         * */
        values: prepareValuesObject([
          "Vedic",
          "Kp",
          "Gemology",
          "Prashna",
          "Nadi",
          "Lal Kitab",
          "Western",
          "Vastu",
          "Numerology",
          "Fengshui",
          "Tarot",
        ]),
        /**
         * IMPORTANT:
         * value map format: [#id, caption]. Never ever cange the #id in the list
         * however you can reorder the items without changing the #ids
         * */
        valueMap: {
          Vedic: [0, "Vedic astrology"],
          Kp: [1, "KP method"],
          Gemology: [2, "Gemology/Rasi kal"],
          Prashna: [3, "Prashna/ Kavadi prashnam/ Sozi prashnam/ Ashtamangala prashnam"],
          Nadi: [4, "Nadi jothidam/Nadi astrology"],
          "Lal Kitab": [5, "Lal kitab"],
          Western: [6, "Western astrology"],
          Vastu: [7, "Vastu/Manaiyadi shastra"],
          Numerology: [8, "Numerology/En ganitham"],
          Fengshui: [9, "Feng shui"],
          Tarot: [10, "Tarot"],
        },

        field: "expertise",
        showInfo: true,
        warningCaption: true,
      },
      /*
      {
        title: "Show Astrologers whose mother tongue is:",
        type: "absolute",
        values: langObjectNative,
        field: "languages:native",
        disabled: localStorage.user_languages ? false : true,
        warningCaption: "There is no native astrologers available for __S__",
      },
      {
        title: "Also show astrologers who understands and speaks:",
        type: "absolute",
        values: langObjectOther,
        field: "languages:second",
        disabled: localStorage.user_languages ? false : true,
        warningCaption: "There is no astrologers available for __S__",
      },
      {
        title: "Astrologer experience",
        type: "range",
        field: "experience",
        tail: "years",
        values: [
          {from: 1, to: 5},
          {from: 5, to: 10},
          {from: 10, to: 20},
          {from: 20, to: 30},
          {from: 30, to: 500, suffix: "or above"}, //above 30
        ],
        disabled: true,
      },
      {
        title: "Astrologer rating",
        type: "range",
        field: "reviewsData.rating",
        values: [
          {from: 4, to: 5},
          {from: 3, to: 4},
          {from: 2, to: 3},
          {from: 1, to: 2},
          {from: 0, to: 0, caption: "no rating"},
        ],
        disabled: true,
      },
      {
        title: "Astrologer fees",
        type: "range",
        field: "price",
        values: [
          {from: 10, to: 15},
          {from: 15, to: 20},
          {from: 20, to: 25},
          {from: 25, to: 30},
          {from: 30, to: 40},
          {from: 40, to: 50},
          {from: 50, to: 70},
          {from: 70, to: 90},
          {from: 90, to: 150},
        ],
        disabled: true,
      },*/
    ];
    setTimeout((e) => setCurrentFilter(filter), 100);
    localStorage["currentFilterData"] = JSON.stringify(filter);
  };
  const [currentFilter, setCurrentFilter] = useState(null);
  const [showFilterView, setShowFilterView] = useState(false);
  const [showInstallBanner, setShowInstallBanner] = useState(false);

  const handleDownload = () => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get("utm_source") || "Findastro_web";
    const medium = params.get("utm_medium") || "install_optional_banner";
    const campaign = params.get("utm_campaign") || "";
    const term = params.get("utm_term") || "";
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3D" +
      source +
      "%26utm_medium%3D" +
      medium +
      "%26utm_campaign%3D" +
      campaign +
      "%26utm_term%3D" +
      term;

    trackActions("fa_appinstall_init", "install_optional_banner", {});
  };

  const processFilter = (filter, data) => {
    setLoading(true);
    const asCopy = [...astrologersData];
    const paCopy = [...promotionalList];
    const tobeChecked = [];
    filter.map((f) => {
      const type = f.type;
      const field = f.field;
      f.values.map((v) => {
        if (v.selected) {
          tobeChecked.push({ type: type, field: field, value: v });
        }
      });
    });

    if (tobeChecked.length > 0) {
      const filterAstrologers = (astrologer) => {
        astrologer.visible = false;
        const visibleFlags = {};
        tobeChecked.map((check) => {
          if (check.field === "languages:native") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.languages.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) === 0;
          } else if (check.field === "languages:second") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.languages.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) > 0;
          } else if (check.field === "languages:all") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.languages.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) >= 0;
          } else if (check.field === "expertise") {
            visibleFlags[check.field] =
              visibleFlags[check.field] ||
              astrologer.expertise.join(",").toUpperCase().indexOf(check.value.text.toUpperCase()) >= 0;
          }
          astrologer.visible = Object.values(visibleFlags).reduce((a, b) => a && b);
          return astrologer;
        });
      };

      //console.log("falog", tobeChecked);
      if (!data) {
        asCopy.map((astrologer) => {
          return filterAstrologers(astrologer);
        });
        setAstrologersData(asCopy);

        setShowPromoList(false);
        paCopy.map((astrologer) => {
          filterAstrologers(astrologer);
          if (astrologer.visible) setShowPromoList(true);
          return astrologer;
        });
        setPromotionalList(paCopy);
      } else {
        data.map((astrologer) => {
          return filterAstrologers(astrologer);
        });
      }

      //console.log("falog", tobeChecked, asCopy, paCopy);
    }
    setLoading(false);
    return data;
  };

  const showFilterFeature = () => {
    //return showFilterView && currentFilter && langExpertiseMatrix && currentTab === 0;
    return false;
  };

  const processFilterMap = (providedFilter) => {
    if (providedFilter && providedFilter.length > 0) {
      processFilter(providedFilter);
    }
  };

  const closeAllModalPopups = () => {
    //window.history.pushState("", document.title, window.location.pathname + window.location.search);
    setAstProfileData(null);
    setAstProfileOpen(false);
    setDailyPredictionOpen(false);
    document.body.style.overflow = "auto";
    console.log('currentTab:', currentTab)
  };

  const [dailyPredictionOpen, setDailyPredictionOpen] = useState(window.location.hash === "#dpexpanded");
  // Hook

  const handleHashChange = () => {
    if (window.location.hash === "") {
      closeAllModalPopups();
    }
  };

  window.addEventListener("hashchange", handleHashChange, true);

  const checkDuplicatesInList = () => {
    const counter = [];
    astrologersData.map((astrologer, id) => {
      counter[astrologer.id] = (counter[astrologer.id] || 0) + 1;
      if (counter[astrologer.id] > 1) {
        //console.log("arun", "Duplicate:", astrologer);
        return true;
      }
    });
    return false;
  };

  const handleOfferIcon = (e, offer) => {
    e.stopPropagation();
    setShowCurrentOffer({ nextCallStatus: true, offer: offer });
  };

  const handleFavourite = async (id, status) => {
    /** check for the favourite change in both normal
     * and promo list and set status accordingly */
    let data = [...astrologersData];
    let found = false;
    for (const i in data) {
      const astrologer = data[i];
      if (astrologer.id === id) {
        astrologer.is_favourite = status;
        found = true;
        break;
      }
    }
    if (found) {
      setAstrologersData(data);
    } else {
      data = [...promotionalList];
      let found = false;
      for (const i in data) {
        const astrologer = data[i];
        if (astrologer.id === id) {
          astrologer.is_favourite = status;
          found = true;
          break;
        }
      }
      if (found) {
        setPromotionalList(data);
      }
    }
  };

  const imageLoaded = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("loaded");
  };

  const postReview = () => {
    const pr = { ...pendingReview };
    //console.log("arun", pr);
    if (pr.rating > 0) {
      onReviewSubmit(pr);
    } else {
      pr.ratingError = true;
      setPendingReview(pr);
      /*reset error*/
      setTimeout(() => {
        const lpr = { ...pendingReview };
        lpr.ratingError = false;
        setPendingReview(lpr);
      }, 1800);
    }
  };
  /* Forced update for findastro 1.0.x users */
  const forceUpdate = () => {
    return (
      //2.0.1-r
      BrowserUtils.isFindAstroWebview === true && typeof window.AndroidBridge === "undefined"
    );
  };
  /* 
  const isVersionExpired = (version) => {
    const currentVersion = CURRENT_APP_VERSION;
    const currentVerTag = parseInt(currentVersion.split(".")[0] + currentVersion.split(".")[1]);
    const expectedVersion = parseInt(version.split(".")[0] + version.split(".")[1]);
    return expectedVersion > currentVerTag;
  }; */

  const tabPanelPuja = useRef();
  const tabPanelChat = useRef();

  const closeReview = () => {
    const pr = { ...pendingReview };
    pr.ratingError = false;
    pr.is_submitted = true;
    setPendingReview(pr);
  };

  const onpageEventHandler = (action) => {
    switch (action) {
      case "couponRedeem":
        setDrawerOpenState(false);
        setRedeemDlgStateOpen(true);
        break;
      default:
        return false;
    }
  };

  const CouponHandleClose = () => {
    setRedeemDlgStateOpen(false);
    if (window.location.pathname === "/redeem") {
      window.location.href = "/";
    }
  };

  const callFirstOnlineAstrologer = () => {
    let targetAst = [];
    const refinedData = sortByRank([...astrologersData]);
    //console.log(refinedData);
    refinedData.map((astrologer) => {
      if (targetAst.length < 5 && astrologer.status === "online") {
        targetAst.push(astrologer);
      }
    });
    const seed = parseInt((Math.random() * 1000) % targetAst.length);
    if (targetAst.length > 0) {
      openCallDialog(targetAst[seed], "main");
      trackActions("fa_daily_pred_consult", "daily_prediction", targetAst[seed]);
      trackActions("fa_consult_intent", "daily_prediction", targetAst[seed]);
    } else showError("No astrologers online. Please try later");
  };

  const handleDlgClose = () => {
    setCallDlgStateOpen(false);
  };

  const openCallDialog = (astrologer, astrologerType, extra) => {
    //console.log("arun", astrologer, astrologerType);
    setSelectedAstrologer(astrologer);
    setActivecashBack(
      astrologerType === "promotional" ? offerPromoDetails.promotionalDiscount : offerDetails.nextCallDiscount
    );

    setCallDlgType(extra && extra.booking ? "booking" : "call");
    setCallDlgExtra(extra);
    setCallDlgStateOpen(true);
  };

  const onReviewSubmit = (pr) => {
    setLoading(true);
    axios()
      .post("/review/consultation", {
        id: pr.id,
        answers: {
          1: pr.rating,
        },
        comment: pr.reviewText,
      })
      .then((res) => {
        setLoading(false);
        pr.is_submitted = true;
        pr.ratingError = false;
        setPendingReview(pr);
      })
      .catch((err) => {
        setLoading(false);
      });
    /** event tracking */
    trackActions("fa_consult_ast_rating", "consult", {
      profileUrl: pr.astrologer.profileUrl || "NA",
      value: pr.rating,
      call_rate: 0,
    });
  };

  const trackActions = (type, label, data) => {
    moengage(type);
    let ast_rating = data.reviewsData && data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: "home",
      p_label: label,
      p_content: data.profileUrl || "NA",
      p_value: data.value || 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience ? data.experience.split(" ")[0] : "NA",
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.call_rate ? data.call_rate.split("/")[0] : "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  const toggleHeader = (scrollTop) => {
    const header = document.getElementsByClassName("autoHideHeader")[0];
    if (scrollTop > 10) {
      header.classList.add("folded");
    } else {
      header.classList.remove("folded");
    }
  };

  const [scrollPercentage, setScrollPercentage] = useState(0);
  const ScrollViewHandler = (e) => {
    const value = Math.round((e.target.scrollTop / e.target.scrollHeight) * 1500) / 100;
    // toggleHeader(e.target.scrollTop); //toggle static header for big cards.
    //console.log(value);
    /* if (scrollPercentage !== value) {
      setScrollPercentage(value);
    }
    const bannerstatus = e.target.scrollTop > 50;
    if (showInstallBanner !== bannerstatus) {
      setShowInstallBanner(bannerstatus);
    } */
  };
  /*
  useEffect(() => {
    console.log(scrollPercentage);
  }, [scrollPercentage]);
*/
  const showFilterBox = () => {
    setFilterOpen(!filterOpen);
  };

  // const filterByCategory = (e) => {
  //   e.preventDefault();
  //   const el = e.currentTarget;
  //   el.classList.toggle("active");
  //   const category = el.getAttribute("category");
  //   const data = {...advancedFilter};
  //   const value = el.classList.contains("active");
  //   advancedFilter.categories[category] = value;
  //   setTimeout(() => {
  //     setAdvancedFilter(data);
  //     showStatusToast(" Filter " + (value ? "applied" : "removed") + ": " + category, "status");
  //   }, 300);
  // };

  const AstrologerListMaker = (data) => {
    const astrologerCopy = data;
    let refinedData = [];

    switch (filterData.sortBy) {
      case "plth":
      case "phtl":
        refinedData = sortByPrice(astrologerCopy, filterData.sortBy);
        break;
      case "exp":
        refinedData = sortByExperience(astrologerCopy);
        break;
      case "reviews":
        refinedData = sortByRating(astrologerCopy);
        break;
      case "rank":
      default:
        refinedData = sortByRank(astrologerCopy);
        break;
    }
    return refinedData;
  };

  const sortByPrice = (data, type) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let price = parseInt(ast.price.split("/")[0]);
      sortedList[price] = sortedList[price] || [];
      sortedList[price].push(ast);
    }
    let finalList = [];
    for (var price in sortedList) {
      for (var ast in sortedList[price]) {
        finalList.push(sortedList[price][ast]);
      }
    }
    if (type === "phtl") {
      finalList.reverse();
    }
    return finalList;
  };
  const sortByExperience = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = parseInt(ast.experience.match(/\d{1,3}/)[0]) || 0;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }

    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }

    finalList.reverse();
    return finalList;
  };

  /* const sortByStatus = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = ast.status === "online" ? 0 : 1;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }
 
    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }
    return finalList;
  }; */

  const sortByRank = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = ast.sequence;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }
    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }

    finalList.reverse();
    return finalList;
  };

  const sortByRating = (data) => {
    let sortedList = [];
    for (let id in data) {
      let ast = data[id];
      let skey = parseFloat(ast.reviewsData.rating) * 10 * Math.pow(ast.reviewsData.count, 2) || 0;
      sortedList[skey] = sortedList[skey] || [];
      sortedList[skey].push(ast);
    }
    let finalList = [];
    for (var skey in sortedList) {
      for (var ast in sortedList[skey]) {
        finalList.push(sortedList[skey][ast]);
      }
    }

    finalList.reverse();
    return finalList;
  };
  useEffect(() => {
    sessionStorage.setItem("showOnlyOnline", showOnlyOnline);
  }, [showOnlyOnline]);

  useEffect(() => {
    sessionStorage.setItem("filterData", JSON.stringify(filterData));
    applySort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    sessionStorage.setItem("advancedFilter", JSON.stringify(advancedFilter));
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedFilter]);

  const [postUpdate, setPostUpdate] = useState(null);
  useEffect(() => {
    updateAstrologerStatus(postUpdate);
  }, [postUpdate]);

  const updateAstrologerStatus = (update) => {
    const asCopy = [...astrologersData];
    //console.log("arun--+", asCopy.length);
    if (update) {
      let changed = false;
      asCopy.map((data, i) => {
        if (data.id.toString() === update.message.astrologer_id.toString()) {
          data.status = update.message.status_to;
          data.away_until = update.message.away_until;
          //console.log("arun", data, update);
          const message = data.name + " is " + update.message.status_to + " now.";
          showStatusToast(message, update.message.status_to);
          changed = true;
        }
        return true;
      });
      if (changed === true) {
        setAstrologersData(asCopy);
      }

      //reset
      changed = false;

      const pasCopy = [...promotionalList];
      pasCopy.map((data, i) => {
        if (data.id.toString() === update.message.astrologer_id.toString()) {
          data.status = update.message.status_to;
          data.away_until = update.message.away_until;
          //console.log("arun", data, update);
          const message = data.name + " is " + update.message.status_to + " now.";
          showStatusToast(message, update.message.status_to);
          changed = true;
        }
        return true;
      });
      if (changed === true) {
        setPromotionalList(pasCopy);
      }
    }
  };
  const applyFilter = () => {
    const data = [...astrologersData];
    for (let key in data) {
      let astrologer = data[key];
      for (let category in advancedFilter.categories) {
        if (advancedFilter.categories[category] === true) {
          if (astrologer.category.indexOf(category) >= 0) {
            astrologer.masked = "";
          } else {
            astrologer.masked = "masked";
          }
        }
      }
    }
    setAstrologersData(data);
  };
  const sortTypes = {
    plth: "Fees low to high",
    phtl: "Fees high to low",
    rank: "Findastro recommendation",
    exp: "Experience",
    reviews: "Popularity",
  };
  const setSortBy = (sortBy) => {
    const filter = { ...filterData };
    filter.sortBy = sortBy;
    setTimeout(() => {
      setFilterData(filter);
      trackActions("fa_sort", sortBy, {});
      showStatusToast("Sorted astrologers list by: " + sortTypes[sortBy], "status");
    }, 100);
  };

  const applySort = () => {
    const data = applySortingOnDataset([...astrologersData]);
    setAstrologersData(data);
  };

  const applySortingOnDataset = (data) => {
    data = AstrologerListMaker(data);
    return data;
  };

  const toggleShowOnlyOnline = () => {
    window.stopAllAudio();
    const input = document.querySelector(".MuiAvatar-rounded .switch-slider input");
    input.checked = !input.checked;
    setTimeout(() => {
      showStatusToast(input.checked ? "Showing only online astrologers" : "Showing all astrologers", "status");
      setshowOnlyOnline(input.checked);
    }, 300);

    /* if (!window.statusAutomated) {
      window.statusAutomated = true;
      setInterval(() => {
        const data = [...astrologersData];
        const d = Date.now() % 3;
        data[d].status = ["offline", "online", "away"][Date.now() % 3];
        setAstrologersData(data);
        showStatusToast("Astrolgoer " + data[d].name + " is " + data[d].status + " now.", data[d].status);
      }, 20000);
    } */
  };

  const showSortBox = () => {
    window.stopAllAudio();
    setSortOpen(!sortOpen);
  };

  const showError = (message) => {
    setSnackBar({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBar({
      open: false,
    });
  };

  const onSearch = () => {
    moengage("SEARCH_CLICKED");
    let url = "/search-results";
    if (searchText !== "") {
      url += "?q=" + searchText;
      if (category !== "all") {
        url = url + "&category=" + category;
      }
    }
    window.location.href = url;
  };

  const onSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const onClear = () => {
    window.location.href = "/";
  };

  const onJoinBanner = (e) => {
    trackActions("fa_join_banner", "", {});
    onWallet();
  };

  const onWallet = () => {
    // window.location.href = "/my-wallet";
    history.push('/user-wallet')
  }

  const showLoading = () => {
    setLoading(true);
    setFilterOpen(false);
  };

  const fetchMoreData = () => {
    if (currentPage > maxPages) {
      setHasMore(false);
      return;
    } else {
      setIsFetching(true);
      requestAndFetchData(currentPage);
      if (currentPage >= maxPages) {
        setHasMore(false);
      }
    }
  };

  const requestAndFetchData = (page = 1) => {
    let tempParams = "";
    if (window.location.search) {
      tempParams = window.location.search + "&";
    }
    tempParams += (tempParams === "" ? "?" : "&") + "phone=" + FA_localStorage("phone");
    let url = "ma"; //isMobile ? "m" : "d"
    const loader = FA_localStorage("phone") && FA_localStorage("phone") !== "" ? axios : axios_noheader;
    loader()
      .post("/astrologers/all/" + url + tempParams + "&page=" + page)
      .then((res) => {
        if (res.data.success) {
          res.data.data = [...astrologersData, ...res.data.data];
          res.data.data = addSequencing(res.data.data);
          const data = applySortingOnDataset(res.data.data);
          setAstrologersData(data);
          setIsFetching(false);
          setCurrentPage(currentPage + 1);
        } else {
          showError(res.data.message);
          setIsFetching(false);
        }
      })
      .catch((err) => {
        sentryError("ASTROLOGER_LIST_PAGENATION", err);
        showError("Something went wrong. please try again");
        setIsFetching(false);
      });
  };

  const handleNotifyMe = (id, type) => {
    setLoading(true);
    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login";
    } else {
      axios()
        .post("/notifications/" + (type === "add" ? "notify-me" : "revoke-notify-me"), {
          ast_id: id,
        })
        .then((res) => {
          if (res.data.success) {
            if (type === "add") {
              showStatusToast("You will be notified when astrologer is online.");
              let temp = { ...notifications };
              if (temp.initiated) {
                temp.initiated.push(id);
                setNotifications(temp);
              }
              setLoading(false);
            } else {
              showStatusToast("Cancelled astrologer online notification.");
              let temp = { ...notifications };
              if (temp.initiated) {
                const index = temp.initiated.indexOf(id);
                if (index > -1) {
                  temp.initiated.splice(index, 1);
                  setNotifications(temp);
                }
              }
              setLoading(false);
            }
          } else {
            showError(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          showError("Something went wrong. please try again");
        });
    }
  };

  const onReferEarnClaim = () => {
    const paramsData = QueryString();
    window.location.href = "/refer-earn/?code=" + paramsData.code;
  };

  useEffect(() => {
    if (currentPage > 1 && currentPage <= maxPages) {
      setTimeout(fetchMoreData, 500);
    } else {
      setHasMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, maxPages]);

  const [otherAstrologersList, setOtherAstrologersList] = useState([]);
  const [favAstrologersList, setFavAstrologersList] = useState([]);
  const [favPromoAstrologersList, setFavPromoAstrologersList] = useState([]);

  useEffect(() => {
    /*display pending offer message*/
    if (FA_localStorage("pendingCashbackOffer") && FA_localStorage("pendingCashbackOffer") !== "") {
      setTimeout((e) => {
        let pcbo = JSON.parse(FA_localStorage("pendingCashbackOffer") || null);
        if (pcbo && pcbo.cashbackOffer) {
          pcbo.cashbackOffer.blastConfetti = false;
          setShowCashbackOffer(pcbo.cashbackOffer);

          setTimeout((e) => {
            pcbo = JSON.parse(FA_localStorage("pendingCashbackOffer"));
            pcbo.cashbackOffer.blastConfetti = true;
            setShowCashbackOffer(pcbo.cashbackOffer);
          }, 200);
        }
      }, 5000);
    }
    /* prepare and separate fav and other astrologers */
    (function prepareFavAndOtherAstrologersList() {
      let favouriteList = [];
      let normalListOnline = [];
      let normalListOffline = [];
      astrologersData.map((astrologer) => {
        if (astrologer.status === "online" || astrologer.status === "busy") normalListOnline.push(astrologer);
        else normalListOffline.push(astrologer);
        return true;
      });
      setOtherAstrologersList(normalListOnline.concat(normalListOffline));
      setFavAstrologersList(favouriteList);
    })();

    bindRealtimeEvents();
    recalculateOnlineCount();
    /** show filterview once the astrologers data is loaded completely */
    setShowFilterView(maxPages > 0 && currentPage >= maxPages);
    //setTimeout((e) => console.log("falog", astrologersData.length, currentPage, maxPages), 1500);
    //console.log("arun--", astrologersData.length);
  }, [astrologersData]);

  const updateUserDetailsLocal = () => {
    if (localStorage.getItem("phone") && localStorage.getItem("phone") !== "undefined") {
      axios()
        .post("/profile/user")
        .then((res) => {
          if (res && res.data.data.success) {
            localStorage.setItem("dob", res.data.data.user.dateOfBirth);
            localStorage.setItem("tob", res.data.data.user.timeOfBirth);
            localStorage.setItem("pob", res.data.data.user.placeOfBirth);
            localStorage.setItem("gender", res.data.data.user.gender);
            localStorage.setItem("user_languages", res.data.data.user.languages);
            getDefaultFilter();
          }
        })
        .catch((err) => {
          sentryError("MY_ACCOUNT", err);
        });
    } else {
      /*axios()
        .get("/list/languages")
        .then((res) => {
          if (res.data.success === true) {
            const ulang = [];
            Object.values(res.data.languages).map((lang) => {
              ulang.push(lang.name);
            });
            localStorage.setItem("user_languages", ulang.join(","));
            getDefaultFilter();
          }
        })
        .catch((err) => {
          sentryError("languageListFetch:Error", err);
        });*/
    }
  };

  const checkPendingReferEarn = (refdata) => {
    try {
      if (refdata) {
        const date = parse(refdata.created_at, "yyyy-MM-dd HH:mm:ss", new Date());
        if (localStorage.lastRefEarnId !== refdata.id.toString()) {
          const _72HRS = 72 * 60 * 60 * 1000;
          if (Date.now() - date.getTime() < _72HRS) {
            setReferEarnPendingCallout(refdata);
          }
        }
      }
    } catch (err) {
      sentryError("checkPendingReferEarn:Error", err);
    }
  };

  const [listUpdated, setListUpdated] = useState(Date.now());

  useEffect(() => {
    (function prepareFavPromoAstrologersList() {
      let favouriteList = [];
      promotionalList.map((astrologer) => {
        if (astrologer.is_favourite === true) {
          favouriteList.push(astrologer);
        }
        return true;
      });
      setFavPromoAstrologersList(favouriteList);
    })();
    bindRealtimeEvents();
    recalculateOnlineCount();
  }, [promotionalList]);

  useEffect(() => {
    // force login
    /* if (!FA_localStorage("phone") && sessionStorage["skipLogin"] !== "true") {
      window.location.href = "/login";
    } */
    //force web use
    setLoading(true);
    if (window.location.search.indexOf("test=1") >= 0) {
      sessionStorage.setItem("forceWebUse", "force");
    }

    moengage("LIST_PAGE_VIEWED");
    if (!FA_localStorage("phone") || !FA_localStorage("name") || !FA_localStorage("email")) {
      FA_localStorage("name", "remove");
      FA_localStorage("email", "remove");
      FA_localStorage("phone", "remove");
      FA_localStorage("image", "remove");
      FA_localStorage("utkn", "remove");
      FA_localStorage("btkn", "remove");
      FA_localStorage("walletBalance", "remove");
      FA_localStorage("rate_us", "remove");
      FA_localStorage("user_languages", "remove");
    }
    if (FA_localStorage("btkn") && FA_localStorage("utkn") && FA_localStorage("phone")) {
      setOfferClaim(false);
      if (window.Moengage) {
        window.Moengage.add_unique_user_id(FA_localStorage("phone"));
      }
    }
    let filterParams = "";
    /*
    const paramsData = QueryString();
    if (window.location.search) {
      filterParams = window.location.search;
      if (paramsData.category || paramsData.expertise || paramsData.language)
        moengage("FILTERS_SELECTED", {
          category: paramsData.category ? paramsData.category : "",
          expertise: paramsData.expertise ? paramsData.expertise : "",
          language: paramsData.language ? paramsData.language : "",
        });
      if (paramsData.sort)
        moengage("SORT_APPLIED", {
          sort_type: paramsData.sort,
        });
      if (paramsData.availability) moengage("ONLINE_FILTER_CLICKED");
    }
    setFilterData(paramsData);
    */
    // try to get astrologers data from the sessioncache

    //

    let url = "ma"; //isMobile ? "m" : "d"

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      axios_noheader()
        .post("/astrologers/all/" + url + filterParams)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length === 0 && window.location.search !== "") {
              axios_noheader()
                .post("/astrologers/all/ma")
                .then((res) => {
                  if (res.data.success) {
                    setRecommendedData(res.data.data);
                    setOthersLoading(false);
                  } else {
                    showError(res.data.message);
                  }
                })
                .catch((err) => {
                  sentryError("ASTROLOGER_LIST_RECOMMENDED", err);
                  showError("Something went wrong. please try again");
                });
            } else {
              /*axios_noheader()
                .post("/profile-unauth/updates")
                .then((res) => {
                  if (res.data.success) {
                    const details = {...offerDetails};
                    details.nextCallDiscount = res.data.data.discount_per;
                    setLangExpertise(res.data.data.language_expertise);
                    FA_localStorage("nextCallPrice", details.nextCallDiscount);
                    setofferDetails(details);
                    setOfferClaim(res.data.isNUser);
                    setIsPaidUser(res.data.data.recharges > 0);
                  }
                })
                .catch((err) => {
                  sentryError("ASTROLOGER_LIST_NEW_USER", err);
                });*/

              res.data.data = addSequencing(res.data.data);
              res.data.data = applySortingOnDataset(res.data.data);
              setMaxPages(res.data.paginate.last_page);
              setAstrologersData(res.data.data);
              sessionStorage["astrologersData"] = JSON.stringify(res.data.data);
              setCurrentPage(currentPage + 1);
              setLoading(false);
            }
          } else {
            showError(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          sentryError("ASTROLOGER_LIST_DETAILS", err);
          showError("Something went wrong. please try again");
          setLoading(false);
        });
      //updateUserDetailsLocal();
    } else {
      filterParams += (filterParams === "" ? "?" : "&") + "phone=" + FA_localStorage("phone");
      axios()
        .post("/astrologers/all/" + url + filterParams)
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length === 0 && window.location.search !== "") {
              axios_noheader()
                .post("/astrologers/all/ma")
                .then((res) => {
                  if (res.data.success) {
                    setRecommendedData(res.data.data);
                    setOthersLoading(false);
                  } else {
                    showError(res.data.message);
                  }
                })
                .catch((err) => {
                  sentryError("ASTROLOGER_LIST_RECOMMENDED", err);
                  showError("Something went wrong. please try again");
                });
            } else {
              if (FA_localStorage("btkn") && FA_localStorage("utkn")) {
                runUserUpdates();
              }
              res.data.data = addSequencing(res.data.data);
              res.data.data = applySortingOnDataset(res.data.data);
              setMaxPages(res.data.paginate.last_page);
              setAstrologersData(res.data.data);
              sessionStorage["astrologersData"] = JSON.stringify(res.data.data);
              setCurrentPage(currentPage + 1);
              setLoading(false);
            }
          } else {
            showError(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          sentryError("ASTROLOGER_LIST_DETAILS", err);
          showError("Something went wrong. please try again");
          setLoading(false);
        });
    }

    //fetchPromotionalList();
    fetchNotifiedUsers();
    updateUserDetailsLocal();
    setTestimonialId(Date.now() % TestimonialData.data.length);
    window.addEventListener("focus", onAppScreenFocus);
    window.addEventListener("blur", onAppScreenLostFocus);

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    //document.body.appendChild(script);

    return () => {
      window.removeEventListener("focus", onAppScreenFocus);
      window.removeEventListener("blur", onAppScreenLostFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runUserUpdates = () => {
    axios()
      .post("/profile/updates")
      .then((res) => {
        if (res && res.data.success) {
          const details = { ...offerDetails };
          details.nextCallDiscount = res.data.data.discount_per || 0;
          setofferDetails(details);
          setIsPaidUser(res.data.data.recharges > 0);
          const pr = { ...res.data.data.last_consultation_review };
          pr.rating = pr.rating || 5;
          setPendingReview(pr);
          setOfferClaim(res.data.isNUser);
          FA_localStorage("nextCallPrice", details.nextCallDiscount);
          FA_localStorage("user_languages", res.data.data.languages);
          FA_localStorage("walletBalance", res.data.data.wallet_balance || 0);
          setLangExpertise(res.data.data.language_expertise);
          checkPendingReferEarn(res.data.data.latest_refer_earn);

          checkPendingConsultation({ message: false });
          setUpcomingBookings(res.data.data.active_bookings);

          if (FA_localStorage("registrationInit") === "true") {
            if (res.data.data.wallet_balance > 0) {
              setShowRegnOffer({ status: res.data.data.wallet_balance > 0 });
              setTimeout(() => {
                setShowRegnOffer({
                  status: res.data.data.wallet_balance > 0,
                  blastConfetti: true,
                });
              }, 100);
            } else {
              setTimeout(() => {
                setShowRegnOffer({ nextCallStatus: true });
              }, 2000);
              setTimeout(() => {
                setShowRegnOffer({ nextCallStatus: true, blastNextCallConfetti: true });
              }, 2100);
            }
          }
          //FA_localStorage("activeConsultation", JSON.stringify(res.data.data.active_consultation));
        }
      })
      .catch((err) => {
        sentryError("ASTROLOGER_LIST_NEW_USER", err);
      });
  };

  const onAppScreenFocus = () => {
    //console.log("arun", "on the window");
    bindRealtimeEvents();
  };

  const onAppScreenLostFocus = () => {
    //console.log("arun", "out of the window");
    if (window.statusChangeListener) {
      window.statusChangeListener.close();
      window.statusChangeListener = false;
    }
    //window.stopAllAudio(); //iOs issue
  };

  /*useEffect(() => {
    //console.log("arun", loadingPromoList);
    if (loadingPromoList === false) {
      fetchPromotionalList();
    }
  }, [loadingPromoList]);*/

  const fetchPromotionalList = () => {
    if (loadingPromoList === false && window.location.pathname === "/") {
      setLoadingPromoList(true);
      //console.log("arun", "fetching promo.." + Date.now());
      if (FA_localStorage("phone")) {
        axios()
          .post("/astrologers/promotions?phone=" + FA_localStorage("phone"))
          .then((res) => {
            if (res.data.success) {
              const details = { ...offerPromoDetails };
              details.promotionalDiscount = res.data.discount_per;
              setofferPromoDetails(details);
              res.data.data = addSequencing(res.data.data);
              const onlinePromoList = res.data.data; //promoData.data; //
              setPromotionalList(onlinePromoList.filter((ast) => ast.status === "online" || ast.status === "busy"));
              FA_localStorage("promoPrice", res.data.discount_per);
              setLoading(false);
            }
          })
          .catch((err) => {
            sentryError("PROMOTIONAL_LIST_DETAILS", err);
            if (promotionalList.length === 0) {
              showError("Something went wrong. please try again");
            }
            setLoading(false);
          });
      } else {
        axios()
          .post("/astrologers/promotions")
          .then((res) => {
            if (res.data.success) {
              const details = { ...offerPromoDetails };
              details.promotionalDiscount = res.data.discount_per;
              setofferPromoDetails(details);
              res.data.data = addSequencing(res.data.data);
              setPromotionalList(res.data.data);
              FA_localStorage("promoPrice", res.data.discount_per);
              setLoading(false);
            }
          })
          .catch((err) => {
            sentryError("PROMOTIONAL_LIST_DETAILS", err);
            if (promotionalList.length === 0) {
              showError("Something went wrong. please try again");
            }
            setLoading(false);
          });
      }
    }
  };

  const fetchNotifiedUsers = () => {
    if (FA_localStorage("phone")) {
      axios()
        .post("/notifications/all")
        .then((res) => {
          if (res.data.success) {
            setNotifications(res.data.data);
          }
        })
        .catch((err) => {
          sentryError("NOTIFICATIONS", err);
          showError("Error occured, Please try again");
        });
    }
  };

  const addSequencing = (data) => {
    for (let i in data) {
      data[i].sequence = data.length - i;
      data[i].rank = parseInt(i) + 1;
      data[i].visible = true;
    }
    return data;
  };

  const clearRecentConsultation = () => {
    setActiveConsultation({ active: false });
    FA_localStorage("lastActiveConsultation", "remove");
  };
  const showConsultationDetails = () => {
    if (activeConsultation.status === "completed") {
      clearRecentConsultation();
      window.location.href = "/consultant-feedback/" + activeConsultation.id + "?token=" + activeConsultation.token;
    } else if (activeConsultation.status === "initiated") {
      window.location.href =
        "/astrologer-connect/" +
        activeConsultation.id +
        "?token=" +
        activeConsultation.token +
        "&stkn=" +
        activeConsultation.status_token +
        "&prl=%2Fastrologer%2F" +
        activeConsultation.astrologerUrl;
    } else {
      checkRecentConsultations();
    }
  };

  const checkRecentConsultations = () => {
    clearRecentConsultation();
    window.location.href = "/user-consultation-history";
  };
  const checkPendingConsultation = (update) => {
    const lacData = JSON.parse(FA_localStorage("lastActiveConsultation") || "{}");
    if (lacData.token) {
      if (update.message === false || lacData.key === update.message.key) {
        axios()
          .post("/consultations/call/status", {
            id: lacData.id,
            token: lacData.token,
          })
          .then((res) => {
            //console.log("arun", res.data);
            if (res.data.status) {
              setActiveConsultation({
                id: lacData.id,
                astrologer: lacData.astrologer,
                active: true,
                status: res.data.status,
                token: lacData.token,
                status_token: lacData.status_token,
                astrologerUrl: lacData.astrologerUrl,
              });
            } else {
              clearRecentConsultation();
            }
          })
          .catch((err) => {
            sentryError("Call status check error", err);
          });
      }
    }
  };
  const bindRealtimeEvents = () => {
    if (astrologerListData.length > 0 || promotionalList.length > 0) {
      if (typeof EventSource !== "undefined" && !window.statusChangeListener) {
        try {
          const es = new EventSource(STATUS_API_PATH);
          window.statusChangeListener = es;
          var listener = function (event) {
            if (typeof event.data !== "undefined") {
              const update = JSON.parse(event.data);
              if (validateOrigin(update.origin)) {
                //console.log("arun", update);
                if (update.type === "status" && update.message.astrologer_id) {
                  setPostUpdate(update);
                } else if (update.type === "consultation") {
                  checkPendingConsultation(update);
                }
              }
            }
          };
          window.simStatusUpdate = listener;
          window.statusChangeListener = es;
          es.addEventListener("message", listener);
          es.addEventListener("error", (event) => {
            //sentryError("SSE run:Error", event);
            console.error("SSEError", event);
          });
        } catch (err) {
          //sentryError("SSE init:Error", err);
          console.error("SSEError", err);
        }
      }
    }

    const validateOrigin = (origin) => {
      return window.location.origin.indexOf("http://localhost") >= 0 || window.location.origin === origin;
    };
  };

  const recalculateOnlineCount = () => {
    let onlineCount = 0;
    let promoOnlineCount = 0;
    let favOnlineCount = 0;

    astrologersData.map((astrologer) => {
      if (astrologer.visible) {
        if (astrologer.is_favourite) favOnlineCount = favOnlineCount + (astrologer.status === "online" ? 1 : 0);
        else onlineCount = onlineCount + (astrologer.status === "online" ? 1 : 0);
      }
    });
    promotionalList.map((astrologer) => {
      if (astrologer.visible) {
        if (astrologer.is_favourite) favOnlineCount = favOnlineCount + (astrologer.status === "online" ? 1 : 0);
        else promoOnlineCount = promoOnlineCount + (astrologer.status === "online" ? 1 : 0);
      }
    });
    setOnlineAstrologers({
      main: onlineCount,
      promo: promoOnlineCount,
      fav: favOnlineCount,
    });
    setduplicatesInList(checkDuplicatesInList());
    setListUpdated(Date.now());
  };

  const blastConfetti = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 1500);
    showStatusToast(offerDetails.nextCallDiscount + "% cashback already applied", "status");
  };
  const blastConfettiGuest = () => {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 1500);
    showStatusToast("Register now & get " + offerDetails.nextCallDiscount + "% CASHBACK", "status");
  };

  const showStatusToast = (message, status) => {
    setToastBar({ message: message, status: status, open: true });

    if (window.toastHandle) clearTimeout(window.toastHandle);
    window.toastHandle = setTimeout(() => {
      setToastBar({ message: message, status: status, open: false });
    }, 3000);
  };

  const isUserLoggedIn = () => {
    return localStorage.getItem("phone") !== null && localStorage.getItem("phone") !== "";
  };

  const [isConvLoading, setIsConvLoading] = useState(isUserLoggedIn());

  const handleLogoClick = () => {
    window.logoClick = (window.logoClick || 0) + 1;
    if (window.logoClick > 5) {
      /** show credits */
      window.location.href = "/credits";
      window.logoClick = 0;
    } else if (window.logoClick > 3) {
      /** enable debug mode */
      const isInDebug = localStorage.debug ? true : false;
      if (isInDebug) {
        localStorage.removeItem("debug");
        showStatusToast("Debug mode disabled");
      } else {
        localStorage.setItem("debug", "yes");
        showStatusToast("Debug mode enabled");
      }
    }
  };
  const isFavAstrologersExisits = () => {
    if (favAstrologersList) {
      for (var i in favAstrologersList) {
        const astrologer = favAstrologersList[i];
        if (astrologer.visible === true) return true;
      }
    }
    return false;
  };
  /** tab config */
  const CONSULT_TAB = 0;
  const REMEDY_TAB = isChatEnabled ? 2 : 1;
  const CHAT_TAB = 0;
  const CALL_TAB = 1;
  // const [currentTab, setCurrentTab] = useState(
  //   params.view === "remedy" ? REMEDY_TAB : params.view === "chat" ? CHAT_TAB : CALL_TAB
  // ); //window.location.hash === "#pujadetails" ? 1 : 0);
  const [currentTab, setCurrentTab] = useState(0);

  const [activeTab, setActiveTab] = useState(0)

  const switchPanel = (type) => {
    console.log(type)
    setActiveTab(type)
    const event = {
      [CONSULT_TAB]: "fa_consult_tab",
      [REMEDY_TAB]: "fa_remedy_tab",
      [CHAT_TAB]: "fa_chat_tab",
      [CALL_TAB]: "fa_call_tab",
    };
    trackActions(event[type], "NA", {});
    // if (event[type] !== "fa_consult_tab") {
    //   window.location.hash = "#backtomain";
    // } else {
    //   window.location.hash = "";
    //   window.lastHash = undefined;
    // }
    setCurrentTab(type);
  };
  /** tab config */
  const container = useRef();

  useEffect(() => {
    const left = currentTab * container.current.clientWidth;
    container.current.scrollLeft = left;
    //if (currentTab > 0) setScrollPercentage(0.0);
  }, [currentTab]);
  //method debouncer to deal with keypress
  //scroll etc

  const tabViewIndex = {};
  const handleTabSwitch = (e) => {
    // debounce(() => {
    //   tabViewIndex.current = Math.round(container.current.scrollLeft / container.current.clientWidth);
    //   if (tabViewIndex.current !== currentTab) {
    //     switchPanel(tabViewIndex.current);
    //   }
    // }, 600);
  };

  const testAndroid = () => {
    console.log('testAndroid')
    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      const profileData = window.AndroidBridge.call("getAppProfileData")
      alert('AndroidBridge.getAppProfileData: ' + profileData)
    }
  }

  return (
    <ChatWindowStateProvider value={chatWindowData}>
      <ChatWindowStateSetterProvider value={setChatWindowData}>
        <ChatNotificationTrigger setChatWindowData={setChatWindowData} setLoading={setLoading} />
        <Box
          className={[
            isMobile ? classes.container : classes.container,
            "tabbled-container-app",
            'new-style',
            isUserLoggedIn() ? 'logged-in' : 'not-logged-in',
            isMobile ? 'is-mobile' : ''
          ].join(" ")}
          ref={container}
          id={"tab-switch-control"}
          onTouchEnd={handleTabSwitch}>
          <IsSupported />
          <HelmetComponent
            title="Welcome To FindAstro, Get Connected and Talk with Astrologers in India"
            url="https://www.findastro.com/astrologers"
            description="Welcome To FindAstro-We are # 1 Astrologer website in India. Get connected and Talk with reputed astrologers in India at best rates."
          />
          {isLoading ? (
            <div className="circle-loading circle-loading-20px">
              <CircularProgress />
            </div>
          ) : (
            <></>
          )}
          <div className={`autoHideHeader`}>
            <Grid container className={[classes.header, 'header-bar'].join(' ')}>
              <Grid item xs={2} lg={1}>
                <Avatar
                  variant="rounded"
                  className={classes.rounded}
                  onClick={() => {
                    moengage("HAMBURGER_CLICKED");
                    setDrawerOpenState(true);
                    window.stopAllAudio();
                    testAndroid();
                  }}>
                  <MenuIcon />
                </Avatar>
              </Grid>

              <div className="header-bar-logo">
                {isMobile || true ? (
                  <Grid item xs={4} className={classes.marTop5Mobile}>
                    <br></br>
                    <img src={logo} alt="Logo" className={classes.mobileLogo} onClick={handleLogoClick} />
                  </Grid>
                ) : (
                  <Grid item xs={5} className={classes.marTop5}>
                    <div className={classes.desktopTopFlex}>
                      <img src={logo} alt="Logo" className={classes.desktopLogo} />
                      <div className={classes.desktopCustomerCare}>
                        Support:{" "}
                        <a
                          target="_blank"
                          href={"tel:" + String(SUPPORT_NUMBER).replace(/\s/g, "")}
                          className={classes.desktopCustomerCare}>
                          {SUPPORT_NUMBER}
                        </a>
                      </div>
                    </div>
                    Find your <b className={classes.color}>Astrologer</b>
                  </Grid>
                )}
              </div>

              <Grid container item xs={6} style={{ justifyContent: "flex-end" }}>
                <div className={"titleBarIcon"}>
                  <SearchIcon
                    onClick={onSearch}
                    fontSize="small"
                    style={{
                      marginRight: 8,
                      // display: currentTab === CONSULT_TAB ? "inline-block" : "none",
                    }}
                  />
                </div>
                <div className={"titleBarIcon"}>
                  {filterData.sortBy ? (
                    <FilterIconRound
                      onClick={showSortBox}
                      fontSize="small"
                      style={{
                        marginRight: 8,
                        // display: currentTab === CONSULT_TAB ? "inline-block" : "none",
                      }}
                    />
                  ) : (
                    <FilterIcon
                      onClick={showSortBox}
                      fontSize="small"
                      style={{
                        marginRight: 8,
                        // display: currentTab === CONSULT_TAB ? "inline-block" : "none",
                      }}
                    />
                  )}
                </div>
                <div className={"titleBarIcon"} onClick={onWallet}>
                  <AccountBalanceWalletIcon fontSize="small" />
                  &nbsp;{localStorage["walletBalance"] || 0} pts
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container className="footer-bar">
            <Grid item xs={12} id={"headerBar"}>
              <div className={!isChatEnabled() ? "tabbed-head tabbed-head-col-2" : "tabbed-head"}>

                <span
                  className={currentTab === CHAT_TAB ? "current" : ""}
                  onClick={(e) => switchPanel(CHAT_TAB)}
                  onTouchEnd={(e) => e.stopPropagation()}>
                  <b>
                    <ChatBubbleOutlineOutlinedIcon />
                    <span>Chat</span>
                  </b>
                </span>
                <span
                  className={currentTab === CALL_TAB ? "current" : ""}
                  onClick={(e) => switchPanel(CALL_TAB)}
                  onTouchEnd={(e) => e.stopPropagation()}>
                  <b>
                    <CallIcon />
                    <span>Call</span>
                  </b>
                </span>

              </div>
            </Grid>
            <div
              className={clsx("toastBar", toastBar.status, {
                toastBarOpen: toastBar.open === true,
              })}>
              {toastBar.message}
            </div>
          </Grid>
          {isMobile && BrowserUtils.GooglePlaySupport && !window.AndroidBridge && (
            <div className="app-install-banner" onClick={handleDownload}>
              <span>Install Findastro app from Google Play</span>
              <button>Install</button>
            </div>
          )}

          {/* <Grid item xs={12} lg={12} className={[classes.desktopSearchBar, "searchBar"]}>
            <Grid
              className="header-spacer smooth-height"
              style={{
                transition: "height 0.4s ease-out 0.2s",
              }}></Grid>
            {!isUserLoggedIn() ? (
              <div
                style={{
                  padding: "8px 20px",
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#fbead0",
                  color: "#000000",
                  position: "fixed",
                  width: "100%",
                  bottom: "0",
                  zIndex: "100",
                  maxWidth: 460,
                  boxShadow: "0 -2px 20px rgb(0 0 0 / 50%)",
                }}
                onClick={() => {
                  window.location.href = "/login";
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(100% - 80px)",
                  }}>
                  <div>
                    <WarningRoundedIcon
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block",
                        fill: "var(--primary)",
                      }}
                    />

                    <b
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block",
                      }}>
                      You are not logged in
                    </b>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8rem",
                    }}>
                    Login to use the consultancy services
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "80px",
                  }}>
                  <button
                    style={{
                      border: "none",
                      padding: "8px",
                      borderRadius: "18px",
                      boxShadow: "none",
                      backgroundColor: "var(--clr-red)",
                      color: "#fff",
                    }}>
                    Login
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Grid> */}

          {(isChatEnabled() && isUserLoggedIn()) &&
            <div id="new-chat">
              <Conversations showAstrologerProfile={showAstrologerProfile} setIsConvLoading={setIsConvLoading} />
            </div>
          }

          <AstrologersList {...{
            listTypes: [
              {
                type: 'chat',
              },
              // {
              //   type: 'call',
              // },
            ],
            clsx,
            classes,
            astrologersData,
            ScrollViewHandler,
            isMobile,
            hasMore,
            isFetching,
            showBVarient,
            showPromoList,
            promotionalList,
            showOnlyOnline,
            currentFilter,
            showAstrologerProfile,
            openCallDialog,
            handleFavourite,
            handleOfferIcon,
            offerPromoDetails,
            showStatusToast,
            setLoading,
            offerClaim,
            handleNotifyMe,
            notifications,
            onlineAstrologers,
            otherAstrologersList,
            testimonialId,
            defaultImage,
            TestimonialData,
            pendingCashbackOffer,
            activeConsultation,
            isOthersLoading,
            recommendedData,
            isUserLoggedIn,
            onWallet,
            isFavAstrologersExisits,
            offerDetails,
            offlineBookingAvailable,
            FA_localStorage,
            setShowCashbackOffer,
            showConsultationDetails,
            forceUpdate,
            UpdateApp,
            VERSION,
            duplicatesInList,
            isLoading,
            onClear,
            TYBackdrop,
            currentTab,
            activeTab,
            tabInfo,
            activecashBack
          }} />


          {activeConsultation.active === true ? (
            <div className={clsx(classes.activeCall, 'active-call-newUI')}>
              <p>
                <span
                  style={{
                    display: "inline-block",
                    width: "calc(100% - 65px)",
                    verticalAlign: "middle",
                    fontSize: "0.8rem",
                    paddingRight: 8,
                  }}>
                  {activeConsultation.status === "initiated" ? (
                    <>
                      Your astrology consultation with <b>{activeConsultation.astrologer || "our astrologer"}</b>
                      {" is now "}
                      <small className={activeConsultation.status}> in progress</small>
                    </>
                  ) : (
                    <>
                      Your recent consultation with <b>{activeConsultation.astrologer || "our astrologer"}</b>{" "}
                      is&nbsp;
                      <small
                        className={activeConsultation.status === "missed" ? "failed" : activeConsultation.status}>
                        {activeConsultation.status === "missed" ? "failed" : activeConsultation.status}
                      </small>
                    </>
                  )}
                </span>
                {activeConsultation.status === "completed" ? (
                  <button
                    style={{
                      padding: 8,
                      backgroundColor: "#ee600d",
                      color: "#ffffff",
                      border: "none",
                      borderRadius: 8,
                      width: 65,
                    }}
                    onClick={showConsultationDetails}>
                    Details
                  </button>
                ) : (
                  <button
                    style={{
                      padding: 8,
                      backgroundColor: "#ee600d",
                      color: "#ffffff",
                      border: "none",
                      borderRadius: 8,
                      width: 65,
                    }}
                    onClick={showConsultationDetails}>
                    Details
                  </button>
                )}
              </p>
            </div>
          ) : (
            <></>
          )}
          {/* {isChatEnabled() && (
            <div className={["chat", "tab-panel"].join(" ")} ref={tabPanelChat} onScroll={ScrollViewHandler}>
              <ChatPanel
                showError={showError}
                scrollPanel={tabPanelChat}
                showAstrologerProfile={showAstrologerProfile}
              />
            </div>
          )} */}
          {/* <div className={["puja-homa", "tab-panel"].join(" ")} ref={tabPanelPuja} onScroll={ScrollViewHandler}>
            <PujaList showError={showError} scrollPanel={tabPanelPuja} showAstrologerProfile={showAstrologerProfile} />
          </div> */}
          {/* {otherAstrologersList.length > 0 && hasMore === false && isUserLoggedIn() && offerDetails.nextCallDiscount && (
        <UserStartHere
          promotionalList={promotionalList}
          mainAstrologersList={astrologersData}
          currentFilter={currentFilter}
          openProfile={showAstrologerProfile}
          handleCall={openCallDialog}
          handleFavourite={handleFavourite}
          handleOfferIcon={handleOfferIcon}
          offerDiscount={offerDetails.nextCallDiscount}
          promoDiscount={offerPromoDetails.promotionalDiscount}
          key={"start_here_widget"}
          showMessage={showStatusToast}
          showLoading={setLoading}
          offerClaim={offerClaim}
          handleNotify={handleNotifyMe}
          notifications={notifications}
          listUpdated={listUpdated}
          show={true}
        />
      )} */}
          <SideDrawerComponent
            drawerOpen={drawerOpen}
            onDrawerClose={() => {
              setDrawerOpenState(false);
            }}
            onpageEventHandler={onpageEventHandler}
          />
          {upcomingBookings.length > 0 && (
            <div
              className="upcoming-bookings"
              style={{ bottom: localStorage["filterApplied"] === "yes" ? 28 : 28 }}
              onClick={(e) => (window.location.href = "/user-consultation-history?v=booking")}>
              <BookingIcon />
              <span>{upcomingBookings.length}</span>
            </div>
          )}
          <FilterDrawerComponent
            filterOpen={filterOpen}
            onFilterDrawerClose={showFilterBox}
            showLoading={showLoading}
            filterData={filterData}
          />
          <SortDrawerComponent
            sortOpen={sortOpen}
            setSortBy={setSortBy}
            onSortDrawerClose={showSortBox}
            filterData={filterData}
          />
          {(BrowserUtils.isAWebview || sessionStorage.forceWebUse === "force") && isCallDlgStateOpen ? (
            <AstrologerCallDialog
              className={"callDialog"}
              astrologer={selectedAstrologer}
              handleClose={handleDlgClose}
              isStateOpen={isCallDlgStateOpen}
              dialogType={callDlgType}
              extras={callDlgExtra}
              cashBackDiscount={activecashBack}
              runUserUpdates={runUserUpdates}
            />
          ) : (
            <AppInstallBanner
              className={"installDialog"}
              handleClose={handleDlgClose}
              isStateOpen={isCallDlgStateOpen}
            />
          )}

          <CouponRedeemDialog
            isStateOpen={isRedeemDialogOpen}
            handleClose={CouponHandleClose}
            showLoading={(value) => {
              setLoading(value);
            }}
            showError={showError}
          />
          {pendingReview.astrologer ? (
            <div
              className={clsx("pendingReviewPanel", {
                open: pendingReview.is_submitted === false,
              })}>
              <span className={"text"}>
                How was your last consultation experience with Astrologer <b>{pendingReview.astrologer.name}</b>?
              </span>
              <div>
                <div className="dialogImage" style={{ background: "#efefef" }}>
                  <Avatar
                    alt={pendingReview.astrologer.name}
                    src={
                      pendingReview.astrologer.id
                        ? AstrologerUtils.createProfileImageURI(pendingReview.astrologer.id)
                        : defaultImage
                    }
                    onLoad={imageLoaded}
                  />
                </div>
                <div className={"ratingBar"}>
                  <div>
                    <Rating
                      name="simple-controlled"
                      value={pendingReview.rating || 5}
                      precision={1}
                      className={pendingReview.ratingError === true ? "rating-error" : ""}
                      onChange={(event, newValue) => {
                        const pr = { ...pendingReview };
                        pr.rating = newValue;
                        setPendingReview(pr);
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <textarea
                      maxLength={400}
                      value={pendingReview.reviewText}
                      className={"reviewText"}
                      placeholder={"Your review here."}
                      onChange={(event) => {
                        const pr = { ...pendingReview };
                        pr.reviewText = event.target.value;
                        setPendingReview(pr);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div onClick={closeReview} style={{ color: "#aaa", textAlign: "center", paddingTop: 8 }}>
                Later
              </div>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  left: "0",
                  bottom: "0",
                  height: "48px",
                  fontWeight: 700,
                }}>
                <Button
                  onClick={postReview}
                  variant="contained"
                  style={{ width: "100%", borderRadius: "0", height: "100%" }}
                  className={"dialogButton primary"}>
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/*QueryString().code && QueryString().code.length > 0 ? (
        <Dialog open={true} className={classes.dialogContainer}>
          <div className={classes.reWrap}>
            <div className={classes.paperCenter}>
              <div className={classes.reMessage}>
                <div>You are one step away to claim the reward</div>
              </div>
              <Button variant="contained" className={classes.reClaimButton} onClick={onReferEarnClaim}>
                Claim Rewards
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <></>
      )*/}

          {/* 
          
          {regnOffer.status === true ? (
            <Modal open={true}>
              <div
                className={classes.rateusModal}
                style={{
                  paddingBottom: 48,
                  overflow: "hidden",
                  backgroundColor: "#f5ff8e",
                  maxWidth: 320,
                }}>
                <TYBackdrop style={{ height: "50vh", position: "absolute", left: "0", opacity: "0.18" }} />
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    color: "#5d5d5d",
                    margin: "24px 0px 4px 0",
                  }}>
                  <b>Congratulations! </b>
                  <br />
                  <small>You are rewarded with points worth</small>
                </div>
                <div style={{ fontSize: "4rem", color: "#f17c38", fontWeight: "400" }}>
                  ₹{FA_localStorage("walletBalance")}
                </div>
                <div
                  style={{
                    padding: "0 24px 24px 24px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#5d5d5d",
                  }}>
                  Happy consulting!
                </div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "24px",
                    boxShadow: "none",
                    backgroundColor: "#f17c38",
                    color: "rgb(255, 255, 255)",
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    FA_localStorage("registrationInit", "remove");
                    setShowRegnOffer({});
                    setTimeout(() => {
                      setShowRegnOffer({ nextCallStatus: true });
                    }, 2000);
                    setTimeout(() => {
                      setShowRegnOffer({ nextCallStatus: true, blastNextCallConfetti: true });
                    }, 2100);
                  }}>
                  <span>Okay</span>
                </Button>
                <BlastConfetti isFloating={true} showConfetti={regnOffer.blastConfetti} />
              </div>
            </Modal>
          ) : (
            <></>
          )}
          {regnOffer.nextCallStatus ? (
            <Modal open={true}>
              <div
                className={classes.rateusModal}
                style={{
                  paddingBottom: 48,
                  overflow: "hidden",
                  backgroundColor: "#f5ff8e",
                  maxWidth: 320,
                }}>
                <TYBackdrop style={{ height: "50vh", position: "absolute", left: "0", opacity: "0.18" }} />
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    color: "#5d5d5d",
                    margin: "24px 0px 24px 0",
                  }}>
                  <b>Congratulations! </b>
                  <br />
                  <small>you will get a</small>
                </div>
                <div style={{ fontSize: "1.9rem", color: "#f17c38", fontWeight: "600" }}>
                  {FA_localStorage("nextCallPrice")}
                  <small>%</small> CASHBACK
                </div>
                <div
                  style={{
                    padding: "0 24px 24px 24px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#5d5d5d",
                  }}>
                  for your first consultation, no matter how long you talk!
                </div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "24px",
                    boxShadow: "none",
                    backgroundColor: "#f17c38",
                    color: "rgb(255, 255, 255)",
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    setShowRegnOffer({});
                    FA_localStorage("registrationInit", "remove");
                  }}>
                  <span>Okay</span>
                </Button>
                <BlastConfetti isFloating={true} showConfetti={regnOffer.blastNextCallConfetti} />
              </div>
            </Modal>
          ) : (
            <></>
          )}
          {currentOffer.nextCallStatus ? (
            <Modal open={true}>
              <div
                className={classes.rateusModal}
                style={{
                  paddingBottom: 48,
                  overflow: "hidden",
                  backgroundColor: "#f5ff8e",
                  maxWidth: 320,
                }}>
                <TYBackdrop style={{ height: "50vh", position: "absolute", left: "0", opacity: "0.18" }} />
                <div
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "400",
                    color: "#5d5d5d",
                    margin: "24px 0px 8px 0",
                  }}>
                  <b>You will get </b>
                </div>
                <div style={{ fontSize: "1.9rem", color: "#f17c38", fontWeight: "600" }}>
                  {currentOffer.offer}
                  <small>%</small> CASHBACK
                </div>
                <div
                  style={{
                    padding: "0 24px 24px 24px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    color: "#5d5d5d",
                  }}>
                  points after the consultation with this astrologer.
                </div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: "24px",
                    boxShadow: "none",
                    backgroundColor: "#f17c38",
                    color: "rgb(255, 255, 255)",
                    padding: "4px 20px",
                  }}
                  onClick={() => {
                    setShowCurrentOffer({});
                  }}>
                  <span>Okay</span>
                </Button>
                <BlastConfetti isFloating={true} showConfetti={regnOffer.blastNextCallConfetti} />
              </div>
            </Modal>
          ) : (
            <></>
          )} 
          
          */}

          {showFilterFeature() && (
            <FilterList
              filter={currentFilter}
              processFilterMap={processFilterMap}
              astrologerList={astrologersData}
              promotionalList={promotionalList}
              showStatusToast={showStatusToast}
              handleClose={closeAllModalPopups}
              langExpertiseMatrix={langExpertiseMatrix}
            />
          )}
          <Snackbar
            vertical="top"
            horizontal="right"
            message={snackBar.message}
            closeError={closeError}
            open={snackBar.open}></Snackbar>
          <div className={["astrologer-profile", astProfileOpen && astProfileDataParams ? "open" : ""].join(" ")}>
            {astProfileDataParams && (
              <AstrologerProfile
                type={"popup"}
                runUserUpdates={runUserUpdates}
                closePopup={closeAllModalPopups}
                location={{}}
                match={astProfileDataParams}
                handleFavourite={handleFavourite}
              />
            )}
          </div>
          {isMobile ? (
            <></>
          ) : (
            <>
              <Divider className={classes.footerDivider} />
              <Grid container className={classes.footer} direction="row">
                <Grid item container direction="column" xs={12}>
                  <Grid item className={classes.footerHeading}>
                    About Findastro
                  </Grid>
                  <Grid item>
                    Findastro, by Clickastro, is one of India's pioneering astrology services company. It presents a
                    unique platform to meet all your astrological needs. Consult your favourite astrologer and get
                    solutions for all your astrological queries related to love, relationships, marriage, career,
                    wealth, over chat, calls, or reports. Findastro helps you to connect to your favourite astrologer
                    any time from the comforts of your home or office. All we require is your accurate birth details,
                    and you are good to go. What's more, the calls are charged at nominal rates. Our panel of
                    astrologers is ready to help you with all your queries and provide precise predictions and remedial
                    measures. Join our customer base of over 120 million satisfied customers.
                  </Grid>
                  <Grid item>
                    <div className={classes.copyright}>
                      &copy; {new Date().getFullYear()} Astro-Vision Futuretech Pvt.Ltd. All rights reserved
                    </div>
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={12}>
                  <Grid item className={classes.footerHeading}>
                    Contact Us
                  </Grid>
                  <Grid item>Phone: +918047248888</Grid>
                  <Grid item>Email: support@findastro.com</Grid>
                  <br />
                  <Grid item className={classes.footerHeading}>
                    Powered By
                  </Grid>
                  <Grid item>
                    Astro-Vision Futuretech Pvt.Ltd., 1st Floor,White Tower, Thammanam P O, Cochin - 682032 Kerala,
                    India.
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={12}>
                  <Grid item onClick={() => (window.location.href = "/")}>
                    Home
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/about-us")}>
                    About Us
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/daily-updates")}>
                    Daily Predictions
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/weekly-updates")}>
                    Weekly Predictions
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/terms-and-conditions")}>
                    Terms &amp; Conditions
                  </Grid>
                  <Grid item onClick={() => (window.location.href = "/privacy-policy")}>
                    Privacy Policy
                  </Grid>
                  <br />
                  <Grid item className={classes.footerHeading}>
                    Download APP
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      window.location.href =
                        "https://play.google.com/store/apps/details?id=com.clickastro.dailyhoroscope&referrer=utm_source%3DFindastro_web%26utm_medium%3Dapplink";
                    }}>
                    <img style={{ height: "50px" }} alt="playstore" src={playstore} />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </ChatWindowStateSetterProvider>
    </ChatWindowStateProvider>
  );
};

export default AppHome;
