import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import BrowserUtils from '../UAScripts'

const useStyles = makeStyles({
  container: {
    '&.try-another': {
      backgroundColor: 'transparent !important',
    },
    '&:not(.try-another)': {
      padding: '1.5rem 0 !important',
    }
  },
  title: {
    marginTop: 0,
    marginBottom: '5px',
    fontSize: '14px',
    color: '#000',
    fontWeight: 'bold',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    width: 210,
    margin: 'auto',
    marginTop: '1rem',
    gap: '.5rem',
    '& .MuiButtonBase-root': {
      backgroundColor: 'var(--clr-green)',
      color: '#fff',
      borderRadius: '.75rem',
      padding: '.5rem .25rem',
      '&.no': {
        backgroundColor: 'var(--clr-red3)',
      }
    }
  },
  minimum: {
    color: 'var(--clr-red3)'
  },
  required: {
    color: 'var(--clr-green)'
  },
  paragraph: {
    padding: '0 1rem',
  }
})

const TryAnotherAstrologerComponent = ({ astrologer, events, onContinueConsultation }) => {
  const classes = useStyles()
  const freeConsultationTryAnotherAstrologer = BrowserUtils.isFreeConsultationTryAnotherAstrologer()

  const handleTryAnotherAstrologer = () => {
    // Reload

    // Add localStorage -> status, astrologer exclusion list
    let freeConsultationFlow = { astrologer_exclusion: [] }
    if (localStorage.getItem('freeConsultationFlow')) {
      const data = JSON.parse(localStorage.getItem('freeConsultationFlow'))
      freeConsultationFlow = { ...freeConsultationFlow, ...data }
    }
    if (!freeConsultationFlow.astrologer_exclusion.includes(astrologer.id)) {
      freeConsultationFlow.astrologer_exclusion.push(astrologer.id)
    }
    freeConsultationFlow = { ...freeConsultationFlow, tryAnotherAstrologer: true }
    localStorage.setItem('freeConsultationFlow', JSON.stringify(freeConsultationFlow))
    localStorage.setItem('freeConsultationTryAnotherAstrologer', '1')

    window.location = '?fcm_try_another_astrologer=true'
  }

  return (
    <div className={clsx(
      'chat-message meta tas-component',
      classes.container,
      freeConsultationTryAnotherAstrologer ? 'try-another' : ''
    )}>
      {freeConsultationTryAnotherAstrologer ?
        <>
          <p>Start consultation with {astrologer.fullName}</p>
          <div className={classes.buttons}>
            <Button onClick={onContinueConsultation}>Yes, start consultation</Button>
            <Button onClick={events.onBack} className='no'>Search for another astrologer</Button>
          </div>
        </>
        :
        <>
          <div className={classes.title}>{astrologer.fullName} is currently not available</div>
          <p className={classes.paragraph}>
            We are sorry, this astrologer is currently not available.
            Try another astrologer?</p>
          <div className={classes.buttons}>
            <Button onClick={handleTryAnotherAstrologer}>Try another astrologer</Button>
          </div>
        </>
      }

    </div>
  )
}

export default TryAnotherAstrologerComponent