import React, { useEffect, useRef } from "react";
import BrowserUtils from "../../../user/astrologer/components/UAScripts";
import RechargeLinkRedirect, { useRechargeRedirect as getRechargeRedirect } from "../../../user/astrologer/components/RechargeLinkRedirect";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "../../../../common/interceptor";
import sentryError from "../../../../common/sentryError";

const getRefData = ({ astrologer, recharge, type }) => {
  let data = {
    type,
    dealPurchase: true,
    refID: 'deal-purchase',
    location: 'deal-purchase',
    amount: recharge.amount,
    minute: recharge.minutes,
    recharge,
    // wallet_transparent_page: true,
  }

  if (astrologer !== null) {
    data = {
      ...data,
      astrologerID: astrologer.id,
      astrologerName: astrologer.name,
      astrologerProfileURL: astrologer.profile_url || astrologer.profileUrl,
      astrologerCharge: astrologer?.perMinutePrice || 0,
    }
  }

  return data
}

// @Deprecated - Not using this component anymore
const Button = ({
  astrologer,
  type,
}) => {
  const history = useHistory()
  const btnEl = useRef()

  const recharge = {
    amount: 1,
    minute: 5,
  }

  useEffect(() => {
    if (btnEl?.current) {
      btnEl.current.astrologer = astrologer
      btnEl.current.consult_type = type
    }
  }, [])

  const refData = getRefData({ astrologer, recharge })
  const rechargeRedirect = getRechargeRedirect()

  const onWallet = (e) => {
    if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
      e?.goToWallet ? e.goToWallet() : history.push('/user-wallet')
    } else {
      showVerifyPopup()
    }
  }

  const showVerifyPopup = () => {

    if (!window.isAppProfileDataHasEmail() && window.AndroidBridge) {
      BrowserUtils.devAlert('isReadyToLogin')
      window.AndroidBridge.call("isReadyToLogin")
      return
    }

    const actionButtons = document.querySelectorAll('.astrologerInfo.visibleInCurrentTab .btn-action')
    let actionBtn = null;
    [...actionButtons].map(btn => {
      if (btn?.astrologer?.id === astrologer?.id && btn?.consult_type === type) {
        actionBtn = btn
      }
    })

    if (!actionBtn && actionButtons.length > 0) {
      actionBtn = actionButtons[0]
    }

    if (!astrologer?.id) {
      actionBtn.classList.add('show--popup')
    }

    // Show the verify phone number popup
    if (actionBtn) {
      actionBtn.click()
    }

    // Reset the recharge redirect data
    if (refData) {
      refData.afterLogin = true
      rechargeRedirect.setData(refData)
    }

  }

  const handleOnClick = (e) => {
    if (window.isAppProfileDataHasEmail() && BrowserUtils.isLoggedIn()) {
      // setTimeout(() => onWallet(e), 1000)
      onWallet(e)
    } else {
      showVerifyPopup()
    }
  }

  return (
    BrowserUtils.isNewUI() ?
      <>
        <RechargeLinkRedirect
          refData={refData}
          onClick={handleOnClick}
        >
          <button
            style={{ marginLeft: 8, borderRadius: 8, border: '1px solid' }}
            ref={btnEl}
          >
            First {type} @ ₹1
          </button>
        </RechargeLinkRedirect>
      </>
      :
      <></>
  )
}

const isLastPurchaseIsDealPurchase = () => {
  let data = localStorage.getItem('last_order_details')
  if (data !== null) {
    data = JSON.parse(data)
    if (data?.dealPurchase) {
      return true
    }
  }
  return false
}

const isPaymentRequest = () => {
  const rechargeRedirect = getRechargeRedirect()

  if (isLastPurchaseIsDealPurchase()) {
    return (rechargeRedirect.isValid() &&
      rechargeRedirect.getItem('amount'))
  }

  return (rechargeRedirect.isValid() &&
    rechargeRedirect.getItem('dealPurchase') &&
    rechargeRedirect.getItem('amount'))
}

const InitiatePayment = ({
  setAmount,
  setPoints,
  setAmountPaid,
  setIsLoading,
  trackeventData,
  showError,
  setPaymentDialog,
  setBlankBackgroundScreen,
  deal,
  gateway = 'CAPG',
  setDealErrorOrder
}) => {

  const rechargeRedirect = getRechargeRedirect()


  if (isPaymentRequest()) {
    //console.log('recharge', rechargeRedirect.getItem('recharge'))
    const instantPayData = rechargeRedirect.getItem('recharge')
    const instAmount = rechargeRedirect.getItem('amount')

    const eData = {
      name: 'Payment Initiate',
      dealPurchase: true,
      status: 'Initiated',
      amount: instantPayData.amount || instAmount,
      orderId: '',
      transactionId: '',
      gateway: gateway,
      gatewayResponse: 'Unprocessed',
      walletBalance: localStorage.getItem("walletBalance"),
      points: instantPayData.actualAmount || instAmount,
      amountPaid: instantPayData.amount
    };
    setAmount(eData.amount);
    setPoints(eData.points);

    if (gateway === 'CAPG') {
      axios()
        .post("/wallet/recharge/initiate-order", {
          plan_id: deal.id,
          gateway: 'CAPG',
        })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            eData.orderId = res.data.order_id;
            eData.transactionId = res.data.payment_reference_id;
            eData.gatewayResponse = 'Initiated';
            if (res.data.amount) {
              eData.amountPaid = res.data?.amount
              setAmountPaid(eData.amountPaid)
            }

            setIsLoading(false);
            if (!(res.data.gateway_url && res.data.gateway_url !== '')) {
              throw new Error('The `gateway_url` is missing');
            } else if (!(res.data.order_id && res.data.order_id !== '')) {
              throw new Error('The `order_id` is missing');
            } else {
              const paymentURL = `${res.data.gateway_url}?order_id=${btoa(res.data.order_id)}&order_checksum=${btoa(res.data.order_checksum)}`
              setPaymentDialog({
                open: true,
                paymentURL,
                fullScreen: true,
              })
              localStorage.setItem('last_order_details', JSON.stringify({
                ...res.data,
                item: deal,
                paymentURL,
                plan_amount: rechargeRedirect.getItem('amount'),
                minutes: rechargeRedirect.getItem('minute'),
                astrologer_id: rechargeRedirect.getItem('astrologerID'),
                // instantPaymentMethod: isInstantPaymentRequest()
                dealPurchase: true,
                plan_type: deal.plan_type,
                id: deal.id,
              }))
            }
          } else if (res.data?.error_code === 'ORDER_ERR_103') {
            setDealErrorOrder(deal => {
              return {
                ...deal,
                open: true,
                response: res
              }
            })
            setIsLoading(false);
            setBlankBackgroundScreen(false);
          }
          else {
            eData.orderId = res?.data?.order_id || '';
            eData.transactionId = res?.data?.payment_reference_id || '';
            showError(res.data.message);
            setIsLoading(false);
            setBlankBackgroundScreen(false);
          }
          trackeventData(eData, deal);
        })
        .catch((err) => {
          sentryError("MY_WALLET_DEAL_PURCHASE_PROCEED", err);
          showError("E-WDP01: Error occured, Please try again");
          setIsLoading(false);
          trackeventData(eData, deal);
        });
    } else if (gateway === 'RZ-M' || gateway === 'GPAY-M') {
      const amount = instantPayData.amount || instAmount
      axios()
        .post("/wallet/recharge/create-order-app", {
          amount,
          gateway,
        })
        .then((res) => {
          console.log({ res })
          if (res.data.success) {
            // triggerRazorpay(res.data);
            eData.orderId = res.data.order_id;
            eData.transactionId = res.data.payment_reference_id;
            eData.gatewayResponse = 'Initiated';
            if (res.data.amount) {
              eData.amountPaid = res.data?.amount
            }

            setIsLoading(false);
            if (gateway === 'RZ-M' && !(res.data.payment_reference_id && res.data.payment_reference_id !== '')) {
              throw new Error('The `payment_reference_id` is missing');
            } else if (!(res.data.order_id && res.data.order_id !== '')) {
              throw new Error('The `order_id` is missing');
            } else {
              const paymentURL = 'nativeRP'
              localStorage.setItem('last_order_details', JSON.stringify({
                ...res.data,
                item: deal,
                paymentURL,
                plan_amount: amount,
                // instantPaymentMethod: isInstantPaymentRequest()
                dealPurchase: true,
                plan_type: deal.plan_type,
                id: deal.id,
              }))
              setPaymentDialog({
                open: true,
                paymentURL,
                paymentData: res.data,
                gateway,
                fullScreen: true,
              })
            }
          } else if (res.data?.error_code === 'ORDER_ERR_103') {
            setDealErrorOrder(deal => {
              return {
                ...deal,
                open: true,
                response: res,
              }
            })
            setIsLoading(false);
            setBlankBackgroundScreen(false);
          } else {
            showError(res.data.message);
            setIsLoading(false);
            setBlankBackgroundScreen(false);
          }
          trackeventData(eData, deal);
        })
        .catch((err) => {
          console.error(err)
          sentryError("MY_WALLET_DEAL_PURCHASE_PROCEED", err);
          showError("E-WN05: Error occured, Please try again");
          setIsLoading(false);
          trackeventData(eData, deal);
        });
    }

    return
  }
}

const DealPurchase = ({ }) => {

}

DealPurchase.getRefData = getRefData
DealPurchase.Button = Button // @Deprecated
DealPurchase.InitiatePayment = InitiatePayment
DealPurchase.isPaymentRequest = isPaymentRequest
DealPurchase.isLastPurchaseIsDealPurchase = isLastPurchaseIsDealPurchase

export default DealPurchase