import React, { useEffect, useState } from "react";
import Moment from "moment";
import SingleMessage from "./SingleMessage";
import BrowserUtils from "../UAScripts";
import { Avatar } from "@material-ui/core";
import AstrologerUtils from "../AstrologerUtils";
import InChatAvatar from "./InChatAvatar";
import TaskAltIcon from './TaskAltIcon';

const ReplaceText = ({ message, astrolgerInfo, chatHistory, returnResult = false }) => {

  const text = message.body.text.text
  let before = <></>
  let after = <></>

  if (true) { //BrowserUtils.isNewUI()
    let _text = text
    let replaceText = `recharge`
    let result = text

    // Recharge text
    if (_text.includes(replaceText)) {
      let replaceTextTo = message.body.text.type === "chat" ? `recharge` : `recharge now`
      let regEx = new RegExp(replaceText, "ig")
      let replaceMask = `<span
        class="chat-meta-link recharge"
        onclick="window.inChatMoengageEvt='Recharge Click in Chat'; document.getElementById('chat-action-bar-recharge-button').click()">
        ${replaceTextTo}
      </span>`
      result = _text.replace(regEx, replaceMask)
    } else if (message.body.text.action === 'horoscope-analyse') {
      after = <TaskAltIcon fontSize="small" style={{ verticalAlign: 'middle', color: '#06b77b' }} />
    }

    if (returnResult) {
      return result
    }

    // Add astrologer photo
    if (message.body.text.action === 'chat-accept') {
      before = <InChatAvatar astrologer={astrolgerInfo} />
    }

    return <span>{before}<span dangerouslySetInnerHTML={{ __html: result }}></span>{after}</span>
  }

  return <>{text}<br /></>
}

const MetaMessage = ({ message, msgStatus, MessageType, astrolgerInfo, chatHistory }) => {
  const [metaText, setMetaText] = useState(null)
  useEffect(() => {
    if (message.body.text.type === "chat") {
      setMetaText(ReplaceText({ message, astrolgerInfo, chatHistory, returnResult: true }))
    }
  }, [message.body.text.type])
  const highlight = message.body?.extra?.highlight ? ' highlight' : ''
  const transparent = message.body?.extra?.transparent ? ' transparent' : ''
  return (
    <>
      {message.body.text.type === "chat" ? (
        <SingleMessage msg={message} msgStatus={msgStatus} MessageType={MessageType} metaText={metaText} />
      ) : (
        <div key={message.id} className={`chat-message meta ${message.body.text.type}${highlight}${transparent}`}>
          <ReplaceText
            {...{ message, astrolgerInfo, chatHistory }}
          />
          {message.formattedTime !== undefined ?
            <div className="chat-message-meta-time">{message.formattedTime}</div>
            :
            <div className="chat-message-meta-time">{Moment(message.created).format("MMM D, yyyy h:mm a")}</div>
          }
        </div>
      )}
    </>
  );
};
export default MetaMessage;
