export const ContentFreeChatInfo = ({ }) => {
  return (
    <div className="content-free-chat-info">will answer at least 1 question.</div>
  )
}

export const ContentPaidChatInfo = ({ }) => {
  return (
    <div className="content-paid-chat-info">will answer all your questions.</div>
  )
}

export const ContentFreeChatInfoForWaitingScreen = ({ }) => {
  return (
    <div className="content-free-chat-info-waiting-screen">At least one question will be answered in this session.</div>
  )
}
export const ContentPaidChatInfoForWaitingScreen = ({ astrologer }) => {
  return (
    <div className="content-paid-chat-info-waiting-screen">{astrologer.name} will answer all your questions.</div>
  )
}