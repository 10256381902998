import React, { useEffect, useRef, useState } from 'react'
import WalletModal from './WalletModal.component'
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import T2AEventTrack from '../../../common/T2AEventTrack';
import BrowserUtils from '../../user/astrologer/components/UAScripts';
import { useRechargeRedirect } from '../../user/astrologer/components/RechargeLinkRedirect';

const useStyles = makeStyles((theme) => ({
  container: {},
  walletModal: {
    '& .MuiBackdrop-root': {
      display: 'none',
    },
    '& .MuiDialog-paper': {
      boxShadow: 'none',
    },
  },
}));

const WalletResponsePage = () => {
  const classes = useStyles()
  const params = new URLSearchParams(window.location.search)
  const status = params.get('status').toLocaleLowerCase()
  const orderIdFromUrl = params.get('order_id') ? params.get('order_id') : "orderIdFromUrl_undefined";
  const orderId = 0
  const [modalOpen, setModalOpen] = useState(false)
  const [rechargeValue, setRechargeValue] = useState(0)
  const history = useHistory()
  const rechargeRedirect = useRechargeRedirect()
  const walletLinkRef = useRef(null)

  useEffect(() => {
    const paymentGateway = params.get('pg') !== null ? params.get('pg') : 'CAPG';
    handleStatus(paymentGateway)
  }, [])

  useEffect(() => {
    // preventBackPage()
  })

  const preventBackPage = () => {
    // Method: 1
    // window.onpopstate = function () {
    //   history.push('/user-wallet')
    // }

    // Method: 2
    // const preventBack = () => {
    //   window.history.forward()
    // }
    // setTimeout(preventBack, 0)
    window.onunload = () => {
      // window.open(`/user-wallet?status=${status}`, '_self');
    }

    // Method: 3
    // window.history.go(1)
  }

  const trackEventData = (data) => {
    if (!data.amountPaid) {
      data.amountPaid = data.amount;
    }
    if (!data.points) {
      data.points = data.amount;
    }
    const payload = {
      event: {
        name: data?.name || 'Payment Initiate',
        attributes: {
          'Screen Name': (data.instantPaymentMethod) ? 'Instant popup' : 'My Wallet',
          'Status': data.status,
          'SKU': data?.sku || 'COT' + data.amount,
          'Order ID': data.orderId,
          'Transaction ID': data.transactionId,
          'Price': parseFloat(data.amountPaid),
          'Wallet Amount': parseFloat(data.points),
          'Gateway': data.gateway,
          'Gateway Response': data.gatewayResponse,
          'Cashback': 0,
          'Discount': parseFloat(data.points) - parseFloat(data.amount),
          'Source Screen Name': rechargeRedirect.getItem('location') || 'My Wallet',
          'Reward Used': 0,
          'Product': 'COT',
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
        }
      },
      extra: {
        user: {
          'User ID': BrowserUtils.CurrentUserInfo().id,
          'User Name': BrowserUtils.CurrentUserInfo().name,
          'Last pay attempt status': data.status,
          'Last pay attempt time stamp': Date.now(),
          'Current wallet balance': parseInt(data.walletBalance)
        }
      }
    }

    console.log(payload);
    T2AEventTrack(payload.event, payload.extra);
  }

  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1)
  }


  const isInstantPaymentRequest = () => {
    return (rechargeRedirect.isValid() &&
      rechargeRedirect.getItem('instantPayment') &&
      rechargeRedirect.getItem('amount'))
  }

  const handleStatus = (paymentGateway) => {

    let data = localStorage.getItem('last_order_details')
    let walletBalance = localStorage.getItem('walletBalance') ? Number(localStorage.getItem('walletBalance')) : 0
    if (data !== null) {
      data = JSON.parse(data)
      if (data?.recharge_points) {
        setRechargeValue(data.recharge_points)
      }

      let isDeal = data?.dealPurchase || false; // added a patch to update the value based on SKU due to an error found while analysing branch data.
      const sku = getSKU(data?.plan_amount, data?.item);
      // if sku value starts with COTDEAL then it is deal
      if (sku.startsWith('COTDEAL')) {
        BrowserUtils.devAlert("DEAL by SKU");
        isDeal = true;
      }

      // Sent track events
      const eData = {
        name: 'Payment Failure',
        status: capitalizeFirstLetter(status),
        amount: data?.plan_amount,
        instantPaymentMethod: isInstantPaymentRequest(),
        points: data?.recharge_points,
        amountPaid: data?.amount,
        orderId: data?.order_id,
        transactionId: data?.payment_reference_id,
        gateway: paymentGateway,
        gatewayResponse: capitalizeFirstLetter(status),
        walletBalance: walletBalance,
        sku: sku
      }

      if (status === 'success') {
        eData.name = 'Purchase Action';
        eData.status = 'Success';
        eData.gatewayResponse = 'Success';
        if (data?.plan_amount && walletBalance) {
          eData.gatewayResponse = walletBalance + data.plan_amount;
        }

        T2AEventTrack({}, {
          eventType: isDeal ? 'COT_DEAL_PURCHASE' : 'COT_NONDEAL_PURCHASE',
          actionType: 'purchase',
          value: data?.amount,
          sku: sku,
          quantity: 1,
          affiliation: paymentGateway,
          transactionId: orderIdFromUrl !== "orderIdFromUrl_undefined" ? orderIdFromUrl : data?.payment_reference_id
        });
      }

      trackEventData(eData)
    }

    // Open modal for successful payments
    if (status === 'success') {
      setModalOpen(true)
      BrowserUtils.setCachedKey('userStatus', 'Paid user');
    }
    // Redirect to retry payment
    else {
      // setTimeout(() => {
      //   window.location.href = `/user-wallet?status=${status}`
      // }, 1000)
      // history.replace(`/user-wallet?status=${status}`)
      // setTimeout(() => {
      //   walletLinkRef.current.click()
      // }, 3000)
      // window.open(`/user-wallet?status=${status}`, "", "width=300, height=300");
      // window.open(`/user-wallet?status=${status}`);

      history.replace(`/user-wallet?status=${status}`)
    }
  }

  const getSKU = (amount, item) => {
    return 'COT' + (item?.plan_type && item?.plan_type !== 'RECHARGE' ? item.plan_type : '') + amount
  }

  const goBack = () => {
    // history.push('/')
  }

  return (
    <div
      className={clsx(
        classes.container
      )}
    >
      <WalletModal
        modalOpen={modalOpen}
        status={status}
        orderId={orderId}
        // resetHistory={clearHistory}
        goBack={goBack}
        rechargeValue={rechargeValue}
        className={clsx(
          classes.walletModal
        )}
        targetURI="/"
      />
      <a style={{ display: 'none' }} ref={walletLinkRef} href={`/user-wallet?status=${status}`}>Link</a>
    </div>
  )
}

export default WalletResponsePage