import Confetti from "react-dom-confetti";

const BlastConfetti = (props) => {
  const config = {
    angle: 90,
    spread: "158",
    startVelocity: 40,
    elementCount: 80,
    dragFriction: 0.12,
    duration: 3000,
    stagger: "4",
    width: "10px",
    height: "10px",
    perspective: "100vw",
    colors: ["#cb0000", "#afcfaf", "#ffaa00"],
  };
  return <Confetti className={"confetti"} active={props.showConfetti} config={config} />;
};
export default BlastConfetti;
