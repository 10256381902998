import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';

const AudioPlay = ({ file, play }) => {
  const audioRef = useRef()
  const buttonRef = useRef()
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.value = play
    inputRef.current.focus()
    inputRef.current.dispatchEvent(new Event('input', {
      view: window,
      bubbles: true,
      cancelable: true
    }))
    setTimeout(() => buttonRef.current.click(), 500)
  }, [play])

  const playSound = () => {
    try {
      audioRef.current.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture")
      audioRef.current.play()
    } catch (error) {
      audioRef.current.muted = true
      audioRef.current.play()
    }
  }

  return (
    <div style={{ display: 'none' }}>
      <audio controls ref={audioRef}>
        <source src={file} type="audio/mpeg" />
      </audio>
      <input type="text" ref={inputRef} />
      <button ref={buttonRef} onClick={playSound}></button>
    </div>
  )
}

export default AudioPlay