import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import makeStyles from "./makeStyles.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelmetComponent from "../../../common/HelmetComponent.component";

import QueryString from "../../../common/util/QueryString";

const styles = (theme) => ({
  ...makeStyles,
});
class AboutUs extends Component {
  render() {
    const {classes} = this.props;
    return (
      <div className={classes.bodyJustify}>
        <HelmetComponent
          title="Terms & Conditions - Findastro"
          url="https://www.findastro.com/terms-and-conditions"
          description="Terms & Conditions at Findastro-Connect to your Favorite Astrologer at Findastro"
        />
        <div className={classes.sidebarScreensTopArea}>
          {this.props.location.search && !QueryString().isRegister ? (
            <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={this.props.history.goBack} />
          ) : (
            <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={this.props.history.goBack} />
          )}
          <div className={classes.sidebarScreensChildTopArea}>
            <p>Terms & Conditions</p>
          </div>
        </div>
        <div style={{paddingTop: "32px"}}>
          <p>
            YOU MUST CAREFULLY READ AND UNDERSTAND THESE TERMS AND CONDITIONS (hereinafter referred as ‘Terms of Usage’)
            before accessing or availing any service, paid or free offered by Astro-Vision Futuretech Private Limited
            through www. findastro.com (hereinafter referred as “We” “Findastro” “us” “our” “Findastro application”
            “Website” or Astro-vision).
          </p>
          <h3>TERMS and Conditions</h3>
          <h4>1. Nature and Content</h4>
          <p>
            1.1 www.findastro.com is a web portal, owned, maintained and managed by Asto-Vision Futuretech Pvt Ltd ,
            (hereinafter referred as “We” “Findastro” “us” “our” “Findastro application” “Website” or Astro-vision ).
            The webportal acts as an intermediary platform between astrologers and users , wherein the users can avail
            various astrological services including astrological reports, data or personal advice through telephone,
            video chat and email consultations from astrologers of their choice, as provided in the website subject to
            the terms and conditions.
          </p>
          <h4>2. BINDING NATURE</h4>
          <p>
            2.1 This terms and conditions (hereinafter referred to as "terms of usage) as well as the privacy policy and
            other conditions , describe and govern the use of the content by the user and services offered by
            Astro-Vision Futuretech Pvt. Ltd. through www.findastro.com (hereinafter referred as “We” “Findastro” “us”
            “our” “Findastro application” “Website”).
          </p>
          <p>
            2.2 For the purpose of the Terms of usage and wherever the context so requires, the terms "you" and "your"
            shall mean any person who access the website or avail the services provided therein with payment or without
            payment . By accessing the Website and using it, you (“Member”, “You”, “Your”) indicate that you understand
            the terms and unconditionally & expressly consent to the Terms of Usage of this Website. If you do not agree
            with the Terms of Usage, please do not click on the “I AGREE” button. The User is advised to read the Terms
            of Usage carefully before using or registering on the Website or accessing any material, information or
            services through the Website. Your use and continued usage of the Website (irrespective of the amendments
            made from time to time) shall signify your acceptance of the terms of usage and your agreement to be legally
            bound by the same.
          </p>
          <p>
            2.3 Astro-Vision has the absolute right to update/amend/modify these Terms of Usage from time to time,
            without any communication to any one. The User is responsible to check the Terms of Usage periodically to
            remain in compliance with these terms. The ignorance of updation will not be treated as a ground for
            absolving any liability created by the updation.
          </p>
          <h4>3. PROCEDURE AND CONDITIONS FOR REGISTRATION </h4>
          <p>
            3.1 The website mandates compulsory registration as member for availing paid services and in the process of
            registration as Member, the user shall provide personal information including name, age, fathers name ,
            residential address, present contract address, email address, contact number, both mobile and Land line,
            birth time and birth sign and other information as required from time to time(hereinafter referred as
            ‘Registration Data” )and update the same in case of any change in respect of the above information.
          </p>
          <p>
            3.2 In order to register as a member, the you shall be person competent to contract who has completed 18
            years of age and is of sound mind and who are not disqualified or declared incompetent under the Indian
            Contract Act, 1872.If You are a minor i.e. under the age of 18 years, or legally incompetent to enter into a
            contract, You can only register only through legal guardian or parents . Astro-Vision reserves the right to
            terminate such use and/ or refuse to provide You with access to the website if it is brought its notice that
            the user is not legally competent or not properly represented by legal guardian.The Website would not be
            held responsible for any misuse that may occur by virtue of any person including a minor using the services
            provided through the Website.
          </p>
          <p>
            3.3 The right to use this Website is personal to the User and is not transferable to any other person or
            entity. The User would be responsible for protecting the confidentiality of User’s passwords and other
            information required for the purposes of registration. The User would be fully responsible for all the
            activities that occur under the User’s account with the Website. Astro-Vision cannot and will not be liable
            for any loss or damage arising from the User’s failure to maintain secrecy and confidentiality. The User
            shall notify the Website immediately if they become aware of any unauthorized use of their Account(s) or
            breach of any security. The User must log out from its account at the end of the session.
          </p>
          <p>
            3.4 The User while availing any service shall be informed whether the service so rendered is personal to the
            Website or is available from a Third party. The Website shall have no control or monitoring on the
            information disseminated to any third party via the Website.
          </p>
          <p>
            3.5 The Website does not permit the use of the Services by any User, If the User is a resident of any
            jurisdiction that may prohibit the use of the Services rendered by the Website.
          </p>
          <p>
            3.6 On availing the service of Call with Astrologer, you hereby grant the unconditional consent to the
            Astro-vision to arrange a call with you on your mobile number even though your number is on DND service
            provided by your mobile service provider.
          </p>
          <h4>4. Representations and Warranties by the user. </h4>
          <p>
            4.1 You hereby represents and warrants that, you are an individual and not a company/entity , above 18 years
            of age and have sound mind enabling you to understand and take rational judgments and not disqualified or
            declared incompetent under the Indian Contract Act, 1872.
          </p>
          <p>
            4.2 You hereby represents and warrants that all the information provided are current, accurate and complete
            and that the you will maintain the accuracy and keep the information updated from time to time. If in case
            it is found that the information so supplied on the Website is inaccurate, incomplete, untrue and not
            current, the Astro-Vision has the right to suspend or terminate the User’s account and restrict/refuse the
            use of the Website by such User in future. The Astro-vision shall have access to the account and the
            information created by the User for ensuring and maintaining the high-quality services provided by the
            Website and for addressing the need of the customer in the most effective manner. User hereby consents for
            the unconditional access of the account by the Astro-vision , its employees, agents and other appointed
            person in such regard. For the purpose of addressing the complaints (if any received) and any suspected
            abuse reported, the Website shall investigate on case-to-case basis from the records available. The User is
            directed to read the terms provided in the Privacy Policy as regards such records.
          </p>
          <p>
            4.3 You also represents and warrants that you maintain only a single account with the website and do not
            have multiple accounts using different phone numbers or different address. You hereby agrees that,
            maintaining multiple accounts using different Phone numbers or different addresses are strictly prohibited
            by the terms of usage and your use can be suspended/terminated with immediate effect without any prior
            notice.
          </p>
          <p>
            4.4 You also represents and warrants that you are competent to understand and evaluate the competency of
            astrologers ( service providers) to make a choice among them and the said decision is absolutely based on
            your judgement and Astro-Vision do not have any role in the process of decision making.
          </p>
          <p>
            4.5 You also represents and warrants that, debit/credit card details or account/bank details provided by you
            for effecting the payment for the services availed must be correct and accurate and that you will not use a
            debit/ credit card, that is not lawfully owned by you or the use of which is not authorized by the lawful
            owner thereof. You also represents and warrants that, in case of online payment, you are making the payment
            through the bank account maintained in your name or by any other account by the respective owner of the said
            bank account.
          </p>
          <p>
            4.6 You also represents and warrants that sufficient credit is available on the nominated card/ bank account
            at the time of making the payment to permit the payment of the dues payable or the bill(s) selected by you
            inclusive of the applicable Fee.
          </p>
          <p>
            4.7 You hereby represents and acknowledges that , on violation of any terms of usage, Astro-vision will have
            the absolute discretion to suspend/ terminate the registration issued to you without any prior notice and
            the decision of Astro-Vision will be final in this regard.
          </p>
          <h4>5. Obligations of the user </h4>
          <p>
            5.1 The User is under a strict obligation to read and understand the ‘Terms of Usage’, Privacy Policy and
            other terms and conditions of the website and clearly undertake not to violate the privacy policy, terms and
            conditions and any other terms as defined on the Website.
          </p>
          <p>
            5.2 The User undertakes that, registration as members using, false data or fake identity or accessing the
            website through another members account is absolutely prohibited by the Terms of Usage and Astro-Vision will
            have the absolute right to suspend/terminate such user from membership . More over, it will also have the
            right to initiate legal action under the Information Technology Act,2000, as the acts of the above nature,
            tantamount to offences provided under the Information Technology Act, 2000 Act or any other legislation
            governing such acts.
          </p>
          <p>
            5.3 The user shall make full disclosure about the emotional, mental and physical state , enabling the
            astrologers to make an informed judgement while giving advice.
          </p>
          <p>
            5.4 The User shall not Post, publish or transmit any messages that is false, misleading, defamatory,
            harmful, threatening, abusive, harassing, defamatory, invades another's privacy, offensive, promotes racism,
            hatred or harm against any individual or group or religion or caste, vulgar, obscene, profane or otherwise
            objectionable, which encourages conduct that would constitute a criminal offence, give rise to civil
            liability or otherwise violate any law or infringes another's rights including any intellectual property
            rights or copyright or trademark, violates or encourages any conduct that would violate any applicable law
            or regulation or would give rise to civil or criminal liability.
          </p>
          <p>
            5.5 The user while communicating with astrologers/service providers shall not use any abusive, vulgar,
            obscene or objectionable languages and maintain decency and integrity in all the communications.
          </p>
          <p>
            5.6 The user shall not directly contact or communicate with astrologer/Service provider . While
            communicating with the astrologer, User shall not reveal any personal data including, name, address , photos
            or contact numbers with them, enabling him to identify the identity, which can be used against the interests
            of the user.
          </p>
          <p>
            5.7 The user shall inform Astro-vision at the first instance about any misbehavior or any attempt made by
            the astrologer to misuse of any personal information inadvertently shared by the user with the astrologer so
            as to take immediate steps to stop further misuse by the Astrologer.
          </p>
          <p>
            5.8 The User shall not collect screen names and email addresses of members who are registered on the Website
            for purposes of advertisement, solicitation or spam.
          </p>
          <p>
            5.9 The User shall not send unsolicited email, junk mail, spam, or chain letters, or promotions or
            advertisements for products or services.
          </p>
          <p>
            5.10 The User shall not upload, distribute , introduce any files that contain viruses, corrupted files, or
            any other similar software or programs that may damage the operation of the Website or another’s computer.
          </p>
          <p>5.11 The User shall not engage in any activity that interferes with or disrupts access to the Website</p>
          <p>
            5.12 The User shall not attempt to gain unauthorized access to any portion or feature of the Website, any
            other systems or networks connected to the Website, to any of the services offered on or through the
            Website, by hacking, password mining or any other illegitimate means.
          </p>
          <p>
            5.13 The User shall not violate any applicable laws or regulations for the time being in force within or
            outside India. The use and continuous use of the Website is subject to but not limited to using the services
            for personal use.
          </p>
          <p>
            5.14 The User shall not resell or make any commercial use of the Services without the express written
            consent from the Website.
          </p>
          <p>
            5.15 The User shall not violate these Terms of Usage including but not limited to any applicable Additional
            terms of the Website contained herein or elsewhere.
          </p>
          <p>
            5.16 The User shall not Reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce,
            publish, license, create derivative works from, transfer, or sell any information, data, pictures, or
            software obtained from the Website.
          </p>
          <p>5.17 The User shall not interfere with any other members' use or enjoyment of the Website or Services.</p>
          <p>
            5.18 The User is under an obligation to report any misuse or abuse of the Site. If you notice any abuse or
            misuse of the Site or anything which is in violation of this Agreement, you shall forthwith report such
            violation to Website by writing to Customer Care. On receipt of such complaint, Website may investigate such
            complaint and if necessary, may terminate the membership of the Member responsible for such violation abuse
            or misuse without any refund of the subscription fee. Any false complaint made by a Member shall make such
            Member liable for termination of his / her membership without any refund of the subscription fee.
          </p>
          <p>
            5.19 The User is under an obligation to provide correct and accurate banking information including the
            debit/card, bank account details as and when required for effecting the payment for availing the services
            from the website. The User should not initiate the payment through any account, debit card/credit card that
            is not lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The
            user will be liable for criminal action for misrepresentation and cheating if the user makes the payment
            against the obligations stipulated above and will be liable to make good the loss caused to Astro-vision by
            the illegal action.
          </p>
          <p>
            5.20 The User is also under an obligation to ensure sufficient credit in nominated card/ bank account at the
            time of making the payment to permit the payment of the dues payable or the bill(s) selected by the User
            inclusive of the applicable Fee. User will be liable to compensate Astro-Vision for the loss suffered due to
            the non compliance of the above stipulation.
          </p>
          <p>
            5.21 The Website reserves the right to withdraw its services to any customer who is found to be unreasonable
            or abusive during their conversation with the Service Provider inclusive of astrologer regardless of any
            reason.
          </p>
          <p>
            5.22 While the Website shall take all steps to resolve any situation that is in violation of the above
            obligations arises, however if the situation is not controllable, the Website reserves its right to send a
            written warning henceforth. Such violations, if repeated by the User, shall lead to a total ban for
            transacting on the platform by such User. If any balance is present in the wallet of the User, the same
            shall be refunded subject to the other charges that may be applicable for such violations.
          </p>
          <h4>6. LIMITATION OF LIABILITY</h4>
          <p>
            6.1 Astro-vision states that the website www.findastro.com is an intermediary web portal managed by them for
            facilitating the interactive service between astrologers and users. It warrants that, it has taken all
            reasonable efforts to ensure and verify the competency of astrologers . But astrological counselling
            provided through the Website is based on cumulative or subjective individual knowledge, experience and
            varied astrological tools and interpretations of astrologers and as such, it may vary from one astrologer to
            another and Astro-vision will not have any control over the same. Such recommendations are being made in
            good faith by the astrologers and Astro-vision and its subsidiaries, affiliates, officers, employees,
            agents, partners, and licensors make no warranty that :
          </p>
          <p>6.1.1 the service will meet your requirements</p>

          <p>6.1.2 the service will be uninterrupted, timely, secure or error-free</p>

          <p>
            6.1.3 the results that may be obtained from the use of the service will be accurate or reliable or will
            provide a solution to any problems you have sought for
          </p>
          <p>6.1.4 the quality of a services, advice or other remedial measures will meet your expectations and</p>
          <p>
            6.1.5 the website and the contents of the materials including the software us error free or and will
            function fool proof without causing any damage to the user.
          </p>
          <p>
            6.2 Astro-vision will not be liable for any action, which was initiated as per the advice or information,
            whether oral or written, obtained by the User from the Website or through or from use of the services shall
            create any warranty not expressly stated in the terms of use.The Website does not take guarantee of any
            untoward incident that may happen with the User after seeking the Service. The Website or the Service
            Provider providing the advice is not liable and does not guarantee any results as expected by the User and
            accessing the Website in such scenario is purely at the risk of the User.
          </p>
          <p>
            6.3 To the maximum extent permitted by applicable law, the Website will have no liability related to User
            content arising under intellectual property rights, libel, privacy, publicity, obscenity or other laws. The
            Website also disclaims all liability with respect to the misuse, loss, modification or unavailability of any
            User content.
          </p>
          <p>
            6.4 The Website will not be liable for any loss that the User may incur as a consequence of unauthorized use
            of their account or account information in connection with the Website or any services or materials, either
            with or without the User’s knowledge. The Website has endeavored to ensure that all the information on the
            Website is correct, but the Website neither warrants nor makes any representations regarding the quality,
            accuracy or completeness of any data, information, product or service. The Website shall not be responsible
            for the delay or inability to use the Website or related functionalities, the provision of or failure to
            provide functionalities, or for any information, software, products, functionalities and related graphics
            obtained through the Website, or otherwise arising out of the use of the Website, whether based on contract,
            tort, negligence, strict liability or otherwise. further, the Website shall not be held responsible for
            non-availability of the Website during periodic maintenance operations or any unplanned suspension of access
            to the Website that may occur due to technical reasons or for any reason beyond the Website's control.
          </p>
          <p>
            6.5 The User understands and agrees that any material or data downloaded or otherwise obtained through the
            Website is done entirely at their own discretion and risk and they will be solely responsible for any damage
            to their computer systems or loss of data that results from the download of such material or data. The
            astro-vision has taken reasonable efforts to be error free in respect of the website. The Website accepts no
            liability for any errors or omissions of any sort including typographical or other wise, with respect to any
            information/data/communication provided to you whether on behalf of itself or third parties.
          </p>
          <p>
            6.6 The services may consist of the following, without limitation: Astrological content, Reports, Tarot
            readings, fortunes, numerology, predictions, live telephone consultations, email consultations or products
            provided through the website. Findastro charges for the chat/call service offered on this platform on per
            minute basis and holds no responsibility or liability about the reality or reliability of the astrological
            effects in any manner or any other products or services represented and sold on the website. No advice or
            information, whether oral or written, obtained by you shall create any warranty.
          </p>
          <p>
            6.7 Astrovision has taken all reasonable efforts to maintain the privacy and security of details of debit
            card/credit card details , account details or other personal details information shared by the user for
            making the online payment for the services rendered through the website. The astro-vision will not be liable
            for any data theft of these information by any third party through illegal means circumventing the security
            measures adopted by Astro-vision.
          </p>

          <p>
            6.8 The advisors/consultants/astrologers are also members of the site and not employees of the Website or
            the company. However, the Astro-vision takes reasonable efforts to verify and ensure the competence by
            verifying the degrees, qualifications, credentials, and background of the advisors/consultants/astrologers
            but does not refer, endorse, recommend, verify, evaluate or guarantee any advice, information or other
            services provided by the advisors/consultants/astrologers or by the company, nor does it warrant the
            validity, accuracy, completeness, safety, legality, quality, or applicability of the content, anything said
            or written by, or any advice provided by the advisors/consultants/astrologers.
          </p>
          <p>
            6.9 The website is not a suicide helpline platform. If you are considering or contemplating suicide or feel
            that you are a danger to yourself or to others, you may discontinue use of the services immediately at your
            discretion and please notify appropriate police or emergency medical personnel. If you are thinking about
            suicide, immediately call a suicide prevention helpline such as AASRA (91-22-27546669).
          </p>
          <p>
            6.10 Astro-Vision do not share any personal information of the user including full name, address and contact
            address with the service provider, which enable him to identify the user. Astro-vision will not be
            responsible for any abuse of personal information shared by the user with the adtrologer/service provider
            while interacting directly with him.
          </p>
          <p>
            6.11 The Website shall not be liable for any inaccuracy, error or delay in, or omission of (a) any data,
            information or message, or (b) the transmission or delivery of any such data, information or message; or (c)
            any loss or damage arising from or occasioned by any such inaccuracy, error, delay or omission,
            non-performance or interruption in any such data, information or message. Under no circumstances shall the
            Website and/or the payment service providers, its employees, directors, and its third party agents involved
            in processing, delivering or managing the services, be liable for any direct, indirect, incidental, special
            or consequential damages, or any damages whatsoever, including punitive or exemplary arising out of or in
            any way connected with the provision of or any inadequacy or deficiency in the provision of the services or
            resulting from unauthorized access or alteration of transmissions of data or arising from suspension or
            termination of the services.
          </p>
          <p>
            6.12 Notwithstanding anything to the contrary contained herein, liability of findastro to you for any cause
            whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if
            any, by you to the Website, for the service during the term of membership.
          </p>
          <p>
            6.13 By using the Site, Application or Services, User hereby agrees that any legal remedy or liability that
            you seek to obtain for actions or omissions of other Members inclusive of the service provider registered
            with the Website or other third parties linked with the Website, shall be limited to claim against such
            particular party who may have caused any harm. You agree not to attempt to impose liability on or seek any
            legal remedy from the Website with respect to such actions or omissions.
          </p>
          <h4>7. INDEMNITY</h4>
          <p>
            7.1 The User shall indemnify, defend and hold harmless the ASTROVISION and its parent, subsidiaries,
            affiliates, officers, directors, employees, suppliers, consultants and agents from any and all third party
            claims, liability, damages and/or costs (including, but not limited to, attorney’s fees) arising from Your
            use of the Services, Your violation of the Privacy Policy or these Terms of Service, or Your violation of
            any third party's rights, including without limitation, infringement by You or any other user of Your
            account of any intellectual property or other right of any person or entity. These Terms of Service will
            inure to the benefit of Website’s successors, assigns, and licensees.
          </p>
          <h4>8.THIRD PARTY CONTENTS:</h4>
          <p>
            8.1 It is strictly clarified that, the website may provide access to Third Party Contents including
            advertisements an online promotions and links to third party websites that are not owned, controlled or
            endorse by Astro-vision. Astro-vision is not responsible for any third-party contents, applications,
            services, advertisements, and/or links that may be contained in the website.
          </p>
          <p>
            8.2 The information including but not limited to Third Party Contents and other Contents in the website are
            made available on the understanding that the Astro-vision and their respective employees and agents shall
            have no liability (including liability by . reason of negligence) to the users for any loss, damage, cost or
            expense incurred or arising by reason of any person using or relying on the information including but not
            limited to Third Party Contents and other Contents on the website and whether caused by reason of any error,
            negligent act, omission or misrepresentation in the information including but not limited to Third Party
            Contents and other Contents on the website or otherwise.
          </p>
          <p>
            8.3 Astro-vision takes no responsibility for the accuracy, reliability correctness of the information
            including but not limited to Third Party Contents and other Contents on the website or for the accuracy,
            reliability correctness and currency of links or references to information sources including but not limited
            to websites or internet sites outside of the findastro. Links to other websites or internet sites are not
            under the control of Astro-Vision and are provided for information only. Astro-vision has not reviewed or
            approved any content that appears on the linked websites.
          </p>
          <h4>9. REFUND POLICY</h4>
          <p>
            Any service chargeable rendered by you (customer) through the Findastro platform is chargeable. It is
            further agreed that if any complaint is being received from any user/customer seeking a refund of the amount
            paid on the website/app, the complaint shall be subject to review from the Administrator/Compliance Team. If
            the complaint is found genuine, the refund shall be processed accordingly either in full or partial. The
            refund against a consultation will be deducted from the astrologer fees settlement without seeking prior
            approval. It is agreed between the parties that the decision taken by Findastro shall be final with regard
            to any refund.
          </p>
          <h4>10. PROPRIETARY RIGHTS IN CONTENTS:</h4>
          <p>
            10.1 User hereby agree, undertake and confirm that all right, title and interest in the website including
            text, software, music, sound, photographs, video, graphics or other material contained in the website or
            distributed via email, commercially produced information presented to Member by the Website, its suppliers,
            and/or advertisers, are vested with Astro-vision. User(s) hereby confirm that this Agreement does not amount
            to transfer of any proprietary interest by Astro-vision to the User, and that all right, title, and interest
            in the property continues to vest with Astro-vision.
          </p>
          <h4>11. INTELLECTUAL PROPERTY:</h4>
          <p>
            11.1 The copyright, trademark, patent or other intellectual property rights in the contents of the Website
            (including, without limitation, all designs, logos, names, text, sound recordings, music, images, graphics,
            video and links) are owned by Astro-Vision or the respective third party entities as identified on the
            website. No license or right is granted and use of the Website by User(s) therein shall not constitute by
            implication, estoppel or otherwise, any license or right of use of the Intellectual property right over the
            App. As such, User shall not reproduce, transmit, republish, upload, post, perform, broadcast, adapt,
            parody, distribute, display, license and/or alter in whole or in part any of the foregoing in any manner
            without the express permission of Astro-Vision.
          </p>
          <p>
            11.2 User shall not download or encourage others to download copyrighted works, trademarks, or other
            proprietary information without obtaining the prior written consent of the owner of the Intellectual
            Property. In the event of infringement, ASTRO-VISION may on its own sole discretion to take necessary steps
            to remedy the same.
          </p>
          <h4>12. LAW, JURISDICTION AND ARBITRATION:</h4>
          <p>
            12.1 The construction of these Terms Of usage shall be determined in accordance with laws and rules in force
            in India and shall be subject to the exclusive jurisdiction of the Courts in Ernakulam and no other court.
          </p>
          <p>
            12.2 All disputes arising under or in relation to the Terms shall be referred to arbitration before a sole
            arbitrator. If the Parties fail to agree on the appointment of a sole arbitrator within the time stipulated
            under the [Indian] Arbitration and Conciliation Act, 1996 (the "Act") the Parties shall approach the
            competent Court under the Act for appointment of the Sole Arbitrator. The Arbitration proceedings shall be
            carried out in accordance with the Act and the Rules framed there under and the place of Arbitration shall
            be Ernakulam The arbitration proceedings shall be conducted in English. The arbitrator's award shall be
            final and undisputable by both Parties.
          </p>
          <h4>13. SEVERABILITY: </h4>
          <p>
            13.1 If any provision in the Terms become invalid or illegal or adjudged unenforceable, the provision shall
            be deemed to have been severed from the Terms and the remaining provisions of the Terms shall not, so far as
            possible, be affected by the severance.
          </p>
          <h4>14. WAIVER:</h4>
          <p>
            14.1 The failure of Astro-vision to enforce at any time any of the provisions of this Terms shall not be
            construed to be a waiver of its right, power, privilege or remedy or as a waiver of any preceding or
            succeeding breach on Your part to this Terms nor shall any single or partial exercise of any right power
            privilege or remedy preclude any other or further exercise of such or any other right power privilege or
            remedy provided in this Terms all of which are several and cumulative and are not exclusive of each other or
            of any other rights or remedies otherwise available to Astro-Vision at law or in equity.
          </p>
          <h4>15. FORCE MAJEURE AND ACT OF THIRD PARTIES:</h4>
          <p>
            15.1 The performance of any part of this Terms by Astro-Vision shall be excused to the extent that such
            performance is hindered, delayed, or made impractical by flood, fire, war, or riot or any other cause beyond
            the reasonable control of Astro-Vision or act of any third party beyond the control of Astro-vision
            including but not limited to hacking, data theft, unauthorised access to Your account, impersonation, fraud,
            misrepresentation and so on.
          </p>
          <h4>16. MODIFICATION:</h4>
          <p>
            16.1 Astro-Vision can update, modify, suspend, discontinue or change any part of these Terms from time to
            time The revised Terms will be posted as notification here on this
            <a href="/terms-and-conditions" target="_blank">
              link
            </a>
            . Users are encouraged to periodically check this page to stay informed about changes to the Terms. The
            revised Terms will be effective from the date stated on the revised Terms. You acknowledge and agree that it
            is Your responsibility to review these Terms periodically and become aware of modifications. If You disagree
            to any of the changes to the Terms, please refrain from accessing or using the website. Your continued
            access or use or availing of the services following the posting of revised Terms will indicate Your
            acceptance and acknowledgement of the changes and You will be bound by it.
          </p>
          <h4>17. SURVIVAL:</h4>
          <p>
            17.1 These Terms, and any modifications, alterations or amendments to, shall remain in full force and effect
            while You use the website and the provisions related to Intellectual Property, Warranties and Indemnity
            shall continue to apply even after termination of this Agreement.
          </p>
          <br />
          <br />
          <p>
            This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as
            applicable and the amended provisions pertaining to electronic records in various statutes as amended by the
            Information Technology Act, 2000. This electronic record is generated by a computer system and does not
            require any physical or digital signatures.
          </p>
          <p>
            This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology
            (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, Privacy Policy
            and Terms for access or usage of the website.
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(AboutUs));
