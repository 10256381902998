import { getUA, isMobile } from "react-device-detect";

const BrowserUtils = {
  isiOSDevice: isMobile && getUA.indexOf("iPhone") >= 0,
  isAWebview:
    !isMobile ||
    getUA.indexOf("Android") >= 0 ||
    getUA.indexOf("iPhone") >= 0 ||
    getUA.indexOf("fa-wv") >= 0 ||
    getUA.match(/iPhone/) !== null ||
    getUA.match(/Version\/\d*\.?\d*\s|Version\/\d*\.?\d*$/) !== null,
  /*true,*/
  GooglePlaySupport: getUA.indexOf("Android") >= 0,
  encodeFA: (str) => {
    return btoa(str);
  },
  decodeFA: (str) => {
    return atob(str);
  },
  isFindAstroWebview: getUA.indexOf("fa-wv") >= 0,
  CurrentUser: function () {
    let usr_age = localStorage["dob"]
      ? new Date().getFullYear() - parseInt(localStorage["dob"].split("/")[2]) || "NA"
      : "NA";
    let usr_gender = localStorage["gender"] ? localStorage["gender"].toLowerCase() : "NA";
    return {
      age: usr_age,
      gender: usr_gender,
      phone: localStorage["phone"],
    };
  },
  CurrentUserInfo: function () {
    let usr_name = (localStorage["name"] && localStorage["name"] !== 'null') ? localStorage["name"] : "NA";
    let usr_id = localStorage["userid"] ? parseInt(localStorage["userid"].split('-').at(-1)) || 0 : 0;
    const user_type = localStorage["userStatus"] ? localStorage["userStatus"] : (usr_id > 0 ? "Registered" : "Guest");
    const paidStatus = (localStorage["CAPaid"] && parseInt(localStorage["CAPaid"]) > 0) ? 'Yes' : 'No';
    return {
      name: usr_name,
      userType: user_type,
      paidStatus: paidStatus,
      id: usr_id
    };
  },
  codePhone: function (phone, type) {
    if (phone) {
      var hash = "ALMRUDNBXKSEYZCHIJFGVTOPQW".split("");
      var result = "";
      var digits = phone.split("");

      if (type === 1) {
        digits.forEach(function (d, i) {
          var x = (parseInt(d) || 1000) + i;
          var char = hash[x] || d;
          result += char;
        });
      } else {
        digits.forEach(function (s, i) {
          var d = hash.indexOf(s) - i;
          result += d < 0 ? s : d;
        });
      }

      return result;
    } else return "NA";
  },
  isFAApp: function () {
    return window.AndroidBridge && window.AndroidBridge.call("getAppVersion").indexOf("3.0.") === 0;
  },
  isLoggedIn: () => {
    return ((!localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined") === false) && window.isAppProfileDataHasEmail()
  },
  isFALoggedIn: () => {
    return ((!localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined") === false)
  },
  hasUserProfile: () => {
    const name = localStorage.getItem('name') !== null ? localStorage.getItem('name') : '';
    const email = localStorage.getItem('email') !== null ? localStorage.getItem('email') : '';
    if (name.length > 1
      && email.length > 1 && !email.includes('@email.com')
    ) {
      return true
    } else {
      return false
    }
  },
  isNewUI: () => localStorage.getItem('strict_app_home') || window.location.pathname.startsWith('/app-home'),
  isLiveURL: () => window.location.href.includes('www.findastro.com'),
  isDevURL: () => window.location.href.includes('dev.findastro.co'),
  isLocalhost: () => window.location.href.includes('://localhost'),
  isDevMode: () => localStorage.getItem('dev_mode') === '1',
  devAlert: (d) => {
    if (!BrowserUtils.isLiveURL() && BrowserUtils.isDevMode()) {
      alert(d)
    }

    console.groupCollapsed('%c DevAlert:', 'color: #2196F3', d)
    console.trace()
    console.groupEnd()
  },
  devConsole: (...log) => {
    if (!BrowserUtils.isLiveURL() && BrowserUtils.isDevMode()) {
      console.groupCollapsed('%c DevConsole:', 'color: #2196F3')
      console.log(...log)
      console.trace()
      console.groupEnd()
    }
  },
  getUsedLocalStorageSpace: () => {
    return Object.keys(window.localStorage).map(function (key) { return localStorage[key].length; }).reduce(function (a, b) { return a + b; });
  },
  isLocalSpaceEnough: () => {
    return BrowserUtils.getUsedLocalStorageSpace() < 3000000;
  },
  getCachedKey: (key, fallbackValue) => {
    try {
      const data = localStorage.getItem(key);
      if (data === null) {
        return fallbackValue;
      } else {
        return data;
      }
    } catch (e) {
      return fallbackValue;
    }
  },
  setCachedKey: (key, value) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (e) {
      return false;
    }
  },
  removeCachedKey(key) {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  },
  getLogStatus: () => {
    return BrowserUtils.getCachedKey('cotDebug', false) ? true : false;
  },
  setLogStatus: (status) => {
    if (status) {
      BrowserUtils.setCachedKey('cotDebug', true);
    } else {
      BrowserUtils.removeCachedKey('cotDebug');
    }
  },
  setLogData: (key, value) => {
    if (!BrowserUtils.getLogStatus()) {
      return false;
    }
    let newData = {};
    if (value) {
      if (BrowserUtils.isLocalSpaceEnough) {
        newData = BrowserUtils.getLogData();
      }
      if (typeof value === 'object') {
        value.time = Date.now()
      }
      if (newData.hasOwnProperty(key)) {
        newData[key].push(value)
      } else {
        newData[key] = [value]
      }
      BrowserUtils.setCachedKey('cotLog', JSON.stringify(newData));
    } else {
      newData = BrowserUtils.getLogData();
      if (newData.hasOwnProperty(key)) {
        delete newData[key]
        if (newData.length > 0) {
          BrowserUtils.setCachedKey('cotLog', JSON.stringify(newData));
        } else {
          BrowserUtils.removeCachedKey('cotLog');
        }
      } else {
        BrowserUtils.removeCachedKey('cotLog');
      }
    }
    return true;
  },
  getLogData: () => {
    const cotLog = BrowserUtils.getCachedKey('cotLog', '{}');
    console.log(cotLog)
    try {
      return JSON.parse(cotLog);
    } catch (e) {
      return {};
    }
  },
  deleteLogData: () => {
    return BrowserUtils.removeCachedKey('cotLog');
  },
  isFreeConsultationFlow: () => {
    return localStorage.getItem('freeConsultationFlowEnded') === null
  },
  setFreeConsultationFlowEnded: v => {
    return localStorage.setItem('freeConsultationFlowEnded', v ? '1' : '')
  },
  isFreeConsultationTryAnotherAstrologer: () => {
    // return localStorage.getItem('freeConsultationTryAnotherAstrologer') === '1'
    return window.location.href.includes('fcm_try_another_astrologer=true')
  },
  deleteAllCacheTemp: async () => {
    // console.log("delete all init");
    // console.log("getall init");
    caches.keys().then((keys) => {
      // console.log("getall keys", keys);
      // console.log("delete all keys", keys);
      keys.forEach(async (key) => {
        // console.log("delete all each", key);
        // console.log("delete", key);
        caches.delete(key).then(() => {
          // console.log('Cache ' + key + ' successfully deleted!');
        })
      })
    })
  },
  clearLocalStorage: () => {
    if (window.Moengage) window.Moengage.destroy_session();
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("image");
    localStorage.removeItem("utkn");
    localStorage.removeItem("btkn");
    localStorage.removeItem("user_languages");
    localStorage.removeItem("walletBalance");
    localStorage.removeItem("rate_us");
    localStorage.removeItem("progress_call");
    localStorage.removeItem("gender");
    localStorage.removeItem("dob");
    if (localStorage.getItem('userid')) {
      localStorage.removeItem('userid');
    }
    if (localStorage.getItem('userType')) {
      localStorage.removeItem('userType');
    }
    if (localStorage.getItem('lastActiveConsultation')) {
      localStorage.removeItem('lastActiveConsultation');
    }
    if (localStorage.getItem('lastCallData')) {
      localStorage.removeItem('lastCallData');
    }
    if (localStorage.getItem('CAPaid')) {
      localStorage.removeItem('CAPaid');
    }
    //remove local crj if logged in
    localStorage.removeItem("userCRJ");
    localStorage.removeItem("sunsignData");
    localStorage.removeItem("moonsignData");
    localStorage.removeItem("dailyPrediciton");
    //remove local crj if logged in
    localStorage.removeItem("debug");

    // Remove fcm(free chat minute ids)
    Object.entries(localStorage).forEach(([key, value]) => {
      if (key.substr(0, 4) === 'fcm_') {
        localStorage.removeItem(key)
      } else if (key.length > 9 && key.substr(0, 9) === 'cnslt_id_') {
        localStorage.removeItem(key)
      }
    })

    // Remove freeConsultationFlowEnded
    if (localStorage.getItem('freeConsultationFlowEnded')) {
      localStorage.removeItem('freeConsultationFlowEnded')
    }
    if (localStorage.getItem('freeConsultationTryAnotherAstrologer')) {
      localStorage.removeItem('freeConsultationTryAnotherAstrologer')
    }
    if (localStorage.getItem('freeConsultationFlow')) {
      localStorage.removeItem('freeConsultationFlow')
    }

    if (localStorage.getItem('lastConsultData')) {
      localStorage.removeItem('lastConsultData');
    }
  },
  logout: async () => {
    BrowserUtils.clearLocalStorage();
    if (typeof window.AndroidBridge !== "undefined") {
      window.AndroidBridge.call("logoutUser");
    }
    await BrowserUtils.deleteAllCacheTemp();
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
  },
  deleteAccount: async () => {
    BrowserUtils.clearLocalStorage();
    if (typeof window.AndroidBridge !== "undefined") {
      window.AndroidBridge.call("deleteAccount");
    }
    await BrowserUtils.deleteAllCacheTemp();
    window.location.href = "/account-deleted";
  },
};

export default BrowserUtils;
