import React, { useEffect, useReducer, useState } from "react"
import { ChatWindowStateProvider, ChatWindowStateSetterProvider } from "../chat/ChatWindowContext"
import ChatNotificationTrigger from "../chat/ChatNotificationTrigger"
import Conversations from "../chat/Conversations"
import "./FreeConsultation.css"
import axios from "../../../../../common/interceptor"
import { initChat, notifyChat } from "../chat/ChatUtls"
import BrowserUtils from "../UAScripts"
import CountDownTimer from "../CountDownTimer"
import FA_localStorage from "../../../../../common/util/FA_localStorage"
import { MIN_BALANCE_MINS } from "../../../../../app.config"
import { Button, Dialog } from "@material-ui/core"
import FreeConsultWindow from "./FreeConsultWindow"
import { isMobile } from "react-device-detect"
import { CheckCircle } from "@material-ui/icons"
import moment from "moment/moment"
import userProfileData from "../../../../user-profile/mock/UserProfileData.mock"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

export const FCMessage = {
  create: (text, type = 'text') => {
    const msg = {
      time: Date.now(),
      text,
      type
    }
    return msg
  },
  createObj: (obj) => {
    const msg = {
      time: Date.now(),
      ...obj
    }
    return msg
  }
}

const initialState = {
  messages: [],
  phoneNumberVerified: BrowserUtils.isLoggedIn() ? true : false,
  birthDetailsConfirmed: false,
  showVerifyNumberComponent: false,
  showBirthDetailsComponent: false,
  showInsufficientBalanceComponent: false,
  showTryAnotherAstrologerComponent: false,
  inputText: false
}

const TIME_FORMAT = {
  FULL: 1,
  SHORT: 2
}

const timeFormat = (timestamp, format) => {
  if (format === 1) {
    const date = new Date(timestamp)
    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    return new Intl.DateTimeFormat('en-US', options).format(date)
  }
  if (format === 2) {
    const date = new Date(timestamp)
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    return new Intl.DateTimeFormat('en-US', options).format(date)
  }
  return ''
}
let lastTimestamp = 0
let lastTimestampAdded = 0
const getNewTimestamp = () => {
  let currentTimestamp = Date.now()
  lastTimestamp = currentTimestamp + (++lastTimestampAdded)
  return lastTimestamp
}

const chatReducer = (state, action) => {

  // Default extra object
  let extra = {
    highlight: false
  }

  if (action.extra) {
    extra = { ...extra, ...action.extra }
  }

  const time = getNewTimestamp()

  switch (action.type) {
    case 'BOT_MESSAGE':
      return {
        ...state, messages: [...state.messages, {
          text: action.text,
          type: 'bot',
          time,
          formattedTime: action.timeFormat ? timeFormat(time, action.timeFormat) : '',
          extra
        }]
      };
    case 'ASTROLOGER_MESSAGE':
      return {
        ...state, messages: [...state.messages, {
          text: action.text,
          type: 'astrologer',
          time,
          formattedTime: timeFormat(time, TIME_FORMAT.SHORT),
          extra
        }]
      };
    case 'USER_MESSAGE':
      return {
        ...state, messages: [...state.messages, {
          text: action.text,
          type: 'user',
          time: time,
          formattedTime: timeFormat(time, TIME_FORMAT.SHORT),
          extra
        }]
      };
    case 'VERIFY_PHONE_NUMBER':
      return { ...state, phoneNumberVerified: true, time: time };
    case 'BIRTH_DETAILS':
      return { ...state, birthDetailsConfirmed: true, time: time };
    case 'SHOW_VERIFY_NUMBER_COMPONENT':
      return { ...state, showVerifyNumberComponent: true, messages: [...state.messages, { text: '', type: 'SHOW_VERIFY_NUMBER_COMPONENT', time: time }] };
    case 'SHOW_BIRTH_DETAILS_COMPONENT':
      return { ...state, showBirthDetailsComponent: true, messages: [...state.messages, { text: '', type: 'SHOW_BIRTH_DETAILS_COMPONENT', time: time }] };
    case 'SHOW_ASTROLOGER_CARD':
      return { ...state, showAstrologerCard: true, messages: [...state.messages, { text: '', type: 'SHOW_ASTROLOGER_CARD', time: time }] };
    case 'SHOW_INSUFFICIENT_BALANCE':
      return { ...state, showInsufficientBalanceComponent: true, messages: [...state.messages, { text: '', type: 'SHOW_INSUFFICIENT_BALANCE', time: time, data: action.data }] };
    case 'SHOW_TRY_ANOTHER_ASTROLOGER':
      return { ...state, showTryAnotherAstrologerComponent: true, messages: [...state.messages, { text: '', type: 'SHOW_TRY_ANOTHER_ASTROLOGER', time: time, data: action.data }] };
    case 'SHOW_INPUT_TEXT':
      return { ...state, inputText: true, infoText: '' };
    case 'HIDE_INPUT_TEXT':
      return { ...state, inputText: false };
    case 'INFO':
      return { ...state, infoText: action.text || '', inputText: false };
    default:
      return state;
  }
};

const getLang = () => {
  let lang = 'english'
  if (localStorage.getItem('user_languages') !== null) {
    lang = localStorage.getItem('user_languages').toString().toLowerCase()
  }
  return lang
}

const FreeConsultationFlowData = () => {
  let freeConsultationFlow = { astrologer_exclusion: [] }
  if (localStorage.getItem('freeConsultationFlow')) {
    const data = JSON.parse(localStorage.getItem('freeConsultationFlow'))
    freeConsultationFlow = { ...freeConsultationFlow, ...data }
  }
  return freeConsultationFlow
}

const FreeConsultation = () => {
  const [chatWindowData, setChatWindowData] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [freeMessages, setFreeMessages] = useState([])
  const [freeMessageStatus, setFreeMessageStatus] = useState('initial')
  const [astrologer, setAstrologer] = useState('')
  const [user, setUser] = useState(false)
  const [state, dispatch] = useReducer(chatReducer, initialState)
  const [realChatConnected, setRealChatConnected] = useState(false)
  const history = useHistory()

  const connectRandomAstrologer = async () => {

    if (BrowserUtils.isLoggedIn()) {
      await getUpdatedProfileData()
    }

    axios()
      .post('/astrologers/all/ma', getAstrologerPostData())
      .then((res) => {
        console.log(res, res.data)
        if (res.data.success && res.data.data.length) {
          const ast = res.data.data.filter(a => {
            return (
              a.chargesChat
              // && a.id === 143 // TODO: testing set to 143
            )
          })[0]
          if (ast?.id) {
            ast.fullName =
              ast.salutation && ast.name.indexOf(ast.salutation) < 0
                ? [ast.salutation, ast.name].join(" ")
                : ast.name
            setAstrologer(ast)
            if (BrowserUtils.isFreeConsultationTryAnotherAstrologer() && BrowserUtils.isLoggedIn()) {
              dispatch({ type: 'SHOW_ASTROLOGER_CARD' })
              dispatch({ type: 'SHOW_TRY_ANOTHER_ASTROLOGER' })
              dispatch({ type: 'INFO', text: 'Please confirm your astrologer choice' })
            } else {
              setTimeout(() => {
                dispatch({ type: 'SHOW_ASTROLOGER_CARD' })
                dispatch({ type: 'BOT_MESSAGE', text: 'You may start consultation now', timeFormat: TIME_FORMAT.FULL })
                simulateAstrologerResponse('Hello', e => {
                  dispatch({ type: 'SHOW_INPUT_TEXT' });
                });
              }, 1000)
            }
          } else {
            console.error('[astrologers/all/ma]: No chat enabled astrologer is available')
          }
        } else {
          console.error('[astrologers/all/ma]: No astrologer is available')
        }
      })
      .catch((err) => {
      })
  }

  const isFreeChatEligible = () => {
    return new Promise((resolve, reject) => {
      axios()
        .post('/astrologers/all/ma', getAstrologerPostData())
        .then((res) => {
          let isFreeAvailable = false
          if (res.data.success && res.data.data.length) {
            const astrologers = res.data.data.filter(a => a.chargesChat)
            console.log('isFreeChatEligible: astrologers', astrologers)
            if (astrologers.length && astrologers[0].free_chat_minutes > 0) {
              isFreeAvailable = true
            }
          }
          if (!isFreeAvailable) {
            resolve(false)
          } else {
            resolve(true)
          }
        })
        .catch((err) => {
          reject(false)
        })
    });
  }

  const isActiveConsultation = () => {
    return new Promise((resolve, reject) => {
      axios()
        .post('/user/chat/list')
        .then((res) => {
          let isOngoingConsultation = false
          if (res && res.data.success) {
            if (res.data?.messages?.data[0] && res.data?.messages?.data[0]?.latest_chat) {
              const latest_chat = res.data?.messages?.data[0]?.latest_chat
              isOngoingConsultation = latest_chat.consultation.status !== "completed" && latest_chat.consultation.status !== "failed" && latest_chat.consultation.status !== "refunded"
              console.log('FreeConsultation: isActiveConsultation: ', {
                latest_chat,
                isOngoingConsultation
              })
            }
          }

          console.log(isOngoingConsultation)

          if (isOngoingConsultation === true) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
        .catch((err) => {
          reject(false)
        })
    });
  }

  const userRedirect = async () => {
    localStorage.setItem("strict_app_home", 1)

    const isEligible = await isFreeChatEligible()
    console.log('isEligible:', isEligible)
    if (BrowserUtils.isLoggedIn()) {
      const isActiveChat = await isActiveConsultation()
      console.log('isActiveChat:', isActiveChat)

      // Redirect existing non-eligible user to listing
      if (!isEligible) {
        console.log('FreeConsultation: Redirect existing non-eligible user to listing')
        goToListing()

        // Clear free consultation flow
        BrowserUtils.setFreeConsultationFlowEnded(true)
        return
      }

      // Redirect user to listing if active consultation
      else if (isActiveChat) {
        console.log('FreeConsultation: Redirect user to listing if active consultation')
        goToListing()
        return
      }
    }

    // Connect random astrologer
    console.log('FreeConsultation: Connect random astrologer')
    connectRandomAstrologer()
  }

  useEffect(() => {
    userRedirect()
  }, [])

  const simulateAstrologerResponse = (text, after = () => { }) => {
    setTimeout(() => {
      dispatch({ type: 'ASTROLOGER_MESSAGE', text });
      after()
    }, Math.floor(Math.random() * 5000) + 1000);
  }

  const goToListing = () => {
    history.push('/app-home-listing')
  }

  const getUpdatedProfileData = async () => {
    return new Promise((resolve, reject) => {
      axios()
        .post("/profile/user")
        .then((res) => {
          if (res.data.data.success && res.data.data.user) {
            const _user = res.data.data.user
            _user.dob = _user.dateOfBirth
            _user.pob = _user.placeOfBirth
            _user.tob = _user.timeOfBirth
            setUser(_user)
            setTimeout(() => resolve(_user), 500)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    });
  }

  const handleUserMessage = (text) => {
    dispatch({ type: 'USER_MESSAGE', text });

    if (!state.phoneNumberVerified) {
      dispatch({
        type: 'BOT_MESSAGE',
        text: `Please finish phone number verification.<br> ${astrologer.fullName} will wait till then.`,
        timeFormat: TIME_FORMAT.FULL,
        extra: {
          highlight: true
        }
      })
      dispatch({ type: 'SHOW_VERIFY_NUMBER_COMPONENT' });
      dispatch({ type: 'INFO', text: 'Please finish mobile number verification\nto continue the chat.' })
    } else {
      (async () => {
        const updatedUser = await getUpdatedProfileData()
        simulateAstrologerResponse(`Hello ${updatedUser.name},\nkindly validate your birth details:\n${updatedUser.gender}, ${updatedUser.dob}, ${updatedUser.tob}\n${updatedUser.pob}`, e => {
          dispatch({ type: 'SHOW_BIRTH_DETAILS_COMPONENT' });
          dispatch({ type: 'INFO', text: 'Please select your choice by clicking\none of the button above' })
          // 
        })
      })()
    }
  };

  const messageEvent = {
    setStatus: e => setFreeMessageStatus(e),
    astrologer,
    activeChatStatus: (status, message) => {
      if (status === 'chat-cancel' && !realChatConnected) {
        connectRandomAstrologer()
      }
    },
    astrologerMessage: e => {
      setTimeout(() => {
        e.from = 'astrologer'
        setFreeMessages([...freeMessages, FCMessage.createObj(e)])
      }, Math.floor(Math.random() * 5000) + 1000)
    },
    botMessage: e => {
      e.from = 'bot'
      setFreeMessages([...freeMessages, FCMessage.createObj(e)])
    },
    userMessage: e => {
      e.from = 'user'
      setFreeMessages([...freeMessages, FCMessage.createObj(e)])
    },
    handleUserMessage,
    dispatch,
    onVerify: async e => {

      dispatch({ type: 'BOT_MESSAGE', text: 'Phone number verified <svg class="MuiSvgIcon-root checkCircle" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>', })

      if (BrowserUtils.isLoggedIn()) {
        const updatedUser = await getUpdatedProfileData()
        // console.log(updatedUser)
        dispatch({ type: 'BOT_MESSAGE', text: 'You may start consultation now', timeFormat: TIME_FORMAT.FULL })
        simulateAstrologerResponse(`Hello ${updatedUser.name},\nkindly validate your birth details:\n${updatedUser.gender}, ${updatedUser.dob}, ${updatedUser.tob}\n${updatedUser.pob}`, e => {
          dispatch({ type: 'SHOW_BIRTH_DETAILS_COMPONENT' });
          dispatch({ type: 'INFO', text: 'Please select your choice by clicking\none of the button above' })
          // 
        })
      }

      dispatch({ type: 'VERIFY_PHONE_NUMBER' })
      dispatch({ type: 'BOT_MESSAGE', text: 'Please wait for the astrologer to re-join this consultation' })

      const time = new Date()
      const totalSeconds = time.getSeconds() + (1 * 60)
      time.setSeconds(totalSeconds)

      dispatch({
        type: 'INFO', text: <>Please wait for the astrologer to re-join<br />this consultation - <CountDownTimer
          className="timer"
          autoStart={false}
          expiryTimestamp={time}
          onExpire={() => { }}
        /></>
      })

    },
    onEnterBirthDetails: e => {
      dispatch({ type: 'INFO', text: 'Please confirm your birth details to continue the consultation.' })
    },
    onCorrectedBirthDetails: async e => {
      dispatch({ type: 'HIDE_INPUT_TEXT' })
      dispatch({ type: 'INFO', text: '' })

      console.log({ e })

      dispatch({
        type: 'BOT_MESSAGE', text:
          `<div class="birth-details-component">
            <h2 class="component-title">Birth details: Select your choice</h2>
            <div class="selected-button">
              ${e?.selectedOption === 'yes' ? '<strong class="yes">Yes, this is correct</strong>' :
            '<strong class="no">No, not correct</strong>'}
            </div>
          </div>`,
        extra: {
          transparent: true
        }
      })

      const tempUser = e?.user ? e.user : user

      localStorage.setItem('name', tempUser.name)
      localStorage.setItem('gender', tempUser.gender)
      localStorage.setItem('dob', tempUser.dob)
      localStorage.setItem('pob', tempUser.pob)
      localStorage.setItem('tob', tempUser.tob)


      dispatch({
        type: 'USER_MESSAGE', text: `Here are my corrected birth details:\n${tempUser.name}, ${tempUser.gender}\n${tempUser.dob}, ${tempUser.tob}\n${tempUser.pob}`
      })

      // dispatch({
      //   type: 'SHOW_INSUFFICIENT_BALANCE',
      //   data: {
      //     minimum: 800,
      //     required: 150,
      //   }
      // });
      const status = await checkAstrologerStatus()
      console.log({ checkAstrologerStatus: status })
      tryToConnectTheAstrologer(status)
    },
    onContinueConsultation: async e => {
      dispatch({ type: 'INFO', text: '' })
      const status = await checkAstrologerStatus()
      console.log({ checkAstrologerStatus: status })
      tryToConnectTheAstrologer(status)
    },
    getUser: () => user,
    setAstrologer
  }

  const checkAstrologerStatus = async () => {
    return new Promise((resolve, reject) => {
      axios()
        .post(`astrologers/${astrologer.profileUrl}`)
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            resolve(res.data.data.status)
          } else {
            reject('failed')
          }
        })
        .catch((err) => {
          reject('failed')
        })
    });
  }

  const tryToConnectTheAstrologer = (status = 'online') => {
    if (status === 'online') {
      tryToStartChatIfFreeChat()
    } else {
      // Show `Try another astrologer`

      dispatch({ type: 'SHOW_TRY_ANOTHER_ASTROLOGER' })
      dispatch({ type: 'INFO', text: 'Astrologer not available right now. Please try another astrologer.' })
    }
  }

  const tryToStartChatIfFreeChat = () => {
    axios()
      .post('/astrologers/all/ma', getAstrologerPostData())
      .then((res) => {
        const astrologers = res.data.data.filter(a => a.chargesChat)
        if (res.data.success && astrologers.length) {
          startChat()
        } else {
          tryToStartChatIfBalance()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getAstrologerPostData = () => {
    const FCFData = FreeConsultationFlowData()
    let postData = {
      user_age: 0,
      // page_count: 10,
      exclusion_astrologers: FCFData.astrologer_exclusion.join('|'),
    }

    if (BrowserUtils.isAWebview && typeof window.AndroidBridge !== "undefined") {
      let profileData = window.AndroidBridge.call("getAppProfileData")
      if (profileData) {
        try {
          profileData = JSON.parse(profileData);
        } catch (er) { profileData = {}; }

        const dob = moment(profileData.dob, 'DD-MM-YYYY');
        if (dob.isValid()) {
          postData.user_dob = dob.format('YYYY-MM-DD');
          postData.user_age = moment().diff(dob, 'years');
        }
        postData.app_language = profileData.appLanguage;
        postData.app_chartstyle = userProfileData.horoscopeStyle[profileData.chartStyle ? profileData.chartStyle : 0];
        postData.user_gender = profileData.gender;
        postData.user_country = profileData.country;
      }
    }

    return postData
  }

  const tryToStartChatIfBalance = () => {
    axios()
      .post("/profile/updates")
      .then((res) => {
        if (res && res.data.success) {
          const wallet_balance = res.data.data.wallet_balance || 0
          FA_localStorage("walletBalance", wallet_balance)
          const minBalance = astrologer.astrologerFee * MIN_BALANCE_MINS
          const diffBalance = minBalance - wallet_balance > 0 ? minBalance - wallet_balance : minBalance
          if (minBalance <= wallet_balance) {
            startChat()
          } else {
            dispatch({
              type: 'SHOW_INSUFFICIENT_BALANCE',
              data: {
                minimum: minBalance,
                required: diffBalance,
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const startChat = async () => {
    const chatInfo = await initChat(astrologer.id);
    if (chatInfo && chatInfo.success === true) {
      console.log({ chatInfo, astrologer })
      // BrowserUtils.setFreeConsultationFlowEnded(true)
      astrologer.fullName =
        astrologer.salutation && astrologer.name.indexOf(astrologer.salutation) < 0
          ? [astrologer.salutation, astrologer.name].join(" ")
          : astrologer.name
      setAstrologer(astrologer)

      setChatWindowData({
        astrologer: astrologer.id,
        time: Date.now(),
        chatID: chatInfo.chatID,
        status: chatInfo.status,
      })
      const consultId = chatInfo?.receiver?.consultationId || 0;
      if (consultId) {
        notifyChat(consultId)
      }
      setRealChatConnected(true)
      setFreeMessageStatus('reconnect')
    } else {
      if (chatInfo?.message) {
        dispatch({ type: 'INFO', text: chatInfo.message })
      }
      tryToStartChatIfBalance()
    }
  }

  const emptyFun = e => { }


  return (
    <>
      <div className="free-consultation">
        {/* <div style={{ position: 'absolute', zIndex: '99999', right: 0 }}>
              <button onClick={() => messageEvent.botMessage({ text: 'hi' })}>Test Message</button>
              <button onClick={startChat}>Start Chat</button>
            </div> */}
        {(messageEvent?.astrologer && freeMessageStatus !== 'reconnect') ?
          <Dialog open={true} fullScreen>
            <div
              className={[
                isMobile ? `chat-window` : `chat-window chat-window-except-mobile`,
                BrowserUtils.isNewUI() ? 'new-ui-chat-window' : ''
              ].join(' ').trim()}
            >
              <div className="chat-wrap free-consult new-chat-ui">
                <FreeConsultWindow
                  freeMessages={state}
                  freeMessageStatus={freeMessageStatus}
                  freeMessageEvent={messageEvent}
                  onlyMessages={false}
                />
              </div>
            </div>
          </Dialog>
          : <>
            <ChatWindowStateProvider value={chatWindowData}>
              <ChatWindowStateSetterProvider value={setChatWindowData}>
                <ChatNotificationTrigger setChatWindowData={setChatWindowData} setLoading={setLoading} />
                <Conversations
                  freeMessages={state}
                  freeMessageStatus={freeMessageStatus}
                  freeMessageEvent={messageEvent}
                  setIsConvLoading={emptyFun}
                />
              </ChatWindowStateSetterProvider>
            </ChatWindowStateProvider>
          </>
        }
      </div>
    </>
  )
}

export default FreeConsultation