import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import moengage from "../../../../common/moengage";
import defaultImage from "../../../../common/default_profile_image.svg";
import FA_localStorage from "../../../../common/util/FA_localStorage";
import axios from "../../../../common/interceptor";
import sentryError from "../../../../common/sentryError";
import Modal from "@material-ui/core/Modal";
import Snackbar from "../../../../common/SnackBarMessage";
import CloseIcon from "@material-ui/icons/Close";
import SadIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import AstrologerUtils, { isChatEnabled, trackFAEvent } from "./AstrologerUtils";
import { Avatar, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import md5 from "md5";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BOOKING_CHARGE, MIN_BALANCE_MINS, SUPPORT_NUMBER } from "../../../../app.config";
import BrowserUtils from "./UAScripts";
import InfoIcon from "./info.svg";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, parse, setDate } from "date-fns";
import Warning from "@material-ui/icons/WarningRounded";
import SlotItem from "./SlotItem.component";
import { initChat } from "./chat/ChatUtls";
import Conversations from "./chat/Conversations";
import { ChatWindowStateSetterContext } from "./chat/ChatWindowContext";
import Phone from "@material-ui/icons/Phone";
import ChatIcon from "@material-ui/icons/Chat";

const AstrologerCallDialog = (props) => {
  const [snackBarState, setSnackBarState] = useState({});
  const [warModalState, SetWarModalState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(null);
  const [confirmAction, setConfirmAction] = useState(null);
  const [dialogType, setDialogType] = useState(props.dialogType);
  const [newUI, setNewUI] = useState(false);

  const setChatWindowData = useContext(ChatWindowStateSetterContext);


  useEffect(() => {
    setNewUI(BrowserUtils.isNewUI())
  });

  const showConfirmDialog = (message, action) => {
    setConfirmMessage(message);
    setConfirmAction(action);
  };

  const confirmShortCall = (e) => {
    const shortCall = true;
    setConfirmMessage(null);
    onCall(e, shortCall);
  };

  const proceedToBooking = (e) => {
    setConfirmMessage(null);
    setDialogType("booking");
  };

  const astrologerFee = props.astrologer.price ? parseInt(props.astrologer.price.split("/")[0], 10) : 0;
  const walletBalance = parseInt(FA_localStorage("walletBalance")) || 0;
  const MINDELAY = 2;

  const upperCaseWords = (str) => {
    if (str) {
      str = str.toLowerCase();
      return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, function (s) {
        return s.toUpperCase();
      });
    }
  };
  const similarAstrologer = () => {
    moengage("PROFILE_FIND_SIMILAR_CLICKED");
    window.location.href = "/similar-astrologer/" + props.astrologer.profileUrl;
  };
  const showError = (message) => {
    setSnackBarState({
      open: true,
      message: message,
    });
  };

  const closeError = () => {
    setSnackBarState({ open: false });
  };

  const onChat = async (e) => {
    if (BrowserUtils.hasUserProfile()) {
      // TODO:call chat api
      const chatInfo = await initChat(props.astrologer.id);
      if (chatInfo && chatInfo.success === true) {
        setChatWindowData({
          astrologer: props.astrologer.id,
          time: Date.now(),
          chatID: chatInfo.chatID,
          status: chatInfo.status,
        });
        // props.handleClose();
        // call back to astrologer list.
      } else {
        showError(chatInfo.message);
      }
    } else {
      window.location.href = "/userprofile?pathname=" + window.location.pathname;
    }
  };

  const onCall = (e, shortCall) => {
    moengage("PROFILE_OKAY_CLICKED");
    handleClose();
    setIsLoading(true);

    if (
      window.AndroidBridge &&
      window.AndroidBridge.call("getAppVersion").toString() !== "" &&
      window.compareVersion("3.0.0", window.AndroidBridge.call("getAppVersion")) > 0
    ) {
      window.location.href = window.location.origin + "?access=no";
      return false;
    }

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + window.location.pathname;
    } else if (!BrowserUtils.hasUserProfile()) {
      window.location.href = "/userprofile?pathname=" + window.location.pathname;
    } else {
      axios()
        .get("/astrologer/in-promo/" + props.astrologer.id)
        .then((res) => {
          if (
            (res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("promoPrice")) ||
            (!res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("nextCallPrice"))
          ) {
            showError("Offer outdated, please try again");
            setTimeout((e) => {
              window.location.href = "/";
            }, 3000);
            setIsLoading(false);
          } else {
            let isInPromoAst = res.data.is_in_promo_list;
            axios()
              .post("/consultations/call", {
                id: props.astrologer.id,
                mode: "audio",
                proceed_short_call: shortCall,
              })
              .then((res) => {
                if (res.data.success) {
                  const secondsSinceEpoch = Math.floor(Date.now() / 1000);

                  FA_localStorage(
                    "lastActiveConsultation",
                    JSON.stringify({
                      id: res.data.data.id,
                      key: md5(res.data.data.id.toString()),
                      token: res.data.data.token,
                      status_token: res.data.data.status_token,
                      astrologer: props.astrologer.name,
                      astrologerUrl: props.astrologer.profileUrl,
                    })
                  );

                  localStorage.setItem(
                    "progress_call",
                    "1|" +
                    (parseInt(secondsSinceEpoch) + parseInt(res.data.data.time)) +
                    "|" +
                    res.data.data.id +
                    "?token=" +
                    res.data.data.token +
                    "&stkn=" +
                    res.data.data.status_token +
                    "&prl=%2Fastrologer%2F" +
                    props.astrologer.profileUrl +
                    "|" +
                    props.astrologer.name
                  );
                  localStorage.setItem("old_user", true);
                  /** tracking start */
                  trackActions("fa_consult_init", isInPromoAst ? "promotional" : "main");
                  trackFAEvent({
                    event_type: "fa_consult_init",
                    event_action: props.extras ? props.extras.source : null,
                    event_label: props.astrologer.profileUrl,
                    event_value: res.data.data.id,
                    meta: {
                      ast_id: props.astrologer.id,
                      ast_type: isInPromoAst ? "promotional" : "main",
                      ast_url: props.astrologer.profileUrl,
                      call_details: res.data.data,
                    },
                  });
                  /** tracking end */
                  window.location.href =
                    "/astrologer-connect/" +
                    res.data.data.id +
                    "?token=" +
                    res.data.data.token +
                    "&stkn=" +
                    res.data.data.status_token +
                    "&prl=%2Fastrologer%2F" +
                    props.astrologer.profileUrl;
                } else if (res.data.isProfileUpdated) {
                  showError("Please update your profile");
                  window.setTimeout(function () {
                    window.location.href = "/userprofile?pathname=/astrologer/" + props.astrologer.profileUrl;
                  }, 3000);
                } else if (res.data.isWalletRecharge) {
                  showError(res.data.message);
                  window.setTimeout(function () {
                    window.location.href = "/my-wallet?pathname=/astrologer/" + props.astrologer.profileUrl;
                  }, 5000);
                } else if (res.data.isSimilar) {
                  //SetWarModalState({...warModalState, warningModalOpen: true, warningModalMessage: res.data.message});
                  props.handleClose();
                  showError(res.data.message);
                } else {
                  if (res.data.confirm) showConfirmDialog(res.data.message, res.data.action);
                  else showError(res.data.message);
                }
                /** tracking un successful calls */
                if (res.data.success !== true) {
                  trackActions("fa_consult_init_err", isInPromoAst ? "promotional" : "main");
                  trackFAEvent({
                    event_type: "fa_consult_init_err",
                    event_action: props.extras ? props.extras.source : null,
                    event_label: props.astrologer.profileUrl,
                    event_value: props.astrologer.id,
                    meta: res.data,
                  });
                }
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err);
                sentryError("ASTROLOGER_PROFILE_CALL", err);
                showError("Error occured, Please try again");
              });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          showError("Error occured, Please try again");
        });
    }
  };
  const trackActions = (type, label) => {
    moengage(type);
    const data = props.astrologer;
    let ast_rating = data.reviewsData.count > 0 ? data.reviewsData.rating.toFixed(1) : "NA";
    const payload = {
      p_screen: props.isSearch ? "search" : "home",
      p_label: label,
      p_content: data.profileUrl,
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: data.experience.split(" ")[0],
      p_ast_rating: ast_rating,
      p_ast_sort: "NA",
      p_ast_rank: data.rank ? data.rank.toString() : "NA",
      p_ast_fees: data.price.split("/")[0],
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };
  const imageLoaded = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("loaded");
  };
  const handleClose = () => {
    console.log("closing..");
  };
  const findCommonLanguages = (asl, usl) => {
    let cl = asl.filter(function (n) {
      return usl.indexOf(n) !== -1;
    });
    return cl.length > 0 ? cl : asl;
  };

  const [slotsOpen, setSlotOpen] = useState(null);
  const [timeInputOpen, setTIOpen] = useState(false);
  const [timeInput, setTimeInputValue] = useState("09:00 AM");
  const onTimeEdit = (e, value) => {
    if (value && value.length > 0) {
      const t = parse("01/Jan/2001 " + value, "dd/MMM/yyyy hh:mm a", new Date());
      if (8 < t.getHours() && t.getHours() < 21) {
        setTimeInputValue(value && value.length > 0 ? value : null);
      } else {
        showError("Please choose a time between 9 AM and 9 PM");
        setTimeInputValue(null);
      }
    }
  };

  const [duration, setDuration] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [dateInput, setDateInput] = useState(null);

  useEffect(() => {
    bookingData && props.runUserUpdates && props.runUserUpdates();
  }, [bookingData]);

  const onBook = (e) => {
    handleClose();
    setIsLoading(true);

    if (
      window.AndroidBridge &&
      window.AndroidBridge.call("getAppVersion").toString() !== "" &&
      window.compareVersion("3.0.0", window.AndroidBridge.call("getAppVersion")) > 0
    ) {
      window.location.href = window.location.origin + "?access=no";
      return false;
    }

    if (
      !localStorage.getItem("btkn") ||
      localStorage.getItem("btkn") === "" ||
      localStorage.getItem("btkn") === "undefined" ||
      !localStorage.getItem("utkn") ||
      localStorage.getItem("utkn") === "" ||
      localStorage.getItem("utkn") === "undefined"
    ) {
      window.location.href = "/login?pathname=" + window.location.pathname;
    } else if (!BrowserUtils.hasUserProfile()) {
      window.location.href = "/userprofile?pathname=" + window.location.pathname;
    } else {
      axios()
        .get("/astrologer/in-promo/" + props.astrologer.id)
        .then((res) => {
          if (
            (res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("promoPrice")) ||
            (!res.data.is_in_promo_list && props.cashBackDiscount.toString() !== FA_localStorage("nextCallPrice"))
          ) {
            showError("Offer outdated, please try again");
            setTimeout((e) => {
              window.location.href = "/";
            }, 3000);
            setIsLoading(false);
          } else {
            let isInPromoAst = res.data.is_in_promo_list;
            const time = parse(format(dateInput, "dd/MM/yyyy") + " " + timeInput, "dd/MM/yyyy hh:mm a", new Date());
            axios()
              .post("/consultations/book", {
                id: props.astrologer.id,
                mode: "audio",
                time: time.toGMTString(),
                duration: duration,
              })
              .then((res) => {
                if (res.data.success) {
                  setBookingData(res.data.data);
                  /** tracking start */
                  trackActions("fa_booking_init", isInPromoAst ? "promotional" : "main");
                  trackFAEvent({
                    event_type: "fa_booking_init",
                    event_action: props.extras ? props.extras.source : null,
                    event_label: props.astrologer.profileUrl,
                    event_value: res.data.data.id,
                    meta: {
                      ast_id: props.astrologer.id,
                      ast_type: isInPromoAst ? "promotional" : "main",
                      ast_url: props.astrologer.profileUrl,
                      call_details: res.data.data,
                    },
                  });
                  /** tracking end */
                } else if (res.data.isProfileUpdated) {
                  showError("Please update your profile");
                  window.setTimeout(function () {
                    window.location.href = "/userprofile?pathname=/astrologer/" + props.astrologer.profileUrl;
                  }, 3000);
                } else if (res.data.isWalletRecharge) {
                  showError(res.data.message);
                  window.setTimeout(function () {
                    window.location.href = "/my-wallet?pathname=/astrologer/" + props.astrologer.profileUrl;
                  }, 5000);
                } else if (res.data.isSimilar) {
                  //SetWarModalState({...warModalState, warningModalOpen: true, warningModalMessage: res.data.message});
                  props.handleClose();
                  showError(res.data.message);
                } else {
                  showError(res.data.message);
                }
                /** tracking un successful calls */
                if (res.data.success !== true) {
                  trackActions("fa_booking_init_err", isInPromoAst ? "promotional" : "main");
                  trackFAEvent({
                    event_type: "fa_booking_init_err",
                    event_action: props.extras ? props.extras.source : null,
                    event_label: props.astrologer.profileUrl,
                    meta: res.data,
                  });
                }
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err);
                sentryError("ASTROLOGER_PROFILE_CALL", err);
                showError("Error occured, Please try again");
              });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          showError("Error occured, Please try again");
        });
    }
  };

  const confirmBooking = () => {
    if (dateInput) {
      const t = parse(format(dateInput, "dd/MM/yyyy") + " " + timeInput, "dd/MM/yyyy hh:mm a", new Date());
      const minTime = new Date(Date.now() + MINDELAY * 60 * 60 * 1000);
      if (t.getTime() >= minTime.getTime() && duration > 0) {
        setShowConfirm(true);
      } else {
        showError(
          duration > 0
            ? `Consultation booking time should be after ${format(minTime, "hh:mm a")}`
            : `Choose a consultation duration`
        );
      }
    } else {
      showError(
        duration > 0
          ? `Choose a preferred consultation date & time`
          : `Choose a preferred consultation duration, date & time`
      );
    }
  };

  const finishBooking = () => {
    cancelBooking();
    props.handleClose();
  };

  const cancelBooking = () => {
    setShowConfirm(false);
  };

  const getRequiredBalance = () => {
    const chargeableDuration = duration - props.astrologer.free_minutes;
    return chargeableDuration * astrologerFee + BOOKING_CHARGE;
  };

  const formatedBookingTime = () => {
    if (dateInput && timeInput) {
      const t = parse(format(dateInput, "dd/MM/yyyy") + " " + timeInput, "dd/MM/yyyy hh:mm a", new Date());
      if (t.toDateString() === new Date().toDateString()) {
        return format(t, "dd MMMM yyyy ") + "(Today)" + format(t, ", hh:mm a");
      } else {
        return format(t, "dd MMMM yyyy, hh:mm a");
      }
    } else {
      return null;
    }
  };

  const BookingSlots = (props) => {
    const defDate = parse(format(new Date(), "yyyy-MM-dd"), "yyyy-MM-dd", new Date());
    const [dateInputOpen, setDIOpen] = useState(false);
    const [dateInput, setDateInputValue] = useState(props.dateInput ? props.dateInput : defDate);
    const [timeInput, setTimeInputValue] = useState(props.timeInput);
    const [error, setError] = useState(null);
    const showLocalError = function (err) {
      setError(err);
    };

    const onDateEdit = (e, value) => {
      if (value && value.length > 0) {
        const t = parse(value + " 12:00 AM", "MMM dd, yyyy hh:mm a", new Date());
        if (t.toDateString() !== dateInput.toDateString()) {
          setTimeInputValue(null);
        }
        setDateInputValue(t);
        if (t.getTime() < Date.now() + 2 * 60 * 60 * 1000) {
          setTimeInputValue(null);
        }
      }
    };

    const [showProgress, showLoading] = useState(false);
    const [slots, setSlots] = useState([]);

    const bookFrom = (i) => {
      const s = [...slots];
      //console.log("{", s, i, s[i]);
      s.map((d) => {
        if (d.marker === "selected") d.marker = "active";
        return true;
      });
      if (s[i] && s[i].marker === "active") {
        const dfactor = duration / 15 - 1;
        if (dfactor > 0) {
          let loop = dfactor;
          let goForward = true;
          let f = 1,
            b = 1;
          while (loop > 0) {
            if (goForward && s[i + f] && s[i + f].status === "open") {
              s[i + f].marker = "selected";
              f++;
            } else if (s[i - b] && s[i - b].status === "open") {
              s[i - b].marker = "selected";
              b++;
              goForward = false;
            }
            loop--;
          }
        }
        s[i].marker = "selected";
        setSlots(s);
      }
    };

    const getCaptionOfSlot = (slot) => {
      const slots = slot.split("-");
      return (
        format(parse("2001-01-01 " + slots[0], "yyyy-MM-dd HH:mm", new Date()), "hh:mm a") +
        " to " +
        format(parse("2001-01-01 " + slots[1], "yyyy-MM-dd HH:mm", new Date()), "hh:mm a")
      );
    };

    const timeElapsed = (slot) => {
      const time = parse(format(dateInput, "yyyy-MM-dd") + " " + slot.split("-")[0], "yyyy-MM-dd HH:mm", new Date());
      const ref = new Date(Date.now() + MINDELAY * 60 * 60 * 1000);
      return ref.getTime() > time.getTime();
    };

    const fetchAstrologerSlots = () => {
      setError(null);
      if (dateInput && slotsOpen) {
        showLoading(true);
        axios()
          .post("/consultations/astrologer-time-slot", {
            astrologer_id: props.astrologer.id,
          })
          .then((res) => {
            if (res.data.success) {
              try {
                const sID = format(dateInput, "yyyy-MM-dd");

                const bookings = res.data.slots[sID];
                const slots = [];
                bookings.map((booking, i) => {
                  const slot = Object.keys(booking)[0];
                  const status = booking[slot];
                  const sameDay = dateInput.toDateString() === new Date().toDateString();
                  const elapsed = sameDay && timeElapsed(slot);

                  if (elapsed === false) {
                    slots.push({
                      index: i,
                      caption: getCaptionOfSlot(slot),
                      status: status,
                      marker: "inactive",
                    });
                  }
                  return true;
                });
                if (slots.length > 0) {
                  filterTimeSlots(slots);
                  setSlots(slots);
                  setChange(Date.now());
                } else {
                  setDateInputValue(new Date(dateInput.getTime() + 24 * 60 * 60 * 1000));
                  setTimeInputValue(null);
                }
              } catch (err) {
                console.error(err);
              }
            }
            showLoading(false);
          })
          .catch((err) => {
            showLocalError("Unable to fetch astrologer bookings");
          });
      }
    };

    const [changed, setChange] = useState(null);

    const markSelection = () => {
      if (timeInput) {
        [...slots].map((slot, i) => {
          if (slot.caption.indexOf(timeInput) === 0) {
            bookFrom(i);
          }
          return true;
        });
      }
    };

    useEffect(() => {
      if (changed) {
        markSelection();
      }
    }, [changed]);

    function filterTimeSlots(slots) {
      const dfactor = duration / 15 - 1;

      slots.map((slot, i) => {
        let loop = dfactor;
        let lcount = 0;
        let f = 1,
          b = 1;
        let goForward = true;
        while (loop > 0) {
          if (goForward && slots[i + f] && slots[i + f].status === "open") {
            lcount++;
            f++;
          } else if (slots[i - b] && slots[i - b].status === "open") {
            lcount++;
            b++;
            goForward = false;
          }
          loop--;
        }
        if (lcount === dfactor || dfactor === 0) {
          slot.marker = slot.status === "open" ? "active" : "inactive";
        }
        return true;
      });
    }

    useEffect(() => {
      if (dateInput) fetchAstrologerSlots();
      else {
        setDateInputValue(new Date());
        setTimeInputValue(null);
      }
    }, [dateInput]);

    const chooseSelectedSlot = () => {
      setError(null);
      let timeslot = null;
      slots.map((slot) => {
        if (timeslot === null && slot.marker === "selected") {
          timeslot = slot.caption.split(" to ")[0];
        }
      });
      if (timeslot) {
        props.setDateInput(dateInput);
        setTimeInputValue(timeslot);
        props.setTimeInput(timeslot);
        setSlotOpen(false);
      } else {
        showLocalError("Please choose a preferred time slot");
      }
    };

    const cancelSlots = () => {
      setSlotOpen(false);
    };

    return (
      <>
        <div className="ctrl-group">
          <span>
            <b>Select consultation date:</b>
          </span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="dateInput"
              format="MMM dd, yyyy"
              className="dlgformInputs"
              placeholder="dd/MMM/yyyy"
              inputVariant="outlined"
              minDate={new Date()}
              maxDate={new Date(Date.now() + 6 * 24 * 60 * 60 * 1000)}
              keyboardIcon={null}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
              value={dateInput ? dateInput : new Date()}
              onChange={onDateEdit}
              open={dateInputOpen}
              onClick={() => {
                setDIOpen(true);
              }}
              onClose={() => {
                setDIOpen(false);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="dialog-content">
          {error && <div style={{ color: "red" }}>{error}</div>}
          <div className="book-slot-wrap">
            {slots.length > 0 ? (
              slots.map((slot, index) => (
                <SlotItem
                  index={index}
                  caption={slot.caption}
                  status={slot.status}
                  active={slot.marker}
                  dateInput={dateInput}
                  bookFrom={bookFrom}
                />
              ))
            ) : (
              <div>No timeslot available</div>
            )}
            <CircularProgress
              style={{
                display: showProgress ? "block" : "none",
                position: "absolute",
                left: "calc(50% - 20px)",
                top: "calc(50% - 20px)",
              }}
            />
          </div>
        </div>
        <div className="buttons">
          <button onClick={cancelSlots}>Cancel</button>
          <button onClick={chooseSelectedSlot}>OK</button>
        </div>
      </>
    );
  };

  return (
    <div className={[
      newUI ? 'new-style' : ''
    ].join(' ').trim()}>
      <Dialog
        open={props.isStateOpen}
        onClose={handleClose}
        className={"astrologerDialog"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="dialog">
          <div className={"dialogHead"} style={{ backgroundColor: dialogType === "booking" ? "#ee600d" : "#00c180" }}>
            <div className="dialogImage" style={{ background: "url(" + defaultImage + ") no-repeat cover center" }}>
              <Avatar
                className={"avatarImage"}
                alt={props.astrologer.name}
                src={
                  props.astrologer.imageUrl ? AstrologerUtils.createThumbImageURI(props.astrologer.id) : defaultImage
                }
                onLoad={imageLoaded}
              />
            </div>
            <div className="dialogTitle">
              <span>{dialogType === "booking" ? <b>Book a consultation with:</b> : <b>Consult with:</b>}</span>
              <span>Astrologer {upperCaseWords(props.astrologer.name)}?</span>
            </div>
          </div>
          <div>
            {dialogType !== "booking" && props.astrologer.price && astrologerFee * MIN_BALANCE_MINS > walletBalance ? (
              <span className="warningBanner">
                A minimum balance of {astrologerFee * MIN_BALANCE_MINS} points (equivalent to {MIN_BALANCE_MINS} min
                consultation) is required to make this call.
                <a href={"/my-wallet?pathname=" + window.location.pathname}>
                  <u style={{ color: "red" }}>&nbsp;Recharge&nbsp;</u>
                </a>
                to proceed.
              </span>
            ) : (
              <></>
            )}
          </div>
          {dialogType === "booking" && (
            <div className="book-controls">
              <div className="ctrl-group">
                <span>
                  <b>Call charges:</b>
                </span>
                <span>Rs.{props.astrologer.price}</span>
              </div>
              {props.astrologer.free_minutes > 0 && (
                <div className="ctrl-group">
                  <span>
                    <b>Free duration:</b>
                  </span>
                  <span className="free-min">
                    {props.astrologer.free_minutes} mins <img className="info-icon" alt="info" src={InfoIcon} />
                  </span>
                  <div>
                    <em style={{ color: "#ccc", fontStyle: "normal", fontSize: "0.8rem", marginTop: 4 }}>
                      The FREE call duration is adjusted in the required wallet balance and is applicable only if the
                      total call duration is at least {MIN_BALANCE_MINS} mins.
                    </em>
                  </div>
                </div>
              )}
              <div className="ctrl-group">
                <span>
                  <b>Select consultation duration:</b>
                </span>
                <FormControl variant="outlined" className="dlgformInputs">
                  <Select
                    value={duration}
                    onChange={(e) => {
                      setDuration(parseInt(e.target.value, 10));
                      setDateInput(null);
                    }}
                    id="durationInput">
                    {[0, 15, 30, 45, 60].map((value, index) => (
                      <MenuItem value={value}>{value === 0 ? "Select duration" : `${value} minutes`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <Button
                  disabled={duration === 0 || getRequiredBalance() > walletBalance}
                  onClick={() => setSlotOpen(true)}
                  variant="contained"
                  className={"dialogButton booking"}>
                  Select date & time
                </Button> */}
              </div>
              <div className="ctrl-group">
                <span>
                  <b>Select consultation date & time:</b>
                </span>
                <FormControl variant="outlined" className="dlgformInputs">
                  <Select
                    inputProps={{
                      readOnly: true,
                      disabled: duration === 0 || getRequiredBalance() > walletBalance,
                    }}
                    onClick={() => setSlotOpen(duration > 0)}
                    value={dateInput ? format(dateInput, "MMM dd, ") + timeInput : "Select date & time"}
                    id="dtInput">
                    {[dateInput ? format(dateInput, "MMM dd, ") + timeInput : "Select date & time"].map(
                      (value, index) => (
                        <MenuItem value={value}>{value}</MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
              {duration > 0 && (
                <div style={{ fontSize: "0.8rem", opacity: 0.8 }}>
                  {getRequiredBalance() > walletBalance && (
                    <div style={{ marginTop: -14 }}>
                      <a href={"/my-wallet?pathname=" + window.location.pathname}>
                        <div className="error-balance">
                          <Warning />
                          <span>
                            You have insufficient balance. <u style={{ color: "#00c180" }}>RECHARGE NOW</u>
                          </span>
                        </div>
                      </a>
                    </div>
                  )}
                  {duration > 0 && getRequiredBalance() <= walletBalance && dateInput && (
                    <div
                      style={{
                        padding: 10,
                        borderRadius: 8,
                        backgroundColor: "#fff",
                        marginBottom: 4,
                      }}>
                      <div>
                        <b>Booking details:</b>
                      </div>
                      <div>Time: {formatedBookingTime()} </div>
                      <div>Duration: {duration} minutes</div>
                    </div>
                  )}
                  <div>
                    <b style={{ color: getRequiredBalance() > walletBalance ? "red" : "#555" }}>
                      Required wallet balance is {getRequiredBalance()} pts. Your current wallet balance is{" "}
                      {walletBalance} pts.
                    </b>
                    <br />
                    (Consultation fee {getRequiredBalance() - BOOKING_CHARGE}pts. + booking fee {BOOKING_CHARGE}pts.)
                  </div>
                </div>
              )}
            </div>
          )}
          {props.astrologer.price ? (
            <div className={"dialogBody"}>
              {dialogType === "booking" ? (
                <></>
              ) : (
                <>
                  <span>You may talk in:</span>
                  <span>
                    {FA_localStorage("user_languages")
                      ? findCommonLanguages(props.astrologer.languages, FA_localStorage("user_languages")).join(", ")
                      : props.astrologer.languages.join(", ")}
                  </span>
                  <span>Wallet balance:</span>
                  <span>
                    {walletBalance} pts. <a href={"/my-wallet?pathname=" + window.location.pathname}>Recharge?</a>
                  </span>
                  <span>Call charges:</span>
                  <span>Rs.{props.astrologer.price}</span>
                  <span>Talk-time:</span>
                  <span>
                    {Math.floor(walletBalance / parseInt(props.astrologer.price.match(/\d{1,4}/)[0]))} mins (approx)
                  </span>
                  {props.astrologer.free_minutes > 0 ? (
                    <>
                      <span>Free duration:</span>
                      <span>
                        {props.astrologer.free_minutes} mins{" "}
                        <em style={{ color: "red", fontStyle: "normal" }}>
                          (applicable only if the total call duration is at least {MIN_BALANCE_MINS} mins)
                        </em>
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
          {props.cashBackDiscount > 0 ? (
            <span className="offerBanner">
              You will get <b>{props.cashBackDiscount}% CASHBACK</b> after the consultation
            </span>
          ) : (
            ""
          )}
        </div>
        <DialogActions style={{ justifyContent: "center", padding: "16px 0" }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                className={"dialogButton secondary"}
                onClick={() => {
                  moengage("PROFILE_CANCEL_CLICKED");
                  props.handleClose();
                }}
                style={{ marginLeft: "4px" }}>
                Cancel
              </Button>
              {dialogType === "call" ? (
                <>
                  {isChatEnabled() && props.astrologer.chargesChat !== null ? (
                    <>
                      <Button
                        startIcon={<ChatIcon />}
                        onClick={onChat}
                        variant="contained"
                        className={"dialogButton booking"}
                        style={{ marginLeft: "4px" }}>
                        Chat now
                      </Button>
                      <Button
                        startIcon={<Phone />}
                        onClick={onCall}
                        variant="contained"
                        className={"dialogButton primary"}
                        style={{ marginLeft: "4px" }}>
                        Call now
                      </Button>
                    </>
                  ) : (
                    <Button
                      startIcon={<Phone />}
                      onClick={onCall}
                      variant="contained"
                      className={"dialogButton primary"}
                      style={{ marginLeft: "4px" }}>
                      Consult now
                    </Button>
                  )}
                </>
              ) : (
                <Button onClick={confirmBooking} variant="contained" className={"dialogButton booking"}>
                  Yes, book now
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
      {
        <Dialog className="confirm-booking" open={slotsOpen}>
          <div className="dialog">
            <div className="title">Choose consultation date &amp; time</div>
            <BookingSlots
              astrologer={props.astrologer}
              setDateInput={setDateInput}
              dateInput={dateInput}
              setTimeInput={setTimeInputValue}
              timeInput={timeInput}
              duration={duration}
              showError={showError}
            />
          </div>
        </Dialog>
      }
      {showConfirm && (
        <Dialog className="confirm-booking" open={showConfirm}>
          <div className="dialog">
            <div className="title">{bookingData ? "Booking initiated" : "Proceed with booking?"}</div>
            <div>
              {bookingData ? (
                <b style={{ color: "#0a0", paddingTop: 14, display: "block" }}>
                  Your Booking is initiated. Please wait for the confirmation call.
                </b>
              ) : (
                <b style={{ color: "#000", paddingTop: 14, display: "block" }}>
                  Proceed with booking of consultation with Astrologer {upperCaseWords(props.astrologer.name)}?
                </b>
              )}
            </div>
            <div className="details">
              <div>
                <b>Booking details:</b>
              </div>
              <div>Time: {formatedBookingTime()} </div>
              <div>Duration: {duration} minutes</div>
              {bookingData && <div>Booking ID: {bookingData.id}</div>}
            </div>
            <div className="content">
              Our support executive will call you within an hour to confirm your booking after checking with the
              astrologer. Please make sure you pick the call from <b>{SUPPORT_NUMBER}</b>.
              {!bookingData && (
                <span>
                  {" "}
                  The booking charge of <b>{BOOKING_CHARGE}pts</b> will be deducted immediately.
                </span>
              )}
            </div>
            {bookingData ? (
              <div className="buttons">
                <button style={{ width: "100%", backgroundColor: "#ee600d", color: "#fff" }} onClick={finishBooking}>
                  Close
                </button>
              </div>
            ) : (
              <div className="buttons">
                {isLoading ? (
                  <button style={{ width: "100%" }}>
                    <CircularProgress style={{ width: 30, height: 30 }} />
                  </button>
                ) : (
                  <>
                    <button onClick={cancelBooking}>Cancel</button>
                    <button onClick={onBook}>Proceed with booking</button>
                  </>
                )}
              </div>
            )}
          </div>
        </Dialog>
      )}
      <Snackbar
        vertical="top"
        horizontal="right"
        message={snackBarState.message}
        closeError={closeError}
        open={snackBarState.open}></Snackbar>
      {confirmMessage && (
        <Modal open={confirmMessage ? true : false}>
          <div className="warningModal">
            <div style={{ textAlign: "left", padding: "0 8px" }}>{confirmMessage}</div>
            <div style={{ padding: "8px 0", marginBottom: -16 }}>
              <button
                style={{ marginRight: 8 }}
                className="dialogButton secondary"
                onClick={(e) => setConfirmMessage(null)}>
                Cancel
              </button>
              {confirmAction === "booking" ? (
                <button className="dialogButton primary" onClick={proceedToBooking}>
                  Book now
                </button>
              ) : (
                <button className="dialogButton primary" onClick={confirmShortCall}>
                  Yes, Continue
                </button>
              )}
            </div>
          </div>
        </Modal>
      )}
      <Modal open={warModalState.warningModalOpen}>
        <div className="warningModal">
          <CloseIcon
            className="profileCloseIcon"
            onClick={() => {
              SetWarModalState({ warningModalOpen: false });
            }}
          />
          <SadIcon className="astrologerProfileSadIcon" />
          <div>{warModalState.warningModalMessage}</div>
          <Button variant="contained" className="warningModalButton" onClick={similarAstrologer}>
            <span>Find Similar Astrologer</span>
          </Button>
          <div
            className="warningModalSkip"
            onClick={() => {
              moengage("PROFILE_PAGE_SKIP_CLICKED");
              SetWarModalState({ warningModalOpen: false });
            }}>
            Skip and continue browsing
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AstrologerCallDialog;
