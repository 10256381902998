import * as Sentry from "@sentry/react";
import axios from "../common/interceptor";

const sentryError = (fileName, err) => {
  if (window.location.href.indexOf("http://localhost:3000") < 0) {
    let errType = "";
    if (err.response) {
      errType = " [E1] - " + err.response.status;
    } else if (err.request) {
      errType = " [E2] ";
    } else {
      errType = " [E3] ";
    }
    if (err?.message && err.message === "Network Error") {
      let isOnline = "offline";
      if (window.navigator.onLine) {
        isOnline = "online";
      }
      axios()
        .post("/user/dbg/details")
        .then((res) => {
          err = "NE[" + isOnline + "] - " + res.data.HTTP_ORIGIN + " - " + err;
          Sentry.captureMessage(fileName + errType + err);
        })
        .catch((err1) => {
          Sentry.captureMessage(fileName + errType + err + " _ " + err1);
        });
    } else if (err?.message && err.message === "Request aborted") {
      // Do nothing, no need to capture aborted errors
    } else {
      Sentry.captureMessage(fileName + errType + err);
    }
  }
};

export default sentryError;
