import React from "react";
import {withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import HelmetComponent from "../../../common/HelmetComponent.component";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styles from "./makeStyles.js";
import {SUPPORT_NUMBER} from "../../../app.config";

const useStyles = makeStyles({
  ...styles,
  aboutUsSection: {
    paddingBottom: "20px",
    textAlign: "left",
    textJustify: "inter-word",
    fontSize: "0.9rem",
  },
  pageTitle: {
    color: "#ee600d",
    fontSize: "2em",
    paddingBottom: "10px",
  },
  title: {
    color: "#ee600d",
    fontSize: "1.2em",
  },
  title1: {
    color: "#ee600d",
    fontSize: "1.2em",
    paddingBottom: "15px",
  },
  padT10: {
    paddingTop: "6px",
  },
  address: {
    paddingBottom: "20px",
    lineHeight: "28px",
  },
  colorBlue: {
    color: "#09F",
    textDecoration: "none",
  },
});

const AboutUs = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.sidebarScreensParentDiv}>
      <HelmetComponent
        title="About us - Findastro"
        url="https://www.findastro.com/about-us"
        description="Findastro is India Leading Astrologer site. You can connect to qualified Astrolger in your city."
      />
      <div className={classes.sidebarScreensTopArea}>
        <ArrowBackIcon className={classes.sidebarScreensArrowIcon} onClick={props.history.goBack} />
        <div className={classes.sidebarScreensChildTopArea}>
          <p>About Us</p>
        </div>
      </div>
      <div style={{paddingTop: "32px"}}>
        <div className={classes.aboutUsSection}>
          <div className={classes.pageTitle}>Profile</div>
          <div className={classes.title}>Background</div>
          <div>
            <p>
              <a className={classes.colorBlue} href="http://www.astro-vision.com" target="_blank" rel="noreferrer">
                Astro-Vision Futuretech Pvt. Ltd (AVFT)
              </a>
              &nbsp;had its humble beginning in 1984 as a small partnership firm in Cochin. Today, we are one of the
              pioneers in the field of astrology software development in India. Astro-Vision is a name easily recognized
              and held in high esteem in the world of astrology software services.
            </p>
            <p>
              We have been steadily adopting the latest technologies in IT and enhancing our range of products and
              services to bring it closer to the common man. Our products range from software that runs on the latest
              operating systems to the latest range of mobile phones. These products are used by thousands of
              astrologers, students of astrology, business centres and home users globally. Apart from astrology based
              applications, we also provide astrology services to mobile subscribers of major telecom operators and
              subscribers of many leading web portals in India.
            </p>
            <p>
              At&nbsp;
              <a className={classes.colorBlue} href="http://www.astro-vision.com" target="_blank" rel="noreferrer">
                Astro-Vision
              </a>
              , our highly skilled team has only one food for thought - Developing Quality Solutions. Over the past 25
              years, Astro-Vision has grown in strength and size, through sheer dedication to quality, reliability &amp;
              cost-effectiveness. We put a great emphasis on our values to empower people to be positive, hopeful and
              guide them to be righteous. We continue with these values, to grow on our success today and for tomorrow.
            </p>
          </div>
        </div>
        <div className={classes.aboutUsSection}>
          <div className={classes.title}>The Company</div>
          <div>
            <p>
              <a className={classes.colorBlue} href="http://www.astro-vision.com" target="_blank" rel="noreferrer">
                Astro-Vision
              </a>
              &nbsp;is a No.1 astrology company in india and one of the most respected names globally, in the astrology
              domain. Our purpose is to create long-term value through innovative solutions. Astro-Vision’s range of
              solutions includes; stand alone applications, online apps and astro content services.
            </p>
            <p>
              Astro-Vision has strategic tie-ups with major IT enabled service networks like Suvidhaa, Reliance world,
              One- Stop-Shop and other networks under the Government of India’s CSC scheme, including; Spanco and GNG.
              Many popular Indian websites, today, are powered by Astro-Vision’s web based astrology solutions and
              astrology content.
            </p>
            <p>Know more about Astro-Vision</p>
            <p>
              Our popular astrology portal www.clickastro.com has unique features like free daily and weekly
              predictions, online dynamic horoscope generation, premium online reports, astro blogs, astrology lessons
              and consultancy services by renowned astrologers.
            </p>
          </div>
        </div>
        <div className={classes.aboutUsSection}>
          <div className={classes.title1}>Management Team</div>
          <div className={classes.title}>Binod Hariharan</div>
          <div className={classes.title}>B.Tech. (E&amp;E),</div>
          <div className={classes.title}>Chairman</div>
          <div>
            <div className={classes.padT10}>
              Mr. Binod is primarily responsible for providing long term vision for the growth of the company including
              strategic planning and evolving marketing policies. Over the last 15 years, he has been heading the
              marketing operations, leading the firm in changing market trends and help it attain the status of global
              brand. He is an engineering graduate with over 21 years of experience in IT based marketing solutions.
            </div>
          </div>
        </div>
        <div className={classes.aboutUsSection}>
          <div className={classes.title}>Arjun Ravindran</div>
          <div className={classes.title}>MBA (IIM Kozhikode)</div>
          <div className={classes.title}>Managing Director</div>
          <div>
            <div className={classes.padT10}>
              Being an experienced campaigner, Mr.Arjun has hands on in Digital Marketing and Product Management. He
              hold an MBA degree from IIM Kozhikode and an engineering graduate from National Institute of Technology
              (NIT), Bhopal. He has 14 years of experience in various MNCs in India, Japan and Singapore. His
              professional stint started with Accel ICIM Systems, and later he went on to be a part of Satyam Computers
              and Kanbay. Arjun also possesses 7 years of executive leadership experience in the software products and
              services industry.
            </div>
          </div>
        </div>
        <div className={classes.aboutUsSection}>
          <div className={classes.title}>Arun Nair</div>
          <div className={classes.title}>MBA (IIM Kozhikode)</div>
          <div className={classes.title}>Chief Executive Officer</div>
          <div>
            <div className={classes.padT10}>
              Mr.Arun is an accomplished senior information professional with 11 years experience in handling various
              capacities within the sales &amp; marketing functions both in India and abroad. He holds an MBA degree
              from Indian Institute of Management (IIM) Kozhikode and an engineering graduate from National Institute of
              Technology (NIT), Bhopal.
            </div>
          </div>
        </div>
        <div className={classes.aboutUsSection}>
          <div className={classes.title}>P.V.Ravindran</div>
          <div className={classes.title}>B.Tech. (IIT Madras), FIE, C.Eng. (I)</div>
          <div className={classes.title}>Founder &amp; Director</div>
          <div>
            <div className={classes.padT10}>
              Mr P.V.Ravindran has been primarily responsible for designing and developing the entire range of astrology
              based products with a perfect blend of traditional astrology with the latest technologies. He is an
              engineering graduate from IIT, Madras. He started his career with the Indian Aluminum Company and
              subsequently went on to work as a consultant and manager in many multinational companies is Kenya,Syria
              and Nigeria.
            </div>
          </div>
        </div>
        <div className={classes.aboutUsSection}>
          <div className={classes.title}>Beena Ravindran</div>
          <div className={classes.title}>B.A., Diploma in Management</div>
          <div className={classes.title}>Director</div>
          <div>
            <div className={classes.padT10}>
              She holds a Diploma in Management and has extensive experience in customer relations management.
            </div>
          </div>
        </div>
        <div className={classes.address}>
          <div className={classes.title}>Head Office</div>
          <div>
            <a className={classes.colorBlue} href="http://www.astro-vision.com" target="_blank" rel="noreferrer">
              Astro-Vision Futuretech Pvt.Ltd.,
            </a>
          </div>
          <div>1st Floor,White Tower,</div>
          <div>Kuthappadi Road,</div>
          <div>Thammanam P O, Cochin - 682 032</div>
          <div>Kerala, India.</div>
          <div>Phone: {SUPPORT_NUMBER}</div>
          <div>
            E-mail: <a href="mailto:support@findastro.com">support@findastro.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AboutUs);
