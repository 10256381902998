import React, { useState } from "react"
import Modal from "./Modal"
import Otp from "../../../../user-actions/components/Otp.component"
import userActionData from "../../../../user-actions/mock/UserActionData.mock"

import { withRouter, useHistory } from 'react-router-dom'
import { Avatar, Button, TextField } from "@material-ui/core"
import Snackbar from "../../../../../common/SnackBarMessage"
import style from './AstrologerModal.style.module.css'
import AstrologerUtils from "../AstrologerUtils"
import defaultImage from "../../../../../common/default_profile_image.svg"
import CallIcon from '@material-ui/icons/Call';
import ChatBubble from '@material-ui/icons/ChatBubble';


const defaultError = { error: false, message: '' }
const otpSecond = 20
let timer = null
const AstrologerCall = (props) => {
  const history = useHistory()
  const [astrologer, setAstrologer] = useState(history.location.state?.astrologer)
  const [actionType, setActionType] = useState(history.location.state?.actionType)
  const [cancel, setCancel] = useState(history.location.state?.cancel)

  console.log('AstrologerCall:astrologer', astrologer)
  console.log('AstrologerCall:actionType', actionType)
  console.log('AstrologerCall:cancel', cancel)

  const [stage, setStage] = useState('before-otp-send')
  const [mobile, setMobile] = useState('')
  const [otp, setOTP] = useState('')
  const [snackBar, setSnackBar] = useState({
    message: '',
    open: false
  })
  const [error, setError] = useState({
    mobile: defaultError,
    otp: defaultError,
  })
  const [second, setSecond] = useState(-1)

  const onlyNumbers = (e) => {
    var validChars = userActionData.validChars
    if (!validChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  const updateNumber = (e) => {
    setMobile(e.target.value)
  }

  const runTimer = (s) => {
    clearTimeout(timer)
    if (s >= 0 && s <= otpSecond) {
      console.log('runTimer:s', s)
      setSecond(s)
      timer = setTimeout(() => { runTimer(s - 1) }, 1000)
    }
  }

  const onSendOTP = () => {
    setError({ ...error, mobile: defaultError })
    if (mobile.length !== 10) {
      // setSnackBar({ message: 'Please enter a valid phone number', open: true })
      setError({ ...error, mobile: { error: true, message: 'Please enter a valid phone number' } })
    }
    // else if (this.state.code === "") {
    //   setSnackBar({ message: 'Please select a country code', open: true })
    // } 
    else {
      setStage('otp-send')
      runTimer(otpSecond)
    }
  }

  const onVerify = () => {

    setError({ ...error, otp: defaultError })
    if (!(otp && otp.length === 6)) {
      setError({ ...error, otp: { error: true, message: 'Please enter the OTP' } })
    } else {
      alert('Verified')
      onClose()
    }
  }

  const onClose = () => {
    history.push(cancel || '/app-home')
  }

  return (
    <>
      <Modal
        type="normal"
        overlayClose={second == -1}
      >

        {actionType === 'call' &&
          <>
            <Modal.Header invert>Calling... <CallIcon /></Modal.Header>
            <Modal.Content>
              <div className={style.astrologerModalContent}>
                <Avatar
                  className="pic"
                  alt={astrologer.name}
                  src={astrologer.imageUrl ? AstrologerUtils.createThumbImageURI(astrologer.id) : defaultImage}
                />
                <div className="para">Please wait while we connect to</div>
                <div className="name">{astrologer?.name}</div>
                <div className="time">will connect to you in 30s</div>
              </div>
            </Modal.Content>

            <Modal.Action>
              <Button variant="contained" onClick={onClose}>
                Cancel Request
              </Button>
              <Button className="primary" variant="contained" onClick={onSendOTP}>
                OK
              </Button>
            </Modal.Action>
          </>
        }

        {actionType === 'chat' &&
          <>
            <Modal.Header invert>Requesting Chat... <ChatBubble /></Modal.Header>
            <Modal.Content>
              <div className={style.astrologerModalContent}>
                <Avatar
                  className="pic"
                  alt={astrologer.name}
                  src={astrologer.imageUrl ? AstrologerUtils.createThumbImageURI(astrologer.id) : defaultImage}
                />
                <div className="para">Please wait for a while for</div>
                <div className="name">{astrologer?.name}</div>
                <div className="time">will connect to you in 30s</div>
              </div>
            </Modal.Content>

            <Modal.Action>
              <Button variant="contained" onClick={onClose}>
                Cancel Request
              </Button>
              <Button className="primary" variant="contained" onClick={onSendOTP}>
                OK
              </Button>
            </Modal.Action>
          </>
        }

      </Modal >

      <Snackbar
        vertical="top"
        horizontal="center"
        message={snackBar.message}
        closeError={() => setSnackBar({ ...snackBar, open: false })}
        open={snackBar.open}
      />
    </>
  )
}

export default AstrologerCall