import React, { Component } from "react";
import axios from "../../../common/interceptor";
import sentryError from "../../../common/sentryError";
import moengage from "../../../common/moengage";
import { withRouter } from "react-router-dom";

import "./MyAccount.css";
import SideDrawerComponent from "../../user/astrologer/components/SideDrawer.component";
import HelmetComponent from "../../../common/HelmetComponent.component";
import Snackbar from "../../../common/SnackBarMessage";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import CircularProgress from "@material-ui/core/CircularProgress";
import CallIcon from '@material-ui/icons/Call';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import { format, parse } from "date-fns";
import BrowserUtils from "../../user/astrologer/components/UAScripts";
import QueryString from "../../../common/util/QueryString";
import { BOOKING_CHARGE } from "../../../app.config";
import TimerIcon from "@material-ui/icons/Schedule";
import PujaIcon from "@material-ui/icons/Fireplace";
import { formatAmount } from "../../user/astrologer/components/AstrologerUtils";
import ChatHistoryModal from "../../user/astrologer/components/Modal/ChatHistoryModalNew";

class MyConsultations extends Component {
  state = {
    snackBar: {
      open: false,
      message: "Error occurred, Please try again",
    },
    walletPoints: "0",
    consultations: [],
    bookings: [],
    isLoading: true,
    drawerOpen: false,
    showBookings: QueryString().v === "booking",
    chatHistory: {
      messages: [],
      astrologer: {},
      chat: {},
    },
    openChatHistoryModal: false
  };

  showError = (message) => {
    this.setState({
      snackBar: {
        open: true,
        message: message,
      },
    });
  };

  closeError = () => {
    this.setState({
      snackBar: {
        open: false,
      },
    });
  };

  onRecharge = () => {
    window.location.href = "/my-wallet";
  };

  componentDidMount = () => {
    try {
      moengage("CONSULTATIONS_PAGE_VIEWED");

      if (
        !localStorage.getItem("btkn") ||
        localStorage.getItem("btkn") === "" ||
        localStorage.getItem("btkn") === "undefined" ||
        !localStorage.getItem("utkn") ||
        localStorage.getItem("utkn") === "" ||
        localStorage.getItem("utkn") === "undefined"
      ) {
        window.location.href = "/login?pathname=" + this.props.location.pathname;
      } else {
        // axios()
        //   .post("/consultations/my-bookings")
        //   .then((res) => {
        //     if (res.data.success) {
        //       this.setState({
        //         bookings: res.data.data,
        //         isLoading: false,
        //       });
        //     } else {
        //       this.showError(res.data.message);
        //       this.setState({ isLoading: false });
        //     }
        //   })
        //   .catch((err) => {
        //     sentryError("MY_CONSULTATIONS", err);
        //     this.showError("Error occured, Please try again");
        //     this.setState({ isLoading: false });
        //   });

        axios()
          .post("/consultations")
          .then((res) => {
            if (res.data.success) {
              this.setState({
                consultations: res.data.data,
                isLoading: false,
              });
            } else {
              this.showError(res.data.message);
              this.setState({ isLoading: false });
            }
          })
          .catch((err) => {
            sentryError("MY_CONSULTATIONS", err);
            this.showError("Error occured, Please try again");
            this.setState({ isLoading: false });
          });
      }
    } catch (err) {
      this.showError("Error while fetching consultations!");
      sentryError(err.message, err);
      this.setState({ isLoading: false });
    }
    this.trackActions("fa_screen_cx_history", "NA");
    // window.location.hash = "backtohome";
  };

  showConsultations = () => {
    this.setState({
      showBookings: false,
    });
  };

  showBookings = () => {
    this.setState({
      showBookings: true,
    });
  };

  trackActions = (type, label) => {
    moengage(type);
    const payload = {
      p_screen: "home",
      p_label: label,
      p_content: "NA",
      p_value: 0,
      value: 0,
      currency: "INR",
      p_ast_exp: "NA",
      p_ast_rating: "NA",
      p_ast_sort: "NA",
      p_ast_rank: "NA",
      p_ast_fees: "NA",
      p_usr_gender: BrowserUtils.CurrentUser().gender,
      p_usr_age: BrowserUtils.CurrentUser().age.toString(),
      p_usr_p: BrowserUtils.codePhone(BrowserUtils.CurrentUser().phone, 1),
    };
    window.FAEventLog(type, payload);
  };

  parseDate = (date, format, ref) => {
    try {
      return parse(date, format, ref);
    } catch (err) {
      return null;
    }
  };

  formatDate = (date, __format) => {
    if (date) return format(date, __format);
    return "NA";
  };

  showPujaDetails(data) {
    window.location.href = `/puja-booking/paid/${data.transaction_id}?t=${data.token}`;
  }

  isFreeChat(wallet) {
    return wallet.status === 'completed' && wallet.free_consult_min >= 1
  }

  onViewChatHistory(wallet) {
    // console.log(consultation_id)
    axios()
      .post("/user/chat/chat-by-consultation", null, {
        params: {
          id: wallet.ref_id
        }
      })
      .then((res) => {
        if (res.data.success && res.data.astrologer) {
          const profile = res.data.astrologer
          profile.astrologerFee = profile.price
          profile.perMinutePrice = profile.price
          profile.fullName = profile.name
          profile.imageUrl = profile.name
          profile.profileUrl = profile.profile_url
          profile.name = wallet.name
          profile.deleted_at = profile.deleted_at
          // console.log({ profile })
          this.setState({
            chatHistory: {
              messages: res.data.message,
              astrologer: profile,
              chat: {
                status: wallet.status
              }
            },
            openChatHistoryModal: true,
            isLoading: false,
          });
        } else {
          this.showError(res.data.message);
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        sentryError("MY_CONSULTATIONS", err);
        this.showError("Error occured, Please try again");
        this.setState({ isLoading: false });
      })
  }

  render() {
    return (
      <div className="accountParentDiv">
        <HelmetComponent
          title="User Consultation History at Findastro"
          url="https://www.findastro.com/user-consultation-history"
          description="User Consultation History at Findastro-Connect to your Astrologer"
        />
        {this.state.isLoading ? (
          <div className="circle-loading">
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
        <div className="accountTopArea">
          <ArrowBackIcon style={{ color: "#5d5d5d", margin: "24px 15px 0 0px" }} onClick={() => {
            this.props.history.push('/')
          }} />
          <div className="accountChildTopArea">
            <p>Consultation History</p>
          </div>
        </div>
        {/* <div className="sectionSwitch">
          <button onClick={this.showConsultations} className={this.state.showBookings ? "" : "active"}>
            Consultations
          </button>
          <button onClick={this.showBookings} className={this.state.showBookings ? "active" : ""}>
            Bookings
          </button>
        </div> */}
        {/* <div id="payHistory">HISTORY</div>
        <div id="payHistorySub">Your recent consultations will be shown here</div> */}
        {this.state.showBookings === false && this.state.consultations && this.state.consultations.length === 0 && (
          <div style={{ padding: "16px 0", textAlign: "left" }}>
            It looks like you haven't done any consultation so far.
          </div>
        )}
        {this.state.showBookings !== false && this.state.bookings && this.state.bookings.length === 0 && (
          <div style={{ padding: "16px 0", textAlign: "left" }}>
            It looks like you haven't done any booking for consultation so far.
          </div>
        )}
        {this.state.showBookings === false &&
          this.state.consultations.map((wallet, key) =>
            wallet.status !== "refunded" ? (
              <div className="flexParent" key={key}>
                <div className="flexChild1">
                  {wallet.consult_type === 'call' ?
                    <CallIcon className="walletIconCons" /> :
                    <ChatBubbleOutlineOutlinedIcon className="walletIconCons" />
                  }
                </div>
                <div className="flexChild2">
                  <div>{wallet.name}</div>
                  <div className="historyDateText">Consultation ID: {wallet.ref_id}</div>
                  {wallet.booking_id && <div className="historyDateText">Booking ID: {wallet.booking_id}</div>}
                  {this.isFreeChat(wallet) ?
                    <>
                      {/* <div className="historyDateText">Free duration: {wallet.time}</div>
                      <div className="historyDateText">Chargeable duration: {wallet.time}</div> */}
                      <div className="historyDateText">Duration: {wallet.time}</div>
                    </>
                    :
                    <>
                      <div className="historyDateText">Duration: {wallet.time}</div>
                    </>
                  }
                  <div className="historyDateText">
                    {wallet.initiated_at
                      ? this.formatDate(
                        this.parseDate(wallet.initiated_at, "yyyy-MM-dd HH:mm:ss", new Date()),
                        "MMM dd, yyyy hh:mm a"
                      )
                      : "NA"}
                  </div>
                  <div
                    className="historyDateText"
                    style={{ color: (formatAmount(wallet.cost) || 0) > 0 ? "#323232" : "#9a9a9a" }}>
                    Fees: ₹ {formatAmount(wallet.cost) || 0}
                    {wallet.discount ? <>&nbsp;| Cashback: ₹ {wallet.discount}</> : <></>}
                  </div>
                  {this.isFreeChat(wallet) &&
                    <div className="historyDateText free-chat">
                      <span>Free {wallet?.consult_type === 'call' ? 'Call' : 'Chat'}</span>
                    </div>
                  }
                </div>
                <div className="flexChild3">
                  <div
                    className={
                      wallet.status === "completed"
                        ? "statusgreen"
                        : wallet.status === "confirmed"
                          ? "statusgreen"
                          : wallet.status === "failed"
                            ? "statusred"
                            : wallet.status === "missed"
                              ? "statusyellow"
                              : "statusblue"
                    }>
                    {wallet.status}
                  </div>
                  {wallet.ref_id
                    && wallet.ref_id !== ""
                    && wallet.consult_type === 'chat'
                    && wallet.status === 'completed' ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="historyDateText-button"
                      onClick={() => this.onViewChatHistory(wallet)}
                    >
                      View Chat
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              ""
            )
          )}
        {this.state.showBookings === true &&
          this.state.bookings.map((booking, key) =>
            booking.status !== "refunded" ? (
              <div className={["flexParent", "bk_" + booking.type].join(" ")} key={key}>
                <div className="flexChild1">
                  {booking.type === "consultation" ? (
                    <TimerIcon className="walletIconCons" />
                  ) : (
                    <PujaIcon className="walletIconCons" />
                  )}
                </div>
                <div className="flexChild2">
                  <div className="historyDateText">
                    <h3>Booking for {booking.type}</h3>
                  </div>
                  {(booking.type === "puja" || booking.type === "homa") && (
                    <>
                      <div className="historyDateText">
                        Name: <b>{booking.astrologer_puja.puja_name}</b>
                      </div>
                      <div className="historyDateText">
                        Mode: <b>{booking.conducted}</b>
                      </div>
                      <div className="historyDateText">
                        Prasad: <b>{booking.prasad}</b>
                      </div>
                    </>
                  )}
                  <div className="historyDateText">
                    {booking.type === "consultation" ? "Astrologer" : "Priest"}:{" "}
                    {booking.astrologer.salutation ? booking.astrologer.salutation + " " : ""}
                    {booking.astrologer.first_name}{" "}
                    {booking.astrologer.middle_name ? booking.astrologer.middle_name[0].toUpperCase() : ""}{" "}
                    {booking.astrologer.last_name}
                  </div>
                  <div className="historyDateText">Booking ID: {booking.booking_id || booking.id}</div>
                  {booking.consultation_id && (
                    <div className="historyDateText">Consultation ID: {booking.consultation_id}</div>
                  )}
                  {booking.agent_initiated && <div className="historyDateText">Booked by: Findastro agent</div>}
                  <div className="historyDateText">
                    Booked on:{" "}
                    {booking.booked_on
                      ? this.formatDate(
                        this.parseDate(booking.booked_on, "yyyy-MM-dd HH:mm:ss", new Date()),
                        "MMM dd, yyyy hh:mm a"
                      )
                      : "NA"}
                  </div>
                  <div className="historyDateText">
                    Booked for:{" "}
                    {booking.booked_for
                      ? this.formatDate(
                        this.parseDate(booking.booked_for, "yyyy-MM-dd HH:mm:ss", new Date()),
                        "MMM dd, yyyy hh:mm a"
                      )
                      : "NA"}
                  </div>
                  {(booking.type === "puja" || booking.type === "homa") && (
                    <div className="historyDateText">
                      Video Link:{" "}
                      {booking.status === "completed" ? (
                        "NA"
                      ) : (
                        <a href={booking.meet_link}>{booking.meet_link || "NA"}</a>
                      )}
                    </div>
                  )}
                  {booking.type === "consultation" && (
                    <>
                      <div className="historyDateText">Duration: {booking.booked_duration_mins} mins.</div>
                      <div className="historyDateText">
                        Estimated consultation Fees: ₹ {formatAmount((booking.cost - BOOKING_CHARGE).toFixed(2))}
                      </div>
                      <div className="historyDateText">Booking Fees: ₹ {formatAmount(BOOKING_CHARGE.toFixed(2))}</div>
                    </>
                  )}
                  <div className="historyDateText" style={{ marginTop: 8 }}>
                    Status:{" "}
                    <span
                      className={
                        booking.status === "completed"
                          ? "statusgreen"
                          : booking.status === "confirmed"
                            ? "statusgreen"
                            : booking.status === "failed" || booking.status === "cancelled"
                              ? "statusred"
                              : booking.status === "missed" || booking.status === "payment pending"
                                ? "statusyellow"
                                : booking.status === "expired"
                                  ? "statusred"
                                  : "statusblue"
                      }>
                      {booking.status === "initiated" ? "confirmation pending" : booking.status}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
        <SideDrawerComponent
          drawerOpen={this.state.drawerOpen}
          onDrawerClose={() => {
            this.setState({ drawerOpen: false });
          }}
        />
        <ChatHistoryModal
          history={this.state.chatHistory}
          open={this.state.openChatHistoryModal}
          handleClose={() => {
            this.setState({ openChatHistoryModal: false })
          }}
        />
        <Snackbar
          vertical="top"
          horizontal="right"
          message={this.state.snackBar.message}
          closeError={this.closeError}
          open={this.state.snackBar.open}></Snackbar>
      </div>
    );
  }
}

export default withRouter(MyConsultations);
