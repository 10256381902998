import axios from "axios";

const axiosInstance = (history = null) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 60000,
  });

  axiosInstance.interceptors.request.use((config) => {
    if (config.method === 'post' && localStorage.getItem("strict_app_home")) {
      if (config.data === undefined) {
        config.data = { app_id: 'CAEN' }
      } else {
        config.data = { ...config.data, app_id: 'CAEN' }
      }
      // console.log(config)
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem("utkn");
        localStorage.removeItem("btkn");

        if (history) {
          history.push("/app/logout");
        } else {
          window.location.href = "/app/logout";
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );
  return axiosInstance;
};
export default axiosInstance;
