import React from "react";
import sortData from "../mock/Astrologers.mock";
import Drawer from "@material-ui/core/Drawer";

const SortDrawerComponent = (props) => {
  const currentFilter = JSON.parse(sessionStorage.getItem("filterData") || "{}");
  const onSort = (e) => {
    /*let filterText = "?";
    if (props.filterData.availability) filterText = filterText + "availability=" + props.filterData.availability + "&";
    if (props.filterData.expertise) filterText = filterText + "expertise=" + props.filterData.expertise + "&";
    if (props.filterData.language) filterText = filterText + "language=" + props.filterData.language + "&";
    if (props.filterData.category) filterText = filterText + "category=" + props.filterData.category + "&";

    filterText = filterText + "sort=" + e.target.id + "&";
    window.location.href = filterText.slice(0, -1);*/
    props.onSortDrawerClose();
    props.setSortBy(e.target.id);
  };

  return (
    <Drawer
      className="homeFilterBox homeSortBox"
      anchor="bottom"
      open={props.sortOpen}
      onClose={() => {
        props.onSortDrawerClose();
      }}>
      <div>
        <div className="sortByText">SORT BY</div>
        {sortData.sortCategories.map((category) => (
          <div key={category.url}>
            <div className={["sortFlexBox", currentFilter.sortBy === category.url ? "current" : ""].join(" ")}>
              {category.icon}
              <div key={category.url} id={category.url} className="sortOptionBox" onClick={onSort}>
                {category.text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};
export default SortDrawerComponent;
