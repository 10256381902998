import React, { useEffect, useState } from "react"
import Modal from "./Modal"
import Otp from "../../../../user-actions/components/Otp.component"
import userActionData from "../../../../user-actions/mock/UserActionData.mock"

import { withRouter, useHistory } from 'react-router-dom'
import { Avatar, Button, CircularProgress, TextField } from "@material-ui/core"
import Snackbar from "../../../../../common/SnackBarMessage"
import style from './ChatHistoryModal.style.module.css'
import AstrologerUtils from "../AstrologerUtils"
import defaultImage from "../../../../../common/default_profile_image.svg"
import CallIcon from '@material-ui/icons/Call';
import ChatBubble from '@material-ui/icons/ChatBubble';
import FA_localStorage from "../../../../../common/util/FA_localStorage"
import MetaMessage from "../chat/MetaMessage"
import SingleMessage from "../chat/SingleMessage"
import clsx from "clsx"
import BrowserUtils from "../UAScripts"
import AstrologerAvatar from "../AstrologerAvatar"
import { isMobile } from "react-device-detect"
import RechargeLinkRedirect, { useRechargeRedirect } from "../RechargeLinkRedirect"
import T2AEventTrack from "../../../../../common/T2AEventTrack"
import InChatAvatar from "../chat/InChatAvatar"
import { MIN_BALANCE_MINS } from "../../../../../app.config"
import axios from "../../../../../common/interceptor"

const ChatHistoryModal = (props) => {
  const history = useHistory()
  const [astrologer, setAstrologer] = useState(history.location.state?.astrologer || props.astrologer)
  const [actionType, setActionType] = useState(history.location.state?.actionType || props.actionType)
  const [cancel, setCancel] = useState(history.location.state?.cancel || props.cancel)
  const [openModal, setOpenModal] = useState(props.open !== 'undefined' ? props.open : true)
  const walletBalance = parseInt(FA_localStorage("walletBalance")) || 0
  const minWalletBalance = astrologer?.astrologerFee * astrologer?.MIN_BALANCE_MINS
  const [isOpen, setIsOpen] = useState(props.open);
  const [astrologerFullProfile, setAstrologerFullProfile] = useState(props.history.astrologer)
  const activeChatStatus = props.history.chat.status
  const rechargeRedirect = useRechargeRedirect()
  const [loading, setLoading] = useState(false);

  // console.log('InsufficientModal:astrologer', astrologer)
  // console.log('InsufficientModal:actionType', actionType)
  // console.log('InsufficientModal:cancel', cancel)
  // console.log('InsufficientModal:props', props)
  // useEffect(() => {
  //   // console.log(props, props.open !== 'undefined')
  //   if (props.open !== 'undefined') {
  //     setOpenModal(props.open)
  //   }
  // }, [props])

  useEffect(() => {
    if (props.history.astrologer) {
      setAstrologerFullProfile(props.history.astrologer)
    }

  }, [props.history.astrologer])

  useEffect(() => {
    if (props.open === true) {
      window.location.hash = "chat-history-view";
    }
  }, [props.open])

  const browserBackTrigger = () => {
    if (window.location.hash === '#chat-history-view' && props.open === true) {
      history.goBack()
    }
  }

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.endsWith('#chat-history-view')
        && !e.newURL.endsWith('#back-button--wallet-recharge')
        && props.open === true) {
        props.handleClose()
      }
    })
  })

  const MessageType = {
    MINE: 1,
    OTHER: 2,
  };

  const [snackBar, setSnackBar] = useState({
    message: '',
    open: false
  })

  const onRechargeNow = () => {
    history.push('/user-wallet')
  }

  const onClose = () => {
    browserBackTrigger()
    props.handleClose()
  }

  const rechargeButtonAction = (data) => {
    if (BrowserUtils.isNewUI()) {
      const eData = {
        event: {
          name: 'Buy Now Action',
          attributes: {
            'Screen Name': 'Chat History Screen',
            'Button Name': data.buttonName,
            'Target Screen Name': 'User Wallet',
            'Button Location': data.location,
            'Source Screen Name': data.location,
            'Product': 'COT',
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name
          }
        },
        extra: {
          user: {
            'User ID': BrowserUtils.CurrentUserInfo().id,
            'User Name': BrowserUtils.CurrentUserInfo().name
          }
        }
      }
      console.log(eData);
      T2AEventTrack(eData.event, eData.extra);
    }
  }

  const openProfile = () => {
    if (astrologerFullProfile?.profile_url) {
      history.push(`/astrologer-profile/${astrologerFullProfile.profile_url}`)
    }
  }

  const handleContinueChat = (e) => {
    if (astrologerFullProfile.deleted_at && astrologerFullProfile.deleted_at.length > 5) {
      window.location.href = "/";
      return;
    }

    const minBalance = astrologerFullProfile.astrologerFee * MIN_BALANCE_MINS
    // console.log({ minBalance, walletBalance, 'minBalance <= walletBalance': minBalance <= walletBalance }); return;
    if (minBalance <= walletBalance) {
      rechargeRedirect.setData({
        refID: 'chat-history/recharge-top-button',
        astrologerID: astrologerFullProfile.id,
        astrologerName: astrologerFullProfile.name,
        astrologerProfileURL: astrologerFullProfile.profile_url,
        astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
        type: 'chat',
        maxAmount: 1000,
        highlight: 499,
        location: 'Chat History Top'
      })
      window.location.href = `/?recharge_redirect=1#chatrequest`
    } else {
      rechargeButtonAction({
        buttonName: 'Continue Chat',
        location: 'Chat Bottom'
      });
      e.goToWallet()
    }
  }

  return (
    <>
      <Modal
        type="normal"
        overlayClose={true}
        open={props.open}
        handleClose={onClose}
      >
        <Modal.Content>

          <div className={style.chatWrapNew}>
            <div
              className="chat-window new-ui-chat-window"
            >
              {loading === false ?
                <div className={clsx(
                  'chat-wrap',
                  BrowserUtils.isNewUI() ? 'new-chat-ui' : ''
                )}>

                  <div className={clsx("chat-window-header")}>
                    {/* <span className="back" onClick={closeConversation}>
                <Back />
              </span> */}
                    {BrowserUtils.isNewUI() && astrologerFullProfile.fullName ?
                      <>
                        <span className="astrologer-display" onClick={openProfile}>
                          <AstrologerAvatar
                            astrologer={astrologerFullProfile}
                          />
                          <span className="name">{astrologerFullProfile.fullName}</span>
                        </span>
                      </>
                      :
                      <span>{astrologerFullProfile.fullName}</span>
                    }
                  </div>


                  <div
                    className={clsx(
                      "chat-action-bar",
                      // wallet_balance === 0 ? 'zero-balance' : '',
                      // reconnecting ? 'reconnecting' : ''
                    )}
                  >
                    <span className={clsx('chat-status', `status-${activeChatStatus}`)} style={{ opacity: 0.6 }}>
                      <span className="name">Status: </span>
                      <span className="value">
                        {activeChatStatus}
                      </span>
                    </span>



                    {/* {(BrowserUtils.isNewUI() && astrologerFullProfile && freeChatMinutes(astrologerFullProfile) > 0) ? */}
                    {/* {(BrowserUtils.isNewUI() && wallet_balance === 0) ?
                    <span className="chat-wallet-balance">
                      <AccountBalanceWalletIcon fontSize="default" />
                      <span>0 pts</span>
                    </span>
                    :
                    null
                  } */}

                    {BrowserUtils.isNewUI() && astrologerFullProfile ?
                      <>
                        <span className="chat-right-btns">
                          <RechargeLinkRedirect
                            refData={{
                              refID: 'chat-history/recharge-top-button',
                              astrologerID: astrologerFullProfile.id,
                              astrologerName: astrologerFullProfile.name,
                              astrologerProfileURL: astrologerFullProfile.profile_url,
                              astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
                              type: 'chat',
                              maxAmount: 1000,
                              highlight: 499,
                              location: 'Chat Top'
                            }}
                            onClick={async (e) => {
                              rechargeButtonAction({
                                buttonName: 'Recharge',
                                location: 'Chat Top'
                              });
                              e.goToWallet()
                            }}
                          >
                            <Button className="btn-action btn-recharge" id="chat-recharge-button">
                              Recharge
                            </Button>
                          </RechargeLinkRedirect>
                          <div style={{ display: 'none' }}>
                            <RechargeLinkRedirect
                              refData={{
                                refID: 'chat-history/recharge-inchat-button',
                                astrologerID: astrologerFullProfile.id,
                                astrologerName: astrologerFullProfile.name,
                                astrologerProfileURL: astrologerFullProfile.profile_url,
                                astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
                                type: 'chat',
                                maxAmount: 1000,
                                highlight: 499,
                                location: 'In chat'
                              }}
                              onClick={async (e) => {
                                rechargeButtonAction({
                                  buttonName: 'Recharge',
                                  location: 'In chat'
                                });
                                e.goToWallet()
                              }}
                            >
                              <Button className="btn-action btn-recharge" id="chat-action-bar-recharge-button"> </Button>
                            </RechargeLinkRedirect>
                          </div>
                          <Button className="btn-action btn-end" onClick={props.handleClose}>
                            Close
                          </Button>
                        </span>
                      </>
                      :
                      <></>
                    }
                  </div>

                  <div
                    className={clsx(
                      'chat-window-body',
                      `status-${activeChatStatus}`
                    )}>
                    <div className="chat-messages">
                      {props.history.messages.map((msg, index) => {
                        let msgStatus = "sent";
                        const lastMsg = index > 0 ? props.history.messages[index - 1] : msg;
                        // if (msg.delivered === true) {
                        //   msgStatus = "delivered";
                        // } else if (msg.read === true) {
                        //   msgStatus = "read";
                        // } else {
                        //   msgStatus = "sent";
                        // }

                        if ((msg.from === "bot" || msg.from.split("-")[0]) !== "user") {
                          if (typeof msg.body.extra === 'undefined') {
                            msg.body.extra = {}
                          }
                          msg.body.extra.before = <InChatAvatar astrologer={astrologerFullProfile} />
                        }

                        // if (msg.body.text) {
                        //   msg.body.text += ' recharge'
                        // }

                        return (
                          <React.Fragment key={index}>
                            {/* {Moment(lastMsg.created).format("DD MMM yyy") !== Moment(msg.created).format("DD MMM yyy") && (
                <div className="chat-day-break">{Moment(msg.created).format("MMMM D, yyyy")}</div>
              )} */}
                            {msg.from === "bot" ? (
                              ["user", "both"].indexOf(msg.body.text.visibility) >= 0 && (
                                <MetaMessage message={msg} msgStatus={msgStatus} MessageType={MessageType} chatHistory astrolgerInfo={astrologerFullProfile} />
                              )
                            ) : (
                              <>
                                {msg.from !== "breaker" ? (
                                  <SingleMessage msg={msg} msgStatus={msgStatus} MessageType={MessageType} key={msg.id} astrolgerInfo={astrologerFullProfile} />
                                ) : (
                                  <div className="breaker">
                                    <hr />
                                  </div>
                                )}
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>

                  <div className="chat-window-footer">
                    <div className="chat-not-accepted">
                      <RechargeLinkRedirect
                        refData={{
                          refID: 'chat-history/continue-chat',
                          astrologerID: astrologerFullProfile.id,
                          astrologerName: astrologerFullProfile.name,
                          astrologerProfileURL: astrologerFullProfile.profile_url,
                          astrologerCharge: astrologerFullProfile?.perMinutePrice || 0,
                          type: 'chat',
                          maxAmount: 1000,
                          highlight: 499,
                          location: 'Chat Bottom',
                        }}
                        onClick={handleContinueChat}
                      >
                        <Button>Continue Chat</Button>
                      </RechargeLinkRedirect>
                    </div>
                  </div>

                </div>
                :
                <div className="loaderWrap">
                  <CircularProgress className="loader" />
                </div>
              }
            </div>
          </div>

        </Modal.Content>
      </Modal >

      <Snackbar
        vertical="top"
        horizontal="center"
        message={snackBar.message}
        closeError={() => setSnackBar({ ...snackBar, open: false })}
        open={snackBar.open}
      />
    </>
  )
}

export default ChatHistoryModal